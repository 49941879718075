import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Column } from "../../../components/src/CustomTable.web";
import { folderImage } from "./assets";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  searchString: string;
  rowItems: RowData[];
  isOpenAddDocumentDialog: boolean;
  docFile: any;
  docDescription: string;
  isDocumentSubmitted: boolean;
  isDataAdded: boolean;
  docValidationMessage: string;
  descriptionValidationMessage: string;
}

interface SS {
  id: any;
}

interface RowData {
  docId: string;
  fileName: string;
  description: string;
  userID: string;
  createDate: string;
}

const documentListData = [1, 2, 3, 4, 5, 6, 7, 8, 9].map(docItem => ({
  docId: `237902${docItem}`,
  fileName: `Document ${docItem}`,
  description: `This is the description for Document ${docItem}`,
  userID: "01",
  createDate: `12/06/2023`
}));

export default class EmployerDocumentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  documentListColumns: Column[] = [
    {
      id: "docId",
      label: "Doc ID",
      headerAlign: "center",
      align: "center",
      format: (row: RowData) => {
        return (
          <div className="folder-image-doc-id">
            <img src={folderImage} alt="folder image" className="mr-20" />
            {row.docId}
          </div>
        );
      }
    },
    {
      id: "fileName",
      label: "File Name",
      headerAlign: "left",
      align: "left"
    },
    { id: "description", label: "Description" },
    { id: "userID", headerAlign: "center", align: "center", label: "User ID" },
    { id: "createDate", label: "Create Date" }
  ];

  constructor(props: Props) {
    super(props);

    this.state = {
      searchString: "",
      rowItems: documentListData,
      isOpenAddDocumentDialog: false,
      docFile: "",
      docDescription: "",
      isDocumentSubmitted: false,
      isDataAdded: false,
      docValidationMessage: "",
      descriptionValidationMessage: ""
    };
  }

  onSearchDocumentChange = (event: React.ChangeEvent<any>) => {
    const searchParam = event.target.value;
    const updatedDocumentList = documentListData.filter(record => {
      return (
        record.docId.toLowerCase().includes(searchParam.toLowerCase()) ||
        record.fileName.toLowerCase().includes(searchParam.toLowerCase()) ||
        record.description.toLowerCase().includes(searchParam.toLowerCase()) ||
        record.userID.toLowerCase().includes(searchParam.toLowerCase()) ||
        record.createDate.toLowerCase().includes(searchParam.toLowerCase())
      );
    });
    this.setState({
      ...this.state,
      searchString: searchParam,
      rowItems: updatedDocumentList
    });
  };

  onOpenAddDocumentDialog = () => {
    this.setState({ ...this.state, isOpenAddDocumentDialog: true, isDataAdded: false });
  };

  onCloseAddDocumentDialog = () => {
    this.setState({
      ...this.state,
      isOpenAddDocumentDialog: false,
      docFile: "",
      docDescription: "",
      docValidationMessage: "",
      descriptionValidationMessage: ""
    });
  };

  onSaveDocument = () => {
    if (this.state.docDescription && this.state.docFile) {
      this.setState({
        ...this.state,
        isDocumentSubmitted: true,
        docValidationMessage: "",
        descriptionValidationMessage: ""
      });
    } else {
      if (!this.state.docFile) {
        this.setState({...this.state, docValidationMessage: "Document file is required", descriptionValidationMessage: ""});
      } else if (!this.state.docDescription) {
        this.setState({...this.state, descriptionValidationMessage: "Description is required", docValidationMessage: ""});
      }
    }
  };

  handleSuccessClose = () => {
    this.setState({ ...this.state, 
      isDocumentSubmitted: false, 
      isOpenAddDocumentDialog: false, 
      docFile: "",
      docDescription: "",
      docValidationMessage: "",
      descriptionValidationMessage: ""
    });
  };

  handleDocumentFileOnChange = (doc: any) => {
    this.setState({ ...this.state, docFile: doc, isDataAdded: true });
  };

  handleDocDescriptionOnChange = (e: any) => {
    this.setState({ ...this.state, docDescription: e.target.value, isDataAdded: true });
  };
}
