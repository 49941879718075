import React, { Component } from "react";

import "./TermsAndConditionsTextComponent.web.css";

interface Props {}

interface S {}

export default class TermsAndConditionsTextComponent extends Component<
  Props,
  S
> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="terms-and-condition-text-component">
        <p>
          <b>
            Before you can proceed, please agree to our terms and conditions.
          </b>
        </p>
        <p>
          When you use this website, and any other websites owned or maintained
          by Benefit Logistics or its affiliates, and their related internet and
          mobile applications and software, (the “websites”), you agree to the
          following terms and conditions. Please read them carefully. If you do
          not agree to these terms and conditions, you should immediately cease
          use of the websites.
        </p>
        <p>
          These terms and conditions can change at any time. When they change,
          we will post the revised terms and conditions on the websites. If you
          continue to use the websites after we make changes, you are bound by
          the terms and conditions as revised.
          <b>
            For that reason, when you use the websites, we suggest you
            periodically visit this page to review the most recent terms and
            conditions.
          </b>
        </p>
        <p>
          As used on the websites, and in these terms and conditions, "Benefit
          Logistics", "we", “us” or "our" refers to Benefit Logistics and its
          affiliates, unless indicated otherwise or the context otherwise
          requires.
        </p>
        <p>
          <b>PRIVACY NOTICES</b>
        </p>
        <p>
          Benefit Logistics respects your privacy. Information about Benefit
          Logistics’ privacy practices, including our Internet Privacy Notice,
          can be found by clicking on the Privacy link located on the bottom of
          each website’s home page or login screen. Benefit Logistics’ practices
          regarding the collection and use of personal information through or in
          connection with use of the websites may be updated from time to time.
        </p>
        <p>
          <b>CAPABILITIES AND FEATURES</b>
        </p>
        <p>
          Benefit Logistics websites, through select applications, are used
          internally and externally to offer, and administer insurance products
          and services and communicate with customers, insureds, employees, job
          applicants and the public. Our robust array of technology
          applications, available with select products and offerings, provide
          users the ability to enroll in and change insurance coverage, submit
          claims and check on claim status, review premium billing, complete
          evidence of insurability and perform other functions related to
          insurance. Our applications also provide administrative tools and
          reporting features for providers, brokers, agents, employers, and plan
          administrators.
        </p>
        <p>
          The navigational tools provided with each application direct users to
          the functions available to them based on the product offering and
          their approved roles. File formatting and security guidelines,
          including electronic transmission standards, apply to your use of the
          websites. An inability to transmit and maintain records according to
          these requirements may impair your use of the websites.
        </p>
        <p>
          <b>PRODUCTS AND SERVICES</b>
        </p>
        <p>
          Benefit Logistics, through its various subsidiaries, provides
          insurance products and services throughout the United States and in
          select countries. Not all Benefit Logistics companies do business in
          all jurisdictions and insurance products and services are not
          available in all jurisdictions. Insurance products are offered through
          the following Benefit Logistics insuring companies:
        </p>
        <p>
          <b>OWNERSHIP AND USE OF CONTENT</b>
        </p>
        <p>
          We grant you a personal, revocable, limited, non-exclusive,
          royalty-free, and non-transferable license to use the websites
          conditioned on your adherence to these terms and conditions. The
          information, including all data, analytics, and reports, on the
          websites is owned or licensed by Benefit Logistics and is provided for
          your personal use. Except as otherwise expressly provided in these
          Terms of Use, the website information may not be modified, copied,
          distributed, republished, uploaded, downloaded, posted, or transmitted
          without our prior written consent.
        </p>
        <p>
          <b>COPYRIGHTS AND TRADEMARKS</b>
        </p>
        <p>
          All trademarks, service marks, trade names, logos, icons, and domain
          names located on the websites are owned or licensed by us. Your use of
          any trademark, service mark, trade name, logo, icon, or domain name
          displayed on the websites, without our written permission, is strictly
          prohibited.
        </p>
        <p>
          The websites contain software, photographs, graphics, text, images,
          and other material (collectively "Material") that are protected by
          copyright law. All Material also is copyrighted as a collective work
          under the copyright laws, and Benefit Logistics owns a copyright in
          the selection, coordination, arrangement, and enhancement of the
          Material. You may not modify, remove, delete, augment, add to,
          publish, transmit, participate in the transfer or sale of, create
          derivative works from, or in any way exploit any of the Material, in
          whole or in part. We grant you permission to electronically copy and
          print in hard copy any portions of the websites (and to download
          software made available on the websites) provided you do so solely for
          your personal use and in connection with conducting business with us
          and provided they retain all copyright, trademark, or other
          proprietary notices. Otherwise, you may not reproduce or distribute in
          any way Material without obtaining our prior written permission.
        </p>
        <p>
          <b>DISCLAIMERS AND LIMITATIONS</b>
        </p>
        <p>
          Access to the websites may be interrupted, restricted, or delayed for
          any reason. We are not liable to you or anyone else for any loss
          resulting from use, or loss of use, of the websites or any content on
          the websites. This includes any error, omission, interruption, or
          delay in the operation of the websites due to natural causes,
          equipment failures or other circumstances. We are under no obligation
          to provide any kind of technical, development or end-user support for
          the websites.
        </p>
        <p>
          Every attempt is made to ensure that the information on the websites
          is accurate and complete, but there are no representations as to the
          accuracy, completeness, or timeliness of any information on the
          websites. The information on the sites may be changed at any time.
        </p>
        <p>
          All policy, claim and account information displayed on the websites is
          provided as a convenience to you and for your information, but is not
          the official records of any policy, claim or account activity. In the
          event there is a discrepancy between the material on the websites and
          any policy documents, the policy documents represent the official
          record.
        </p>
        <p>
          The information on the websites is not intended as legal, tax,
          accounting, or investment advice. You are solely responsible for
          seeking professional advice and taking such actions, and making such
          elections, as you deem appropriate to your circumstances and
          objectives.
        </p>
        <p>
          <b>
            WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT
            LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, TITLE, AND NON-INFRINGEMENT. CONTENT AND FUNCTIONS ARE
            PROVIDED "AS IS" AND "AS AVAILABLE."
          </b>
          <br />
          To the extent the law does not permit the disclaimer of warranties,
          all content and functions are warranted only to the extent allowed by
          law. In using the websites, you agree that you will not hold us liable
          to you or any other party for any direct, indirect, special,
          consequential, or other damages of any kind arising out of any use of
          the websites (or any linked website).
        </p>
        <p>
          <b>VIRUSES</b>
        </p>
        <p>
          We assume no responsibility and will not be liable for any damages to
          your computer equipment or other property, including virus infections,
          caused by your access to, use of, or browsing of the websites or your
          downloading any materials, data, text, video, or audio from the
          websites.
        </p>
        <p>
          <b>LINKING TO OTHER WEBSITES</b>
        </p>
        <p>
          Links from any Benefit Logistics website to a non-Benefit Logistics
          website may be provided for users' convenience only. We do not control
          or review non-Benefit Logistics websites nor does the provision of any
          link imply an endorsement or association of a non-Benefit Logistics
          website. We are not responsible for, and make no representation or
          warranty regarding, the contents, completeness, accuracy, or security
          of any materials on non-Benefit Logistics websites or any product or
          services provided through non-Benefit Logistics websites. Please
          review any terms of use and privacy notices of any non-Benefit
          Logistics websites you visit.
        </p>
        <p>
          <b>UNSOLICITED IDEAS</b>
        </p>
        <p>
          We do not accept or consider unsolicited ideas, proposals, or
          suggestions. Please do not send any ideas, proposals, or suggestions
          to us. If you do send materials to us, regardless of how submitted,
          your submission creates no contract with us and any information you
          provide becomes our property and can be used by us without attribution
          or compensation and will be considered non-confidential.
        </p>
        <p>
          <b>MINORS</b>
        </p>
        <p>
          We do not intend to solicit information from children or market
          products or services to children through websites.
        </p>
        <p>
          <b>MOBILE USE FEES</b>
        </p>
        <p>
          Mobile phone operators may charge fees related to incoming or outgoing
          SMS (or text) messages that you cause to be sent in connection with
          your use of website features. You are responsible for the payment of
          such fees.
        </p>
        <p>
          <b>FORWARD-LOOKING STATEMENTS</b>
        </p>
        <p>
          Our websites may contain statements, estimates or projections that
          constitute “forward-looking statements” within the meaning of the
          Private Securities Litigation Reform Act of 1995. Forward-looking
          statements are those not based on historical information, but rather
          related to future operations, strategies, financial results, or other
          developments. These forward-looking statements are subject to numerous
          assumptions, risks, and uncertainties, many of which are beyond our
          control. For a discussion of assumptions, risks, uncertainties, and
          other crucial factors that could cause actual results to differ
          materially from those expressed in the forward-looking statements, see
          our most recent reports on Form 10-K and Form 10-Q filed with the
          Securities and Exchange Commission. The forward-looking statements on
          the websites speak only as of the date made and we expressly disclaim
          any obligation to update or otherwise revise these statements, whether
          through the websites or otherwise.
        </p>
        <p>
          <b>SECURITY</b>
        </p>
        <p>
          We understand that the confidentiality, integrity, and availability of
          our customers’ information are keys to our overall business success.
          Our internal business processes and procedures are designed to protect
          customer information from unauthorized access, disruption,
          modification, or destruction, and to comply with various privacy laws
          and regulations applicable to the collection, use and disclosure of
          customer information.
        </p>
        <p>
          Our information security program is based on applicable international
          regulatory requirements and industry standards and best practices such
          as the ISO 27001 and the NIST Cyber Security Framework.
        </p>
        <p>
          <b>ACCOUNT INFORMATION</b>
        </p>
        <p>
          In order to establish or maintain an account with us using certain
          applications on the websites, you may be required to provide
          information to us such as your name, e-mail address, mailing address,
          zip code, country, username, and password. You agree to provide true,
          accurate, current, and complete information and promptly update the
          information when it changes. If you provide any untrue or inaccurate
          information, or if we have reasonable grounds to suspect that the
          information is untrue or inaccurate, we may suspend or terminate your
          account and refuse all current and future use by you of our websites,
          or of particular websites or applications.
        </p>
        <p>
          <b>GRANTING ACCESS TO OTHERS</b>
        </p>
        <p>
          Your access to the websites is limited to accounts and information for
          which you have permission. If you establish an account with us, you
          are responsible for maintaining the confidentiality of your username
          and password. If you authorize any other persons to access or use your
          account, you must maintain an authorized users list in sufficient
          detail to enable the administrator, upon request, to confirm their
          identity and level of authorization. You agree to promptly remove from
          your authorized user list anyone who should no longer have access. You
          are responsible for all uses of your account, including by authorized
          users. You agree to notify us immediately of any unauthorized use of
          your account or password.
        </p>
        <p>
          You may not permit access through other systems, or link to other
          systems, unless those systems are maintained in a manner consistent
          with industry standards for the protection of financial records and
          non-public personal information, are password protected, and use
          industry standard secure transmission and encryption technologies.
        </p>
        <p>
          <b>PRIVACY AND CONFIDENTIALITY</b>
        </p>
        <p>
          You understand that the confidentiality and security of any
          information, including personally identifiable information,
          transmitted by you over the internet cannot be guaranteed. You
          acknowledge that when you access the websites, we collect information
          about your visit and your use of the website.
        </p>
        <p>
          You are responsible for maintaining the confidentiality of any
          proprietary or confidential information of another party which you
          access through any Benefit Logistics website. You agree not to
          disclose an individual’s nonpublic personal information obtained
          through the websites to another party without the individual’s prior
          authorization, or as otherwise required by law, and will use the
          information only to carry out the purposes for which the information
          was originally disclosed. You agree to comply with all applicable
          federal and state privacy laws and regulations.
        </p>
        <p>
          You agree that if you access, or are in receipt of, any information or
          reports not intended for your viewing or use you will notify us
          immediately. You agree not to use any information or reports that were
          not intended for you viewing or use and you will return or delete such
          information from your records and system.
        </p>
        <p>
          In the event of a security incident or breach, or potential incident
          or breach, of privacy or security with respect to proprietary or
          personally identifiable information, you agree to notify our privacy
          office immediately [email to: privacy@BenefitLogistics.com ] and
          cooperate fully in mitigating the harmful effects of such incident or
          breach. You are accountable for all costs and damages of any privacy
          or security breach to the extent caused by you. Any privacy breach,
          threatened or actual, may cause irreparable harm to us for which we
          would not have adequate remedies at law. You therefore agree that we
          are entitled to all available equitable remedies for any breach of
          these terms and conditions.
        </p>
        <p>
          <b>DISRUPTION OF THE WEBSITES</b>
        </p>
        <p>You agree not to:</p>
        <p>
          <ul>
            <li>
              Interrupt or attempt to interrupt the operation of the Benefit
              Logistics websites in any way;
            </li>
            <li>
              Attempt to obtain or decrypt any passwords or other security
              protections for any account to which you do not have permitted
              access;
            </li>
            <li>
              Act on behalf of another person without express permission to do
              so and you will not misrepresent your affiliation with a person or
              entity; or misrepresent the origin of any information provided by
              you;
            </li>
            <li>
              Copy, reverse engineer, reverse assemble, decompile, create
              derivative works, modify, or otherwise attempt to derive the
              source code of the websites, nor make any unauthorized use of any
              content on the websites;
            </li>
            <li>
              Display, disclose, rent, lease, modify, loan, distribute, or
              create derivative works based on the websites or any part thereof;
            </li>
            <li>
              Remove, disable, circumvent, or otherwise create or implement any
              workaround to any copy protection, rights management, or security
              features of the websites;
            </li>
            <li>Exploit the websites in any unauthorized way whatsoever; or</li>
            <li>
              Post, transmit or display any offensive, defamatory, hateful,
              threatening, obscene or anti-competitive materials or encourage
              any illegal activities on the websites.
            </li>
          </ul>
        </p>
        <p>
          We have the right to remove any material we feel violates these terms
          and conditions. We may monitor and archive for compliance purposes any
          information related to your use of the websites.
        </p>
        <p>
          <b>THIRD PARTIES</b>
        </p>
        <p>
          If you access the website on behalf of another person, you are
          responsible for obtaining prior authorization to act on behalf of that
          person. You will maintain complete documentation of the authorization
          and actions you are authorized to take. You will use any information
          obtained through such an authorization only to provide services for
          that person.
        </p>
        <p>
          <b>GOVERNING LAW</b>
        </p>
        <p>
          The laws of the United States and the State of Tennessee will govern
          the content and use of the Benefit Logistics websites, without giving
          effect to conflict of law principles.
        </p>
        <p>
          We control the websites from our offices within the United States. We
          make no representation that the content on the websites is
          appropriate, legal, or available for use in other locations. Those who
          choose to access the websites from other locations do so on their own
          initiative and are responsible for compliance with applicable local
          laws. By logging into, or entering the websites, you consent and
          submit to the exclusive jurisdiction and venue of the state and
          federal courts located in the State of Tennessee.
        </p>
        <p>
          <b>COMPLIANCE WITH EXPORT LAWS AND REGULATIONS</b>
        </p>
        <p>
          United States export control laws regulate the export and re-export of
          technology originating in the United States. This includes the
          electronic transmission of information and software to foreign
          countries and to certain foreign nationals. You agree to abide by
          these laws and their regulations, including, but not limited to, the
          Export Administration Act and the Arms Export Control Act, and not to
          transfer, by electronic transmission or otherwise, any such technology
          derived from the websites to either a foreign national or a foreign
          destination in violation of such laws.
        </p>
        <p>
          <b>SEVERABILITY</b>
        </p>
        <p>
          If any provision is determined to be invalid or unenforceable, such
          determination shall not affect the remaining provisions which will be
          enforceable to the extent allowed by law and in a manner that most
          closely matches the intent of the original language.
        </p>
        <p>
          <b>TERMINATION</b>
        </p>
        <p>
          We may terminate your right to use the website at any time for any
          violation of these terms and conditions and for any business purpose.
          All provisions relating to proprietary rights, limitations of
          liability and warranties, non-disclosure, privacy, and security
          survive termination.
        </p>
        <p>
          <b>CUSTOMER RATINGS AND REVIEWS TERMS OF USE</b>
        </p>
        <p>
          These Terms of Use govern your conduct associated with the Customer
          Ratings and Reviews and/or Questions and Answers service offered by
          Benefit Logistics (the "CRR Service").
        </p>
        <p>
          By submitting any content to Benefit Logistics, you guarantee that:
        </p>
        <p>
          <ul>
            <li>
              You are the sole author and owner of the intellectual property
              rights in the content;
            </li>
            <li>
              All "moral rights" that you may have in such content have been
              voluntarily waived by you;
            </li>
            <li>All content that you post is accurate;</li>
            <li>
              You are at least 18 years old and the age of majority in your
              state of residence;
            </li>
            <li>
              You are or have been a customer of Benefit Logistics, or the legal
              guardian of a Benefit Logistics customer;
            </li>
            <li>
              Use of the content you supply does not violate these Terms of Use
              and will not cause injury to any person or entity.
            </li>
          </ul>
        </p>
        <p>You further agree that you may not submit any content:</p>
        <p>
          <ul>
            <li>That is known by you to be false, inaccurate or misleading;</li>
            <li>
              That infringes any third party's copyright, patent, trademark,
              trade secret or other proprietary rights or rights of publicity or
              privacy;
            </li>
            <li>
              That violates any law, statute, ordinance, or regulation
              (including, but not limited to, those governing, consumer
              protection, unfair competition, anti-discrimination, or false
              advertising);
            </li>
            <li>
              That is, or may reasonably be considered to be, defamatory,
              libelous, hateful, racially, or religiously biased or offensive,
              unlawfully threatening or unlawfully harassing to any individual,
              partnership or corporation;
            </li>
            <li>
              For which you were compensated or granted any consideration by any
              third party;
            </li>
            <li>
              That includes any information that references other websites,
              addresses, email addresses, contact information or phone numbers,
              or any other content that invades other's privacy;
            </li>
            <li>
              Using multiple email addresses or otherwise attempting to post the
              same or similar content multiple times;
            </li>
            <li>
              That infringes any third party's copyright, patent, trademark,
              trade secret or other proprietary rights or rights of publicity or
              privacy;
            </li>
            <li>That reference pending or ongoing litigation;</li>
            <li>
              Is unrelated to Benefit Logistics or includes advertising,
              promotion, or solicitation;
            </li>
            <li>
              That includes inappropriate or inflammatory language, or critical
              comments about other reviews or reviewers; or
            </li>
            <li>
              That contains any computer viruses, worms or other potentially
              damaging computer programs or files.
            </li>
          </ul>
        </p>
        <p>
          Review that violates the above restriction, in Benefit Logistics’
          discretion, may be modified to remove offending content or may not be
          posted or utilized by Benefit Logistics in its entirety. Benefit
          Logistics may publish the nickname you designate with your review.
        </p>
        <p>
          You agree to indemnify and hold Benefit Logistics (and its officers,
          directors, agents, subsidiaries, joint ventures, employees and
          third-party service providers, including but not limited to MBA TPA,
          Inc.), harmless from all claims, demands, and damages (actual and
          consequential) of every kind and nature, known and unknown including
          reasonable attorneys' fees, arising out of a breach of your
          representations and warranties set forth above, or your violation of
          any law or the rights of a third party.
        </p>
        <p>
          For any content that you submit, you grant Benefit Logistics a
          perpetual, irrevocable, royalty-free, transferable right and license
          to use, copy, modify, delete in its entirety, adapt, publish,
          translate, create derivative works from and/or sell and/or distribute
          such content and/or incorporate such content into any form, medium or
          technology throughout the world without compensation to you.
        </p>
        <p>
          All content that you submit may be used at Benefit Logistics’ sole
          discretion. Benefit Logistics reserves the right to change, condense,
          withhold publication, remove, or delete any content on Benefit
          Logistics’ website that Benefit Logistics deems, in its sole
          discretion, to violate the content guidelines or any other provision
          of these Terms of Use. Ratings and written comments are generally
          posted within two to four business days. However, Benefit Logistics
          reserves the right to remove or to refuse to post any submission to
          the extent authorized by law. You acknowledge that you, not Benefit
          Logistics, are responsible for the contents of your submission. None
          of the content that you submit shall be subject to any obligation of
          confidence on the part of Benefit Logistics, its agents, subsidiaries,
          affiliates, partners or third-party service providers and their
          respective directors, officers, and employees. Benefit Logistics may
          include disclosures with published responses to comply with applicable
          law.
        </p>
        <p>
          Benefit Logistics does not guarantee that you will have any recourse
          through Benefit Logistics to edit or delete any content you have
          submitted.
        </p>
        <p>
          By submitting your email address in connection with your rating and
          review, you agree that Benefit Logistics and its third-party service
          providers may use your email address to contact you about the status
          of your review and other administrative purposes.
        </p>
        <p>
          Benefit Logistics reserves the right to modify these Terms and
          Conditions at any time. For questions regarding these Terms and
          Conditions, please contact us at the number on your Healthcare ID
          Card.
        </p>
      </div>
    );
  }
}
