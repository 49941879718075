import React, { Component } from "react";
import { Menu, MenuItem } from "@material-ui/core";

import "./CustomTableMenu.web.css";

export interface MenuItemList {
  label: string;
  icon: string;
  id: string;
}

interface Props {
  selectedMenuAnchorEl: HTMLElement | null;
  closeTableMenu: () => void;
  menuItemList: MenuItemList[];
  menuItemOnClick?: (id: string) => void;
}

interface S {}

export default class CustomTableMenu extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Menu
        id="custom-table-menu"
        anchorEl={this.props.selectedMenuAnchorEl}
        keepMounted
        open={Boolean(this.props.selectedMenuAnchorEl)}
        className="custom-table-menu"
        onClose={this.props.closeTableMenu}
      >
        {this.props.menuItemList.map((item) => (
          <MenuItem
            className={`custom-table-menu-item ${item.label === "Delete" &&
              "delete-action-item"}`}
            onClick={() =>
              this.props.menuItemOnClick
                ? this.props.menuItemOnClick(item.id)
                : this.props.closeTableMenu()
            }
            data-test-id="custom-menu-item"
            key={item.label}
          >
            <span className="menu-item-img">
              <img height={12} src={item.icon} />
            </span>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    );
  }
}
