import React, { Component } from "react";
import "./GenericContactDetailsFields.css";
import { Grid, TextField } from "@material-ui/core";
import { FormikProps } from "formik";

interface Props {
  handleInputChange: any;
  fieldsArray: any[];
  commonOtherKey?: string;
  formikProps?: FormikProps<any>
}

interface S { }

export default class GenericContactDetailsFields extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
  }

  convertNumber = (e: any) => {
    let newValue: string = e.target.value;
    newValue = newValue.replace(/[^\d.-]+/gi, '');
    e.target.value = newValue;
    return e
  }

  convertLetter = (e: any) => {
    let newValue: string = e.target.value;
    newValue = newValue.replace(/[^a-zA-Z ]/g, "");
    e.target.value = newValue;
    return e
  }

  onChangeText = (event: any, item: any) => {

    if (item.onlyNumber) {
      event = this.convertNumber(event)
    }
    if (item.onlyLetter) {
      event = this.convertLetter(event)
    }
    if (this.props.formikProps) {
      this.props.formikProps?.handleChange(event)
    }
    this.props.commonOtherKey
      ? this.props.handleInputChange(
        event,
        this.props.commonOtherKey,
        item.key
      )
      : this.props.handleInputChange(event, item.key)
  }

  render() {
    return (
      <div className="contact-field-wrapper">
        {this.props.fieldsArray.map((item: any, index: any) => (
          <Grid key={index} item xs={12} sm={12} md={12} className="fieldSpace">
            <div className="text-input-label">{item.label}</div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder=""
              type={item.type ? item.type : "text"}
              className="text-input"
              data-test-id={item.key}
              value={item.value}
              error={!!item.error}
              onChange={(event) => this.onChangeText(event, item)}
              name={item.key}
            />
            <span style={{ color: "red", fontSize: "12px" }}>{item.error ? item.error : ""}</span>
          </Grid>
        ))}
      </div>
    );
  }
}
