import { IAgentModel } from "../../../../components/src/GenericAddAgentComponent.web";

export interface IGroupBasicDetailModel {
  group_name: string;
  group_id: string;
  client_name: string;
  employer_name: string;
  employer_number: string;
  group_being_date: Date | string;
  group_termination_date: Date | string;
  group_deductible_date: Date | string;
  payroll_day: string;
  payroll_month: string;
  payroll_cycle1: string;
  payroll_cycle2: string;
  payroll_cycle3: string;
  payroll_cycle4: string;
  tax_id: string;
}

export interface IGroupAddressDetailModel {
  physical_address_line1: string;
  physical_address_line2: string;
  physical_city: string;
  physical_zip_code: string;
  physical_state_id: string;
  physical_country_id: string;
  mailing_address_line1: string;
  mailing_address_line2: string;
  mailing_city: string;
  mailing_zip_code: string;
  mailing_state_id: string;
  mailing_country_id: string;
  group_check_address_line1: string;
  group_check_address_line2: string;
  group_check_city: string;
  group_check_zip_code: string;
  group_check_state_id: string;
  group_check_country_id: string;
  group_check_group_contact1: string;
  group_check_group_contact2: string;
  group_check_group_email1: string;
  group_check_group_email2: string;
  group_check_group_phone_number1: string;
  group_check_group_phone_number2: string;
  group_check_group_billing_contact: string;
  group_check_group_enroll_status: string;
}

export interface IGroupLogoAndDocModel {
  gro_logo: File | string;
  gro_doc: File | string;
}

export interface IGroupOtherDetailModel {
  agents: IAgentModel[];
  cost_containment_fee: string;
  employer_admin_fee: string;
  employer_ppo_fee: string;
  employer_cobra_fee: string;
  employer_hipaa_fee: string;
  employer_agg_premium_fee: string;
  employer_fsa_fee: string;
  employer_hra_fee: string;
  employer_hsa_fee: string;
  employer_fee1: string;
  employer_fee2: string;
  employer_fee3: string;
  employer_fee4: string;
  den_ee_spec_premium: string;
  vis_ee_spec_premium: string;
  med_ee_spec_premium: string;
  med_ee_plus_sp_spec_premium: string;
  den_ee_plus_sp_spec_premium: string;
  vis_ee_plus_sp_spec_premium: string;

  med_ee_plus_ch_spec_premium: string;
  den_ee_plus_ch_spec_premium: string;
  vis_ee_plus_ch_spec_premium: string;

  den_ee_plus_chs_spec_premium: string;
  vis_ee_plus_chs_spec_premium: string;
  med_ee_plus_chs_spec_premium:string;

  med_ee_fam_spec_premium: string;
  den_ee_fam_spec_premium: string;
  vis_ee_fam_spec_premium: string;

  aggregate_deductible: string;
  surcharge_percentage: string;
  specific_deductible: string;
  employer_waiting_period: string;

  group_disability_flag: boolean;
  group_den_flag: boolean;
  group_med_flag: boolean;
  group_std_flag: boolean;
  group_vis_flag: boolean;
  group_life_cover_flag: boolean;
  group_ltd_flag: boolean;
  group_vol_life_flag: boolean;
  group_add_flag: boolean;
  group_fsa_flag: boolean;
  group_rx_flag: boolean;
  group_hra_flag: boolean;
  group_cancer_flag: boolean;
  group_hsa_flag: boolean;
  group_medical_question_flag: boolean;
  group_401k_flag: boolean;
  group_other_flag2: boolean;
  is_first_of_the_month: boolean;
}

export interface IGroupDetailModel {
  groupBasicDetails: IGroupBasicDetailModel;
  groupAddressDetails: IGroupAddressDetailModel;
  groupOtherDetails: IGroupOtherDetailModel;
}

export interface IDropdownModel {
  id: string;
  name: string;
}

export const initialGroupBasicDetailModelData: IGroupBasicDetailModel = {
  group_name: "",
  group_id: "",
  client_name: "",
  employer_name: "",
  employer_number: "",
  group_being_date: "",
  group_termination_date: "",
  group_deductible_date: "",
  payroll_day: "",
  payroll_month: "",
  payroll_cycle1: "",
  payroll_cycle2: "",
  payroll_cycle3: "",
  payroll_cycle4: "",
  tax_id: ""
};

export const initialGroupAddressDetailModelData: IGroupAddressDetailModel = {
  physical_address_line1: "",
  physical_address_line2: "",
  physical_city: "",
  physical_zip_code: "",
  physical_state_id:"",
  physical_country_id:"",
  mailing_address_line1: "",
  mailing_address_line2: "",
  mailing_city: "",
  mailing_zip_code: "",
  mailing_state_id: "",
  mailing_country_id: "",
  group_check_address_line1: "",
  group_check_address_line2: "",
  group_check_city: "",
  group_check_zip_code: "",
  group_check_state_id: "",
  group_check_country_id: "",
  group_check_group_contact1: "",
  group_check_group_contact2: "",
  group_check_group_email1: "",
  group_check_group_email2: "",
  group_check_group_phone_number1: "",
  group_check_group_phone_number2: "",
  group_check_group_billing_contact: "",
  group_check_group_enroll_status: ""
};

export const initialGroupFileModelData: IGroupLogoAndDocModel = {
  gro_logo: "",
  gro_doc: ""
};

export const initialGroupAgentData: IAgentModel = {
  agent_name: "",
  agent_rate: "",
  id: 1
};

export const initialGroupOtherDetailModelData: IGroupOtherDetailModel = {
  agents: [initialGroupAgentData],
  employer_admin_fee: "",
  employer_cobra_fee: "",
  employer_hipaa_fee: "",
  employer_ppo_fee: "",
  employer_fsa_fee: "",
  cost_containment_fee: "",
  employer_agg_premium_fee: "",
  med_ee_spec_premium: "",
  den_ee_spec_premium: "",
  vis_ee_spec_premium: "",
  employer_hra_fee: "",
  employer_hsa_fee: "",
  employer_fee1: "",
  employer_fee2: "",
  employer_fee3: "",
  employer_fee4: "",
  med_ee_plus_sp_spec_premium: "",
  vis_ee_plus_sp_spec_premium: "",
  den_ee_plus_chs_spec_premium: "",
  med_ee_plus_ch_spec_premium: "",
  specific_deductible: "",
  aggregate_deductible: "",
  surcharge_percentage: "",
  med_ee_fam_spec_premium: "",
  den_ee_fam_spec_premium: "",
  vis_ee_fam_spec_premium: "",
  employer_waiting_period: "",
  med_ee_plus_chs_spec_premium: "",
  vis_ee_plus_ch_spec_premium: "",
  den_ee_plus_sp_spec_premium: "",
  vis_ee_plus_chs_spec_premium: "",
  den_ee_plus_ch_spec_premium: "",

  group_disability_flag: false,
  group_den_flag: false,
  group_med_flag: false,
  group_std_flag: false,
  group_vis_flag: false,
  group_life_cover_flag: false,
  group_ltd_flag: false,
  group_vol_life_flag: false,
  group_add_flag: false,
  group_fsa_flag: false,
  group_rx_flag: false,
  group_hra_flag: false,
  group_cancer_flag: false,
  group_hsa_flag: false,
  group_medical_question_flag: false,
  group_401k_flag: false,
  group_other_flag2: false,
  is_first_of_the_month: false
};

export const initialGroupDetailModelData: IGroupDetailModel = {
  groupBasicDetails: initialGroupBasicDetailModelData,
  groupAddressDetails: initialGroupAddressDetailModelData,
  groupOtherDetails: initialGroupOtherDetailModelData
};
