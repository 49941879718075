import React, { Component } from "react";
import { TextField, InputAdornment, Divider } from "@material-ui/core";
import "./GenericTextFieldWithSelect.web.css";
import GenericSelectField from "./GenericSelectField.web";

export interface SelectionList {
  id: number;
  name: string;
}

interface Props {
  value: string | number;
  onChange: (e: any) => void;
  error?: string;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  type?: string;
  variant?: "outlined" | "standard" | "filled";
  disabled?: boolean;
  isTaxId?: boolean;
  name?: string;
  selectArray: SelectionList[];
}

interface S {}

export default class GenericTextFieldWithSelect extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  getTaxIdValue = (e: any) => {
    let newValue: string = e.target.value;
    newValue = newValue.replace(/[^0-9]/g, "");
    if (newValue.length > 2) {
      newValue = newValue.slice(0, 2) + "-" + newValue.slice(2);
    }
    e.target.value = newValue;
    return e;
  };

  onChange = (e: any) => {
    if (e.target.value !== " " && this.props.onChange) {
      this.props.isTaxId
        ? this.props.onChange(this.getTaxIdValue(e))
        : this.props.onChange(e);
    }
  };

  render() {
    return (
      <div className="generic-text-field">
        {this.props.label && (
          <div className="text-input-label">
            {this.props.label}
            {this.props.isRequired && <span className="error-asterisk">*</span>}
          </div>
        )}
        <TextField
          fullWidth
          variant={this.props.variant || "outlined"}
          placeholder={this.props.placeholder}
          type={this.props.type || "text"}
          className="text-input"
          value={this.props.value}
          onChange={this.onChange}
          error={!!this.props.error}
          helperText={this.props.error}
          disabled={this.props.disabled}
          name={this.props.name}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  className="divider"
                />
                <GenericSelectField
                  selectionList={this.props.selectArray}
                  value={1}
                  onChange={(e) => this.onChange(e)}
                  data-test-id="payroll_day"
                />
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  }
}
