import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
export const images = require("./assets");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  firstName: string;
  firstNameError: string;
  lastName: string;
  lastNameError: string;
  email: string;
  emailError: string;
  phone: string;
  phoneError: string;
  message: string;
  messageError: string;
  isDataSubmitted: boolean;
  isSubmitOnce: boolean;
}

interface SS {
  id: any;
}

export default class HelpController extends BlockComponent<Props, S, SS> {
  defaultValue = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };

  defaultError = {
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    phoneError: "",
    messageError: "",
  };

  emailRegex = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  );
  mobileRegex = new RegExp(/^\d{10}$/);

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      ...this.defaultValue,
      ...this.defaultError,
      isDataSubmitted: false,
      isSubmitOnce: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleInputChange = (e: any, param: keyof S) => {
    if (e.target.value !== " ") {
      this.setState(
        {
          ...this.state,
          [param]: e.target.value,
        },
        () => this.validationCheck(this.state.isSubmitOnce)
      );
    }
  };

  validationCheck = (isSubmit: boolean) => {
    if (!isSubmit) return true;
    this.setState({
      ...this.defaultError,
    });
    let validate = true;

    if (!this.state.firstName) {
      this.setState({
        firstNameError: configJSON.firstNameRequired,
      });
      validate = false;
    } else if (this.state.firstName.length > configJSON.maxLength) {
      this.setState({
        firstNameError: configJSON.maxLengthExist,
      });
      validate = false;
    }

    if (this.state.lastName.length > configJSON.maxLength) {
      this.setState({
        lastNameError: configJSON.maxLengthExist,
      });
      validate = false;
    }

    if (!this.state.email) {
      this.setState({
        emailError: configJSON.emailAddressRequired,
      });
      validate = false;
    } else if (this.state.email.length > configJSON.maxLength) {
      this.setState({
        emailError: configJSON.maxLengthExist,
      });
      validate = false;
    } else if (this.state.email && !this.emailRegex.test(this.state.email)) {
      this.setState({
        emailError: "E-mail Address is invalid.",
      });
      validate = false;
    }

    if (this.state.phone && !this.mobileRegex.test(this.state.phone)) {
      this.setState({
        phoneError: "Phone Number is invalid.",
      });
      validate = false;
    }

    if (!this.state.message) {
      this.setState({
        messageError: configJSON.messageRequired,
      });
      validate = false;
    } else if (this.state.message.length > configJSON.descriptionMaxLength) {
      this.setState({
        messageError: configJSON.maxLengthExist,
      });
      validate = false;
    }
    return validate;
  };

  handleSendMessageClick = () => {
    this.setState({ isSubmitOnce: true });
    if (this.validationCheck(true)) {
      this.setState({
        ...this.defaultValue,
        ...this.defaultError,
        isDataSubmitted: true,
      });
    }
  };

  handleSuccessClose = () => {
    this.setState({ isDataSubmitted: false });
  };
}
