import React, { Component } from "react";
import { Box, Tab } from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";

import "./CustomTabPanel.web.css";

interface Props {
  tabList: string[];
  children: any;
}

interface S {
  tabIndex: string;
  childrenList: React.ReactNode[];
}

export default class CustomTabPanel extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tabIndex: this.props.tabList[0],
      childrenList: [],
    };
  }

  getUpdatedChildren = () => {
    const newChildrenList: React.ReactNode[] = [];
    React.Children.map(this.props.children, (child) => {
      newChildrenList.push(child);
    });
    this.setState({ childrenList: newChildrenList });
  };

  componentDidMount(): void {
    this.getUpdatedChildren();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.children !== this.props.children) {
      this.getUpdatedChildren();
    }
  }

  handleTabIndexChange = (event: React.ChangeEvent<{}>, value: string) => {
    this.setState({
      tabIndex: value,
    });
  };

  render() {
    return (
      <Box className="custom-tab-panel-container">
        <TabContext value={this.state.tabIndex}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={this.handleTabIndexChange} variant="scrollable">
              {this.props.tabList.map((tab) => (
                <Tab label={tab} value={tab} key={tab} />
              ))}
            </TabList>
          </Box>
          {this.props.tabList.map((tab, index) => (
            <TabPanel value={tab} key={`${tab}children`}>
              {this.state.childrenList[index]}
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    );
  }
}
