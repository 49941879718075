import React, { Component } from "react";
import { Input } from "@material-ui/core";

import "./GenericDateField.web.css";

interface Props {
  value: Date | string;
  onChange?: (e: any) => void;
  error?: string;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  type?: string;
  disabled?: boolean;
}

interface S {}

export default class GenericDateField extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="generic-date-field">
        {this.props.label && (
          <div className="text-input-label">
            {this.props.label}
            {this.props.isRequired && <span className="error-asterisk">*</span>}
          </div>
        )}
        <Input
          fullWidth
          placeholder={this.props.placeholder}
          type="date"
          className="date-input"
          value={this.props.value}
          onChange={(e) => {
            return this.props.onChange && e.target.value.length <= 10 ? this.props.onChange(e) : () => { }
          }
          }
          error={!!this.props.error}
          disabled={this.props.disabled}
        />
        {this.props.error && (
          <div className="input-validation-msg">{this.props.error}</div>
        )}
      </div>
    );
  }
}
