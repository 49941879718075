import React from "react";
import ClientEmployerLandingPageController, {
  Props
} from "./ClientEmployerLandingPageController.web";
import { Box, Grid, Typography, Button, createTheme } from "@material-ui/core";
export const configJSON = require("../config");
import { userClient } from "../assets";
import "./ClientEmpLanding.web.css";
const images = require("../assets");
import CustomClientTabPanel from "../../../../components/src/CustomClientTabPanel.web";
import CustomerTable from "../../../../components/src/CustomTable.web";
import EditClientEmpFormDialog from "./EditClientEmpFormDialog.web";
import CustomTableMenu from "../../../../components/src/CustomTableMenu.web";
import GenericSuccessDialog from "../../../../components/src/GenericSuccessDialog.web";

export const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: "#4166f8"
    }
  },
  typography: {
    fontFamily: "Poppins"
  }
});

export default class ClientEmployerLandingPage extends ClientEmployerLandingPageController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <>
        <div>
          <Box
            component={"div"}
            className="client-employer-landing-mainheader-postion"
          >
            {/*   Employer Header  */}
            <Grid container alignItems="center">
              <Grid
                container
                item
                xs={12}
                className="client-employer-landing-main"
                alignItems="center"
              >
                <Grid item>
                  <Box component={"div"} className="name-tag">
                    <img
                      src={userClient}
                      alt="user_client"
                      width={"120px"}
                      height={"120px"}
                    />
                    <Box component={"div"} style={{ marginLeft: 20 }}>
                      <Box component={"div"} className="name-tag">
                        <Typography className="name">
                          {this.state.clientDetails.client_name}
                        </Typography>
                      </Box>
                      <Typography className="desc">
                        "You are viewing data across all your employers"
                      </Typography>
                      <Typography className="desc">All Employers</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <div>
            <CustomClientTabPanel
              tabList={this.tabMenuList}
              isDisplayAllTab
              allTabLabel={configJSON.employer}
            >
              <Grid
                container
                className="all-location-tab-container all-member-tab-container all-employer-tab-container"
              >
                <Grid item xs={12} className="search-bar-and-add-btn">
                  <div className="add-member-btn-div">
                    <Button
                      className="add-member-btn"
                      data-test-id="add-employer-btn"
                      onClick={() => this.toggleEditEmployerDialog(true, false)}
                    >
                      + Add Employer
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} className="hr mt-30" />
                <Grid item xs={12}>
                  <CustomerTable
                    columns={this.tableColumns}
                    rowData={this.state.rowData}
                    isColumnSelectable
                    onActionClick={this.HandleActionButton}
                    getSelectedColumns={this.handleSelected}
                    rowCount={this.state.totalCount}
                    handlePageChange={this.handlePaginationChange}
                    pageNo={this.state.page}
                    selectedEmployer={this.props.selectedEmployer}
                  />
                  <CustomTableMenu
                    selectedMenuAnchorEl={this.state.employerTableMenuAnchorEl}
                    menuItemList={[
                      { id: "1", label: "Edit", icon: images.blackEditIcon },
                      { id: "2", label: "Delete", icon: images.deleteIcon }
                    ]}
                    closeTableMenu={this.handleEmployerCloseTableMenu}
                    menuItemOnClick={this.handleEmployerTableMenuClick}
                  />
                </Grid>
              </Grid>
              {this.tabMenuList.map(tab => (
                <>{this.getTabPage(tab)}</>
              ))}
            </CustomClientTabPanel>
          </div>
        </div>
        {this.state.isEditEmployerDialogOpen && (
          <EditClientEmpFormDialog
            isOpen={this.state.isEditEmployerDialogOpen}
            isEdit={this.state.isEdit}
            handleClose={() => this.toggleEditEmployerDialog(false, false)}
            saveEmployerData={this.saveEmployerDetails}
            employerDetails={this.state.employerDetails}
            data-test-id="edit-employer-dialog"
            token={this.state.token}
            basicDataForAPI={this.state.basicDataForAPI}
            clientName={this.state.clientDetails.client_name}
            clientId={this.state.clientDetails.client_id}
          />
        )}
        <GenericSuccessDialog
          open={this.state.deletedSuccessfull}
          handleDone={this.handleSuccessDialogClose}
          contains={"Deleted Employer Successfully"}
        />
      </>
    );
  }
}
