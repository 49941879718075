import React from "react";

// Customizable Area Start
import {
  Typography,
  Paper,
  Box,
  Button,
  InputAdornment,
  IconButton,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  OutlinedInput
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const assetImages = require("./assets");
const landingImage = require("./assets");
import "./RegistrationScreen.css";
import "react-placeholder/lib/reactPlaceholder.css";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import GenericDialog from "../../../components/src/GenericDialog.web";
import TermsAndConditionsTextComponent from "../../../components/src/TermsAndConditionsTextComponent.web";
import ReactCodeInput from "react-code-input";

const configJSON = require("./config");

// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

export default class EmailAccountRegistrationBlock extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }

  HandleErrorMessage = (message: string) => {
    return <Typography style={webStyle.errorMessage}> {message}</Typography>;
  };

  selectorContainer = () => (
    <div style={{ position: "relative" }}>
      <div
        style={webStyle.selectorContainer}
        onClick={() => this.handleModal("isModal", !this.state.isModal)}
        data-testId="selectOptiontest"
      >
        <p style={webStyle.selectorValue}>{this.state.selectorValue}</p>
        <img
          src={
            this.state.isModal ? assetImages.arrownUp : assetImages.arrowDown
          }
        />
      </div>
      {this.state.isModal && (
        <Box style={webStyle.modalStyle} data-testId="example">
          {this.state.roleIdApi.map((val: any, index: number) => (
            <p
              key={index}
              onClick={() => this.handleOptionChange(val)}
              data-testId="option"
              style={webStyle.apiValuesName}
            >
              {val.name}
            </p>
          ))}
        </Box>
      )}
    </div>
  );

  firstNameForm = () => {
    return (
      <>
        <OutlinedInput
          style={
            this.state.showFirstNameError
              ? this.webStyle.inputBoxFirstNameError
              : this.webStyle.inputBoxFirstName
          }
          fullWidth={true}
          className="input-boxes"
          placeholder={"First Name*"}
          value={this.state.firstName}
          name="firstName"
          data-testId="firstNameHr"
          onChange={this.handleInput}
        />
        {this.state.showFirstNameError ? (
          <Typography style={webStyle.errorMessage}>
            {" "}
            {this.state.errorFirstName}{" "}
          </Typography>
        ) : (
          ""
        )}
      </>
    );
  };

  lastNameForm = () => {
    return (
      <>
        <OutlinedInput
          style={
            this.state.showLastNameError
              ? this.webStyle.inputBoxLastNameError
              : this.webStyle.inputBoxLastName
          }
          placeholder={"Last Name*"}
          fullWidth={true}
          value={this.state.lastName}
          name="lastName"
          onChange={this.handleInput}
          data-testId="lastNameHr"
        />
        {this.state.showLastNameError
          ? this.HandleErrorMessage(this.state.errorLastName)
          : ""}
      </>
    );
  };

  phoneNumberForm = () => {
    return (
      <>
        <div className="phoneNumberForm">
          <Select
            style={{
              borderRadius: "10px",
              backgroundColor: "#eaeef8",
              height: "57px",
              marginRight: "10px",
              width: "95px",
              textAlign: "center",
              marginTop: "25px"
            }}
            className={this.state.countryCodeError ? "error-border" : ""}
            value={this.state.selectedCountryCode}
            onChange={(e: any) => {
              this.setState({
                selectedCountryCode: e.target.value,
                countryCodeError: ""
              });
            }}
          >
            {this.phoneNumberArray.map(item => {
              return (
                <MenuItem
                  value={item.countryCode}
                  key={item.countryName + "phone-country-dropdown"}
                  style={{ display: "flex" }}
                >
                  <img src={item.flag} /> {item.countryCode}
                </MenuItem>
              );
            })}
          </Select>

          <OutlinedInput
            style={
              this.state.showPhoneError
                ? this.webStyle.inputBoxPhoneError
                : this.webStyle.inputBoxPhone
            }
            placeholder={"Phone number (Mobile)*"}
            onChange={this.handleInput}
            fullWidth={true}
            value={this.state.phone}
            name="phone"
            type="number"
            data-testId="phoneHr"
          />
        </div>
        {this.state.countryCodeError
          ? this.HandleErrorMessage(this.state.countryCodeError)
          : ""}
        {this.state.showPhoneError
          ? this.HandleErrorMessage(this.state.errorPhone)
          : ""}
      </>
    );
  };

  emailForm = () => {
    return (
      <>
        <OutlinedInput
          style={
            this.state.showEmailErrorHR
              ? this.webStyle.inputBoxesErrorHR
              : this.webStyle.inputBoxesHR
          }
          placeholder={"Email address*"}
          value={this.state.email}
          name="email"
          fullWidth={true}
          onChange={e =>
            this.handleInput({
              target: { name: "email", value: e.target.value.replace(/ /g, "") }
            })
          }
          data-testId="emailErrorTestHR"
        />
        {this.state.showEmailErrorHR
          ? this.HandleErrorMessage(this.state.emailErrorHR)
          : ""}
      </>
    );
  };

  passwordForm = () => {
    return (
      <>
        <OutlinedInput
          style={
            this.state.showPasswordErrorHR
              ? this.webStyle.inputBoxesErrorHR
              : this.webStyle.inputBoxesHR
          }
          fullWidth={true}
          placeholder={"Password*"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={this.handleClickShowPasswordHR}
                edge="end"
                data-testId="visibility"
              >
                {this.state.enablePasswordFieldHR ? (
                  <VisibilityOff />
                ) : (
                  <Visibility />
                )}
              </IconButton>
            </InputAdornment>
          }
          type={this.state.enablePasswordFieldHR ? "password" : "text"}
          value={this.state.password}
          name="password"
          onChange={e =>
            this.handleInput({
              target: {
                name: "password",
                value: e.target.value.replace(/ /g, "")
              }
            })
          }
          data-testId="passwordTestHr"
        />
        {this.state.showPasswordErrorHR ? (
          this.HandleErrorMessage(this.state.passwordErrorHR)
        ) : (
          <Typography
            style={{
              fontSize: "14px",
              fontFamily: "'Roboto', sans-serif",
              color: "#bbbbbb"
            }}
          >
            {" "}
            {this.passwordText}{" "}
          </Typography>
        )}
      </>
    );
  };

  confirmPasswordForm = () => {
    return (
      <>
        <OutlinedInput
          style={
            this.state.showConfirmPasswordError
              ? this.webStyle.inputBoxConfirmPasswordError
              : this.webStyle.inputBoxConfirmPassword
          }
          fullWidth={true}
          placeholder={"Confirm Password*"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={this.handleClickShowConfirmPasswordHR}
                edge="end"
              >
                {this.state.enableReTypePasswordFieldHR ? (
                  <VisibilityOff />
                ) : (
                  <Visibility />
                )}
              </IconButton>
            </InputAdornment>
          }
          value={this.state.reTypePassword}
          type={this.state.enableReTypePasswordFieldHR ? "password" : "text"}
          onChange={e =>
            this.handleInput({
              target: {
                name: "reTypePassword",
                value: e.target.value.replace(/ /g, "")
              }
            })
          }
          data-testId="confirmPasswordTestHr"
          name="reTypePassword"
        />
        {this.state.showConfirmPasswordError
          ? this.HandleErrorMessage(this.state.confirmPasswordMismatch)
          : ""}
      </>
    );
  };

  companyForm = () => {
    return (
      <>
        <OutlinedInput
          style={
            this.state.showCompanyError
              ? this.webStyle.inputBoxCompanyError
              : this.webStyle.inputBoxCompany
          }
          fullWidth={true}
          placeholder={"Company/Facility Name*"}
          data-testId="companyTestHr"
          value={this.state.company}
          onChange={this.handleInput}
          name="company"
        />
        {this.state.showCompanyError
          ? this.HandleErrorMessage(this.state.errorCompany)
          : ""}
      </>
    );
  };

  taxForm = () => {
    return (
      <>
        <OutlinedInput
          style={
            this.state.showTaxError
              ? this.webStyle.inputBoxTaxError
              : this.webStyle.inputBoxTax
          }
          fullWidth={true}
          placeholder={"Tax ID*"}
          name="taxId"
          onChange={this.handletaxId}
          // onInput={this.handleTaxId}
          value={this.state.taxId}
          data-testId="taxTestHr"
        />
        {this.state.showTaxError
          ? this.HandleErrorMessage(this.state.errorTax)
          : ""}
      </>
    );
  };

  checkboxForm = () => {
    return (
      <div style={this.webStyle.mainCheckbox}>
        <div>
          <Checkbox
            data-testId="tandcCheckboxBtn"
            onClick={() => this.setRememberMe(!this.state.checkedTandC)}
            checked={this.state.checkedTandC}
            style={this.webStyle.checkbox}
          />{" "}
        </div>
        <div style={this.webStyle.checboxText}>
          By clicking, you agree to the the{" "}
          <a
            data-test-id="terms-and-condition-btn"
            onClick={() => this.handleModal("showModalTerms", true)}
            style={this.webStyle.tncText}
          >
            Terms & Conditions
          </a>{" "}
          and{" "}
          <a
            // onClick={this.handleOpenModalPrivacy}
            onClick={() => this.handleModal("showModalPrivacy", true)}
            style={this.webStyle.tncText}
          >
            Privacy Policy
          </a>{" "}
          of Benefit Logistics
        </div>
      </div>
    );
  };

  ssnForm = () => {
    return (
      <>
        {!this.state.showSsnErrorTax ? <OutlinedInput
          data-testId="ssnTestHr"
          style={
            this.state.showSsnErrorEmpty
              ? this.webStyle.inputBoxSsnError
              : this.webStyle.inputBoxSsn
          }
          fullWidth={true}
          placeholder={"SSN*"}
          value={this.state.ssnId}
          name="ssnId"
          onChange={this.handleSsnId}
        /> : ""}
        {this.state.showSsnErrorEmpty && !this.state.showSsnErrorTax
          ? this.HandleErrorMessage("Please enter your SSN No.")
          : ""}
        {this.state.showSsnErrorTax ? (
          <>
            <Typography
              style={{
                color: "#ff6666",
                fontSize: "14px",
                marginTop: "6px",
                fontFamily: "'Roboto', sans-serif"
              }}
            >
              {this.state.errorSsnTax}
            </Typography>
            <OutlinedInput
              style={
                this.state.showTaxError
                  ? this.webStyle.inputBoxTaxError
                  : this.webStyle.inputBoxTax
              }
              placeholder={"Tax ID*"}
              onChange={this.handletaxId}
              value={this.state.taxId}
              fullWidth={true}
              name="taxId"
              data-testId="taxSsn"
            />
            {this.state.showTaxError ? (
              <Typography
                style={{
                  color: "#ff6666",
                  fontSize: "14px",
                  paddingTop: "5px"
                }}
              >
                {" "}
                {this.state.errorTax}{" "}
              </Typography>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  employeeHRForm = () => {
    return (
      <div>
        {this.firstNameForm()}

        {this.lastNameForm()}

        {this.phoneNumberForm()}

        {this.emailForm()}

        {this.passwordForm()}

        {this.confirmPasswordForm()}

        {this.companyForm()}

        {this.taxForm()}

        {this.checkboxForm()}

        <Button
          variant="contained"
          // type="submit"
          style={this.webStyle.registerFormButton}
          onClick={this.registerButtonValidation}
          id="registerButtonTest"
          data-testid="registrationButtonTest"
        >
          Register
        </Button>

        {this.checkBoxError()}
      </div>
    );
  };

  employeeMemberForm = () => {
    return (
      <div>
        {this.firstNameForm()}

        {this.lastNameForm()}

        {this.phoneNumberForm()}

        {this.emailForm()}

        {this.passwordForm()}

        {this.confirmPasswordForm()}

        {this.companyForm()}

        {this.ssnForm()}

        {this.checkboxForm()}

        <Button
          variant="contained"
          type="submit"
          style={this.webStyle.registerFormButton}
          id="registerButtonTest"
          onClick={this.registerButtonValidation}
          data-testid="registrationButtonTest"
        >
          Register
        </Button>
        {this.checkBoxError()}
      </div>
    );
  };

  tpaForm = () => {
    return (
      <div>
        {this.firstNameForm()}

        {this.lastNameForm()}

        {this.phoneNumberForm()}

        {this.emailForm()}

        {this.passwordForm()}

        {this.confirmPasswordForm()}

        {this.companyForm()}

        {this.taxForm()}

        {this.checkboxForm()}

        <Button
          variant="contained"
          type="submit"
          style={this.webStyle.registerFormButton}
          data-testid="registrationButtonTest"
          onClick={this.registerButtonValidation}
          id="registerButtonTest"
        >
          Register
        </Button>

        {this.checkBoxError()}
      </div>
    );
  };

  brokerForm = () => {
    return (
      <div>
        {this.firstNameForm()}

        {this.lastNameForm()}

        {this.phoneNumberForm()}

        {this.emailForm()}

        {this.passwordForm()}

        {this.confirmPasswordForm()}

        {this.companyForm()}

        {this.taxForm()}

        {this.checkboxForm()}

        <Button
          onClick={this.registerButtonValidation}
          id="registerButtonTest"
          data-testid="registrationButtonTest"
          type="submit"
          style={this.webStyle.registerFormButton}
          variant="contained"
        >
          Register
        </Button>

        {this.checkBoxError()}
      </div>
    );
  };

  costForm = () => {
    return (
      <div>
        {this.firstNameForm()}

        {this.lastNameForm()}

        {this.phoneNumberForm()}

        {this.emailForm()}

        {this.passwordForm()}

        {this.confirmPasswordForm()}

        {this.companyForm()}

        {this.taxForm()}

        {this.checkboxForm()}

        <Button
          data-testid="registrationButtonTest"
          onClick={this.registerButtonValidation}
          id="registerButtonTest"
          type="submit"
          variant="contained"
          style={this.webStyle.registerFormButton}
        >
          Register
        </Button>

        {this.checkBoxError()}
      </div>
    );
  };

  healthCareForm = () => {
    return (
      <div>
        {this.firstNameForm()}

        {this.lastNameForm()}

        {this.phoneNumberForm()}

        {this.emailForm()}

        {this.passwordForm()}

        {this.confirmPasswordForm()}

        {this.companyForm()}

        {this.taxForm()}

        {this.checkboxForm()}

        <Button
          data-testid="registrationButtonTest"
          id="registerButtonTest"
          onClick={this.registerButtonValidation}
          type="submit"
          style={this.webStyle.registerFormButton}
          variant="contained"
        >
          Register
        </Button>

        {this.checkBoxError()}
      </div>
    );
  };

  stopLossForm = () => {
    return (
      <div>
        {this.firstNameForm()}

        {this.lastNameForm()}

        {this.phoneNumberForm()}

        {this.emailForm()}

        {this.passwordForm()}

        {this.confirmPasswordForm()}

        {this.companyForm()}

        {this.taxForm()}

        {this.checkboxForm()}

        <Button
          type="submit"
          style={this.webStyle.registerFormButton}
          data-testid="registrationButtonTest"
          onClick={this.registerButtonValidation}
          id="registerButtonTest"
          variant="contained"
        >
          Register
        </Button>

        {this.checkBoxError()}
      </div>
    );
  };

  checkBoxError = () => {
    if (this.state.checkboxError) {
      return (
        <div>
          <img
            style={{
              width: "14px",
              marginRight: "5px",
              marginTop: "6px"
            }}
            src={assetImages.warning}
          />
          <Typography
            style={{
              color: "#ff6666",
              fontSize: "14px",
              marginTop: "6px",
              fontFamily: "'Roboto', sans-serif",
              display: "inline"
            }}
          >
            You cannot progress without accepting Terms & Conditions and
            Privacy Policy
          </Typography>
        </div>
      )
    }
  }

  renderForm() {
    let formFields;
    switch (this.state.selectedOption) {
      case "Employer (HR)":
        formFields = this.employeeHRForm();

        break;
      case "Employee (Member)":
        formFields = this.employeeMemberForm();
        break;
      case "Client/ TPA/ Benefit Administrator":
        formFields = this.tpaForm();
        break;
      case "Broker/Agent":
        formFields = this.brokerForm();
        break;
      case "Cost Containment (Nurse)":
        formFields = this.costForm();
        break;
      case "Healthcare Provider (Doctor Office)":
        formFields = this.healthCareForm();
        break;
      case "Stop Loss Carrier":
        formFields = this.stopLossForm();
        break;
      default:
        formFields = null;
    }

    return (
      <div>
        <form>{formFields}</form>
      </div>
    );
  }

  render() {
    const { showModalTerms, showModalPrivacy, headingText, showForm, selectedOption, subLineText, userSignupResponse } = this.state;

    return (
      //Required for all blocks
      <div className="registration-screen-main">
        <ThemeProvider theme={theme}>
          <Grid container className="main-container">
            <Grid item xs={12} md={6}>
              <Paper
                style={webStyle.paperImagesContainer}
                className="paper-images-container p-relative h-100p w-100p"
              >
                {selectedOption ? (
                  <img
                    src={landingImage.landingImage2}
                    className="w-100p h-100p"
                  />
                ) : (
                  <img
                    src={landingImage.landingImage1}
                    className="w-100p h-100p"
                  />
                )}
              </Paper>
            </Grid>

            <Grid item xs={12} md={6} style={{ minHeight: "100vh" }}>
              <Grid
                container
                style={webStyle.logoAndBackContainer}
                className="logo-and-back-container"
              >
                <Grid item xs={6}>
                  <Button
                    onClick={this.handleBackClick}
                    data-testId="backButton"
                  >
                    <img className="w-75p" src={landingImage.backButton} />
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  justifyContent="flex-end"
                  className="d-flex"
                >
                  <img
                    src={landingImage.imgCompanyLogo}
                    className="mr-40 w-75p"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={webStyle.formBox}
                className="main-form-div"
              >
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    component="h2"
                    style={webStyle.createAccountTitle}
                    className="create-account-title"
                  >
                    {headingText}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    style={webStyle.createAccountHeader}
                    className="create-account-header"
                  >
                    {subLineText}
                  </Typography>
                  <Box sx={{ width: "100%" }} data-testId="selectOption">
                    {userSignupResponse.data ?
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <ReactCodeInput
                              type="text"
                              fields={4}
                              disabled={this.state.attemptsOver}
                              onChange={this.setOtpValue}
                              className={`w-100p otp-field ${this.state.otpEr &&
                                "otp-field-red-border"}`}
                                inputMode="numeric"
                                name="OTP input"
                                value={this.state.otp}
                                data-test-id="txtInputOTP"
                            />
                          </Grid>
                          <Grid item xs={12} className="error-box">
                            <Typography
                              component="div"
                              className="text-info-red"
                              variant="subtitle1"
                            >
                              {this.state.otpEr}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} justifyContent="flex-end">
                            <Typography
                              className="text-info"
                              component="div"
                              align="right"
                              variant="subtitle1"
                            >
                              {this.checkCondition(
                                this.state.otpTimer > 0,
                                `00:${this.getStringTime(
                                  this.state.otpTimer
                                )}`,
                                <Button
                                  data-test-id="resendOTPBtn"
                                  variant="text"
                                  onClick={this.ResendOTPHandle}
                                  className="resend-code"
                                >
                                  {configJSON.resendOtp}
                                </Button>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      : this.selectorContainer()}
                    {showForm && this.renderForm()}
                    {userSignupResponse.data &&
                      <Grid item xs={12}>
                        <Button
                          data-test-id={"forgotPasswordBtn"}
                          variant="contained"
                          color="primary"
                          fullWidth
                          className="send-otp-btn"
                          onClick={this.handleVerifyOtp}
                          disabled={this.state.isBTNDisabled}
                        >
                          Verify Now
                        </Button>
                      </Grid>
                    }
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <div>
              <GenericDialog
                title={configJSON.termsAndConditions}
                open={showModalTerms}
                handleClose={() => this.handleModal("showModalTerms", false)}
                contains={<TermsAndConditionsTextComponent />}
                maxWidth="lg"
                hideActions
                hideCloseDialog
                scroll="paper"
              />
            </div>
            <div>
              <GenericDialog
                title={configJSON.privacyPolicy}
                open={showModalPrivacy}
                handleClose={() => this.handleModal("showModalPrivacy", false)}
                contains={<TermsAndConditionsTextComponent />}
                maxWidth="lg"
                hideActions
                hideCloseDialog
                scroll="paper"
              />
            </div>
          </Grid>
        </ThemeProvider>
      </div>
    );
  }
}

const webStyle: any = {
  formBox: {
    width: "60%",
    margin: "0 auto",
    paddingTop: "120px"
  },
  mainBody: {
    display: "flex",
    flexGrow: 1,
    width: "100%",
    height: "auto"
  },
  logInContainer: {
    display: "flex",
    justifyContent: "center",
    width: "60%",
    height: "100%",
    backgroundColor: "#FFFFFF"
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "13% 19% 0%",
    justifyContent: "space-evenly"
  },
  backButton: {
    borderRadius: "50%",
    position: "absolute",
    top: "3%",
    left: "51%"
  },
  paperImagesContainer: {
    borderRadius: "10px 0px 0px 10px",
    justifyContent: "flex-start",
    display: "flex"
  },
  companyLogoAndBackButtonContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  createAccountHeader: {
    fontFamily: "'Roboto', sans-serif",
    color: "#3C4557",
    fontSize: "20"
  },
  createAccountTitle: {
    fontFamily: "'Darker Grotesque', sans-serif",
    fontWeight: "600",
    color: "#212330",
    fontSize: "44"
  },
  container: {
    alignItems: "flex-start",
    height: "100%"
  },
  companylogo: {
    width: "145px",
    height: "47px",
    marginLeft: "78%",
    marginTop: "3%"
  },
  dropdownSelect: {
    width: "400px",
    backgroundColor: "#f4f6fb",
    borderRadius: "10px",
    height: "51px",
    border: "none",
    fontFamily: "'Roboto', sans-serif",
    fontStyle: "normal",
    fontSize: "18px",
    color: "#7a7b7f",
    marginTop: "8px"
    // marginLeft: '20px',
  },
  optionsDropdown: {
    fontFamily: "'Roboto', sans-serif",
    fontStyle: "normal",
    fontSize: "18px"
  },
  dropdownInputLabel: {
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    color: "#7a7b7f",
    marginTop: "10px",
    marginLeft: "20px"
  },

  dotContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    cursor: "pointer",
    backgroundColor: "#bbb"
  },
  activeDot: {
    backgroundColor: "#555"
  },
  inputBoxes: {
    border: "2px",
    borderRadius: "7px",
    backgroundColor: "#f5f6fb"
  },
  modalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: 600,
    backgroundColor: "#ffffff",
    boxShadow: 24,
    p: 4,
    padding: "30px",
    paddingTop: "15px",
    borderRadius: "10px"
  },
  crossButton: {
    width: "24px",
    height: "24px",
    marginTop: "10px",
    cursor: "pointer"
  },
  heading: {
    variant: "h4",
    component: "h2",
    gutterBottom: true,
    fontSize: "32px",
    fontFamily: "Montserrat, sans-serif"
  },
  contentContainer: {
    maxHeight: "60vh",
    overflowY: "auto",
    scrollbarWidth: "thin",
    scrollbarColor: "#888888 #f0f0f0",
    "&::-webkit-scrollbar": {
      width: "3px",
      marginRight: "2px"
    },
    "&::-webkit-scrollbar-track": {
      background: "#f0f0f0"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888888",
      borderRadius: "4px"
    }
  },
  modalStyle: {
    padding: "10px",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    borderRadius: "8px",
    width: "100%",
    fontFamily: "'Roboto', sans-serif",
    fontStyle: "normal",
    cursor: "pointer",
    position: "absolute",
    backgroundColor: "white",
    zIndex: 1
  },
  selectorContainer: {
    borderWidth: 1,
    borderColor: "#000",
    background: "#eaeef8",
    height: "57px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "10px",
    padding: "0 8px 0 8px",
    marginTop: "10px"
  },
  selectorValue: {
    fontFamily: "'Roboto', sans-serif",
    fontStyle: "normal",
    marginLeft: "10px",
    color: "black"
  },
  apiValuesName: {
    marginTop: "12px",
    marginLeft: "12px",
    marginBottom: "12px"
  },
  logoAndBackContainer: {
    padding: "20px"
  },
  errorMessage: {
    color: "#ff6666",
    fontSize: "14px",
    paddingTop: "5px"
  },
  checkBoxErrorImage: {
    width: "17px",
    marginRight: "5px",
    marginTop: "8px"
  }
};
