import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Box } from '@material-ui/core'

const useColorlibStepIconStyles =  makeStyles({
    main: {
        display: "flex",
        alignItems: "center"
    },
    root: {
        backgroundColor: "#FFF",
        zIndex: 1,
        color: "#CCC",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        border: "2.5px solid #CCC",
        fontWeight: 700,
        fontSize: 22
    },
    active: {
        backgroundColor: "#4166F8",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
        border: "2px solid #FFF",
        color: "#FFF"
    },
    completed: {
        color: "#3BB964",
        border: "2px solid #3BB964"
    },
    mainlabel: {
        fontFamily: "Poppins",
        color: "#7583A0",
        fontSize: 16,
        fontWeight: 600
    },
    mainlabelcompleted: {
        fontFamily: "Poppins",
        color: "#7583A0",
        fontSize: 16,
        fontWeight: 500
    },
    mainlabelactive: {
        fontFamily: "Poppins",
        color: "#4166F8",
        fontSize: 16,
        fontWeight: 500
    },
    mainlabelsecond: {
        fontFamily: "Poppins",
        color: "#C2CFE1",
        fontSize: 16,
        fontWeight: 400
    }
});


const ColorlibStepIcon = (props: any) => {
    
    

    const classes = useColorlibStepIconStyles();
    const { active, completed, itemProp} = props;
   

    const getStepIconClass = () => {
        let stepIconClass = classes.root;
        if (active) {
            stepIconClass += ' ' + classes.active;
        }
        if (completed) {
            stepIconClass += ' ' + classes.completed;
        }
        return stepIconClass;
    };



    return (
        <Grid container spacing={2}>
            <Grid item>
                <div className={getStepIconClass()}>
                    {itemProp[String(props.icon)]?.name}
                </div>
            </Grid>
            <Grid item>
                <Typography className={`${classes.mainlabel} ${active && classes.mainlabelactive} ${completed && classes.mainlabelcompleted}`}>
                    {itemProp[String(props.icon)]?.label}
                </Typography>
                    <Typography className={classes.mainlabelsecond}>
                        {itemProp[String(props.icon)]?.des}
                    </Typography>
            </Grid>
        </Grid>
    );

}

export default ColorlibStepIcon