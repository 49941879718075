import React from "react";
import {
  Grid,
  Card,
  Button,
  MenuItem,
  Select,
  FormControl
} from "@material-ui/core";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import Chart from "react-apexcharts";
import PieChart from "../../../components/src/PieChart.web";
import "./EmployerDetails.web.css";
import DashboardController, {
  ITimeFilterItem,
  IMetricsCard,
  Props,
  IOtherFilterItem
} from "./DashboardController";
import {
  companyIcon,
  dropdownIcon,
  editIcon,
  groupIcon,
  increaseIcon,
  solidarityIcon,
  customerIcon,
  decreaseIcon
} from "./assets";
import EditEmployerDetailsDialog from "./EditEmployerDetailsDialog.web";
import GenericSuccessDialog from "../../../components/src/GenericSuccessDialog.web";

export const MetricsCard = (metricsProps: IMetricsCard) => {
  const {
    title,
    count,
    description,
    iconType,
    isIncreased,
    premiums,
    claims
  } = metricsProps;

  const setMetricsIcon = () => {
    let metricsLogo: string = groupIcon;
    if (iconType === "solidarity") {
      metricsLogo = solidarityIcon;
    } else if (iconType === "group") {
      metricsLogo = customerIcon;
    }
    return metricsLogo;
  };
  return (
    <Card className="metrics-card" data-test-id="metrics-card">
      <div className="metrics-top-row">
        <div className="metrics-top-text">
          <div className="metrics-header">{title}</div>
          {iconType === "chart" ? (
            <div>
              <div className="total-claims">
                <span />
                Total Claims
              </div>
              <div className="total-premiums">
                <span />
                Total Premiums
              </div>
            </div>
          ) : (
            <div className="metrics-value">{(title === "Total Claims" || title === "Total Premium") ? `$${count}` :count}</div>
          )}
        </div>
        {iconType === "chart" ? (
          <div className="metrics-chart-section">
            <PieChart
              labels={["Premiums", "Claims"]}
              series={[premiums, claims] as number[]}
              colors={["#b655e4", "#e690f3"]}
            />
          </div>
        ) : (
          <div className="metrics-logo-section">
            <img src={setMetricsIcon()} height={45} />
          </div>
        )}
      </div>
      {iconType !== "chart" && (
        <div className="metrics-description-wrapper">
          <img src={isIncreased ? increaseIcon : decreaseIcon} height={14} />
          <span className="metrics-description">{description}</span>
        </div>
      )}
    </Card>
  );
};

export default class EmployerDetails extends DashboardController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div data-test-id="emp-details-wrapper">
        <Card className="emp-details-top-section-card">
          <Grid container className="emp-details-top-section" spacing={2}>
            <Grid item lg={5} md={12} sm={12}>
              <div className="emp-name-blocks">
                <img src={companyIcon} height={80} />
                <div className="emp-name-address-block">
                  <div className="emp-name-edit-block">
                    <div className="emp-name">Infotech pvt ltd</div>
                    <Button
                      className="emp-edit-btn"
                      data-test-id="edit-btn"
                      onClick={this.toggleEditEmployerDialog}
                    >
                      <img src={editIcon} height={12} />
                      <span className="emp-edit-btn-text">Edit</span>
                    </Button>
                  </div>
                  <div className="emp-address">8 Moretown, Mountain Road</div>
                </div>
              </div>
            </Grid>
            <Grid item lg={7} md={12} sm={12}>
              <div className="emp-other-details-section">
                <div className="emp-other-details-block">
                  <Grid container>
                    <Grid item xs={4} className="emp-other-details-item">
                      <div className="emp-other-item-title">Phone Number</div>
                      <div className="emp-other-item-value">9201589852</div>
                    </Grid>
                    <Grid item xs={4} className="emp-other-details-item">
                      <div className="emp-other-item-title">Effective Date</div>
                      <div className="emp-other-item-value">28-09-23</div>
                    </Grid>
                    <Grid item xs={4} className="emp-other-details-item">
                      <div className="emp-other-item-title">
                        Termination Date
                      </div>
                      <div className="emp-other-item-value">30-09-23</div>
                    </Grid>
                  </Grid>
                  <Grid container className="emp-other-details-bottom-wrapper">
                    <Grid item xs={6} className="emp-other-details-item">
                      <div className="emp-other-item-bottom-title">
                        Contact Name 1 & Email Address
                      </div>
                      <div className="emp-other-item-bottom-value1">
                        Mark Evans
                      </div>
                      <div className="emp-other-item-bottom-value2">
                        markevan878@gmail.com
                      </div>
                    </Grid>
                    <Grid item xs={6} className="emp-other-details-item">
                      <div className="emp-other-item-bottom-title">
                        Contact Name 2 & Email Address
                      </div>
                      <div className="emp-other-item-bottom-value1">
                        Mark Evans
                      </div>
                      <div className="emp-other-item-bottom-value2">
                        markevan878@gmail.com
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="emp-dropdown-btn-block">
                  <Button
                    data-test-id="toggle-emp-full-details"
                    onClick={this.toggleEmployerFullDetails}
                  >
                    <img
                      className={
                        this.state.isOpenEmployerFullDetails ? "up-arrow" : ""
                      }
                      src={dropdownIcon}
                      height={32}
                    />
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
          {this.state.isOpenEmployerFullDetails && (
            <div data-test-id="emp-full-details-wrapper">
              <div className="emp-full-details-section">
                <div className="emp-full-detail-grid-item">
                  <div className="emp-full-detail-grid-content">
                    <div className="emp-full-detail-section-title">
                      Employer ID
                    </div>
                    <div className="emp-full-detail-section-value">
                      XYZ Group
                    </div>
                  </div>
                </div>
                <div className="emp-full-detail-grid-item">
                  <div className="emp-full-detail-grid-content">
                    <div className="emp-full-detail-section-title">
                      Client Name
                    </div>
                    <div className="emp-full-detail-section-value">
                      XYZ Location
                    </div>
                  </div>
                </div>
                <div className="emp-full-detail-grid-item">
                  <div className="emp-full-detail-grid-content">
                    <div className="emp-full-detail-section-title">
                      Other ID
                    </div>
                    <div className="emp-full-detail-section-value">560785</div>
                  </div>
                </div>
                <div className="emp-full-detail-grid-item">
                  <div className="emp-full-detail-grid-content">
                    <div className="emp-full-detail-section-title">
                      Other Desc
                    </div>
                    <div className="emp-full-detail-section-value">24567</div>
                  </div>
                </div>
              </div>
              <div className="emp-full-details-address-section">
                <div className="emp-full-details-address-wrapper physical">
                  <div className="emp-address-title">Physical Address</div>
                  <div className="emp-address-street">99 King Street</div>
                  <div className="emp-address-description">
                    City/Town: St Augustine | State/Country: Florida |
                    Zip/Postal Code: 32085 | Country: USA
                  </div>
                </div>
                <div className="emp-full-details-address-wrapper mailing">
                  <div className="emp-address-title">Mailing Address</div>
                  <div className="emp-address-street">100 Queen Street</div>
                  <div className="emp-address-description">
                    City/Town: St Augustine | State/Country: Florida |
                    Zip/Postal Code: 32085 | Country: USA
                  </div>
                </div>
              </div>
            </div>
          )}
        </Card>
        <div className="metrics-section">
          <div className="metrics-header-section">
            <div className="metrics-header">Metrics</div>
            <div className="metrics-filter">
              <FormControl variant="outlined">
                <Select
                  IconComponent={dropdownProps => (
                    <KeyboardArrowDownRoundedIcon {...dropdownProps} />
                  )}
                  value={this.state.selectedMetricsFilter}
                  onChange={e => this.handleChangeFilter(e, "metrics")}
                  label="Metrics Filter"
                  className="metrics-filter-dropdown"
                  data-test-id="metrics-filter"
                >
                  {this.timeFilterList.map((filterItem: ITimeFilterItem) => (
                    <MenuItem
                      value={filterItem.value}
                      key={filterItem.name + " metrics-filter"}
                    >
                      {filterItem.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="metrics-card-container">
            <Grid container spacing={2}>
              {this.state.metricsDataList.map((item: IMetricsCard) => (
                <Grid
                  item
                  xl={3}
                  lg={4}
                  md={6}
                  sm={6}
                  xs={12}
                  key={item.title + "metrics-tile"}
                >
                  <MetricsCard {...item} />
                </Grid>
              ))}
            </Grid>
          </div>
          <div className="metrics-chart-container">
            <Grid container spacing={2}>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Card className="metrics-chart-card">
                  <div className="metrics-chart-header-wrapper">
                    <div className="metrics-chart-header">
                      <div className="metrics-chart-header-title">
                        Premium vs Claims Report
                      </div>
                      <div className="metrics-chart-header-description">
                        All Groups, Divisions, Locations
                      </div>
                    </div>
                    <div className="metrics-chart-filter-wrapper">
                      <div className="time-filter">
                        <FormControl variant="outlined">
                          <Select
                            IconComponent={premiumTimeFilterProps => (
                              <KeyboardArrowDownRoundedIcon
                                {...premiumTimeFilterProps}
                              />
                            )}
                            value={this.state.selectedPremiumTimeFilter}
                            onChange={e =>
                              this.handleChangeFilter(e, "premium-time")
                            }
                            label="Premium Time Filter"
                            className="metrics-filter-dropdown"
                            data-test-id="premium-time-filter"
                          >
                            {this.timeFilterList.map(
                              (premiumTimeFilterItem: ITimeFilterItem) => (
                                <MenuItem
                                  value={premiumTimeFilterItem.value}
                                  key={
                                    premiumTimeFilterItem.name +
                                    " premium-time-filter"
                                  }
                                >
                                  {premiumTimeFilterItem.name}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="group-filter">
                        <FormControl variant="outlined">
                          <Select
                            IconComponent={premiumOtherFilterProps => (
                              <KeyboardArrowDownRoundedIcon
                                {...premiumOtherFilterProps}
                              />
                            )}
                            value={this.state.selectedPremiumOtherFilter}
                            onChange={e =>
                              this.handleChangeFilter(e, "premium-other")
                            }
                            label="Premium Other Filter"
                            className="metrics-filter-dropdown"
                            data-test-id="premium-other-filter"
                          >
                            {this.otherFilterList.map(
                              (premiumOtherFilterItem: IOtherFilterItem) => (
                                <MenuItem
                                  value={premiumOtherFilterItem.value}
                                  key={
                                    premiumOtherFilterItem.name +
                                    " premium-other-filter"
                                  }
                                >
                                  {premiumOtherFilterItem.name}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className="chart-card-wrapper">
                    <Chart
                      options={{
                        chart: {
                          type: "line",
                          stacked: false,
                          toolbar: {
                            show: false
                          }
                        },
                        colors: ["#895bf1", "#3BB964"],
                        stroke: {
                          show: true,
                          width: [12, 3],
                          colors: ["transparent", "#3BB964"]
                        },
                        markers: {
                          size: 5,
                          colors: "#fff",
                          strokeColors: "#3BB964",
                        },
                        labels: [
                          "Week 1",
                          "",
                          "Week 2",
                          "",
                          "Week 3",
                          "",
                          "Week 4",
                          "",
                          "Week 5"
                        ],
                        plotOptions: {
                          bar: {
                            borderRadius: 12,
                            columnWidth: "90%",
                            borderRadiusApplication: "end"
                          }
                        },
                        xaxis: {
                          type: "category",
                          axisBorder: {
                            show: false,
                          },
                          labels: {
                            offsetX: 10,
                            style: {
                              colors: "#B1B6CD",
                              fontSize: '14px',
                              fontFamily: 'Poppins',
                              fontWeight: 400,
                            },
                          }
                        },
                        yaxis: [
                          {
                            seriesName: "Premium",
                            axisBorder: {
                              show: true
                            },
                            labels: {
                              style: {
                                colors: "#7583A0",
                                fontSize: '14px',
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                              },
                            }
                          },
                          {
                            opposite: true,
                            show: false
                          }
                        ],
                        tooltip: {
                          shared: false,
                          intersect: true,
                          x: {
                            show: false
                          }
                        },
                        legend: {
                          show: false
                        },
                        grid: {
                          show: true,
                          strokeDashArray: 0,
                          xaxis: {
                            lines: {
                              show: true
                            }
                          },
                          yaxis: {
                            lines: {
                              show: true
                            }
                          }
                        }
                      }}
                      series={[
                        {
                          name: "Premium",
                          type: "column",
                          data: [
                            5000,
                            20000,
                            25000,
                            10000,
                            35000,
                            12000,
                            4000,
                            8000,
                            10000,
                            20000
                          ]
                        },
                        {
                          name: "Claims",
                          type: "line",
                          data: [
                            5000,
                            20000,
                            10000,
                            15000,
                            30000,
                            1000,
                            20000,
                            5000,
                            15000,
                            25000
                          ]
                        }
                      ]}
                      height={300}
                    />
                    <div className="premium-charts-legend">
                      <div className="premium-legend"><span></span>Premium</div>
                      <div className="claims-legend"><span></span>Claims</div>
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Card className="metrics-chart-card">
                  <div className="metrics-chart-header-wrapper eligibility-report-header-wrapper">
                    <div className="metrics-chart-header-title">
                      Eligibility Report
                    </div>
                    <div className="metrics-chart-filter-wrapper">
                      <div>
                        <FormControl variant="outlined">
                          <Select
                            IconComponent={eligibilityFilterProps => (
                              <KeyboardArrowDownRoundedIcon
                                {...eligibilityFilterProps}
                              />
                            )}
                            value={this.state.selectedEligibilityFilter}
                            onChange={e =>
                              this.handleChangeFilter(e, "eligibility")
                            }
                            label="Eligibility Filter"
                            className="metrics-filter-dropdown"
                            data-test-id="eligibility-filter"
                          >
                            {this.timeFilterList.map(
                              (eligibilityFilterItem: ITimeFilterItem) => (
                                <MenuItem
                                  value={eligibilityFilterItem.value}
                                  key={
                                    eligibilityFilterItem.name +
                                    " eligibility-filter"
                                  }
                                >
                                  {eligibilityFilterItem.name}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className="chart-card-wrapper">
                    <Chart
                      options={{
                        chart: {
                          type: "line",
                          stacked: false,
                          toolbar: {
                            show: false
                          }
                        },
                        dataLabels: {
                          enabled: false
                        },
                        colors: ["#ff6666", "#4166f8", "#ffcc5d", "#83c5b5"],
                        stroke: {
                          show: true,
                          width: 6,
                          colors: ["transparent"]
                        },
                        plotOptions: {
                          bar: {
                            borderRadius: 4,
                            borderRadiusApplication: "end",
                            columnWidth: "60%",
                          }
                        },
                        xaxis: {
                          categories: [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun"
                          ],
                          axisBorder: {
                            show: false
                          },
                          tickPlacement: "between",
                          labels: {
                            style: {
                              colors: "#B1B6CD",
                              fontSize: '14px',
                              fontFamily: 'Poppins',
                              fontWeight: 400,
                            },
                          }
                        },
                        yaxis: [
                          {
                            seriesName: "Total number of employees",
                            axisTicks: {
                              show: false
                            },
                            axisBorder: {
                              show: false
                            },
                            labels: {
                              style: {
                                colors: "#7583A0",
                                fontSize: '14px',
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                              },
                            }
                          }
                        ],
                        tooltip: {
                          shared: false,
                          intersect: true,
                          x: {
                            show: false
                          }
                        },
                        legend: {
                          show: false
                        }
                      }}
                      series={[
                        {
                          name: "Total number of employees",
                          type: "column",
                          data: [40, 53, 33, 56, 58, 20]
                        },
                        {
                          name: "Enrolled in coverage",
                          type: "column",
                          data: [40, 19, 27, 5, 58, 34]
                        },
                        {
                          name: "Active members",
                          type: "column",
                          data: [12, 47, 20, 56, 42, 30]
                        },
                        {
                          name: "Terminated",
                          type: "column",
                          data: [32, 20, 60, 26, 42, 10]
                        }
                      ]}
                      height={300}
                    />
                    <Grid container className="eligibility-charts-legend">
                      <Grid item xs={6} className="legend-text total-employee-legend"><span></span>Total number of employees</Grid>
                      <Grid item xs={6} className="legend-text enrolled-coverage-legend"><span></span>Enrolled in coverage</Grid>
                      <Grid item xs={6} className="legend-text active-members-legend"><span></span>Active members</Grid>
                      <Grid item xs={6} className="legend-text terminated-legend"><span></span>Terminated</Grid>
                    </Grid>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
        {this.state.isOpenEditEmployerDialog && <EditEmployerDetailsDialog
          isOpen={this.state.isOpenEditEmployerDialog}
          handleClose={this.toggleEditEmployerDialog}
          employerDetail={this.state.employerDetail}
          saveEmployerDetail={this.saveEmployerDetails}
          data-test-id="edit-emp-dialog"
        />}
        <GenericSuccessDialog
          open={this.state.isEmployerDetailsSaved}
          handleDone={this.handleCloseConfirmationDialog}
        />
      </div>
    );
  }
}
