import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Column } from "../../../../components/src/CustomTable.web";
import { runEngine } from "../../../../framework/src/RunEngine";
import { StepList } from "../../../../components/src/CustomVerticalFormStepper.web";
import { AddressDataInterface, ApiDataItem, Data, EmployerData, EmployerListingDetail, IEmployerAddressDetailModel, IEmployerBasicDetailModel, IEmployerDetailModel, IEmployerLogoAndDocModel, IEmployerOtherDetailModel, initialEMPAgentData, initialEmployerDetailModelData } from "./ClientEmployerModel";
import ClientEmployerPlanPage from "./ClientEmployerPlanPage.web";
import { SelectedEmployerInterface } from "../AllRoleLandingPageController.web";

export const configJSON = require("../config");

export interface Props {
  id: string;
  navigation: any;
  saveEmployerData?: any;
  handleSelectedRow: (selectedColumn: {}[]) => void;
  selectedEmployer: SelectedEmployerInterface[]
}
interface RowData {
  id: string;
  employer_name: string;
  address_line1: string;
  city: string;
  state: string;
  zip: string;
  effective_date: Date | string;
  termination_date: Date | string;
  contact_name: string;
  contact_no: string;
  is_active: boolean;
  emp_id?: string;
}

interface S {
  rowData: RowData[];
  employerTableMenuAnchorEl: HTMLElement | null;
  isEdit: boolean;
  isEditEmployerDialogOpen: boolean;
  addressValues: any;
  addEditEmployerStepList: StepList[];
  isEmployerDetailSaved: boolean;
  employerDetails: IEmployerDetailModel;
  tablePageNo: number;
  token: string;
  actionId: string | number;
  editData: {
    employerBasicDetails: IEmployerBasicDetailModel;
    employerAddressDetails: IEmployerAddressDetailModel;
    employerFileDetails: IEmployerLogoAndDocModel;
    employerOtherDetails: IEmployerOtherDetailModel;
    id?: string
  }[];
  basicDataForAPI: EmployerData;
  listOfAllData: Data[];
  clientDetails: { client_name: string, client_id: string };
  page: number;
  totalCount: number;
  deletedSuccessfull:boolean;
}

interface SS {
  id: any;
}

export default class ClientEmployerLandingPageController extends BlockComponent<Props, S, SS
> {
  employersListApiId: string = "";
  employersTableApiId: string = "";
  employerDeleteApiId: string = "";

  tableColumns: Column[] = [
    {
      label: "Employer Name",
      id: "employer_name",
      headerAlign: "left",
      align: "left",
    },
    {
      headerAlign: "left",
      align: "left",
      id: "address_line1",
      label: "Address Line1",
    },
    {
      id: "city",
      align: "left",
      label: "City",
      headerAlign: "left",
    },
    {
      headerAlign: "left",
      id: "state",
      label: "State",
      align: "left",
    },
    {
      id: "zip",
      label: "Zip",
      align: "left",
      headerAlign: "left",
    },
    {
      headerAlign: "left",
      id: "effective_date",
      label: "Effective Date",
      align: "left",
    },

    {
      label: "Termination Date",
      headerAlign: "left",
      id: "termination_date",
      align: "left",
    },
    {
      label: "Contact Name",
      headerAlign: "left",
      id: "contact_name",
      align: "left",
    },
    {
      headerAlign: "left",
      id: "contact_no",
      align: "left",
      label: "Contact No",
    },
    {
      headerAlign: "left",
      id: "is_active",
      align: "left",
      label: "Is Active",
      format: (row: RowData) => {
        return (
          <div className={`employer-isActive-container employer-isActive-${row.is_active}`}>
            {row.is_active === true ? "YES" : "NO"}
          </div>
        );
      },
    },
    {
      id: "action",
      label: "",
      headerAlign: "left",
      align: "right",
      format: (row: RowData) => {
        return (
          <div className="division-table-action-container" key={row.id + 1}>
            <IconButton
              className="menu-btn"
              data-test-id="employer-table-menu"
              onClick={this.handleEmployerOpenTableMenu}
            >
              <MoreVertIcon fontSize="large" />
            </IconButton>
          </div>
        );
      },
    },
  ];

  tabMenuList = [
    "Plan",
    "Medical Review",
    "Enrollment",
    "Enroll Config",
    "Pay Claims",
    "Recon",
    "Invoice",
    "Payroll Invoice",
    "Case Billing",
    "Documents",
  ];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      rowData: [],
      employerTableMenuAnchorEl: null,
      isEdit: false,
      isEmployerDetailSaved: false,
      employerDetails: initialEmployerDetailModelData,
      isEditEmployerDialogOpen: false,
      addressValues: {},
      addEditEmployerStepList: [
        {
          key: 1,
          title: "Employer Basic Details",
          description: "Type and Select details",
          isCompleted: false,
        },
        {
          key: 2,
          title: "Address",
          description: "Type and Select details",
          isCompleted: false,
        },
        {
          key: 3,
          title: "Upload Logo / Supporting Documents",
          description: "Type and Select details",
          isCompleted: false,
        },
        {
          key: 4,
          title: "Other Details",
          description: "Type and Select details",
          isCompleted: false,
        },
      ],
      tablePageNo: 1,
      token: "",
      actionId: "",
      editData: [initialEmployerDetailModelData],
      basicDataForAPI: {},
      listOfAllData: [],
      clientDetails: { client_name: "", client_id: "" },
      page: 1,
      totalCount: 0,
      deletedSuccessfull:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.employersListApiId) {
        this.getEmployerListData(responseJson)
      }
      if (apiRequestCallId === this.employersTableApiId) {
        this.getEmployerTableData(responseJson)
      }
      if (apiRequestCallId === this.employerDeleteApiId) {
        this.setState({deletedSuccessfull:true, employerTableMenuAnchorEl: null});
      }
    } else {
      runEngine.debugLog("GOIT");
    }
  }

  async componentDidMount() {
    const loggedInUser = (typeof localStorage !== undefined && localStorage.getItem("user")) || "";
    if (loggedInUser) {
      const { token, client_name, id } = JSON.parse(loggedInUser);
      this.setState({ token: token, clientDetails: { client_id: id, client_name } })
      this.fetchAllEmployersList(token);
      this.fetchTableEmployersList(token, 1);
    }
  }

  getEmployerListData = (response: any) => {
    if (response && response.data) {
      if (response.data.length > 0) {
        this.extractTableData(response.data);
      }
    } else {
      this.props.navigation.history.push("/");
    }
  }

  getEmployerTableData = (response: { count: number; employers: { data: EmployerListingDetail[] } }) => {
    if (response && response.employers.data.length) {
      const tableDta = response.employers.data.map((item: EmployerListingDetail) => ({
        id: item.id,
        employer_name: item.attributes.employer_name,
        address_line1: item.attributes.physical_address.address_line_1,
        city: item.attributes.physical_address.city,
        state: item.attributes.physical_address.state,
        zip: item.attributes.physical_address.zip,
        effective_date: item.attributes.effective_date,
        termination_date: item.attributes.termination_date,
        contact_name: item.attributes.contact.contact_name,
        contact_no: item.attributes.contact.contact_number,
        is_active: item.attributes.is_active,
      }));
      this.setState({ rowData: tableDta, totalCount: response.count });
    }
  }

  getTabPage = (tab: string) => {
    if (tab === "Plan") {
      return <ClientEmployerPlanPage />
    }

    return null;
  }

  extractBasicDetails(item: ApiDataItem) {
    const { id, attributes } = item;
    const {
      emp_id,
      client_name,
      employer_name,
      effective_date,
      termination_date,
      is_active,
      payroll_day,
      payroll_month,
      payroll_cycle1,
      payroll_cycle2,
      payroll_cycle3,
      payroll_cycle4,
      tax_id,
    } = attributes;

    return {
      emp_id,
      id,
      client_name,
      employer_name,
      effective_date,
      termination_date,
      is_active,
      payroll_day,
      payroll_month,
      payroll_cycle1,
      payroll_cycle2,
      payroll_cycle3,
      payroll_cycle4,
      tax_id,
    };
  }

  extractAddressAndContact(addressData: AddressDataInterface, mailingAddress: AddressDataInterface, contacts: any) {
    const demoData = {
      contact: "",
      email: "",
      phone_number: "",
      fax: ""
    }
    const contact1Data = contacts[0] ? contacts[0].attributes : demoData;
    const contact2Data = contacts[1] ? contacts[1].attributes : demoData;
    return {
      physical_address_line1: addressData.address_line_1 || "",
      physical_address_line2: addressData.address_line_2 || "",
      physical_city: addressData.city || "",
      physical_zip_code: addressData.zip_code || "",
      physical_state_id: addressData.state,
      physical_country_id: addressData.country,
      mailing_address_line1: mailingAddress.address_line_1 || "",
      mailing_address_line2: mailingAddress.address_line_2 || "",
      mailing_city: mailingAddress.city || "",
      mailing_zip_code: mailingAddress.zip_code || "",
      mailing_state_id: mailingAddress.state,
      mailing_country_id: mailingAddress.country,
      mailing_contact1: contact1Data.contact,
      mailing_contact2: contact2Data.contact,
      mailing_email1: contact1Data.email,
      mailing_email2: contact2Data.email,
      mailing_phone_number1: this.handlePhoneValidation(contact1Data.phone_number),
      mailing_phone_number2: this.handlePhoneValidation(contact2Data.phone_number),
      mailing_fax1: contact1Data.fax,
      mailing_fax2: contact2Data.fax,
    };
  }

  handlePhoneValidation = (phone: string) => {
    if (!!phone && phone.length > 2 && phone[0] === "+") {
      return phone.slice(2)
    } else {
      return phone
    }
  }

  extractOtherDetails(otherDetailsData: {}, agents: { attributes: object }[]) {
    const mdAgent = agents.map((item: any, index: number) => ({ ...item.attributes, id: index }));
    return {
      ...otherDetailsData,
      agentsDetails: mdAgent,
    };
  }

  extractContactData(contact: { data: { attributes: { contact: string, phone_number: string } }[] }) {
    if (contact.data && contact.data.length > 0) {
      const contactData = contact.data[0].attributes;
      return {
        contact_name: contactData.contact || "-",
        contact_no: contactData.phone_number || "-",
      };
    }
    return {
      contact_name: "-",
      contact_no: "-",
    };
  }

  extractTableData = (apiData: any) => {
    const EditData: any[] = [];
    const extractedData: { id: any; employer_name: any; is_active: any; effective_date: any; termination_date: any; address_line1: any; city: any; state: any; zip: any; contact_name: any; contact_no: any; client_name: any }[] = [];
    apiData.forEach((item: any) => {
      const { id, attributes } = item;
      const { physical_address, mailing_address, contact, other_details, agents, emp_id } = attributes;
      const addressData = physical_address.data ? physical_address.data.attributes : {};
      const mailingAddress = mailing_address.data ? mailing_address.data.attributes : {};
      const otherDetailsData = other_details.data ? other_details.data.attributes : {};
      const otherAgents = agents && agents.data.length ? agents.data : [initialEMPAgentData];
      const contacts = contact ? contact.data : [];

      const basicDetails = this.extractBasicDetails(item);
      const addressAndContact = this.extractAddressAndContact(addressData, mailingAddress, contacts);
      const otherDetails = this.extractOtherDetails(otherDetailsData, otherAgents);
      const contactData = this.extractContactData(contact);
      const extractedItem = {
        id,
        emp_id,
        employer_name: attributes.employer_name,
        is_active: attributes.is_active,
        client_name: basicDetails.client_name,
        effective_date: basicDetails.effective_date,
        termination_date: basicDetails.termination_date,
        address_line1: addressData.address_line_1 || "-",
        city: addressData.city || "-",
        state: addressData.state || "-",
        zip: addressData.zip_code || "-",
        ...contactData,
      };
      extractedData.push(extractedItem);

      EditData.push({
        employerBasicDetails: basicDetails,
        employerAddressDetails: addressAndContact,
        employerOtherDetails: otherDetails,
        id,
      });
    });


    this.setState({
      ...this.state,
      listOfAllData: apiData,
      editData: EditData,
      employerDetails: {
        ...this.state.employerDetails,
        employerBasicDetails: {
          ...this.state.employerDetails.employerBasicDetails,
          client_name: extractedData[0].client_name,
        }
      }
    });
  }

  handleUpdateList = () => {
    const {token , page} = this.state;
    this.fetchTableEmployersList(token, page);
    this.fetchAllEmployersList(token);
  }

  toggleEditEmployerDialog = (open: boolean, isEdit: boolean) => {
    const { actionId, editData, listOfAllData } = this.state;
    const EditData = editData.find((item) => item.id === actionId);
    const EditAPIData = listOfAllData.find((item) => item.id === actionId)
    let State = {}
    if (!open && !isEdit) {
      this.handleUpdateList();
    }
    if (EditData && isEdit) {
      State = {
        ...this.state,
        isEditEmployerDialogOpen: open,
        employerTableMenuAnchorEl: null,
        isEdit: isEdit,
        employerDetails: { ...EditData },
        basicDataForAPI: { data: EditAPIData }
      }
    } else {
      State = {
        ...this.state,
        isEditEmployerDialogOpen: open,
        employerTableMenuAnchorEl: null,
        isEdit: isEdit
      }
    }
    this.setState(State);
  };
  handleEmployerOpenTableMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ employerTableMenuAnchorEl: event ? event.currentTarget : null, });
  };

  HandleActionButton = (id: string) => {
    this.setState({ actionId: id });
  };

  handleEmployerCloseTableMenu = () => {
    this.setState({ ...this.state, employerTableMenuAnchorEl: null });
  };

  saveEmployerDetails = () => {
    this.setState({ ...this.state, isEmployerDetailSaved: true });
  };

  handlePaginationChange = (page: number) => {
    this.fetchTableEmployersList(this.state.token, page + 1)
  }

  fetchTableEmployersList = (token: string, page: number) => {
    this.setState({ page: page })
    const header = {
      "Content-Type": configJSON.ContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.employersTableApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllEmployersAPIEndPoint}?page=${page}&per_page=7`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchAllEmployersList = (token: string) => {
    const header = {
      "Content-Type": configJSON.ContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.employersListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.employersListGetApiEndPoint}?page=1&per_page=500`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleEmployerTableMenuClick = (id: string) => {
    if (id === "1") {
      this.toggleEditEmployerDialog(true, true);
    }else if(id === "2") {
      this.handleDeleteEmployer(this.state.actionId);
    }
  };

  handleSelected = (data: any[]) => {
    let result: RowData[] = []

    data.forEach((item: RowData) => {
      const detail = this.state.listOfAllData.find(element => element.id === item.id)
      if (detail) {
        const itemResult = {
          ...item,
          emp_id: detail.attributes.emp_id
        }
        result = result.concat(itemResult)
      }
    })
    this.props.handleSelectedRow(result)
  }

  handleSuccessDialogClose = () => {
    this.setState({deletedSuccessfull:false});
    const {rowData, page} = this.state;
    const isPageChange = rowData.length===1&&page!==1;
    if(isPageChange){
      this.handlePaginationChange(page-2);
    }else{
      this.handlePaginationChange(page-1);
    }
  }

  handleDeleteEmployer = (deleteId:string|number) => {
    const {token} = this.state;
    const header = {
      "Content-Type": configJSON.ContentType,
      "token": token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.employerDeleteApiId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.employersListGetApiEndPoint}/${deleteId}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
