// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

export const configJSON = require("./config");
const images = require("./assets");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  token: any;
  phone: string;
  accountType: string;
  accountStatus: any;
  headerTitleLabel: string;
  subHeaderTitleLabel: string;
  emailOrMobileValue: string;
  emailOrMobileError: string;
  emailRegex: RegExp;
  mobileRegex: RegExp;
  passwordRegex: RegExp;
  btnLabel: string;
  isBTNDisabled: boolean;
  forgotPasswordStep: number;
  otpValue: string;
  otpError: string;
  otpTimerValue: number;
  passwordValue: string;
  enablePasswordField: boolean;
  confirmPasswordValue: string;
  enableConfirmPasswordField: boolean;
  confirmPasswordError: string;
  attemptOver:boolean;
  attempts: string;
  attemptMessageShow: boolean;
  selectedCountryCode: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

// Customizable Area Start
export const forgotPasswordStep = {
  first: 1,
  second: 2,
  thired: 3,
  fourth: 4
};

// Customizable Area End

export default class ForgotPasswordBlockController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  requestEmailOtpCallId: any;
  requestChangePasswordAPICallId: any;
  requestGoToConfirmationAPICallId: any;
  otpToken: any;

  private otpTimer: NodeJS.Timeout | any;
  phoneNumberArray = [
    { countryName: "India", countryCode: "+91", flag: images.india },
    { countryName: "US", countryCode: "+1", flag: images.unitedStates },
    { countryName: "UK", countryCode: "+44", flag: images.unitedKingdom }
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.otpTimer = null;

    this.state = {
      token: "",
      accountType: "email",
      accountStatus: "ChooseAccountType",
      headerTitleLabel: configJSON.headerTitleLabel1,
      subHeaderTitleLabel: configJSON.subHeaderTitleLabel1,
      emailOrMobileValue: "",
      emailOrMobileError: "",
      btnLabel: configJSON.sendOTPLabel,
      isBTNDisabled: true,
      forgotPasswordStep: forgotPasswordStep.first,
      otpValue: "",
      otpError: "",
      otpTimerValue: 60,
      passwordValue: "",
      enablePasswordField: false,
      confirmPasswordValue: "",
      enableConfirmPasswordField: false,
      confirmPasswordError: "",
      phone: "",
      selectedCountryCode: "+1",
      emailRegex: new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      ),
      mobileRegex: new RegExp(/^\d{10}$/),
      passwordRegex: new RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?:{}|<>\-_+=/\\~\[\];]).{8,}$/
      ),
      attemptOver:false,
      attemptMessageShow: true,
      attempts:"3 OTP attempts left."
    };

    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    await super.componentDidMount();
    // Customizable Area End
  }

  // Customizable Area Start

  getIfCondition = (condition: boolean, ifTrue: any, ifFales: any) =>
    condition ? ifTrue : ifFales;

  handleBackClick = () => {
    if (this.state.forgotPasswordStep === forgotPasswordStep.first) {
      this.props.navigation.navigate("EmailAccountLoginSignupBlock");
    } else if (
      [
        forgotPasswordStep.second,
        forgotPasswordStep.thired,
        forgotPasswordStep.fourth
      ].includes(this.state.forgotPasswordStep)
    ) {
      this.stopTimer();
      this.setState({
        forgotPasswordStep: forgotPasswordStep.first,
        otpValue: "",
        otpTimerValue: 60,
        passwordValue: "",
        enablePasswordField: false,
        confirmPasswordValue: "",
        enableConfirmPasswordField: false,
        confirmPasswordError: "",
        otpError: "",
        emailOrMobileError: ""
      });
    }
  };

  handleInput = (e: {target:{name:string,value:string}},test:boolean) => {
    const { name, value } = e.target;
    if (name === "phone") {
      if(value.trim().length<=10){
        this.setState({
          phone: value.trim()
        });
        if (
          test &&
          this.state.selectedCountryCode
        ) {
          this.setState({
            isBTNDisabled: false,
            accountType: "phone_number"
          });
        } else {
          this.setState({ isBTNDisabled: true });
        }
      }
    }
  };

  handleResendOTP = () => {
    this.stopTimer();
    this.setState({
      otpTimerValue: 60,
      attemptOver: false,
      attempts: "3 OTP attempts left.",
      otpError: ""
    });

    this.goToOtpAfterEmailOrMobileValidation({
      accountType: this.state.accountType,
      email: this.state.emailOrMobileValue
    });
  };

  startTimer = () => {
    this.otpTimer = setInterval(() => {
      this.setState(
        prevState => ({
          otpTimerValue: prevState.otpTimerValue - 1
        }),
        () => {
          if (this.state.otpTimerValue === 0) {
            this.stopTimer();
          }
        }
      );
    }, 1000);
  };

  stopTimer = () => {
    if (this.otpTimer) {
      clearInterval(this.otpTimer);
    }
  };

  getStringTime = (time: number) => {
    if (time > 9) {
      return time;
    }
    return `0${time}`;
  };

  otpErrorHandling = (attemptMessageShow:boolean) => {
    this.setState({isBTNDisabled:true,attemptOver:true})
  }

  // Web Event Handling

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (prevState.forgotPasswordStep !== this.state.forgotPasswordStep) {
      if (this.state.forgotPasswordStep === forgotPasswordStep.first) {
        this.setState({
          headerTitleLabel: configJSON.headerTitleLabel1,
          subHeaderTitleLabel: configJSON.subHeaderTitleLabel1,
          btnLabel: configJSON.sendOTPLabel,
          isBTNDisabled: !this.state.emailOrMobileValue.length
        });
      } else if (this.state.forgotPasswordStep === forgotPasswordStep.second) {
        this.setState({
          headerTitleLabel: configJSON.headerTitleLabel2,
          subHeaderTitleLabel: this.state.accountType === "email"? 
            configJSON.subHeaderTitleLabel2: configJSON.subHeaderTitleMobileLabel2 + this.state.emailOrMobileValue,
          btnLabel: configJSON.verifyNow,
          isBTNDisabled: true
        });
      } else if (this.state.forgotPasswordStep === forgotPasswordStep.thired) {
        this.setState({
          headerTitleLabel: configJSON.headerTitleLabel3,
          subHeaderTitleLabel: configJSON.subHeaderTitleLabel3,
          btnLabel: configJSON.confirm,
          isBTNDisabled: true
        });
      } else if (this.state.forgotPasswordStep === forgotPasswordStep.fourth) {
        this.setState({
          headerTitleLabel: configJSON.headerTitleLabel4,
          subHeaderTitleLabel: configJSON.subHeaderTitleLabel4,
          btnLabel: configJSON.loginNow,
          isBTNDisabled: false
        });
      }
    }
  }

  setEmailOrMobileValue = (text: string) => {
    this.setState({
      emailOrMobileValue: text
    });
    if (this.state.emailRegex.test(text)) {
      this.setState({
        isBTNDisabled: false,
        accountType: "email"
      });
    } else {
      this.setState({
        isBTNDisabled: true
      });
    }
    if (this.state.emailOrMobileError) {
      this.setState({
        emailOrMobileError: ""
      });
    }
  };

  handleSelectChange = (value:string,phone:string,test:boolean) => {
    if (phone && test) {
      this.setState({
        isBTNDisabled: false,
        accountType: "phone_number"
      });
    }
    this.setState({
      selectedCountryCode: value
    });
  }

  handleErrorClass = (MobileError:string,phone:string) => {
    if(MobileError&&phone){
      return "input-box-red-border"
    }
  }

  setOtpValue = (text: string) => {
    if (text.length === 4) {
      this.setState({
        isBTNDisabled: false
      });
    } else {
      this.setState({
        isBTNDisabled: true
      });
    }
    this.setState({
      otpValue: text,
      otpError: ""
    });
  };

  setPasswordValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      passwordValue: e.target.value
    });
    if (this.state.passwordRegex.test(e.target.value)) {
      this.setState({
        isBTNDisabled: false
      });
    } else {
      this.setState({
        isBTNDisabled: true
      });
    }
  };

  setConfirmPasswordValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      confirmPasswordValue: e.target.value
    });
    if (this.state.passwordValue !== e.target.value) {
      this.setState({
        confirmPasswordError: "Password mismatch, Please try again"
      });
    } else {
      this.setState({
        confirmPasswordError: ""
      });
    }
  };

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField
    });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField
    });
  };

  doOnClickBtn = () => {
    if (this.state.forgotPasswordStep === forgotPasswordStep.first) {
      this.goToOtpAfterEmailOrMobileValidation({
        accountType: this.state.accountType,
        email: this.state.emailOrMobileValue
      });
    } else if (this.state.forgotPasswordStep === forgotPasswordStep.second) {
      this.goToChangePasswordAfterOtp({
        otpCodeValue: this.state.otpValue
      });
    } else if (this.state.forgotPasswordStep === forgotPasswordStep.thired) {
      if (this.state.confirmPasswordError || !this.state.confirmPasswordValue) {
        this.setState({
          confirmPasswordError: "Password mismatch, Please try again"
        });
        return;
      }
      this.goToConfirmationAfterPasswordChange({
        passwordValue: this.state.passwordValue,
        confirmPassword: this.state.confirmPasswordValue
      });
    } else if (this.state.forgotPasswordStep === forgotPasswordStep.fourth) {
      this.props.navigation.navigate("EmailAccountLoginSignupBlock");
    }
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.requestEmailOtpCallId !== null &&
      this.requestEmailOtpCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.receiveRequestEmailOtp(message);
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.requestChangePasswordAPICallId !== null &&
      this.requestChangePasswordAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.receiveRequestChangePasswordAPI(message);
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.requestGoToConfirmationAPICallId !== null &&
      this.requestGoToConfirmationAPICallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.receiveRequestGoToConfirmation(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  goToOtpAfterEmailOrMobileValidation(values: {
    accountType: string;
    email: string;
  }) {
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestEmailOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordStartOtpAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      type: values.accountType,
      attributes:
        values.accountType === "email"
          ? {
              email: values.email
            }
          : {
              full_phone_number: `${this.state.selectedCountryCode}${
                this.state.phone
              }`
            }
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToChangePasswordAfterOtp(values: { otpCodeValue: string }) {
    //change status to change passwordValue
    //change status to OTP
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestChangePasswordAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordConfirmOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      otp_code: values.otpCodeValue ? values.otpCodeValue : "",
      type: this.state.accountType==="phone_number"?"sms":"email"
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToConfirmationAfterPasswordChange(values: {
    passwordValue: any;
    confirmPassword: any;
  }) {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestGoToConfirmationAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordChangePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
      token: this.otpToken ? this.otpToken : "",
      new_password: values.passwordValue
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  receiveRequestEmailOtp(message: Message) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      this.otpToken = responseJson.meta.token;

      this.setState({ token: this.otpToken });

      this.setState({
        forgotPasswordStep: forgotPasswordStep.second
      });
      this.startTimer();
    }
    //error handling
    else if (responseJson && responseJson.errors) {
      this.setState({
        emailOrMobileError: responseJson.errors[0].otp
      });
    } else {
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  receiveRequestChangePasswordAPI(message: Message) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && responseJson.messages) {
      this.setState({
        forgotPasswordStep: forgotPasswordStep.thired
      });
    }
    //error handling
    else if (responseJson && responseJson.errors) {
      this.stopTimer();
      let errorMessage = {otpError: responseJson.errors[0].otp || responseJson.errors[0].pin, attempts: "Wait a min for new OTP, attempts exhausted.",otpTimerValue:60, attemptMessageShow:this.state.attemptMessageShow}
      if(!!responseJson.attempts_left){
        errorMessage.attempts = `${responseJson.attempts_left} OTP attempts left.`
      }else{
        this.otpErrorHandling(true)
      }
      this.setState(errorMessage)
      this.startTimer();
    } else {
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  receiveRequestGoToConfirmation(message: Message) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.serialized_account.data) {
      this.setState({
        accountStatus: "Confirmation",
        forgotPasswordStep: forgotPasswordStep.fourth
      });
    } else if (responseJson && responseJson.errors) {
      this.setState({
        confirmPasswordError: responseJson.errors[0].otp
      });
    } else {
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  // Customizable Area End
}
