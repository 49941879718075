import React from 'react';
import { Grid, Card } from '@material-ui/core';
import './GenericCompanyDetailsCard.css';

interface Props {
  companyName: string;
  companyAddress: string;
  companyIcon: string;
}

const GenericCompanyDetailsCard = (props: Props) => {
  return (
    <Card className="emp-details-top-section-card">
      <Grid container className="emp-details-top-section">
        <Grid item lg={5} md={12} sm={12}>
          <div className="emp-name-blocks">
            <img src={props.companyIcon} height={80} width={80} />
            <div className="emp-name-address-block">
              <div className="emp-name-edit-block">
                <div className="emp-name">{props.companyName}</div>
              </div>
              <div className="emp-address">{props.companyAddress}</div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default GenericCompanyDetailsCard;
