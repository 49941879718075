import { IBlock } from "../../../../framework/src/IBlock";
import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
export const configJSON = require("../config");
import { StepList } from "../../../../components/src/CustomVerticalFormStepper.web";

import {
  APIPostData,
  ClassData,
  ClassItem,
  Countries,
  EditIDs,
  IMemberAddressDetailModel,
  IMemberBasicModel,
  IMemberDetailModel,
  IMemberOtherDetailModel,
  SelectionList,
  States,
  initialMemberDetailModelData
} from "./ClientMemberModel";
import { Message } from "../../../../framework/src/Message";

export interface Props {
  isOpen: boolean;
  isEdit: boolean;
  handleClose: any;
  saveMemberData: any;
  memberDetails: IMemberDetailModel;
  token: string;
  employersList: SelectionList[];
  locationList: SelectionList[];
  editMemberDetails: IMemberDetailModel;
  clientDetails: { client_id: string; client_name: string; accountId: string };
  editMemberIDs: EditIDs;
}

interface S {
  memberActiveStep: number;
  isOpenWarningDialog: boolean;
  editMemberFormStepList: StepList[];
  isOpenGroupSearch: boolean;
  groupSearchInput: string;
  countryList: SelectionList[];
  stateList: SelectionList[];
  mailingStateList: SelectionList[];
  memberData: IMemberDetailModel;
  isSecondStepDirty: boolean;
  isMailingAddressSameAsPhysicalAddress: boolean;
  ApiErrors: { [key: string]: string[] };
  employerList: SelectionList[];
  groupList: SelectionList[];
  divisionList: SelectionList[];
  locationList: SelectionList[];
  classList: SelectionList[];
  memberRelationList: SelectionList[];
  editIds: {
    id: string;
    physicalId: string;
    mailingId: string;
    contactId: string;
    otherId: string;
  };
  isMailingState: boolean;
  planList: SelectionList[];
  coverageList: SelectionList[];
  isMemberCreated: boolean;
  memberBasicSuccess: boolean;
}

interface SS {
  id: any;
}

export default class EditClientMemberDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  countryCallId: string = "";
  stateCallId: string = "";
  memberBasicPostId: string = "";
  memberAddressContactPutId: string = "";
  memberOtherDetailsPutId: string = "";
  fetchGroupsId: string = "";
  fetchDivisionId: string = "";
  fetchClassId: string = "";
  memberBasicDetailsPut: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      memberActiveStep: 0,
      isOpenWarningDialog: false,
      isOpenGroupSearch: false,
      groupSearchInput: "",
      memberData: initialMemberDetailModelData,
      editMemberFormStepList: [
        {
          key: 1,
          title: "Members Basic Details",
          description: "Type and Select details",
          isCompleted: false
        },
        {
          key: 2,
          title: "Address & Contact",
          description: "Type and Select details",
          isCompleted: false
        },
        {
          key: 3,
          title: "Other Details",
          description: "Type and Select details",
          isCompleted: false
        }
      ],
      isSecondStepDirty: false,
      countryList: [],
      stateList: [],
      mailingStateList: [],
      isMailingAddressSameAsPhysicalAddress: false,
      ApiErrors: {},
      employerList: [{ name: "Employer", id: 1 }],
      groupList: [{ name: "Employer", id: 1 }],
      divisionList: [{ name: "Employer", id: 1 }],
      locationList: [{ name: "Employer", id: 1 }],
      classList: [{ name: "Employer", id: 1 }],
      memberRelationList: [{ name: "0", id: 0 }, { name: "1", id: 1 }],
      editIds: {
        physicalId: "",
        mailingId: "",
        contactId: "",
        otherId: "",
        id: ""
      },
      isMailingState: false,
      planList: [{ name: "Main Plan", id: 53 }],
      coverageList: [
        { name: "Self", id: 53 },
        { name: "Family", id: 54 }
      ],
      isMemberCreated: false,
      memberBasicSuccess: false
    };
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.countryCallId:
        this.receiveCountryListFromAPI(responseJson);
        break;
      case this.stateCallId:
        this.receiveStateListFromAPI(responseJson);
        break;
      case this.memberBasicPostId:
        this.handleBasicDatailsAPIRes(responseJson);
        break;
      case this.memberAddressContactPutId:
        this.handleAddressResponse(responseJson);
        break;
      case this.memberOtherDetailsPutId:
        this.handleOtherDetailsResponse(responseJson);
        break;
      case this.fetchGroupsId:
        this.handleGroupsData(responseJson);
        break;
      case this.fetchDivisionId:
        this.handleDivisionsData(responseJson);
        break;
      case this.fetchClassId:
        this.handleClassData(responseJson);
        break;
      case this.memberBasicDetailsPut:
        this.handleBasicDatailsAPIRes(responseJson);
        break;
      default:
        break;
    }
  }

  async componentDidMount() {
    const basicDetails = this.state.memberData;
    const { employersList, locationList, clientDetails, editMemberDetails, editMemberIDs } = this.props;
    if (this.props.isEdit) {
      const employerr = employersList.find((item: SelectionList) => item.id == editMemberDetails.memberBasicDetails.employer_id);
      const addressDetails = editMemberDetails.memberAddressDetails;
      const empId = employerr?.empId;
      if (empId) {
        this.fetchGroupData(empId);
        this.fetchDivisionData(empId);
        this.fetchClassData(empId);
      }
      if (addressDetails.physical_country_id) {
        this.fetchStateListFromAPI(addressDetails.physical_country_id);
      }
      if (addressDetails.mailing_country_id) {
        this.fetchStateListFromAPI(addressDetails.mailing_country_id);
      }
      this.setState({
        employerList: employersList,
        isMailingState:!!addressDetails.mailing_country_id,
        memberBasicSuccess: true,
        locationList: locationList,
        memberData: { ...editMemberDetails, memberBasicDetails: { ...editMemberDetails.memberBasicDetails, client_name: clientDetails.client_id, account_id: clientDetails.accountId } },
        editIds: editMemberIDs,
        editMemberFormStepList: this.state.editMemberFormStepList.map(
          list => ({ ...list, isCompleted: true })
        )
      });
    } else {
      this.setState({
        employerList: employersList,
        locationList: locationList,
        memberData: {
          ...basicDetails,
          memberBasicDetails: {
            ...basicDetails.memberBasicDetails,
            client_name: clientDetails.client_id,
            account_id: clientDetails.accountId
          }
        }
      });
    }
    if (this.state.countryList.length === 0) {
      this.fetchCountryListFromAPI();
    }
  }

  setActiveStep = (step: number) => {
    this.setState({ memberActiveStep: step });
  };

  handleCloseWarningDialog = (isAgreeToLooseData: boolean) => {
    if (isAgreeToLooseData) {
      this.props.handleClose();
    }
    this.setState({ ...this.state, isOpenWarningDialog: false });
  };
  setInitialState = () => {
    this.setState({
      ...this.state,
      memberActiveStep: 0,
      memberData: initialMemberDetailModelData,
      isOpenWarningDialog: false
    });
  };
  handleNext = (isFinish?: boolean) => {
    if (isFinish) {
      this.props.saveMemberData();
    } else {
      this.handleSteps(true);
    }
  };

  handleSteps = (next: boolean) => {
    const activeStep = next
      ? this.state.memberActiveStep + 1
      : this.state.memberActiveStep - 1;
    const length = this.state.editMemberFormStepList.length;
    if (length > activeStep) {
      this.setState({
        memberActiveStep: activeStep,
        editMemberFormStepList: this.state.editMemberFormStepList.map(
          (list, index) => {
            if (index === this.state.memberActiveStep) {
              return {
                ...list,
                isCompleted: true
              };
            }
            return list;
          }
        )
      });
    }
  };

  groupItemSelectionClickHandler = () => {
    this.setState({
      ...this.state,
      isOpenGroupSearch: !this.state.isOpenGroupSearch
    });
  };

  searchInputOnChangeHandler = (targetValue: string) => {
    this.setState({ ...this.state, groupSearchInput: targetValue });
  };

  handleMemberDialogClose = () => {
    if (
      JSON.stringify(this.props.memberDetails) !==
      JSON.stringify(this.state.memberData)
    ) {
      this.setState({ ...this.state, isOpenWarningDialog: true });
    } else {
      this.setInitialState();
      this.props.handleClose();
    }
  };

  saveAndNext = () => {
    this.handleNext();
  };

  handleDialogClose = () => {
    this.setState({ isMemberCreated: false });
    this.props.handleClose();
  };

  handleMailingAddressCheckbox = (event: { target: { checked: boolean } }) => {
    const isChecked = event.target.checked;
    const {
      physical_address_line1,
      physical_address_line2,
      physical_city,
      physical_country_id,
      physical_state_id,
      physical_zip_code
    } = this.state.memberData.memberAddressDetails;
    let stateData = {
      mailingStateList: this.state.mailingStateList,
      isMailingAddressSameAsPhysicalAddress: isChecked,
      memberData: {
        ...this.state.memberData,
        memberAddressDetails: {
          ...this.state.memberData.memberAddressDetails,
          mailing_address_line1: "",
          mailing_address_line2: "",
          mailing_city: "",
          mailing_country_id: -1,
          mailing_state_id: -1,
          mailing_zip_code: ""
        }
      }
    };
    if (isChecked) {
      stateData = {
        mailingStateList: this.state.stateList,
        isMailingAddressSameAsPhysicalAddress: isChecked,
        memberData: {
          ...this.state.memberData,
          memberAddressDetails: {
            ...this.state.memberData.memberAddressDetails,
            mailing_address_line1: physical_address_line1,
            mailing_address_line2: physical_address_line2,
            mailing_city: physical_city,
            mailing_country_id: physical_country_id,
            mailing_state_id: physical_state_id,
            mailing_zip_code: physical_zip_code
          }
        }
      };
    }
    this.setState(stateData);
  };

  saveAndFinish = () => {
    const { memberData, memberActiveStep, memberBasicSuccess } = this.state;
    const editedMemberData = { ...memberData };

    switch (memberActiveStep) {
      case 0:
        this.handleBasicDetails(editedMemberData.memberBasicDetails);
        break;

      case 1:
      case 2:
        if (memberBasicSuccess) {
          if (memberActiveStep === 1) {
            this.handleAddressContact(editedMemberData.memberAddressDetails);
          } else if (memberActiveStep === 2) {
            this.handleOtherDetails(editedMemberData.memberOtherDetails);
          }
        } else {
          this.setState({ memberActiveStep: 0 }, () => {
            this.handleBasicDetails(editedMemberData.memberBasicDetails);
          });
        }
        break;

      default:
        this.props.saveMemberData(editedMemberData);
        this.handleSteps(true);
        break;
    }
  };

  handleBasicDetails = (data: IMemberBasicModel) => {
    const errors: { [key: string]: string[] } = {};

    const requiredFields = [
      { field: "member_number", message: "Please enter member no" },
      { field: "employer_id", message: "Please select Employer" },
      { field: "ssn", message: "Please enter SSN" }
    ];

    requiredFields.forEach(({ field, message }) => {
      if (!data[field]) {
        errors[field] = [message];
      }
    });

    this.setState({ ApiErrors: errors });

    if (!Object.keys(errors).length) {
      if (this.props.isEdit) {
        this.editMemberBasicDetails(data);
      } else {
        this.handleAPIBasicDetails(data);
      }
    }
  };

  editMemberBasicDetails = (data: IMemberBasicModel) => {
    const { id } = this.state.editIds;
    let payload = {
      member_detail: {
        ...data,
        ...this.handleAddressData(this.state.memberData.memberAddressDetails).member_detail
      }
    }
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.props.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    payload.member_detail.ssn = `${payload.member_detail.ssn.split("-").join("")}`;
    this.memberBasicDetailsPut = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.membersListGetApiEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOtherDetails = (data: IMemberOtherDetailModel) => {
    const { id } = this.state.editIds;
    const {
      member_hire_date,
      member_paid_through_date,
      member_termination_date,
      member_other_id,
      member_other_id_desc,
      member_client_emp_id,
      member_alt_emp_id,
      member_cvg_effective_date,
      member_medical_effective_date,
      member_medical_term_date,
      member_dental_effective_date,
      member_dental_term_date,
      member_vision_effective_date,
      member_vision_term_date,
      member_rx_effective_date,
      member_rx_term_date,
      member_401k_effective_date,
      member_401k_term_date,
      member_hsa_effective_date,
      member_hsa_term_date,
      member_hra_effective_date,
      member_hra_term_date,
      member_fsa_effective_date,
      member_fsa_term_date,
      member_group_life_effective_date,
      member_group_life_term_date,
      member_group_life_benefit_amount,
      member_std_effective_date,
      member_std_term_date,
      member_std_weekly_benefit_amount,
      member_ltd_effective_date,
      member_ltd_term_date,
      member_ltd_monthly_payroll_amount,
      member_voluntary_life_effective_date,
      member_voluntary_life_term_date,
      member_voluntary_life_benefit_amount,
      member_accident_effective_date,
      member_accident_term_date,
      member_cancer_effective_date,
      member_cancer_term_date,
      member_heart_stroke_effective_date,
      member_heart_stroke_term_date,
      member_additional_benefits_effective_date,
      member_additional_benefits_term_date,
      member_medical_plan,
      member_medical_cvg_type,
      member_dental_cvg_type,
      member_dental_plan,
      member_vision_cvg_type,
      member_vision_plan,
      member_rx_cvg_type,
      member_rx_plan,
      member_accident_cvg_type,
      member_accident_plan,
      member_fsa_plan,
      member_fsa_cvg_type,
      member_cancer_cvg_type,
      member_cancer_plan,
      member_group_life_cvg_type,
      member_group_life_plan,
      member_hra_cvg_type,
      member_hra_plan,
      member_hsa_cvg_type,
      member_hsa_plan,
      member_voluntary_life_cvg_type,
      member_voluntary_life_plan,
      member_std_cvg_type,
      member_std_plan,
      member_ltd_cvg_type,
      member_ltd_plan,
      member_heart_stroke_cvg_type,
      member_heart_stroke_plan,
      member_payroll_cycle
    } = data;
    const addressDataPayload = this.handleAddressData(
      this.state.memberData.memberAddressDetails
    );
    let datas: any = {};
    Object.keys(data).forEach((key: string) => {
      const newKey = key.substring(7);
      datas[newKey] = data[key];
    })
    const payload = {
      member_detail: {
        ...addressDataPayload.member_detail,
        member_other_detail_attributes: {
          ...datas,
          hire_date: member_hire_date,
          paid_thru_date: member_paid_through_date,
          terminate_date: member_termination_date,
          other_id: member_other_id,
          otherid_description: member_other_id_desc,
          client_employee_id: member_client_emp_id,
          alternate_employee_id: member_alt_emp_id,
          cvg_eff_date: member_cvg_effective_date,
          medical_eff_date: member_medical_effective_date,
          medical_trm_date: member_medical_term_date,
          dental_eff_date: member_dental_effective_date,
          dental_trm_date: member_dental_term_date,
          vision_eff_date: member_vision_effective_date,
          vision_trm_date: member_vision_term_date,
          rx_eff_date: member_rx_effective_date,
          rx_trm_date: member_rx_term_date,
          group_life_eff_date: member_group_life_effective_date,
          group_life_trm_date: member_group_life_term_date,
          group_life_benefit_amount:member_group_life_benefit_amount,
          hsa_eff_date:  member_hsa_effective_date,
          hsa_trm_date: member_hsa_term_date,
          hra_eff_date: member_hra_effective_date,
          hra_trm_date: member_hra_term_date,
          fsa_eff_date: member_fsa_effective_date,
          fsa_trm_date: member_fsa_term_date,
          ["401k_eff_date"]: member_401k_effective_date,
          ["401k_trm_date"]: member_401k_term_date,
          std_eff_date: member_std_effective_date,
          std_trm_date: member_std_term_date,
          std_weekly_benefit_amount:member_std_weekly_benefit_amount,
          ltd_eff_date: member_ltd_effective_date,
          ltd_trm_date: member_ltd_term_date,
          ltd_mon_payroll_amount: member_ltd_monthly_payroll_amount,
          Voluntary_eff_date: member_voluntary_life_effective_date,
          Voluntary_trm_date: member_voluntary_life_term_date,
          vol_life_benefit_amount: member_voluntary_life_benefit_amount,
          accident_eff_date: member_accident_effective_date,
          accident_trm_date: member_accident_term_date,
          cancer_eff_date: member_cancer_effective_date,
          cancer_trm_date: member_cancer_term_date,
          heart_stroke_eff_date: member_heart_stroke_effective_date,
          heart_stroke_trm_date: member_heart_stroke_term_date,
          add_eff_date: member_additional_benefits_effective_date,
          add_trm_date: member_additional_benefits_term_date,
          medical_plan_id: member_medical_plan,
          medical_cvg_type_id: member_medical_cvg_type,
          dental_plan_id: member_dental_plan,
          dental_cvg_type_id: member_dental_cvg_type,
          vision_plan_id:member_vision_plan, 
          vision_cvg_type_id:member_vision_cvg_type,
          rx_plan_id: member_rx_plan, 
          rx_cvg_type_id: member_rx_cvg_type,
          group_life_plan_id: member_group_life_plan, 
          group_life_cvg_type_id: member_group_life_cvg_type, 
          fsa_plan_id:member_fsa_plan, 
          fsa_cvg_type_id: member_fsa_cvg_type, 
          hra_plan_id: member_hra_plan, 
          hra_cvg_type_id: member_hra_cvg_type, 
          hsa_plan_id: member_hsa_plan, 
          hsa_cvg_type_id: member_hsa_cvg_type, 
          std_plan_id: member_std_plan, 
          std_cvg_type_id: member_std_cvg_type, 
          ltd_plan_id: member_ltd_plan, 
          ltd_cvg_type_id: member_ltd_cvg_type, 
          vol_life_plan_id: member_voluntary_life_plan, 
          vol_life_cvg_type_id: member_voluntary_life_cvg_type, 
          accident_plan_id: member_accident_plan, 
          accident_cvg_type_id: member_accident_cvg_type, 
          cancer_plan_id: member_cancer_plan, 
          cancer_cvg_type_id: member_cancer_cvg_type, 
          heart_stroke_plan_id: member_heart_stroke_plan, 
          heart_stroke_cvg_type_id: member_heart_stroke_cvg_type, 
          payroll_cycle_id:member_payroll_cycle
        },
      }
    };
    payload.member_detail.ssn = `${payload.member_detail.ssn.split("-").join("")}`;
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.props.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.memberOtherDetailsPutId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.membersListGetApiEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleContryandStateId = (id:number) => {
    return id==-1?"":id
  }

  handleAddressData = (data: IMemberAddressDetailModel) => {
    const { physicalId, mailingId, contactId, otherId } = this.state.editIds;
    const {
      physical_address_line1,
      physical_address_line2,
      physical_city,
      physical_zip_code,
      physical_state_id,
      physical_country_id,
      mailing_address_line1,
      mailing_address_line2,
      mailing_city,
      mailing_zip_code,
      mailing_state_id,
      mailing_country_id,
      member_age,
      member_gender,
      member_dob,
      member_fax1,
      member_fax2,
      member_email_address,
      member_mobile,
      member_phone_number,
      member_phone_numberExt,
      member_primary_language_spoken,
      member_salary
    } = data;
    const payload = {
      member_detail: {
        ...APIPostData.member_detail,
        ...this.state.memberData.memberBasicDetails,
        physical_address_attributes: {
          id: physicalId,
          address_line_1: physical_address_line1,
          address_line_2: physical_address_line2,
          zip_code: physical_zip_code,
          city: physical_city,
          country: this.handleContryandStateId(physical_country_id),
          state: this.handleContryandStateId(physical_state_id)
        },
        mailing_address_attributes: {
          id: mailingId,
          address_line_1: mailing_address_line1,
          address_line_2: mailing_address_line2,
          zip_code: mailing_zip_code,
          city: mailing_city,
          country: this.handleContryandStateId(mailing_country_id),
          state: this.handleContryandStateId(mailing_state_id)
        },
        member_contact_information_attributes: {
          id: contactId,
          phone_number: member_phone_number,
          phone_number_ext: member_phone_numberExt,
          fax1: member_fax1,
          fax2: member_fax2,
          mobile: member_mobile,
        },
        email: member_email_address,
        age: member_age,
        gender: member_gender,
        date_of_birth: member_dob,
        primary_language_spoken: member_primary_language_spoken,
        salary: member_salary,
        member_other_detail_attributes: {
          ...this.state.memberData.memberOtherDetails,
          id: otherId
        }
      }
    };
    return payload;
  };

  handleAddressContact = (data: IMemberAddressDetailModel) => {
    const email1 = data.member_email_address;
    if (email1 && !configJSON.emailRegex.test(email1)) {
      this.setState({ ApiErrors: { member_email_address: ["Invalid Email Address"] } });
      return
    }
    const { id } = this.state.editIds;
    const payload = this.handleAddressData(data);
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.props.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    payload.member_detail.ssn = `${payload.member_detail.ssn.split("-").join("")}`;
    this.memberAddressContactPutId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.membersListGetApiEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAPIBasicDetails = (data: IMemberBasicModel) => {
    const payload = {
      member_detail: {
        ...APIPostData.member_detail,
        ...data
      }
    };
    payload.member_detail.ssn = `${data.ssn.split("-").join("")}`;
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.props.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.memberBasicPostId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.membersListGetApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchCountryListFromAPI = () => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.countryCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCountryAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleMemberInputItemChange = (
    event: { target: { value: string } },
    paramName: string
  ) => {
    const { effective_date, termination_date } = this.state.memberData.memberBasicDetails;
    const stateEff = new Date(effective_date);
    const stateTer = new Date(termination_date);
    let inputValue = event.target.value;
  
    if (paramName === "ssn") {
      inputValue = this.handleSsnFormate(inputValue);
    } else if (paramName === "effective_date" && termination_date) {
      const diffDays = stateTer.getDate() - new Date(inputValue).getDate();
      inputValue = this.handleDateDiff(diffDays, effective_date, inputValue);
    } else if (paramName === "termination_date" && effective_date) {
      const diffDays = new Date(inputValue).getDate() - stateEff.getDate();
      inputValue = this.handleDateDiff(diffDays, termination_date, inputValue);
    } else if (paramName === "employer_id") {
      this.fetchGroupData(inputValue);
      this.fetchDivisionData(inputValue);
      this.fetchClassData(inputValue);
    }
  
    this.setState({
      ...this.state,
      memberData: {
        ...this.state.memberData,
        memberBasicDetails: {
          ...this.state.memberData.memberBasicDetails,
          [paramName]: inputValue
        }
      }
    });
  };
  
  handleDateDiff = (diff: number, date: string, value: string) => {
    return diff < 0 ? date : value;
  };

  handleSsnFormate = (inputValue: string) => {
    let formattedSsnId = inputValue.replace(/\D/g, "").toUpperCase();
    if (formattedSsnId.length > 3) {
      formattedSsnId =
        formattedSsnId.slice(0, 3) + "-" + formattedSsnId.slice(3);
    }
    if (formattedSsnId.length > 6) {
      formattedSsnId =
        formattedSsnId.slice(0, 6) + "-" + formattedSsnId.slice(6);
    }
    return formattedSsnId.length > 11
      ? formattedSsnId.slice(0, 11)
      : formattedSsnId;
  };

  fetchGroupData = (id: string) => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.props.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchGroupsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createGroupAPIEndPoint}Emp${id}/groups`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchClassData = (id: string) => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.props.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchClassId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createGroupAPIEndPoint}/${id}/class_basic_details`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchDivisionData = (id: string) => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.props.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchDivisionId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.divisionsListGetApiEndPoint}?employer_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getMembersErrorMessage = (fieldName: string) => {
    const { ApiErrors } = this.state;
    if (ApiErrors[fieldName]) {
      return ApiErrors[fieldName][0];
    }
  };

  itemListClickHandler = (groupName: string) => {
    this.setState({
      ...this.state,
      memberData: {
        ...this.state.memberData,
        memberAddressDetails: {
          ...this.state.memberData.memberAddressDetails,
          member_primary_language_spoken: groupName
        }
      },
      isOpenGroupSearch: false,
      groupSearchInput: ""
    });
  };

  handleAddressItemChange = (
    event: { target: { value: string } },
    paramName: string
  ) => {
    let inputValue = event.target.value;
    const isDateOfBirthParam = (paramName === "member_dob");
    const currentAddressDetails = this.state.memberData.memberAddressDetails;
    let age;
  
    if (paramName === "physical_city" || paramName === "mailing_city") {
      const sanitizedValue = inputValue.trim().replace(/[1-9]/g, "");
      inputValue = sanitizedValue;
    } else if (paramName === "member_gender") {
      inputValue = inputValue.trim();
    } else if (
      paramName === "mailing_country_id" ||
      paramName === "physical_country_id"
    ) {
      this.fetchStateListFromAPI(inputValue);
    } else if (isDateOfBirthParam) {
      const dob = new Date(inputValue);
      age = Math.abs(new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970);
    } else if (
      paramName === "member_phone_number" ||
      paramName === "member_mobile"
    ) {
      if (inputValue.length > 10) {
        inputValue = paramName === "member_phone_number" ? `${currentAddressDetails.member_phone_number}` : `${currentAddressDetails.member_mobile}`;
      }
    }
  
    this.setState({
      ...this.state,
      isMailingState: paramName === "mailing_country_id",
      memberData: {
        ...this.state.memberData,
        memberAddressDetails: {
          ...currentAddressDetails,
          [paramName]: inputValue,
          member_age: isDateOfBirthParam && age ? age : currentAddressDetails.member_age
        }
      }
    });
  };  

  handleOtherItemChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { value: string | number | boolean }
    >,
    param: string
  ) => {
    const eventValue = e.target.value;
    this.setState({
      ...this.state,
      memberData: {
        ...this.state.memberData,
        memberOtherDetails: {
          ...this.state.memberData.memberOtherDetails,
          [param]: eventValue
        }
      }
    });
  };

  handleOtherItemCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    param: string
  ) => {
    const isChecked = event.target.checked;
    this.setState({
      ...this.state,
      memberData: {
        ...this.state.memberData,
        memberOtherDetails: {
          ...this.state.memberData.memberOtherDetails,
          [param]: isChecked
        }
      }
    });
  };

  fetchStateListFromAPI = (country_id: string | number) => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.stateCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStateFromCountryAPiEndPoint}${country_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCountryData = (data: Countries[]) =>
    data.map(({ CountryName, countryCode }: Countries) => ({
      id: countryCode,
      name: CountryName
    }));

  handleStateData = (data: States[]) =>
    data.map(({ StateName, StateCode }: States) => ({
      id: StateCode,
      name: StateName
    }));

  handleBasicDatailsAPIRes(responseJson: any) {
    const ApiSuccessfull = responseJson.data ? responseJson.data.id:"";
    if (ApiSuccessfull) {
      const {
        physical_address,
        mailing_address,
        member_contact_information,
        member_other_detail
      } = responseJson.data.attributes;
      this.setState({
        editIds: {
          id: responseJson.data.id,
          physicalId: physical_address.data.id,
          mailingId: mailing_address.data.id,
          contactId: member_contact_information.data.id,
          otherId: member_other_detail.data.id
        },
        memberBasicSuccess: true
      });
      this.handleSteps(true);
    } else if (responseJson.errors) {
      this.setState({ ApiErrors: responseJson.errors });
    }
  }

  handleAddressResponse = (responseJson: any) => {
    const addressApiSuccess = responseJson.data ? responseJson.data.id :"";
    if (addressApiSuccess) {
      this.handleSteps(true);
    }else if (responseJson.errors) {
      this.setState({ ApiErrors: responseJson.errors });
    }
  };

  handleOtherDetailsResponse = (responseJson: {}) => {
    this.setState({ isMemberCreated: true });
  };

  handleGroupsData = (responseJson: any) => {
    const groupApisuccess = responseJson.data ? responseJson.data.length: 0;
    if (groupApisuccess) {
      const GroupData = responseJson.data.map(
        (i: {
          attributes: { group_id: string; group_name: string };
          id: string;
        }) => ({
          id: i.id,
          empId: i.attributes.group_id,
          name: i.attributes.group_name
        })
      );
      this.setState({ groupList: GroupData });
    }
  };

  handleDivisionsData = (responseJson: any) => {
    const divisionApiSuccessfull = responseJson.data ? responseJson.data.length : 0; 
    if (divisionApiSuccessfull) {
      const divisionData = responseJson.data.map(
        (i: {
          attributes: { division_id: string; division_name: string };
          id: string;
        }) => ({
          id: i.id,
          empId: i.attributes.division_id,
          name: i.attributes.division_name
        })
      );
      this.setState({ divisionList: divisionData });
    }
  };

  handleClassData = (responseJson: ClassData) => {
    const classApisuccessfull = responseJson.data ? responseJson.data.length : 0; 
    if (classApisuccessfull) {
      const classData = responseJson.data.map((i: ClassItem) => ({
        id: i.id,
        empId: i.attributes.class_id,
        name: i.attributes.class_name
      }));
      this.setState({ classList: classData });
    }
  };

  receiveCountryListFromAPI(responseJson: any) {
    const countryApisuccess = responseJson ? responseJson.countries : false;
    if (countryApisuccess) {
      this.setState({
        countryList: this.handleCountryData(responseJson.countries)
      });
    }
  }

  receiveStateListFromAPI(responseJson: any) {
    const stateApiSuccessfull = responseJson ? responseJson.states : false
    if (stateApiSuccessfull) {
      if (this.state.isMailingState) {
        this.setState({
          mailingStateList: this.handleStateData(responseJson.states)
        });
        const country = this.props.editMemberDetails.memberAddressDetails.physical_country_id;
        if (this.props.isEdit && !!country) {
          this.setState({ isMailingState: false });
          this.fetchStateListFromAPI(country);
        }
      } else {
        this.setState({
          stateList: this.handleStateData(responseJson.states)
        });
      }
    }
  }

  
}
