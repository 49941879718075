import React, { Component, Fragment } from "react";
import { Button, Grid, Drawer, Typography } from "@material-ui/core";
import { KeyboardArrowUp, KeyboardArrowDown, Menu } from "@material-ui/icons";

import "./CustomLeftSideBarContainer.web.css";
import { toast } from "react-toastify";
const images = require("./assets");

export interface MenuList {
  key: string;
  icon?: string;
  selectedIcon?: string;
  label: string;
  navigation: string;
  role?: number;
  showTopBorder?: boolean;
  showCount?: number;
}

export interface MainMenuList extends MenuList {
  children?: MenuList[];
}

interface Props {
  mainMenuList: MainMenuList[];
  userName: string;
  userEmail: string;
  userImage: string;
  navigation: any;
  selectedEmployer: {}[];
}

interface S {
  selectedMenu: string;
  isMobileBarDisplay: boolean;
  openChildrenList: string[];
  selectedChildrenMenu: string;
}

export default class CustomLeftSideBarContainer extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedMenu: window.location.href.split("/")[4],
      selectedChildrenMenu: window.location.href.split("/")[5],
      isMobileBarDisplay: false,
      openChildrenList: [],
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (
      window.location.href.split("/")[5] &&
      prevState.selectedChildrenMenu !== window.location.href.split("/")[5]
    ) {
      this.setState({
        selectedChildrenMenu: window.location.href.split("/")[5],
        openChildrenList: window.location.href.split("/")[5]
          ? [...this.state.openChildrenList, window.location.href.split("/")[4]]
          : [...this.state.openChildrenList],
        selectedMenu: window.location.href.split("/")[4],
      });
    } else if (
      window.location.href.split("/")[4] &&
      prevState.selectedMenu !== window.location.href.split("/")[4]
    ) {
      this.setState({
        selectedMenu: window.location.href.split("/")[4],
        selectedChildrenMenu: "",
      });
    }
  }

  handleMobileSideBar = () => {
    this.setState({
      isMobileBarDisplay: !this.state.isMobileBarDisplay,
    });
  };

  handleClick = (navigation: string, key: string) => {
    this.setState({
      selectedMenu: key,
      selectedChildrenMenu: "",
    });
    this.props.navigation.navigate(navigation);
  };

  handleChildrenClick = (navigation: string, key: string, parent: string) => {
    if ((key === "Divisions"|| key === 'Locations') && this.props.selectedEmployer.length === 0) {
      return toast.error("Please select employer first")
    }
    this.setState({
      selectedChildrenMenu: key,
      selectedMenu: parent,
    });
    this.props.navigation.navigate(navigation);
  };

  handleOpenCloseChildrenClick = (key: string) => {
    let newList = this.state.openChildrenList;
    if (newList.includes(key)) {
      newList = newList.filter((list) => list !== key);
    } else {
      newList.push(key);
    }
    this.setState({
      openChildrenList: newList,
    });
  };

  ifTrueThenReturn = (condition: boolean, value: string) => {
    return condition ? value : "";
  };

  render() {
    const sideBarMenuComponent = (
      <>
        {this.props.mainMenuList.map((menu, index) => {
          const isHaveChild =
            !!menu && !!menu.children && !!menu.children.length;
          return (
            <Fragment key={menu.key}>
              {menu.showTopBorder && <Grid item xs={12} className="hr my-10" />}
              <Grid
                item
                xs={12}
                className={`d-flex ${this.ifTrueThenReturn(
                  index === 0,
                  "mt-10"
                )}`}
                key={menu.key}
              >
                <Button
                  data-test-id={`side-bar-menu-${menu.key}`}
                  onClick={() => this.handleClick(menu.navigation, menu.key)}
                  className={`menu-btn ${this.ifTrueThenReturn(
                    menu.key === this.state.selectedMenu,
                    "active-menu-btn"
                  )}`}
                >
                  <img
                    src={
                      menu.key === this.state.selectedMenu
                        ? menu.selectedIcon
                        : menu.icon
                    }
                    alt="icon image"
                    className="icon-image"
                  />
                  <Typography
                    variant="body1"
                    className={`menu-name ${this.ifTrueThenReturn(
                      isHaveChild,
                      "mr-28p"
                    )}`}
                  >
                    {menu.label}
                  </Typography>
                  {isHaveChild && (
                    <Button
                      data-test-id={`side-bar-child-menu-${menu.key}`}
                      className="arrow-down-btn"
                      onClick={() =>
                        this.handleOpenCloseChildrenClick(menu.key)
                      }
                    >
                      {this.state.openChildrenList.includes(menu.key) ? (
                        <KeyboardArrowUp
                          className={`arrow-down-icon ${this.ifTrueThenReturn(
                            menu.key === this.state.selectedMenu,
                            "active"
                          )}`}
                        />
                      ) : (
                        <KeyboardArrowDown
                          className={`arrow-down-icon ${this.ifTrueThenReturn(
                            menu.key === this.state.selectedMenu,
                            "active"
                          )}`}
                        />
                      )}
                    </Button>
                  )}
                  {!!menu.showCount && (
                    <Typography variant="body2" className="notification-count">
                      4
                    </Typography>
                  )}
                </Button>
              </Grid>
              {!!menu &&
                !!menu.children &&
                menu.children.map((child) => (
                  <Grid
                    item
                    xs={12}
                    className={
                      this.state.openChildrenList.includes(menu.key)
                        ? "d-flex"
                        : "d-none"
                    }
                    key={child.key}
                  >
                    <Button
                      data-test-id={`side-bar-children-menu-${child.key}`}
                      onClick={() =>
                        this.handleChildrenClick(
                          child.navigation,
                          child.key,
                          menu.key
                        )
                      }
                      className={`ml-40 menu-btn ${this.ifTrueThenReturn(
                        child.key === this.state.selectedChildrenMenu,
                        "active-children-menu-btn"
                      )}`}
                    >
                      {child.icon && (
                        <img
                          src={
                            child.key === this.state.selectedChildrenMenu
                              ? child.selectedIcon
                              : child.icon
                          }
                          alt="icon image"
                          className="icon-image"
                        />
                      )}
                      <Typography variant="body1" className="menu-name">
                        {child.label}
                      </Typography>
                    </Button>
                  </Grid>
                ))}
            </Fragment>
          );
        })}

        <Grid item xs={12} className="mt-30p">
          <Grid container className="user-side-bar-container">
            <Grid item xs={3}>
              <img
                src={this.props.userImage}
                alt="user image"
                className="user-image"
              />
            </Grid>
            <Grid item xs={7} className="div-align-center-justify-start">
              <Typography variant="body1" className="user-name">
                {this.props.userName}
              </Typography>
              <Typography variant="body2" className="user-email">
                {this.props.userEmail}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              className="div-align-center-justify-start p-relative"
            >
              <img
                src={images.logout}
                alt="setting icon"
                className="setting-icon"
              />
              <div className="title-overlay">
                Click here to access your settings
              </div>
            </Grid>
          </Grid>
        </Grid>
      </>
    );

    return (
      <div className="custom-left-side-bar-container">
        <Grid container className="main-side-bar-container p-20 w-375">
          <Grid item xs={12}>
            <img
              src={images.companyLogo}
              alt="logo"
              className="logo-image"
              data-test-id="logo-image"
            />
          </Grid>
          {sideBarMenuComponent}
        </Grid>
        <div className="side-bar-mobile">
          <Button
            data-test-id="mobile-side-bar-btn"
            onClick={this.handleMobileSideBar}
            className="menu-icon-left-side"
          >
            <Menu fontSize="large" />
          </Button>
          <img src={images.companyLogo} alt="logo" className="logo-image" />
          <Drawer
            data-test-id="mobile-drawer"
            open={this.state.isMobileBarDisplay}
            onClick={this.handleMobileSideBar}
            className="drawer-class"
          >
            <Grid container className="main-side-bar-container p-20 w-375">
              {sideBarMenuComponent}
            </Grid>
          </Drawer>
        </div>
      </div>
    );
  }
}
