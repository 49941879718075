/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Field } from 'formik';
import "../../../../blocks/landingpage/assets/Styles/ClientDetails.css"
import { Grid, makeStyles, Button, FormHelperText, Card, Divider, Typography } from '@material-ui/core';
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
    input: {
        display: "none"
    },
    uploadButton: {
        textTransform: "capitalize",
        border: "2px dashed #4166F8",
        color: "#4166F8",
        fontSize: "18px",
        borderRadius: 8,
        padding: "10px"
    }
}));

const StepThree = (props: any) => {
    const { setValues, values } = props

    const classes = useStyles()

    const handleUploadClick = (event: any) => {
        var file = event.target.files[0];
        var url = URL.createObjectURL(file);
        setValues({
            ...values,
            image_upload: file,
            selectedFile: url
        });
    };
    const handleUploadFileClick = (event: any) => {
        var file = event.target.files[0];
        setValues({
            ...values,
            pdf_file: file,
        });
    };
    return (
        <>
            <Grid container spacing={2}>
                <Grid container item xs={12}>
                    <Grid container item xs={12} md={5}>
                        <Field name="image_upload">
                            {({
                                field,
                                meta,
                            }: any) => (
                                <>
                                    <Grid item xs={12}>
                                        <Typography className="title-2">Upload a logo</Typography>
                                    </Grid>
                                    <label htmlFor="contained-button-file" style={{ width: '100%' }}>
                                        <Button fullWidth component="span" className="choose-file-btn">
                                            Choose a File
                                            <input
                                                accept="image/*"
                                                className="file-display"
                                                id="contained-button-file"
                                                type="file"
                                                onChange={handleUploadClick}
                                            />
                                        </Button>
                                    </label>
                                    {meta.touched && meta.error && (<FormHelperText style={{ color: "#FF0000" }}>{meta.touched && meta.error}</FormHelperText>)}
                                </>
                            )}
                        </Field>
                    </Grid>

                </Grid>
                {values.selectedFile && (
                    <Grid item xs={12}>
                        <Typography className="title-2">Preview</Typography>
                        <Card style={{ width: 120, height: 120, padding: 5 }}>
                            <img
                                width="100%" height={"100%"} style={{ borderRadius: 4 }}
                                src={values.selectedFile}
                            />
                        </Card>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid container item xs={12}>
                    <Grid container item xs={12} md={5}>
                        <Field name="pdf_file">
                            {({
                                field,
                                meta,
                            }: any) => (
                                <>
                                    <Grid item xs={12}>
                                        <Typography className="title-2">Upload Documents(format:doc,pdf)</Typography>
                                    </Grid>
                                    <label htmlFor="contained-button-pdf-file" style={{ width: '100%' }}>
                                        <Button fullWidth component="span" className="choose-file-btn">
                                            {field.value.name ? field.value.name : "Choose a File"}
                                            <input
                                                accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                className="file-display"
                                                id="contained-button-pdf-file"
                                                type="file"
                                                onChange={handleUploadFileClick}
                                            />
                                        </Button>
                                    </label>
                                    {meta.touched && meta.error && (<FormHelperText style={{ color: "#FF0000" }}>{meta.touched && meta.error}</FormHelperText>)}
                                </>
                            )}
                        </Field>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

StepThree.label = 'stap 3';
StepThree.initialValues = {
    selectedFile: "",
    image_upload: '',
    pdf_file: '',
};
StepThree.validationSchema = yup.object().shape({
    image_upload: yup.mixed().required('image is required'),
    pdf_file: yup.mixed().required('file is required')
});

export default StepThree;
