import React from "react";

// Customizable Area Start
import "./LoginSignup.css";
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  Paper,
  Checkbox,
  OutlinedInput,
  MenuItem,
  Select,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { imgCompanyLogo } from "./assets";
const landingImage = require("./assets");
export const configJSON = require("./config");
import * as Yup from "yup";

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";
import { Formik } from "formik";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email"),
  mobile_no: Yup.number().min(10, "Please enter 10 digit mobile number").typeError("Please enter number value only"),
  password: Yup.string().required("Password is Required"),
  country_code: Yup.string(),
});

export default class EmailAccountLoginSignupBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const renderEmailError = (emailError:boolean, errorsmsg:string|undefined) => (
      emailError ? (
        <Typography style={webStyle.emailError}>
          {errorsmsg}
        </Typography>
      ) : null
    );
    return (
      // Required for all blocks
      <div className="login-screen-main-body">
        <ThemeProvider theme={theme}>
          <Grid
            container
            style={webStyle.mainContainer}
            className="main-container"
          >
            <Grid item xs={12} md={6}>
              <Paper
                style={webStyle.sliderImage}
                className="slider-image p-relative h-100p"
              >
                <img
                  src={landingImage.landingImage1}
                  className={`w-100p h-100p `}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid
                container
                style={webStyle.logoAndBackContainer}
                className="logo-and-back-container"
              >
                <Grid item xs={6} style={webStyle.displayFlex}>
                  <img src={imgCompanyLogo} style={webStyle.logoImg} />
                </Grid>
                <Formik
                  initialValues={{
                    email: "",
                    mobile_no: "",
                    password: "",
                    country_code: "",
                  }}
                  validationSchema={SignupSchema}
                  onSubmit={(values) => {
                    this.handleFormikSubmit(values)
                  }}
                >
                  {({
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    values,
                  }) => {
                    const oneField = this.setErrorOfOneFieldRequire(values,touched.email, touched.mobile_no, touched.country_code);
                    const countryError = this.countryValidationError(!values.country_code,!!values.mobile_no,!!touched.mobile_no, !values.email);
                    const emailError = this.errorHandling(errors.email , touched.email);
                    const mobileError = this.mobileValidationError(!values.mobile_no, !!touched.mobile_no, !!values.country_code, !!touched.country_code, !values.email);
                    const passwordError = this.errorHandling(errors.password , touched.password);
                    return(
                    <Box
                      style={webStyle.leftContainer}
                      className="left-container"
                    >
                      <form className="login-form">
                        <Typography
                          variant="h4"
                          component="h2"
                          style={webStyle.loginTitle}
                          className="login-title"
                        >
                          {this.state.labelTitleLogin}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          style={webStyle.headerLogin}
                          className="header-title"
                        >
                          {this.state.labelHeaderLogin}{" "}
                          {/*UI Engine::From Sketch*/}
                        </Typography>
                        <Box className="form-main-box">
                          <OutlinedInput
                            placeholder={"Email address*"}
                            data-test-id="txtInputEmailField"
                            className={this.getInputEmailClassName(!!emailError, !!oneField)}
                            fullWidth={true}
                            name="email"
                            value={values.email}
                            data-testId="emailLogintest"
                            onChange={(e)=>{handleChange({target:{name:"email",value:e.target.value.replace(/ /g, "")}})}}
                          />
                          {renderEmailError(emailError, errors.email)}
                          <Typography
                            style={{
                              fontSize: "14px",
                              color: "black",
                              textAlign: "center",
                              marginTop: "25px",
                              fontWeight: 700
                            }}
                          >
                            {configJSON.labelOr}
                          </Typography>

                          <div style={{ display: "flex" }}>
                            <Select
                              style={{
                                borderRadius: "10px",
                                backgroundColor: "#eaeef8",
                                height: "57px",
                                marginRight: "10px",
                                width: "110px",
                                marginTop: "25px",
                                textAlign: "center",
                                paddingLeft: "10px",
                              }}
                              data-testing-id="select-input-country"
                              className={"country-select"}
                              name="country_code"
                              value={this.state.selectedCountryCode}
                              onChange={(e)=>{
                                this.setState({selectedCountryCode:`${e.target.value}`})
                                handleChange(e)
                              }}
                            >
                              {this.phoneNumberArray.map((item) => {
                                return (
                                  <MenuItem
                                    value={item.countryCode}
                                    key={
                                      item.countryName +
                                      "phone-country-dropdown"
                                    }
                                    style={{ display: "flex" }}
                                  >
                                    <img src={item.flag} /> {item.countryCode}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <OutlinedInput
                              placeholder={"Mobile number*"}
                              data-test-id="txtInputMobile"
                              className={this.getInputEmailClassName(!!mobileError,oneField)}
                              fullWidth={true}
                              type="number"
                              name="mobile_no"
                              value={values.mobile_no}
                              data-testId="emailLogintest"
                              onChange={(e)=>{this.handlePhoneNoLength(e,handleChange)}}
                            />
                          </div>
                          {countryError || mobileError || oneField ? (
                            <Typography style={webStyle.emailError}>
                              {mobileError ? configJSON.enterMobileLabel : ""}
                              {oneField ? configJSON.atleatOneIsRequireLabel : ""}
                            </Typography>
                          ) : null}

                          <OutlinedInput
                            data-test-id="txtInputPassword"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={this.handleClickShowPassword}
                                  data-test-id="show-password-button"
                                  edge="end"
                                >
                                  {this.state.enablePasswordField ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            type={
                              this.state.enablePasswordField
                                ? "password"
                                : "text"
                            }
                            placeholder={"Password*"}
                            className={this.getInputEmailClassName(passwordError,false)}
                            fullWidth={true}
                            value={values.password}
                            onChange={(e)=>{handleChange({target:{name:"password",value:e.target.value.replace(/ /g, "")}})}}
                            data-testId="passwordLogintest"
                            name="password"
                          />
                          {renderEmailError(passwordError, errors.password)}
                          <Typography style={webStyle.emailError}>{this.state.apiErrorMsg}</Typography>
                          <Box style={webStyle.forgotAndRememberBox}>
                            <Box
                              sx={{
                                fontSize: "14px",
                                color: "#7a7c81",
                              }}
                            >
                              <Checkbox
                                data-test-id={"btnRememberMe"}
                                onClick={() =>
                                  this.setRememberMe(
                                    !this.state.checkedRememberMe
                                  )
                                }
                                checked={this.state.checkedRememberMe}
                                onChange={handleChange}
                                style={webStyle.checkbox}
                              />{" "}
                              {configJSON.labelRememberMe}
                            </Box>
                            <Box
                              data-test-id={"btnForgotPassword"}
                              sx={{
                                fontSize: "14px",
                                color: "#4166F8",
                                marginTop: "12px",
                              }}
                              style={webStyle.forgotPassword}
                              onClick={() => this.goToForgetPasswordblock()}
                              data-testId="forgotPasswordTest"
                            >
                              {configJSON.labelForgetPassword}
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              data-test-id="btnEmailLogIn"
                              variant="contained"
                              className="login-btn"
                              style={webStyle.loginButton}
                              fullWidth
                              onClick={() => handleSubmit()}
                            >
                              {configJSON.btnTxtLogin} {/*UI Engine::From Sketch*/}
                            </Button>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "25px",
                              marginBottom: "5px",
                            }}
                            style={webStyle.dontHaveAccountBox}
                          >
                            <Typography
                              style={{
                                fontFamily: "Roboto, sans-serif",
                                color: "#7a7c81",
                              }}
                              className="dont-have-account"
                            >
                              {configJSON.dontHaveAccountLabel}
                            </Typography>
                            <Typography
                              style={webStyle.createAnAccount}
                              onClick={this.goToRegistration}
                              data-testId="createAccountTest"
                              className="create-account"
                            >
                              {configJSON.createAccountLabel}
                            </Typography>
                          </Box>
                        </Box>
                      </form>
                    </Box>
                  )}}
                </Formik>
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      </div>
    );
  }
}

const webStyle: any = {
  mainContainer: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    display: "flex",
    flexGrow: 1,
    width: "100%",
    height: "100%",
  },
  forgotAndRememberBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2%",
    marginBottom: "2%",
    fontFamily: "'Roboto', sans-serif",
  },
  inputBoxes: {
    borderRadius: "7px",
    backgroundColor: "#f4f6fb",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "18px",
    marginTop: "20px",
    height: "51px",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "100px auto",
    justifyContent: "space-evenly",
    width: "60%",
  },
  loginTitle: {
    fontFamily: "'Darker Grotesque', sans-serif",
    fontWeight: "600",
    color: "#212330",
    fontSize: "44",
  },
  headerLogin: {
    fontFamily: "'Roboto', sans-serif",
    color: "#3C4557",
    fontSize: "20",
  },
  emailError: {
    color: "#ff6666",
    fontSize: "14px",
    marginBottom: "-20px",
    display: "inline",
  },
  passwordError: {
    color: "#ff6666",
    fontSize: "14px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  checkbox: {
    color: "#a7a7a7",
    marginTop: "-2px",
    marginLeft: "0px",
    padding: "0px"
  },
  forgotPassword: {
    textDecoration: "underline",
    fontFamily: "'Roboto', sans-serif",
    cursor: "pointer",
    fontSize: "15px"
  },
  loginButton: {
    backgroundColor: "#4166F8",
    color: "white",
    textTransform: "capitalize",
    fontWeight: "bold",
    height: "52px",
    marginTop: "30px",
    marginBottom: "10px",
    borderRadius: "10px",
    fontSize: "18px",
  },
  createAnAccount: {
    textDecoration: "underline",
    color: "#4166F8",
    fontFamily: "Roboto, sans-serif",
    marginLeft: "2px",
    cursor: "pointer",
    fontWeight: "600",
    paddingLeft: "5px"
  },
  inputBoxesError: {
    borderRadius: "10px",
    backgroundColor: "#f4f6fb",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "18px",
    marginTop: "20px",
    border: "1px solid red",
  },
  logoAndBackContainer: {
    padding: "20px",
  },
  displayFlex: {
    display: "flex",
    justifyContent: "flex-end",
  },
  logoImg: {
    width: "145px",
    left: "90%",
    top: "5%",
    position: "relative",
  },
  sliderImage: {
    borderRadius: "10px 0px 0px 10px",
    justifyContent: "flex-start",
    display: "flex",
    height: "100%",
    position: "relative",
    top: 0,
    bottom: 0,
  },
  dotContainer: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "10px 0px 0px 10px",
    alignItems: "center",
    position: "absolute",
    backgroundColor: "solid red",
  },
  dot: {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    margin: "0 5px",
    cursor: "pointer",
    backgroundColor: "#bbb",
  },
  activeDot: {
    backgroundColor: "#0000ff",
  },
};
