import React from "react";
import {
  Typography,
  Grid,
  Button,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import GenericSearch from "../../../components/src/GenericSearch.web";
import CustomIconTabPanel from "../../../components/src/CustomIconTabPanel.web";
import CustomerTable from "../../../components/src/CustomTable.web";
import GenericDialog from "../../../components/src/GenericDialog.web";
import GenericCloseDialog from "../../../components/src/GenericCloseDialog.web";
import GenericSuccessDialog from "../../../components/src/GenericSuccessDialog.web";
import GenericButton from "../../../components/src/GenericButton.web";

import MyClaimsController, {
  Props,
  configJSON,
  images,
} from "./MyClaimsController.web";
import "./MyClaims.web.css";

export default class MyClaims extends MyClaimsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const claimsDialogContent = (
      <div className="upload-claims-container">
        <div>
          <div className="text-input-label">{configJSON.claimType}</div>
          <FormControl variant="outlined" fullWidth>
            <Select
              fullWidth
              IconComponent={KeyboardArrowDownRoundedIcon}
              className="upload-claims-dropdown"
              value={this.state.selectedClaimType}
              onChange={this.handleChangeClaimType}
              data-test-id="claim-type"
              error={!!this.state.selectedClaimTypeError}
            >
              {this.state.tabIconList.map((item) => (
                <MenuItem value={item.key} key={item.key + " claim-type"}>
                  {item.key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {this.state.selectedClaimTypeError && (
            <Typography
              variant="subtitle1"
              component="div"
              className="text-info-red"
              data-test-id="claim-type-error-message"
            >
              {this.state.selectedClaimTypeError}
            </Typography>
          )}
        </div>
        <div>
          <input
            accept="image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            type="file"
            id="claim-file-upload-input"
            style={{ display: "none" }}
            onChange={this.handleChangeClaimFile}
            data-test-id="claim-file"
          />
          <label htmlFor="claim-file-upload-input">
            <div
              className={`claim-file-upload-field ${
                this.state.selectedClaimFile ? " file-added" : ""
              } ${this.state.selectedClaimFileNameError ? "error" : ""}`}
            >
              {this.state.selectedClaimFileName || configJSON.chooseAFile}
            </div>
          </label>
          {this.state.selectedClaimFileNameError && (
            <Typography
              variant="subtitle1"
              component="div"
              className="text-info-red"
              data-text-id="claim-type-error-message"
            >
              {this.state.selectedClaimFileNameError}
            </Typography>
          )}
        </div>
      </div>
    );

    const claimsDialogActions = (
      <>
        <Button
          className="upload-claim-cancel-btn"
          variant="outlined"
          onClick={this.onCloseClaimsDialog}
          data-test-id="cancel-claim"
        >
          {configJSON.cancel}
        </Button>
        <GenericButton
          variant="contained"
          color="primary"
          onClick={this.onSaveClaimsData}
          data-test-id="submit-claim"
        >
          {configJSON.submitClaim}
        </GenericButton>
      </>
    );

    return (
      <>
        <Grid container className="my-claims-right-side-container">
          <Grid item xs={12} sm={12} md={12} lg={5} className="d-flex">
            <Typography variant="body1" className="greeting-name">
              {configJSON.welcome} Vikas Member
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7} className="white-box-grid">
            <Button className="white-box">{configJSON.myIdCard}</Button>
            <Button
              data-test-id="my-claims-find-healthcare"
              className="white-box"
              onClick={() =>
                this.props.navigation.navigate("FindHealthcare")
              }
            >
              {configJSON.findHealthcare}
            </Button>
            <Button className="white-box">{configJSON.benefitStatement}</Button>
          </Grid>
          <Grid item xs={12} className="claims-search-btn">
            <div data-test-id="tab-heading" className="selected-claims-name">
              {this.state.tabIndex} Claims
            </div>
            <div className="search-bar-and-add-btn p-relative">
              <GenericSearch
                data-test-id="my-claims-search"
                searchIcon={images.searchIcon}
                placeholder={configJSON.search}
                value={this.state.searchValue}
                onChange={this.onSearchInputChange}
              />
              <div className="search-filter-btn">
                <Button data-test-id="filter-btn" className="filter-icon">
                  <img src={images.filterIcon} alt="Filter Icon" />
                </Button>
                <Button
                  data-test-id="submit-new-claim-btn"
                  className="search-btn"
                  onClick={this.onOpenClaimsDialog}
                >
                  {configJSON.submitNewClaim}
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className="hr mt-30" />
          <Grid item xs={12} className="mt-20">
            <CustomIconTabPanel
              tabList={this.state.tabIconList}
              isDisplayAllTab
              allTabLabel={configJSON.allClaims}
              onChange={this.onTabIndexChange}
            >
              <CustomerTable
                columns={this.tableColumns}
                rowData={this.state.rowData}
                isRowExpandable
                innerColumns={this.tableInnerColumns}
                onActionClick={()=>{}}
              />
              {this.state.tabIconList.map((tab) => (
                <CustomerTable
                  columns={this.tableColumns}
                  rowData={this.state.rowData}
                  onActionClick={()=>{}}
                  key={tab.key}
                  isRowExpandable
                  innerColumns={this.tableInnerColumns}
                />
              ))}
            </CustomIconTabPanel>
          </Grid>
        </Grid>
        {this.state.isOpenClaimsDialog && (
          <GenericDialog
            title={configJSON.uploadClaimFile}
            open={this.state.isOpenClaimsDialog}
            handleClose={this.onCloseClaimsDialog}
            contains={claimsDialogContent}
            actions={claimsDialogActions}
            hideCloseDialog={true}
            maxWidth="sm"
            className="my-claims-upload-claims-dialog"
            data-test-id="upload-claims-file"
          />
        )}
        {this.state.isOpenCloseWarningDialog && (
          <GenericCloseDialog
            open={this.state.isOpenCloseWarningDialog}
            handleClose={this.handleCloseDialogClose}
            handleOk={() => this.handleCloseDialogOk()}
          />
        )}
        {this.state.isUploadClaimDataSaved && (
          <GenericSuccessDialog
            open={this.state.isUploadClaimDataSaved}
            handleDone={this.handleUploadClaimDone}
          />
        )}
      </>
    );
  }
}
