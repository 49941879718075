import React from "react";
import "./memberScreenMyDetails.css"
import {
    Box,
    Button,
    Typography,
    Divider,
    Radio,
    RadioGroup,
    FormControlLabel,
    Dialog,
    IconButton,
    Grid,
    TextField,
    Stepper,
    Step,
    StepButton,
    Select,
    MenuItem,
    DialogContent,
    DialogContentText,
    DialogActions,
    Tooltip,
    FormControl,
    InputLabel,
} from "@material-ui/core";
import { withStyles, Theme, } from "@material-ui/core/styles";
import { HealthcareProviderLogo, MyBenefitStatementLogo, arrowDownBlueIcon, editPencilIcon, memberDetailBlueBackground } from "./assets";
import Visibility from "@material-ui/icons/Visibility";
import CloseIcon from '@material-ui/icons/Close';
import CallRoundedIcon from '@material-ui/icons/CallRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import GenericSuccessDialog from "../../../components/src/GenericSuccessDialog.web";


import LandingPageController, {
    Props,
    configJSON
} from "./LandingPageController.web";

export default class MemberScreenMyDetails extends LandingPageController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const MemberFullInfoHoverTooltip = withStyles((theme: Theme) => ({
            tooltip: {
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: '#fff',
                borderRadius: "8px",
                minWidth: "300",
                fontSize: "16px",
                border: "none",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: 600,
                textAlign: "center",
                position: "absolute",
                right: "-5px",
                top: "-15px"
            },
        }))(Tooltip);

        return (
            <Box className="member_screen-my_detail">
                <Box className={this.state.isIdCardBtnClicked ? "display_none" : "member_myDetails_box"}>
                    <Box className="welcome_container">
                        <Box className="welcome_container_left">
                            <Typography variant="h3" className="welcome_text_1">{`Welcome ${this.state.memberFullInfo.memberInfo_firstName} ${this.state.memberFullInfo.memberInfo_lastName}`}</Typography>
                            <Typography className="welcome_text_2">Your all in one benefits portal</Typography>
                        </Box>
                        <Box className="welcome_container_right">
                            <Box className="healthcare_provider_box">
                                <Button data-testId="member-myDetails-healthcare-provider-btn" onClick={() => this.props.handleClick && this.props.handleClick("FindHealthcare", "FindHealthcare")}>
                                    <Box>
                                        <Typography className="healthcare_provider_text1">Find a <span style={{ color: "#7978FF" }}>Healthcare Provider</span></Typography>
                                        <Typography className="healthcare_provider_text2">Find Now {'>>'}</Typography>
                                    </Box>
                                    <Box>
                                        <img className="healthcare_provider_img" src={HealthcareProviderLogo} style={{ maxWidth: "78px", maxHeight: "78px" }} />
                                    </Box>

                                </Button>
                            </Box>
                            <Box className="benefits_statement_box">
                                <Button>
                                    <Box>
                                        <Typography className="benefits_statement_text1">My <span style={{ color: "#3BB964" }}>Benefit</span> Statement</Typography>
                                        <Typography className="benefits_statement_text2">Details {'>>'}</Typography>
                                    </Box>
                                    <Box>
                                        <img className="mybenefit_statement_img" src={MyBenefitStatementLogo} style={{ maxHeight: "150px" }} />
                                    </Box>

                                </Button>
                            </Box>
                        </Box>

                    </Box>
                    <Box className="member_info_container">
                        <Box className="profile_container">
                            <img src={memberDetailBlueBackground} className="member_detail_blue_background" />
                            <Box className="profile_img_and_name">
                                <Box className="profile_pic">
                                    {this.state.memberFullInfo.memberInfo_profile_pic && <img src={this.state.memberFullInfo.memberInfo_profile_pic} alt="member profile pic" />}
                                </Box>
                                <Box className="profile_name-no">
                                    <Typography className="profile_name profile_lable">{`${this.state.memberFullInfo.memberInfo_firstName} ${this.state.memberFullInfo.memberInfo_lastName}`}</Typography>
                                    <Typography className="profile_no lable_value">Member No: {this.state.memberFullInfo.memberInfo_memberNumber}</Typography>
                                </Box>
                                <Button className="profile_edit_btn" onClick={this.handleMemberEditBtn}>
                                    <img src={editPencilIcon} alt="" style={{ width: "34px", height: "34px" }} />
                                </Button>
                                <Button className="profile_id_card_btn" onClick={() => this.setState({ isIdCardBtnClicked: true })}>
                                    <Visibility style={{ marginRight: "5px", width: "20px", height: "20px" }} />
                                    ID Card
                                </Button>
                            </Box>
                            <Box className="profile_details">
                                <Box className="profile_gender">
                                    <Divider orientation="vertical" className="profile_divider" />
                                    <Box>
                                        <Typography className="gender_lable profile_lable">Gender</Typography>
                                        <Typography className="gender_value lable_value">{this.state.memberFullInfo.memberInfo_gender}</Typography>
                                    </Box>
                                </Box>

                                <Box className="profile_DOB">
                                    <Divider orientation="vertical" className="profile_divider" />
                                    <Box>
                                        <Typography className="DOB_lable profile_lable">Date of Birth</Typography>
                                        <Typography className="DOB_value lable_value">{this.state.memberFullInfo.memberInfo_dob}</Typography>
                                    </Box>

                                </Box>
                                <Box className="profile_age">
                                    <Divider orientation="vertical" className="profile_divider" />
                                    <Box>
                                        <Typography className="age_lable profile_lable">Age</Typography>
                                        <Typography className="age_value lable_value">{this.state.memberFullInfo.memberInfo_age}</Typography>
                                    </Box>

                                </Box>
                            </Box>
                            <Box className="profile_details_right">
                                <Box className="profile_marital_status">
                                    <Divider orientation="vertical" className="profile_divider" />
                                    <Box>
                                        <Typography className="marital_status_lable profile_lable">Marital status</Typography>
                                        <Typography className="marital_status_value lable_value">{this.state.memberFullInfo.memberInfo_maritalStatus}</Typography>
                                    </Box>
                                </Box>

                                <Box className="profile_mobile_no">
                                    <Divider orientation="vertical" className="profile_divider" />
                                    <Box>
                                        <Typography className="mobile_no_lable profile_lable">mobile no</Typography>
                                        <Typography className="mobile_no_value lable_value">{this.state.memberFullInfo.memberInfo_mobileNumber}</Typography>
                                    </Box>
                                    <MemberFullInfoHoverTooltip
                                        title={ this.showTooltipTitle()}
                                        placement="bottom"
                                    >
                                        <Button style={{ marginLeft: "10px" }} className="profile_full_info_btn" onClick={() => this.setState({ showMemberFullInfo: !this.state.showMemberFullInfo })}>
                                            <img src={arrowDownBlueIcon} className={this.state.showMemberFullInfo ? "rotate180" : ""} />
                                        </Button>
                                    </MemberFullInfoHoverTooltip>

                                </Box>
                            </Box>
                        </Box>
                        {this.state.showMemberFullInfo &&
                            <Box className="member_fullInfo_container">
                                <Box className="main_fullInfo_firstBox">
                                    <Box className="main_fullInfo_singleBox">
                                        <Box>
                                            <Typography className="main_fullInfo_lable">
                                                Client Name
                                            </Typography>
                                            <Typography className="main_fullInfo_lable_value">
                                                {this.state.memberFullInfo.memberInfo_clientName}
                                            </Typography>
                                        </Box>

                                    </Box>
                                    <Box className="main_fullInfo_singleBox">
                                        <Divider orientation="vertical" className="profile_divider" />
                                        <Box>
                                            <Typography className="main_fullInfo_lable">
                                                Employer Name
                                            </Typography>
                                            <Typography className="main_fullInfo_lable_value">
                                                {this.state.memberFullInfo.memberInfo_employerName}
                                            </Typography>
                                        </Box>

                                    </Box>

                                    <Box className="main_fullInfo_singleBox">
                                        <Divider orientation="vertical" className="profile_divider" />
                                        <Box>
                                            <Typography className="main_fullInfo_lable">
                                                Group Name
                                            </Typography>
                                            <Typography className="main_fullInfo_lable_value">
                                                {this.state.memberFullInfo.memberInfo_groupName}
                                            </Typography>
                                        </Box>

                                    </Box>
                                    <Box className="main_fullInfo_singleBox">
                                        <Divider orientation="vertical" className="profile_divider" />
                                        <Box>
                                            <Typography className="main_fullInfo_lable">
                                                Division Name
                                            </Typography>
                                            <Typography className="main_fullInfo_lable_value">
                                                {this.state.memberFullInfo.memberInfo_divisionName}
                                            </Typography>
                                        </Box>

                                    </Box>
                                    <Box className="main_fullInfo_singleBox">
                                        <Divider orientation="vertical" className="profile_divider" />
                                        <Box>
                                            <Typography className="main_fullInfo_lable">
                                                Location Name
                                            </Typography>
                                            <Typography className="main_fullInfo_lable_value">
                                                {this.state.memberFullInfo.memberInfo_locationName}
                                            </Typography>
                                        </Box>

                                    </Box>
                                    <Box className="main_fullInfo_singleBox">
                                        <Divider orientation="vertical" className="profile_divider" />
                                        <Box>
                                            <Typography className="main_fullInfo_lable">
                                                SSN
                                            </Typography>
                                            <Typography className="main_fullInfo_lable_value">
                                                {this.state.memberFullInfo.memberInfo_ssn}
                                            </Typography>
                                        </Box>

                                    </Box>
                                    <Box className="main_fullInfo_singleBox">
                                        <Divider orientation="vertical" className="profile_divider" />
                                        <Box>
                                            <Typography className="main_fullInfo_lable">
                                                Other ID
                                            </Typography>
                                            <Typography className="main_fullInfo_lable_value">
                                                {this.state.memberFullInfo.memberInfo_otherId}
                                            </Typography>
                                        </Box>

                                    </Box>
                                    <Box className="main_fullInfo_singleBox">
                                        <Divider orientation="vertical" className="profile_divider" />
                                        <Box>
                                            <Typography className="main_fullInfo_lable">
                                                Other Desc
                                            </Typography>
                                            <Typography className="main_fullInfo_lable_value">
                                                {this.state.memberFullInfo.memberInfo_otherDesc}
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Box>
                                <Divider style={{ borderBottom: "#C2CFE1 dashed", background: "none", margin: "20px 0" }} />
                                <Box className="member_fullInfo_address_container">
                                    <Box className="member_physical_address">
                                        <Typography className="address_lable">Physical Address</Typography>
                                        <Typography className="address_line1">{this.state.memberFullInfo.memberInfo_physicalAddressLine1}</Typography>
                                        <Typography className="address_line2">{this.state.memberFullInfo.memberInfo_physicalAddressLine2}</Typography>
                                        <Typography className="rest_address">
                                            <span style={{ marginRight: "5px" }}>{`City/Town: ${this.state.memberFullInfo.memberInfo_physicalCity} |`}</span>
                                            <span style={{ marginRight: "5px" }}>{`State/County: ${this.state.memberFullInfo.memberInfo_physicalState} |`}</span>
                                            <span style={{ marginRight: "5px" }}>{`Zip/ Postal Code: ${this.state.memberFullInfo.memberInfo_physicalZipcode} |`}</span>
                                            <span style={{ marginRight: "5px" }}>{`Country: ${this.state.memberFullInfo.memberInfo_physicalCountry}`}</span>
                                        </Typography>
                                    </Box>
                                    <Box className="member_mailing_address">
                                        <Typography className="address_lable">Mailing Address</Typography>
                                        <Typography className="address_line1">{this.state.memberFullInfo.memberInfo_mailingAddressLine1}</Typography>
                                        <Typography className="address_line2">{this.state.memberFullInfo.memberInfo_mailingAddressLine2}</Typography>
                                        <Typography className="rest_address">
                                            <span style={{ marginRight: "5px" }}>{`City/Town: ${this.state.memberFullInfo.memberInfo_mailingCity} |`}</span>
                                            <span style={{ marginRight: "5px" }}>{`State/County: ${this.state.memberFullInfo.memberInfo_mailingState} |`}</span>
                                            <span style={{ marginRight: "5px" }}>{`Zip/ Postal Code: ${this.state.memberFullInfo.memberInfo_mailingZipcode} |`}</span>
                                            <span style={{ marginRight: "5px" }}>{`Country: ${this.state.memberFullInfo.memberInfo_mailingCountry}`}</span>
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className="member_tableInfo_container dependentInfo_table_container">
                                    <Typography className="member_tableInfo_lable">
                                        <span>Dependent Info:</span>
                                        <span className="add_dependent_btn" data-testId="add-dependent-btn" onClick={this.handleAddDependent}>+ Add Dependent</span>
                                    </Typography>
                                    {this.showTableInfo(configJSON.dependentInfoTableColumn, this.state.dependentInfoList)}
                                </Box>
                                <Box className="member_tableInfo_container beneficiaryInfo_table_container">
                                    <Typography className="member_tableInfo_lable">
                                        <span>Beneficiary Info:</span>
                                        <span className="add_dependent_btn" data-testId="add-beneficiary-btn" onClick={this.handleAddBeneficiary}>+ Add Beneficiary</span>
                                    </Typography>
                                    {this.showTableInfo(configJSON.beneficiaryInfoTableColumn, this.state.beneficiaryInfoList)}
                                </Box>
                            </Box>
                        }
                    </Box>
                    <Box className="member_benefits_container">
                        {this.state.memberBenefitsDetails?.map((memberBenefit) => {
                            return (
                                <Box className="benefits_box" key={memberBenefit.label}>
                                    <img src={memberBenefit.icon} className="benefits_circular_img" />
                                    <img src={memberBenefit.background} className="benefits_background_img" />
                                    <Typography className="benefits_text" style={{ zIndex: 1 }}>{memberBenefit.textLine1}</Typography>
                                    <Typography className="benefits_text" style={{ zIndex: 1 }}>{memberBenefit.textLine2}</Typography>
                                </Box>
                            )
                        })}
                    </Box>

                    <Dialog
                        className="edit-member-details-dialog"
                        maxWidth="lg"
                        fullWidth
                        open={this.state.showMemberInfoEditModal}
                        scroll="body"
                    >
                        <div className="edit-member-dialog-wrapper">
                            <div className="edit-member-flex-wrapper">
                                <div className="member-dialog-title">Edit Info</div>
                                <div>
                                    <IconButton size="small" className="edit_member_dialog_closeBtn" onClick={this.handleDialogClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <Divider className="member-dialog-flex-divider" />
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6} md={4} >
                                    <Stepper
                                        activeStep={this.state.formActiveStep}
                                        orientation="vertical"
                                        nonLinear
                                    >
                                        {this.state.editMemberDetailsStepList.map(
                                            (memberDetailStep, index) => {
                                                const buttonProps: { optional?: React.ReactNode } = {};
                                                buttonProps.optional = (
                                                    <div className="member-dialog-stepper-description">
                                                        {memberDetailStep.description}
                                                    </div>
                                                );
                                                return (
                                                    <Step key={memberDetailStep.title}>
                                                        <StepButton
                                                            {...buttonProps}
                                                            className="member-my-details-step-button"
                                                            onClick={() =>
                                                                this.setState({ ...this.state, formActiveStep: index })
                                                            }
                                                        >
                                                            {memberDetailStep.title}
                                                        </StepButton>
                                                    </Step>
                                                );
                                            }
                                        )}
                                    </Stepper>
                                </Grid>
                                <Grid item xs={6} sm={6} md={8} className="member-edit-form-wrapper">
                                    {(() => {
                                        switch (this.state.formActiveStep) {
                                            case 0:
                                                return this.showEditMemberInfoForm();
                                            case 1:
                                                return this.showAddDependentInfoForm();
                                            case 2:
                                                return this.showAddBeneficiaryInfoForm();
                                            case 3:
                                                return this.showEditProfilePictureForm();
                                            case 4:
                                                return this.showWellnessForm();
                                            default:
                                                return null;
                                        }
                                    })()}
                                </Grid>
                            </Grid>
                            <Divider style={{ margin: "40px 0 25px" }} />
                            <div className="edit-member-flex-wrapper edit-member-action-flex">
                                <div>
                                    {this.state.formActiveStep <= 4 && (
                                        <Button
                                            variant="contained"
                                            className="edit-member-save-next-btn"
                                            onClick={this.saveButNotNext}
                                            data-testId="member-form-saveBtn"
                                        >
                                            Save
                                        </Button>
                                    )}
                                </div>
                                <div>
                                    <IconButton
                                        disabled={this.state.formActiveStep <= 0}
                                        className="member-dialog-previous-btn"
                                        onClick={this.handlePrevious}
                                        data-testId="stepper-button-prev"
                                    >
                                        <KeyboardArrowLeftRoundedIcon fontSize="large" />
                                    </IconButton>
                                    {this.state.formActiveStep < 4 && (
                                        <IconButton
                                            // disabled={this.state.formActiveStep >= 2}
                                            className="member-dialog-next-btn"
                                            onClick={this.saveAndNext}
                                            data-testId="stepper-button-saveAndNext"
                                        >
                                            Save & Next
                                        </IconButton>
                                    )}
                                    {this.state.formActiveStep === 4 && (
                                        <Button
                                            variant="contained"
                                            className="edit-member-save-next-btn"
                                        // onClick={this.saveAndNext}
                                        >
                                            Submit Changes
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <GenericSuccessDialog
                            open={this.state.isFormUpdated}
                            handleDone={this.handleSuccessDialogClose}
                        />
                    </Dialog>
                    <Dialog
                        className="member-details-cancle_confirmation_dialog"
                        open={this.state.open_cancle_confirmation_dialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" className="cancle_confirmation_dialog_text">
                                Are you sure you want to cancel,
                                your changes will not be saved.
                            </DialogContentText>
                        </DialogContent>
                        <Divider />
                        <DialogActions style={{ height: "30%" }}>
                            <Button className="dialog_cancle_button" onClick={this.handleCancleConfirmationDialogClose}>
                                Cancel
                            </Button>
                            <Button className="dialog_ok_button" onClick={this.handleCancleConfirmationDialogOkButton}>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
                <Box className={this.state.isIdCardBtnClicked ? "member_idCard_screen" : "display_none"}>
                    <Grid container className="idCard_screen_header" spacing={2}>

                        <Grid item xs={12} sm={12} md={12} lg={4} className="idCard_screen_header_left">
                            <Button className="idCard_backBtn" onClick={() => this.setState({ isIdCardBtnClicked: false })}>
                                <ArrowBackIosRoundedIcon style={{ width: "30px", height: "30px" }} />
                            </Button>
                            <Typography className="greeting-name">
                                Welcome {`${this.state.memberFullInfo.memberInfo_firstName} ${this.state.memberFullInfo.memberInfo_lastName}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={8} className="idCard_screen_header_right">
                            <Button
                                onClick={() => this.setState({ isMyIdCardScreenActive: true, isBenefitStatementScreenActive: false })}
                                className={this.state.isMyIdCardScreenActive ? "idCard_screen_header_btn my_idCard_btn active" : "idCard_screen_header_btn my_idCard_btn"}
                                data-testId="my-idCard-btn"
                            >
                                My Id Card
                            </Button>
                            <Button
                                data-testId="my-details-idCard-find-healthcare"
                                className="idCard_screen_header_btn"
                                onClick={() => this.props.handleClick && this.props.handleClick("FindHealthcare", "FindHealthcare")}
                            >
                                Find a Healthcare Provider
                            </Button>
                            <Button
                                data-testId="benefit-statement-btn"
                                className="idCard_screen_header_btn benefit_statement_btn"
                            >
                                Benefit Statement
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container className="fax_and_request_member_idCard" spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={6} className="fax_idCard">
                            <TextField
                                id="outlined-basic"
                                label="Fax Number"
                                variant="outlined"
                                type="number"
                                name="idCardScreen_faxNumber"
                                className="faxNumber"
                                value={this.state.idCardScreen_faxNumber}
                                onChange={this.handleFormInputsOnChange}
                            />
                            <FormControl variant="outlined">
                                <InputLabel id="demo-simple-select-outlined-label" className="benefit_schedule_label">Benefit Schedule</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    name="benefitSchedule"
                                    value={this.state.benefitSchedule}
                                    className="benefit_schedule"
                                    onChange={this.handleFormInputsOnChange}
                                    label="Benefit"
                                >
                                    <MenuItem className="member_form_list" value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem className="member_form_list" value="medical">Medical</MenuItem>
                                    <MenuItem className="member_form_list" value="dental">Dental</MenuItem>
                                    <MenuItem className="member_form_list" value="vision">Vision</MenuItem>
                                </Select>
                            </FormControl>
                            <Button variant="contained" color="primary" className="send_fax_btn">
                                Send Fax
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} className="request_idCard">
                            <Button variant="contained" color="primary" className="request_idCard_btn" onClick={() => this.setState({ openRequestIdCardModal: true })}>
                                Request ID Card
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider style={{ margin: "30px 0", backgroundColor: "rgba(0, 0, 0, 0.2)" }} />

                    <Box className={this.state.isMyIdCardScreenActive ? "idCard_screen_detail_box" : "display_none"}>
                        <Grid container className="my_idCard_container" spacing={4}>
                            <Grid item xs={12} sm={12} md={12} lg={6} className="idCard_front_view">
                                <Typography className="idCard_view_label">Front View</Typography>
                                <Box className="idCard_view_box front_view">
                                    <Box className="id_frontView_header">
                                        <Box className="idCard_logo">
                                            <img src="" alt="" />
                                        </Box>
                                        <Box className="id_frontView_header_text">Medical Health Plan</Box>
                                    </Box>
                                    <Box className="frontView_middleBox">
                                        <Box className="frontView_middleBox_text">
                                            <Box className="frontView_middleBox_heading_text">Group#</Box>
                                            <Box className="frontView_middleBox_value_text">4371</Box>
                                        </Box>
                                        <Box className="frontView_middleBox_text">
                                            <Box className="frontView_middleBox_heading_text">Member#</Box>
                                            <Box className="frontView_middleBox_value_text">4371345354</Box>
                                        </Box>
                                        <Box className="frontView_middleBox_text">
                                            <Box className="frontView_middleBox_heading_text">Member</Box>
                                            <Box className="frontView_middleBox_value_text">Builder ai Member</Box>
                                        </Box>
                                    </Box>
                                    <Divider style={{ margin: "30px 0", backgroundColor: "rgba(0, 0, 0, 0.2)" }} />
                                    <Box className="frontView_detailed_text">
                                        <Typography>
                                            Impatient and outpatient hospitaliztion stays, MRI/CAT scans, substance abuse, mental health, skilled nursing facility, home health, physical speech or occupational services must be precertified by calling our precert line at 1-866-415-2609 or reimbursement may be reduced.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={6} className="idCard_back_view">
                                <Typography className="idCard_view_label">Back View</Typography>
                                <Box className="idCard_view_box back_view">
                                    <Box className="backView_upperBox">
                                        <Box style={{ marginBottom: "20px" }} className="backView_upper_heading_box">
                                            <Box className="backView_heading">For Coverage and Claim Information</Box>
                                            <Box className="backView_upper_call">
                                                <CallRoundedIcon style={{ color: "#7583a0" }} />
                                                <span className="backView_tags_heading_text" style={{ fontSize: "18px" }}>Call:</span>
                                                <span className="backView_tags_value_text">3052228654</span>
                                            </Box>
                                        </Box>

                                        <Box className="backView_upper_detail">
                                            <Box className="backView_tags">
                                                <Box className="backView_tags_heading_text">Group Number:</Box>
                                                <Box className="backView_tags_value_text">1616</Box>
                                            </Box>
                                            <Box className="backView_tags">
                                                <Box className="backView_tags_heading_text">Pharmacy Assistance Cell:</Box>
                                                <Box className="backView_tags_value_text">986545354</Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Divider style={{ margin: "30px 0", backgroundColor: "rgba(0, 0, 0, 0.2)" }} />
                                    <Box className="backView_middle_detail">
                                        <Box style={{ marginBottom: "10px" }} className="backView_heading">Send Medical Claims to Super Med PPO</Box>
                                        <Box className="backView_tags">
                                            <Box className="backView_tags_heading_text">Player ID:</Box>
                                            <Box className="backView_tags_value_text">354</Box>
                                        </Box>
                                    </Box>
                                    <Divider style={{ margin: "30px 0", backgroundColor: "rgba(0, 0, 0, 0.2)" }} />
                                    <Box className="backView_lower_detail">
                                        <Box style={{ marginBottom: "20px" }} className="backView_heading">
                                            Send all Dental and Vision Claims to :
                                        </Box>
                                        <Box className="backView_lower_detail_with_clientName_and_tags">
                                            <Box className="backView_clientDetails">
                                                <Box className="idCard_clientName">Builder Ai Client</Box>
                                                <Box className="idCard_clientDetail">1531 SW 46 ST Miami FL 3319</Box>
                                            </Box>
                                            <Box className="backView_lower_tags">
                                                <Box className="backView_tags_value_text">Phone: 9833575343</Box>
                                                <Box className="backView_tags_value_text">Fax: 9923</Box>
                                            </Box>
                                        </Box>
                                    </Box>

                                </Box>
                            </Grid>
                            <Divider style={{ margin: "90px 0 30px", backgroundColor: "rgba(0, 0, 0, 0.2)" }} />
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                <Box className="idCard_printId_btn">
                                    <Button className="printId_btn">Print ID</Button>
                                </Box>
                            </Grid>

                            <Dialog
                                className="edit-member-details-dialog member-idCard-dialog"
                                maxWidth="md"
                                fullWidth
                                open={this.state.openRequestIdCardModal}
                                scroll="body"
                            >
                                <div className="edit-member-dialog-wrapper">
                                    <div className="edit-member-flex-wrapper">
                                        <div className="member-dialog-title">Request ID Card</div>
                                        <div>
                                            <IconButton size="small" className="member_idCard_dialog_closeBtn" onClick={() => this.setState({ openRequestIdCardModal: false })}>
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <Divider style={{ margin: "20px 0" }} />
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <RadioGroup className="idCard_radio_group text-input" name="idCard_request_address" value={this.state.idCard_request_address} onChange={this.handleFormInputsOnChange}>
                                                <FormControlLabel value="physical" control={<Radio color="primary" />} label="Send to physical address" />
                                                <FormControlLabel value="mailing" control={<Radio color="primary" />} label="Send to mailing address" />
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <div className="text-input-label">
                                                Address Line 1
                                            </div>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                type="text"
                                                disabled
                                                className="text-input"
                                                value={this.state.memberFullInfo.memberInfo_physicalAddressLine1}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <div className="text-input-label">
                                                Address Line 2
                                            </div>
                                            <TextField
                                                fullWidth
                                                disabled
                                                variant="outlined"
                                                type="text"
                                                className="text-input"
                                                value={this.state.memberFullInfo.memberInfo_physicalAddressLine2}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="text-input-label">
                                                City/Town
                                            </div>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                disabled
                                                type="text"
                                                className="text-input"
                                                value={this.state.memberFullInfo.memberInfo_physicalCity}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="text-input-label">
                                                State/County
                                            </div>
                                            <Select
                                                fullWidth
                                                className={this.handleFormFieldClassName("member_physicalState")}
                                                disabled
                                                value={this.state.memberFullInfo.memberInfo_physicalState}
                                            >
                                                <MenuItem className="member_form_list" value="">Select State</MenuItem>
                                                {configJSON.statesList.map((state: string, indx: number) => {
                                                    return (
                                                        <MenuItem className="member_form_list" key={indx} value={state}>{state}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="text-input-label">
                                                Zipcode/Postal Code
                                            </div>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                disabled
                                                type="text"
                                                className="text-input"
                                                value={this.state.memberFullInfo.memberInfo_physicalZipcode}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="text-input-label">
                                                Country
                                            </div>
                                            <Select
                                                fullWidth
                                                disabled
                                                className={this.handleFormFieldClassName("member_physicalCountry")}
                                                value={this.state.memberFullInfo.memberInfo_physicalCountry}
                                            >
                                                <MenuItem className="member_form_list" value="">Select Country</MenuItem>
                                                {configJSON.countriesList.map((country: string, indx: number) => {
                                                    return (
                                                        <MenuItem className="member_form_list" key={indx} value={country}>{country}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <Divider style={{ margin: "30px 0" }} />
                                    <div className="member-idCard-flex-wrapper">
                                        <IconButton
                                            className="member-idCard-dialog-cancel-btn"
                                            onClick={() => this.setState({ openRequestIdCardModal: false })}
                                        >
                                            Cancel
                                        </IconButton>
                                        <Button
                                            variant="contained"
                                            className="member-idCard-dialog-send-btn"
                                            onClick={this.handleSendRequestIdCard}
                                        >
                                            Send
                                        </Button>
                                    </div>
                                </div>
                            </Dialog>
                        </Grid>
                    </Box>
                </Box>
            </Box >
        );
    }

}