import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { TabIconList } from "../../../components/src/CustomIconTabPanel.web";
import { Column } from "../../../components/src/CustomTable.web";
import GenericButton from "../../../components/src/GenericButton.web";

export const configJSON = require("./config");
export const images = require("./assets");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  searchValue: string;
  openFilter: boolean;
  tabIconList: TabIconList[];
  tabIndex: string;
  rowData: RowData[];
  isOpenClaimsDialog: boolean;
  isClaimsDataAdded: boolean;
  selectedClaimType: string;
  selectedClaimTypeError: string;
  selectedClaimFile: File | string;
  selectedClaimFileName: string;
  selectedClaimFileNameError: string;
  isUploadClaimDataSaved: boolean;
  isOpenCloseWarningDialog: boolean;
  isSubmitOnce: boolean;
}

interface SS {
  id: any;
}

interface RowData {
  id: string;
  claimNo: string;
  claimType: string;
  serviceDate: string;
  status: string;
  patientName: string;
  provider: string;
  billedAmt: string;
  allowedAmt: string;
  paidPlan: string;
  coPay: string;
  memResp: string;
  innerRowData: InnerRowData[];
}

interface InnerRowData {
  deductible: string;
  coIns: string;
  empRep: string;
  paidDate: string;
  submitBy: string;
  paidTo: string;
  check: string;
  eob: string;
  preCert: string;
}

const claimList: RowData[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
  (item) => ({
    id: `${item}`,
    claimNo: `${item}2379029`,
    claimType: "-",
    serviceDate: `${item} jan 1999`,
    status: "-",
    patientName: `${item}Vikas Member`,
    provider: `${item}Albert`,
    billedAmt: `$${item}2000`,
    allowedAmt: `$${item}1500`,
    paidPlan: `$${item}1500`,
    coPay: "-",
    memResp: "-",
    innerRowData: [1, 2, 3, 4].map((inner) => ({
      deductible: "-",
      coIns: "-",
      empRep: "-",
      paidDate: `${inner} jan 1999`,
      submitBy: `${inner}ACLAD`,
      paidTo: `${inner}Johney`,
      check: "View",
      eob: "View",
      preCert: "View",
    })),
  })
);

export default class MyClaimsController extends BlockComponent<Props, S, SS> {
  defaultValue = {
    selectedClaimType: "",
    selectedClaimFile: "",
    selectedClaimFileName: "",
  };

  defaultError = {
    selectedClaimTypeError: "",
    selectedClaimFileNameError: "",
  };

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      ...this.defaultValue,
      ...this.defaultError,
      searchValue: "",
      openFilter: false,
      tabIconList: [
        {
          key: configJSON.medical,
          icon: images.medical,
          selectedIcon: images.medicalSelected,
        },
        {
          key: configJSON.dental,
          icon: images.dental,
          selectedIcon: images.dentalSelected,
        },
        {
          key: configJSON.vision,
          icon: images.vision,
          selectedIcon: images.visionSelected,
        },
        {
          key: configJSON.rx,
          icon: images.rx,
          selectedIcon: images.rxSelected,
        },
        {
          key: configJSON.lifeInsurance,
          icon: images.lifeInsurance,
          selectedIcon: images.lifeInsuranceSelected,
        },
        {
          key: configJSON.voluntarySupplements,
          icon: images.voluntarySupplements,
          selectedIcon: images.voluntarySupplementsSelected,
        },
        {
          key: configJSON.retirement,
          icon: images.retirement,
          selectedIcon: images.retirementSelected,
        },
        {
          key: configJSON.healthSavingsAccount,
          icon: images.healthSavingsAccount,
          selectedIcon: images.healthSavingsAccountSelected,
        },
        {
          key: configJSON.healthFSA,
          icon: images.healthFSA,
          selectedIcon: images.healthFSASelected,
        },
        {
          key: configJSON.HRA,
          icon: images.HRA,
          selectedIcon: images.HRASelected,
        },
        {
          key: configJSON.others,
          icon: images.others,
          selectedIcon: images.othersSelected,
        },
      ],
      tabIndex: "All",
      rowData: claimList,
      isOpenClaimsDialog: false,
      isClaimsDataAdded: false,
      isUploadClaimDataSaved: false,
      isOpenCloseWarningDialog: false,
      isSubmitOnce: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  tableColumns: Column[] = [
    { id: "claimNo", label: "Claim No." },
    { id: "claimType", label: "Claim type" },
    { id: "serviceDate", label: "Service Date" },
    { id: "status", label: "Status" },
    { id: "patientName", label: "Patient Name" },
    { id: "provider", label: "Provider" },
    { id: "billedAmt", label: "Billed Amt." },
    { id: "allowedAmt", label: "Allowed Amt." },
    { id: "paidPlan", label: "Paid Plan" },
    { id: "coPay", label: "Co Pay" },
    { id: "memResp", label: "Mem Resp" },
  ];

  tableInnerColumns: Column[] = [
    { id: "deductible", label: "Deductible" },
    { id: "coIns", label: "Co Ins" },
    { id: "empRep", label: "EMP Rep" },
    { id: "paidDate", label: "Paid Date" },
    { id: "submitBy", label: "Submit By" },
    { id: "paidTo", label: "Paid To" },
    {
      id: "check",
      label: "Check",
      headerAlign: "center",
      align: "center",
      format: (row: InnerRowData) => {
        return (
          <GenericButton color="primary" variant="text" className="p-0">
            {row.check}
          </GenericButton>
        );
      },
    },
    {
      id: "eob",
      label: "EOB",
      headerAlign: "center",
      align: "center",
      format: (row: InnerRowData) => {
        return (
          <GenericButton color="primary" variant="text" className="p-0">
            {row.eob}
          </GenericButton>
        );
      },
    },
    {
      id: "preCert",
      label: "Pre Cert",
      headerAlign: "center",
      align: "center",
      format: (row: InnerRowData) => {
        return (
          <GenericButton color="primary" variant="text" className="p-0">
            {row.preCert}
          </GenericButton>
        );
      },
    },
  ];

  onSearchInputChange = (e: React.ChangeEvent<any>) => {
    const searchKey = e.target.value;
    const newDocumentList = claimList.filter((record) => {
      return (
        record.claimNo.toLowerCase().includes(searchKey.toLowerCase()) ||
        record.claimType.toLowerCase().includes(searchKey.toLowerCase()) ||
        record.serviceDate.toLowerCase().includes(searchKey.toLowerCase()) ||
        record.status.toLowerCase().includes(searchKey.toLowerCase()) ||
        record.patientName.toLowerCase().includes(searchKey.toLowerCase()) ||
        record.provider.toLowerCase().includes(searchKey.toLowerCase()) ||
        record.billedAmt.toLowerCase().includes(searchKey.toLowerCase()) ||
        record.allowedAmt.toLowerCase().includes(searchKey.toLowerCase()) ||
        record.paidPlan.toLowerCase().includes(searchKey.toLowerCase()) ||
        record.coPay.toLowerCase().includes(searchKey.toLowerCase()) ||
        record.memResp.toLowerCase().includes(searchKey.toLowerCase())
      );
    });
    this.setState({ searchValue: searchKey, rowData: newDocumentList });
  };

  onTabIndexChange = (tab: string) => {
    this.setState({ tabIndex: tab });
  };

  onOpenClaimsDialog = () => {
    this.setState({
      isOpenClaimsDialog: true,
      isClaimsDataAdded: false,
      isSubmitOnce: false,
    });
  };

  onCloseClaimsDialog = () => {
    if (!this.state.isClaimsDataAdded) {
      this.setState({
        isOpenClaimsDialog: false,
        isClaimsDataAdded: false,
        ...this.defaultValue,
        ...this.defaultError,
      });
    } else {
      this.setState({ isOpenCloseWarningDialog: true });
    }
  };

  handleUploadClaimDone = () => {
    this.setState({
      isUploadClaimDataSaved: false,
      isOpenClaimsDialog: false,
      isClaimsDataAdded: false,
      ...this.defaultValue,
      ...this.defaultError,
    });
  };

  handleCloseDialogClose = () => {
    this.setState({ isOpenCloseWarningDialog: false });
  };

  handleCloseDialogOk = () => {
    this.setState({
      isOpenCloseWarningDialog: false,
      isOpenClaimsDialog: false,
      isClaimsDataAdded: false,
      ...this.defaultValue,
      ...this.defaultError,
    });
  };

  handleChangeClaimType = (e: React.ChangeEvent<any>) => {
    this.setState(
      {
        selectedClaimType: e.target.value,
        isClaimsDataAdded: true,
      },
      () => this.validationCheck(this.state.isSubmitOnce)
    );
  };

  handleChangeClaimFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      this.setState(
        {
          selectedClaimFile: e.target.files[0],
          selectedClaimFileName: e.target.files[0].name,
          isClaimsDataAdded: true,
        },
        () => this.validationCheck(this.state.isSubmitOnce)
      );
    }
  };

  validationCheck = (isSubmit: boolean) => {
    if (!isSubmit) return true;
    this.setState({
      ...this.defaultError,
    });
    let validate = true;

    if (!this.state.selectedClaimType) {
      this.setState({
        selectedClaimTypeError: configJSON.claimTypeRequired,
      });
      validate = false;
    }
    if (!this.state.selectedClaimFileName) {
      this.setState({
        selectedClaimFileNameError: configJSON.fileRequired,
      });
      validate = false;
    }
    return validate;
  };

  onSaveClaimsData = () => {
    this.setState({ isSubmitOnce: true });
    if (this.validationCheck(true)) {
      this.setState({
        isUploadClaimDataSaved: true,
      });
    }
  };
}
