import React, { Component } from "react";
import { FormControlLabel, Checkbox } from "@material-ui/core";

import "./GenericCheckBoxField.web.css";

interface Props {
  checked: boolean;
  onChange: (event: React.ChangeEvent<any>, checked: boolean) => void;
  label?: string;
  disabled?: boolean;
}

interface S { }

export default class GenericCheckBoxField extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="generic-check-box-field">
        <FormControlLabel
          control={<Checkbox color="primary" />}
          checked={this.props.checked}
          onChange={this.props.onChange}
          label={this.props.label}
          disabled={this.props.disabled}
          data-test-id="check-box"
        />
      </div>
    );
  }
}
