import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import "./GenericSearch.web.css";

interface Props {
  searchIcon: string;
  placeholder: string;
  value: string;
  onChange: (e: any) => void;
}

interface S {}

export default class GenericSearch extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Grid container alignItems="flex-end" className="generic-search-web">
        <Grid item xs className="generic-search-item">
          <FormControl variant="outlined">
            <OutlinedInput
              id="outlined-adornment-search"
              type="text"
              placeholder={this.props.placeholder}
              title={this.props.placeholder}
              value={this.props.value}
              onChange={(e) => e.target.value !== " " && this.props.onChange(e)}
              startAdornment={
                <img
                  src={this.props.searchIcon}
                  alt="Search Icon"
                  className="search-icon"
                />
              }
              autoComplete="off"
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}
