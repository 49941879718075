import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import { Checkbox, Divider, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography, makeStyles } from '@material-ui/core';
import * as yup from 'yup';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const Images = require("../../../../blocks/landingpage/src/assets")
export const configJSON = require("../../../src/CountryList");

export const CheckIcon = ({ ...other }) => {
    return <img src={Images.checkboxIcon} alt="images" width="18px" />
}

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        height: 50,
        background: "#F4F6FB",
        borderRadius: 8
    },
    inputlabel: {
        fontFamily: "Montserrat",
        letterSpacing: 0,
        fontSize: 16,
        fontWeight: 400,
        marginBottom: theme.spacing(1),
        color: "#757A8C"
    },
    dateGrid: {
        display: "flex",
        alignItems: "end"
    },
    title: {
        fontFamily: "Poppins",
        letterSpacing: 0,
        fontSize: 22,
        fontWeight: 500,
        color: "rgba(60, 69, 87, 0.40)"
    },
    checkboxlabel: {
        fontFamily: "Montserrat",
        letterSpacing: 0,
        fontSize: 18,
        fontWeight: 400,
        color: "rgba(60, 69, 87, 0.40)",
        marginLeft: theme.spacing(1)
    }
}));

const StepTwo = (props: any) => {
    const { setValues, values } = props
    const classes = useStyles()
    const [statesList, setstatesList] = useState([])
    const [statesListTwo, setstatesListTwo] = useState([])
    const handleSelectPhysicalCountryOnChange = (e: any) => {
        setValues({
            ...values,
            country: e.target.value,
        });
        setstatesList(
            configJSON.countries.find(
                (ctr: { country: string; states: string[] }) =>
                    ctr.country === e.target.value
            ).states
        )
    };

    const handleSelectPhysicalCountryOnChangeTwo = (e: any) => {
        setValues({
            ...values,
            mail_country: e.target.value,
        });
        setstatesListTwo(
            configJSON.countries.find(
                (ctr: { country: string; states: string[] }) =>
                    ctr.country === "United States"
            ).states
        )
    };

    useEffect(()=>{
        setstatesList(
            configJSON.countries.find(
                (ctr: { country: string; states: string[] }) =>
                    ctr.country === "United States"
            ).states
        )
    },[])


    const onCheckedValue = (event: any, key: string) => {
        setValues(values.mailcheck ? {
            ...values,
            [event.target.name]: event.target.value,
            [key]: event.target.value
        } : {
            ...values,
            [event.target.name]: event.target.value
        })

    }
    return (
        <>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Typography className={classes.title}>Physical Address</Typography>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel className={classes.inputlabel}>Address Line 1</InputLabel>
                    <Field name="address_line_1">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <>
                                <TextField
                                    {...field}
                                    fullWidth
                                    variant='outlined'
                                    error={meta.touched && meta.error}
                                    helperText={meta.touched && meta.error}
                                    InputProps={{
                                        className: classes.inputStyle
                                    }}
                                    onChange={(event) => onCheckedValue(event, "mail_address_line_1")}
                                />
                            </>
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} >
                    <InputLabel className={classes.inputlabel}>Address Line 2</InputLabel>
                    <Field name="address_line_2">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <>
                                <TextField
                                    {...field}
                                    fullWidth
                                    variant='outlined'
                                    error={meta.touched && meta.error}
                                    helperText={meta.touched && meta.error}
                                    InputProps={{
                                        className: classes.inputStyle
                                    }}
                                    onChange={(event) => onCheckedValue(event, "mail_address_line_2")}
                                />
                            </>
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel className={classes.inputlabel}>City/Town</InputLabel>
                    <Field name="city_town">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <TextField
                                {...field}
                                fullWidth
                                variant='outlined'
                                error={meta.touched && meta.error}
                                helperText={meta.touched && meta.error}
                                InputProps={{
                                    className: classes.inputStyle
                                }}
                                onChange={(event) => onCheckedValue(event,"mail_city_town")}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel className={classes.inputlabel}>State / County</InputLabel>
                    <Field name="state_county">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <>
                                <Select
                                    {...field}
                                    fullWidth
                                    variant='outlined'
                                    error={meta.touched && meta.error}
                                    className={classes.inputStyle}
                                    IconComponent={ExpandMoreIcon}
                                    onChange={(event) => onCheckedValue(event,"mail_state_county")}
                                >
                                    <MenuItem value="">Select State</MenuItem>
                                    {statesList?.map((state: string, indx: number) => {
                                        return (
                                            <MenuItem key={indx} value={state}>{state}</MenuItem>
                                        )
                                    })}
                                </Select>
                                {meta.touched && meta.error && (<FormHelperText style={{ color: "#FF0000" }}>{meta.touched && meta.error}</FormHelperText>)}
                            </>
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel className={classes.inputlabel}>Zip code</InputLabel>
                    <Field name="zipcode">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <TextField
                                {...field}
                                fullWidth
                                variant='outlined'
                                error={meta.touched && meta.error}
                                helperText={meta.touched && meta.error}
                                InputProps={{
                                    className: classes.inputStyle
                                }}
                                onChange={(event) => onCheckedValue(event, "mail_zipcode")}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel className={classes.inputlabel}>Country</InputLabel>
                    <Field name="country">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <>
                                <Select
                                    {...field}
                                    fullWidth
                                    variant='outlined'
                                    error={meta.touched && meta.error}
                                    className={classes.inputStyle}
                                    IconComponent={ExpandMoreIcon}
                                    onChange={(e) => handleSelectPhysicalCountryOnChange(e)}
                                >
                                    <MenuItem value="">Select Country</MenuItem>
                                    {configJSON.countries.map((country: { country: string, states: string[] }, indx: number) => {
                                        return (
                                            <MenuItem key={indx} value={country.country}>{country.country}</MenuItem>
                                        )
                                    })}
                                </Select>
                                {meta.touched && meta.error && (<FormHelperText style={{ color: "#FF0000" }}>{meta.touched && meta.error}</FormHelperText>)}
                            </>
                        )}
                    </Field>
                </Grid>
                <Grid container spacing={1} item xs={12}>
                    <Grid item xs={12} md={6}>
                        <Typography className={classes.title}>Mailing Address</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field name="mailcheck">
                            {({
                                field
                            }: any) => (
                                <FormControlLabel
                                    {...field}
                                    control={
                                        <Checkbox
                                            {...field}
                                            color='primary'
                                            icon={<CheckIcon />}
                                            onChange={(event: any) => {
                                                setValues(event.target.checked ? {
                                                    ...values,
                                                    mailcheck: event.target.checked,
                                                    mail_address_line_1: values.address_line_1,
                                                    mail_address_line_2: values.address_line_2,
                                                    mail_city_town: values.city_town,
                                                    mail_state_county: values.state_county,
                                                    mail_zipcode: values.zipcode,
                                                    mail_country: values.country
                                                } : {
                                                    ...values,
                                                    mailcheck: event.target.checked,
                                                    mail_address_line_1: "",
                                                    mail_address_line_2: "",
                                                    mail_city_town: "",
                                                    mail_state_county: "",
                                                    mail_zipcode: "",
                                                    mail_country: ""
                                                });
                                                setstatesListTwo(
                                                    event.target.checked ? configJSON.countries.find(
                                                        (ctr: { country: string; states: string[] }) =>
                                                            ctr.country === values.country
                                                    ).states : []
                                                )
                                            }}
                                            name="mailcheck"
                                        />
                                    }

                                    label={
                                        <Typography className={classes.checkboxlabel}>
                                            Mailing address same as physical address
                                        </Typography>
                                    }
                                />
                            )}
                        </Field>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    <InputLabel className={classes.inputlabel}>Address Line 1</InputLabel>
                    <Field name="mail_address_line_1">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <TextField
                                {...field}
                                fullWidth
                                variant='outlined'
                                error={meta.touched && meta.error}
                                helperText={meta.touched && meta.error}
                                InputProps={{
                                    className: classes.inputStyle,
                                    readOnly: values.mailcheck ? true :false, 
                                }}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} md={12}>
                    <InputLabel className={classes.inputlabel}>Address Line 2</InputLabel>
                    <Field name="mail_address_line_2">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <TextField
                                {...field}
                                fullWidth
                                variant='outlined'
                                error={meta.touched && meta.error}
                                helperText={meta.touched && meta.error}
                                InputProps={{
                                    className: classes.inputStyle,
                                    readOnly: values.mailcheck ? true :false, 
                                }}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel className={classes.inputlabel}>City / Town</InputLabel>
                    <Field name="mail_city_town">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <TextField
                                {...field}
                                fullWidth
                                variant='outlined'
                                error={meta.touched && meta.error}
                                helperText={meta.touched && meta.error}
                                InputProps={{
                                    className: classes.inputStyle,
                                    readOnly: values.mailcheck ? true :false, 
                                }}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel className={classes.inputlabel}>State / County</InputLabel>
                    <Field name="mail_state_county">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <>
                                <Select
                                    {...field}
                                    fullWidth
                                    variant='outlined'
                                    error={meta.touched && meta.error}
                                    IconComponent={ExpandMoreIcon}
                                    disabled={values.mailcheck}
                                    className={classes.inputStyle}
                                >
                                    <MenuItem value="">Select State</MenuItem>
                                    {statesListTwo?.map((state: string, indx: number) => {
                                        return (
                                            <MenuItem key={indx} value={state}>{state}</MenuItem>
                                        )
                                    })}
                                </Select>
                                {meta.touched && meta.error && (<FormHelperText style={{ color: "#FF0000" }}>{meta.touched && meta.error}</FormHelperText>)}
                            </>
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel className={classes.inputlabel}>Zip code</InputLabel>
                    <Field name="mail_zipcode">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <TextField
                                {...field}
                                fullWidth
                                variant='outlined'
                                error={meta.touched && meta.error}
                                helperText={meta.touched && meta.error}
                                InputProps={{
                                    className: classes.inputStyle,
                                    readOnly: values.mailcheck ? true :false,
                                }}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel className={classes.inputlabel}>Country</InputLabel>
                    <Field name="mail_country">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <>
                                <Select
                                    {...field}
                                    fullWidth
                                    variant='outlined'
                                    error={meta.touched && meta.error}
                                    IconComponent={ExpandMoreIcon}
                                    disabled={values.mailcheck}
                                    className={classes.inputStyle}
                                    onChange={(e) => handleSelectPhysicalCountryOnChangeTwo(e)}
                                >
                                    <MenuItem value="">Select Country</MenuItem>
                                    {configJSON.countries.map((country: { country: string, states: string[] }, indx: number) => {
                                        return (
                                            <MenuItem key={indx} value={country.country}>{country.country}</MenuItem>
                                        )
                                    })}
                                </Select>
                                {meta.touched && meta.error && (<FormHelperText style={{ color: "#FF0000" }}>{meta.touched && meta.error}</FormHelperText>)}
                            </>
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel className={classes.inputlabel}>Contact 1</InputLabel>
                    <Field name="contact_1">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <TextField
                                {...field}
                                fullWidth
                                variant='outlined'
                                error={meta.touched && meta.error}
                                helperText={meta.touched && meta.error}
                                InputProps={{
                                    className: classes.inputStyle
                                }}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel className={classes.inputlabel}>Contact 2</InputLabel>
                    <Field name="contact_2">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <TextField
                                {...field}
                                fullWidth
                                variant='outlined'
                                error={meta.touched && meta.error}
                                helperText={meta.touched && meta.error}
                                InputProps={{
                                    className: classes.inputStyle
                                }}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel className={classes.inputlabel}>Email 1</InputLabel>
                    <Field name="email_1">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <TextField
                                {...field}
                                fullWidth
                                variant='outlined'
                                error={meta.touched && meta.error}
                                helperText={meta.touched && meta.error}
                                InputProps={{
                                    className: classes.inputStyle
                                }}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel className={classes.inputlabel}>Email 2</InputLabel>
                    <Field name="email_2">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <TextField
                                {...field}
                                fullWidth
                                variant='outlined'
                                error={meta.touched && meta.error}
                                helperText={meta.touched && meta.error}
                                InputProps={{
                                    className: classes.inputStyle
                                }}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel className={classes.inputlabel}> Phone Number 1</InputLabel>
                    <Field name="phone_number_1">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <TextField
                                {...field}
                                fullWidth
                                variant='outlined'
                                error={meta.touched && meta.error}
                                helperText={meta.touched && meta.error}
                                InputProps={{
                                    className: classes.inputStyle
                                }}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel className={classes.inputlabel}> Phone Number 2</InputLabel>
                    <Field name="phone_number_2">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <TextField
                                {...field}
                                fullWidth
                                variant='outlined'
                                error={meta.touched && meta.error}
                                helperText={meta.touched && meta.error}
                                InputProps={{
                                    className: classes.inputStyle
                                }}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel className={classes.inputlabel}> Fax 1</InputLabel>
                    <Field name="fax_1">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <TextField
                                {...field}
                                fullWidth
                                variant='outlined'
                                error={meta.touched && meta.error}
                                helperText={meta.touched && meta.error}
                                InputProps={{
                                    className: classes.inputStyle
                                }}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel className={classes.inputlabel}> Fax 2</InputLabel>
                    <Field name="fax_2">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <TextField
                                {...field}
                                fullWidth
                                variant='outlined'
                                error={meta.touched && meta.error}
                                helperText={meta.touched && meta.error}
                                InputProps={{
                                    className: classes.inputStyle
                                }}
                            />
                        )}
                    </Field>
                </Grid>
            </Grid>
        </>
    );
};

StepTwo.label = 'step 2';
StepTwo.initialValues = {
    address_line_1: '',
    address_line_2: '',
    city_town: '',
    state_county: '',
    zipcode: '',
    country: 'United States',
    mailcheck: false,
    mail_address_line_1: '',
    mail_address_line_2: '',
    mail_city_town: '',
    mail_state_county: '',
    mail_zipcode: '',
    mail_country: '',
    contact_1: '',
    contact_2: '',
    email_1: '',
    email_2: '',
    phone_number_1: '',
    phone_number_2: '',
    fax_1: '',
    fax_2: '',
};
StepTwo.validationSchema = yup.object().shape({
    address_line_1: yup.string().max(30, 'Must be 30 characters or less').required('Please enter your Address 1'),
    address_line_2: yup.string().max(30, 'Must be 30 characters or less').required('Please enter your Address 2'),
    city_town: yup.string().max(30, 'Must be 30 characters or less').required('Please enter your City/Town'),
    state_county: yup.string().required('Please enter your State/County'),
    zipcode: yup.string().matches(/^\d{5}(-\d{4})?$/ || /^[A-Z]{1,2}\d{1,2}[A-Z]?\s\d[A-Z]{2}$/, 'Invalid zip code').required('Please enter your Zip code'),
    country: yup.string().required('Please enter your Country'),
    mail_address_line_1: yup.string().max(30, 'Must be 30 characters or less').required('Please enter your Address'),
    mail_address_line_2: yup.string().max(30, 'Must be 30 characters or less').required('Please enter your Address'),
    mail_city_town: yup.string().max(30, 'Must be 30 characters or less').required('Please enter your City/Town'),
    mail_state_county: yup.string().required('Please enter your State/County'),
    mail_zipcode: yup.string().matches(/^\d{5}(-\d{4})?$/ || /^[A-Z]{1,2}\d{1,2}[A-Z]?\s\d[A-Z]{2}$/, 'Invalid zip code').required('Please enter your Zip code'),
    mail_country: yup.string().required('Please enter your Country'),
    contact_1: yup.string().max(30, 'Must be 30 characters or less').required('Please enter your Contact 1'),
    contact_2: yup.string().max(30, 'Must be 30 characters or less').required('Please enter your Contact 2'),
    email_1: yup.string().email('Invalid email address').required('Please enter your Email 1'),
    email_2: yup.string().email('Invalid email address').required('Please enter your Email 2'),
    phone_number_1: yup.string().matches(/^\d{10}$/, 'Invalid phone number').required('Please enter your Phone Number 1'),
    phone_number_2: yup.string().matches(/^\d{10}$/, 'Invalid phone number').required('Please enter your Phone Number 2'),
    fax_1: yup.string().matches(/^\d{10}$/, 'Fax number must be exactly 10 digits').required('Please enter your Fax 1'),
    fax_2: yup.string().matches(/^\d{10}$/, 'Fax number must be exactly 10 digits').required('Please enter your Fax 2'),
});

export default StepTwo;