import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { SelectedEmployerInterface } from "../AllRoleLandingPageController.web";

export const configJSON = require("./../config");
export const images = require("./../assets");

export interface Props {
  navigation: any;
  id: string;
  selectedEmployer: SelectedEmployerInterface[]
}

interface S {}

interface SS {
  id: any;
}

export default class ClientEmployerDivisionController extends BlockComponent<
  Props,
  S,
  SS
> {
  tabMenuList = [
    "Plan",
    "Enrollment",
    "Enroll Config",
    "Medical Review",
    "Pay Claims",
    "Recon",
    "Invoice",
    "Payroll Invoice",
    "Case Billing",
    "Documents",
  ];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {};
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
}
