Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";
exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.sendOtpUrl = "account_block/accounts/send_otps";
exports.verifyOtpUrl = "account_block/accounts/sms_confirmations"
exports.validationApiMethodType = "GET";


exports.errorEmailMessage = "Please enter Email ID";
exports.errorPasswordMessage = "Please enter Password";
exports.errorConfirmPassword = "Please Retype Password";
exports.errorEnterConfirmPassword = "Please enter Confirm Password";
exports.errorConfirmPasswordMismatch = "Password mismatch. Please try again";
exports.emailRegex = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$";
exports.errorEmailNotValid = "Email not valid";
exports.errorPasswordNotValid = "Password not valid";
exports.errorFirstName = "Please enter First Name";
exports.errorLastName = "Please enter Last Name";
exports.errorAddress = "Please enter Address";
exports.errorPhoneNumber = "Please enter Phone Number";
exports.errorCompanyName = "Please enter your Company Name";
exports.errorTaxId = "Please enter your TAX ID";
exports.errorSsn = "Please enter your SSN No";
exports.errorSsntax = "*We cannot find your SSN in our database, please enter the Tax ID for your employer."
exports.privacyPolicy = "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker";
exports.termConditionsText = "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker";
exports.waitingLabelLine = "Registration Pending"
exports.waitingSubLabelLine = "Your registration is waiting to be approved by the administrator. please log back in when you receive a notification that your registration is approved.";
exports.registerDetailsBefore = "Please select your role and complete the sections to create your account.";
exports.registerDetailsAfter = "Please enter your basic details to create your account.";
exports.headerTitleOtp = "OTP Verification";
exports.subHeaderTitleOtp = "Check your email. We have sent you the 4 digit code at ";
exports.resendOtp = "Resend Code";
exports.termsAndConditions = "Terms & Conditions"
exports.privacyPolicy = "Privacy Policy"
// Customizable Area End
