import React from "react";
import { Grid, Button } from "@material-ui/core";

import ClientEmployerDivisionTabController, {
  Props,
  images,
  configJSON,
} from "./ClientEmployerDivisionTabController.web";
import "./ClientEmployerDivisionTab.web.css";
import GenericSearch from "../../../../../components/src/GenericSearch.web";
import CustomerTable from "../../../../../components/src/CustomTable.web";
import CustomFilterPopup from "../../../../../components/src/CustomFilterPopup.web";
import AddEditClientEmployerDivisionDialog from "./AddEditClientEmployerDivisionDialog.web";
import CustomTableMenu from "../../../../../components/src/CustomTableMenu.web";
import GenericSuccessDialog from "../../../../../components/src/GenericSuccessDialog.web";

export default class ClientEmployerDivisionTab extends ClientEmployerDivisionTabController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <Grid container className="all-division-tab-container">
          <Grid item xs={12} className="division-search-btn">
            <div className="search-bar-and-add-btn p-relative">
              <GenericSearch
                data-test-id="division-search"
                searchIcon={images.searchIcon}
                placeholder={configJSON.searchForDivision}
                value={this.state.searchValue}
                onChange={this.onSearchInputChange}
              />
              <div className="search-filter-btn">
                <Button
                  data-test-id="filter-btn"
                  onClick={this.handleFilterClick}
                  className="filter-icon"
                >
                  <img
                    src={
                      this.state.openFilter
                        ? images.filterIconSelected
                        : images.filterIcon
                    }
                    alt="Filter Icon"
                  />
                </Button>
                {this.state.openFilter && (
                  <CustomFilterPopup
                    searchIcon={images.searchIcon}
                    filterChildList={this.state.filterChildList}
                    onChange={this.handleFilterOrSearchOnChange}
                  />
                )}
                <Button
                  data-test-id="add-division-btn"
                  className="search-btn"
                  onClick={() => this.toggleAddEditDivisionDialog(true, false)}
                >
                  {configJSON.addDivision}
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className="hr mt-30" />
          <Grid item xs={12}>
            <CustomerTable
              columns={this.tableColumns}
              rowData={this.state.rowData}
              isColumnSelectable
              onActionClick={this.handleActionClick}
            />
            <CustomTableMenu
              selectedMenuAnchorEl={this.state.divisionTableMenuAnchorEl}
              menuItemList={[
                { id: "1", label: "Edit", icon: images.blackEditIcon },
                { id: "2", label: "Delete", icon: images.deleteIcon },
              ]}
              closeTableMenu={this.handleDivisionCloseTableMenu}
              menuItemOnClick={this.handleDivisionTableMenuClick}
            />
          </Grid>
        </Grid>
        {this.state.isOpenAddEditDivisionDialog && (
          <AddEditClientEmployerDivisionDialog
            isOpen={this.state.isOpenAddEditDivisionDialog}
            isEdit={this.state.isEditDivision}
            handleClose={this.handleDivsionDialogClose}
            divisionDetails={this.state.divisionDetails}
            data-test-id="edit-group-dialog"
            selectedEmployer={this.props.selectedEmployer}
          />
        )}
        <GenericSuccessDialog
          data-test-id="del-success-dialog"
          open={this.state.showDialogSuccess}
          handleDone={this.handleSuccessDialogClose}
          contains={"Delete Division Successfully"}
        />
      </>
    );
  }
}
