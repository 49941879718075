import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Divider,
  Dialog,
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  Input,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowLeftRoundedIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import { ThemeProvider } from "@material-ui/styles";

import "./EmployerDetails.web.css";
import EditEmployerDivisionDialogController, {
  Props, getStepLabelClassName,
} from "./EditEmployerDivisionDialogController";
import GenericCloseDialog from "../../../components/src/GenericCloseDialog.web";
import {
  defaultMaterialTheme,
  formValidationMsg,
} from "./EditEmployerDetailsDialog.web";
import GenericDropdownSearch from "../../../components/src/GenericDropdownSearch.web";

export default class EditEmployerDivisionDialog extends EditEmployerDivisionDialogController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      divisionActiveStep,
      divisionData,
      supportingDocName,
      logoUrl,
      divisionBasicDetailsValidation,
      divisionAddressDetailsValidation,
      logoErrorMessage,
      supportingDocErrorMessage,
      isOpenGroupSearch,
      groupSearchInput
    } = this.state;
    const {
      physicalAddress,
      mailingAddress,
      divisionCheckAddress,
    } = divisionData;

    return (
      <ThemeProvider theme={defaultMaterialTheme}>
        <Dialog
          className="edit-emp-details-dialog edit-division-dialog"
          maxWidth="lg"
          fullWidth
          open={this.props.isOpen}
          scroll="body"
        >
          <div className="edit-emp-dialog-wrapper edit-division-wrapper">
            <div className="edit-emp-flex-wrapper edit-division-flex-wrapper">
              <div className="emp-dialog-title edit-division-title">
                {this.props.isEdit ? "Edit" : "Add New"} Division
              </div>
              <div>
                <IconButton
                  data-test-id="close-edit-division-btn"
                  size="small"
                  onClick={this.handleDivisionDialogClose}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <Divider className="emp-dialog-flex-divider edit-division-flex-divider" />
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={4}>
                <Stepper activeStep={divisionActiveStep} orientation="vertical">
                  {this.editDivisionStepList.map((divisionStep, index) => {
                    const alternateLabel = (
                      <div className="emp-dialog-stepper-description edit-division-dialog-stepper">
                        {divisionStep.description}
                      </div>
                    );
                    return (
                      <Step key={divisionStep.title}>
                        <StepLabel className={getStepLabelClassName(index, divisionActiveStep)} optional={alternateLabel} StepIconProps={{ icon: index + 1, completed: false}}>
                          {divisionStep.title}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={8}
                className="emp-edit-form-wrapper edit-division-form-wrapper"
              >
                {divisionActiveStep === 0 && (
                  <Grid container spacing={3} data-test-id="step-1-wrapper">
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Employer ID<span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Employer ID"
                        type="text"
                        className="text-input"
                        data-test-id="employerID"
                        value={divisionData.employerID}
                        onChange={(e) =>
                          this.handleDivisionInputChange(e, "employerID")
                        }
                        name="employerID"
                      />
                      {formValidationMsg(
                        divisionBasicDetailsValidation.employerID
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Employer Name<span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Employer Name"
                        type="text"
                        className="text-input"
                        data-test-id="employerName"
                        value={divisionData.employerName}
                        onChange={(e) =>
                          this.handleDivisionInputChange(e, "employerName")
                        }
                        name="employerName"
                      />
                      {formValidationMsg(
                        divisionBasicDetailsValidation.employerName
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Client Name<span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Client Name"
                        type="text"
                        className="text-input"
                        data-test-id="clientName"
                        value={divisionData.clientName}
                        onChange={(e) =>
                          this.handleDivisionInputChange(e, "clientName")
                        }
                        name="clientName"
                        disabled
                      />
                      {formValidationMsg(
                        divisionBasicDetailsValidation.clientName
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Division ID<span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Division ID"
                        type="text"
                        className="text-input"
                        data-test-id="divisionID"
                        value={divisionData.divisionID}
                        onChange={(e) =>
                          this.handleDivisionInputChange(e, "divisionID")
                        }
                        name="divisionID"
                      />
                      {formValidationMsg(
                        divisionBasicDetailsValidation.divisionID
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Division Name<span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Division Name"
                        type="text"
                        className="text-input"
                        data-test-id="divisionName"
                        value={divisionData.divisionName}
                        onChange={(e) =>
                          this.handleDivisionInputChange(e, "divisionName")
                        }
                        name="divisionName"
                      />
                      {formValidationMsg(
                        divisionBasicDetailsValidation.divisionName
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Group Name<span className="error-asterisk">*</span>
                      </div>
                      <GenericDropdownSearch
                        selectedItem={divisionData.groupName}
                        openSearchBox={isOpenGroupSearch}
                        searchInputValue={groupSearchInput}
                        searchItemList={this.groupList}
                        itemSelectionClickHandler={
                          this.groupItemSelectionClickHandler
                        }
                        searchInputOnChangeHandler={
                          this.searchInputOnChangeHandler
                        }
                        itemListClickHandler={this.itemListClickHandler}
                        label="Group Name"
                        fieldName="groupName"
                      />
                      {formValidationMsg(
                        divisionBasicDetailsValidation.groupName
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Effective Date<span className="error-asterisk">*</span>
                      </div>
                      <Input
                        fullWidth
                        placeholder="Select Effective Date"
                        type="date"
                        onChange={(date) =>
                          this.handleDateChange(date, "effectiveDate")
                        }
                        className="effective-date-input"
                        name="effectiveDate"
                        value={divisionData.effectiveDate}
                        data-test-id="effectiveDate"
                      />
                      {formValidationMsg(
                        divisionBasicDetailsValidation.effectiveDate
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Termination Date
                        <span className="error-asterisk">*</span>
                      </div>
                      <Input
                        fullWidth
                        placeholder="Select Termination Date"
                        type="date"
                        onChange={(date) =>
                          this.handleDateChange(date, "terminationDate")
                        }
                        className="termination-date-input"
                        name="terminationDate"
                        value={divisionData.terminationDate}
                        data-test-id="terminationDate"
                      />
                      {formValidationMsg(
                        divisionBasicDetailsValidation.terminationDate
                      )}
                    </Grid>
                  </Grid>
                )}
                {divisionActiveStep === 1 && (
                  <Grid container spacing={3} data-test-id="step-2-wrapper">
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className="emp-edit-address-header edit-division-address-header"
                    >
                      Physical Address <span className="error-asterisk">*</span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="text-input-label">
                        Address Line 1<span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Address Line 1"
                        type="text"
                        className="text-input"
                        data-test-id="physical-addressLine1"
                        value={physicalAddress.addressLine1}
                        onChange={(e) =>
                          this.handleAddressItemChange(
                            e,
                            "physical",
                            "addressLine1"
                          )
                        }
                        name="physical-addressLine1"
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.physicalAddressLine1
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="text-input-label">
                        Address Line 2 <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Address Line 2"
                        type="text"
                        className="text-input"
                        data-test-id="physical-addressLine2"
                        value={physicalAddress.addressLine2}
                        onChange={(e) =>
                          this.handleAddressItemChange(
                            e,
                            "physical",
                            "addressLine2"
                          )
                        }
                        name="physical-addressLine2"
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.physicalAddressLine2
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        City / Town <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter City / Town"
                        type="text"
                        className="text-input"
                        data-test-id="physical-city"
                        value={physicalAddress.city}
                        onChange={(e) =>
                          this.handleAddressItemChange(e, "physical", "city")
                        }
                        name="physical-city"
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.physicalCity
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        State / County
                        <span className="error-asterisk">*</span>
                      </div>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          fullWidth
                          IconComponent={(dropdownProps) => (
                            <KeyboardArrowDownRoundedIcon {...dropdownProps} />
                          )}
                          className="edit-emp-dropdown edit-division-dropdown"
                          placeholder="Select State / County"
                          value={physicalAddress.state}
                          data-test-id="physical-state"
                          onChange={(e) =>
                            this.handleAddressItemChange(e, "physical", "state")
                          }
                          name="physical-state"
                        >
                          {this.stateList.map((item) => (
                            <MenuItem
                              value={item.value}
                              key={item.name + " state-county-dropdown"}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formValidationMsg(
                        divisionAddressDetailsValidation.physicalState
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Zipcode / Postal Code
                        <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Zipcode / Postal Code"
                        type="text"
                        className="text-input"
                        data-test-id="physical-zipCode"
                        value={physicalAddress.zipCode}
                        onChange={(e) =>
                          this.handleAddressItemChange(e, "physical", "zipCode")
                        }
                        name="physical-zipCode"
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.physicalZipCode
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Country <span className="error-asterisk">*</span>
                      </div>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          fullWidth
                          IconComponent={(dropdownProps) => (
                            <KeyboardArrowDownRoundedIcon {...dropdownProps} />
                          )}
                          className="edit-emp-dropdown edit-division-dropdown"
                          placeholder="Select Country"
                          value={physicalAddress.country}
                          data-test-id="physical-country"
                          onChange={(e) =>
                            this.handleAddressItemChange(
                              e,
                              "physical",
                              "country"
                            )
                          }
                          name="physical-country"
                        >
                          {this.countryList.map((item) => (
                            <MenuItem
                              value={item.value}
                              key={item.name + " country1-dropdown"}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formValidationMsg(
                        divisionAddressDetailsValidation.physicalCountry
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className="emp-edit-address-header edit-division-address-header"
                    >
                      <div className="emp-mailing-address-flex-wrapper edit-division-mailing-address-flex-wrapper">
                        <div>
                          Mailing Address
                          <span className="error-asterisk">*</span>
                        </div>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={
                                  divisionData.isMailingAddressSameAsPhysicalAddress
                                }
                                onChange={this.toggleMailingAddress}
                              />
                            }
                            label="Mailing address same as physical address"
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="text-input-label">
                        Address Line 1 <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Address Line 1"
                        type="text"
                        className="text-input"
                        data-test-id="mailing-addressLine1"
                        value={mailingAddress.addressLine1}
                        onChange={(event) =>
                          this.handleAddressItemChange(
                            event,
                            "mailing",
                            "addressLine1"
                          )
                        }
                        name="mailing-addressLine1"
                        disabled={divisionData.isMailingAddressSameAsPhysicalAddress}
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.mailingAddressLine1
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="text-input-label">
                        Address Line 2 <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Address Line 2"
                        type="text"
                        className="text-input"
                        data-test-id="mailing-addressLine2"
                        value={mailingAddress.addressLine2}
                        onChange={(event) =>
                          this.handleAddressItemChange(
                            event,
                            "mailing",
                            "addressLine2"
                          )
                        }
                        name="mailing-addressLine2"
                        disabled={divisionData.isMailingAddressSameAsPhysicalAddress}
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.mailingAddressLine2
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        City / Town <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter City / Town"
                        type="text"
                        className="text-input"
                        value={mailingAddress.city}
                        onChange={(event) =>
                          this.handleAddressItemChange(event, "mailing", "city")
                        }
                        name="mailing-city"
                        data-test-id="mailing-city"
                        disabled={divisionData.isMailingAddressSameAsPhysicalAddress}
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.mailingCity
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        State / County
                        <span className="error-asterisk">*</span>
                      </div>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          fullWidth
                          IconComponent={(dropdownProps) => (
                            <KeyboardArrowDownRoundedIcon {...dropdownProps} />
                          )}
                          className="edit-emp-dropdown edit-division-dropdown"
                          placeholder="Select State / County"
                          value={mailingAddress.state}
                          onChange={(e) =>
                            this.handleAddressItemChange(e, "mailing", "state")
                          }
                          name="mailing-state"
                          data-test-id="mailing-state"
                          disabled={divisionData.isMailingAddressSameAsPhysicalAddress}
                        >
                          {this.stateList.map((stateObj) => (
                            <MenuItem
                              value={stateObj.value}
                              key={stateObj.name + " state-country-dropdown1"}
                            >
                              {stateObj.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formValidationMsg(
                        divisionAddressDetailsValidation.mailingState
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Zipcode / Postal Code
                        <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Zipcode / Postal Code"
                        type="text"
                        className="text-input"
                        data-test-id="mailing-zipCode"
                        value={mailingAddress.zipCode}
                        onChange={(event) =>
                          this.handleAddressItemChange(
                            event,
                            "mailing",
                            "zipCode"
                          )
                        }
                        name="mailing-zipCode"
                        disabled={divisionData.isMailingAddressSameAsPhysicalAddress}
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.mailingZipCode
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Country <span className="error-asterisk">*</span>
                      </div>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          fullWidth
                          IconComponent={(dropdownProps) => (
                            <KeyboardArrowDownRoundedIcon {...dropdownProps} />
                          )}
                          className="edit-emp-dropdown edit-division-dropdown"
                          placeholder="Select Country"
                          value={mailingAddress.country}
                          data-test-id="mailing-country"
                          onChange={(e) =>
                            this.handleAddressItemChange(
                              e,
                              "mailing",
                              "country"
                            )
                          }
                          name="mailing-country"
                          disabled={divisionData.isMailingAddressSameAsPhysicalAddress}
                        >
                          {this.countryList.map((item) => (
                            <MenuItem
                              value={item.value}
                              key={item.name + " country-dropdown1"}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formValidationMsg(
                        divisionAddressDetailsValidation.mailingCountry
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className="emp-edit-address-header edit-division-address-header"
                    >
                      <div className="emp-mailing-address-flex-wrapper edit-division-mailing-address-flex-wrapper">
                        <div>
                          Division Check Address
                          <span className="error-asterisk">*</span>
                        </div>
                        <div>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={
                                    divisionData.isDivisionAddressSameAsPhysicalAddress
                                  }
                                  onChange={
                                    this.toggleDivisionCheckAddressAsPrimary
                                  }
                                />
                              }
                              label="Division check address same as physical address"
                            />
                          </div>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={
                                    divisionData.isDivisionAddressSameAsMailingAddress
                                  }
                                  onChange={
                                    this.toggleDivisionCheckAddressAsMailing
                                  }
                                />
                              }
                              label="Division check address same as mailing address"
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="text-input-label">
                        Address Line 1 <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Address Line 1"
                        type="text"
                        className="text-input"
                        data-test-id="divisionCheckAddress-addressLine1"
                        value={divisionCheckAddress.addressLine1}
                        onChange={(event) =>
                          this.handleAddressItemChange(
                            event,
                            "divisionCheckAddress",
                            "addressLine1"
                          )
                        }
                        name="divisionCheckAddress-addressLine1"
                        disabled={divisionData.isDivisionAddressSameAsPhysicalAddress || divisionData.isDivisionAddressSameAsMailingAddress}
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.divisionCheckAddressLine1
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="text-input-label">
                        Address Line 2 <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Address Line 2"
                        type="text"
                        className="text-input"
                        data-test-id="divisionCheckAddress-addressLine2"
                        value={divisionCheckAddress.addressLine2}
                        onChange={(event) =>
                          this.handleAddressItemChange(
                            event,
                            "divisionCheckAddress",
                            "addressLine2"
                          )
                        }
                        name="divisionCheckAddress-addressLine2"
                        disabled={divisionData.isDivisionAddressSameAsPhysicalAddress || divisionData.isDivisionAddressSameAsMailingAddress}
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.divisionCheckAddressLine2
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        City / Town <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter City / Town"
                        type="text"
                        className="text-input"
                        value={divisionCheckAddress.city}
                        onChange={(event) =>
                          this.handleAddressItemChange(
                            event,
                            "divisionCheckAddress",
                            "city"
                          )
                        }
                        name="divisionCheckAddress-city"
                        data-test-id="divisionCheckAddress-city"
                        disabled={divisionData.isDivisionAddressSameAsPhysicalAddress || divisionData.isDivisionAddressSameAsMailingAddress}
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.divisionCheckCity
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        State / County
                        <span className="error-asterisk">*</span>
                      </div>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          fullWidth
                          IconComponent={(dropdownProps) => (
                            <KeyboardArrowDownRoundedIcon {...dropdownProps} />
                          )}
                          className="edit-emp-dropdown edit-division-dropdown"
                          placeholder="Select State / County"
                          value={divisionCheckAddress.state}
                          onChange={(e) =>
                            this.handleAddressItemChange(
                              e,
                              "divisionCheckAddress",
                              "state"
                            )
                          }
                          name="divisionCheckAddress-state"
                          data-test-id="divisionCheckAddress-state"
                          disabled={divisionData.isDivisionAddressSameAsPhysicalAddress || divisionData.isDivisionAddressSameAsMailingAddress}
                        >
                          {this.stateList.map((stateObj) => (
                            <MenuItem
                              value={stateObj.value}
                              key={stateObj.name + " state-country-dropdown1"}
                            >
                              {stateObj.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formValidationMsg(
                        divisionAddressDetailsValidation.divisionCheckState
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Zipcode / Postal Code
                        <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Zipcode / Postal Code"
                        type="text"
                        className="text-input"
                        data-test-id="divisionCheckAddress-zipCode"
                        value={divisionCheckAddress.zipCode}
                        onChange={(event) =>
                          this.handleAddressItemChange(
                            event,
                            "divisionCheckAddress",
                            "zipCode"
                          )
                        }
                        name="divisionCheckAddress-zipCode"
                        disabled={divisionData.isDivisionAddressSameAsPhysicalAddress || divisionData.isDivisionAddressSameAsMailingAddress}
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.divisionCheckZipCode
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Country <span className="error-asterisk">*</span>
                      </div>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          fullWidth
                          IconComponent={(dropdownProps) => (
                            <KeyboardArrowDownRoundedIcon {...dropdownProps} />
                          )}
                          className="edit-emp-dropdown edit-division-dropdown"
                          placeholder="Select Country"
                          value={divisionCheckAddress.country}
                          data-test-id="divisionCheckAddress-country"
                          onChange={(e) =>
                            this.handleAddressItemChange(
                              e,
                              "divisionCheckAddress",
                              "country"
                            )
                          }
                          name="divisionCheckAddress-country"
                          disabled={divisionData.isDivisionAddressSameAsPhysicalAddress || divisionData.isDivisionAddressSameAsMailingAddress}
                        >
                          {this.countryList.map((item) => (
                            <MenuItem
                              value={item.value}
                              key={item.name + " country-dropdown1"}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formValidationMsg(
                        divisionAddressDetailsValidation.divisionCheckCountry
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Group Contact 1<span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Group Contact 1"
                        type="text"
                        className="text-input"
                        data-test-id="groupContact1"
                        value={divisionData.groupContact1}
                        onChange={(event) =>
                          this.handleDivisionInputChange(event, "groupContact1")
                        }
                        name="groupContact1"
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.groupContact1
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Group Contact 2<span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Group Contact 2"
                        type="text"
                        className="text-input"
                        data-test-id="groupContact2"
                        value={divisionData.groupContact2}
                        onChange={(event) =>
                          this.handleDivisionInputChange(event, "groupContact2")
                        }
                        name="groupContact2"
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.groupContact2
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Group Email 1<span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Group Email 1"
                        type="text"
                        className="text-input"
                        data-test-id="groupEmail1"
                        value={divisionData.groupEmail1}
                        onChange={(event) =>
                          this.handleDivisionInputChange(event, "groupEmail1")
                        }
                        name="groupEmail1"
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.groupEmail1
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Group Email 2<span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Group Email 2"
                        type="text"
                        className="text-input"
                        data-test-id="groupEmail2"
                        value={divisionData.groupEmail2}
                        onChange={(event) =>
                          this.handleDivisionInputChange(event, "groupEmail2")
                        }
                        name="groupEmail2"
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.groupEmail2
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Group Phone Number 1
                        <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Group Phone Number 1"
                        type="text"
                        className="text-input"
                        data-test-id="groupPhoneNumber1"
                        value={divisionData.groupPhoneNumber1}
                        onChange={(event) =>
                          this.handleDivisionInputChange(
                            event,
                            "groupPhoneNumber1"
                          )
                        }
                        name="groupPhoneNumber1"
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.groupPhoneNumber1
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Group Phone Number 2
                        <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Group Phone Number 2"
                        type="text"
                        className="text-input"
                        data-test-id="groupPhoneNumber2"
                        value={divisionData.groupPhoneNumber2}
                        onChange={(event) =>
                          this.handleDivisionInputChange(
                            event,
                            "groupPhoneNumber2"
                          )
                        }
                        name="groupPhoneNumber2"
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.groupPhoneNumber2
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Group Billing Contact
                        <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Group Billing Contact"
                        type="text"
                        className="text-input"
                        data-test-id="groupBillingContact"
                        value={divisionData.groupBillingContact}
                        onChange={(event) =>
                          this.handleDivisionInputChange(
                            event,
                            "groupBillingContact"
                          )
                        }
                        name="groupBillingContact"
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.groupBillingContact
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Enroll Status
                        <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Enroll Status"
                        type="text"
                        className="text-input"
                        data-test-id="enrollStatus"
                        value={divisionData.enrollStatus}
                        onChange={(event) =>
                          this.handleDivisionInputChange(event, "enrollStatus")
                        }
                        name="enrollStatus"
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.enrollStatus
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Group Tax ID
                        <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Group Tax ID"
                        type="text"
                        className="text-input"
                        data-test-id="groupTaxID"
                        value={divisionData.groupTaxID}
                        onChange={(event) =>
                          this.handleDivisionInputChange(event, "groupTaxID")
                        }
                        name="groupTaxID"
                      />
                      {formValidationMsg(
                        divisionAddressDetailsValidation.groupTaxID
                      )}
                    </Grid>
                  </Grid>
                )}
                {divisionActiveStep === 2 && (
                  <Grid container spacing={3} data-test-id="step-3-wrapper">
                    <Grid container item xs={12} sm={12} md={12}>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className="text-input-label">
                          Upload a logo
                          <span className="error-asterisk">*</span>
                        </div>
                        <div>
                          <input
                            accept="image/*"
                            type="file"
                            id="division-logo-upload-input"
                            style={{ display: "none" }}
                            data-test-id="division-logo-upload"
                            onChange={(e) =>
                              this.handleDivisionFileUpload(e, "logo")
                            }
                            name="logo"
                          />
                          <label htmlFor="division-logo-upload-input">
                            <Button
                              variant="outlined"
                              component="span"
                              color="primary"
                              fullWidth
                              className="emp-img-upload-btn"
                            >
                              Choose a File
                            </Button>
                          </label>
                        </div>
                        {formValidationMsg(logoErrorMessage)}
                      </Grid>
                    </Grid>
                    {logoUrl && (
                      <Grid item xs={12} sm={12} md={12}>
                        <div className="text-input-label">Preview</div>
                        <div className="emp-img-preview division-logo-preview">
                          <img src={logoUrl} height={96} width={96} />
                        </div>
                      </Grid>
                    )}
                    <div className="emp-img-upload-divider division-img-upload-divider" />
                    <Grid container item xs={12} sm={12} md={12}>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className="text-input-label">
                          Upload Documents (format: doc, pdf)
                          <span className="error-asterisk">*</span>
                        </div>
                        <div>
                          <input
                            accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            type="file"
                            id="division-file-upload-input"
                            style={{ display: "none" }}
                            data-test-id="supporting-doc"
                            onChange={(e) =>
                              this.handleDivisionFileUpload(
                                e,
                                "supportingDocument"
                              )
                            }
                            name="supporting-doc"
                          />
                          <label htmlFor="division-file-upload-input">
                            <Button
                              variant="outlined"
                              color="primary"
                              component="span"
                              fullWidth
                              className="emp-img-upload-btn"
                            >
                              Choose a File
                            </Button>
                          </label>
                        </div>
                        {formValidationMsg(supportingDocErrorMessage)}
                        <div className="support-doc-name">
                          {supportingDocName}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Divider className="emp-dialog-flex-divider" />
            <div className="edit-emp-flex-wrapper edit-emp-action-flex">
              <div>
                {divisionActiveStep <= 1 && (
                  <Button
                    variant="contained"
                    className="edit-emp-save-next-btn"
                    onClick={this.saveAndNext}
                    data-test-id="save-and-next-btn"
                  >
                    Save & Next
                  </Button>
                )}
              </div>
              <div>
                <IconButton
                  disabled={divisionActiveStep <= 0}
                  className="emp-dialog-previous-btn"
                  onClick={this.handlePrevious}
                  data-test-id="previous-step-btn"
                >
                  <KeyboardArrowLeftRoundedIcon fontSize="large" />
                </IconButton>
                {divisionActiveStep < 2 && (
                  <IconButton
                    disabled={divisionActiveStep >= 2}
                    className="emp-dialog-next-btn"
                    onClick={this.handleNext}
                    data-test-id="next-step-btn"
                  >
                    <KeyboardArrowRightRoundedIcon fontSize="large" />
                  </IconButton>
                )}
                {divisionActiveStep === 2 && (
                  <Button
                    variant="contained"
                    className="edit-emp-save-next-btn"
                    onClick={this.validateDivisionFiles}
                    data-test-id="save-changes-btn"
                  >
                    Save Changes
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Dialog>
        <GenericCloseDialog
          open={this.state.isOpenWarningDialog}
          handleClose={() => this.handleCloseWarningDialog(false)}
          handleOk={() => this.handleCloseWarningDialog(true)}
        />
      </ThemeProvider>
    );
  }
}
