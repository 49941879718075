import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";

import MyDocumentsController, {
  Props,
  configJSON,
  images,
} from "./MyDocumentsController.web";
import "./MyDocuments.web.css";
import GenericSearch from "../../../components/src/GenericSearch.web";
import CustomerTable from "../../../components/src/CustomTable.web";
import GenericDialog from "../../../components/src/GenericDialog.web";
import GenericButton from "../../../components/src/GenericButton.web";
import CustomFileDropInput from "../../../components/src/CustomFileDropInput.web";
import GenericTextAreaBox from "../../../components/src/GenericTextAreaBox.web";
import GenericSuccessDialog from "../../../components/src/GenericSuccessDialog.web";

export default class MyDocuments extends MyDocumentsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const dialogContain = (
      <>
        <CustomFileDropInput
          data-test-id="file-drop-input"
          file={this.state.addedFile}
          onChange={this.handleFileOnChange}
          error={this.state.addedFileError}
        />
        <GenericTextAreaBox
          data-test-id="text-area-input"
          label={configJSON.description}
          className="dialog-textarea-box"
          value={this.state.descriptionValue}
          onChange={this.handleDescriptionOnChange}
          error={this.state.descriptionError}
        />
      </>
    );

    const dialogActions = (
      <GenericButton
        data-test-id="action-add-document-btn"
        variant="contained"
        color="primary"
        onClick={this.handleAddDocument}
      >
        {configJSON.addDocument}
      </GenericButton>
    );

    return (
      <>
        <Grid container className="my-documents-right-side-container">
          <Grid item xs={12} className="d-flex">
            <Typography variant="body1" className="greeting-name">
              {configJSON.myDocuments}
            </Typography>
          </Grid>
          <Grid item xs={12} className="search-bar-and-add-btn">
            <GenericSearch
              data-test-id="my-document-search"
              searchIcon={images.searchIcon}
              placeholder={configJSON.search}
              value={this.state.searchValue}
              onChange={this.onSearchInputChange}
            />
            <div className="add-document-btn-div">
              <Button
                data-test-id="add-document-btn"
                className="add-document-btn"
                onClick={this.handleClickOpen}
              >
                {configJSON.addDocument}
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} className="hr mt-30" />
          <Grid item xs={12}>
            <CustomerTable
              columns={this.tableColumns}
              rowData={this.state.rowData}
              onActionClick={()=>{}}
            />
          </Grid>
        </Grid>
        {this.state.openAddDialog && (
          <GenericDialog
            title={configJSON.addDocument}
            open={this.state.openAddDialog}
            handleClose={this.handleClose}
            contains={dialogContain}
            actions={dialogActions}
            hideCloseDialog={!this.state.isFormChange}
            maxWidth="md"
          />
        )}
        {this.state.isDataSubmitted && (
          <GenericSuccessDialog
            open={this.state.isDataSubmitted}
            handleDone={this.handleSuccessClose}
          />
        )}
      </>
    );
  }
}
