import React, { Component } from "react";
import "./GenericDropdownSearch.css"
import {
  Box,
  Input,
  List,
  ListItem,
} from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

interface Props {
  selectedItem: string;
  openSearchBox: any;
  searchInputValue: string;
  searchItemList: string[];
  itemSelectionClickHandler: any;
  searchInputOnChangeHandler: any;
  itemListClickHandler: any;
  label: string;
  fieldName: string;
}

interface S {}

export default class GenericDropdownSearch extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
  }

  handleSelectedItemLabel = (selectedItem: string) => {
    if (selectedItem) {
      return selectedItem.length > 25 ? selectedItem.substring(0, 25) + "..." : selectedItem
    } else {
      return `Select`
    }
  }

  filteredItemList = () => {
    return this.props.searchItemList?.filter(item =>
      item?.toLowerCase().includes(this.props.searchInputValue)
    );
  } 

  render() {
    return (
      <Box className="search-item-selection-box">
         {this.props.label && (
          <div className="text-input-label">
            {this.props.label}
            {/* {this.props.isRequired && <span className="error-asterisk">*</span>} */}
          </div>
        )}
        <Box
          onClick={this.props.itemSelectionClickHandler}
          className={`item_input_box ${!this.props.selectedItem && " text-gray-700"
            }`}
        >
          {this.handleSelectedItemLabel(this.props.selectedItem)}
          <KeyboardArrowDownIcon className={`${this.props.openSearchBox && "rotate-180"}`} />
        </Box>
        <List
          className={`item_container ${this.props.openSearchBox ? "max-h-160" : "hidden"
            } `}
        >
          <Box style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            borderRadius: "6px",
            border: "1px solid #B1B6CD",
            marginBottom: "10px",
            paddingLeft: "5px",            
          }}>
            <SearchOutlinedIcon className="search_icon" style={{ marginRight: "5px" }} />
            <Input
              type="text"
              value={this.props.searchInputValue}
              onChange={(e) => this.props.searchInputOnChangeHandler(e.target.value.toLowerCase())}
              placeholder={`Enter ${this.props.label}`}
              className="item_input"
              name={this.props.fieldName}
              data-test-id="dropdown-search-input"
            />
          </Box>
          {this.filteredItemList().length === 0 ? 
            (<ListItem className="item_list_item" onClick={() => this.props.itemListClickHandler("")}>
              No record found
            </ListItem>)
          :
          (<> 
          {this.filteredItemList().map((item) => (
            <ListItem
              key={item}
              className={`item_list_item
              ${item?.toLowerCase() === this.props.selectedItem?.toLowerCase() &&
                "selected_item_list_item"
                }
              ${item?.toLowerCase().includes(this.props.searchInputValue)
                  ? "block"
                  : "hidden"
                }`}
              onClick={() => {
                if (item?.toLowerCase() !== this.props.selectedItem.toLowerCase()) {
                  this.props.itemListClickHandler(item)
                }
              }}
            >
              {item}
            </ListItem>
          ))}
          </>)}
        </List>
      </Box>
    );
  }
}


