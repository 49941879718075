import React, { Component } from "react";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";

import "./GenericSelectField.web.css";

export interface SelectionList {
  id: number | string;
  name: string;
}

interface Props {
  value: string | number;
  selectionList: SelectionList[];
  onChange?: (e: any) => void;
  error?: string;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  variant?: "outlined" | "standard" | "filled";
  disabled?: boolean;
  name?: string;
}

interface S { }

export default class GenericSelectField extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="generic-select-field">
        {this.props.label && (
          <div className="select-input-label">
            {this.props.label}
            {this.props.isRequired && <span className="error-asterisk">*</span>}
          </div>
        )}
        <FormControl variant={this.props.variant || "outlined"} fullWidth>
          <Select
            name={this.props.name}
            fullWidth
            IconComponent={(dropdownProps) => (
              <KeyboardArrowDownRoundedIcon {...dropdownProps} />
            )}
            className="select-dropdown"
            placeholder={this.props.placeholder}
            value={this.props.value}
            onChange={(e) =>
              this.props.onChange ? this.props.onChange(e) : () => { }
            }
            disabled={this.props.disabled}
            error={!!this.props.error}
          >
            {this.props.selectionList.map((item) => (
              <MenuItem value={item.id} key={item.name + item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {this.props.error && (
          <div className="input-validation-msg">{this.props.error}</div>
        )}
      </div>
    );
  }
}
