import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";

import "./GenericDialog.web.css";
import GenericCloseDialog from "./GenericCloseDialog.web";
const closeIcon = require("./close_icon.png");

interface Props {
  open: boolean;
  handleClose: () => void;
  title: string;
  contains: any;
  actions?: any;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  hideActions?: boolean;
  hideCloseDialog?: boolean;
  className?: string;
  scroll?: "body" | "paper";
}

interface S {
  openCloseDialog: boolean;
}

export default class GenericDialog extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openCloseDialog: false,
    };
  }

  handleClose = () => {
    if (this.props.hideCloseDialog) this.props.handleClose();
    else this.setState({ openCloseDialog: true });
  };

  handleCloseDialogClose = () => {
    this.setState({ openCloseDialog: false });
  };

  handleCloseDialogOk = () => {
    this.setState({ openCloseDialog: false });
    this.props.handleClose();
  };

  render() {
    return (
      <>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
          className={`generic-dialog ${this.props.className || ""}`}
          maxWidth={this.props.maxWidth || "sm"}
          fullWidth
          scroll={this.props.scroll || "body"}
        >
          <DialogTitle id="customized-dialog-title">
            {this.props.title}
            <IconButton
              aria-label="close"
              data-test-id="dialog-close"
              onClick={this.handleClose}
            >
              <img src={closeIcon} alt="Close Icon" className="close-icon" />
            </IconButton>
          </DialogTitle>
          <DialogContent
            dividers
            className={this.props.hideActions ? "pb-0" : ""}
          >
            {this.props.contains}
          </DialogContent>
          {!this.props.hideActions && (
            <DialogActions>{this.props.actions}</DialogActions>
          )}
        </Dialog>
        <GenericCloseDialog
          open={this.state.openCloseDialog}
          handleClose={this.handleCloseDialogClose}
          handleOk={this.handleCloseDialogOk}
        />
      </>
    );
  }
}
