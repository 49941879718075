import React from "react";
import { Grid, Button, Menu, MenuItem } from "@material-ui/core";

import ClientEmployerLocationController, {
  Props,
  images,
  configJSON
} from "./ClientEmployerLocationController";
import "./ClientEmployerLocation.web.css";
import CustomClientTabPanel from "../../../../components/src/CustomClientTabPanel.web";
import GenericSearch from "../../../../components/src/GenericSearch.web";
import CustomerTable from "../../../../components/src/CustomTable.web";
import CustomFilterPopup from "../../../../components/src/CustomFilterPopup.web";
import AddEditLocationDialog from "./ClientEmployerLocationDialog/AddEditLocationDialog.web";
import CustomTableMenu from "../../../../components/src/CustomTableMenu.web";

export default class ClientEmployerLocation extends ClientEmployerLocationController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="client-employer-location">
          <div
            data-test-id="client-employer-heading"
            className="client-employer-heading mb-20"
          >
            {configJSON.locationHeading}
          </div>
          <div>
            <CustomClientTabPanel
              tabList={this.tabMenuList}
              isDisplayAllTab
              allTabLabel={"Location"}
            >
              <Grid container className="all-location-tab-container">
                <Grid item xs={12} className="location-search-btn">
                  <div className="search-bar-and-add-btn p-relative">
                    <GenericSearch
                      data-test-id="location-search"
                      searchIcon={images.searchIcon}
                      placeholder={configJSON.searchForLocation}
                      value={this.state.searchValue}
                      onChange={this.onSearchInputChange}
                    />
                    <div className="search-filter-btn">
                      <Button
                        data-test-id="filter-btn"
                        onClick={this.handleLocationFilterClick}
                        className="filter-icon"
                      >
                        <img
                          src={
                            this.state.openFilter
                              ? images.filterIconSelected
                              : images.filterIcon
                          }
                          alt="Filter Icon"
                        />
                      </Button>
                      {this.state.openFilter && (
                        <CustomFilterPopup
                          searchIcon={images.searchIcon}
                          filterChildList={this.state.filterChildList}
                          onChange={this.handleLocationFilterOrSearchOnChange}
                        />
                      )}
                      <Button
                        data-test-id="add-location-btn"
                        className="search-btn"
                        onClick={() =>
                          this.toggleEditLocationDialog(true, false)
                        }
                      >
                        {configJSON.addLocation}
                      </Button>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} className="hr mt-30" />
                <Grid item xs={12}>
                  <CustomerTable
                    columns={this.tableColumns}
                    rowData={this.state.rowData}
                    isColumnSelectable
                    onActionClick={() => { }}
                  />
                  <CustomTableMenu
                    selectedMenuAnchorEl={this.state.locationTableMenuAnchorEl}
                    menuItemList={[
                      { id: "1", label: "Edit", icon: images.blackEditIcon },
                      { id: "2", label: "Delete", icon: images.deleteIcon },
                    ]}
                    closeTableMenu={this.handleLocationCloseTableMenu}
                    menuItemOnClick={this.handleLocationTableMenuClick}
                  />
                </Grid>
              </Grid>
              {this.tabMenuList.map(tab => (
                <>{tab}</>
              ))}
            </CustomClientTabPanel>
            {this.state.isOpenEditLocationDialog && (
              <AddEditLocationDialog
                isOpen={this.state.isOpenEditLocationDialog}
                isEdit={this.state.isEditLocation}
                handleClose={this.handleLocationDialogClose}
                data-test-id="location-dialog-id"
                selectedEmployer={this.props.selectedEmployer}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
