import React from "react";
import { ThemeProvider } from "@material-ui/styles";

import { Grid } from "@material-ui/core";
import GenericCloseDialog from "../../../../components/src/GenericCloseDialog.web";
import { defaultMaterialTheme } from "../../../dashboard/src/EditEmployerDetailsDialog.web";
import GenericDialogFormWrapper from "../../../../components/src/GenericDialogFormWrapper.web";
import EditClientMemberDialogController, {
  Props
} from "./EditClientMemberDialogController";
import "./ClientMember.web.css";
import GenericAddressComponent from "../../../../components/src/GenericeAddressFields.web";
import GenericTextFieldWithSelect from "../../../../components/src/GenericTextFieldWithSelect.web";
import GenericDropdownSearch from "../../../../components/src/GenericDropdownSearch.web";
import GenericTextField from "../../../../components/src/GenericTextField.web";
import GenericDateField from "../../../../components/src/GenericDateField.web";
import GenericCheckBoxField from "../../../../components/src/GenericCheckBoxField.web";
import GenericSelectField from "../../../../components/src/GenericSelectField.web";
import GenericTextAreaBox from "../../../../components/src/GenericTextAreaBox.web";
import GenericSuccessDialog from "../../../../components/src/GenericSuccessDialog.web";
import { GenderData } from "./ClientMemberModel";
export const configJSON = require("../config");

export default class EditClientMemberDialog extends EditClientMemberDialogController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      memberActiveStep,
      isOpenWarningDialog,
      editMemberFormStepList,
      employerList,
      classList,
      locationList,
      planList,
      coverageList,
      groupList,
      divisionList,
      memberData: {
        memberBasicDetails,
        memberAddressDetails,
        memberOtherDetails
      },
      memberBasicSuccess
    } = this.state;

    const disableBasicFields = memberBasicSuccess && !this.props.isEdit;

    const clientMemberFormContent = (
      <div>
        {memberActiveStep === 0 && (
          <Grid container spacing={3} data-test-id="step-1-wrapper">
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="First Name"
                value={memberBasicDetails.first_name}
                onChange={e =>
                  this.handleMemberInputItemChange(e, "first_name")
                }
                data-test-id="first_name"
                nameField={true}
                disabled={disableBasicFields}
                error={this.getMembersErrorMessage("first_name")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Last Name"
                value={memberBasicDetails.last_name}
                onChange={e => this.handleMemberInputItemChange(e, "last_name")}
                data-test-id="last_name"
                nameField={true}
                disabled={disableBasicFields}
                error={this.getMembersErrorMessage("last_name")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Middle Name"
                value={memberBasicDetails.middle_name}
                onChange={e =>
                  this.handleMemberInputItemChange(e, "middle_name")
                }
                nameField={true}
                disabled={disableBasicFields}
                data-test-id="middle_name"
                error={this.getMembersErrorMessage("middle_name")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Member Relation"
                value={memberBasicDetails.member_relation}
                onChange={e =>
                  this.handleMemberInputItemChange(e, "member_relation")
                }
                type="number"
                disabled={disableBasicFields}
                data-test-id="member_relation"
                error={this.getMembersErrorMessage("member_relation")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Name Prefix"
                value={memberBasicDetails.name_prefix}
                onChange={e =>
                  this.handleMemberInputItemChange(e, "name_prefix")
                }
                nameField={true}
                disabled={disableBasicFields}
                data-test-id="name_prefix"
                error={this.getMembersErrorMessage("name_prefix")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Name Suffix"
                value={memberBasicDetails.name_suffix}
                onChange={e =>
                  this.handleMemberInputItemChange(e, "name_suffix")
                }
                nameField={true}
                disabled={disableBasicFields}
                data-test-id="name_suffix"
                error={this.getMembersErrorMessage("name_suffix")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Member No"
                value={memberBasicDetails.member_number}
                onChange={e =>
                  this.handleMemberInputItemChange(e, "member_number")
                }
                isRequired={true}
                data-test-id="member_number"
                type="number"
                disabled={disableBasicFields}
                error={this.getMembersErrorMessage("member_number")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Employer Name"
                selectionList={employerList}
                value={memberBasicDetails.employer_id}
                disabled={disableBasicFields}
                isRequired
                onChange={e =>
                  this.handleMemberInputItemChange(e, "employer_id")
                }
                data-test-id="employer_id"
                error={this.getMembersErrorMessage("employer_id")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Group Name"
                selectionList={groupList}
                disabled={disableBasicFields}
                value={memberBasicDetails.group_id}
                onChange={e => this.handleMemberInputItemChange(e, "group_id")}
                data-test-id="group_id"
                error={this.getMembersErrorMessage("group")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Division Name"
                selectionList={divisionList}
                disabled={disableBasicFields}
                value={memberBasicDetails.division_id}
                onChange={e =>
                  this.handleMemberInputItemChange(e, "division_id")
                }
                error={this.getMembersErrorMessage("division")}
                data-test-id="division_id"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Location Name"
                selectionList={locationList}
                disabled={disableBasicFields}
                value={memberBasicDetails.location_id}
                onChange={e =>
                  this.handleMemberInputItemChange(e, "location_id")
                }
                error={this.getMembersErrorMessage("location")}
                data-test-id="location_id"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Class Name"
                selectionList={classList}
                disabled={disableBasicFields}
                value={memberBasicDetails.class_basic_details_id}
                onChange={e =>
                  this.handleMemberInputItemChange(e, "class_basic_details_id")
                }
                error={this.getMembersErrorMessage("class_basic_details")}
                data-test-id="class_basic_details_id"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Client Name"
                selectionList={[
                  {
                    id: this.props.clientDetails.client_id,
                    name: this.props.clientDetails.client_name
                  }
                ]}
                value={memberBasicDetails.client_name}
                onChange={e =>
                  this.handleMemberInputItemChange(e, "client_name")
                }
                data-test-id="client_name"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="SSN"
                value={memberBasicDetails.ssn}
                onChange={e => this.handleMemberInputItemChange(e, "ssn")}
                data-test-id="ssn"
                isRequired={true}
                disabled={disableBasicFields}
                error={this.getMembersErrorMessage("ssn")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Effective Date"
                disabled={disableBasicFields}
                value={memberBasicDetails.effective_date}
                onChange={date =>
                  this.handleMemberInputItemChange(date, "effective_date")
                }
                data-test-id="effective_date"
                error={this.getMembersErrorMessage("effective_date")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Termination Date"
                value={memberBasicDetails.termination_date}
                onChange={date =>
                  this.handleMemberInputItemChange(date, "termination_date")
                }
                disabled={disableBasicFields}
                data-test-id="termination_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Link SSN"
                value={memberBasicDetails.link_ssn}
                onChange={e => this.handleMemberInputItemChange(e, "link_ssn")}
                data-test-id="link_ssn"
                type="number"
                disabled={disableBasicFields}
                error={this.getMembersErrorMessage("link_ssn")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Other Id"
                value={memberBasicDetails.other_id}
                onChange={e => this.handleMemberInputItemChange(e, "other_id")}
                data-test-id="other_id"
                type="number"
                disabled={disableBasicFields}
                error={this.getMembersErrorMessage("other_id")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Other Desc"
                value={memberBasicDetails.other_desc}
                onChange={e =>
                  this.handleMemberInputItemChange(e, "other_desc")
                }
                type="number"
                disabled={disableBasicFields}
                data-test-id="other_desc"
                error={this.getMembersErrorMessage("other_desc")}
              />
            </Grid>
          </Grid>
        )}
        {memberActiveStep === 1 && (
          <Grid
            container
            spacing={3}
            className="client-member-step-2-wrapper"
            data-test-id="step-2-wrapper"
          >
            <Grid item xs={12} sm={12} md={12}>
              <div className="address_label">Physical Address</div>
            </Grid>
            <GenericAddressComponent
              type="physical"
              addressValues={memberAddressDetails}
              handleAddressChange={this.handleAddressItemChange}
              countryList={this.state.countryList}
              stateList={this.state.stateList}
            />
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="emp-edit-address-header"
            >
              <div className="member-mailing-address-flex-wrapper">
                <div className="address_label">Mailing Address</div>
                <div>
                  <GenericCheckBoxField
                    checked={this.state.isMailingAddressSameAsPhysicalAddress}
                    label="Mailing address same as physical address"
                    onChange={(event: React.ChangeEvent<any>) =>
                      this.handleMailingAddressCheckbox(event)
                    }
                  />
                </div>
              </div>
            </Grid>
            <GenericAddressComponent
              type="mailing"
              addressValues={memberAddressDetails}
              handleAddressChange={this.handleAddressItemChange}
              countryList={this.state.countryList}
              stateList={this.state.mailingStateList}
              disabled={!!this.state.isMailingAddressSameAsPhysicalAddress}
            />
            <Grid container spacing={3} className="contactspace">
              <Grid item xs={12} sm={12} md={6}>
                <GenericSelectField
                  selectionList={GenderData}
                  label="Gender"
                  value={memberAddressDetails.member_gender}
                  onChange={e =>
                    this.handleAddressItemChange(e, "member_gender")
                  }
                  error={this.getMembersErrorMessage("member_gender")}
                  data-test-id="member-gender"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <GenericTextField
                  label="Age"
                  value={memberAddressDetails.member_age}
                  onChange={e => this.handleAddressItemChange(e, "member_age")}
                  data-test-id="member-age"
                  type="number"
                  error={this.getMembersErrorMessage("member_age")}
                />
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <GenericDateField
                    label="Date Of Birth"
                    value={memberAddressDetails.member_dob}
                    onChange={e =>
                      this.handleAddressItemChange(e, "member_dob")
                    }
                    data-test-id="member-dob"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <GenericTextField
                  label="Phone Number"
                  value={memberAddressDetails.member_phone_number}
                  onChange={e =>
                    this.handleAddressItemChange(e, "member_phone_number")
                  }
                  type="number"
                  data-test-id="member-phone-number"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <GenericTextField
                  label="Phone Number Ext"
                  value={memberAddressDetails.member_phone_numberExt}
                  onChange={e =>
                    this.handleAddressItemChange(e, "member_phone_numberExt")
                  }
                  type="number"
                  data-test-id="member-phone-number-ext"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <GenericTextField
                  label="Fax 1"
                  value={memberAddressDetails.member_fax1}
                  onChange={e => this.handleAddressItemChange(e, "member_fax1")}
                  type="number"
                  data-test-id="member-fax1"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <GenericTextField
                  label="Fax 2"
                  value={memberAddressDetails.member_fax2}
                  onChange={e => this.handleAddressItemChange(e, "member_fax2")}
                  type="number"
                  data-test-id="member-fax2"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <GenericTextField
                  label="Email Address"
                  value={memberAddressDetails.member_email_address}
                  onChange={e =>
                    this.handleAddressItemChange(e, "member_email_address")
                  }
                  error={this.getMembersErrorMessage("member_email_address")}
                  data-test-id="member-email-address"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <GenericTextField
                  label="Mobile"
                  type="number"
                  value={memberAddressDetails.member_mobile}
                  onChange={e =>
                    this.handleAddressItemChange(e, "member_mobile")
                  }
                  data-test-id="member-mobile"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <GenericDropdownSearch
                  label={"Primary Language Spoken"}
                  selectedItem={
                    memberAddressDetails.member_primary_language_spoken
                  }
                  openSearchBox={this.state.isOpenGroupSearch}
                  searchInputValue={this.state.groupSearchInput}
                  searchItemList={[
                    "English (American)",
                    "English (United Kingdom)"
                  ]}
                  itemListClickHandler={this.itemListClickHandler}
                  fieldName="member_primary_language_spoken"
                  itemSelectionClickHandler={
                    this.groupItemSelectionClickHandler
                  }
                  searchInputOnChangeHandler={this.searchInputOnChangeHandler}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <GenericTextFieldWithSelect
                  type="number"
                  label="Salary/wage"
                  value={memberAddressDetails.member_salary}
                  onChange={e =>
                    this.handleAddressItemChange(e, "member_salary")
                  }
                  data-test-id="member-salary_or_wage"
                  selectArray={[
                    { id: 1, name: "Annual" },
                    { id: 2, name: "Hourly" }
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {memberActiveStep === 2 && (
          <Grid
            container
            spacing={3}
            className="client-member-step-3-wrapper"
            data-test-id="step-3-wrapper"
          >
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Paid Thru Date"
                value={memberOtherDetails.member_paid_through_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_paid_through_date")
                }
                data-test-id="member_paid_through_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Termination Date"
                value={memberOtherDetails.member_termination_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_termination_date")
                }
                data-test-id="member_termination_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Other ID"
                value={memberOtherDetails.member_other_id}
                onChange={e => this.handleOtherItemChange(e, "member_other_id")}
                data-test-id="member_other_id"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Other ID Desc"
                value={memberOtherDetails.member_other_id_desc}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_other_id_desc")
                }
                data-test-id="member_other_id_desc"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Hire Date"
                value={memberOtherDetails.member_hire_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_hire_date")
                }
                data-test-id="member_hire_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Client Employee ID"
                value={memberOtherDetails.member_client_emp_id}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_client_emp_id")
                }
                data-test-id="member_client_emp_id"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Alt Employee ID"
                value={memberOtherDetails.member_alt_emp_id}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_alt_emp_id")
                }
                data-test-id="member_alt_emp_id"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="CVG Effective(Change) Date"
                value={memberOtherDetails.member_cvg_effective_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_cvg_effective_date")
                }
                data-test-id="member_cvg_effective_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <p className="form-heading">Medical Plan</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Medical Plan"
                selectionList={planList}
                value={memberOtherDetails.member_medical_plan}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_medical_plan")
                }
                data-test-id="member_medical_plan"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Medical Effective Date"
                value={memberOtherDetails.member_medical_effective_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_medical_effective_date")
                }
                data-test-id="member_medical_effective_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Medical Terminate Date"
                value={memberOtherDetails.member_medical_term_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_medical_term_date")
                }
                data-test-id="member_medical_term_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Medical Coverage Type"
                selectionList={coverageList}
                value={memberOtherDetails.member_medical_cvg_type}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_medical_cvg_type")
                }
                data-test-id="member_medical_cvg_type"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <p className="form-heading">Dental Plan</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Dental Plan"
                selectionList={planList}
                value={memberOtherDetails.member_dental_plan}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_dental_plan")
                }
                data-test-id="member_dental_plan"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Dental Effective Date"
                value={memberOtherDetails.member_dental_effective_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_dental_effective_date")
                }
                data-test-id="member_dental_effective_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Dental Terminate Date"
                value={memberOtherDetails.member_dental_term_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_dental_term_date")
                }
                data-test-id="member_dental_term_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Dental Coverage Type"
                selectionList={coverageList}
                value={memberOtherDetails.member_dental_cvg_type}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_dental_cvg_type")
                }
                data-test-id="member_dental_cvg_type"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <p className="form-heading">Vision Plan</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Vision Plan"
                selectionList={planList}
                value={memberOtherDetails.member_vision_plan}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_vision_plan")
                }
                data-test-id="member_vision_plan"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Vision Effective Date"
                value={memberOtherDetails.member_vision_effective_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_vision_effective_date")
                }
                data-test-id="member_vision_effective_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Vision Terminate Date"
                value={memberOtherDetails.member_vision_term_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_vision_term_date")
                }
                data-test-id="member_vision_term_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Vision Coverage Type"
                selectionList={coverageList}
                value={memberOtherDetails.member_vision_cvg_type}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_vision_cvg_type")
                }
                data-test-id="member_vision_cvg_type"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <p className="form-heading">Rx Plan</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Rx Plan"
                selectionList={planList}
                value={memberOtherDetails.member_rx_plan}
                onChange={e => this.handleOtherItemChange(e, "member_rx_plan")}
                data-test-id="member_rx_plan"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Rx Effective Date"
                value={memberOtherDetails.member_rx_effective_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_rx_effective_date")
                }
                data-test-id="member_rx_effective_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Rx Terminate Date"
                value={memberOtherDetails.member_rx_term_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_rx_term_date")
                }
                data-test-id="member_rx_term_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Rx Coverage Type"
                selectionList={coverageList}
                value={memberOtherDetails.member_rx_cvg_type}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_rx_cvg_type")
                }
                data-test-id="member_rx_cvg_type"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <p className="form-heading">Group Life Plan</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Group Life Plan"
                selectionList={planList}
                value={memberOtherDetails.member_group_life_plan}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_group_life_plan")
                }
                data-test-id="member_group_life_plan"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Group Life Effective Date"
                value={memberOtherDetails.member_group_life_effective_date}
                onChange={e =>
                  this.handleOtherItemChange(
                    e,
                    "member_group_life_effective_date"
                  )
                }
                data-test-id="member_group_life_effective_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Group Life Terminate Date"
                value={memberOtherDetails.member_group_life_term_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_group_life_term_date")
                }
                data-test-id="member_group_life_term_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Group Life Coverage Type"
                selectionList={coverageList}
                value={memberOtherDetails.member_group_life_cvg_type}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_group_life_cvg_type")
                }
                data-test-id="member_group_life_cvg_type"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Group Life Benefit Amt"
                type="number"
                value={memberOtherDetails.member_group_life_benefit_amount}
                onChange={e =>
                  this.handleOtherItemChange(
                    e,
                    "member_group_life_benefit_amount"
                  )
                }
                data-test-id="member_group_life_benefit_amount"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <p className="form-heading">FSA Plan</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="FSA Plan"
                selectionList={planList}
                value={memberOtherDetails.member_fsa_plan}
                onChange={e => this.handleOtherItemChange(e, "member_fsa_plan")}
                data-test-id="member_fsa_plan"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="FSA Effective Date"
                value={memberOtherDetails.member_fsa_effective_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_fsa_effective_date")
                }
                data-test-id="member_fsa_effective_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="FSA Terminate Date"
                value={memberOtherDetails.member_fsa_term_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_fsa_term_date")
                }
                data-test-id="member_fsa_term_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="FSA Coverage Type"
                selectionList={coverageList}
                value={memberOtherDetails.member_fsa_cvg_type}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_fsa_cvg_type")
                }
                data-test-id="member_fsa_cvg_type"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <p className="form-heading">HRA Plan</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="HRA Plan"
                selectionList={planList}
                value={memberOtherDetails.member_hra_plan}
                onChange={e => this.handleOtherItemChange(e, "member_hra_plan")}
                data-test-id="member_hra_plan"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="HRA Effective Date"
                value={memberOtherDetails.member_hra_effective_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_hra_effective_date")
                }
                data-test-id="member_hra_effective_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="HRA Terminate Date"
                value={memberOtherDetails.member_hra_term_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_hra_term_date")
                }
                data-test-id="member_hra_term_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="HRA Coverage Type"
                selectionList={coverageList}
                value={memberOtherDetails.member_hra_cvg_type}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_hra_cvg_type")
                }
                data-test-id="member_hra_cvg_type"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <p className="form-heading">HSA Plan</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="HSA Plan"
                selectionList={planList}
                value={memberOtherDetails.member_hsa_plan}
                onChange={e => this.handleOtherItemChange(e, "member_hsa_plan")}
                data-test-id="member_hsa_plan"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="HSA Effective Date"
                value={memberOtherDetails.member_hsa_effective_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_hsa_effective_date")
                }
                data-test-id="member_hsa_effective_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="HSA Terminate Date"
                value={memberOtherDetails.member_hsa_term_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_hsa_term_date")
                }
                data-test-id="member_hsa_term_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="HSA Coverage Type"
                selectionList={coverageList}
                value={memberOtherDetails.member_hsa_cvg_type}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_hsa_cvg_type")
                }
                data-test-id="member_hsa_cvg_type"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <p className="form-heading">401K Plan</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="401k Plan"
                selectionList={planList}
                value={memberOtherDetails.member_401k_plan}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_401k_plan")
                }
                data-test-id="member_401k_plan"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="401k Effective Date"
                value={memberOtherDetails.member_401k_effective_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_401k_effective_date")
                }
                data-test-id="member_401k_effective_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="401k Terminate Date"
                value={memberOtherDetails.member_401k_term_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_401k_term_date")
                }
                data-test-id="member_401k_term_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="401k Coverage Type"
                selectionList={coverageList}
                value={memberOtherDetails.member_401k_cvg_type}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_401k_cvg_type")
                }
                data-test-id="member_401k_cvg_type"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <p className="form-heading">STD Plan</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="STD Plan"
                selectionList={planList}
                value={memberOtherDetails.member_std_plan}
                onChange={e => this.handleOtherItemChange(e, "member_std_plan")}
                data-test-id="member_std_plan"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="STD Effective Date"
                value={memberOtherDetails.member_std_effective_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_std_effective_date")
                }
                data-test-id="member_std_effective_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="STD Terminate Date"
                value={memberOtherDetails.member_std_term_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_std_term_date")
                }
                data-test-id="member_std_term_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="STD Coverage Type"
                selectionList={coverageList}
                value={memberOtherDetails.member_std_cvg_type}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_std_cvg_type")
                }
                data-test-id="member_std_cvg_type"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="STD Weekly Benefit Amt"
                type="number"
                value={memberOtherDetails.member_std_weekly_benefit_amount}
                onChange={e =>
                  this.handleOtherItemChange(
                    e,
                    "member_std_weekly_benefit_amount"
                  )
                }
                data-test-id="member_std_weekly_benefit_amount"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <p className="form-heading">LTD Plan</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="LTD Plan"
                selectionList={planList}
                value={memberOtherDetails.member_ltd_plan}
                onChange={e => this.handleOtherItemChange(e, "member_ltd_plan")}
                data-test-id="member_ltd_plan"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="LTD Effective Date"
                value={memberOtherDetails.member_ltd_effective_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_ltd_effective_date")
                }
                data-test-id="member_ltd_effective_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="LTD Terminate Date"
                value={memberOtherDetails.member_ltd_term_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_ltd_term_date")
                }
                data-test-id="member_ltd_term_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="LTD Coverage Type"
                selectionList={coverageList}
                value={memberOtherDetails.member_ltd_cvg_type}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_ltd_cvg_type")
                }
                data-test-id="member_ltd_cvg_type"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="LTD Mon Payroll Amt"
                type="number"
                value={memberOtherDetails.member_ltd_monthly_payroll_amount}
                onChange={e =>
                  this.handleOtherItemChange(
                    e,
                    "member_ltd_monthly_payroll_amount"
                  )
                }
                data-test-id="member_ltd_monthly_payroll_amount"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <p className="form-heading">Voluntary Life Plan</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Vol Life Plan"
                selectionList={planList}
                value={memberOtherDetails.member_voluntary_life_plan}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_voluntary_life_plan")
                }
                data-test-id="member_voluntary_life_plan"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Vol life Effective Date"
                value={memberOtherDetails.member_voluntary_life_effective_date}
                onChange={e =>
                  this.handleOtherItemChange(
                    e,
                    "member_voluntary_life_effective_date"
                  )
                }
                data-test-id="member_voluntary_life_effective_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Vol life Terminate Date"
                value={memberOtherDetails.member_voluntary_life_term_date}
                onChange={e =>
                  this.handleOtherItemChange(
                    e,
                    "member_voluntary_life_term_date"
                  )
                }
                data-test-id="member_voluntary_life_term_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Vol life Coverage Type"
                selectionList={coverageList}
                value={memberOtherDetails.member_voluntary_life_cvg_type}
                onChange={e =>
                  this.handleOtherItemChange(
                    e,
                    "member_voluntary_life_cvg_type"
                  )
                }
                data-test-id="member_voluntary_life_cvg_type"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Vol Life Benefit Amt"
                type="number"
                value={memberOtherDetails.member_voluntary_life_benefit_amount}
                onChange={e =>
                  this.handleOtherItemChange(
                    e,
                    "member_voluntary_life_benefit_amount"
                  )
                }
                data-test-id="member_voluntary_life_benefit_amount"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <p className="form-heading">Accident Plan</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Accident Plan"
                selectionList={planList}
                value={memberOtherDetails.member_accident_plan}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_accident_plan")
                }
                data-test-id="member_accident_plan"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Accident Effective Date"
                value={memberOtherDetails.member_accident_effective_date}
                onChange={e =>
                  this.handleOtherItemChange(
                    e,
                    "member_accident_effective_date"
                  )
                }
                data-test-id="member_accident_effective_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Accident Terminate Date"
                value={memberOtherDetails.member_accident_term_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_accident_term_date")
                }
                data-test-id="member_accident_term_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Accident Coverage Type"
                selectionList={coverageList}
                value={memberOtherDetails.member_accident_cvg_type}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_accident_cvg_type")
                }
                data-test-id="member_accident_cvg_type"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <p className="form-heading">Cancer Plan</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Cancer Plan"
                selectionList={planList}
                value={memberOtherDetails.member_cancer_plan}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_cancer_plan")
                }
                data-test-id="member_cancer_plan"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Cancer Effective Date"
                value={memberOtherDetails.member_cancer_effective_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_cancer_effective_date")
                }
                data-test-id="member_cancer_effective_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Cancer Terminate Date"
                value={memberOtherDetails.member_cancer_term_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_cancer_term_date")
                }
                data-test-id="member_cancer_term_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Cancer Coverage Type"
                selectionList={coverageList}
                value={memberOtherDetails.member_cancer_cvg_type}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_cancer_cvg_type")
                }
                data-test-id="member_cancer_cvg_type"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <p className="form-heading">Heart Stroke Plan</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Heart Stroke Plan"
                selectionList={planList}
                value={memberOtherDetails.member_heart_stroke_plan}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_heart_stroke_plan")
                }
                data-test-id="member_heart_stroke_plan"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                placeholder="Test1"
                label="Heart Stroke Effective Date"
                value={memberOtherDetails.member_heart_stroke_effective_date}
                onChange={e =>
                  this.handleOtherItemChange(
                    e,
                    "member_heart_stroke_effective_date"
                  )
                }
                data-test-id="member_heart_stroke_effective_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Heart Stroke Terminate Date"
                value={memberOtherDetails.member_heart_stroke_term_date}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_heart_stroke_term_date")
                }
                data-test-id="member_heart_stroke_term_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Heart Stroke Coverage Type"
                selectionList={coverageList}
                value={memberOtherDetails.member_heart_stroke_cvg_type}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_heart_stroke_cvg_type")
                }
                data-test-id="member_heart_stroke_cvg_type"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <p className="form-heading">Additional Benefits Plan</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Additional Benefits Plan"
                selectionList={planList}
                value={memberOtherDetails.member_additional_benefits_plan}
                onChange={e =>
                  this.handleOtherItemChange(
                    e,
                    "member_additional_benefits_plan"
                  )
                }
                data-test-id="member_additional_benefits_plan"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Additional Benefits Effective Date"
                value={
                  memberOtherDetails.member_additional_benefits_effective_date
                }
                onChange={e =>
                  this.handleOtherItemChange(
                    e,
                    "member_additional_benefits_effective_date"
                  )
                }
                data-test-id="member_additional_benefits_effective_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Additional Benefits Terminate Date"
                value={memberOtherDetails.member_additional_benefits_term_date}
                onChange={e =>
                  this.handleOtherItemChange(
                    e,
                    "member_additional_benefits_term_date"
                  )
                }
                data-test-id="member_additional_benefits_term_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Additional Benefits Coverage Type"
                selectionList={coverageList}
                value={memberOtherDetails.member_additional_benefits_cvg_type}
                onChange={e =>
                  this.handleOtherItemChange(
                    e,
                    "member_additional_benefits_cvg_type"
                  )
                }
                data-test-id="member_additional_benefits_cvg_type"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Date Of Death"
                value={memberOtherDetails.member_date_of_death}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_date_of_death")
                }
                error={this.getMembersErrorMessage("member_date_of_death")}
                data-test-id="member_date_of_death"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Annual Salary"
                type="number"
                value={memberOtherDetails.member_annual_salary}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_annual_salary")
                }
                data-test-id="member_annual_salary"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Payroll Cycle"
                selectionList={[
                  { name: "Weekly", id: "weekly" },
                  { name: "Monthly", id: "monthly" },
                  { name: "Yearly", id: "yearly" }
                ]}
                value={memberOtherDetails.member_payroll_cycle}
                onChange={e =>
                  this.handleOtherItemChange(e, "member_payroll_cycle")
                }
                data-test-id="member_payroll_cycle"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <GenericTextAreaBox
                label="Notes"
                value={memberOtherDetails.member_notes}
                onChange={e => this.handleOtherItemChange(e, "member_notes")}
                data-test-id="member_notes"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={memberOtherDetails.member_male}
                label="Male"
                onChange={(event: React.ChangeEvent<any>) =>
                  this.handleOtherItemCheckbox(event, "member_male")
                }
                data-test-id="member_male"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={memberOtherDetails.member_is_married}
                label="Is Married"
                onChange={(event: React.ChangeEvent<any>) =>
                  this.handleOtherItemCheckbox(event, "member_is_married")
                }
                data-test-id="member_is_married"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={memberOtherDetails.member_cobra}
                label="Cobra"
                onChange={(event: React.ChangeEvent<any>) =>
                  this.handleOtherItemCheckbox(event, "member_cobra")
                }
                data-test-id="member_cobra"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={memberOtherDetails.member_is_employer_ft}
                label="Is Employer FT"
                onChange={(event: React.ChangeEvent<any>) =>
                  this.handleOtherItemCheckbox(event, "member_is_employer_ft")
                }
                data-test-id="member_is_employer_ft"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={memberOtherDetails.member_vip}
                label="VIP"
                onChange={(event: React.ChangeEvent<any>) =>
                  this.handleOtherItemCheckbox(event, "member_vip")
                }
                data-test-id="member_vip"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={memberOtherDetails.member_retired}
                label="Retired"
                onChange={(event: React.ChangeEvent<any>) =>
                  this.handleOtherItemCheckbox(event, "member_retired")
                }
                data-test-id="member_retired"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={memberOtherDetails.member_is_student}
                label="Is Student"
                onChange={(event: React.ChangeEvent<any>) =>
                  this.handleOtherItemCheckbox(event, "member_is_student")
                }
                data-test-id="member_is_student"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={memberOtherDetails.member_terminate_member}
                label="Terminate Member"
                onChange={(event: React.ChangeEvent<any>) =>
                  this.handleOtherItemCheckbox(event, "member_terminate_member")
                }
                data-test-id="member_terminate_member"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={memberOtherDetails.member_is_spouse}
                label="Is Spouse"
                onChange={(event: React.ChangeEvent<any>) =>
                  this.handleOtherItemCheckbox(event, "member_is_spouse")
                }
                data-test-id="member_is_spouse"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={memberOtherDetails.member_is_child}
                label="Is Child"
                onChange={(event: React.ChangeEvent<any>) =>
                  this.handleOtherItemCheckbox(event, "member_is_child")
                }
                data-test-id="member_is_child"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={memberOtherDetails.member_do_not_bill}
                label="Do Not Bill"
                onChange={(event: React.ChangeEvent<any>) =>
                  this.handleOtherItemCheckbox(event, "member_do_not_bill")
                }
                data-test-id="member_do_not_bill"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={memberOtherDetails.member_disable_dependent}
                label="Disable Dependent"
                onChange={(event: React.ChangeEvent<any>) =>
                  this.handleOtherItemCheckbox(
                    event,
                    "member_disable_dependent"
                  )
                }
                data-test-id="member_disable_dependent"
              />
            </Grid>
          </Grid>
        )}
        <GenericSuccessDialog
          open={this.state.isMemberCreated}
          handleDone={this.handleDialogClose}
          contains={
            this.props.isEdit
              ? "Member edited Successfully"
              : "Member added Successfully"
          }
        />
      </div>
    );

    return (
      <ThemeProvider theme={defaultMaterialTheme}>
        <GenericDialogFormWrapper
          open={this.props.isOpen}
          title={this.props.isEdit ? "Edit Member" : "Add New Members"}
          handleClose={this.handleMemberDialogClose}
          stepList={editMemberFormStepList}
          activeStep={memberActiveStep}
          saveAndNext={this.saveAndNext}
          saveAndFinish={this.saveAndFinish}
          formContent={clientMemberFormContent}
          disableOnClick={!editMemberFormStepList[0].isCompleted}
          setActiveStep={this.setActiveStep}
          handlePrevious={() => {
            this.handleSteps(false);
          }}
          buttonTitle={
            this.props.isEdit ? configJSON.save_changes : configJSON.add_member
          }
        />
        <GenericCloseDialog
          open={isOpenWarningDialog}
          handleClose={() => this.handleCloseWarningDialog(false)}
          handleOk={() => this.handleCloseWarningDialog(true)}
        />
      </ThemeProvider>
    );
  }
}
