import { IBlock } from "../../../../framework/src/IBlock";
import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { Column } from "../../../../components/src/CustomTable.web";
import { runEngine } from "../../../../framework/src/RunEngine";
import { AddressData, EditIDs, EditIdsData, EmployeeOtherData, ErrorResponse, IMemberDetailModel, IMemberOtherDetailModel, MemberData, MemberDataResponse, MembersEditData, initialMemberDetailModelData, initialMemberOtherDetailModelData } from "./ClientMemberModel";
import { Message } from "../../../../framework/src/Message";
import { toast } from "react-toastify";
export const configJSON = require("../config");


export interface Props {
  id: string;
  navigation: any;
}

interface RowData {
  id: string;
  first_name: string;
  group_name: string;
  division_name: string;
  member_no: string | number;
  last_name: string;
  mi: string | number;
  client_name: string;
  employer_name: string;
  dob: Date | string;
  ed: string;
}
interface SelectionList {
  id: number | string;
  name: string;
  empId?: string;
}
interface S {
  newRowData: RowData[]
  rowData: RowData[];
  memberTableMenuAnchorEl: HTMLElement | null;
  isEditMemberDialogOpen: boolean;
  isEdit: boolean;
  isMemberDetailSaved: boolean;
  memberDetails: IMemberDetailModel;
  client_name: string;
  token: string;
  employerList: SelectionList[];
  locationList: SelectionList[];
  client_id: string;
  accountId: string;
  page: number;
  totalMembers: number;
  allDetailedMemberList: IMemberDetailModel[];
  actionId: string;
  EditMemberData: IMemberDetailModel;
  UpdateIds: EditIDs[];
  EditMembersId: EditIDs;
  deletedMemberSuccessfull: boolean;
}

interface SS {
  id: any;
}

export default class ClientMemberLandingPageController extends BlockComponent<Props, S, SS
> {
  membersListApiId: string = "";
  membersDetailsListApiId: string = "";
  employerListApiId: string = "";
  deleteMemberAPIId: string = "";
  locationsListAPIId: string = "";
  tableColumns: Column[] = [
    {
      id: "first_name",
      label: "First Name",
      headerAlign: "left",
      align: "left",
    },
    {
      headerAlign: "left",
      align: "left",
      id: "last_name",
      label: "Last Name",
    },
    {
      id: "mi",
      headerAlign: "left",
      align: "left",
      label: "MI",
    },
    {
      headerAlign: "left",
      id: "client_name",
      label: "Client Name",
      align: "left",
    },
    {
      align: "left",
      headerAlign: "left",
      id: "employer_name",
      label: "Employer Name",
    },
    {
      headerAlign: "left",
      id: "group_name",
      label: "Group Name",
      align: "left",
    },

    {
      label: "Division Name",
      headerAlign: "left",
      id: "division_name",
      align: "left",
    },
    {
      label: "Member No.",
      align: "left",
      headerAlign: "left",
      id: "member_no",
    },
    {
      label: "DOB",
      headerAlign: "left",
      id: "dob",
      align: "left",
    },
    {
      headerAlign: "left",
      id: "ed",
      align: "left",
      label: "ED",
    },
    {
      id: "action",
      label: "",
      headerAlign: "left",
      align: "right",
      format: (row: RowData) => {
        return (
          <div className="division-table-action-container" key={row.id}>
            <IconButton
              className="menu-btn"
              data-test-id="member-table-menu"
              onClick={this.handleMemberOpenTableMenu}
            >
              <MoreVertIcon fontSize="large" />
            </IconButton>
          </div>
        );
      },
    },
  ];

  tabMenuList = [
    "Benefits",
    "Enrollment",
    "Medical Review",
    "Notes",
    "Wellness",
    "Submit Claim",
  ];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      newRowData: [],
      rowData: [],
      memberTableMenuAnchorEl: null,
      isEditMemberDialogOpen: false,
      isEdit: false,
      isMemberDetailSaved: false,
      memberDetails: initialMemberDetailModelData,
      client_name: "",
      token: "",
      employerList: [],
      locationList: [],
      client_id: "",
      accountId: "",
      page: 1,
      totalMembers: 0,
      allDetailedMemberList:[],
      actionId:"",
      EditMemberData:initialMemberDetailModelData,
      UpdateIds: [],
      EditMembersId:EditIdsData,
      deletedMemberSuccessfull: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.membersListApiId) {
        this.getMemberListData(responseJson)
      }
      if (apiRequestCallId === this.employerListApiId) {
        this.getEmployersListData(responseJson)
      }
      if (apiRequestCallId === this.locationsListAPIId) {
        this.getLocationsListData(responseJson)
      }
      if (apiRequestCallId === this.membersDetailsListApiId) {
        this.handleDataSetup(responseJson.data)
      }
      if(apiRequestCallId === this.deleteMemberAPIId){
        this.setState({deletedMemberSuccessfull:true, memberTableMenuAnchorEl:null});
      }
    } else {
      runEngine.debugLog("GOIT");
    }
  }

  async componentDidMount() {
    const loggedInUser = localStorage.getItem("user");
    let token;
    if (loggedInUser) {
      const response = JSON.parse(loggedInUser);
      token = response.token;
      this.setState({ client_name: response.client_name, client_id: response.client_id, token: token, accountId: response.id })
    }
    this.fetchAllMemberWithData(token);
    this.fetchAllEmployer(token);
    this.fetchAllLocaitons(token);
    this.handleMembersPaginationChange(0, token);
  }

  fetchAllMemberWithData = (token: string) => {
    const header = {
      "Content-Type": configJSON.ContentType,
      "token": token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.membersDetailsListApiId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.membersListGetApiEndPoint}?page=1&per_page=200`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDataSetup = (data: { id: string, attributes: MembersEditData }[]) => {
    const updatedData = data.map((item: { id: string, attributes: MembersEditData }) => {
      const otherData = item.attributes.member_other_detail && item.attributes.member_other_detail.data ;
      return {
        id: item.id,
        memberBasicDetails: this.setBasicDetails(item.attributes),
        memberAddressDetails: this.setAddressData(item.attributes),
        memberOtherDetails: this.setOtherDetails(otherData)
      }
    })
    const updateIds = data.map((item: { id: string, attributes: MembersEditData }) => {
      const otherData = item.attributes.member_other_detail && item.attributes.member_other_detail.data ;
      const physicalData = item.attributes.physical_address&&item.attributes.physical_address.data;
      const mailingData = item.attributes.mailing_address&&item.attributes.mailing_address.data;
      const contactData = item.attributes.member_contact_information&&item.attributes.member_contact_information.data;
      return {
        id: item.id,
        physicalId: physicalData && physicalData.id || "",
        mailingId: mailingData && mailingData.id || "",
        contactId: contactData && contactData.id || "",
        otherId: otherData && otherData.id || "",
      }
    })
    this.setState({allDetailedMemberList:updatedData,UpdateIds:updateIds})
  }

  setBasicDetails = (data: MembersEditData) => {
    const {
      first_name,
      last_name,
      name_suffix,
      name_prefix,
      member_relation,
      middle_name,
      member_number,
      ssn,
      effective_date,
      termination_date,
      link_ssn,
      other_id,
      other_desc,
      account_id,
      employer,
      class_basic_details,
      group,
      location,
      division
    } = data;
    const ssnn = ssn.slice(0, 3)+"-"+ssn.slice(3, 5)+"-"+ssn.slice(5, 9);

    return {
      first_name,
      last_name,
      name_suffix,
      name_prefix,
      member_relation,
      middle_name,
      member_number,
      effective_date,
      termination_date,
      link_ssn,
      other_id,
      other_desc,
      account_id,
      ssn:ssnn,
      client_name: this.state.client_name,
      employer_id: employer && employer.id,
      class_basic_details_id: class_basic_details && class_basic_details.id,
      location_id: location && location.id,
      division_id: division && division.id,
      group_id: group && group.id,
    }
  }

  setAddressData = (data: MembersEditData) => {
    const {
      age,
      gender,
      date_of_birth,
      primary_language_spoken,
      salary,
      member_contact_information,
      email
    } = data;
    const contactData = member_contact_information&&member_contact_information.data&&member_contact_information.data.attributes;
    return {
      member_age: age,
      member_gender: gender,
      member_dob: date_of_birth,
      member_primary_language_spoken:primary_language_spoken,
      member_salary: salary,
      ...this.setPhysicalAddress(data.physical_address.data),
      ...this.setMailingAddress(data.mailing_address.data),
      member_fax1: contactData&&contactData.fax1,
      member_fax2: contactData&&contactData.fax2,
      member_email_address: email,
      member_mobile: contactData&&contactData.mobile,
      member_phone_number: contactData&&contactData.phone_number,
      member_phone_numberExt: contactData&&contactData.phone_number_ext,
    }
  }

  setPhysicalAddress = (data: AddressData) => {
    const {
      address_line_1,
      address_line_2,
      city,
      country,
      state,
      zip_code,
    } = data.attributes;
    return {
      physical_address_line1: address_line_1,
      physical_address_line2: address_line_2,
      physical_city: city,
      physical_zip_code: zip_code,
      physical_state_id: state,
      physical_country_id: country,
    }
  }

  setMailingAddress = (data: AddressData) => {
    const {
      address_line_1,
      address_line_2,
      city,
      country,
      state,
      zip_code,
    } = data.attributes;
    return {
      mailing_address_line1: address_line_1,
      mailing_address_line2: address_line_2,
      mailing_city: city,
      mailing_zip_code: zip_code,
      mailing_state_id: state,
      mailing_country_id: country,
    }
  }

  setOtherDetails = (otherData: { attributes: EmployeeOtherData, id: string } | null) => {
    if (!otherData) {
      return initialMemberOtherDetailModelData;
    }
  
    const { attributes: data } = otherData;
    const updatedData: IMemberOtherDetailModel = {...initialMemberOtherDetailModelData};
  
    Object.keys(data).forEach((key: string) => {
      const newKey = `member_${key}`;
      updatedData[newKey] = data[key];
    });
    return {
      ...updatedData,
      member_hire_date: data.hire_date,
      member_paid_through_date: data.paid_thru_date,
      member_termination_date: data.terminate_date,
      member_other_id: data.other_id,
      member_other_id_desc: data.otherid_description,
      member_client_emp_id: data.client_employee_id,
      member_alt_emp_id: data.alternate_employee_id,
      member_cvg_effective_date: data.cvg_eff_date,
      member_medical_effective_date: data.medical_eff_date,
      member_medical_term_date: data.medical_trm_date,
      member_dental_effective_date: data.dental_eff_date,
      member_dental_term_date: data.dental_trm_date,
      member_vision_effective_date: data.vision_eff_date,
      member_vision_term_date: data.vision_trm_date,
      member_rx_effective_date: data.rx_eff_date,
      member_rx_term_date: data.rx_trm_date,
      member_group_life_effective_date: data.group_life_eff_date,
      member_group_life_term_date: data.group_life_trm_date,
      member_group_life_benefit_amount: data.group_life_benefit_amount,
      member_hsa_effective_date: data.hsa_eff_date,
      member_hsa_term_date: data.hsa_trm_date,
      member_hra_effective_date: data.hra_eff_date,
      member_hra_term_date: data.hra_trm_date,
      member_fsa_effective_date: data.fsa_eff_date,
      member_fsa_term_date: data.fsa_trm_date,
      member_std_effective_date: data.std_eff_date,
      member_std_term_date: data.std_trm_date,
      member_std_weekly_benefit_amount: data.std_weekly_benefit_amount,
      member_ltd_effective_date: data.ltd_eff_date,
      member_ltd_term_date: data.ltd_trm_date,
      member_ltd_monthly_payroll_amount: data.ltd_mon_payroll_amount,
      member_voluntary_life_benefit_amount: data.vol_life_benefit_amount,
      member_accident_effective_date: data.accident_eff_date,
      member_accident_term_date: data.accident_trm_date,
      member_cancer_effective_date: data.cancer_eff_date,
      member_cancer_term_date: data.cancer_trm_date,
      member_heart_stroke_effective_date: data.heart_stroke_eff_date,
      member_heart_stroke_term_date: data.heart_stroke_trm_date,
      member_medical_plan: data.medical_plan_id,
      member_medical_cvg_type: data.medical_cvg_type_id,
      member_dental_plan: data.dental_plan_id,
      member_dental_cvg_type: data.dental_cvg_type_id,
      member_vision_plan: data.vision_plan_id,
      member_vision_cvg_type: data.vision_cvg_type_id,
      member_rx_plan: data.rx_plan_id,
      member_rx_cvg_type: data.rx_cvg_type_id,
      member_group_life_plan: data.group_life_plan_id,
      member_group_life_cvg_type: data.group_life_cvg_type_id,
      member_fsa_plan: data.fsa_plan_id,
      member_fsa_cvg_type: data.fsa_cvg_type_id,
      member_hra_plan: data.hra_plan_id,
      member_hra_cvg_type: data.hra_cvg_type_id,
      member_hsa_plan: data.hsa_plan_id,
      member_hsa_cvg_type: data.hsa_cvg_type_id,
      member_std_plan: data.std_plan_id,
      member_std_cvg_type: data.std_cvg_type_id,
      member_ltd_plan: data.ltd_plan_id,
      member_ltd_cvg_type: data.ltd_cvg_type_id,
      member_voluntary_life_plan: data.vol_life_plan_id,
      member_voluntary_life_cvg_type: data.vol_life_cvg_type_id,
      member_accident_plan: data.accident_plan_id,
      member_accident_cvg_type: data.accident_cvg_type_id,
      member_cancer_plan: data.cancer_plan_id,
      member_cancer_cvg_type: data.cancer_cvg_type_id,
      member_heart_stroke_plan: data.heart_stroke_plan_id,
      member_heart_stroke_cvg_type: data.heart_stroke_cvg_type_id,
      member_payroll_cycle: data.payroll_cycle_id
    };
  };

  getMemberListData = (response: MemberDataResponse | ErrorResponse | { data: {}[] }) => {
    if ('members' in response && response.members.data && response.members.data.length) {
      const members = response.members.data.map((item: MemberData) => ({
        id: item.id,
        client_name: item.attributes.account.client_name,
        division_name: item.attributes.division.division_name,
        employer_name: item.attributes.employer.employer_name,
        group_name: item.attributes.group.group_name,
        first_name: item.attributes.first_name,
        last_name: item.attributes.last_name,
        member_no: item.attributes.member_number,
        mi: item.attributes.member_detail.MI,
        dob: item.attributes.date_of_birth,
        ed: item.attributes.effective_date
      }))
      this.setState({
        rowData: members,
        totalMembers: response.count
      });
    } else if ('errors' in response) {
      const errors = Object.values(response.errors[0]);
      toast.error(errors[0]);
    } else {
      this.extractMemberTableData(response);
    }
  }

  getEmployersListData = (response: any) => {
    if (response && response.employers && response.employers.data.length) {
      const EMPData = response.employers.data.map((i: { attributes: { emp_id?: string, employer_name: string }, id: string }) => ({ id: i.id, empId: i.attributes.emp_id ? i.attributes.emp_id : i.id, name: i.attributes.employer_name }))
      this.setState({ employerList: EMPData })
    }
  }

  getLocationsListData = (response: { location_name: string, id: string }[]) => {
    if (response.length) {
      const locationData = response.map((i: { location_name: string, id: string }) => ({ id: i.id, name: i.location_name }));
      this.setState({ locationList: locationData })
    }
  }

  extractMemberTableData = (apiData: any) => {
    const extractedData: { id: any; first_name: any; last_name: any; mi: any; employer_name: any; group_name: any; division_name: any; member_no: any; dob: any; ed: any; client_name: any }[] = [];

    apiData.forEach((item: any) => {
      const id = item.id;
      const mi = item.id;
      const client_name = this.state.client_name;
      const employer_name = item.attributes.employer.name ? item.attributes.employer.name : "-";
      const group_name = item.attributes.group.name ? item.attributes.group.name : "-";
      const division_name = item.attributes.division.name ? item.attributes.division.name : "-";
      const first_name = item.attributes.first_name;
      const last_name = item.attributes.last_name;
      const member_no = item.attributes.member_number;
      const dob = item.attributes.date_of_birth;
      const ed = "-"

      const extractedItem = {
        id,
        mi,
        employer_name,
        group_name,
        client_name,
        division_name,
        first_name,
        last_name,
        member_no,
        dob,
        ed,
      }
      extractedData.push(extractedItem);
    });

    this.setState({
      ...this.state,
      rowData: extractedData,
    });
  }

  handleMemberOpenTableMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ memberTableMenuAnchorEl: event ? event.currentTarget : null, });
  };
  handleMemberCloseTableMenu = () => {
    this.setState({ ...this.state, memberTableMenuAnchorEl: null });
  };
  toggleEditMemberDialog = (open: boolean, isEdit: boolean) => {
    const EditData = this.state.allDetailedMemberList.find((item:IMemberDetailModel)=>item.id===this.state.actionId);
    const editMemberIds = this.state.UpdateIds.find((item:EditIDs)=>item.id===this.state.actionId);
    if (!open && !isEdit) {
      const token = this.state.token;
      this.handleMembersPaginationChange(this.state.page-1, token);
      this.fetchAllMemberWithData(token);
    }
    this.setState({
      ...this.state,
      isEditMemberDialogOpen: open,
      memberTableMenuAnchorEl: null,
      isEdit: isEdit,
      EditMemberData:EditData?EditData:initialMemberDetailModelData,
      EditMembersId: editMemberIds?editMemberIds:EditIdsData
    });
  };
  saveMemberDetails = () => {
    this.setState({ ...this.state, isMemberDetailSaved: true });
  };

  handleMembersPaginationChange = (page: number, token?: string) => {
    if (!!token) {
      this.fetchAllMembersList(token, page + 1)
    } else {
      this.fetchAllMembersList(this.state.token, page + 1)
    }
  }

  fetchAllMembersList = (token: string, page: number) => {
    this.setState({ page: page })
    const header = {
      "Content-Type": configJSON.ContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.membersListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllMembersListAPIEndPoint}?page=${page}&per_page=7`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchAllEmployer = (token: string) => {
    const header = {
      "Content-Type": configJSON.ContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.employerListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.employersListGetApiEndPoint}/get_employers?page=1&per_page=400`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchAllLocaitons = (token: string) => {
    const header = {
      "Content-Type": configJSON.ContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.locationsListAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getLocationsAPiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleMemberTableMenuClick = (id: string) => {
    if (id === "1") {
      this.toggleEditMemberDialog(true, true);
    }else if(id==="2"){
      this.handleDeleteAPIhandle();
    }
  };

  handleDeleteAPIhandle= () => {
    const {token,actionId} = this.state;
    const header = {
      "Content-Type": configJSON.ContentType,
      "token": token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteMemberAPIId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.membersListGetApiEndPoint}/${actionId}`
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpDeleteMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDialogClose = () => {
    const {rowData, page, token} = this.state;
    const isChange = rowData.length===1&&page!==1;
    this.setState({deletedMemberSuccessfull:false});
    if(isChange){
      this.handleMembersPaginationChange(page-2, token);
    }else{
      this.handleMembersPaginationChange(page-1, token);
    }
  }

  HandleActionClick = (id:string) => {
    this.setState({ actionId: id });
  }
}
