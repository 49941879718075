import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { StepList } from "../../../../components/src/CustomVerticalFormStepper.web";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../../framework/src/Message";
import { IClassAddressAndContactModel, IClassBasicDetailModel, IDropdownModel } from "../ClientEmployerClass/ClientEmpClassModel";
import {
  IClassDetailModel,
  initialClassDetailModelData
} from "./ClientEmpClassModel";
import { SelectedEmployerInterface } from "../AllRoleLandingPageController.web";
import moment from "moment";
import { IAgentModel } from "../../../../components/src/GenericAddAgentComponent.web";
import { initialAgentModelData } from "../ClientEmployerDivision/ClientEmployerDivisionModel";


export const configJSON = require("./../config");

export interface Props {
  isOpenEditClassDialog: boolean;
  isEdit: boolean;
  handleClose: any;
  saveClassData: any;
  classDetails: IClassDetailModel;
  selectedEmployer: SelectedEmployerInterface[],
}

interface SelectionList {
  id: number;
  name: string;
}

interface S {
  isClassCreated: boolean;
  apiClassId: string;
  classActiveStep: number;
  isOpenWarningDialog: boolean;
  addEditClassStepList: StepList[];
  classData: IClassDetailModel;
  fieldErrors: { [key: string]: string[] };
  generalError: string;

  groupList: IDropdownModel[];
  locationList: IDropdownModel[];
  divisionList: IDropdownModel[];
  countryList: IDropdownModel[];
  physicalStateList: IDropdownModel[];
  mailingStateList: IDropdownModel[];
  classCheckStateList: IDropdownModel[];

  mailSameAsPhysical: boolean;
  isSameAsPhysical: boolean;
  isSameAsMailing: boolean;
}

interface SS { }


type InputType = HTMLInputElement | HTMLTextAreaElement | { value: unknown };

export default class AddEditClientEmployerClassDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  token: string = "";
  client_name: string = "";
  stateType: string = "";
  getGroupCallId: string = "";
  getLocationCallId: string = "";
  getCountryId: string = "";
  getStateId: string = "";
  requestCreateClassCallId: string = "";
  getDivisonCallId: string = "";
  requestCreateClassAddressCallId: string = "";
  requiredClassFieldStep1: { name: keyof IClassBasicDetailModel, message: string }[] = [
    {
      name: 'class_name',
      message: 'Class name is required'
    },
    {
      name: 'class_id',
      message: 'Class id is required'
    },
    {
      name: 'group_id',
      message: 'Group name is required'
    },
    {
      name: 'division_id',
      message: 'Division name is required'
    },
    {
      name: 'location_id',
      message: 'Location name is required'
    },
    {
      name: 'tax_id',
      message: 'Tax ID is required'
    }
  ]

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      apiClassId: "",
      isClassCreated: false,
      classActiveStep: 0,

      addEditClassStepList: [
        {
          key: 1,
          title: "Class Basic Details",
          description: "Type and Select details",
          isCompleted: false
        },
        {
          key: 2,
          title: "Address & Contact",
          description: "Type and Select details",
          isCompleted: false
        },
        {
          key: 3,
          title: "Upload Logo / Supporting Document",
          description: "Type and Select details",
          isCompleted: false
        },
        {
          key: 4,
          title: "Other Details",
          description: "Type and Select details",
          isCompleted: false
        }
      ],
      classData: initialClassDetailModelData,

      fieldErrors: {},
      generalError: '',

      groupList: [],
      locationList: [],
      divisionList: [],
      countryList: [],
      physicalStateList: [],
      mailingStateList: [],
      classCheckStateList: [],

      isOpenWarningDialog: false,
      mailSameAsPhysical: false,
      isSameAsPhysical: false,
      isSameAsMailing: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const response = JSON.parse(loggedInUser);
      this.token = response.token;
      this.client_name = response.client_name
      this.updateClient(response.client_name)
    }
    this.getGroupList();
    this.getLocationList();
    this.getDivisonList();
    this.getCountryList()
  }

  updateClient(client_name: string) {
    this.setState({
      classData: {
        ...this.state.classData,
        classBasicDetails: {
          ...this.state.classData.classBasicDetails,
          employer_name: this.props.selectedEmployer[0].employer_name,
          employer_id: this.props.selectedEmployer[0].id,
          client_name,
        }
      }
    })
  }

  setActiveStep = (step: number) => {
    this.setState({ classActiveStep: step });
  };

  removeObjProperty = (propKey: keyof IClassBasicDetailModel | keyof IClassAddressAndContactModel, { [propKey]: propValue, ...rest }) => rest

  handleSameAsPhysical = (event: React.ChangeEvent<HTMLInputElement>, param: string) => {
    const {
      physicalStateList,
    } = this.state
    const {
      physical_address_line1,
      physical_address_line2,
      physical_city,
      physical_country_id,
      physical_state_id,
      physical_zip_code,
    } = this.state.classData.classAddressDetails;
    const isChecked = event.target.checked;

    this.setState({
      ...this.state,
      [param]: isChecked,
      classCheckStateList: isChecked ? physicalStateList : [],
      isSameAsMailing: false,
      classData: {
        ...this.state.classData,
        classAddressDetails: {
          ...this.state.classData.classAddressDetails,
          class_check_address_line1: isChecked ? physical_address_line1 : '',
          class_check_address_line2: isChecked ? physical_address_line2 : '',
          class_check_city: isChecked ? physical_city : '',
          class_check_country_id: isChecked ? physical_country_id : '',
          class_check_state_id: isChecked ? physical_state_id : '',
          class_check_zip_code: isChecked ? physical_zip_code : '',
        },
      },
    });
  }

  handleSameAsMailing = (event: React.ChangeEvent<HTMLInputElement>, param: string) => {
    const {
      mailingStateList
    } = this.state
    const {
      mailing_address_line1,
      mailing_address_line2,
      mailing_city,
      mailing_country_id,
      mailing_state_id,
      mailing_zip_code,
    } = this.state.classData.classAddressDetails;
    const isChecked = event.target.checked;

    this.setState({
      ...this.state,
      [param]: isChecked,
      classCheckStateList: isChecked ? mailingStateList : [],
      isSameAsPhysical: false,
      classData: {
        ...this.state.classData,
        classAddressDetails: {
          ...this.state.classData.classAddressDetails,
          class_check_address_line1: isChecked ? mailing_address_line1 : '',
          class_check_address_line2: isChecked ? mailing_address_line2 : '',
          class_check_city: isChecked ? mailing_city : '',
          class_check_country_id: isChecked ? mailing_country_id : '',
          class_check_state_id: isChecked ? mailing_state_id : '',
          class_check_zip_code: isChecked ? mailing_zip_code : '',
        },
      },
    });
  }

  classContactChange = (
    e: React.ChangeEvent<InputType>,
    param: any
  ) => {
    const regexEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/

    const eventValue = e.target.value;

    if (["class_check_email1", "class_check_email2"].includes(param)) {
      const val: any = eventValue

      const isValid = regexEmail.test(val)

      if (isValid || !eventValue) {
        this.setState({
          fieldErrors: this.removeObjProperty(param, this.state.fieldErrors)
        })
      } else {
        this.setState({
          fieldErrors: {
            ...this.state.fieldErrors,
            [param]: ["Email invalid"]
          }
        })
      }
    }

    this.setState({
      classData: {
        ...this.state.classData,
        classAddressDetails: {
          ...this.state.classData.classAddressDetails,
          [param]: eventValue,
        },
      },
    });
  };

  classAddressChange = (e: React.ChangeEvent<InputType>, param: string) => {
    const eventValue = e.target.value;

    let fieldName = ""
    if (param.includes("country")) {
      this.stateType = param
      this.getStateList(eventValue + "")
      switch (param) {
        case 'physical_country_id':
          fieldName = 'physical_state_id'
          break;
        case 'mailing_country_id':
          fieldName = 'mailing_state_id';
          break;
        case 'class_check_country_id':
          fieldName = 'class_check_state_id';
          break;

      }

      this.setState({
        classData: {
          ...this.state.classData,
          classAddressDetails: {
            ...this.state.classData.classAddressDetails,
          }
        }
      })
    }
    if (fieldName) {
      this.setState({
        classData: {
          ...this.state.classData,
          classAddressDetails: {
            ...this.state.classData.classAddressDetails,
            [param]: eventValue,
            [fieldName]: ""
          },
        },
      });
    } else {
      this.setState({
        classData: {
          ...this.state.classData,
          classAddressDetails: {
            ...this.state.classData.classAddressDetails,
            [param]: eventValue,
          },
        },
      });
    }
  }

  handleClassInputItemChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { value: any }
    >,
    param: keyof IClassBasicDetailModel
  ) => {
    const index = this.requiredClassFieldStep1.findIndex(item => item.name === param)

    if (index !== -1) {
      if (!e.target.value) {
        this.setState({
          fieldErrors: {
            ...this.state.fieldErrors,
            [param]: [this.requiredClassFieldStep1[index].message]
          }
        })
      } else {
        this.setState({
          fieldErrors: this.removeObjProperty(param, this.state.fieldErrors)
        })
      }
    }

    let inputValue = e.target.value;
    this.setState({
      classData: {
        ...this.state.classData,
        classBasicDetails: {
          ...this.state.classData.classBasicDetails,
          [param]: inputValue
        }
      }
    });
  };

  handleCheckboxMailing = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    const {
      physical_address_line1,
      physical_address_line2,
      physical_city,
      physical_country_id,
      physical_state_id,
      physical_zip_code,
    } = this.state.classData.classAddressDetails;

    const {
      physicalStateList,
    } = this.state

    this.setState({
      mailSameAsPhysical: isChecked,
      mailingStateList: isChecked ? physicalStateList : [],
      classData: {
        ...this.state.classData,
        classAddressDetails: {
          ...this.state.classData.classAddressDetails,
          mailing_address_line1: isChecked ? physical_address_line1 : '',
          mailing_address_line2: isChecked ? physical_address_line2 : '',
          mailing_city: isChecked ? physical_city : '',
          mailing_country_id: isChecked ? physical_country_id : '',
          mailing_state_id: isChecked ? physical_state_id : '',
          mailing_zip_code: isChecked ? physical_zip_code : '',
        },
      },
    });
  };

  getClassErrorMessage = (fieldName: string) => {
    const { fieldErrors } = this.state;
    if (fieldErrors[fieldName]) {
      return fieldErrors[fieldName][0];
    }
    return "";
  }

  classOtherDetailChange = (
    e: React.ChangeEvent<InputType>,
    param: string,
    agentId?: number
  ) => {
    if (agentId !== undefined) {
      this.setState({
        ...this.state,
        classData: {
          ...this.state.classData,
          classOtherDetails: {
            ...this.state.classData.classOtherDetails,
            agents: this.state.classData.classOtherDetails.agents.map(
              (list, index) => {
                if (index === agentId) {
                  return {
                    ...list,
                    [param]: e.target.value,
                  };
                }
                return list;
              }
            ),
          },
        },
      });
    } else {
      this.setState({
        ...this.state,
        classData: {
          ...this.state.classData,
          classOtherDetails: {
            ...this.state.classData.classOtherDetails,
            [param]: e.target.value,
          },
        },
      });
    }
  };

  setAgentList = (
    newAgentList: IAgentModel[],
  ) => {
    this.setState({
      ...this.state,
      classData: {
        ...this.state.classData,
        classOtherDetails: {
          ...this.state.classData.classOtherDetails,
          agents: newAgentList,
        },
      },
    });
  };

  addAgent = () => {
    const newAgentList = [
      ...this.state.classData.classOtherDetails.agents,
    ];
    newAgentList.push(initialAgentModelData)

    this.setAgentList(newAgentList);
  };

  removeAgent = (index: number) => {
    const newAgentList = this.state.classData.classOtherDetails.agents.filter(
      (_, i) => i !== index
    );

    this.setAgentList(newAgentList);
  };

  handleNext = () => {
    this.handleSteps(true);
  };

  handleSteps = (next: boolean) => {
    const activeStep = next
      ? this.state.classActiveStep + 1
      : this.state.classActiveStep - 1;
    const length = this.state.addEditClassStepList.length;
    if (length > activeStep) {
      this.setState({
        classActiveStep: activeStep,
        addEditClassStepList: this.state.addEditClassStepList.map(
          (list, index) => {
            if (index === this.state.classActiveStep) {
              return {
                ...list,
                isCompleted: true
              };
            }
            return list;
          }
        )
      });
    }
  };

  isEmptyObject = (object: any) => {
    for (let key in object) {
      if (object.hasOwnProperty(key)) {
        return false
      }
    }
    return true
  }

  checkForErrors = () => {
    return new Promise((resolve) => {

      if (!this.isEmptyObject(this.state.fieldErrors) || this.state.generalError !== "") {
        return resolve(true)
      }

      const { classBasicDetails } = this.state.classData

      const { class_termination_date, class_effective_date, location_id, class_name, class_id, division_id, group_id, tax_id } = classBasicDetails

      const errors: { [key: string]: string[] } = {}

      if (!class_name) {
        errors.class_name = ["Class name is required"]
      }
      if (!class_id) {
        errors.class_id = ["Class id is required"]
      }
      if (!location_id) {
        errors.location_id = ["Location name is required"]
      }
      if (!division_id) {
        errors.division_id = ["Division name is required"]
      }
      if (!group_id) {
        errors.group_id = ["Group name is required"]
      }
      if (!tax_id) {
        errors.tax_id = ["Tax ID is required"]
      }

      if (!this.isEmptyObject(errors)) {
        this.setState({
          fieldErrors: errors
        })
        return resolve(true)
      }

      if (class_termination_date && class_effective_date) {
        const termi_date = moment(class_termination_date, "YYYY-MM-DD")
        const beg_date = moment(class_effective_date, "YYYY-MM-DD")

        if (termi_date.isBefore(beg_date)) {
          this.setState({
            generalError: `Termination date can't before the effective date`
          })
          return resolve(true)
        }
      }

      resolve(false)
    })
  }

  removeErrors = () => {
    return new Promise((resolve) => {
      this.setState({
        generalError: ''
      }, () => resolve(true))
    })
  }

  saveAndNext = () => {
    this.handleNext();
  };

  saveAndFinish = async () => {
    await this.removeErrors()
    const stillError = await this.checkForErrors()
    if (stillError) {
      return;
    }

    if (this.state.classActiveStep === 0) {
      return this.createClassBasicDetailAPI();
    }
    if (this.state.classActiveStep === 1) {
      return this.createClassAddressAPI()
    }
  };

  handleClassDialogClose = () => {
    const { classDetails } = this.props;
    const { classData } = this.state;
    const isDataChanged = JSON.stringify(classDetails) !== JSON.stringify(classData);

    if (isDataChanged) {
      this.setState({ isOpenWarningDialog: true });
    } else {
      this.setInitialState();
      this.props.handleClose();
    }
  };

  createBodyFunc = () => {
    const { countryList, physicalStateList, mailingStateList, classCheckStateList, apiClassId } = this.state
    const { classAddressDetails } = this.state.classData
    const {
      physical_state_id,
      physical_country_id,
      mailing_state_id,
      mailing_country_id,
      class_check_country_id,
      class_check_state_id,
    } = classAddressDetails

    const mailingCountryName: string = countryList.find((item: IDropdownModel) => (item.id + "") === mailing_country_id)?.name || ""

    const physicalCountryName: string = countryList.find((item: IDropdownModel) => (item.id + "") === physical_country_id)?.name || ""

    const physicalStateName: string = physicalStateList.find((item: IDropdownModel) => (item.id + "") === physical_state_id)?.name || ""

    const mailingStateName: string = mailingStateList.find((item: IDropdownModel) => (item.id + "") === mailing_state_id)?.name || ""

    const divCheckCountryName: string = countryList.find((item: IDropdownModel) => (item.id + "") === class_check_country_id)?.name || ""

    const divCheckStateName: string = classCheckStateList.find((item: IDropdownModel) => (item.id + "") === class_check_state_id)?.name || ""

    const addressDetails = classAddressDetails

    let dataAddressDetais = this.removeObjProperty("mailing_country_id", addressDetails)

    dataAddressDetais = this.removeObjProperty("physical_country_id", dataAddressDetais)
    dataAddressDetais = this.removeObjProperty("physical_state_id", dataAddressDetais)
    dataAddressDetais = this.removeObjProperty("mailing_state_id", dataAddressDetais)
    dataAddressDetais = this.removeObjProperty("class_check_country_id", dataAddressDetais)
    dataAddressDetais = this.removeObjProperty("class_check_state_id", dataAddressDetais)

    const body: any = {
      class_address: {
        ...dataAddressDetais,
        physical_country: physicalCountryName,
        physical_state: physicalStateName,
        mailing_country: mailingCountryName,
        mailing_state: mailingStateName,
        division_check_country: divCheckCountryName,
        division_check_state: divCheckStateName,
        class_basic_details_id: apiClassId
      }
    }

    return body
  }

  createClassBasicDetailAPI = () => {
    this.setState({
      generalError: "",
      fieldErrors: {}
    });

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.token,
    };

    const httpBody = {
      class_basic_detail: this.state.classData.classBasicDetails
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.requestCreateClassCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createClassAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createClassAddressAPI = () => {

    this.setState({
      generalError: '',
      fieldErrors: {}
    })
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.token,
    }

    const requestMessageAddress = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.requestCreateClassAddressCallId = requestMessageAddress.messageId

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createClassAddressAPIEndPoint
    )

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.createBodyFunc())
    );

    runEngine.sendMessage(requestMessageAddress.id, requestMessageAddress);

  }

  getCountryList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCountryAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStateList = (idCountry: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStateId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStateFromCountryAPiEndPoint}${idCountry}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getGroupList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.token,
    };

    this.getGroupCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGroupAPiEndPoint.replace("{id}", this.props.selectedEmployer[0].emp_id)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getLocationList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.token,
    };

    this.getLocationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getListLocationEndPoint}?employer_id=${this.props.selectedEmployer[0].id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getDivisonList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.token,
    };

    this.getDivisonCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDivisonApiEndPoint}?employer_id=${this.props.selectedEmployer[0].id}&page=1&per_page=300`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    switch (apiRequestCallId) {
      case this.getGroupCallId:
        this.receiveGroupList(message);
        break;
      case this.getLocationCallId:
        this.receiveLocationList(message);
        break;
      case this.getDivisonCallId:
        this.receiveDivisonList(message);
        break;
      case this.requestCreateClassCallId:
        this.receiveCreateClassBasicDetail(message);
        break;
      case this.requestCreateClassAddressCallId:
        this.receiveCreateClassAddress(message);
        break;
      case this.getCountryId:
        this.receiveCountryList(message);
        break;
      case this.getStateId:
        this.receiveStateList(message);
        break;
    }
  }

  receiveCountryList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.countries && responseJson.countries.length > 0) {
      this.setState({ countryList: responseJson.countries.map((item: any) => ({ id: item.countryCode, name: item.CountryName })) });
    }
  };

  receiveStateList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let type: string = ""

    switch (this.stateType) {
      case 'physical_country_id':
        type = 'physicalStateList'
        break;
      case 'mailing_country_id':
        type = 'mailingStateList'
        break;
      case 'class_check_country_id':
        type = 'classCheckStateList'
        break;
    }
    if (responseJson && responseJson.states && responseJson.states.length > 0 && type) {
      this.setState({ ...this.state, [type]: responseJson.states.map((item: any) => ({ id: item.StateCode, name: item.StateName })) });
    }
  };

  receiveCreateClassBasicDetail = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson.data) {
      this.setState({ apiClassId: responseJson.data.id })
      return this.handleNext()
    }
    return this.handleErrorApiResponse(responseJson)
  }

  receiveCreateClassAddress = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson.data) {
      return this.handleNext()
    }
    return this.handleErrorApiResponse(responseJson)
  }

  receiveGroupList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      this.setState({
        groupList: responseJson.data.map((list: any) => ({
          id: list.id,
          name: list.attributes.group_name,
        })),
      });
    }
  };

  receiveLocationList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      let locationList = responseJson.data.map((list: any) => ({
        id: list.id,
        name: list.attributes.location_name,
      }))
      this.setState({
        locationList: locationList,
      });
    }
  };

  receiveDivisonList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      let divisionList = responseJson.data.map((list: any) => ({
        id: list.id,
        name: list.attributes.division_name,
      }))
      this.setState({
        divisionList: divisionList,
      });
    }
  };

  handleErrorApiResponse = (responseJson: any) => {
    if (responseJson.errors) {
      if (responseJson.errors && Array.isArray(responseJson.errors)) {
        if (typeof (responseJson.errors[0]) === 'string') {
          return this.setState({
            generalError: responseJson.errors[0],
          });
        }

        if (responseJson.errors[0]?.token) {
          return this.setState({
            generalError: responseJson.errors[0].token,
          });
        }
      }

      if (typeof responseJson.errors === 'object') {
        return this.setState({
          fieldErrors: responseJson.errors,
        });
      }
    }
  }

  setInitialState = () => {
    this.setState({
      ...this.state,
      classActiveStep: 0,
      classData: initialClassDetailModelData,
      isOpenWarningDialog: false
    });
  };

  handleCloseWarningDialog = (isAgreeToLooseData: boolean) => {
    if (isAgreeToLooseData) {
      this.setInitialState();
      this.props.handleClose();
    }
    this.setState({ isOpenWarningDialog: false });
  };
}
