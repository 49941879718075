import React, { Component } from "react";
import { Dialog, DialogTitle, Typography, DialogContent, Grid, Box, Stepper, Step, StepLabel, IconButton, DialogActions, Button, Paper, InputLabel, TextField, FormControlLabel, Checkbox, Divider, Card, createTheme, ThemeProvider, Input, Select, MenuItem, FormHelperText, DialogContentText } from '@material-ui/core'
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ColorlibStepIcon from "./ColorlibStepIcon.web";

const images = require("../../../blocks/landingpage/src/assets");

interface Props {
    open: boolean;
    handleClose: () => void;
    handleOk: () => void;
}

export const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            main: "#4166f8",
        },

    },
    typography: {
        fontFamily: 'Poppins',
    },
});



export class CloseDialogMessage extends Component<Props> {




    render() {
        return (
            <ThemeProvider theme={defaultMaterialTheme}>
                <Dialog open={this.props.open} className="member-details-cancle_confirmation_dialog"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" className="cancle_confirmation_dialog_text">
                            Are you sure you want to cancel,
                            your changes will not be saved.
                        </DialogContentText>
                    </DialogContent>
                    <Divider />
                    <DialogActions style={{ height: "30%" }}>
                        <Button className="dialog_cancle_button"
                            onClick={this.props.handleClose}
                        >
                            Cancel
                        </Button>
                        <Button className="dialog_ok_button"
                            onClick={this.props.handleOk}
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        )
    }
}

export default CloseDialogMessage