Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "Log in";
exports.labelRememberMe = "Remember me";
exports.labelForgetPassword = "Forgot Password";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.enterCountrycodeLabel = "Please enter country code";
exports.dontHaveAccountLabel = "Don't have an account yet?";
exports.createAccountLabel = "Create an account";
exports.enterMobileLabel = "Please enter Mobile No";
exports.atleatOneIsRequireLabel = "At least one of the email or mobile number is required";
exports.labelSocialLoginLine = "---------Easy Login With---------";
exports.labelTitle = "Log in";
exports.labelTitleLogin = "Login";
exports.labelHeaderLogin = "Please enter your details to Login to your account."
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.errorEmailMessage = "Please enter Email ID";
exports.errorPasswordMessage = "Please enter Password";
exports.emailRegex = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$";
exports.loginSucessMessage = "Logged In Successfully";
// Customizable Area End