import React, { Component } from "react";
import { Box, Tab } from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";

import "./CustomClientTabPanel.web.css";

interface Props {
  tabList: string[];
  children: any;
  isDisplayAllTab?: boolean;
  allTabLabel?: string;
  onChange?: (tabIndex: string) => void;
}

interface S {
  tabIndex: string;
  childrenList: React.ReactNode[];
}

export default class CustomClientTabPanel extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tabIndex: this.props.isDisplayAllTab ? "All" : this.props.tabList[0],
      childrenList: [],
    };
  }

  getUpdatedChildren = () => {
    const newChildrenList: React.ReactNode[] = [];
    React.Children.map(this.props.children, (child) => {
      newChildrenList.push(child);
    });
    this.setState({ childrenList: newChildrenList });
  };

  componentDidMount(): void {
    this.getUpdatedChildren();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (prevProps.children !== this.props.children) {
      this.getUpdatedChildren();
    }
    if (prevState.tabIndex !== this.state.tabIndex && this.props.onChange) {
      this.props.onChange(this.state.tabIndex);
    }
  }

  handleTabIndexChange = (event: React.ChangeEvent<{}>, value: string) => {
    this.setState({
      tabIndex: value,
    });
  };

  render() {
    return (
      <Box className="custom-client-tab-panel-container">
        <TabContext value={this.state.tabIndex}>
          <Box className="menu-selector">
            {this.props.isDisplayAllTab && (
              <>
                <TabList onChange={this.handleTabIndexChange}>
                  <Tab
                    label={this.props.allTabLabel}
                    value="All"
                    className="all-claims-tab"
                  />
                </TabList>
              </>
            )}
            <TabList
              onChange={this.handleTabIndexChange}
              variant="scrollable"
              className="tab-list"
            >
              {this.props.tabList.map((tab) => (
                <Tab value={tab} key={tab} label={tab} />
              ))}
            </TabList>
          </Box>
          {this.props.isDisplayAllTab && (
            <TabPanel value="All">{this.state.childrenList[0]}</TabPanel>
          )}
          {this.props.tabList.map((tab, index) => (
            <TabPanel value={tab} key={`${tab}children`}>
              {
                this.state.childrenList[
                  this.props.isDisplayAllTab ? index + 1 : index
                ]
              }
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    );
  }
}
