import React, { Component } from "react";
import { Typography } from "@material-ui/core";

import "./GenericTextAreaBox.web.css";

interface Props {
  label: string;
  value: string;
  onChange: (e: any) => void;
  className?: string;
  rows?: number;
  error?: string;
}

interface S {}

export default class GenericTextAreaBox extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className={`generic-input-box ${this.props.className}`}>
        <label htmlFor="text-input" className="input-box-label">
          {this.props.label}
        </label>
        <textarea
          id="text-input"
          rows={this.props.rows || 4}
          className={`input-box ${this.props.error && "error"}`}
          value={this.props.value}
          onChange={this.props.onChange}
        />
        {this.props.error && (
          <Typography
            variant="subtitle1"
            component="div"
            className="text-info-red"
            data-test-id="text-box-error"
          >
            {this.props.error}
          </Typography>
        )}
      </div>
    );
  }
}
