import React from "react";
import {
  Grid,
  Button,
  Menu,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";

import "./EmployerDetails.web.css";
import EmployerMembersController, { Props } from "./EmployerMembersController";
import GenericSearch from "../../../components/src/GenericSearch.web";
import CustomerTable from "../../../components/src/CustomTable.web";
import GenericCloseDialog from "../../../components/src/GenericCloseDialog.web";
import {
  blackEditIcon,
  companyIcon,
  deleteIcon,
  filterIcon,
  searchIcon,
  claimIcon,
} from "./assets";
import GenericCompanyDetailsCard from "../../../components/src/GenericCompanyDetailsCard.web";
import GenericDialog from "../../../components/src/GenericDialog.web";
import GenericButton from "../../../components/src/GenericButton.web";
import GenericSuccessDialog from "../../../components/src/GenericSuccessDialog.web";
import { formValidationMsg } from "./EditEmployerDetailsDialog.web";

export default class EmployerMembers extends EmployerMembersController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const claimsDialogContent = (
      <div className="upload-claims-container">
        <div>
          <div className="text-input-label">Claim Type</div>
          <FormControl variant="outlined" fullWidth>
            <Select
              fullWidth
              IconComponent={(dropdownProps) => (
                <KeyboardArrowDownRoundedIcon {...dropdownProps} />
              )}
              className="edit-emp-dropdown edit-division-dropdown"
              placeholder="Select State / County"
              value={this.state.selectedClaimType}
              onChange={this.handleChangeClaimType}
              data-test-id="claim-type"
            >
              {this.claimList.map((item) => (
                <MenuItem value={item.value} key={item.name + " claim-type"}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {formValidationMsg(this.state.claimTypeValidationMessage)}
        </div>
        <div>
          <input
            accept="image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            type="file"
            id="claim-file-upload-input"
            style={{ display: "none" }}
            onChange={this.handleChangeClaimFile}
            data-test-id="claim-file"
          />
          <label htmlFor="claim-file-upload-input">
            <div
              className={`claim-file-upload-field ${
                this.state.selectedClaimFile ? " file-added" : ""
              }`}
            >
              {this.state.selectedClaimFileName || "Choose a File"}
            </div>
          </label>
          {formValidationMsg(this.state.claimFileValidationMessage)}
        </div>
      </div>
    );

    const claimsDialogActions = (
      <>
        <Button
          className="upload-claim-cancel-btn"
          variant="outlined"
          onClick={this.onCloseClaimsDialog}
          data-test-id="cancel-claim"
        >
          Cancel
        </Button>
        <GenericButton
          variant="contained"
          color="primary"
          onClick={this.onSaveClaimsData}
          data-test-id="submit-claim"
        >
          Submit Claim
        </GenericButton>
      </>
    );
    return (
      <div data-test-id="employer-member-wrapper">
        <GenericCompanyDetailsCard
          companyName="Infotech pvt ltd"
          companyIcon={companyIcon}
          companyAddress="8 Moretown, Mountain Road"
        />
        <Grid
          container
          className="emp-documents-container emp-divisions-container emp-member-container"
        >
          <Grid item xs={12} className="search-bar-and-add-btn">
            <GenericSearch
              searchIcon={searchIcon}
              placeholder="Search for Employee"
              value={this.state.memberSearchString}
              onChange={this.onSearchMemberChange}
              data-test-id="member-search-input"
            />
            <div className="add-document-btn-div">
              <div className="filter-divisions-btn-container">
                <Button>
                  <img src={filterIcon} height={48} />
                </Button>
              </div>
              <Button
                className="add-document-btn add-member-btn"
                data-test-id="add-member-btn"
              >
                + Add Members
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} className="hr mt-30" />
          <Grid item xs={12} className="member-table-container">
            <CustomerTable
              columns={this.memberListColumns}
              rowData={this.state.memberRowItems}
              onActionClick={()=>{}}
            />
          </Grid>
        </Grid>
        <Menu
          id="member-table-menu"
          anchorEl={this.state.membersTableMenuAnchorEl}
          keepMounted
          open={Boolean(this.state.membersTableMenuAnchorEl)}
          className="division-table-menu member-table-menu"
          onClose={this.handleMembersCloseTableMenu}
        >
          <MenuItem
            className="division-table-menu-item"
            onClick={this.handleMembersCloseTableMenu}
            data-test-id="edit-member-menu"
          >
            <span className="menu-item-img">
              <img height={12} src={blackEditIcon} />
            </span>
            Edit
          </MenuItem>
          <MenuItem
            className="division-table-menu-item delete-action-item"
            onClick={this.handleMembersCloseTableMenu}
          >
            <span className="menu-item-img">
              <img height={12} src={deleteIcon} />
            </span>
            Delete
          </MenuItem>
          <MenuItem
            className="division-table-menu-item submit-action-item"
            onClick={this.onOpenClaimsDialog}
            data-test-id="submit-claim-menu"
          >
            <span className="menu-item-img">
              <img height={12} src={claimIcon} />
            </span>
            Submit Claims
          </MenuItem>
        </Menu>
        {this.state.isOpenClaimSDialog && (
          <GenericDialog
            title="Upload Claim File"
            open={this.state.isOpenClaimSDialog}
            handleClose={this.onCloseClaimsDialog}
            contains={claimsDialogContent}
            actions={claimsDialogActions}
            hideCloseDialog={true}
            maxWidth="sm"
            className="upload-claims-dialog"
            data-test-id="upload-claims-file"
          />
        )}
        <GenericCloseDialog
          open={this.state.isOpenCloseWarningDialog}
          handleClose={this.handleCloseDialogClose}
          handleOk={this.handleCloseDialogOk}
        />
        <GenericSuccessDialog
          open={this.state.isMembersDataSaved}
          handleDone={this.handleCloseMembersConfirmationDialog}
        />
      </div>
    );
  }
}
