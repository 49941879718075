import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { FilterChild } from "../../../components/src/CustomFilterPopup.web";

export const configJSON = require("./config");
export const images = require("./assets");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  searchValue: string;
  pharmacyList: Pharmacy[];
  openContactUsDialog: boolean;
  selectedContactDetails: Pharmacy;
  openFilter: boolean;
  filterChildList: FilterChild[];
}

interface SS {
  id: any;
}

interface Pharmacy {
  key?: string;
  img?: string;
  pharmacyName?: string;
  pharmacyTiming?: string;
  drug?: string;
  address?: string;
  area?: string;
  phone?: string;
  email?: string;
}

const PharmacyList = [
  {
    key: "1",
    img: images.pharmacy1,
    pharmacyName: configJSON.pharmacyName1,
    pharmacyTiming: configJSON.pharmacyTiming1,
    drug: configJSON.pharmacyDrug1,
    address: configJSON.address1,
    area: configJSON.addressArea1,
    phone: "+1-265-265-8461",
    email: "drug1@dummy.com",
  },
  {
    key: "2",
    img: images.pharmacy2,
    pharmacyName: configJSON.pharmacyName2,
    pharmacyTiming: configJSON.pharmacyTiming2,
    drug: configJSON.pharmacyDrug2,
    address: configJSON.address2,
    area: configJSON.addressArea2,
    phone: "+1-265-265-8462",
    email: "drug2@dummy.com",
  },
  {
    key: "3",
    img: images.pharmacy3,
    pharmacyName: configJSON.pharmacyName1,
    pharmacyTiming: configJSON.pharmacyTiming1,
    drug: configJSON.pharmacyDrug3,
    address: configJSON.address3,
    area: configJSON.addressArea3,
    phone: "+1-265-265-8463",
    email: "drug3@dummy.com",
  },
  {
    key: "4",
    img: images.pharmacy4,
    pharmacyName: configJSON.pharmacyName2,
    pharmacyTiming: configJSON.pharmacyTiming2,
    drug: configJSON.pharmacyDrug4,
    address: configJSON.address4,
    area: configJSON.addressArea4,
    phone: "+1-265-265-8464",
    email: "drug4@dummy.com",
  },
];

export default class FindPharmacyController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    const drugList = [...new Set(PharmacyList.map((list) => list.drug))].map(
      (list) => ({
        key: list,
        isChecked: false,
      })
    );

    const areaList = [...new Set(PharmacyList.map((list) => list.area))].map(
      (list) => ({
        key: list,
        isChecked: false,
      })
    );

    this.state = {
      searchValue: "",
      pharmacyList: PharmacyList,
      openContactUsDialog: false,
      selectedContactDetails: {},
      openFilter: false,
      filterChildList: [
        {
          key: "Drug",
          isOpen: true,
          searchString: "",
          viewAll: false,
          list: drugList,
          mainList: drugList,
        },
        {
          key: "Area",
          isOpen: false,
          searchString: "",
          viewAll: false,
          list: areaList,
          mainList: areaList,
        },
      ],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  onSearchInputChange = (e: React.ChangeEvent<any>) => {
    const searchKey = e.target.value;
    this.setState({ searchValue: searchKey });
  };

  handleContactUsClick = (key: string) => {
    this.setState({
      openContactUsDialog: true,
      selectedContactDetails: PharmacyList.filter((l) => l.key === key)[0],
    });
  };

  handleClose = () => {
    this.setState({ openContactUsDialog: false });
  };

  handlePharmacyFilterClick = () => {
    this.setState({ openFilter: !this.state.openFilter });
  };

  handlePharmacyFilterOrSearchOnChange = (newState: FilterChild[]) => {
    this.setState({ filterChildList: newState });
    const filterString = ([] as string[]).concat(
      ...newState.map((n) =>
        n.mainList.filter((l) => l.isChecked).map((l) => l.key)
      )
    );

    const newPharmacyListAfterFilter = PharmacyList.filter((record) => {
      return filterString.length
        ? filterString
            .map(
              (filter) =>
                record.drug.toLowerCase().includes(filter.toLowerCase()) ||
                record.address.toLowerCase().includes(filter.toLowerCase())
            )
            .some((filter) => filter)
        : true;
    });

    const newPharmacyList = newPharmacyListAfterFilter.filter((record) => {
      return (
        record.drug
          ?.toLowerCase()
          .includes(this.state.searchValue.toLowerCase()) ||
        record.address
          ?.toLowerCase()
          .includes(this.state.searchValue.toLowerCase())
      );
    });
    this.setState({ pharmacyList: newPharmacyList });
  };
}
