import React from "react";

import ClientEmployerDivisionController, {
  Props,
  configJSON,
} from "./ClientEmployerDivisionController.web";
import "./ClientEmployerDivision.web.css";
import CustomClientTabPanel from "../../../../components/src/CustomClientTabPanel.web";
import ClientEmployerDivisionTab from "./ClientEmployerDivisionTab/ClientEmployerDivisionTab.web";

export default class ClientEmployerDivision extends ClientEmployerDivisionController {
  constructor(props: Props) {
    super(props);
  }

  
  render() {
    return (
      <>
        <div className="client-employer-division">
          <div
            data-test-id="client-employer-heading"
            className="client-employer-heading mb-20"
          >
            {configJSON.divisionHeading}
          </div>
          <div>
            <CustomClientTabPanel
              tabList={this.tabMenuList}
              isDisplayAllTab
              allTabLabel={configJSON.division}
            >
              {this.props.selectedEmployer.length !== 0 && <ClientEmployerDivisionTab selectedEmployer={this.props.selectedEmployer} />}
              {this.tabMenuList.map((tab) => (
                <>{tab}</>
              ))}
            </CustomClientTabPanel>
          </div>
        </div>
      </>
    );
  }
}
