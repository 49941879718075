import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { toast } from "react-toastify";
const images = require("./assets");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  phone: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  labelHeaderLogin: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  labelTitleLogin: string;
  activeIndex: number;
  emailError: any;
  showEmailError: boolean;
  passwordError: any;
  showPasswordError: boolean;
  logInApi: any;
  isValid: any;
  showPhoneError: boolean;
  apiErrorMsg:string;
  selectedCountryCode: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  labelTitleLogin: string = "";
  private timer: any;
  phoneNumberArray = [
    { countryName: "India", countryCode: "+91", flag: images.india },
    { countryName: "US", countryCode: "+1", flag: images.unitedStates },
    { countryName: "UK", countryCode: "+44", flag: images.unitedKingdom },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.timer = null;
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      phone: "",
      password: "",
      selectedCountryCode: "+1",
      showPhoneError: false,
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      labelHeaderLogin: configJSON.labelHeaderLogin,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      labelTitleLogin: configJSON.labelTitleLogin,
      activeIndex: 0,
      emailError: null,
      showEmailError: false,
      passwordError: null,
      showPasswordError: false,
      logInApi: {},
      isValid: null,
      apiErrorMsg: ""
    };

    this.emailReg = new RegExp(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
    );
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.timer = setInterval(this.nextSlide, 5000);
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling

  async componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  nextSlide = () => {
    const { activeIndex } = this.state;
    this.setState({
      activeIndex: (activeIndex + 1) % 3,
    });
  };


  handleFormikSubmit = (values:{ email:string, country_code:string, mobile_no:string, password:string })=>{
    const { email, country_code, mobile_no, password } = values;
    const validPhone = mobile_no && country_code;
    const isValid = email || validPhone;
    this.setState({
      email: email.replace(/ /g, ""),
      selectedCountryCode: country_code,
      phone: mobile_no,
      password: password,
    });
    if(isValid){
      this.doEmailLogInValidation(email?true:false)
    }
  }

  goToSlide = (index: number) => {
    this.setState({
      activeIndex: index,
    });
  };

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  handlePhoneNoLength = (e:{target:{value:string}},handleChange:(e:{target:{value:string}})=>void) => {
    if(e.target.value.length<=10){
      handleChange(e)
    }
  }

  logInCallID: string = "";
  doEmailLogInValidation = (email: boolean) => {
    const header = {
      "Content-Type": "application/json",
    };
    const EmailPayload = {
      email: `${this.state.email}`,
      password: `${this.state.password}`,
    };
    const mobilePayload = {
      full_phone_number: `${this.state.selectedCountryCode + this.state.phone}`,
      password: `${this.state.password}`,
    };
    const httpBody = {
      data: {
        type: email ? "email_account" : "sms_account",
        attributes: email ? EmailPayload : mobilePayload,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.logInCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_login/login"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.logInCallID;
  };

  setEmail = (text: any) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    this.setState({ isValid: emailRegex.test(text) });
    this.setState({ email: text, showEmailError: false });
  };

  goToForgetPasswordblock = (): any => {
    this.props.navigation.navigate("ForgotPasswordBlock");
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
      showPasswordError: false,
    });
  };

  setLoginCallID = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ logInApi: responseJson });
      toast.success(configJSON.loginSucessMessage);
      localStorage.setItem("user",JSON.stringify(responseJson.meta));
      this.props.navigation.history.push("/home/ClientLandingPage");
    }
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  setErrorOfOneFieldRequire = (values:{mobile_no:string,email:string,country_code:string},touchedEmail:boolean|undefined,touchedMobile:boolean|undefined,touchedCountry:boolean|undefined) => {
    const touched = !!(touchedEmail || touchedMobile || touchedCountry);
    const ErrorValidation = !values.mobile_no && !values.country_code && !values.email && touched;
    return ErrorValidation
  }

  countryValidationError = (firstValue:boolean,secoundValue:boolean,thirdValue:boolean ,fourValue:boolean) => {
    return (firstValue && secoundValue && thirdValue && fourValue)
  }

  mobileValidationError = (firstValue:boolean,secoundValue:boolean,thirdValue:boolean,fourValue:boolean,fifthValue:boolean) => {
    return (firstValue && secoundValue && thirdValue && fourValue && fifthValue)
  }

  goToRegistration = () => {
    this.props.navigation.navigate("EmailAccountRegistrationBlock");
  };

  errorHandling = (error:string|undefined,touched:boolean|undefined) =>{
    return !!(error&&touched)
  }

  getInputEmailClassName = (emailError:boolean, oneField:boolean) => {
    return `input-boxes ${emailError || oneField ? 'error-border' : ''}`;
  }

  apiCallData = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiEmailLoginCallId) {
      this.apiCall(responseJson);
    }
    if (apiRequestCallId === this.logInCallID) {
      this.setLoginCallID(responseJson);
    }
  };

  apiCall = (responseJson: any) => {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      this.saveLoggedInUserData(responseJson);
      this.sendLoginSuccessMessage();
      this.openInfoPage();
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
      this.sendLoginFailMessage();
    }
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        this.apiCallData(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.setState({apiErrorMsg:responseJson.errors[0].error});
        this.setState({
          emailError:
            responseJson.errors[0].account_not_activated ||
            responseJson.errors[0].account_not_found,
          showEmailError: !!(
            responseJson.errors[0].account_not_activated ||
            responseJson.errors[0].account_not_found
          ),
          passwordError: responseJson.errors[0].failed_login,
          showPasswordError: !!responseJson.errors[0].failed_login,
        });
      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
