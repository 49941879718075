import React from "react";
import { ThemeProvider } from "@material-ui/styles";

import { Grid, Divider } from "@material-ui/core";
import GenericCloseDialog from "../../../../components/src/GenericCloseDialog.web";
import { defaultMaterialTheme } from "../../../dashboard/src/EditEmployerDetailsDialog.web";
import GenericDialogFormWrapper from "../../../../components/src/GenericDialogFormWrapper.web";
import EditClientEmpFormDialogController, {
  Props
} from "./EditClientEmpFormDialogController";
import GenericAddressComponent from "../../../../components/src/GenericeAddressFields.web";
import GenericCheckBoxField from "../../../../components/src/GenericCheckBoxField.web";
import GenericTextField from "../../../../components/src/GenericTextField.web";
import GenericDateField from "../../../../components/src/GenericDateField.web";
import GenericSelectField from "../../../../components/src/GenericSelectField.web";
import GenericSuccessDialog from "../../../../components/src/GenericSuccessDialog.web";
import GenericContactDetailsFields from "../../../../components/src/GenericContactDetailsFields.web";
import { initialEMPAgentData } from "./ClientEmployerModel";
import GenericAddAgentComponent from "../../../../components/src/GenericAddAgentComponent.web";

export default class EditClientEmpFormDialog extends EditClientEmpFormDialogController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      employerActiveStep,
      isOpenWarningDialog,
      editEmployerFormStepList,
      payrollCycleList,
      payrollDayList,
      payrollMonthList,
      basicDetailsCompleated
    } = this.state;
    const {
      employerBasicDetails,
      employerOtherDetails
    } = this.state.employerData;

    const clientEmployerFormContent = (
      <div>
        {employerActiveStep === 0 && (
          <Grid
            container
            spacing={3}
            data-test-id="employer-step-1-wrapper"
            className="employer-step-1-wrapper"
          >
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Employer Name"
                value={employerBasicDetails.employer_name}
                onChange={e =>
                  this.handleEmployerInputItemChange(e, "employer_name")
                }
                data-test-id="employer_name"
                isRequired={true}
                error={this.getEmpErrorMessage("employer_name")}
                disabled={basicDetailsCompleated}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Employer ID"
                value={employerBasicDetails.emp_id}
                onChange={e => this.handleEmployerInputItemChange(e, "emp_id")}
                data-test-id="emp_id"
                isRequired={true}
                error={this.getEmpErrorMessage("emp_id")}
                disabled={basicDetailsCompleated}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Client Name"
                value={employerBasicDetails.client_name}
                onChange={e =>
                  this.handleEmployerInputItemChange(e, "client_name")
                }
                data-test-id="client_name"
                disabled={true}
                isRequired={true}
                error={this.getEmpErrorMessage("client_name")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Effective Date"
                value={employerBasicDetails.effective_date}
                onChange={date =>
                  this.handleEmployerInputItemChange(date, "effective_date")
                }
                data-test-id="effective_date"
                error={this.getEmpErrorMessage("effective_date")}
                disabled={basicDetailsCompleated}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label="Termination Date"
                value={employerBasicDetails.termination_date}
                onChange={date =>
                  this.handleEmployerInputItemChange(date, "termination_date")
                }
                data-test-id="termination_date"
                disabled={basicDetailsCompleated}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={this.state.employerData.employerBasicDetails.is_active}
                label="Is Active"
                data-test-id="is_active"
                disabled={basicDetailsCompleated}
                onChange={(event: React.ChangeEvent<any>) =>
                  this.handleEmpIsActiveCheckbox(event)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="First Payroll Day"
                selectionList={payrollDayList}
                disabled={basicDetailsCompleated}
                value={employerBasicDetails.payroll_day}
                onChange={e =>
                  this.handleEmployerInputItemChange(e, "payroll_day")
                }
                data-test-id="payroll_day"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="First Payroll Month"
                selectionList={payrollMonthList}
                value={employerBasicDetails.payroll_month}
                disabled={basicDetailsCompleated}
                onChange={e =>
                  this.handleEmployerInputItemChange(e, "payroll_month")
                }
                data-test-id="payroll_month"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Payroll Cycle 1 (Default)"
                selectionList={payrollCycleList}
                value={employerBasicDetails.payroll_cycle1}
                onChange={e =>
                  this.handleEmployerInputItemChange(e, "payroll_cycle1")
                }
                data-test-id="payroll_cycle1"
                disabled={basicDetailsCompleated}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Payroll Cycle 2"
                selectionList={payrollCycleList}
                value={employerBasicDetails.payroll_cycle2}
                onChange={e =>
                  this.handleEmployerInputItemChange(e, "payroll_cycle2")
                }
                data-test-id="payroll_cycle2"
                disabled={basicDetailsCompleated}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Payroll Cycle 3"
                selectionList={payrollCycleList}
                value={employerBasicDetails.payroll_cycle3}
                onChange={e =>
                  this.handleEmployerInputItemChange(e, "payroll_cycle3")
                }
                data-test-id="payroll_cycle3"
                disabled={basicDetailsCompleated}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Payroll Cycle 4"
                selectionList={payrollCycleList}
                value={employerBasicDetails.payroll_cycle4}
                onChange={e =>
                  this.handleEmployerInputItemChange(e, "payroll_cycle4")
                }
                data-test-id="payroll_cycle4"
                disabled={basicDetailsCompleated}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Tax ID"
                value={employerBasicDetails.tax_id}
                onChange={e => this.handleEmployerInputItemChange(e, "tax_id")}
                data-test-id="tax_id"
                isTaxId={true}
                isRequired={true}
                error={this.getEmpErrorMessage("tax_id")}
                disabled={basicDetailsCompleated}
              />
            </Grid>
          </Grid>
        )}
        {employerActiveStep === 1 && (
          <Grid
            container
            spacing={3}
            className="client-member-step-2-wrapper"
            data-test-id="step-2-wrapper"
          >
            <Grid item xs={12} sm={12} md={12}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{color: "#757a8c",fontSize:"22px",fontWeight: 500, fontFamily:'"Montserrat", sans-serif'}}
              >Physical Address</Grid>
            </Grid>
            <GenericAddressComponent
              addressLine1Error={this.getEmpErrorMessage(
                "physical_address_line1"
              )}
              addressLine2Error={this.getEmpErrorMessage(
                "physical_address_line2"
              )}
              cityError={this.getEmpErrorMessage("physical_address.city")}
              stateError={this.getEmpErrorMessage("physical_address.state")}
              zipCodeError={this.getEmpErrorMessage(
                "physical_address.zip_code"
              )}
              countryError={this.getEmpErrorMessage("physical_address.country")}
              type="physical"
              addressValues={this.state.employerData.employerAddressDetails}
              handleAddressChange={this.handleEmpAddressItemChange}
              countryList={this.state.countryList}
              stateList={this.state.stateList}
            />
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="emp-edit-address-header"
            >
              <div className="member-mailing-address-flex-wrapper">
              <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{color: "#757a8c",fontSize:"22px",fontWeight: 500, fontFamily:'"Montserrat", sans-serif'}}>
                Mailing Address</Grid>
                <div>
                  <GenericCheckBoxField
                    checked={this.state.isMailingAddressSameAsPhysicalAddress}
                    label="Mailing address same as physical address"
                    onChange={(event: React.ChangeEvent<any>) =>
                      this.handleEmpMailingAddressCheckbox(event)
                    }
                  />
                </div>
              </div>
            </Grid>
            <GenericAddressComponent
              type="mailing"
              addressValues={this.state.employerData.employerAddressDetails}
              handleAddressChange={this.handleEmpAddressItemChange}
              countryList={this.state.countryList}
              stateList={this.state.mailingStateList}
              disabled={!!this.state.isMailingAddressSameAsPhysicalAddress}
              addressLine1Error={this.getEmpErrorMessage(
                "mailing_address_line1"
              )}
              addressLine2Error={this.getEmpErrorMessage(
                "mailing_address_line2"
              )}
              cityError={this.getEmpErrorMessage("mailing_address.city")}
              stateError={this.getEmpErrorMessage("mailing_address.state")}
              zipCodeError={this.getEmpErrorMessage("mailing_address.zip_code")}
              countryError={this.getEmpErrorMessage("mailing_address.country")}
            />
            <Grid item xs={12}>
              <Divider style={{ backgroundColor: "#c2cfe1" }} />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <GenericContactDetailsFields
                fieldsArray={[
                  {
                    label: "Contact 1",
                    key: "mailing_contact1",
                    value: this.state.employerData.employerAddressDetails
                      .mailing_contact1,
                    error: this.getEmpErrorMessage("contacts.contact")
                  },
                  {
                    label: "Email 1",
                    key: "mailing_email1",
                    value: this.state.employerData.employerAddressDetails
                      .mailing_email1,
                    error: this.getEmpErrorMessage("contacts.email1")
                  },
                  {
                    label: "Phone Number 1",
                    key: "mailing_phone_number1",
                    value: this.state.employerData.employerAddressDetails
                      .mailing_phone_number1,
                    type: "number",
                    onlyNumber: true,
                    error: this.getEmpErrorMessage("contacts.phone_number")
                  },
                  {
                    label: "Fax 1",
                    key: "mailing_fax1",
                    value: this.state.employerData.employerAddressDetails
                      .mailing_fax1,
                    type: "number",
                    error: this.getEmpErrorMessage("contacts.fax")
                  }
                ]}
                handleInputChange={this.handleEmpAddressItemChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericContactDetailsFields
                fieldsArray={[
                  {
                    label: "Contact 2",
                    key: "mailing_contact2",
                    value: this.state.employerData.employerAddressDetails
                      .mailing_contact2,
                    error: this.getEmpErrorMessage("contacts.contact")
                  },
                  {
                    label: "Email 2",
                    key: "mailing_email2",
                    value: this.state.employerData.employerAddressDetails
                      .mailing_email2,
                    error: this.getEmpErrorMessage("contacts.email2")
                  },
                  {
                    label: "Phone Number 2",
                    key: "mailing_phone_number2",
                    value: this.state.employerData.employerAddressDetails
                      .mailing_phone_number2,
                    type: "number",
                    onlyNumber: true,
                    error: this.getEmpErrorMessage("contacts.phone_number")
                  },
                  {
                    label: "Fax 2",
                    key: "mailing_fax2",
                    value: this.state.employerData.employerAddressDetails
                      .mailing_fax2,
                    type: "number",
                    error: this.getEmpErrorMessage("contacts.fax")
                  }
                ]}
                handleInputChange={this.handleEmpAddressItemChange}
              />
            </Grid>
          </Grid>
        )}
        {employerActiveStep === 2 && (
          <Grid container spacing={3} data-test-id="step-3-wrapper" />
        )}
        {employerActiveStep === 3 && (
          <Grid container spacing={3} data-test-id="employer-step-4-wrapper">
            <GenericAddAgentComponent
              addAgent={this.addEMPAgent}
              agentList={employerOtherDetails.agentsDetails}
              agentListError={[initialEMPAgentData]}
              handleInputChange={this.handleEmployerOtherDetailsInputItemChange}
              removeAgent={this.removeAgent}
            />
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Cost Containment Fee"
                value={employerOtherDetails.cost_containment_fee}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "cost_containment_fee"
                  )
                }
                type="number"
                data-test-id="cost_containment_fee"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Employer Admin Fee"
                value={employerOtherDetails.employer_admin_fee}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "employer_admin_fee"
                  )
                }
                type="number"
                data-test-id="employer_admin_fee"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Employer PPO Fee"
                value={employerOtherDetails.employer_ppo_fee}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "employer_ppo_fee"
                  )
                }
                type="number"
                data-test-id="employer_ppo_fee"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Employer Cobra Fee"
                value={employerOtherDetails.employer_cobra_fee}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "employer_cobra_fee"
                  )
                }
                type="number"
                data-test-id="employer_cobra_fee"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Employer HIPPA Fee"
                value={employerOtherDetails.employer_hipaa_fee}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "employer_hipaa_fee"
                  )
                }
                type="number"
                data-test-id="employer_hipaa_fee"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Employer Agg Premium Fee"
                value={employerOtherDetails.employer_agg_premium_fee}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "employer_agg_premium_fee"
                  )
                }
                type="number"
                data-test-id="employer_agg_premium_fee"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Employer FSA Fee"
                value={employerOtherDetails.employer_fsa_fee}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "employer_fsa_fee"
                  )
                }
                type="number"
                data-test-id="employer_fsa_fee"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Employer HRA Fee"
                value={employerOtherDetails.employer_hra_fee}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "employer_hra_fee"
                  )
                }
                type="number"
                data-test-id="employer_hra_fee"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Employer HSA Fee"
                value={employerOtherDetails.employer_hsa_fee}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "employer_hsa_fee"
                  )
                }
                type="number"
                data-test-id="employer_hsa_fee"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Employer Fee 1"
                value={employerOtherDetails.employer_fee1}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "employer_fee1"
                  )
                }
                type="number"
                data-test-id="employer_fee1"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Employer Fee 2"
                value={employerOtherDetails.employer_fee2}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "employer_fee2"
                  )
                }
                type="number"
                data-test-id="employer_fee2"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Employer Fee 3"
                value={employerOtherDetails.employer_fee3}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "employer_fee3"
                  )
                }
                type="number"
                data-test-id="employer_fee3"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Employer Fee 4"
                value={employerOtherDetails.employer_fee4}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "employer_fee4"
                  )
                }
                type="number"
                data-test-id="employer_fee4"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Med EE Spec Premium"
                value={employerOtherDetails.med_ee_spec_premium}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "med_ee_spec_premium"
                  )
                }
                type="number"
                data-test-id="med_ee_spec_premium"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Den EE Spec Premium"
                value={employerOtherDetails.den_ee_spec_premium}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "den_ee_spec_premium"
                  )
                }
                type="number"
                data-test-id="den_ee_spec_premium"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Vis EE Spec Premium"
                value={employerOtherDetails.vis_ee_spec_premium}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "vis_ee_spec_premium"
                  )
                }
                type="number"
                data-test-id="vis_ee_spec_premium"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Med EE + SP Spec Premium"
                value={employerOtherDetails.med_ee_plus_sp_spec_premium}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "med_ee_plus_sp_spec_premium"
                  )
                }
                type="number"
                data-test-id="med_ee_plus_sp_spec_premium"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Den EE + SP Spec Premium"
                value={employerOtherDetails.den_ee_plus_sp_spec_premium}
                type="number"
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "den_ee_plus_sp_spec_premium"
                  )
                }
                data-test-id="den_ee_plus_sp_spec_premium"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Vis EE + SP Spec Premium"
                value={employerOtherDetails.vis_ee_plus_sp_spec_premium}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "vis_ee_plus_sp_spec_premium"
                  )
                }
                type="number"
                data-test-id="vis_ee_plus_sp_spec_premium"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Med EE + CH Spec Premium"
                value={employerOtherDetails.med_ee_plus_ch_spec_premium}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "med_ee_plus_ch_spec_premium"
                  )
                }
                type="number"
                data-test-id="med_ee_plus_ch_spec_premium"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Den EE + CH Spec Premium"
                value={employerOtherDetails.den_ee_plus_ch_spec_premium}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "den_ee_plus_ch_spec_premium"
                  )
                }
                type="number"
                data-test-id="den_ee_plus_ch_spec_premium"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Vis EE + CH Spec Premium"
                value={employerOtherDetails.vis_ee_plus_ch_spec_premium}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "vis_ee_plus_ch_spec_premium"
                  )
                }
                type="number"
                data-test-id="vis_ee_plus_ch_spec_premium"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Med EE + CHS Spec Premium"
                value={employerOtherDetails.med_ee_plus_chs_spec_premium}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "med_ee_plus_chs_spec_premium"
                  )
                }
                type="number"
                data-test-id="med_ee_plus_chs_spec_premium"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Den EE + CHS Spec Premium"
                value={employerOtherDetails.den_ee_plus_chs_spec_premium}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "den_ee_plus_chs_spec_premium"
                  )
                }
                type="number"
                data-test-id="den_ee_plus_chs_spec_premium"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Vis EE + CHS Spec Premium"
                value={employerOtherDetails.vis_ee_plus_chs_spec_premium}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "vis_ee_plus_chs_spec_premium"
                  )
                }
                type="number"
                data-test-id="vis_ee_plus_chs_spec_premium"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Med EE FAM Spec Premium"
                value={employerOtherDetails.med_ee_fam_spec_premium}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "med_ee_fam_spec_premium"
                  )
                }
                type="number"
                data-test-id="med_ee_fam_spec_premium"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Den EE FAM Spec Premium"
                value={employerOtherDetails.den_ee_fam_spec_premium}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "den_ee_fam_spec_premium"
                  )
                }
                type="number"
                data-test-id="den_ee_fam_spec_premium"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Vis EE FAM Spec Premium"
                value={employerOtherDetails.vis_ee_fam_spec_premium}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "vis_ee_fam_spec_premium"
                  )
                }
                type="number"
                data-test-id="vis_ee_fam_spec_premium"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Specific Deductible"
                value={employerOtherDetails.specific_deductible}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "specific_deductible"
                  )
                }
                type="number"
                data-test-id="specific_deductible"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Aggregate Deductible"
                value={employerOtherDetails.aggregate_deductible}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "aggregate_deductible"
                  )
                }
                type="number"
                data-test-id="aggregate_deductible"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Surcharge %"
                value={employerOtherDetails.surcharge_percentage}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "surcharge_percentage"
                  )
                }
                type="number"
                data-test-id="surcharge_percentage"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Employer Waiting Period"
                value={employerOtherDetails.employer_waiting_period}
                onChange={e =>
                  this.handleEmployerOtherDetailsInputItemChange(
                    e,
                    "employer_waiting_period"
                  )
                }
                type="number"
                data-test-id="employer_waiting_period"
              />
            </Grid>
          </Grid>
        )}
        {this.state.isEmployerCreated && (
          <GenericSuccessDialog
            open={this.state.isEmployerCreated}
            handleDone={this.handleSuccessDialogClose}
            contains={this.handleSecoundDialogContant(this.state.isEmployerAdded)}
          />
        )}
      </div>
    );

    return (
      <ThemeProvider theme={defaultMaterialTheme}>
        <GenericDialogFormWrapper
          open={this.props.isOpen}
          title={this.props.isEdit ? "Edit Employer" : "Add New Employer"}
          handleClose={this.handleMemberDialogClose}
          stepList={editEmployerFormStepList}
          activeStep={employerActiveStep}
          saveAndNext={this.saveAndNext}
          saveAndFinish={this.saveAndFinish}
          formContent={clientEmployerFormContent}
          disableOnClick={!editEmployerFormStepList[0].isCompleted}
          setActiveStep={this.setActiveStep}
          handlePrevious={() => {
            this.handleSteps(false);
          }}
          disableSaveAndNext={this.state.basicDetailsCompleated && this.state.employerActiveStep===0}
          buttonTitle={this.props.isEdit ? "Save Changes" : "Add Employer"}
        />
        <GenericCloseDialog
          open={isOpenWarningDialog}
          handleClose={() => this.handleCloseWarningDialog(false)}
          handleOk={() => this.handleCloseWarningDialog(true)}
        />
      </ThemeProvider>
    );
  }
}
