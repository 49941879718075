import React from "react";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { StepList } from "../../../../../components/src/CustomVerticalFormStepper.web";
import {
  IDivisionAddressDetailModel,
  IDivisionBasicDetailModel,
  IDivisionDetailModel,
  IDivisionOtherDetailModel,
  IDropdownModel,
  initialAgentModelData,
  initialDivisionDetailModelData,
} from "../ClientEmployerDivisionModel";
import { IAgentModel } from "../../../../../components/src/GenericAddAgentComponent.web";
import { SelectedEmployerInterface } from "../../AllRoleLandingPageController.web";
import moment from 'moment';
export const configJSON = require("./../../config");

export interface Props {
  isOpen: boolean;
  isEdit: boolean;
  handleClose: any;
  divisionDetails: IDivisionDetailModel;
  selectedEmployer: SelectedEmployerInterface[],
}

interface S {
  apiDevisionId: string;
  apiDivisionAddressId: string
  isDivisionCreated: boolean;
  divisionActiveStep: number;

  isOpenWarningDialog: boolean;
  addEditDivisionStepList: StepList[];
  divisionData: IDivisionDetailModel;
  fieldErrors: { [key: string]: string[] };
  generalError: string;

  groupList: IDropdownModel[];
  countryList: IDropdownModel[];
  statePhysicalList: IDropdownModel[];
  stateMailingList: IDropdownModel[];
  stateDivCheckList: IDropdownModel[];

  isMailingAddressSameAsPhysicalAddress: boolean;
  isDivisionCheckAddressSameAsPhysicalAddress: boolean;
  isDivisionCheckAddressSameAsMailingAddress: boolean;
  isBasicDetailComplete: boolean;
}

interface SS { }

type InputType = HTMLInputElement | HTMLTextAreaElement | { value: unknown };

export default class AddEditClientEmployerDivisionDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  token: string = "";
  clien_name: string = "";
  currentStateType: string = "";
  getGroupCallId: string = "";
  getCountryCallId: string = "";
  getStateCallId: string = "";
  requestCreateDivisionOtherDetailCallId: string = "";
  requestCreateDivisionAddressCallId: string = "";
  requestCreateDivisionCallId: string = "";
  requestUpdateDivisionCallId: string = "";
  requestUpdateDivisionAddressCallId: string = ""
  requiredFieldStep1: { name: keyof IDivisionBasicDetailModel, message: string }[] = [
    {
      name: 'division_name',
      message: 'Division name is required'
    },
    {
      name: 'division_id',
      message: 'Division id is required'
    },
    {
      name: 'group_id',
      message: 'Group name is required'
    },
    {
      name: 'tax_id',
      message: 'Tax ID is required'
    }
  ]
  requestPhysicalStateCallId: string = "";
  requestMailingStateCallId: string = "";
  requestDivCheckStateCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      divisionActiveStep: 0,
      addEditDivisionStepList: [
        {
          key: 1,
          title: "Division Basic Details",
          description: "Type and Select details",
          isCompleted: false,
        },
        {
          key: 2,
          title: "Address & Contact",
          description: "Type and Select details",
          isCompleted: false,
        },
        {
          key: 3,
          title: "Upload Logo / Supporting Document",
          description: "Type and Select details",
          isCompleted: false,
        },
        {
          key: 4,
          title: "Other Details",
          description: "Type and Select details",
          isCompleted: false,
        },
      ],
      apiDevisionId: '',
      apiDivisionAddressId: '',
      divisionData: initialDivisionDetailModelData,

      fieldErrors: {},
      generalError: '',

      groupList: [],
      countryList: [],
      statePhysicalList: [],
      stateMailingList: [],
      stateDivCheckList: [],

      isDivisionCreated: false,
      isOpenWarningDialog: false,
      isMailingAddressSameAsPhysicalAddress: false,
      isDivisionCheckAddressSameAsPhysicalAddress: false,
      isDivisionCheckAddressSameAsMailingAddress: false,

      isBasicDetailComplete: false,
    };
  }

  async componentDidMount() {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const response = JSON.parse(loggedInUser);
      this.token = response.token;
      this.clien_name = response.client_name
      if (!this.props.isEdit) {
        this.updateClientName(response.client_name)
      }
    }
    this.getGroupList();
    this.getCountryList();
  }

  updateClientName(client_name: string) {
    this.setState({
      divisionData: {
        ...this.state.divisionData,
        divisionBasicDetails: {
          ...this.state.divisionData.divisionBasicDetails,
          employer_name: this.props.selectedEmployer[0].employer_name,
          employer_id: this.props.selectedEmployer[0].emp_id,
          client_name,
        }
      }
    })
  }

  removeProperty = (propKey: keyof IDivisionBasicDetailModel | keyof IDivisionAddressDetailModel | keyof IDivisionOtherDetailModel, { [propKey]: propValue, ...rest }) => rest

  handleDivisionInputChange = (
    e: React.ChangeEvent<InputType>,
    param: keyof IDivisionBasicDetailModel
  ) => {
    const index = this.requiredFieldStep1.findIndex(item => item.name === param)
    if (index !== -1) {
      if (e.target.value) {
        this.setState({
          fieldErrors: this.removeProperty(param, this.state.fieldErrors)
        })
      } else {
        this.setState({
          fieldErrors: {
            ...this.state.fieldErrors,
            [param]: [this.requiredFieldStep1[index].message]
          }
        })
      }
    }

    this.setState({
      divisionData: {
        ...this.state.divisionData,
        divisionBasicDetails: {
          ...this.state.divisionData.divisionBasicDetails,
          [param]: e.target.value,
        },
      },
    });
  };

  handleDivisionAddressItemChange = (
    e: React.ChangeEvent<InputType>,
    param: string
  ) => {
    const eventValue = e.target.value;
    let fieldName = ""
    if (param.includes("country")) {
      this.currentStateType = param
      this.getStateList(eventValue + "")

      switch (param) {
        case 'physical_country_id':
          fieldName = 'physical_state_id'
          break;
        case 'mailing_country_id':
          fieldName = 'mailing_state_id';
          break;
        case 'division_check_country_id':
          fieldName = 'division_check_state_id';
          break;

      }
      this.setState({
        divisionData: {
          ...this.state.divisionData,
          divisionAddressDetails: {
            ...this.state.divisionData.divisionAddressDetails,
          }
        }
      })
    }
    if (fieldName) {
      this.setState({
        ...this.state,
        divisionData: {
          ...this.state.divisionData,
          divisionAddressDetails: {
            ...this.state.divisionData.divisionAddressDetails,
            [param]: eventValue,
            [fieldName]: -1
          },
        },
      });
    } else {
      this.setState({
        ...this.state,
        divisionData: {
          ...this.state.divisionData,
          divisionAddressDetails: {
            ...this.state.divisionData.divisionAddressDetails,
            [param]: eventValue,
          },
        },
      });
    }
  };

  handleDivisionContactItemChange = (
    e: React.ChangeEvent<InputType>,
    param: string
  ) => {
    const eventValue = e.target.value;

    if (param === "division_check_email1" || param === "division_check_email2") {
      const regexEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
      const val: any = eventValue
      const isValid = regexEmail.test(val)

      if (isValid || !eventValue) {
        this.setState({
          fieldErrors: this.removeProperty(param, this.state.fieldErrors)
        })
      } else {
        this.setState({
          fieldErrors: {
            ...this.state.fieldErrors,
            [param]: ["Email invalid"]
          }
        })
      }
    }

    this.setState({
      divisionData: {
        ...this.state.divisionData,
        divisionAddressDetails: {
          ...this.state.divisionData.divisionAddressDetails,
          [param]: eventValue,
        },
      },
    });
  };

  handleMailingAddressCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    const {
      physical_address_line1,
      physical_address_line2,
      physical_city,
      physical_country_id,
      physical_state_id,
      physical_zip_code,
    } = this.state.divisionData.divisionAddressDetails;

    const {
      statePhysicalList,
    } = this.state

    this.setState({
      isMailingAddressSameAsPhysicalAddress: isChecked,
      stateMailingList: isChecked ? statePhysicalList : [],
      divisionData: {
        ...this.state.divisionData,
        divisionAddressDetails: {
          ...this.state.divisionData.divisionAddressDetails,
          mailing_address_line1: isChecked ? physical_address_line1 : '',
          mailing_address_line2: isChecked ? physical_address_line2 : '',
          mailing_city: isChecked ? physical_city : '',
          mailing_country_id: isChecked ? physical_country_id : '',
          mailing_state_id: isChecked ? physical_state_id : '',
          mailing_zip_code: isChecked ? physical_zip_code : '',
        },
      },
    });
  };

  handleDivCheckSameAsPhysical = (event: React.ChangeEvent<HTMLInputElement>, param: string) => {
    const {
      statePhysicalList,
    } = this.state
    const {
      physical_address_line1,
      physical_address_line2,
      physical_city,
      physical_country_id,
      physical_state_id,
      physical_zip_code,
    } = this.state.divisionData.divisionAddressDetails;
    const isChecked = event.target.checked;

    this.setState({
      ...this.state,
      [param]: isChecked,
      stateDivCheckList: isChecked ? statePhysicalList : [],
      isDivisionCheckAddressSameAsMailingAddress: false,
      divisionData: {
        ...this.state.divisionData,
        divisionAddressDetails: {
          ...this.state.divisionData.divisionAddressDetails,
          division_check_address_line1: isChecked ? physical_address_line1 : '',
          division_check_address_line2: isChecked ? physical_address_line2 : '',
          division_check_city: isChecked ? physical_city : '',
          division_check_country_id: isChecked ? physical_country_id : '',
          division_check_state_id: isChecked ? physical_state_id : '',
          division_check_zip_code: isChecked ? physical_zip_code : '',
        },
      },
    });
  }

  handleDivCheckSameAsMailing = (event: React.ChangeEvent<HTMLInputElement>, param: string) => {
    const {
      stateMailingList
    } = this.state
    const {
      mailing_address_line1,
      mailing_address_line2,
      mailing_city,
      mailing_country_id,
      mailing_state_id,
      mailing_zip_code,
    } = this.state.divisionData.divisionAddressDetails;
    const isChecked = event.target.checked;

    this.setState({
      ...this.state,
      [param]: isChecked,
      stateDivCheckList: isChecked ? stateMailingList : [],
      isDivisionCheckAddressSameAsPhysicalAddress: false,
      divisionData: {
        ...this.state.divisionData,
        divisionAddressDetails: {
          ...this.state.divisionData.divisionAddressDetails,
          division_check_address_line1: isChecked ? mailing_address_line1 : '',
          division_check_address_line2: isChecked ? mailing_address_line2 : '',
          division_check_city: isChecked ? mailing_city : '',
          division_check_country_id: isChecked ? mailing_country_id : '',
          division_check_state_id: isChecked ? mailing_state_id : '',
          division_check_zip_code: isChecked ? mailing_zip_code : '',
        },
      },
    });
  }

  getDivisionErrorMessage = (fieldName: string) => {
    const { fieldErrors } = this.state;
    if (fieldErrors[fieldName]) {
      return fieldErrors[fieldName][0];
    }
    return "";
  };

  handleOtherDetailsInputChange = (
    e: React.ChangeEvent<InputType>,
    param: string,
    agentId?: number
  ) => {
    if (agentId !== undefined) {
      this.setState({
        ...this.state,
        divisionData: {
          ...this.state.divisionData,
          divisionOtherDetails: {
            ...this.state.divisionData.divisionOtherDetails,
            agents_attributes: this.state.divisionData.divisionOtherDetails.agents_attributes.map(
              (list, index) => {
                if (index === agentId) {
                  return {
                    ...list,
                    [param]: e.target.value,
                  };
                }
                return list;
              }
            ),
          },
        },
      });
    } else {
      this.setState({
        ...this.state,
        divisionData: {
          ...this.state.divisionData,
          divisionOtherDetails: {
            ...this.state.divisionData.divisionOtherDetails,
            [param]: e.target.value,
          },
        },
      });
    }
  };

  setAgentList = (
    newAgentList: IAgentModel[],
  ) => {
    this.setState({
      ...this.state,
      divisionData: {
        ...this.state.divisionData,
        divisionOtherDetails: {
          ...this.state.divisionData.divisionOtherDetails,
          agents_attributes: newAgentList,
        },
      },
    });
  };

  addAgent = () => {
    const newAgentList = [
      ...this.state.divisionData.divisionOtherDetails.agents_attributes,
    ];
    newAgentList.push(initialAgentModelData);

    this.setAgentList(newAgentList);
  };

  removeAgent = (index: number) => {
    const newAgentList = this.state.divisionData.divisionOtherDetails.agents_attributes.filter(
      (_, i) => i !== index
    );
    this.setAgentList(newAgentList);
  };

  toggleGroupOtherDetailsField = (
    event: React.ChangeEvent<{}>,
    checked: boolean,
    param: string
  ) => {
    this.setState({
      ...this.state,
      divisionData: {
        ...this.state.divisionData,
        divisionOtherDetails: {
          ...this.state.divisionData.divisionOtherDetails,
          [param]: checked,
        },
      },
    });
  };

  setToInitialState = () => {
    this.setState({
      ...this.state,
      divisionActiveStep: 0,
      isDivisionCreated: false,
      divisionData: this.props.divisionDetails,
      isOpenWarningDialog: false,
      groupList: [],
      countryList: [],
      statePhysicalList: [],
      stateMailingList: [],
      stateDivCheckList: [],
      generalError: "",
      apiDevisionId: '',
      apiDivisionAddressId: '',
      isBasicDetailComplete:false
    });
  };

  setActiveStep = (step: number) => {
    this.setState({ divisionActiveStep: step });
  };

  handleCloseWarningDialog = (isAgreeToLooseData: boolean) => {
    if (isAgreeToLooseData) {
      this.props.handleClose();
    }
    this.setState({ ...this.state, isOpenWarningDialog: false });
  };

  handleNext = (mustCheckStep: boolean) => {
    if (mustCheckStep && this.state.divisionActiveStep === 3) {
      return this.setState({
        isDivisionCreated: true
      })
    }
    this.handleSteps(true);
  };

  handleSteps = (next: boolean) => {
    const activeStep = next ? this.state.divisionActiveStep + 1 : this.state.divisionActiveStep - 1;
    const length = this.state.addEditDivisionStepList.length;
    if (length > activeStep) {
      this.setState({
        divisionActiveStep: activeStep,
        addEditDivisionStepList: this.state.addEditDivisionStepList.map(
          (list, index) => {
            if (index === this.state.divisionActiveStep) {
              return {
                ...list,
                isCompleted: true,
              };
            }
            return list;
          }
        ),
      });
    }
  }

  handleDivisionDialogClose = () => {
    this.setState({ ...this.state, isOpenWarningDialog: true });
  };

  isEmpty = (object: any) => {
    for (let key in object) {
      if (object.hasOwnProperty(key)) {
        return false
      }
    }
    return true
  }

  checkErrors = () => {
    return new Promise((resolve) => {

      if (!this.isEmpty(this.state.fieldErrors) || this.state.generalError !== "") {
        return resolve(true)
      }

      const { divisionBasicDetails } = this.state.divisionData

      const { termination_date, effective_date, division_name, division_id, group_id, tax_id } = divisionBasicDetails

      const errors: { [key: string]: string[] } = {}

      if (!division_id) {
        errors.division_id = ["Division id is required"]
      }
      if (!division_name) {
        errors.division_name = ["Division name is required"]
      }
      if (!group_id) {
        errors.group_id = ["Group name is required"]
      }
      if (!tax_id) {
        errors.tax_id = ["Tax ID is required"]
      }

      if (!this.isEmpty(errors)) {
        this.setState({
          fieldErrors: errors
        })
        return resolve(true)
      }

      if (termination_date && effective_date) {
        const termi_date = moment(termination_date, "YYYY-MM-DD")
        const beg_date = moment(effective_date, "YYYY-MM-DD")

        if (termi_date.isBefore(beg_date)) {
          this.setState({
            generalError: `Termination date can't before the effective date`
          })
          return resolve(true)
        }
      }

      resolve(false)
    })
  }

  removeErrors = () => {
    return new Promise((resolve) => {
      this.setState({
        generalError: ''
      }, () => resolve(true))
    })
  }

  //next func
  saveAndNext = () => {
    this.handleNext(false);
  };

  //save and next func
  saveAndFinish = async () => {
    if (this.state.divisionData.divisionBasicDetails.division_id.trim() == "0") {
      return this.setState({
        generalError: "The division id invalid"
      })
    }
    await this.removeErrors()
    const stillError = await this.checkErrors()
    if (stillError) {
      return;
    }

    if (this.state.divisionActiveStep === 0) {
      if (this.state.apiDevisionId) {
        return this.updateDivisionAPI();
      }
      return this.createDivisionAPI();

    }
    if (this.state.divisionActiveStep === 1) {
      if (this.state.apiDivisionAddressId) {
        return this.updateDivisionAddressAPI();
      }
      return this.createDivisionAddressAPI()
    }
    if (this.state.divisionActiveStep === 2) {
      return this.handleNext(false)
    }
    if (this.state.divisionActiveStep === 3) {
      return this.createDivisionOtherDetailAPI()
    }
  };

  createDivisionAPI = () => {
    this.setState({
      generalError: "",
      fieldErrors: {},
    });

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.requestCreateDivisionCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createDivisionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    const httpBody = {
      division: this.state.divisionData.divisionBasicDetails,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createBodyDivAddress = (isCreate: boolean) => {
    const { countryList, stateDivCheckList, statePhysicalList, stateMailingList } = this.state
    const { divisionAddressDetails } = this.state.divisionData
    const {
      physical_state_id,
      physical_country_id,
      mailing_state_id,
      mailing_country_id,
      division_check_state_id,
      division_check_country_id,
    } = divisionAddressDetails

    const mailingCountryName: string = countryList.find((item: IDropdownModel) => item.id === mailing_country_id)?.name || ""

    const physicalCountryName: string = countryList.find((item: IDropdownModel) => item.id === physical_country_id)?.name || ""

    const physicalStateName: string = statePhysicalList.find((item: IDropdownModel) => item.id === physical_state_id)?.name || ""

    const mailingStateName: string = stateMailingList.find((item: IDropdownModel) => item.id === mailing_state_id)?.name || ""

    const divCheckCountryName: string = countryList.find((item: IDropdownModel) => item.id === division_check_country_id)?.name || ""

    const divCheckStateName: string = stateDivCheckList.find((item: IDropdownModel) => item.id === division_check_state_id)?.name || ""

    const addressDetails = divisionAddressDetails

    let dataAddressDetais = this.removeProperty("mailing_country_id", addressDetails)

    dataAddressDetais = this.removeProperty("physical_country_id", dataAddressDetais)
    dataAddressDetais = this.removeProperty("physical_state_id", dataAddressDetais)
    dataAddressDetais = this.removeProperty("mailing_state_id", dataAddressDetais)
    dataAddressDetais = this.removeProperty("division_check_country_id", dataAddressDetais)
    dataAddressDetais = this.removeProperty("division_check_state_id", dataAddressDetais)

    const body: any = {
      division_address: {
        ...dataAddressDetais,
        physical_country: physicalCountryName,
        physical_state: physicalStateName,
        mailing_country: mailingCountryName,
        mailing_state: mailingStateName,
        division_check_country: divCheckCountryName,
        division_check_state: divCheckStateName,
      }
    }

    if (isCreate) {
      body.division_address.division_id = this.state.apiDevisionId
    }

    return body
  }

  createDivisionAddressAPI = () => {

    this.setState({
      generalError: '',
      fieldErrors: {}
    })
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.token,
    }

    const requestMessageAddress = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.requestCreateDivisionAddressCallId = requestMessageAddress.messageId

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createDivisionAddressEndPoint
    )

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.createBodyDivAddress(true))
    );

    runEngine.sendMessage(requestMessageAddress.id, requestMessageAddress);
  }

  createDivisionOtherDetailAPI = () => {
    this.setState({
      generalError: '',
      fieldErrors: {}
    })
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.token,
    }

    const requestMessageAddress = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.requestCreateDivisionOtherDetailCallId = requestMessageAddress.messageId

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createDivisionOtherDetailEndPoint
    )

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    const agentInfo = this.state.divisionData.divisionOtherDetails.agents_attributes

    const bodyForAgent: any = {}

    agentInfo.forEach((itemAgent, index) => {
      bodyForAgent[`agent${index + 1}_name`] = itemAgent.agent_name
      bodyForAgent[`agent${index + 1}_rate`] = itemAgent.agent_rate
    })

    const otherDetailParams = (({ agents_attributes, ...o }) => o)(this.state.divisionData.divisionOtherDetails)

    const httpBody = {
      division_other_detail: {
        ...otherDetailParams,
        division_id: this.state.apiDevisionId,
        ...bodyForAgent
      },
    };

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessageAddress.id, requestMessageAddress);
  }

  updateDivisionAPI = () => {
    this.setState({
      generalError: "",
      fieldErrors: {},
    });

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.requestUpdateDivisionCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createDivisionAPiEndPoint}/${this.state.apiDevisionId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    const httpBody = {
      division: this.state.divisionData.divisionBasicDetails,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateDivisionAddressAPI = () => {
    this.setState({
      generalError: '',
      fieldErrors: {}
    })

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.token,
    }

    const requestMessageAddress = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.requestUpdateDivisionAddressCallId = requestMessageAddress.messageId

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createDivisionAddressEndPoint}/${this.state.apiDivisionAddressId}`
    )

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.createBodyDivAddress(false))
    );

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessageAddress.id, requestMessageAddress);
  }

  getGroupList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.token,
    };

    this.getGroupCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGroupAPiEndPoint.replace("{id}", this.props.selectedEmployer[0].emp_id)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCountryList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCountryAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStateList = (idCountry: string, typeState?: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    switch (typeState) {
      case 'physical_country_id':
        this.requestPhysicalStateCallId = requestMessage.messageId;
        break;
      case 'mailing_country_id':
        this.requestMailingStateCallId = requestMessage.messageId;
        break;
      case 'division_check_country_id':
        this.requestDivCheckStateCallId = requestMessage.messageId;
        break;
      default:
        this.getStateCallId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStateFromCountryAPiEndPoint}${idCountry}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    switch (apiRequestCallId) {
      case this.requestCreateDivisionCallId:
        this.receiveCreateDivision(message);
        break;
      case this.requestUpdateDivisionCallId:
        this.receiveUpdateDivision(message);
        break;
      case this.requestCreateDivisionAddressCallId:
        this.receiveCreateDivisionAddress(message);
        break;
      case this.requestUpdateDivisionAddressCallId:
        this.receiveUpdateDivisionAddress(message);
        break;
      case this.requestCreateDivisionOtherDetailCallId:
        this.receiveCreateDivisionOtherDetail(message);
        break;
      case this.getGroupCallId:
        this.receiveGroupList(message);
        break;
      case this.getCountryCallId:
        this.receiveCountryList(message);
        break;
      case this.getStateCallId:
        this.receiveStateList(message);
        break;
      case this.requestPhysicalStateCallId:
        this.receiveSpecifyStateList(message, 'physical_country_id')
        break;
      case this.requestMailingStateCallId:
        this.receiveSpecifyStateList(message, 'mailing_country_id')
        break;
      case this.requestDivCheckStateCallId:
        this.receiveSpecifyStateList(message, 'division_check_country_id')
        break;
    }
  }

  receiveCreateDivision = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && responseJson.data) {
      this.setState({
        apiDevisionId: responseJson.data.id,
        isBasicDetailComplete: !this.props.isEdit
      })
      return this.handleNext(true);
    }
    return this.handleErrorApi(responseJson)
  };

  receiveUpdateDivision = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data) {
      return this.handleNext(false);
    }
    return this.handleErrorApi(responseJson)
  }

  receiveCreateDivisionOtherDetail = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data) {
      return this.setState({
        isDivisionCreated: true
      })
    }
    return this.handleErrorApi(responseJson)
  }

  receiveCreateDivisionAddress = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && responseJson.data) {
      this.setState({
        apiDivisionAddressId: responseJson.data.id
      })
      return this.handleNext(true);
    }
    return this.handleErrorApi(responseJson)
  }

  receiveUpdateDivisionAddress = (message: Message) => {

    const responseUdate = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseUdate && responseUdate.data) {
      return this.handleNext(false);
    }
    return this.handleErrorApi(responseUdate)
  }

  handleErrorApi = (responseJson: any) => {
    if (responseJson) {
      if (responseJson.errors && Array.isArray(responseJson.errors) && responseJson.errors[0].token) {
        return this.setState({
          generalError: responseJson.errors[0].token,
        });
      }

      if (typeof responseJson === 'object') {
        if (responseJson.errors) {
          return this.setState({
            generalError: responseJson.errors
          });
        }
        return this.setState({
          generalError: `${Object.keys(responseJson)[0]} ${responseJson[Object.keys(responseJson)[0]][0]}`,
        });
      }
    }
  }

  receiveGroupList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      this.setState({
        ...this.state,
        groupList: responseJson.data.map((list: any) => ({
          id: list.id,
          name: list.attributes.group_name,
        })),
      });
    } else {
      this.setState({ ...this.state, groupList: [] });
    }
  };

  receiveCountryList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && responseJson.countries && responseJson.countries.length > 0) {
      this.setState({
        ...this.state,
        countryList: responseJson.countries.map((item: any) => ({ id: item.countryCode, name: item.CountryName }))
      }, () => this.updateEditDiv());
    } else {
      this.setState({ ...this.state, countryList: [] });
    }
  };

  updateEditDiv() {
    const { isEdit, divisionDetails } = this.props
    const { countryList } = this.state
    if (!isEdit) {
      return;
    }
    let physical_country_id: string = divisionDetails.divisionAddressDetails.physical_country_id
    let mailing_country_id: string = divisionDetails.divisionAddressDetails.mailing_country_id
    let division_check_country_id: string = divisionDetails.divisionAddressDetails.division_check_country_id

    if (physical_country_id) {
      physical_country_id = countryList.find(item => item.name === physical_country_id)?.id || ''
      this.getStateList(physical_country_id, 'physical_country_id')
    }
    if (mailing_country_id) {
      mailing_country_id = countryList.find(item => item.name === mailing_country_id)?.id || ''
      this.getStateList(mailing_country_id, 'mailing_country_id')
    }
    if (division_check_country_id) {
      division_check_country_id = countryList.find(item => item.name === division_check_country_id)?.id || ''
      this.getStateList(division_check_country_id, 'division_check_country_id')
    }

    this.setState({
      apiDevisionId: divisionDetails.apiDivisionId,
      apiDivisionAddressId: divisionDetails.apiDivisionAddressId,
      divisionData: {
        ...divisionDetails,
        divisionBasicDetails: {
          ...divisionDetails.divisionBasicDetails,
          employer_name: this.props.selectedEmployer[0].employer_name,
          employer_id: this.props.selectedEmployer[0].emp_id,
          client_name: this.clien_name,
        },
        divisionAddressDetails: {
          ...divisionDetails.divisionAddressDetails,
          physical_country_id,
          mailing_country_id,
          division_check_country_id
        }
      }
    })
  }

  receiveStateList = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let type: string = ""
    switch (this.currentStateType) {
      case 'physical_country_id':
        type = 'statePhysicalList'
        break;
      case 'mailing_country_id':
        type = 'stateMailingList'
        break;
      case 'division_check_country_id':
        type = 'stateDivCheckList'
        break;
    }
    this.currentStateType = ''
    if (responseJson && responseJson.states && responseJson.states.length > 0) {
      this.setState({ ...this.state, [type]: responseJson.states.map((item: any) => ({ id: item.StateCode, name: item.StateName })) });
    } else {
      this.setState({ ...this.state, [type]: [] });
    }
  };

  receiveSpecifyStateList = (message: Message, typeState?: string) => {
    const { divisionDetails } = this.props
    // console.log('divisionDetails', divisionDetails)
    const { divisionData } = this.state
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const array = responseJson.states.map((item: any) => ({ id: item.StateCode, name: item.StateName }))
    let type: string = ""
    let value: string = ""
    let param: string = ""
    switch (typeState) {
      case 'physical_country_id':
        type = 'statePhysicalList'
        value = array.find((item: IDropdownModel) => item.name == divisionDetails.divisionAddressDetails.physical_state_id)?.id
        param = 'physical_state_id'
        break;
      case 'mailing_country_id':
        type = 'stateMailingList'
        value = array.find((item: IDropdownModel) => item.name == divisionDetails.divisionAddressDetails.mailing_state_id)?.id
        param = 'mailing_state_id'
        break;
      case 'division_check_country_id':
        type = 'stateDivCheckList'
        value = array.find((item: IDropdownModel) => item.name == divisionDetails.divisionAddressDetails.division_check_state_id)?.id
        param = 'division_check_state_id'
        break;
    }

    if (responseJson && responseJson.states && responseJson.states.length > 0) {
      this.setState({
        ...this.state,
        [type]: array,
        divisionData: {
          ...divisionData,
          divisionAddressDetails: {
            ...divisionData.divisionAddressDetails,
            [param]: value
          }
        }
      });
    } else {
      this.setState({ ...this.state, [type]: [] });
    }
  };

  handleSuccessDialogClose = () => {
    this.setToInitialState()
    this.props.handleClose();
  }
}