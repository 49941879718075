import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { StepList } from "../../../../components/src/CustomVerticalFormStepper.web";
import {
  initialplanInfoModelData,
  IPlanDetailModel,
  initialMedicalModelData
} from "./ClientPlanModel";

export const configJSON = require("../config");

export interface Props {
  isOpen: boolean;
  handleClose: any;
}

interface S {
  planActiveStep: number;
  planStepList: StepList[];
  planData: IPlanDetailModel["planData"];
}

interface SS {
  id: any;
}

export default class ClientEmployerAddPlanDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      planActiveStep: 0,
      planStepList: [
        {
          key: 1,
          title: "Plan Info",
          description: "Type and Selet details",
          isCompleted: false
        },
        {
          key: 2,
          title: "Medical",
          description: "Type and Selet details",
          isCompleted: false
        }
      ],
      planData: {
        planInfo: initialplanInfoModelData,
        medical: initialMedicalModelData
      }
    };
  }

  setActiveStep = (step: number) => {
    this.setState({ planActiveStep: step });
  };

  handleNext = (isFinish?: boolean) => {
    if (isFinish) {
      // handle finish
    } else {
      this.handleSteps(true);
    }
  };

  handleSteps = (next: boolean) => {
    const activeStep = next
      ? this.state.planActiveStep + 1
      : this.state.planActiveStep - 1;
    const length = this.state.planStepList.length;
    if (length > activeStep) {
      this.setState({
        planActiveStep: activeStep,
        planStepList: this.state.planStepList.map((list, index) => {
          if (index === this.state.planActiveStep) {
            return {
              ...list,
              isCompleted: true
            };
          }
          return list;
        })
      });
    }
  };

  handleFileUpload = (
    files: FileList | null,
    group: keyof IPlanDetailModel["planData"],
    param: string
  ) => {
    if (files) {
      this.setState({
        ...this.state,
        planData: {
          ...this.state.planData,
          [group]: {
            ...this.state.planData[group],
            [param]: files[0]
          }
        }
      });
    }
  };

  handleInputItemChange = (
    inputValue: string | number | boolean,
    group: keyof IPlanDetailModel["planData"],
    param: string
  ) => {
    this.setState({
      ...this.state,
      planData: {
        ...this.state.planData,
        [group]: {
          ...this.state.planData[group],
          [param]: inputValue
        }
      }
    });
  };

  saveAndNext = () => {
    this.handleNext();
  };

  saveAndFinish = () => {};
}
