import React from "react";
import { Grid, Button } from "@material-ui/core";

import "./EmployerDetails.web.css";
import EmployerDocumentsController, {
  Props
} from "./EmployerDocumentsController";
import GenericSearch from "../../../components/src/GenericSearch.web";
import CustomerTable from "../../../components/src/CustomTable.web";
import GenericDialog from "../../../components/src/GenericDialog.web";
import GenericButton from "../../../components/src/GenericButton.web";
import CustomFileDropInput from "../../../components/src/CustomFileDropInput.web";
import GenericTextAreaBox from "../../../components/src/GenericTextAreaBox.web";
import GenericSuccessDialog from "../../../components/src/GenericSuccessDialog.web";
import { companyIcon, searchIcon } from "./assets";
import GenericCompanyDetailsCard from "../../../components/src/GenericCompanyDetailsCard.web";
import { formValidationMsg } from "./EditEmployerDetailsDialog.web";

export default class EmployerDocuments extends EmployerDocumentsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const dialogContain = (
      <>
        <CustomFileDropInput
          data-test-id="file-drop-input"
          file={this.state.docFile}
          onChange={this.handleDocumentFileOnChange}
        />
        {formValidationMsg(this.state.docValidationMessage)}
        <GenericTextAreaBox
          data-test-id="text-area-input"
          label="Description"
          className="dialog-textarea-box"
          value={this.state.docDescription}
          onChange={this.handleDocDescriptionOnChange}
        />
        {formValidationMsg(this.state.descriptionValidationMessage)}
      </>
    );

    const dialogActions = (
      <GenericButton
        data-test-id="action-add-document-btn"
        variant="contained"
        color="primary"
        onClick={this.onSaveDocument}
      >
        Add Document
      </GenericButton>
    );
    return (
      <div>
        <GenericCompanyDetailsCard
          companyName="Infotech pvt ltd"
          companyAddress="8 Moretown, Mountain Road"
          companyIcon={companyIcon}
        />
        <Grid container className="emp-documents-container">
          <Grid item xs={12} className="search-bar-and-add-btn">
            <GenericSearch
              data-test-id="my-document-search"
              searchIcon={searchIcon}
              placeholder="Search for Employee"
              value={this.state.searchString}
              onChange={this.onSearchDocumentChange}
            />
            <div className="add-document-btn-div">
              <Button
                data-test-id="add-document-btn"
                className="add-document-btn"
                onClick={this.onOpenAddDocumentDialog}
              >
                Add Document
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} className="hr mt-30" />
          <Grid item xs={12}>
            <CustomerTable
              data-test-id="custom-table"
              columns={this.documentListColumns}
              rowData={this.state.rowItems}
              onActionClick={()=>{}}
            />
          </Grid>
        </Grid>
        <GenericDialog
          title="Add Document"
          open={this.state.isOpenAddDocumentDialog}
          handleClose={this.onCloseAddDocumentDialog}
          contains={dialogContain}
          actions={dialogActions}
          maxWidth="md"
          hideCloseDialog={!this.state.isDataAdded}
        />
        <GenericSuccessDialog
          open={this.state.isDocumentSubmitted}
          handleDone={this.handleSuccessClose}
        />
      </div>
    );
  }
}
