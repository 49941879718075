import React, { Component } from "react";
import Button from "@material-ui/core/Button";

import "./GenericButton.web.css";

interface Props {
  variant: "text" | "outlined" | "contained";
  color: "inherit" | "primary" | "secondary" | "default";
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  isIconButton?: boolean;
}

interface S {}

export default class GenericButton extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Button
        variant={this.props.variant}
        color={this.props.color}
        className={`generic-button ${this.props.className} ${this.props
          .isIconButton && "icon-button"}`}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        {this.props.children}
      </Button>
    );
  }
}
