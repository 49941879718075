import { IDivisionOtherDetailModel, initialDivisionOtherDetailModelData } from "../ClientEmployerDivision/ClientEmployerDivisionModel";

export interface LocationBasicDetailsModel {
  location_name:string;
  location_id:string;
  client_name: string;
  employer_name: string;
  employer_id: string;
  group_id: string;
  division_id: string;
  begin_date: string;
  termination_date: Date | string;
  deductible_date: Date | string;
  payroll_day: string;
  payroll_month: string;
  first_payroll_cycle: string;
  second_payroll_cycle: string;
  third_payroll_cycle: string;
  fourth_payroll_cycle: string;
  tax_id: string;
}

export interface LocationAddressAndContactModel {
  physical_address_line1: string;
  physical_address_line2: string;
  physical_city: string;
  physical_zip_code: string;
  physical_state_id: number;
  physical_country_id: number;
  location_check_contact1: string;
  location_check_contact2: string;
  location_check_email1: string;
  location_check_email2: string;
  location_check_phone_number1: string;
  location_check_phone_number2: string;
  location_check_fax1: string;
  location_check_fax2: string;
  mailing_address_line1: string;
  mailing_address_line2: string;
  mailing_city: string;
  mailing_zip_code: string;
  mailing_state_id: number;
  mailing_country_id: number;
  location_check_address_line1: string;
  location_check_address_line2: string;
  location_check_city: string;
  location_check_zip_code: string;
  location_check_state_id: number;
  location_check_country_id: number;
}

export interface LocationModel {
  locationBasicDetails: LocationBasicDetailsModel;
  locationAddressDetails:LocationAddressAndContactModel;
  locationOtherDetails: IDivisionOtherDetailModel
}

export const initialLocationAddressAndContactData: LocationAddressAndContactModel = {
  physical_address_line1: "",
  physical_address_line2: "",
  physical_city: "",
  physical_zip_code: "",
  physical_state_id: -1,
  physical_country_id: -1,
  location_check_contact1: "",
  location_check_contact2: "",
  location_check_email1: "",
  location_check_email2: "",
  location_check_phone_number1: "",
  location_check_phone_number2: "",
  location_check_fax1: "",
  location_check_fax2: "",
  mailing_address_line1: "",
  mailing_address_line2: "",
  mailing_city: "",
  mailing_zip_code: "",
  mailing_state_id: -1,
  mailing_country_id: -1,
  location_check_address_line1: "",
  location_check_address_line2: "",
  location_check_city: "",
  location_check_zip_code: "",
  location_check_state_id: -1,
  location_check_country_id: -1
};

export const initialLocationBasicDetailData: LocationBasicDetailsModel = {
  location_name: "",
  location_id: "",
  client_name: "",
  employer_name: "",
  employer_id: "",
  group_id: "",
  division_id:"",
  begin_date: "",
  termination_date: "",
  deductible_date: "",
  payroll_day: "",
  payroll_month: "",
  first_payroll_cycle: "",
  second_payroll_cycle: "",
  third_payroll_cycle: "",
  fourth_payroll_cycle: "",
  tax_id: ""
};

export const initialLocationData : LocationModel = {
  locationBasicDetails: initialLocationBasicDetailData,
  locationAddressDetails: initialLocationAddressAndContactData,
  locationOtherDetails: initialDivisionOtherDetailModelData
}

export interface IDropdownModel {
  id: number;
  name: string;
}