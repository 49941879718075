import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import "./GenericCloseDialog.web.css";
import GenericButton from "./GenericButton.web";

interface Props {
  open: boolean;
  handleClose: () => void;
  handleOk: () => void;
  contains?: any;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

interface S {}

export default class GenericCloseDialog extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Dialog
        onClose={this.props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={this.props.open}
        className="generic-close-dialog"
        maxWidth={this.props.maxWidth || "sm"}
        fullWidth
      >
        <DialogContent dividers>
          {this.props.contains ||
            "Are you sure you want to cancel your changes will not be saved"}
        </DialogContent>
        <DialogActions>
          <GenericButton
            variant="outlined"
            color="default"
            onClick={this.props.handleClose}
            data-test-id="cancel-close-btn"
          >
            Cancel
          </GenericButton>
          <GenericButton
            variant="contained"
            color="primary"
            onClick={this.props.handleOk}
            data-test-id="ok-close-btn"
          >
            Ok
          </GenericButton>
        </DialogActions>
      </Dialog>
    );
  }
}
