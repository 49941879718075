export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const arrowRight = require("../assets/arrow_right.png");
export const searchIcon = require("../assets/search_icon.png");
export const folderImage = require("../assets/folder_image.png");
export const filterIcon = require("../assets/filter_icon.png");
export const filterIconSelected = require("../assets/filter_icon_selected.png");
export const findHealthcareImage = require("../assets/find_healthcare_image.png");
export const healthcareDr1 = require("../assets/healthcare_dr1.png");
export const healthcareDr2 = require("../assets/healthcare_dr2.png");
export const healthcareDr3 = require("../assets/healthcare_dr3.png");
export const emailIcon = require("../assets/email_icon.png");
export const phoneIcon = require("../assets/phone_icon.png");
export const pharmacy1 = require("../assets/pharmacy1.png");
export const pharmacy2 = require("../assets/pharmacy2.png");
export const pharmacy3 = require("../assets/pharmacy3.png");
export const pharmacy4 = require("../assets/pharmacy4.png");
export const helpLeftImg = require("../assets/help_left_img.png");
export const helpRightImg = require("../assets/help_right_img.png");
export const oval = require("../assets/oval.png");
export const bigOval = require("../assets/big_oval.png");
export const notificationIcon = require("../assets/notification_icon.png");
export const optionsIcon = require("../assets/options_icon.png");
export const medical = require("../assets/medical.png");
export const medicalSelected = require("../assets/medical_selected.png");
export const dental = require("../assets/dental.png");
export const dentalSelected = require("../assets/dental_selected.png");
export const vision = require("../assets/vision.png");
export const visionSelected = require("../assets/vision_selected.png");
export const rx = require("../assets/rx.png");
export const rxSelected = require("../assets/rx_selected.png");
export const lifeInsurance = require("../assets/life_insurance.png");
export const lifeInsuranceSelected = require("../assets/life_insurance_selected.png");
export const voluntarySupplements = require("../assets/voluntary_supplements.png");
export const voluntarySupplementsSelected = require("../assets/voluntary_supplements_selected.png");
export const retirement = require("../assets/retirement.png");
export const retirementSelected = require("../assets/retirement_selected.png");
export const healthSavingsAccount = require("../assets/health_savings_account.png");
export const healthSavingsAccountSelected = require("../assets/health_savings_account_selected.png");
export const healthFSA = require("../assets/health_fsa.png");
export const healthFSASelected = require("../assets/health_fsa_selected.png");
export const HRA = require("../assets/hra.png");
export const HRASelected = require("../assets/hra_selected.png");
export const others = require("../assets/others.png");
export const othersSelected = require("../assets/others_selected.png");
export const userClient = require("../assets/user_client.png");
export const blackEditIcon = require("../assets/blackEditIcon.png");
export const deleteIcon = require("../assets/deleteIcon.png");
export const cloneIcon = require("../assets/clone.png");
export const viewIcon = require("../assets/view.png")
