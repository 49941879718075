import React, { Component } from "react";
import { Typography } from "@material-ui/core";

import "./CustomFileDropInput.web.css";

interface Props {
  buttonLabel?: string;
  file?: any;
  onChange?: (file: any) => void;
  error?: string;
}

interface S {
  file: any;
}

export default class CustomFileDropInput extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {
      file: this.props.file || "",
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.file !== this.props.file) {
      this.setState({ file: this.props.file });
    }
  }

  handleDrop = (event: any) => {
    event.preventDefault();
    if (event.dataTransfer.files &&  event.dataTransfer.files[0]) {
      this.setState({ file: event.dataTransfer.files[0] });
      this.props?.onChange?.(event.dataTransfer.files[0])
    }
  };

  handleDragOver = (event: any) => {
    event.preventDefault();
  };

  handleFileChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      this.setState({ file: event.target.files[0] });
      this.props?.onChange?.(event.target.files[0])
    }
  };

  render() {
    return (
      <div className="custom-file-drop-input">
        <div className="choose-file">
          <input
            type="file"
            id="actual-btn"
            onChange={this.handleFileChange}
            hidden
          />
          <label htmlFor="actual-btn" className="choose-file-label">
            {this.props.buttonLabel || "Choose File"}
          </label>
          {this.state.file ? (
            <p>{this.state.file.name}</p>
          ) : (
            <p>No file chosen</p>
          )}
        </div>
        <div
          onDrop={this.handleDrop}
          onDragOver={this.handleDragOver}
          className={`drop-input ${this.props.error && "error"}`}
        >
          {this.state.file ? (
            <p>{this.state.file.name}</p>
          ) : (
            <p>Drop File Here</p>
          )}
        </div>
        {this.props.error && (
          <Typography
            variant="subtitle1"
            component="div"
            className="text-info-red"
          >
            {this.props.error}
          </Typography>
        )}
      </div>
    );
  }
}
