import React from "react";
import { Typography, Grid } from "@material-ui/core";

import MyNotificationsController, {
  Props,
  configJSON,
  NotificationList,
  images,
} from "./MyNotificationsController.web";
import "./MyNotifications.web.css";
import CustomTabPanel from "../../../components/src/CustomTabPanel.web";

export default class MyNotifications extends MyNotificationsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const getNotificationList = (list: NotificationList[]) => {
      const openNotification = list.filter((l) => l.isOpen)[0];
      if (openNotification) {
        return (
          <div
            className={`notification-box open ${
              openNotification.isRead && "read"
            }`}
          >
            <div className="notification-main-section">
              <div
                data-test-id="open-notification-on-click"
                className="notification-box-left-side"
                onClick={() =>
                  this.handleNotificationOnClick(openNotification.key)
                }
              >
                <img
                  src={openNotification.icon}
                  alt="Notification Icon"
                  className="notification-icon"
                />
                <div className="notification-header">
                  <div className="notification-heading-open">
                    {openNotification.headingLabel}
                  </div>
                  <div className="notification-date-time">
                    {openNotification.date} | {openNotification.time}
                  </div>
                </div>
              </div>
              <div className="notification-box-right-side-open">
                <button className="notification-options-btn">
                  <img
                    src={images.optionsIcon}
                    alt="Options Icon"
                    className="options-icon"
                  />
                </button>
              </div>
            </div>
            <div
              className={`my-30 ${openNotification.isRead ? "hr-white" : "hr"}`}
            />
            <div
              data-test-id="long-description"
              className="notification-long-description"
            >
              {openNotification.longDescription}
            </div>
          </div>
        );
      }

      return list.map((notification) => (
        <div
          className={`notification-box ${notification.isRead && "read"}`}
          key={notification.key}
        >
          <div
            className="notification-main-section"
            data-test-id="notification-on-click"
            onClick={() => this.handleNotificationOnClick(notification.key)}
          >
            <div className="notification-box-left-side">
              <img
                src={notification.icon}
                alt="Notification Icon"
                className="notification-icon"
              />
              <div className="notification-header">
                <div className="notification-heading">
                  {notification.headingLabel}
                </div>
                <div className="notification-description">
                  {notification.description}
                </div>
              </div>
            </div>
            <div className="notification-box-right-side">
              <div className="notification-box-time">{notification.time}</div>
              <div className="notification-box-date">{notification.date}</div>
            </div>
          </div>
        </div>
      ));
    };

    return (
      <>
        <Grid container className="my-notifications-right-side-container">
          <Grid item xs={12} className="d-flex">
            <Typography variant="body1" className="greeting-name">
              {configJSON.myNotifications}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomTabPanel
              tabList={[
                configJSON.notification,
                configJSON.read,
                configJSON.unread,
              ]}
            >
              <div className="notification-container">
                {getNotificationList(this.state.notificationList)}
              </div>
              <div className="notification-container">
                {getNotificationList(
                  this.state.notificationList.filter(
                    (notification) => notification.isRead
                  )
                )}
              </div>
              <div>
                <div className="notification-container">
                  {getNotificationList(
                    this.state.notificationList.filter(
                      (notification) => !notification.isRead
                    )
                  )}
                </div>
              </div>
            </CustomTabPanel>
          </Grid>
        </Grid>
      </>
    );
  }
}
