import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { Grid, Button } from "@material-ui/core";

import { defaultMaterialTheme } from "../../../dashboard/src/EditEmployerDetailsDialog.web";
import GenericDialogFormWrapper from "../../../../components/src/GenericDialogFormWrapper.web";
import GenericCheckBoxField from "../../../../components/src/GenericCheckBoxField.web";
import GenericTextField from "../../../../components/src/GenericTextField.web";
import GenericSelectField from "../../../../components/src/GenericSelectField.web";
import ClientEmployerAddPlanDialogController, {
  Props
} from "./ClientEmployerAddPlanDialogController.web";
import GenericTextAreaBox from "../../../../components/src/GenericTextAreaBox.web";
import EmployerAddPlanMedical from "./EmployerAddPlanStepper/EmployerAddPlanMedical.web";

export default class ClientEmployerAddPlanDialog extends ClientEmployerAddPlanDialogController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      planActiveStep,
      planStepList,
      planData: { planInfo, medical }
    } = this.state;

    const planFormContent = (
      <div>
        {planActiveStep === 0 && (
          <Grid
            container
            spacing={3}
            data-test-id="plan-step-1-wrapper"
            className="plan-step-1-wrapper"
          >
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Plan Name"
                value={planInfo.plan_name}
                onChange={e =>
                  this.handleInputItemChange(
                    e.target.value,
                    "planInfo",
                    "plan_name"
                  )
                }
                data-test-id="plan_name"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label="Client Name"
                value={planInfo.plan_name}
                onChange={e =>
                  this.handleInputItemChange(
                    e.target.value,
                    "planInfo",
                    "client_name"
                  )
                }
                data-test-id="client_name"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Employer Name"
                selectionList={[]}
                value={planInfo.employer_name}
                onChange={e =>
                  this.handleInputItemChange(
                    e.target.value,
                    "planInfo",
                    "employer_name"
                  )
                }
                data-test-id="employer_name"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Group Name"
                selectionList={[]}
                value={planInfo.group_name}
                onChange={e =>
                  this.handleInputItemChange(
                    e.target.value,
                    "planInfo",
                    "group_name"
                  )
                }
                data-test-id="group_name"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Divison Name"
                selectionList={[]}
                value={planInfo.division_name}
                onChange={e =>
                  this.handleInputItemChange(
                    e.target.value,
                    "planInfo",
                    "division_name"
                  )
                }
                data-test-id="division_name"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Location Name"
                selectionList={[]}
                value={planInfo.location_name}
                onChange={e =>
                  this.handleInputItemChange(
                    e.target.value,
                    "planInfo",
                    "location_name"
                  )
                }
                data-test-id="location_name"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Class Name"
                selectionList={[]}
                value={planInfo.class_name}
                onChange={e =>
                  this.handleInputItemChange(
                    e.target.value,
                    "planInfo",
                    "class_name"
                  )
                }
                data-test-id="class_name"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label="Stop Loss Carrier"
                selectionList={[]}
                value={planInfo.stop_loss_carrier}
                onChange={e =>
                  this.handleInputItemChange(
                    e.target.value,
                    "planInfo",
                    "stop_loss_carrier"
                  )
                }
                data-test-id="stop_loss_carrier"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <GenericTextAreaBox
                label="Plan Description"
                value={planInfo.plan_description}
                onChange={e =>
                  this.handleInputItemChange(
                    e.target.value,
                    "planInfo",
                    "plan_description"
                  )
                }
                data-test-id="plan_description"
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <div className="text-input-label">
                Summary Plan Documents (add pdf format)
              </div>
              <div>
                <input
                  type="file"
                  id="plan-documents-upload-input"
                  style={{ display: "none" }}
                  data-test-id="plan-documents-upload-input"
                  onChange={e =>
                    this.handleFileUpload(
                      e.target.files,
                      "planInfo",
                      "plan_documents"
                    )
                  }
                  name="plan_documents"
                />
                <label htmlFor="plan-documents-upload-input">
                  <Button
                    variant="outlined"
                    component="span"
                    color="primary"
                    fullWidth
                    className="img-upload-btn"
                  >
                    Choose a File
                  </Button>
                </label>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <div className="text-input-label">Enrollment Documents</div>
              <div>
                <input
                  type="file"
                  id="enrollment-documents-upload-input"
                  style={{ display: "none" }}
                  data-test-id="enrollment-documents-upload-input"
                  onChange={e =>
                    this.handleFileUpload(
                      e.target.files,
                      "planInfo",
                      "enrollment_documents"
                    )
                  }
                  name="enrollment_documents"
                />
                <label htmlFor="enrollment-documents-upload-input">
                  <Button
                    variant="outlined"
                    component="span"
                    color="primary"
                    fullWidth
                    className="img-upload-btn"
                  >
                    Choose a File
                  </Button>
                </label>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={planInfo.is_enrollment_plan}
                label="Is Enrollment Plan"
                data-test-id="is_enrollment_plan"
                onChange={e =>
                  this.handleInputItemChange(
                    e.target.checked,
                    "planInfo",
                    "is_enrollment_plan"
                  )
                }
              />
            </Grid>
          </Grid>
        )}
        {planActiveStep === 1 && (
          <EmployerAddPlanMedical
            handleInputItemChange={this.handleInputItemChange}
            medicalData={medical}
          />
        )}
      </div>
    );

    return (
      <ThemeProvider theme={defaultMaterialTheme}>
        <GenericDialogFormWrapper
          open={this.props.isOpen}
          title={"Add New Plan"}
          handleClose={this.props.handleClose}
          stepList={planStepList}
          activeStep={planActiveStep}
          saveAndNext={this.saveAndNext}
          saveAndFinish={this.saveAndFinish}
          formContent={planFormContent}
          setActiveStep={this.setActiveStep}
          handlePrevious={() => {
            this.handleSteps(false);
          }}
          buttonTitle={"Save & Submit"}
        />
      </ThemeProvider>
    );
  }
}
