export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgCompanyLogo = require("../assets/company-logo.png");
export const landingImage1 = require("../assets/landingPhoto.png");
export const backButton = require("../assets/backButton.png");
export const confirmImage = require("../assets/5500660.png");
export const landingPage = require("../assets/landingPage.png");
export const multiImage = require("../assets/multiImage.png");
export const india = require("../assets/india.png");
export const unitedKingdom = require("../assets/united-kingdom.png");
export const unitedStates = require("../assets/united-states.png");
