import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { AppConstant } from "../../../components/src/Constant";
import React from "react";
const images = require("./assets");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  company: string;
  ssnId: string;
  taxId: string;
  address: string;
  data: any[];
  passwordHelpingText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enablePasswordFieldHR: boolean;
  enableReTypePasswordFieldHR: boolean;
  countryCodeSelected: string;
  phone: string;
  selectedOption: any;
  showForm: boolean;
  passwordVisibility: boolean;
  confirmPasswordVisibility: boolean;
  emailErrorHR: any;
  showEmailErrorHR: boolean;
  passwordErrorHR: any;
  showPasswordErrorHR: boolean;
  apiData: boolean;
  userSignupResponse: any;
  errorFirstName: any;
  showFirstNameError: boolean;
  errorLastName: any;
  showLastNameError: boolean;
  errorAddress: any;
  showAddressError: boolean;
  errorPhone: any;
  showPhoneError: boolean;
  errorCompany: any;
  showCompanyError: boolean;
  errorTax: any;
  showTaxError: boolean;
  errorConfirmPassword: any;
  showConfirmPasswordError: boolean;
  errorSsnEmpty: any;
  errorSsnTax: any;
  showSsnErrorEmpty: boolean;
  showSsnErrorTax: boolean;
  isModal: boolean;
  selectorValue: string;
  checkedTandC: boolean;
  checkboxError: boolean;
  checkboxErrorText: boolean;
  showModalTerms: boolean;
  termsAndConditions: any;
  showModalPrivacy: boolean;
  privacyAndPolicy: any;
  roleIdApi: any;
  apiRoldId: any;
  storeId: number;
  setSelectedOptionId: any;
  confirmPasswordMismatch: any;
  waitingLabel: string;
  waitingSublabel: string;
  registerTextBefore: string;
  registertextAfter: string;
  countryCodeError: string;
  otpVerificationToken: string;
  headingText: string;
  subLineText: string;
  otp: string;
  otpEr: string;
  otpTimer: number;
  attemptsOver:boolean;
  attempts: string;
  attemptMessageShow: boolean;
  isBTNDisabled: boolean;
  selectedCountryCode: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  phoneNumberArray = [
    { countryName: "India", countryCode: "+91", flag: images.india },
    { countryName: "US", countryCode: "+1", flag: images.unitedStates },
    { countryName: "UK", countryCode: "+44", flag: images.unitedKingdom },
  ];
  private otpTimer: NodeJS.Timeout | any;
  arrayHolders: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  emailRegexTest: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  passwordText: string;
  btnTextSignUp: string;
  currentCountryCode: any;
  userSignupCallId: string = "";
  sendOtpCallId: string = "";
  verifyOtpAPIId: string = "";
  roleIdGetApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      company: "",
      ssnId: "",
      taxId: "",
      address: "",
      otpAuthToken: "",
      data: [],
      passwordHelpingText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enablePasswordFieldHR: true,
      enableReTypePasswordFieldHR: true,
      countryCodeSelected: "",
      phone: "",
      selectedOption: null,
      showForm: true,
      passwordVisibility: false,
      confirmPasswordVisibility: false,
      emailErrorHR: null,
      showEmailErrorHR: false,
      passwordErrorHR: null,
      showPasswordErrorHR: false,
      apiData: false,
      userSignupResponse: {},
      errorFirstName: null,
      showFirstNameError: false,
      errorLastName: null,
      showLastNameError: false,
      errorAddress: null,
      showAddressError: false,
      errorCompany: null,
      showCompanyError: false,
      errorPhone: null,
      showPhoneError: false,
      errorTax: null,
      showTaxError: false,
      errorConfirmPassword: null,
      showConfirmPasswordError: false,
      errorSsnEmpty: null,
      errorSsnTax: null,
      showSsnErrorEmpty: false,
      showSsnErrorTax: false,
      isModal: false,
      selectorValue: "I am",
      checkedTandC: false,
      checkboxError: false,
      checkboxErrorText: false,
      showModalTerms: false,
      termsAndConditions: configJSON.termConditionsText,
      showModalPrivacy: false,
      privacyAndPolicy: configJSON.privacyPolicy,
      roleIdApi: [],
      apiRoldId: 0,
      storeId: 0,
      setSelectedOptionId: "",
      confirmPasswordMismatch: null,
      waitingLabel: configJSON.waitingLabelLine,
      waitingSublabel: configJSON.waitingSubLabelLine,
      registerTextBefore: configJSON.registerDetailsBefore,
      registertextAfter: configJSON.registerDetailsAfter,
      countryCodeError: "",
      otpVerificationToken:"",
      subLineText: configJSON.registerDetailsBefore,
      attemptsOver:false,
      otpEr:"",
      otp:"",
      attemptMessageShow:true,
      attempts:"3 OTP attempts left.",
      otpTimer:60,
      headingText: "Create an Account",
      isBTNDisabled: true,
      selectedCountryCode: "+1",
      // Customizable Area End
    };

    // Customizable Area Start
    this.handleBackClick = this.handleBackClick.bind(this);
    this.arrayHolders = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");
    this.emailRegexTest = new RegExp(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/);

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.passwordText = AppConstant.PASSWORD_TEXT;

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.userSignupCallId:
            this.apiCallingfunction(responseJson);
            break;
          case this.roleIdGetApi:
            this.setState({ roleIdApi: responseJson.data });
            break;
          case this.verifyOtpAPIId:
            this.verifyResponseJson(responseJson);
            break;
          case this.sendOtpCallId:
            if (responseJson.meta && responseJson.meta.token) {
              this.setState({
                otpVerificationToken: responseJson.meta.token,
                headingText: configJSON.headerTitleOtp,
                subLineText: configJSON.subHeaderTitleOtp,
              });
            }
            this.startTimer();
            break;
          default:
            // Handle other cases or do nothing
            break;
        }
      }
    }    
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.roleIdApi();
  }

  apiCallingfunction = (responseJson: any) => {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      this.setState({ userSignupResponse: responseJson, showForm:false });
      this.handleSendOtp();
    } else if (responseJson && responseJson.errors) {
      let errors = {
        email:"",
        full_phone_number:"",
        password:"",
        tax_id:"",
        ssn:""
      }
      responseJson.errors.map((error:{})=>{
        errors = {
          ...errors,
          ...error
        }
      })
      let errorStates = {
        errorSsnTax: this.state.errorSsnTax,
        showSsnErrorTax: this.state.showSsnErrorTax,
        emailErrorHR: errors.email,
        showEmailErrorHR: !!errors.email,
        errorPhone: errors.full_phone_number,
        showPhoneError: !!errors.full_phone_number,
        passwordErrorHR: errors.password,
        showPasswordErrorHR: !!errors.password,
        errorTax: errors.tax_id,
        showTaxError: !!errors.tax_id,
        apiData: false,
      }
      if(!!errors.ssn){
        errorStates.errorSsnTax = errors.ssn;
        errorStates.showSsnErrorTax = !!errors.ssn;
      }
      this.setState(errorStates);
    }
  };

  phoneValidation = () => {
    if (!this.state.phone) {
      this.setState({
        errorPhone: configJSON.errorPhoneNumber,
        showPhoneError: true,
        apiData: false,
      });
    }
  }
  
  registerButtonValidation = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.phoneValidation();
    if (this.state.firstName.length === 0) {
      this.setState({
        errorFirstName: configJSON.errorFirstName,
        showFirstNameError: true,
        apiData: false,
      });
    }

    if (this.state.lastName.length === 0) {
      this.setState({
        errorLastName: configJSON.errorLastName,
        showLastNameError: true,
        apiData: false,
      });
    }

    if (this.state.email.length === 0) {
      this.setState({ emailErrorHR: configJSON.errorEmailMessage, showEmailErrorHR: true, apiData: false });
    }


    if (!this.emailRegexTest.test(this.state.email)) {
      this.setState({ emailErrorHR: configJSON.errorEmailNotValid, showEmailErrorHR: true, apiData: false });
    }

    if (this.state.password.length === 0) {
      this.setState({ passwordErrorHR: configJSON.errorPasswordMessage });
      this.setState({ showPasswordErrorHR: true, apiData: false });
    }

    if (this.state.reTypePassword.length === 0) {  
      this.setState({
        confirmPasswordMismatch: configJSON.errorEnterConfirmPassword,
        showConfirmPasswordError: true,
        apiData: false,
      });
    }

    if (this.state.company.length === 0) {
      this.setState({
        errorCompany: configJSON.errorCompanyName,
        showCompanyError: true,
        apiData: false,
      });
    }
    this.ssnRoleCondition();
    if (this.state.password && this.state.password !== this.state.reTypePassword) {
      this.setState({
        showConfirmPasswordError: true,
        confirmPasswordMismatch: configJSON.errorConfirmPasswordMismatch,
        apiData: false,
      });
      return;
    } else {
      this.setState({ apiData: true });
    }

    this.ssnCondition();


    if (this.state.checkedTandC !== true) {
      this.setState({
        checkboxError: true,
        checkboxErrorText: true,
        apiData: false,
      });
      return;
    } else {
      this.setState({ apiData: true });
    }

    this.setState({ apiData: true }, () => {
      this.signupApiData();
    });
  };

  ssnCondition = () => {
    if (this.state.taxId.length === 0 && this.state.ssnId !== "AAA-BB-CCCC") {
      this.setState({
        errorTax: configJSON.errorTaxId,
        showTaxError: true,
        apiData: false,
      });
    } else {
      this.setState({ apiData: true });
    }
  };

  handleSendOtp = () => {
    const header = {
      "Content-Type": "application/json",
    };
    const httpBody = {
      data:{
        type: "email",
        attributes:{ 
          email: this.state.email
        } 
      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.sendOtpCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.sendOtpUrl);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  ssnRoleCondition = () => {
    if (
      this.state.ssnId.length === 0 &&
      this.state.selectedOption === "Employee (Member)"
      ) {
        this.setState({
          showSsnErrorEmpty:true,
          apiData: false,
        });
      } else {
        this.setState({ apiData: true });
      }
    };
    
    signupApiData = () => {
      const header = {
        "Content-Type": "application/json",
    };
    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          address: this.state.address,
          email: this.state.email,
          password: this.state.password,
          confirm_password: this.state.reTypePassword,
          ...(this.state.selectedCountryCode.length > 0 && {
            full_phone_number: `${this.state.selectedCountryCode +
              this.state.phone}`,
          }),
          company: this.state.company,
          tax_id: this.state.taxId,
          role_id: this.state.storeId,
          ssn: this.state.ssnId,
          //"hospital_name": "bw"
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userSignupCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/accounts"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.userSignupCallId;
  };

  roleIdApi = () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.roleIdGetApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_roles_permissions/get_all_roles"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleModal = (key: keyof S, value: boolean) => {
    this.setState({
      ...this.state,
      [key]: value,
    });
  };

  handleOptionChange = (val: any) => {
    this.setState({
      selectedOption: val.name,
      showForm: true,
      // storeId: event.target.value,
      isModal: false,
      selectorValue: val.name,
      storeId: val.id,
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      password: "",
      reTypePassword: "",
      company: "",
      taxId: "",
      ssnId: "",
      showFirstNameError: false,
      showLastNameError: false,
      showEmailErrorHR: false,
      showPhoneError: false,
      showPasswordErrorHR: false,
      showConfirmPasswordError: false,
      showCompanyError: false,
      showTaxError: false,
      showSsnErrorTax: false,
      showSsnErrorEmpty: false,
      checkboxError: false,
      checkboxErrorText: false,
      checkedTandC: false,
      countryCodeError:"",
      selectedCountryCode: "+1",
      subLineText: val && val.name ? configJSON.registerDetailsAfter : this.state.subLineText
    });
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      passwordVisibility: !prevState.passwordVisibility,
    }));
  };

  handleInput = (e: any) => {
    const { name } = e.target;
    let value = e.target.value;
    if (name === "firstName" && !value.startsWith(" ")) {
      const values = value.replace(/[1-9]/g, "");
      this.setState({ firstName: values, showFirstNameError: false });
    }
    if (name === "lastName" && !value.startsWith(" ")) {
      const values = value.replace(/[1-9]/g, "");
      this.setState({ lastName: values, showLastNameError: false });
    }
    if (name === "company" && !value.startsWith(" ")) {
      const values = value.replace(/[1-9]/g, "");
      this.setState({ company: values, showCompanyError: false });
    }
    if (name === "email" && !value.startsWith(" ")) {
      this.setState({
        email: value.trim(),
        showEmailErrorHR: false,
      });
    }
    if (name === "password") {
      this.setState({
        password: value.trim(),
        showPasswordErrorHR: false,
      });
    }
    if (name === "reTypePassword") {
      this.setState({ reTypePassword: value.trim(), showConfirmPasswordError: false });
    }
    if (name === "phone" && value.length<=10 && !value.startsWith(" ")) {
      this.setState({ phone: value, showPhoneError: false });
    }
  };

  handletaxId = (e: any) => {
    const { name, value } = e.target;
    if (name === "taxId") {
      let formattedTaxId = value.replace(/\D/g, "");
      if (formattedTaxId.length > 2) {
        formattedTaxId =
          formattedTaxId.slice(0, 2) + "-" + formattedTaxId.slice(2);
      }
      if (formattedTaxId.length > 10) {
        formattedTaxId = formattedTaxId.slice(0, 10);
      }
      this.setState({ taxId: formattedTaxId, showTaxError: false, ssnId:"",errorSsnTax:""});
    }
  };

  handleSsnId = (e: any) => {
    const { name, value } = e.target;
    if (name === "ssnId") {
      let formattedSsnId = value.replace(/\D/g, "").toUpperCase();
      if (formattedSsnId.length > 3) {
        formattedSsnId =
          formattedSsnId.slice(0, 3) + "-" + formattedSsnId.slice(3);
      }
      if (formattedSsnId.length > 6) {
        formattedSsnId =
          formattedSsnId.slice(0, 6) + "-" + formattedSsnId.slice(6);
      }
      if (formattedSsnId.length > 11) {
        formattedSsnId = formattedSsnId.slice(0, 11);
      }
      this.setState({ ssnId: formattedSsnId, showSsnErrorEmpty: false });
    }
  };

  handleClickShowPasswordHR = () => {
    this.setState({
      enablePasswordFieldHR: !this.state.enablePasswordFieldHR,
    });
  };

  handleClickShowConfirmPasswordHR = () => {
    this.setState({
      enableReTypePasswordFieldHR: !this.state.enableReTypePasswordFieldHR,
    });
  };

  toggleConfirmPasswordVisibility = () => {
    this.setState((prevState) => ({
      confirmPasswordVisibility: !prevState.confirmPasswordVisibility,
    }));
  };

  handleBackClick = () => {
    this.setState({
      showForm: false,
    });
    this.props.navigation.navigate("EmailAccountLoginSignupBlock");
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedTandC: value, checkboxError: false });
  };

  goToPrivacyAndPolicy() {
    const msgs: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msgs.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msgs);
  }

  goToTermsAndConditions() {
    const msgs: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msgs.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msgs);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmailCheck(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }


    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number:
        "+" + this.state.countryCodeSelected + this.state.phone,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsmsgs = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsmsgs.messageId;

    getValidationsmsgs.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsmsgs.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsmsgs.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsmsgs.id, getValidationsmsgs);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  setOtpValue = (text: string) => {
    if (text.length === 4) {
      this.setState({
        isBTNDisabled: false
      });
    } else {
      this.setState({
        isBTNDisabled: true
      });
    }
    this.setState({
      otp: text,
      otpEr: ""
    });
  };

  checkCondition = (condition: boolean, ifTrue: any, ifFales: any) =>
  condition ? ifTrue : ifFales;

  getStringTime = (time: number) => {
    if (time > 9) {
      return time;
    }
    return `0${time}`;
  };

  ResendOTPHandle = () => {
    this.stopTimer();
    this.setState({
      otpTimer: 60,
      attemptsOver: false,
      attempts: "3 OTP attempts left.",
      otpEr: ""
    });
    this.handleSendOtp();
  };

  stopTimer = () => {
    if (this.otpTimer) {
      clearInterval(this.otpTimer);
    }
  };

  startTimer = () => {
    this.otpTimer = setInterval(() => {
      this.setState(
        prevState => ({
          otpTimer: prevState.otpTimer - 1
        }),
        () => {
          if (this.state.otpTimer === 0) {
            this.stopTimer();
          }
        }
      );
    }, 1000);
  };

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  handleVerifyOtp = () => {
    const header = {
      "Content-Type": "application/json",
      token:this.state.otpVerificationToken
    };
    const httpBody = {
      data:{
        type:"email",
        attributes:{
          pin:this.state.otp
        } 
      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.verifyOtpAPIId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.verifyOtpUrl);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"POST");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  verifyResponseJson = (response: { errors?: { [key: string]: string; }[], data: {} }) => {
    const { errors } = response;
    if (errors && errors.length > 0) {
      const firstError = errors[0];
      if(typeof firstError === 'object' && Object.keys(firstError).length){
        const firstKey = Object.keys(firstError)[0];
        const errorMsg = firstError[firstKey] || "";
        this.setState({ otpEr: errorMsg });
      }
    } else {
      this.props.navigation.navigate("RegistrationWaitingScreen");
    }
  };
  

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField,
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this.txtInputConfirmPasswordProps.secureTextEntry;
    },
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnSignUpProps = {
    onPress: () => this.createAccount(),
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyAndPolicy(),
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndConditions(),
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    },
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address",
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    },
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    },
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  webStyle: any = {
    inputBoxesHR: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginTop: "24px",
    },
    inputBoxesErrorHR: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginTop: "24px",
      border: "1px solid red",
    },
    inputBoxesMember: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      width: "400px",
      marginBottom: "5px",
    },
    inputBoxesErrorMember: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginBottom: "5px",
      border: "1px solid red",
      width: "400px",
    },
    inputBoxesTpa: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      width: "400px",
      marginBottom: "5px",
      marginTop: "10px",
    },
    inputBoxesErrorTpa: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginBottom: "5px",
      border: "1px solid red",
      width: "400px",
    },
    inputBoxesCost: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      width: "400px",
      marginBottom: "5px",
      marginTop: "10px",
    },
    inputBoxesErrorCost: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginBottom: "5px",
      border: "1px solid red",
      width: "400px",
    },
    inputBoxesHealthcare: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      width: "400px",
      marginBottom: "5px",
      marginTop: "10px",
    },
    inputBoxesErrorHealthcare: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginBottom: "5px",
      border: "1px solid red",
      width: "400px",
    },
    inputBoxesStoploss: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      width: "400px",
      marginBottom: "5px",
      marginTop: "10px",
    },
    inputBoxesErrorStoploss: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginBottom: "5px",
      border: "1px solid red",
      width: "400px",
    },
    inputBoxesBroker: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      width: "400px",
      marginBottom: "5px",
      marginTop: "10px",
    },
    inputBoxesErrorBroker: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginBottom: "5px",
      border: "1px solid red",
      width: "400px",
    },
    registerFormButton: {
      marginTop: "50px",
      marginBottom: "1px",
      marginLeft: "1px",
      backgroundColor: "#4166F8",
      color: "white",
      fontFamily: "Roboto, sans-serif",
      fontWeight: "bold",
      textTransform: "capitalize",
      width: "100%",
      alignContent: "center",
      height: "58px",
      borderRadius: "10px",
      fontSize: "18px",
    },
    inputBoxFirstName: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginTop: "24px",
    },
    inputBoxFirstNameError: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginTop: "24px",
      border: "1px solid red",
    },
    inputBoxLastName: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginTop: "24px",
    },
    inputBoxLastNameError: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginTop: "24px",
      border: "1px solid red",
    },
    inputBoxPhoneError: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginTop: "24px",
      border: "1px solid red",
    },
    inputBoxPhone: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginTop: "24px",
    },
    inputBoxCompanyError: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginTop: "24px",
      border: "1px solid red",
    },
    inputBoxCompany: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginTop: "24px",
    },
    inputBoxTaxError: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginTop: "24px",
      border: "1px solid red",
    },
    inputBoxTax: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginTop: "24px",
    },
    inputBoxConfirmPasswordError: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginTop: "24px",
      border: "1px solid red",
    },
    inputBoxConfirmPassword: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginTop: "24px",
    },
    inputBoxSsnError: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginTop: "24px",
      border: "1px solid red",
    },
    inputBoxSsn: {
      borderRadius: "10px",
      backgroundColor: "#eaeef8",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "18px",
      marginTop: "24px",
    },
    mainCheckbox: {
      display: "flex",
      marginTop: "24px"
    },
    checkbox: {
      // color: "#a7a7a7",
      // marginLeft: "-10px",
      // marginTop: '5px',
      width: "24px",
    },
    checboxText: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: "14px",
      marginTop: "10px",
      marginBottom: "10px",
      marginLeft: "7px",
    },
    tncText: {
      color: "#4166f8",
      textDecoration: "underline",
      cursor: "pointer",
    },
  };
  // Customizable Area End
}
