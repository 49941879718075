import { IBlock } from "../../../../framework/src/IBlock";
import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Column } from "../../../../components/src/CustomTable.web";
import { IconButton, Box } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { FilterChild } from "../../../../components/src/CustomFilterPopup.web";
import { IClassDetailModel, initialClassDetailModelData } from "./ClientEmpClassModel";
import { SelectedEmployerInterface } from "../AllRoleLandingPageController.web";
export const images = require("../../../MemberDirectory2/src/assets");


// Customizable Area Start
// Customizable Area End

export const configJSON = require(".././config");

export interface Props {
    id: string;
    navigation: any;
    // Customizable Area Start
    selectedEmployer: SelectedEmployerInterface[]
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    rowData: RowData[];
    filterChildList: FilterChild[];
    tableMenuAnchorEl: HTMLElement | null;
    isEditDivision: boolean;
    isOpenEditDivisionDialog: boolean;
    isEditClass: boolean;
    isOpenEditClassDialog: boolean;
    classDetails: IClassDetailModel;
    isClassDetailSaved: boolean;
    searchValue: string;
    openFilter: boolean;
    classList: Class[];
    // Customizable Area End
}
interface Class {
    key?: string;
    className?: string;
    clientName?: string;
    employerName?: string;
    groupName?: string;
    divisionName?: string;
    locationName?: string;
}

interface RowData {
    key: string;
    className: string;
    clientName: string;
    employerName: string;
    groupName: string;
    divisionName: string;
    locationName: string
}




interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

const ClassList = [
    {
        key: "1",
        className: "Management1",
        clientName: "Vin Diese",
        employerName: "-",
        groupName: "-",
        divisionName: "Division1",
        locationName: "America",
    },
    {
        key: "2",
        className: "Management2",
        clientName: "Dwayne Johnson",
        employerName: "-",
        groupName: "-",
        divisionName: "Division2",
        locationName: "Germany",
    },


];

const documentList = ClassList.map((item) => ({
    key: item.key,
    className: item.className,
    clientName: item.clientName,
    employerName: item.employerName,
    groupName: item.groupName,
    divisionName: item.divisionName,
    locationName: item.locationName
}));

export default class ClientEmployerClassController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    tabMenuList = [
        "Groups",
        "Enrollment",
        "Enroll Config",
        "Medical Review",
        "Pay Claims",
        "Recon",
        "Invoice",
        "Payroll Invoice",
        "Case Billing",
        "Documents",
    ];
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        const ClassName = [...new Set(ClassList.map((list) => list.className))].map(
            (list) => ({
                key: list,
                isChecked: false,
            })
        );

        const GroupName = [...new Set(ClassList.map((list) => list.groupName))].map(
            (list) => ({
                key: list,
                isChecked: false,
            })
        );

        const clientName = [...new Set(ClassList.map((list) => list.clientName))].map(
            (list) => ({
                key: list,
                isChecked: false,
            })
        );

        const employerName = [...new Set(ClassList.map((list) => list.employerName))].map(
            (list) => ({
                key: list,
                isChecked: false,
            })
        );

        const LocationName = [...new Set(ClassList.map((list) => list.locationName))].map(
            (list) => ({
                key: list,
                isChecked: false,
            })
        );

        const DivisionName = [...new Set(ClassList.map((list) => list.divisionName))].map(
            (list) => ({
                key: list,
                isChecked: false,
            })
        );



        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            classList: ClassList,
            isEditDivision: false,
            rowData: documentList,
            tableMenuAnchorEl: null,
            isClassDetailSaved: false,
            isEditClass: false,
            isOpenEditDivisionDialog: false,
            classDetails: initialClassDetailModelData,
            isOpenEditClassDialog: false,
            searchValue: "",
            openFilter: false,
            filterChildList: [
                {
                    key: "ClassName",
                    isOpen: false,
                    searchString: "",
                    viewAll: false,
                    list: ClassName,
                    mainList: ClassName,
                },
                {
                    key: "ClientName",
                    isOpen: false,
                    searchString: "",
                    viewAll: false,
                    list: clientName,
                    mainList: clientName,
                },
                {
                    key: "employerName",
                    isOpen: false,
                    searchString: "",
                    viewAll: false,
                    list: employerName,
                    mainList: employerName,
                },
                {
                    key: "GroupName",
                    isOpen: false,
                    searchString: "",
                    viewAll: false,
                    list: GroupName,
                    mainList: GroupName,
                },
                {
                    key: "DivisionName",
                    isOpen: false,
                    searchString: "",
                    viewAll: false,
                    list: DivisionName,
                    mainList: DivisionName,
                },
                {
                    key: "LocationName",
                    isOpen: false,
                    searchString: "",
                    viewAll: false,
                    list: LocationName,
                    mainList: LocationName,
                },
            ],
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start


        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);


        // Customizable Area Start
        // Customizable Area End
    }


    // Customizable Area Start
    tableColumns: Column[] = [
        {
            id: "className",
            label: "Class Name",
            headerAlign: "center",
            align: "center",
        },
        {
            id: "clientName",
            label: "Client Name",
            headerAlign: "center",
            align: "center",
        },
        {
            id: "employerName",
            label: "Employer Name",
            headerAlign: "center",
            align: "center",
        },
        {
            id: "groupName",
            label: "Group Name",
            headerAlign: "center",
            align: "center",
        },
        {
            id: "divisionName",
            label: "Division Name",
            headerAlign: "center",
            align: "center",
        },
        {
            id: "locationName",
            label: "Location Name",
            headerAlign: "center",
            align: "center",
            format: (row: any) => {
                return (
                    <>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {row.locationName}
                            <IconButton
                                style={{ padding: 0 }}
                                aria-label="more"
                                aria-controls="long-menu"
                                data-test-id="menu_icon"
                                aria-haspopup="true"
                                onClick={this.handleOpenTableMenu}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        </Box>
                    </>
                );
            },
        },
    ];

    toggleEditDivisionDialog = (open: boolean, isEdit: boolean) => {
        this.setState({
            ...this.state,
            isEditDivision: isEdit,
            tableMenuAnchorEl: null,
            isOpenEditDivisionDialog: open,
        });
    };

    toggleEditClassDialog = (open: boolean, isEdit: boolean) => {
        this.setState({
            ...this.state,
            isEditClass: isEdit,
            tableMenuAnchorEl: null,
            isOpenEditClassDialog: open,
        });
    };

    saveClassDetails = (grpDetails: IClassDetailModel) => {
        this.setState({ ...this.state, isClassDetailSaved: true });
    }

    handleCloseTableMenu = () => {
        this.setState({ ...this.state, tableMenuAnchorEl: null });
    };

    handleOpenTableMenu = (event: any) => {
        this.setState({ ...this.state, tableMenuAnchorEl: event.currentTarget });
    };

    onSearchInputChange = (e: any) => {
        const searchKey = e.target.value;
        const newDocumentList = documentList.filter((record) => {
            return (
                record.className.toLowerCase().includes(searchKey.toLowerCase()) ||
                record.clientName.toLowerCase().includes(searchKey.toLowerCase()) ||
                record.employerName.toLowerCase().includes(searchKey.toLowerCase()) ||
                record.groupName.toLowerCase().includes(searchKey.toLowerCase()) ||
                record.divisionName.toLowerCase().includes(searchKey.toLowerCase()) ||
                record.locationName.toLowerCase().includes(searchKey.toLowerCase())
            );
        });
        this.setState({ searchValue: searchKey, rowData: newDocumentList });
    };

    handleClassFilterClick = () => {
        this.setState({ openFilter: !this.state.openFilter });
    };

    handleClassFilterOrSearchOnChange = (newState: FilterChild[]) => {
        this.setState({ filterChildList: newState });
        const filterString = ([] as string[]).concat(
            ...newState.map((n) =>
                n.mainList.filter((l) => l.isChecked).map((l) => l.key)
            )
        );

        const newClassListAfterFilter = ClassList.filter((record) => {
            const filterStringLowerCase = filterString.map(filter => filter.toLowerCase());

            if (filterStringLowerCase.length === 0) {
                return true;
            }

            return filterStringLowerCase.some(filter => (
                record.className.toLowerCase().includes(filter) ||
                record.clientName.toLowerCase().includes(filter) ||
                record.employerName.toLowerCase().includes(filter) ||
                record.groupName.toLowerCase().includes(filter) ||
                record.divisionName.toLowerCase().includes(filter) ||
                record.locationName.toLowerCase().includes(filter)
            ));
        });


        const searchValue = this.state.searchValue.toLowerCase();

        const newClassList = newClassListAfterFilter.filter((record) => {
            const propertiesToSearch = [
                record.className,
                record.clientName,
                record.employerName,
                record.groupName,
                record.divisionName,
                record.locationName,
            ];

            return propertiesToSearch.some(property =>
                property.toLowerCase().includes(searchValue)
            );
        });
        this.setState({ rowData: newClassList });
    };


    // Customizable Area End
}
