import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Column } from "../../../components/src/CustomTable.web";

export const configJSON = require("./config");
export const images = require("./assets");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  searchValue: string;
  rowData: RowData[];
  openAddDialog: boolean;
  addedFile: any;
  addedFileError: string;
  descriptionValue: string;
  descriptionError: string;
  isDataSubmitted: boolean;
  isFormChange: boolean;
  isSubmitOnce: boolean;
}

interface SS {
  id: any;
}

interface RowData {
  docId: string;
  fileName: string;
  description: string;
  userName: string;
  createDate: string;
}

const documentList = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => ({
  docId: `${item}651621321520`,
  fileName: `${item} Document`,
  description: `${item} This is the description for Document`,
  userName: "John Doe",
  createDate: `0${item}/07/2023`,
}));

export default class MyDocumentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  defaultValue = {
    addedFile: "",
    descriptionValue: "",
  };

  defaultError = {
    addedFileError: "",
    descriptionError: "",
  };

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      ...this.defaultValue,
      ...this.defaultError,
      searchValue: "",
      rowData: documentList,
      openAddDialog: false,
      isDataSubmitted: false,
      isFormChange: false,
      isSubmitOnce: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  tableColumns: Column[] = [
    {
      id: "docId",
      label: "Doc ID",
      headerAlign: "center",
      align: "center",
      format: (row: RowData) => {
        return (
          <div className="folder-image-doc-id">
            <img
              src={images.folderImage}
              alt="folder image"
              className="mr-20"
            />
            {row.docId}
          </div>
        );
      },
    },
    {
      id: "fileName",
      label: "File Name",
      headerAlign: "center",
      align: "center",
    },
    { id: "description", label: "Description" },
    { id: "userName", label: "User Name" },
    { id: "createDate", label: "Create Date" },
  ];

  onSearchInputChange = (e: React.ChangeEvent<any>) => {
    const searchKey = e.target.value;
    const newDocumentList = documentList.filter((record) => {
      return (
        record.docId.toLowerCase().includes(searchKey.toLowerCase()) ||
        record.fileName.toLowerCase().includes(searchKey.toLowerCase()) ||
        record.description.toLowerCase().includes(searchKey.toLowerCase()) ||
        record.userName.toLowerCase().includes(searchKey.toLowerCase()) ||
        record.createDate.toLowerCase().includes(searchKey.toLowerCase())
      );
    });
    this.setState({ searchValue: searchKey, rowData: newDocumentList });
  };

  handleClickOpen = () => {
    this.setState({
      openAddDialog: true,
      isFormChange: false,
      isSubmitOnce: false,
    });
  };

  handleClose = () => {
    this.setState({
      openAddDialog: false,
      ...this.defaultValue,
      ...this.defaultError,
    });
  };

  validationCheck = (isSubmit: boolean) => {
    if (!isSubmit) {
      return true;
    }
    this.setState({
      ...this.defaultError,
    });
    let validate = true;

    if (!this.state.descriptionValue) {
      this.setState({
        descriptionError: configJSON.descriptionRequired,
      });
      validate = false;
    } else if (
      this.state.descriptionValue.length > configJSON.descriptionMaxLength
    ) {
      this.setState({
        descriptionError: configJSON.maxLengthExist,
      });
      validate = false;
    }
    if (!this.state.addedFile) {
      this.setState({
        addedFileError: configJSON.fileRequired,
      });
      validate = false;
    }

    return validate;
  };

  handleAddDocument = () => {
    this.setState({ isSubmitOnce: true });
    if (this.validationCheck(true)) {
      this.setState({
        isDataSubmitted: true,
      });
    }
  };

  handleSuccessClose = () => {
    this.setState({
      isDataSubmitted: false,
      openAddDialog: false,
      ...this.defaultValue,
      ...this.defaultError,
    });
  };

  handleFileOnChange = (file: any) => {
    this.setState({ addedFile: file, isFormChange: true }, () =>
      this.validationCheck(this.state.isSubmitOnce)
    );
  };

  handleDescriptionOnChange = (e: any) => {
    if (e.target.value !== " ") {
      this.setState(
        { descriptionValue: e.target.value, isFormChange: true },
        () => this.validationCheck(this.state.isSubmitOnce)
      );
    }
  };
}
