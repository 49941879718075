// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import AllRoleLandingPage from '../../blocks/landingpage/src/AllRoleLandingPage.web';
import EmailAccountLoginSignupBlock from '../../blocks/email-account-login/src/EmailAccountLoginSignupBlock.web';
import EmailAccountRegistrationBlock from '../../blocks/email-account-registration/src/EmailAccountRegistrationBlock.web';
import RegistrationWaitingScreen from '../../blocks/email-account-registration/src/RegistrationWaitingScreen.web';
import ForgotPasswordBlock from '../../blocks/forgot-password/src/ForgotPasswordBlock.web';
import {ToastContainer} from "react-toastify"
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import 'react-toastify/dist/ReactToastify.css';

const routeMap = {
  EmailAccountLoginSignupBlock: {
    component: EmailAccountLoginSignupBlock,
    path: '/',
    exact: true
  },
  EmailAccountRegistrationBlock: {
    component: EmailAccountRegistrationBlock,
    path: '/EmailAccountRegistrationBlock'
  },
  RegistrationWaitingScreen: {
    component: RegistrationWaitingScreen,
    path: '/RegistrationWaitingScreen'
  },
  ForgotPasswordBlock: {
    component: ForgotPasswordBlock,
    path: '/ForgotPasswordBlock'
  },
  AllRoleLandingPage: {
    component: AllRoleLandingPage,
    path: '/Home'
  },
  MyDetails: {
    component: AllRoleLandingPage,
    path: 'MyDetails',
    modal: true
  },
  MyBenefits: {
    component: AllRoleLandingPage,
    path: 'MyBenefits',
    modal: true
  },
  MyClaims: {
    component: AllRoleLandingPage,
    path: 'MyClaims',
    modal: true
  },
  MyDocuments: {
    component: AllRoleLandingPage,
    path: 'MyDocuments',
    modal: true
  },
  MyEnrollments: {
    component: AllRoleLandingPage,
    path: 'MyEnrollments',
    modal: true
  },
  MyNotifications: {
    component: AllRoleLandingPage,
    path: 'MyNotifications',
    modal: true
  },
  FindHealthcare: {
    component: AllRoleLandingPage,
    path: 'FindHealthcare',
    modal: true
  },
  FindPharmacy: {
    component: AllRoleLandingPage,
    path: 'FindPharmacy',
    modal: true
  },
  Help: {
    component: AllRoleLandingPage,
    path: 'Help',
    modal: true
  },
  EmployerDetails: {
    component: AllRoleLandingPage,
    path: 'EmployerDetails',
    modal: true
  },
  MyCoverageHistory: {
    component: AllRoleLandingPage,
    path: 'MyBenefits/MyCoverageHistory',
    modal: true
  },
  EmployerDocuments: {
    component: AllRoleLandingPage,
    path: 'EmployerDocuments',
    modal: true
  },
  EmployerDivisions: {
    component: AllRoleLandingPage,
    path: 'EmployerDivisions'
  },
  EmployerMembers: {
    component: AllRoleLandingPage,
    path: 'EmployerMembers'
  },
  ClientLandingPage: {
    component: AllRoleLandingPage,
    path: 'ClientLandingPage',
    modal: true
  },
  Employers: {
    component: AllRoleLandingPage,
    path: 'Employers',
    modal: true
  },
  Groups: {
    component: AllRoleLandingPage,
    path: 'Employers/Groups',
    modal: true
  },
  Divisions: {
    component: AllRoleLandingPage,
    path: 'Employers/Divisions',
    modal: true
  },
  Locations: {
    component: AllRoleLandingPage,
    path: 'Employers/Locations',
    modal: true
  },
  Class: {
    component: AllRoleLandingPage,
    path: 'Employers/Class',
    modal: true
  },
  Union: {
    component: AllRoleLandingPage,
    path: 'Union',
    modal: true
  },
  Notifications: {
    component: AllRoleLandingPage,
    path: 'Notifications',
    modal: true
  },
  Reports: {
    component: AllRoleLandingPage,
    path: 'Reports',
    modal: true
  },
  Release: {
    component: AllRoleLandingPage,
    path: 'Release',
    modal: true
  },
  Members: {
    component: AllRoleLandingPage,
    path: 'Members',
    modal: true
  },
  More: {
    component: AllRoleLandingPage,
    path: 'More',
    modal: true
  },
  SupportHelp: {
    component: AllRoleLandingPage,
    path: 'SupportHelp',
    modal: true
  },
  ExportImport: {
    component: AllRoleLandingPage,
    path: 'ExportImport',
    modal: true
  },
  BroadcastMessage: {
    component: AllRoleLandingPage,
    path: 'BroadcastMessage',
    modal: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ width: '100vw' }}>
        {WebRoutesGenerator({ routeMap, match: { url: '/Home' } })}
        <ModalContainer />
        <ToastContainer />
      </View>
    );
  }
}

export default App;
