import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Column } from "../../../components/src/CustomTable.web";
import { Button, IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  IEmployerDivisionModel,
  initialEmployerDivisionModelData,
} from "./EmployerDetailModel";

interface IDivisionRowData {
  divisionNumber: string;
  divisionName: string;
  groupName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
}

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  divisionSearchString: string;
  divisionRowItems: IDivisionRowData[];
  tableMenuAnchorEl: HTMLElement | null;
  isOpenEditDivisionDialog: boolean;
  divisionDetail: IEmployerDivisionModel;
  isDivisionDetailSaved: boolean;
  isEditDivision: boolean;
}

interface SS {}

const divisionListData = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((docItem) => ({
  divisionNumber: `65656${docItem}`,
  divisionName: `Premier Plus`,
  groupName: `MBA TPA`,
  addressLine1: "Edward Cullen",
  addressLine2: "FHCP Medi",
  city: "NY",
  state: "FL",
  zip: "292823",
}));

export default class EmployerDivisionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  documentListColumns: Column[] = [
    {
      id: "divisionNumber",
      label: "Division No.",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "divisionName",
      label: "Division Name",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "groupName",
      label: "Group Name",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "addressLine1",
      headerAlign: "left",
      align: "left",
      label: "Address Line 1",
    },
    {
      id: "addressLine2",
      label: "Address Line 2",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "city",
      label: "City",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "state",
      label: "State",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "zip",
      label: "Zip",
      headerAlign: "left",
      align: "left",
    },
    {
      id: "action",
      label: "",
      headerAlign: "left",
      align: "right",
      format: (row: IDivisionRowData) => {
        return (
          <div className="division-table-action-container">
            <Button className="view-member-btn" variant="contained">
              View Members
            </Button>
            <IconButton className="menu-btn" onClick={this.handleOpenTableMenu}>
              <MoreVertIcon fontSize="large" />
            </IconButton>
          </div>
        );
      },
    },
  ];

  constructor(props: Props) {
    super(props);

    this.state = {
      divisionSearchString: "",
      divisionRowItems: divisionListData,
      tableMenuAnchorEl: null,
      isOpenEditDivisionDialog: false,
      divisionDetail: initialEmployerDivisionModelData,
      isDivisionDetailSaved: false,
      isEditDivision: false
    };
  }

  handleOpenTableMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ ...this.state, tableMenuAnchorEl: event.currentTarget });
  };

  handleCloseTableMenu = () => {
    this.setState({ ...this.state, tableMenuAnchorEl: null });
  };

  onSearchDivisionChange = (e: React.ChangeEvent<any>) => {
    const searchParam = e.target.value;
    const updatedDivisionList = divisionListData.filter((division) => {
      return (
        division.divisionNumber
          .toLowerCase()
          .includes(searchParam.toLowerCase()) ||
        division.divisionName
          .toLowerCase()
          .includes(searchParam.toLowerCase()) ||
        division.groupName.toLowerCase().includes(searchParam.toLowerCase()) ||
        division.addressLine1
          .toLowerCase()
          .includes(searchParam.toLowerCase()) ||
        division.addressLine2
          .toLowerCase()
          .includes(searchParam.toLowerCase()) ||
        division.city.toLowerCase().includes(searchParam.toLowerCase()) ||
        division.state.toLowerCase().includes(searchParam.toLowerCase()) ||
        division.zip.toLowerCase().includes(searchParam.toLowerCase())
      );
    });
    this.setState({
      ...this.state,
      divisionSearchString: searchParam,
      divisionRowItems: updatedDivisionList,
    });
  };

  toggleEditDivisionDialog = (open: boolean, isEdit: boolean) => {
    this.setState({
      ...this.state,
      isOpenEditDivisionDialog: open,
      tableMenuAnchorEl: null,
      isEditDivision: isEdit
    });
  };

  saveDivisionDetails = (editedDivisionOb: any) => {
    this.setState({ ...this.state, isDivisionDetailSaved: true });
  };

  handleCloseConfirmationDialog = () => {
    this.setState({ ...this.state, isDivisionDetailSaved: false, isOpenEditDivisionDialog: false, divisionDetail: initialEmployerDivisionModelData });
  };
}
