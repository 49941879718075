import React from "react";
import { Grid, Card, Button, Menu, MenuItem } from "@material-ui/core";

import "./EmployerDetails.web.css";
import EmployerDivisionsController, {
  Props,
} from "./EmployerDivisionsController";
import GenericSearch from "../../../components/src/GenericSearch.web";
import CustomerTable from "../../../components/src/CustomTable.web";
import {
  blackEditIcon,
  companyIcon,
  deleteIcon,
  filterIcon,
  searchIcon,
} from "./assets";
import EditEmployerDivisionDialog from "./EditEmployerDivisionDialog.web";
import GenericSuccessDialog from "../../../components/src/GenericSuccessDialog.web";
import GenericCompanyDetailsCard from "../../../components/src/GenericCompanyDetailsCard.web";

export default class EmployerDivisions extends EmployerDivisionsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div data-test-id="employer-division-wrapper">
        <GenericCompanyDetailsCard
          companyIcon={companyIcon}
          companyName="Infotech pvt ltd"
          companyAddress="8 Moretown, Mountain Road"
        />
        <Grid
          container
          className="emp-documents-container emp-divisions-container"
        >
          <Grid item xs={12} className="search-bar-and-add-btn">
            <GenericSearch
              searchIcon={searchIcon}
              placeholder="Search for Division"
              value={this.state.divisionSearchString}
              onChange={this.onSearchDivisionChange}
              data-test-id="division-search-input"
            />
            <div className="add-document-btn-div">
              <div className="filter-divisions-btn-container">
                <Button>
                  <img src={filterIcon} height={48} />
                </Button>
              </div>
              <Button
                className="add-document-btn add-division-btn"
                onClick={() => this.toggleEditDivisionDialog(true, false)}
                data-test-id="add-division-btn"
              >
                + Add Division
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} className="hr mt-30" />
          <Grid item xs={12} className="division-table-container">
            <CustomerTable
              columns={this.documentListColumns}
              rowData={this.state.divisionRowItems}
              onActionClick={()=>{}}
            />
          </Grid>
        </Grid>
        <Menu
          id="division-table-menu"
          anchorEl={this.state.tableMenuAnchorEl}
          keepMounted
          open={Boolean(this.state.tableMenuAnchorEl)}
          onClose={this.handleCloseTableMenu}
          className="division-table-menu"
        >
          <MenuItem
            className="division-table-menu-item"
            onClick={() => this.toggleEditDivisionDialog(true, true)}
          >
            <span className="menu-item-img">
              <img src={blackEditIcon} />
            </span>
            Edit
          </MenuItem>
          <MenuItem
            className="division-table-menu-item delete-action-item"
            onClick={this.handleCloseTableMenu}
          >
            <span className="menu-item-img">
              <img src={deleteIcon} />
            </span>
            Delete
          </MenuItem>
        </Menu>
        {this.state.isOpenEditDivisionDialog && (
          <EditEmployerDivisionDialog
            isOpen={this.state.isOpenEditDivisionDialog}
            isEdit={this.state.isEditDivision}
            handleClose={() => this.toggleEditDivisionDialog(false, false)}
            divisionDetail={this.state.divisionDetail}
            saveDivisionDetail={this.saveDivisionDetails}
            data-test-id="edit-division-dialog"
          />
        )}
        <GenericSuccessDialog
          open={this.state.isDivisionDetailSaved}
          handleDone={this.handleCloseConfirmationDialog}
        />
      </div>
    );
  }
}
