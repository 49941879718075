import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import LanguageSelector from "./LanguageSelector.web";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  TextField,
  Input,
  Checkbox,
  Select,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';

export const configJSON = require("./config");
const images = require("./assets");

export interface Props {
  navigation: any;
  id: string;
  handleClick?: (navigation: string, key: string) => void;
}
interface BeneficiaryInfoList {
  id: string,
  firstName: string,
  lastName: string,
  relationship: string,
  gender: string,
  beneficiary: string,
  dob: string,
  email: string,
  percentage: string,
  contactNumber: string,
  physicalAddressLine1: string,
  physicalAddressLine2: string,
  physicalCity: string,
  physicalState: string,
  physicalZipcode: string,
  physicalCountry: string,
  mailingAddressLine1: string,
  mailingAddressLine2: string,
  mailingCity: string,
  mailingState: string,
  mailingZipcode: string,
  mailingCountry: string,
}
interface S {
  [state: string]: any;
  memberBenefitsDetails: {
    icon: string;
    background: string;
    textLine1: string;
    textLine2: string;
    label: string;
  }[];

  member_documents: File | null,
  selectedMenu: string;
  selectedChildrenMenu: string;
  isMobileBarDisplay: boolean;
  openChildrenList: string[];
  isEditClick: boolean,
  idCard_request_address: string,
  memberBenefits: string[];
  showMemberFullInfo: boolean;
  benefitSchedule: string,
  open_cancle_confirmation_dialog: boolean;
  isMyIdCardScreenActive: boolean,
  isBenefitStatementScreenActive: boolean,
  idCardScreen_faxNumber: string,
  memberFullInfo: {
    memberInfo_firstName: string;
    memberInfo_lastName: string;
    memberInfo_middleName: string;
    memberInfo_fax1: string;
    memberInfo_fax2: string;
    memberInfo_phoneNumber: string;
    memberInfo_phoneNumberExt: string;
    memberInfo_profile_pic: string | null,
    memberInfo_sameAsPhysicalAddress: boolean;
    memberInfo_linkSsn: string;
    memberInfo_nameSuffix: string;
    memberInfo_namePrefix: string;
    memberInfo_relation: string;
    memberInfo_memberNumber: string;
    memberInfo_gender: string;
    memberInfo_dob: string;
    memberInfo_age: string;
    memberInfo_maritalStatus: string;
    memberInfo_mobileNumber: string;
    memberInfo_clientName: string;
    memberInfo_employerName: string;
    memberInfo_groupName: string;
    memberInfo_divisionName: string;
    memberInfo_locationName: string;
    memberInfo_ssn: string;
    memberInfo_otherId: string;
    memberInfo_otherDesc: string;
    memberInfo_email: string;
    memberInfo_physicalAddressLine1: string;
    memberInfo_physicalAddressLine2: string;
    memberInfo_physicalCity: string;
    memberInfo_physicalState: string;
    memberInfo_physicalZipcode: string;
    memberInfo_physicalCountry: string;
    memberInfo_mailingAddressLine1: string;
    memberInfo_mailingAddressLine2: string;
    memberInfo_mailingCity: string;
    memberInfo_mailingState: string;
    memberInfo_mailingZipcode: string;
    memberInfo_mailingCountry: string;
    memberInfo_salary: string;
    memberInfo_salaryType: string;
    memberInfo_language: string;
  };
  member_firstName: string;
  member_lastName: string;
  member_middleName: string;
  member_fax1: string;
  member_fax2: string;
  member_phoneNumber: string;
  member_phoneNumberExt: string;
  member_sameAsPhysicalAddress: boolean;
  member_linkSsn: string;
  member_nameSuffix: string;
  member_namePrefix: string;
  member_relation: string;
  member_memberNumber: string;
  isFormChanged: boolean,
  member_gender: string;
  member_dob: string;
  member_age: string;
  member_maritalStatus: string;
  member_mobileNumber: string;
  member_clientName: string;
  member_employerName: string;
  member_groupName: string;
  member_divisionName: string;
  member_locationName: string;
  member_ssn: string;
  member_otherId: string;
  member_otherDesc: string;
  member_email: string;
  member_physicalAddressLine1: string;
  member_physicalAddressLine2: string;
  member_physicalCity: string;
  member_physicalState: string;
  member_physicalZipcode: string;
  member_physicalCountry: string;
  member_mailingAddressLine1: string;
  member_mailingAddressLine2: string;
  member_mailingCity: string;
  member_mailingState: string;
  member_mailingZipcode: string;
  member_mailingCountry: string;
  member_salary: string;
  member_salaryType: string;
  languageList: string[];
  openRequestIdCardModal: boolean,
  selectedLanguage: string;
  openLanguageSelectionBox: boolean;
  languageInputValue: string;
  isFormUpdated: boolean;
  dependentInfoList: DependentInfoList[],
  beneficiaryInfoList: BeneficiaryInfoList[],
  editTableItemId: string,
  showMemberInfoEditModal: boolean;
  formActiveStep: number;
  editMemberDetailsStepList: {
    title: string;
    description: string;
  }[];
  memberForm1Errors: {
    [key: string]: string;
  };
  dependentFormErrors: {
    [key: string]: string;
  };
  beneficiaryFormErrors: {
    [key: string]: string;
  };
  editProfilePicFormError: string,
  dependent_firstName: string,
  dependent_lastName: string,
  dependent_relationship: string,
  dependent_gender: string,
  dependent_ssn: string,
  isIdCardBtnClicked: boolean,
  dependent_dob: string,
  dependent_email: string,
  dependent_contactNumber: string,
  dependent_physicalAddressLine1: string,
  dependent_physicalAddressLine2: string,
  dependent_physicalCity: string,
  dependent_physicalState: string,
  dependent_physicalZipcode: string,
  dependent_physicalCountry: string,
  dependent_sameAsPhysicalAddress: boolean,
  dependent_mailingAddressLine1: string,
  dependent_mailingAddressLine2: string,
  dependent_mailingCity: string,
  dependent_mailingState: string,
  dependent_mailingZipcode: string,
  dependent_mailingCountry: string,

  beneficiary_firstName: string,
  beneficiary_lastName: string,
  beneficiary_relationship: string,
  beneficiary_gender: string,
  beneficiary_dob: string,
  beneficiary_percentage: string,
  beneficiary_beneficiary: string,
  beneficiary_physicalAddressLine1: string,
  beneficiary_physicalAddressLine2: string,
  beneficiary_physicalCity: string,
  beneficiary_contactNumber: string,
  beneficiary_physicalState: string,
  beneficiary_physicalZipcode: string,
  beneficiary_physicalCountry: string,
  beneficiary_sameAsPhysicalAddress: boolean,
  beneficiary_mailingAddressLine1: string,
  beneficiary_mailingAddressLine2: string,
  beneficiary_mailingCity: string,
  beneficiary_mailingState: string,
  beneficiary_mailingZipcode: string,
  beneficiary_mailingCountry: string,
}
interface FormField {
  name: string;
  label: string;
  required: boolean;
  pattern?: RegExp;
  error?: string;
  lengthLimitation?: boolean;
}

interface DependentInfoList {
  id: string,
  firstName: string,
  lastName: string,
  relationship: string,
  gender: string,
  ssn: string,
  dob: string,
  email: string,
  contactNumber: string,
  physicalAddressLine1: string,
  physicalAddressLine2: string,
  physicalCity: string,
  physicalState: string,
  physicalZipcode: string,
  physicalCountry: string,
  mailingAddressLine1: string,
  mailingAddressLine2: string,
  mailingCity: string,
  mailingState: string,
  mailingZipcode: string,
  mailingCountry: string,
}

interface SS {
  id: any;
}

export default class LendingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      memberForm1Errors: {},
      dependentFormErrors: {},
      beneficiaryFormErrors: {},
      editProfilePicFormError: "",
      isEditClick: false,
      isIdCardBtnClicked: false,
      openRequestIdCardModal: false,
      isMyIdCardScreenActive: true,
      isBenefitStatementScreenActive: false,
      editTableItemId: "",
      idCard_request_address: "physical",
      member_documents: null,
      isFormChanged: false,
      idCardScreen_faxNumber: "",
      benefitSchedule: "",
      memberBenefitsDetails: [
        {
          icon: images.MedicalBenefitsLogo,
          background: images.MedicalBenefitsBackground,
          textLine1: "medical",
          textLine2: "benefits",
          label: "medical benefits",
        },
        {
          icon: images.DentalBenefitsLogo,
          background: images.DentalBenefitsBackground,
          textLine1: "dental",
          textLine2: "benefits",
          label: "dental benefits",
        },
        {
          icon: images.VisionBenefitsLogo,
          background: images.VisionBenefitsBackground,
          textLine1: "vision",
          textLine2: "benefits",
          label: "vision benefits",
        },
        {
          icon: images.RxBenefitsLogo,
          background: images.RxAndRetirementBenefitsBackground,
          textLine1: "rx",
          textLine2: "benefits",
          label: "rx benefits",
        },
        {
          icon: images.LifeBenefitsLogo,
          background: images.LifeBenefitsBackground,
          textLine1: "life",
          textLine2: "benefits",
          label: "life benefits",
        },
        {
          icon: images.VoluntarySupplementsLogo,
          background: images.VoluntarySupplementsBackground,
          textLine1: "voluntary",
          textLine2: "supplements",
          label: "voluntary supplements",
        },
        {
          icon: images.RetirementBenefitsLogo,
          background: images.RxAndRetirementBenefitsBackground,
          textLine1: "retirement",
          textLine2: "benefits",
          label: "retirement benefits",
        },
        {
          icon: images.HealthSavingAccountLogo,
          background: images.HealthSavingAccountBackground,
          textLine1: "health",
          textLine2: "savings account",
          label: "health savings account",
        },
        {
          icon: images.Health_FSA_BenefitsLogo,
          background: images.Health_FSA_Background,
          textLine1: "health FSA",
          textLine2: "benefits",
          label: "health fsa benefits",
        },
        {
          icon: images.HRA_BenefitsLogo,
          background: images.HRA_BenefitsBackground,
          textLine1: "HRA",
          textLine2: "benefits",
          label: "hra benefits",
        },
        {
          icon: images.preCertificationLogo,
          background: images.preCertificationBackground,
          textLine1: "pre",
          textLine2: "certification",
          label: "pre certification",
        },
        {
          icon: images.otherBenefitsLogo,
          background: images.otherBenefitsBackground,
          textLine1: "other",
          textLine2: "benefits",
          label: "other benefits",
        },
      ],
      open_cancle_confirmation_dialog: false,
      memberBenefits: [
        "medical benefits",
        "dental benefits",
        "vision benefits",
        "rx benefits",
        "life benefits",
        "voluntary supplements",
        "retirement benefits",
        "health savings account",
        "health fsa benefits",
        "hra benefits",
        "pre certification",
        "other benefits",
      ],

      showMemberFullInfo: false,
      showMemberInfoEditModal: false,
      memberFullInfo: {
        memberInfo_firstName: "Vikas",
        memberInfo_lastName: "kumar",
        memberInfo_middleName: "-",
        memberInfo_fax1: "12345",
        memberInfo_fax2: "12345",
        memberInfo_phoneNumber: "8957593456",
        memberInfo_phoneNumberExt: "",
        memberInfo_profile_pic: images.profile_img,
        memberInfo_sameAsPhysicalAddress: false,
        memberInfo_linkSsn: "-",
        memberInfo_nameSuffix: "-",
        memberInfo_namePrefix: "-",
        memberInfo_relation: "child",
        memberInfo_memberNumber: "12345",
        memberInfo_gender: "male",
        memberInfo_dob: "1989-06-23",
        memberInfo_age: "34",
        memberInfo_maritalStatus: "married",
        memberInfo_mobileNumber: "9876543210",
        memberInfo_clientName: "john wick",
        memberInfo_employerName: "builder ai",
        memberInfo_groupName: "xyz",
        memberInfo_divisionName: "abc",
        memberInfo_locationName: "xyz",
        memberInfo_ssn: "3453453",
        memberInfo_otherId: "99765785865865",
        memberInfo_otherDesc: "xyz",
        memberInfo_email: "vikas.kumar@gmail.com",
        memberInfo_physicalAddressLine1: "99 king street",
        memberInfo_physicalAddressLine2: "-",
        memberInfo_physicalCity: "Florida",
        memberInfo_physicalState: "Florida",
        memberInfo_physicalZipcode: "32085",
        memberInfo_physicalCountry: "United States",
        memberInfo_mailingAddressLine1: "10 queen street",
        memberInfo_mailingAddressLine2: "",
        memberInfo_mailingCity: "st augustine",
        memberInfo_mailingState: "Florida",
        memberInfo_mailingZipcode: "32805",
        memberInfo_mailingCountry: "United States",
        memberInfo_salary: "32000",
        memberInfo_salaryType: "annual",
        memberInfo_language: "English(American)",
      },
      member_firstName: "",
      member_lastName: "",
      member_middleName: "",
      member_fax1: "",
      member_fax2: "",
      member_phoneNumber: "",
      member_phoneNumberExt: "",
      member_sameAsPhysicalAddress: false,
      member_linkSsn: "",
      member_nameSuffix: "",
      member_namePrefix: "",
      member_relation: "",
      member_memberNumber: "",
      member_gender: "",
      member_dob: "",
      member_age: "",
      member_maritalStatus: "",
      member_mobileNumber: "",
      member_clientName: "",
      member_employerName: "",
      member_groupName: "",
      member_divisionName: "",
      member_locationName: "",
      member_ssn: "",
      member_otherId: "",
      member_otherDesc: "",
      member_email: "",
      member_physicalAddressLine1: "",
      member_physicalAddressLine2: "",
      member_physicalCity: "",
      member_physicalState: "",
      member_physicalZipcode: "",
      member_physicalCountry: "",
      member_mailingAddressLine1: "",
      member_mailingAddressLine2: "",
      member_mailingCity: "",
      member_mailingState: "",
      member_mailingZipcode: "",
      member_mailingCountry: "",
      member_salary: "",
      member_salaryType: "",
      languageList: ["English(American)", "English(UK)"],
      selectedLanguage: "",
      openLanguageSelectionBox: false,
      languageInputValue: "",
      dependentInfoList: [],
      beneficiaryInfoList: [
        {
          id: "ajaybeneficiary12b",
          firstName: "ajay",
          lastName: "kumar",
          relationship: "child",
          gender: "male",
          dob: "1986-06-18",
          percentage: "54",
          contactNumber: "9876543210",
          physicalAddressLine1: "18 new street",
          physicalAddressLine2: "-",
          physicalCity: "Florida",
          physicalState: "Florida",
          beneficiary: "primary",
          physicalZipcode: "56783",
          email: "ajay@test.com",
          physicalCountry: "United States",
          mailingAddressLine1: "malviya nagar",
          mailingAddressLine2: "sector 5",
          mailingCity: "jaipur",
          mailingState: "New York",
          mailingZipcode: "13346",
          mailingCountry: "United States",
        },
      ],
      selectedMenu: window.location.href.split("/")[4],
      selectedChildrenMenu: window.location.href.split("/")[5],
      isMobileBarDisplay: false,
      formActiveStep: 0,
      isFormUpdated: false,
      editMemberDetailsStepList: [
        {
          title: "Member Info",
          description: "Type and Select details",
        },
        {
          title: "Dependent Info",
          description: "Type and Select details",
        },
        {
          title: "Beneficiary Info",
          description: "Type and Select details",
        },
        {
          title: "Profile picture/ Supporting documents",
          description: "Drag or Select file",
        },
        {
          title: "Wellness form",
          description: "Type and Select details",
        },
      ],
      openChildrenList: window.location.href.split("/")[5]
        ? [window.location.href.split("/")[4]]
        : [],

      dependent_sameAsPhysicalAddress: false,
      dependent_firstName: "",
      dependent_lastName: "",
      dependent_relationship: "",
      dependent_gender: "",
      dependent_ssn: "",
      dependent_dob: "",
      dependent_email: "",
      dependent_contactNumber: "",
      dependent_physicalAddressLine1: "",
      dependent_physicalAddressLine2: "",
      dependent_physicalCity: "",
      dependent_physicalState: "",
      dependent_physicalZipcode: "",
      dependent_physicalCountry: "",
      dependent_mailingAddressLine1: "",
      dependent_mailingAddressLine2: "",
      dependent_mailingCity: "",
      dependent_mailingState: "",
      dependent_mailingZipcode: "",
      dependent_mailingCountry: "",

      beneficiary_sameAsPhysicalAddress: false,
      beneficiary_firstName: "",
      beneficiary_lastName: "",
      beneficiary_relationship: "",
      beneficiary_gender: "",
      beneficiary_dob: "",
      beneficiary_percentage: "",
      beneficiary_contactNumber: "",
      beneficiary_physicalAddressLine1: "",
      beneficiary_physicalAddressLine2: "",
      beneficiary_physicalCity: "",
      beneficiary_beneficiary: "",
      beneficiary_physicalState: "",
      beneficiary_physicalZipcode: "",
      beneficiary_physicalCountry: "",
      beneficiary_mailingAddressLine1: "",
      beneficiary_mailingAddressLine2: "",
      beneficiary_mailingCity: "",
      beneficiary_mailingState: "",
      beneficiary_mailingZipcode: "",
      beneficiary_mailingCountry: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (prevState.selectedMenu !== window.location.href.split("/")[4]) {
      this.setState({ selectedMenu: window.location.href.split("/")[4] });
    }
    if (prevState.selectedChildrenMenu !== window.location.href.split("/")[5]) {
      this.setState({
        selectedChildrenMenu: window.location.href.split("/")[5],
        openChildrenList: window.location.href.split("/")[5]
          ? [window.location.href.split("/")[4]]
          : [],
      });
    }
  }



  handleClick = (navigation: string, key: string) => {
    this.setState({
      selectedMenu: key,
      selectedChildrenMenu: "",
    });
    this.props.navigation.navigate(navigation);
  };

  handleChildrenClick = (navigation: string, key: string, parent: string) => {
    this.setState({
      selectedChildrenMenu: key,
      selectedMenu: parent,
    });
    this.props.navigation.navigate(navigation);
  };

  handleMailingAddressValue = (
    mailingCheckboxState: boolean,
    physicalAddressValue: string,
    mailingAddressValue: string
  ) => {
    return mailingCheckboxState ? physicalAddressValue : mailingAddressValue;
  };

  handleOpenCloseChildrenClick = (key: string) => {
    let newList = this.state.openChildrenList;
    if (newList.includes(key)) {
      newList = newList.filter((list) => list !== key);
    } else {
      newList.push(key);
    }
    this.setState({
      openChildrenList: newList,
    });
  };

  handleMobileSideBar = () => {
    this.setState({
      isMobileBarDisplay: !this.state.isMobileBarDisplay,
    });
  };

  handleDialogClose = () => {
    if (this.state.isFormChanged) {
      this.setState({
        open_cancle_confirmation_dialog: !this.state
          .open_cancle_confirmation_dialog,
      });
    } else {
      this.handleCancleConfirmationDialogOkButton();
      this.setState({ showMemberInfoEditModal: false });
    }
  };
  handleCancleConfirmationDialogClose = () => {
    this.setState({ open_cancle_confirmation_dialog: false });
  };
  handleCancleConfirmationDialogOkButton = () => {
    this.setState({
      showMemberInfoEditModal: false,
      open_cancle_confirmation_dialog: false,
      dependentFormErrors: {},
      beneficiaryFormErrors: {},
      editProfilePicFormError: "",
      isEditClick: false,
      editTableItemId: "",
      member_documents: null,
      member_phoneNumber: "",
      member_phoneNumberExt: "",
      member_sameAsPhysicalAddress: false,
      member_linkSsn: "",
      member_firstName: "",
      member_lastName: "",
      member_middleName: "",
      member_fax1: "",
      member_fax2: "",
      member_nameSuffix: "",
      member_namePrefix: "",
      member_relation: "",
      member_maritalStatus: "",
      member_mobileNumber: "",
      member_clientName: "",
      member_employerName: "",
      member_memberNumber: "",
      member_gender: "",
      member_ssn: "",
      member_otherId: "",
      member_otherDesc: "",
      member_email: "",
      member_physicalAddressLine1: "",
      member_physicalAddressLine2: "",
      member_dob: "",
      member_age: "",
      member_groupName: "",
      member_divisionName: "",
      member_locationName: "",
      member_physicalCity: "",
      member_physicalState: "",
      member_physicalZipcode: "",
      member_mailingZipcode: "",
      member_mailingCountry: "",
      member_salary: "",
      member_physicalCountry: "",
      member_mailingAddressLine1: "",
      member_mailingAddressLine2: "",
      member_mailingCity: "",
      member_mailingState: "",
      member_salaryType: "",
      openLanguageSelectionBox: false,
      languageInputValue: "",
      memberForm1Errors: {},
      selectedLanguage: "",
      member_profile_pic: "",
      dependent_ssn: "",
      dependent_dob: "",
      dependent_email: "",
      dependent_contactNumber: "",
      dependent_sameAsPhysicalAddress: false,
      dependent_firstName: "",
      dependent_lastName: "",
      dependent_relationship: "",
      dependent_gender: "",
      dependent_physicalAddressLine1: "",
      dependent_physicalAddressLine2: "",
      dependent_physicalCity: "",
      dependent_physicalState: "",
      dependent_physicalZipcode: "",
      dependent_physicalCountry: "",
      dependent_mailingAddressLine1: "",
      dependent_mailingAddressLine2: "",
      dependent_mailingCity: "",
      dependent_mailingState: "",
      dependent_mailingZipcode: "",
      dependent_mailingCountry: "",
      beneficiary_physicalCity: "",
      beneficiary_beneficiary: "",
      beneficiary_physicalState: "",
      beneficiary_physicalZipcode: "",
      beneficiary_email: "",
      beneficiary_physicalCountry: "",
      beneficiary_sameAsPhysicalAddress: false,
      beneficiary_firstName: "",
      beneficiary_lastName: "",
      beneficiary_relationship: "",
      beneficiary_gender: "",
      beneficiary_dob: "",
      beneficiary_percentage: "",
      beneficiary_contactNumber: "",
      beneficiary_physicalAddressLine1: "",
      beneficiary_physicalAddressLine2: "",
      beneficiary_mailingCity: "",
      beneficiary_mailingState: "",
      beneficiary_mailingZipcode: "",
      beneficiary_mailingCountry: "",
      beneficiary_mailingAddressLine1: "",
      beneficiary_mailingAddressLine2: "",
      isFormUpdated: false,

    });
  };

  handleMemberFormStep = () => {
    const { memberFormIsValid } = this.handleMemberFormValidation();
    if (memberFormIsValid) {
      this.setState({
        memberFullInfo: {
          ...this.state.memberFullInfo,
          memberInfo_firstName: this.state.member_firstName,
          memberInfo_lastName: this.state.member_lastName,
          memberInfo_middleName: this.state.member_middleName,
          memberInfo_fax1: this.state.member_fax1,
          memberInfo_fax2: this.state.member_fax2,
          memberInfo_phoneNumber: this.state.member_phoneNumber,
          memberInfo_phoneNumberExt: this.state.member_phoneNumberExt,
          memberInfo_sameAsPhysicalAddress: this.state
            .member_sameAsPhysicalAddress,
          memberInfo_linkSsn: this.state.member_linkSsn,
          memberInfo_nameSuffix: this.state.member_nameSuffix,
          memberInfo_namePrefix: this.state.member_namePrefix,
          memberInfo_relation: this.state.member_relation,
          memberInfo_memberNumber: this.state.member_memberNumber,
          memberInfo_gender: this.state.member_gender,
          memberInfo_dob: this.state.member_dob,
          memberInfo_age: this.state.member_age,
          memberInfo_maritalStatus: "married",
          memberInfo_mobileNumber: this.state.member_mobileNumber,
          memberInfo_clientName: "mate henry",
          memberInfo_ssn: this.state.member_ssn,
          memberInfo_otherId: this.state.member_otherId,
          memberInfo_otherDesc: this.state.member_otherDesc,
          memberInfo_email: this.state.member_email,
          memberInfo_physicalAddressLine1: this.state
            .member_physicalAddressLine1,
          memberInfo_physicalAddressLine2: this.state
            .member_physicalAddressLine2,
          memberInfo_physicalCity: this.state.member_physicalCity,
          memberInfo_physicalState: this.state.member_physicalState,
          memberInfo_physicalZipcode: this.state.member_physicalZipcode,
          memberInfo_physicalCountry: this.state.member_physicalCountry,
          memberInfo_mailingAddressLine1: this.state.member_mailingAddressLine1,
          memberInfo_mailingAddressLine2: this.state.member_mailingAddressLine2,
          memberInfo_mailingCity: this.state.member_mailingCity,
          memberInfo_mailingState: this.state.member_mailingState,
          memberInfo_mailingZipcode: this.state.member_mailingZipcode,
          memberInfo_mailingCountry: this.state.member_mailingCountry,
          memberInfo_salary: this.state.member_salary,
          memberInfo_salaryType: this.state.member_salaryType,
          memberInfo_language: this.state.selectedLanguage,
        },
      });
      this.setState({
        isFormUpdated: !this.state.isFormUpdated,
      });
    }
  }

  handleDependentFormStep = () => {
    const { dependentFormIsValid } = this.handleDependentFormValidation();
    const {
      dependent_firstName,
      dependent_lastName,
      dependent_relationship,
      dependent_gender,
      dependent_ssn,
      dependent_dob,
      dependent_email,
      dependent_sameAsPhysicalAddress,
      dependent_contactNumber,
      dependent_physicalAddressLine1,
      dependent_physicalAddressLine2,
      dependent_physicalCity,
      dependent_physicalState,
      dependent_physicalZipcode,
      dependent_physicalCountry,
      dependent_mailingAddressLine1,
      dependent_mailingAddressLine2,
      dependent_mailingCity,
      dependent_mailingState,
      dependent_mailingZipcode,
      dependent_mailingCountry,
    } = this.state;

    const newDependent = {
      id: dependent_firstName + "dependent" + new Date().getTime().toString(),
      firstName: dependent_firstName,
      lastName: dependent_lastName,
      relationship: dependent_relationship,
      gender: dependent_gender,
      ssn: dependent_ssn,
      dob: dependent_dob,
      email: dependent_email,
      contactNumber: dependent_contactNumber,
      physicalAddressLine1: dependent_physicalAddressLine1,
      physicalAddressLine2: dependent_physicalAddressLine2,
      physicalCity: dependent_physicalCity,
      physicalState: dependent_physicalState,
      physicalZipcode: dependent_physicalZipcode,
      physicalCountry: dependent_physicalCountry,
      mailingAddressLine1: dependent_sameAsPhysicalAddress ? dependent_physicalAddressLine1 : dependent_mailingAddressLine1,
      mailingAddressLine2: dependent_sameAsPhysicalAddress ? dependent_physicalAddressLine2 : dependent_mailingAddressLine2,
      mailingCity: dependent_sameAsPhysicalAddress ? dependent_physicalCity : dependent_mailingCity,
      mailingState: dependent_sameAsPhysicalAddress ? dependent_physicalState : dependent_mailingState,
      mailingZipcode: dependent_sameAsPhysicalAddress ? dependent_physicalZipcode : dependent_mailingZipcode,
      mailingCountry: dependent_sameAsPhysicalAddress ? dependent_physicalCountry : dependent_mailingCountry,
    };
    if (dependentFormIsValid) {
      if (this.state.isEditClick) {
        const updatedDependentList = this.state.dependentInfoList.map((dependent) =>
          dependent.id === this.state.editTableItemId ? newDependent : dependent
        );
        this.setState({
          dependentInfoList: updatedDependentList,
          isFormUpdated: !this.state.isFormUpdated,
          isEditClick: false,
          editTableItemId: "",
          dependent_sameAsPhysicalAddress: false,
          dependent_firstName: "",
          dependent_lastName: "",
          dependent_relationship: "",
          dependent_gender: "",
          dependent_ssn: "",
          dependent_dob: "",
          dependent_email: "",
          dependent_contactNumber: "",
          dependent_physicalAddressLine1: "",
          dependent_physicalAddressLine2: "",
          dependent_physicalCity: "",
          dependent_physicalState: "",
          dependent_physicalZipcode: "",
          dependent_physicalCountry: "",
          dependent_mailingAddressLine1: "",
          dependent_mailingAddressLine2: "",
          dependent_mailingCity: "",
          dependent_mailingState: "",
          dependent_mailingZipcode: "",
          dependent_mailingCountry: "",
        })
      } else {
        this.setState({
          dependentInfoList: [...this.state.dependentInfoList, newDependent],
          isFormUpdated: !this.state.isFormUpdated,
        });
      }

    }
  }

  handleBeneficiaryFormStep = () => {
    const { beneficiaryFormIsValid } = this.handleBeneficiaryFormValidation();
    const {
      beneficiary_firstName,
      beneficiary_lastName,
      beneficiary_relationship,
      beneficiary_gender,
      beneficiary_physicalCity,
      beneficiary_physicalState,
      beneficiary_dob,
      beneficiary_email,
      beneficiary_beneficiary,
      beneficiary_percentage,
      beneficiary_contactNumber,
      beneficiary_physicalAddressLine1,
      beneficiary_physicalAddressLine2,
      beneficiary_physicalZipcode,
      beneficiary_physicalCountry,
      beneficiary_mailingState,
      beneficiary_mailingZipcode,
      beneficiary_mailingAddressLine1,
      beneficiary_mailingAddressLine2,
      beneficiary_mailingCity,
      beneficiary_mailingCountry,
      beneficiary_sameAsPhysicalAddress
    } = this.state;

    const newBeneficiary = {
      id: beneficiary_firstName + "beneficiary" + new Date().getTime().toString(),
      firstName: beneficiary_firstName,
      lastName: beneficiary_lastName,
      dob: beneficiary_dob,
      percentage: beneficiary_percentage,
      contactNumber: beneficiary_contactNumber,
      relationship: beneficiary_relationship,
      gender: beneficiary_gender,
      beneficiary: beneficiary_beneficiary,
      physicalAddressLine1: beneficiary_physicalAddressLine1,
      physicalAddressLine2: beneficiary_physicalAddressLine2,
      physicalCity: beneficiary_physicalCity,
      email: beneficiary_email,
      physicalState: beneficiary_physicalState,
      physicalZipcode: beneficiary_physicalZipcode,
      physicalCountry: beneficiary_physicalCountry,
      mailingAddressLine1: beneficiary_sameAsPhysicalAddress ? beneficiary_physicalAddressLine1 : beneficiary_mailingAddressLine1,
      mailingZipcode: beneficiary_sameAsPhysicalAddress ? beneficiary_physicalZipcode : beneficiary_mailingZipcode,
      mailingCountry: beneficiary_sameAsPhysicalAddress ? beneficiary_physicalCountry : beneficiary_mailingCountry,
      mailingAddressLine2: beneficiary_sameAsPhysicalAddress ? beneficiary_physicalAddressLine2 : beneficiary_mailingAddressLine2,
      mailingCity: beneficiary_sameAsPhysicalAddress ? beneficiary_physicalCity : beneficiary_mailingCity,
      mailingState: beneficiary_sameAsPhysicalAddress ? beneficiary_physicalState : beneficiary_mailingState,
    };

    if (beneficiaryFormIsValid) {
      if (this.state.isEditClick) {
        const updatedBeneficiaryList = this.state.beneficiaryInfoList.map((beneficiary) =>
          beneficiary.id === this.state.editTableItemId ? newBeneficiary : beneficiary
        );
        this.setState({
          beneficiaryInfoList: updatedBeneficiaryList,
          isFormUpdated: !this.state.isFormUpdated,
          isEditClick: false,
          editTableItemId: "",
          beneficiary_sameAsPhysicalAddress: false,
          beneficiary_firstName: "",
          beneficiary_lastName: "",
          beneficiary_relationship: "",
          beneficiary_gender: "",
          beneficiary_percentage: "",
          beneficiary_dob: "",
          beneficiary_email: "",
          beneficiary_contactNumber: "",
          beneficiary_physicalAddressLine1: "",
          beneficiary_physicalAddressLine2: "",
          beneficiary_physicalCity: "",
          beneficiary_physicalState: "",
          beneficiary_physicalZipcode: "",
          beneficiary_physicalCountry: "",
          beneficiary_mailingAddressLine1: "",
          beneficiary_mailingAddressLine2: "",
          beneficiary_mailingCity: "",
          beneficiary_mailingState: "",
          beneficiary_mailingZipcode: "",
          beneficiary_mailingCountry: "",
        })
      } else {
        this.setState({
          beneficiaryInfoList: [...this.state.beneficiaryInfoList, newBeneficiary],
          isFormUpdated: !this.state.isFormUpdated,
        });
      }

    }
  }

  handleProfilePicFormStep = () => {
    const { member_profile_pic } = this.state;
    if (member_profile_pic) {
      this.setState({
        memberFullInfo: {
          ...this.state.memberFullInfo,
          memberInfo_profile_pic: member_profile_pic,
        }
      });
      this.setState({ editProfilePicFormError: "", isFormUpdated: true });
    } else {
      this.setState({ editProfilePicFormError: "Profile Image is Required" })
    }
  }

  saveButNotNext = () => {
    const { formActiveStep } = this.state;

    switch (formActiveStep) {
      case 0:
        this.handleMemberFormStep();
        break

      case 1:
        this.handleDependentFormStep();
        break

      case 2:
        this.handleBeneficiaryFormStep();
        break

      case 3:
        this.handleProfilePicFormStep();
        break

      default:
        break
    }

  };
  saveAndNext = () => {
    const { formActiveStep } = this.state;
    this.setState({ formActiveStep: formActiveStep + 1 })
  }

  handleSuccessDialogClose = () => {
    this.setState({ isFormUpdated: false });
  };

  handlePrevious = () => {
    if (this.state.formActiveStep > 0) {
      this.setState({ formActiveStep: this.state.formActiveStep - 1 });
    }
  };
  checkMemberOnChangeError = (name: string, value: string) => {
    const errors = { ...this.state.memberForm1Errors };
    switch (name) {
      case "member_email": {
        const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        if (emailPattern.test(value)) {
          errors[`${name}Error`] = '';
        }
        this.setState({
          ...this.state,
          [name]: value.trimLeft(),
          memberForm1Errors: errors,
          isFormChanged: true,
        });
        break
      }
      case "member_mobileNumber": {
        const mobilePattern = /^\d{10}$/;
        if (mobilePattern.test(value)) {
          errors[`${name}Error`] = '';
        }
        this.setState({
          ...this.state,
          [name]: value.trimLeft(),
          isFormChanged: true,
          memberForm1Errors: errors,
        });
        break
      }
      case "member_physicalZipcode":
      case "member_mailingZipcode": {
        const zipcodePattern = /^\d{5}$/;
        if (zipcodePattern.test(value)) {
          errors[`${name}Error`] = '';
        }
        this.setState({
          ...this.state,
          [name]: value.trimLeft(),
          isFormChanged: true,
          memberForm1Errors: errors,
        });
        break
      }
      default: {
        if (value.trim() !== '' && value.length < 30) {
          errors[`${name}Error`] = '';
        }
        this.setState({
          ...this.state,
          [name]: value.trimLeft(),
          isFormChanged: true,
          memberForm1Errors: errors,
        });
      }
        break
    }
  }
  checkDependentOnChangeError = (name: string, value: string) => {
    const errors = { ...this.state.dependentFormErrors };
    switch (name) {
      case "dependent_email": {
        const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        if (emailPattern.test(value)) {
          errors[`${name}Error`] = '';
        }
        this.setState({
          ...this.state,
          [name]: value.trimLeft(),
          isFormChanged: true,
          dependentFormErrors: errors,
        });
        break
      }
      case "dependent_contactNumber": {
        const mobilePattern = /^\d{10}$/;
        if (mobilePattern.test(value)) {
          errors[`${name}Error`] = '';
        }
        this.setState({
          ...this.state,
          [name]: value.trimLeft(),
          isFormChanged: true,
          dependentFormErrors: errors,
        });
        break
      }
      case "dependent_physicalZipcode":
      case "dependent_mailingZipcode": {
        const zipcodePattern = /^\d{5}$/;
        if (zipcodePattern.test(value)) {
          errors[`${name}Error`] = '';
        }
        this.setState({
          ...this.state,
          [name]: value.trimLeft(),
          dependentFormErrors: errors,
          isFormChanged: true,
        });
        break
      }
      default: {
        if (value.trim() !== '' && value.length < 30) {
          errors[`${name}Error`] = '';
        }
        this.setState({
          ...this.state,
          [name]: value.trimLeft(),
          isFormChanged: true,
          dependentFormErrors: errors,
        });
      }
        break
    }
  }
  checkBeneficiaryOnChangeError = (name: string, value: string) => {
    const errors = { ...this.state.beneficiaryFormErrors };
    switch (name) {
      case "beneficiary_email": {
        const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        if (emailPattern.test(value)) {
          errors[`${name}Error`] = '';
        }
        this.setState({
          ...this.state,
          [name]: value.trimLeft(),
          isFormChanged: true,
          beneficiaryFormErrors: errors,
        });
        break
      }
      case "beneficiary_contactNumber": {
        const mobilePattern = /^\d{10}$/;
        if (mobilePattern.test(value)) {
          errors[`${name}Error`] = '';
        }
        this.setState({
          ...this.state,
          [name]: value.trimLeft(),
          isFormChanged: true,
          beneficiaryFormErrors: errors,
        });
        break
      }
      case "beneficiary_physicalZipcode":
      case "beneficiary_mailingZipcode": {
        const zipcodePattern = /^\d{5}$/;
        if (zipcodePattern.test(value)) {
          errors[`${name}Error`] = '';
        }
        this.setState({
          ...this.state,
          [name]: value.trimLeft(),
          isFormChanged: true,
          beneficiaryFormErrors: errors,
        });
        break
      }
      default: {
        if (value.trim() !== '' && value.length < 30) {
          errors[`${name}Error`] = '';
        }
        this.setState({
          ...this.state,
          [name]: value.trimLeft(),
          isFormChanged: true,
          beneficiaryFormErrors: errors,
        });
      }
        break
    }
  }
  handleFormInputsOnChange = (e: any) => {
    const { name, value } = e.target;
    const { formActiveStep } = this.state;
    switch (formActiveStep) {
      case 0: {
        if (this.state.memberForm1Errors) {
          this.checkMemberOnChangeError(name, value);
        }
        break
      }
      case 1: {
        if (this.state.dependentFormErrors) {
          this.checkDependentOnChangeError(name, value);
        }
        break
      }
      case 2: {
        if (this.state.beneficiaryFormErrors) {
          this.checkBeneficiaryOnChangeError(name, value);
        }
        break
      }
      default:
        break
    }

  }
  calculateAge = () => {
    const { member_dob } = this.state;
    const dobDate = new Date(member_dob);
    const today = new Date();
    const ageTime = today.getTime() - dobDate.getTime();
    const ageDate = new Date(ageTime);

    const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    this.setState({ member_age: `${calculatedAge} years` });
  }
  handleDOBOnChange = (e: any) => {
    this.setState({ member_dob: e.target.value }, () => {
      this.calculateAge();
    });
  }

  languageListClickHandler = (language: string) => {
    this.setState({ selectedLanguage: language });
    this.setState({ openLanguageSelectionBox: false });
    this.setState({ languageInputValue: "" });
  };
  languageSearchInputOnChangeHandler = (targetValue: string) => {
    this.setState({ languageInputValue: targetValue });
  };

  showTooltipTitle = () => {
    return this.state.showMemberFullInfo ? "Click to hide personal details" : "Click to view full personal details"
  }

  handleMemberFormValidation = () => {
    const formFields: FormField[] = configJSON.memberFormFields;

    let memberFormIsValid = true;
    let errors: any = {};

    formFields.forEach((field) => {
      const { name, label, required, pattern, error, lengthLimitation } = field;
      const value = this.state[name];

      if (lengthLimitation && value.length > 30) {
        memberFormIsValid = false;
        errors[`${name}Error`] = `Maximum length exceeded`;
      }

      if (required && !value) {
        memberFormIsValid = false;
        errors[`${name}Error`] = `${label} is required`;
      }


      if (pattern && !pattern.test(value)) {
        memberFormIsValid = false;
        errors[`${name}Error`] = error || "Invalid input";
      }
    });

    this.setState({ memberForm1Errors: errors });
    return { memberFormIsValid, errors };
  };

  handleDependentFormValidation = () => {
    const formFields: FormField[] = configJSON.dependentFormFields;

    let dependentFormIsValid = true;
    let errors: any = {};

    formFields.forEach((field) => {
      const { name, label, required, pattern, lengthLimitation, error } = field;
      const value = this.state[name];

      if (required && !value) {
        dependentFormIsValid = false;
        errors[`${name}Error`] = `${label} is required`;
      }

      if (pattern && !pattern.test(value)) {
        dependentFormIsValid = false;
        errors[`${name}Error`] = error || "Invalid input";
      }
      if (lengthLimitation && value.length > 30) {
        dependentFormIsValid = false;
        errors[`${name}Error`] = `Maximum length exceeded`;
      }
    });

    this.setState({ dependentFormErrors: errors });
    return { dependentFormIsValid, errors };
  };

  handleBeneficiaryFormValidation = () => {
    const formFields: FormField[] = configJSON.beneficiaryFormFields;

    let beneficiaryFormIsValid = true;
    let errors: any = {};

    formFields.forEach((field) => {
      const { name, label, lengthLimitation, required, pattern, error } = field;
      const value = this.state[name];

      if (required && !value) {
        beneficiaryFormIsValid = false;
        errors[`${name}Error`] = `${label} is required`;
      }
      if (lengthLimitation && value.length > 30) {
        beneficiaryFormIsValid = false;
        errors[`${name}Error`] = `Maximum length exceeded`;
      }

      if (pattern && !pattern.test(value)) {
        beneficiaryFormIsValid = false;
        errors[`${name}Error`] = error || "Invalid input";
      }
    });

    this.setState({ beneficiaryFormErrors: errors });
    return { beneficiaryFormIsValid, errors };
  };
  handleAddDependent = () => {
    this.setState({
      formActiveStep: 1,
      showMemberInfoEditModal: true,
      isFormChanged: false,
    });
  };

  handleAddBeneficiary = () => {
    this.setState({
      formActiveStep: 2,
      showMemberInfoEditModal: true,
      isFormChanged: false,
    });
  };

  handleSameAsPhysicalAddress = (e: any) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked });

    switch (name) {
      case "member_sameAsPhysicalAddress": {
        if (checked) {
          const {
            member_physicalAddressLine1,
            member_physicalAddressLine2,
            member_physicalCity,
            member_physicalState,
            member_physicalCountry,
            member_physicalZipcode,
          } = this.state;

          this.setState({
            member_mailingAddressLine1: member_physicalAddressLine1,
            member_mailingAddressLine2: member_physicalAddressLine2,
            member_mailingCity: member_physicalCity,
            member_mailingState: member_physicalState,
            member_mailingCountry: member_physicalCountry,
            member_mailingZipcode: member_physicalZipcode,
            
            memberForm1Errors:{
              ...this.state.memberForm1Errors,
              member_mailingAddressLine1Error: "",
              member_mailingCityError: "",
              member_mailingStateError: "",
              member_mailingCountryError: "",
              member_mailingZipcodeError: "",
            }
          });

        } else {
          this.setState({
            member_mailingAddressLine1: "",
            member_mailingAddressLine2: "",
            member_mailingCity: "",
            member_mailingState: "",
            member_mailingCountry: "",
            member_mailingZipcode: "",
          });
        }
        break
      }
      case "dependent_sameAsPhysicalAddress": {
        if (checked) {
          const {
            dependent_physicalAddressLine1,
            dependent_physicalAddressLine2,
            dependent_physicalCity,
            dependent_physicalState,
            dependent_physicalCountry,
            dependent_physicalZipcode,
          } = this.state;

          this.setState({
            dependent_mailingAddressLine1: dependent_physicalAddressLine1,
            dependent_mailingAddressLine2: dependent_physicalAddressLine2,
            dependent_mailingCity: dependent_physicalCity,
            dependent_mailingState: dependent_physicalState,
            dependent_mailingCountry: dependent_physicalCountry,
            dependent_mailingZipcode: dependent_physicalZipcode,

            dependentFormErrors:{
              ...this.state.dependentFormErrors,
              dependent_mailingAddressLine1Error: "",
              dependent_mailingCityError: "",
              dependent_mailingStateError: "",
              dependent_mailingCountryError: "",
              dependent_mailingZipcodeError: "",
            }
          });
        } else {
          this.setState({
            dependent_mailingAddressLine1: "",
            dependent_mailingAddressLine2: "",
            dependent_mailingCity: "",
            dependent_mailingState: "",
            dependent_mailingCountry: "",
            dependent_mailingZipcode: "",
          });
        }
        break
      }
      case "beneficiary_sameAsPhysicalAddress": {
        if (checked) {
          const {
            beneficiary_physicalAddressLine1,
            beneficiary_physicalAddressLine2,
            beneficiary_physicalCity,
            beneficiary_physicalState,
            beneficiary_physicalCountry,
            beneficiary_physicalZipcode,
          } = this.state;

          this.setState({
            beneficiary_mailingAddressLine1: beneficiary_physicalAddressLine1,
            beneficiary_mailingAddressLine2: beneficiary_physicalAddressLine2,
            beneficiary_mailingCity: beneficiary_physicalCity,
            beneficiary_mailingState: beneficiary_physicalState,
            beneficiary_mailingCountry: beneficiary_physicalCountry,
            beneficiary_mailingZipcode: beneficiary_physicalZipcode,

            beneficiaryFormErrors:{
              ...this.state.beneficiaryFormErrors,
              beneficiary_mailingAddressLine1Error: "",
              beneficiary_mailingCityError: "",
              beneficiary_mailingStateError: "",
              beneficiary_mailingCountryError: "",
              beneficiary_mailingZipcodeError: "",
            }
          });
        } else {
          this.setState({
            beneficiary_mailingAddressLine1: "",
            beneficiary_mailingAddressLine2: "",
            beneficiary_mailingCity: "",
            beneficiary_mailingState: "",
            beneficiary_mailingCountry: "",
            beneficiary_mailingZipcode: "",
          });
        }
        break
      }
      default:
        break
    }
  };

  showTableInfo = (columns: string[], tableInfo: any[]) => {
    return (
      <TableContainer component={Paper} className="table_container">
        <Table size="small" className="dependent_table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableInfo.length > 0 ?
              tableInfo.map((data) => (
                <TableRow key={data.id}>
                  <TableCell scope="row">
                    {data.firstName}
                  </TableCell>
                  <TableCell>{data.lastName}</TableCell>
                  <TableCell>{data.relationship}</TableCell>
                  <TableCell>{data.physicalState}</TableCell>
                  <TableCell>{data.dob}</TableCell>
                  {columns.includes('Gender') && <TableCell>{data.gender}</TableCell>}
                  {columns.includes('Email Address') && <TableCell>{data.email}</TableCell>}
                  {columns.includes('Contact Number') && <TableCell>{data.contactNumber}</TableCell>}
                  {columns.includes('Percentage') && <TableCell>{data.percentage}</TableCell>}
                  <TableCell><EditIcon onClick={() => this.handleEditTableBtn(data.id)} style={{ width: "20px", height: "20px", cursor: "pointer" }} data-testId={data.id.includes("dependent") ? "dependent-table-edit-btn" : "beneficiary-table-edit-btn"} /></TableCell>
                </TableRow>
              )) :
              <TableRow>
                <TableCell colSpan={columns.length}>No record found</TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  checkEditTableItem = (id: string) => {
    if (id?.includes("dependent")) {
      return this.state.dependentInfoList.find((dependent) => dependent.id === id);
    } else if (id?.includes("beneficiary")) {
      return this.state.beneficiaryInfoList.find((beneficiary) => beneficiary.id === id);
    }
  }

  handleEditTableBtn = (id: string) => {
    let tableItemToEdit = this.checkEditTableItem(id);
    if (tableItemToEdit?.id.includes("dependent")) {
      const dependentItem = tableItemToEdit as DependentInfoList;
      this.setState({
        dependentId: dependentItem.id,
        dependent_firstName: dependentItem.firstName,
        dependent_lastName: dependentItem.lastName,
        dependent_contactNumber: dependentItem.contactNumber,
        dependent_email: dependentItem.email,
        dependent_dob: dependentItem.dob,
        dependent_gender: dependentItem.gender,
        dependent_mailingAddressLine1: dependentItem.mailingAddressLine1,
        dependent_mailingAddressLine2: dependentItem.mailingAddressLine2,
        dependent_mailingCity: dependentItem.mailingCity,
        dependent_mailingCountry: dependentItem.mailingCountry,
        dependent_mailingState: dependentItem.mailingState,
        dependent_mailingZipcode: dependentItem.mailingZipcode,
        dependent_physicalAddressLine1: dependentItem.physicalAddressLine1,
        dependent_physicalCountry: dependentItem.physicalCountry,
        dependent_physicalAddressLine2: dependentItem.physicalAddressLine2,
        dependent_physicalCity: dependentItem.physicalCity,
        dependent_physicalState: dependentItem.physicalState,
        dependent_physicalZipcode: dependentItem.physicalZipcode,
        dependent_relationship: dependentItem.relationship,
        dependent_ssn: dependentItem.ssn,
        formActiveStep: 1,
      });

    } else if (tableItemToEdit?.id.includes("beneficiary")) {
      const beneficiaryItem = tableItemToEdit as BeneficiaryInfoList;
      this.setState({
        beneficiaryId: beneficiaryItem.id,
        beneficiary_firstName: beneficiaryItem.firstName,
        beneficiary_lastName: beneficiaryItem.lastName,
        beneficiary_contactNumber: beneficiaryItem.contactNumber,
        beneficiary_email: beneficiaryItem.email,
        beneficiary_dob: beneficiaryItem.dob,
        beneficiary_gender: beneficiaryItem.gender,
        beneficiary_beneficiary: beneficiaryItem.beneficiary,
        beneficiary_percentage: beneficiaryItem.percentage,
        beneficiary_mailingAddressLine1: beneficiaryItem.mailingAddressLine1,
        beneficiary_mailingAddressLine2: beneficiaryItem.mailingAddressLine2,
        beneficiary_mailingCity: beneficiaryItem.mailingCity,
        beneficiary_mailingCountry: beneficiaryItem.mailingCountry,
        beneficiary_mailingState: beneficiaryItem.mailingState,
        beneficiary_mailingZipcode: beneficiaryItem.mailingZipcode,
        beneficiary_physicalAddressLine1: beneficiaryItem.physicalAddressLine1,
        beneficiary_physicalCountry: beneficiaryItem.physicalCountry,
        beneficiary_physicalAddressLine2: beneficiaryItem.physicalAddressLine2,
        beneficiary_physicalCity: beneficiaryItem.physicalCity,
        beneficiary_physicalState: beneficiaryItem.physicalState,
        beneficiary_physicalZipcode: beneficiaryItem.physicalZipcode,
        beneficiary_relationship: beneficiaryItem.relationship,
        formActiveStep: 2,
      });
    }
    this.setState({
      isEditClick: true,
      showMemberInfoEditModal: true,
      editTableItemId: id,
    });
  }

  handleFileChange = (e: any) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      const fileUrl = URL.createObjectURL(file);

      if (name === "member_profile_pic") {
        this.setState({
          member_profile_pic: fileUrl,
        });
      } else if (name === "member_documents") {
        this.setState({
          member_documents: file.name,
        });
      }
    }
  }
  handleSendRequestIdCard = () => {
    const { idCard_request_address } = this.state;
    this.setState({ openRequestIdCardModal: false })
  }

  handleMemberEditBtn = () => {
    const {
      memberInfo_lastName,
      memberInfo_firstName,
      memberInfo_middleName,
      memberInfo_fax1,
      memberInfo_fax2,
      memberInfo_phoneNumber,
      memberInfo_phoneNumberExt,
      memberInfo_linkSsn,
      memberInfo_nameSuffix,
      memberInfo_namePrefix,
      memberInfo_relation,
      memberInfo_memberNumber,
      memberInfo_gender,
      memberInfo_dob,
      memberInfo_age,
      memberInfo_mobileNumber,
      memberInfo_clientName,
      memberInfo_employerName,
      memberInfo_groupName,
      memberInfo_divisionName,
      memberInfo_locationName,
      memberInfo_ssn,
      memberInfo_otherId,
      memberInfo_otherDesc,
      memberInfo_email,
      memberInfo_physicalAddressLine1,
      memberInfo_physicalAddressLine2,
      memberInfo_physicalCity,
      memberInfo_physicalState,
      memberInfo_physicalZipcode,
      memberInfo_physicalCountry,
      memberInfo_mailingAddressLine1,
      memberInfo_mailingAddressLine2,
      memberInfo_mailingCity,
      memberInfo_mailingState,
      memberInfo_mailingZipcode,
      memberInfo_mailingCountry,
      memberInfo_salary,
      memberInfo_salaryType,
      memberInfo_language,
    } = this.state.memberFullInfo;
    this.setState({
      showMemberInfoEditModal: !this.state.showMemberInfoEditModal,
      isFormChanged: false,
      formActiveStep: 0,
      member_lastName: memberInfo_lastName,
      member_firstName: memberInfo_firstName,
      member_middleName: memberInfo_middleName,
      member_fax1: memberInfo_fax1,
      member_fax2: memberInfo_fax2,
      member_phoneNumber: memberInfo_phoneNumber,
      member_phoneNumberExt: memberInfo_phoneNumberExt,
      member_sameAsPhysicalAddress: false,
      member_linkSsn: memberInfo_linkSsn,
      member_nameSuffix: memberInfo_nameSuffix,
      member_namePrefix: memberInfo_namePrefix,
      member_relation: memberInfo_relation,
      member_memberNumber: memberInfo_memberNumber,
      member_gender: memberInfo_gender,
      member_dob: memberInfo_dob,
      member_age: memberInfo_age,
      member_mobileNumber: memberInfo_mobileNumber,
      member_clientName: memberInfo_clientName,
      member_employerName: memberInfo_employerName,
      member_groupName: memberInfo_groupName,
      member_divisionName: memberInfo_divisionName,
      member_locationName: memberInfo_locationName,
      member_ssn: memberInfo_ssn,
      member_otherId: memberInfo_otherId,
      member_otherDesc: memberInfo_otherDesc,
      member_email: memberInfo_email,
      member_physicalAddressLine1: memberInfo_physicalAddressLine1,
      member_physicalAddressLine2: memberInfo_physicalAddressLine2,
      member_physicalCity: memberInfo_physicalCity,
      member_physicalState: memberInfo_physicalState,
      member_physicalZipcode: memberInfo_physicalZipcode,
      member_physicalCountry: memberInfo_physicalCountry,
      member_mailingAddressLine1: memberInfo_mailingAddressLine1,
      member_mailingAddressLine2: memberInfo_mailingAddressLine2,
      member_mailingCity: memberInfo_mailingCity,
      member_mailingState: memberInfo_mailingState,
      member_mailingZipcode: memberInfo_mailingZipcode,
      member_mailingCountry: memberInfo_mailingCountry,
      member_salary: memberInfo_salary,
      member_salaryType: memberInfo_salaryType,
      selectedLanguage: memberInfo_language,
    })
  }
  handleFormFieldClassName = (name: string) => {
    const { formActiveStep } = this.state;
    switch (formActiveStep) {
      case 0: {
        if (this.state.memberForm1Errors[name + "Error"]) {
          return (`text-input ${name}Error error`)
        } else if (!this.state.memberForm1Errors[name + "Error"]) {
          return (`text-input`)
        }
        break
      }
      case 1: {
        if (this.state.dependentFormErrors[name + "Error"]) {
          return (`text-input ${name}Error error`)
        } else if (!this.state.memberForm1Errors[name + "Error"]) {
          return (`text-input`)
        }
        break
      }
      case 2: {
        if (this.state.beneficiaryFormErrors[name + "Error"]) {
          return (`text-input ${name}Error error`)
        } else if (!this.state.memberForm1Errors[name + "Error"]) {
          return (`text-input`)
        }
        break
      }
      default:
        break
    }
  }
  handleDobFieldClassName = (name: string) => {
    const { formActiveStep } = this.state;
    switch (formActiveStep) {
      case 0: {
        if (this.state.memberForm1Errors[name + "Error"]) {
          return (`dob-input ${name}Error error`)
        } else if (!this.state.memberForm1Errors[name + "Error"]) {
          return (`dob-input`)
        }
        break
      }
      case 1: {
        if (this.state.dependentFormErrors[name + "Error"]) {
          return (`dob-input ${name}Error error`)
        } else if (!this.state.dependentFormErrors[name + "Error"]) {
          return (`dob-input`)
        }
        break
      }
      case 2: {
        if (this.state.beneficiaryFormErrors[name + "Error"]) {
          return (`dob-input ${name}Error error`)
        } else if (!this.state.beneficiaryFormErrors[name + "Error"]) {
          return (`dob-input`)
        }
        break
      }
      default:
        break

    }
  }
  previewProfilePic = (profilePic: string | null) => {
    if (profilePic) {
      return (
        <img src={this.state.member_profile_pic || this.state.memberFullInfo.memberInfo_profile_pic} alt="Preview" />
      )
    }
  }
  errorMessage = (fieldName: string) => {
    if (fieldName) {
      return (
        <div className="validation_errors">{fieldName}</div>
      )
    }
  }
  showEditMemberInfoForm = () => {
    const {
      member_firstNameError,
      member_lastNameError,
      member_dobError,
      member_genderError,
      member_mobileNumberError,
      member_memberNumberError,
      member_ageError,
      member_ssnError,
      member_otherIdError,
      member_otherDescError,
      member_emailError,
      member_physicalAddressLine1Error,
      member_physicalCityError,
      member_physicalStateError,
      member_physicalZipcodeError,
      member_physicalCountryError,
      member_mailingAddressLine1Error,
      member_mailingCityError,
      member_mailingStateError,
      member_mailingZipcodeError,
      member_mailingCountryError,
    } = this.state.memberForm1Errors;
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              First Name<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter First Name"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("member_firstName")}
              name="member_firstName"
              value={this.state.member_firstName}
            />
            {this.errorMessage(member_firstNameError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Last Name<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Last Name"
              data-testId="member-last-name-input"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("member_lastName")}
              name="member_lastName"
              value={this.state.member_lastName}
            />
            {this.errorMessage(member_lastNameError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Middle Name
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Middle Name"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className="text-input"
              name="member_middleName"
              value={this.state.member_middleName}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Member Relation
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Member Relation"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className="text-input"
              name="member_relation"
              value={this.state.member_relation}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Name Prifix
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Name Prefix"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className="text-input"
              name="member_namePrefix"
              value={this.state.member_namePrefix}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Name Suffix
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Name Suffix"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className="text-input"
              name="member_nameSuffix"
              value={this.state.member_nameSuffix}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Member No<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Member Number"
              type="number"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("member_memberNumber")}
              name="member_memberNumber"
              value={this.state.member_memberNumber}
            />
            {this.errorMessage(member_memberNumberError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Employer Name
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Employer Name"
              type="text"
              disabled={true}
              onChange={this.handleFormInputsOnChange}
              className="text-input"
              name="member_employerName"
              value={this.state.memberFullInfo.memberInfo_employerName}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Group Name
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Group Name"
              type="text"
              disabled={true}
              onChange={this.handleFormInputsOnChange}
              className="text-input"
              name="member_groupName"
              value={this.state.memberFullInfo.memberInfo_groupName}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Division Name
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Division Name"
              disabled={true}
              type="text"
              onChange={this.handleFormInputsOnChange}
              className="text-input"
              name="member_divisionName"
              value={this.state.memberFullInfo.memberInfo_divisionName}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Location Name
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Location Name"
              type="text"
              disabled={true}
              onChange={this.handleFormInputsOnChange}
              className="text-input"
              name="member_locationName"
              value={this.state.memberFullInfo.memberInfo_locationName}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}></Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              SSN<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter SSN"
              type="number"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("member_ssn")}
              name="member_ssn"
              value={this.state.member_ssn}
            />
            {this.errorMessage(member_ssnError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Link SSN
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Link SSN"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className="text-input"
              name="member_linkSsn"
              value={this.state.member_linkSsn}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Other ID<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Other"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("member_otherId")}
              name="member_otherId"
              value={this.state.member_otherId}
            />
            {this.errorMessage(member_otherIdError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Other Desc<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Other Desc"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("member_otherDesc")}
              name="member_otherDesc"
              value={this.state.member_otherDesc}
            />
            {this.errorMessage(member_otherDescError)}
          </Grid>
          <Divider style={{ margin: "20px 0" }} />
          <Grid item xs={12} sm={12} md={12}>
            <Typography className="form_address_label">Physical Address
              <span className="required_field_star_icon" style={{ color: "red" }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className="text-input-label">
              Address Line 1
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Address Line 1"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("member_physicalAddressLine1")}
              name="member_physicalAddressLine1"
              value={this.state.member_physicalAddressLine1}
            />
            {this.errorMessage(member_physicalAddressLine1Error)}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className="text-input-label">
              Address Line 2
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Address Line 2"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className="text-input"
              name="member_physicalAddressLine2"
              value={this.state.member_physicalAddressLine2}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              City/Town
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter City/Town Name"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("member_physicalCity")}
              name="member_physicalCity"
              value={this.state.member_physicalCity}
            />
            {this.errorMessage(member_physicalCityError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              State/County
            </div>
            <Select
              fullWidth
              label="Select State"
              className={this.handleFormFieldClassName("member_physicalState")}
              name="member_physicalState"
              value={this.state.member_physicalState}
              onChange={this.handleFormInputsOnChange}
            >
              <MenuItem className="member_form_list" value="">Select State</MenuItem>
              {configJSON.statesList?.map((state: string, indx: number) => {
                return (
                  <MenuItem className="member_form_list" key={indx} value={state}>{state}</MenuItem>
                )
              })}
            </Select>
            {this.errorMessage(member_physicalStateError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Zipcode/Postal Code
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Zipcode/Postal Code"
              type="number"
              data-testId = "member-physicalZipcode-input"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("member_physicalZipcode")}
              name="member_physicalZipcode"
              value={this.state.member_physicalZipcode}
            />
            {this.errorMessage(member_physicalZipcodeError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Country
            </div>
            <Select
              fullWidth
              label="Enter Country"
              className={this.handleFormFieldClassName("member_physicalCountry")}
              name="member_physicalCountry"
              data-testId="member-physical-country"
              value={this.state.member_physicalCountry}
              onChange={this.handleFormInputsOnChange}
            >
              <MenuItem className="member_form_list" value="">Select Country</MenuItem>
              {configJSON.countriesList.map((country: string, indx: number) => {
                return (
                  <MenuItem className="member_form_list" key={indx} value={country}>{country}</MenuItem>
                )
              })}
            </Select>
            {this.errorMessage(member_physicalCountryError)}
          </Grid>

          <Grid item xs={12} sm={12} md={12} className="address_heading_box">
            <Typography className="form_address_label">Mailing Address
              <span className="required_field_star_icon" style={{ color: "red" }}>*</span>
            </Typography>
            <span>
              <Checkbox
                checked={this.state.member_sameAsPhysicalAddress}
                onChange={this.handleSameAsPhysicalAddress}
                name="member_sameAsPhysicalAddress"
                color="primary"
                id="same-as-physical-address"
                data-testId="same-as-physical-address-checkbox"
              />
              <label
                htmlFor="same-as-physical-address"
                className="form_checkbox_label"
              > Mailing address same as physical address</label>
            </span>

          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className="text-input-label">
              Address Line 1
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Address Line 1"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("member_mailingAddressLine1")}
              name="member_mailingAddressLine1"
              value={this.handleMailingAddressValue(this.state.member_sameAsPhysicalAddress, this.state.member_physicalAddressLine1, this.state.member_mailingAddressLine1)}
            />
            {this.errorMessage(member_mailingAddressLine1Error)}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className="text-input-label">
              Address Line 2
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Address Line 2"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className="text-input"
              name="member_mailingAddressLine2"
              value={this.handleMailingAddressValue(this.state.member_sameAsPhysicalAddress, this.state.member_physicalAddressLine2, this.state.member_mailingAddressLine2)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              City/Town
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter City/Town Name"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("member_mailingCity")}
              name="member_mailingCity"
              value={this.handleMailingAddressValue(this.state.member_sameAsPhysicalAddress, this.state.member_physicalCity, this.state.member_mailingCity)}
            />
            {this.errorMessage(member_mailingCityError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              State/County
            </div>
            <Select
              fullWidth
              placeholder="Enter State Name"
              className={this.handleFormFieldClassName("member_mailingState")}
              name="member_mailingState"
              value={this.handleMailingAddressValue(this.state.member_sameAsPhysicalAddress, this.state.member_physicalState, this.state.member_mailingState)}
              onChange={this.handleFormInputsOnChange}
            >
              <MenuItem className="member_form_list" value="">Select State</MenuItem>
              {configJSON.statesList?.map((state: string, indx: number) => {
                return (
                  <MenuItem className="member_form_list" key={indx} value={state}>{state}</MenuItem>
                )
              })}
            </Select>
            {this.errorMessage(member_mailingStateError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Zipcode/Postal Code
            </div>
            <TextField
             data-testID = "member-mailingZipcode-input"
              fullWidth
              variant="outlined"
              placeholder="Enter Zipcode/Postal Code"
              type="number"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("member_mailingZipcode")}
              name="member_mailingZipcode"
              value={this.handleMailingAddressValue(this.state.member_sameAsPhysicalAddress, this.state.member_physicalZipcode, this.state.member_mailingZipcode)}
            />
            {this.errorMessage(member_mailingZipcodeError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Country
            </div>
            <Select
              fullWidth
              placeholder="Enter Country Name"
              className={this.handleFormFieldClassName("member_mailingCountry")}
              name="member_mailingCountry"
              data-testId="member-mailing-country"
              value={this.handleMailingAddressValue(this.state.member_sameAsPhysicalAddress, this.state.member_physicalCountry, this.state.member_mailingCountry)}
              onChange={this.handleFormInputsOnChange}
            >
              <MenuItem className="member_form_list" value="">Select Country</MenuItem>
              {configJSON.countriesList.map((country: string, indx: number) => {
                return (
                  <MenuItem className="member_form_list" key={indx} value={country}>{country}</MenuItem>
                )
              })}
            </Select>
            {this.errorMessage(member_mailingCountryError)}
          </Grid>

          <Divider style={{ margin: "20px 0" }} />

          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Gender<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Gender"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("member_gender")}
              name="member_gender"
              value={this.state.member_gender}
            />
            {this.errorMessage(member_genderError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Date of Birth<span style={{ color: "red" }}>*</span>
            </div>
            <Input
              fullWidth
              placeholder="Enter Date of Birth"
              type="date"
              onChange={this.handleDOBOnChange}
              className={this.handleDobFieldClassName("member_dob")}
              name="member_dob"
              value={this.state.member_dob}
              data-testId="member-dob-input"
            />
            {this.errorMessage(member_dobError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Age<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Member Age"
              type="number"
              className={this.handleFormFieldClassName("member_age")}
              name="member_age"
              value={this.state.member_age}
              disabled={true}
            />
            {this.errorMessage(member_ageError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}></Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Phone Number
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Phone Number"
              type="number"
              onChange={this.handleFormInputsOnChange}
              className="text-input"
              name="member_phoneNumber"
              value={this.state.member_phoneNumber}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Phone Number Ext
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Phone Number Ext"
              type="number"
              onChange={this.handleFormInputsOnChange}
              className="text-input"
              name="member_phoneNumberExt"
              value={this.state.member_phoneNumberExt}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Fax 1
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Fax 1"
              type="number"
              onChange={this.handleFormInputsOnChange}
              className="text-input"
              name="member_fax1"
              value={this.state.member_fax1}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Fax 2
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Fax 2"
              type="number"
              onChange={this.handleFormInputsOnChange}
              className="text-input"
              name="member_fax2"
              value={this.state.member_fax2}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Email Address<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              data-testId="member-email-input"
              fullWidth
              variant="outlined"
              placeholder="Enter Email Address"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("member_email")}
              name="member_email"
              value={this.state.member_email}
            />
            {this.errorMessage(member_emailError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Mobile<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              data-testId="member-mobileNumber-input"
              fullWidth
              variant="outlined"
              placeholder="Enter Mobile"
              type="number"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("member_mobileNumber")}
              name="member_mobileNumber"
              value={this.state.member_mobileNumber}
            />
            {this.errorMessage(member_mobileNumberError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Primary Language Spoken
            </div>
            <LanguageSelector
              navigation={this.props.navigation}
              id="languageSelector"
              selectedLanguage={this.state.selectedLanguage}
              openLanguageSelectionBox={this.state.openLanguageSelectionBox}
              languageInputValue={this.state.languageInputValue}
              languageList={this.state.languageList}
              languageSelectionClickHandler={() => this.setState({ openLanguageSelectionBox: !this.state.openLanguageSelectionBox })}
              searchInputOnChangeHandler={this.languageSearchInputOnChangeHandler}
              languageListClickHandler={this.languageListClickHandler}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Salary/wage
            </div>
            <div style={{
              display: "flex", background: "#F4F6FB",
              borderRadius: "10px",
              border: "0.5px solid #FFF",
              alignItems: "center"
            }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Enter Salary/wage"
                type="number"
                onChange={this.handleFormInputsOnChange}
                className="text-input"
                name="member_salary"
                value={this.state.member_salary}
                InputProps={{
                  startAdornment: (<InputAdornment position="start">$</InputAdornment>),
                }}
              />
              <Divider orientation="vertical" style={{ height: "40px" }} />
              <Select
                value={this.state.member_salaryType}
                onChange={this.handleFormInputsOnChange}
                name="member_salaryType"
                style={{ minWidth: "120px" }}
              >
                <MenuItem className="member_form_list" value="annual">Annual</MenuItem>
                <MenuItem className="member_form_list" value="hourly">Hourly</MenuItem>
              </Select>
            </div>

          </Grid>
        </Grid>
      </>
    );
  }
  showAddDependentInfoForm = () => {
    const {
      dependent_firstNameError,
      dependent_lastNameError,
      dependent_dobError,
      dependent_genderError,
      dependent_contactNumberError,
      dependent_ssnError,
      dependent_relationshipError,
      dependent_emailError,
      dependent_physicalAddressLine1Error,
      dependent_physicalCityError,
      dependent_physicalStateError,
      dependent_physicalZipcodeError,
      dependent_physicalCountryError,
      dependent_mailingAddressLine1Error,
      dependent_mailingCityError,
      dependent_mailingStateError,
      dependent_mailingZipcodeError,
      dependent_mailingCountryError,
    } = this.state.dependentFormErrors;
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <div className="addDependentFormTableLabel" style={{ marginBottom: "10px" }}>
              Dependent Info:
            </div>
            {this.showTableInfo(configJSON.dependentInfoTableColumn, this.state.dependentInfoList)}
          </Grid>
        </Grid>
        <Divider style={{ margin: "20px 0" }} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              First Name<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Enter First Name"
              onChange={this.handleFormInputsOnChange}
              type="text"
              className={this.handleFormFieldClassName("dependent_firstName")}
              name="dependent_firstName"
              value={this.state.dependent_firstName}
            />
            {this.errorMessage(dependent_firstNameError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Last Name<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Last Name"
              data-testId="member-last-name-input"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("dependent_lastName")}
              name="dependent_lastName"
              value={this.state.dependent_lastName}
            />
            {this.errorMessage(dependent_lastNameError)}
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Relationship<span style={{ color: "red" }}>*</span>
            </div>
            <Select
              fullWidth
              label="Select Relationship"
              className={this.handleFormFieldClassName("dependent_relationship")}
              name="dependent_relationship"
              value={this.state.dependent_relationship}
              onChange={this.handleFormInputsOnChange}
            >
              <MenuItem className="member_form_list" value="spouse">Spouse</MenuItem>
              <MenuItem className="member_form_list" value="child">Child</MenuItem>
              <MenuItem className="member_form_list" value="family">Family</MenuItem>
              <MenuItem className="member_form_list" value="sibling">Sibling</MenuItem>
              <MenuItem className="member_form_list" value="parent">Parent</MenuItem>
              <MenuItem className="member_form_list" value="other">other</MenuItem>
            </Select>
            {this.errorMessage(dependent_relationshipError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Gender<span style={{ color: "red" }}>*</span>
            </div>
            <Select
              fullWidth
              label="Select Gender"
              className={this.handleFormFieldClassName("dependent_gender")}
              name="dependent_gender"
              value={this.state.dependent_gender}
              onChange={this.handleFormInputsOnChange}
            >
              <MenuItem className="member_form_list" value="">Select Gender</MenuItem>
              <MenuItem className="member_form_list" value="male">Male</MenuItem>
              <MenuItem className="member_form_list" value="female">Female</MenuItem>
              <MenuItem className="member_form_list" value="others">Others</MenuItem>
            </Select>
            {this.errorMessage(dependent_genderError)}
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              SSN<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter SSN"
              type="number"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("dependent_ssn")}
              name="dependent_ssn"
              value={this.state.dependent_ssn}
            />
            {this.errorMessage(dependent_ssnError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Date of Birth<span style={{ color: "red" }}>*</span>
            </div>
            <Input
              fullWidth
              placeholder="Enter Date of Birth"
              type="date"
              onChange={this.handleFormInputsOnChange}
              className={this.handleDobFieldClassName("dependent_dob")}
              name="dependent_dob"
              value={this.state.dependent_dob}
            />
            {this.errorMessage(dependent_dobError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Email Address<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              data-testId="dependent-email-input"
              fullWidth
              variant="outlined"
              placeholder="Enter Email Address"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("dependent_email")}
              name="dependent_email"
              value={this.state.dependent_email}
            />
            {this.errorMessage(dependent_emailError)}
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Contact Number<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              data-testId="dependent-contactNumber-input"
              fullWidth
              variant="outlined"
              placeholder="Enter Contact Number"
              type="number"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("dependent_contactNumber")}
              name="dependent_contactNumber"
              value={this.state.dependent_contactNumber}
            />
            {this.errorMessage(dependent_contactNumberError)}
          </Grid>
          <Divider style={{ margin: "20px 0" }} />
          <Grid item xs={12} sm={12} md={12}>
            <Typography className="form_address_label">Physical Address
              <span className="required_field_star_icon" style={{ color: "red" }}>*</span>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className="text-input-label">
              Address Line 1
            </div>
            <TextField
              variant="outlined"
              fullWidth
              name="dependent_physicalAddressLine1"
              placeholder="Enter Address Line 1"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("dependent_physicalAddressLine1")}
              value={this.state.dependent_physicalAddressLine1}
            />
            {this.errorMessage(dependent_physicalAddressLine1Error)}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className="text-input-label">
              Address Line 2
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Address Line 2"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className="text-input"
              name="dependent_physicalAddressLine2"
              value={this.state.dependent_physicalAddressLine2}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              City/Town
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter City/Town Name"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("dependent_physicalCity")}
              name="dependent_physicalCity"
              value={this.state.dependent_physicalCity}
            />
            {this.errorMessage(dependent_physicalCityError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              State/County
            </div>
            <Select
              fullWidth
              className={this.handleFormFieldClassName("dependent_physicalState")}
              name="dependent_physicalState"
              onChange={this.handleFormInputsOnChange}
              value={this.state.dependent_physicalState}
            >
              <MenuItem className="member_form_list" value="">Select State</MenuItem>
              {configJSON.statesList?.map((state: string, indx: number) => {
                return (
                  <MenuItem className="member_form_list" key={indx} value={state}>{state}</MenuItem>
                )
              })}
            </Select>
            {this.errorMessage(dependent_physicalStateError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Zipcode/Postal Code
            </div>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              data-testId = "dependent-physicalZipcode-input"
              placeholder="Enter Zipcode/Postal Code"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("dependent_physicalZipcode")}
              value={this.state.dependent_physicalZipcode}
              name="dependent_physicalZipcode"
            />
            {this.errorMessage(dependent_physicalZipcodeError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Country
            </div>
            <Select
              fullWidth
              label="Enter Country"
              className={this.handleFormFieldClassName("dependent_physicalCountry")}
              name="dependent_physicalCountry"
              onChange={this.handleFormInputsOnChange}
              data-testId="dependent-physical-country"
              value={this.state.dependent_physicalCountry}
            >
              <MenuItem className="member_form_list" value="">Select Country</MenuItem>
              {configJSON.countriesList.map((country: string, indx: number) => {
                return (
                  <MenuItem className="member_form_list" key={indx} value={country}>{country}</MenuItem>
                )
              })}
            </Select>
            {this.errorMessage(dependent_physicalCountryError)}
          </Grid>

          <Grid item xs={12} sm={12} md={12} className="address_heading_box">
            <Typography className="form_address_label">Mailing Address
              <span className="required_field_star_icon" style={{ color: "red" }}>*</span>
            </Typography>
            <span>
              <Checkbox
                checked={this.state.dependent_sameAsPhysicalAddress}
                onChange={this.handleSameAsPhysicalAddress}
                name="dependent_sameAsPhysicalAddress"
                color="primary"
                id="same-as-physical-address"
                data-testId="dependent-same-as-physical-address-checkbox"
              />
              <label
                htmlFor="same-as-physical-address"
                className="form_checkbox_label"
              > Mailing address same as physical address</label>
            </span>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className="text-input-label">
              Address Line 1
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Address Line 1"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("dependent_mailingAddressLine1")}
              name="dependent_mailingAddressLine1"
              value={this.handleMailingAddressValue(this.state.dependent_sameAsPhysicalAddress, this.state.dependent_physicalAddressLine1, this.state.dependent_mailingAddressLine1)}
            />
            {this.errorMessage(dependent_mailingAddressLine1Error)}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className="text-input-label">
              Address Line 2
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Address Line 2"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className="text-input"
              name="dependent_mailingAddressLine2"
              value={this.handleMailingAddressValue(this.state.dependent_sameAsPhysicalAddress, this.state.dependent_physicalAddressLine2, this.state.dependent_mailingAddressLine2)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              City/Town
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter City/Town Name"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("dependent_mailingCity")}
              name="dependent_mailingCity"
              value={this.handleMailingAddressValue(this.state.dependent_sameAsPhysicalAddress, this.state.dependent_physicalCity, this.state.dependent_mailingCity)}
            />
            {this.errorMessage(dependent_mailingCityError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              State/County
            </div>
            <Select
              fullWidth
              placeholder="Enter State Name"
              className={this.handleFormFieldClassName("dependent_mailingState")}
              onChange={this.handleFormInputsOnChange}
              value={this.handleMailingAddressValue(this.state.dependent_sameAsPhysicalAddress, this.state.dependent_physicalState, this.state.dependent_mailingState)}
              name="dependent_mailingState"
            >
              <MenuItem className="member_form_list" value="">Select State</MenuItem>
              {configJSON.statesList?.map((state: string, indx: number) => {
                return (
                  <MenuItem className="member_form_list" key={indx} value={state}>{state}</MenuItem>
                )
              })}
            </Select>
            {this.errorMessage(dependent_mailingStateError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Zipcode/Postal Code
            </div>
            <TextField
              variant="outlined"
              fullWidth
              data-testId = "dependent-mailingZipcode-input"
              placeholder="Enter Zipcode/Postal Code"
              type="number"
              onChange={this.handleFormInputsOnChange}
              name="dependent_mailingZipcode"
              value={this.handleMailingAddressValue(this.state.dependent_sameAsPhysicalAddress, this.state.dependent_physicalZipcode, this.state.dependent_mailingZipcode)}
              className={this.handleFormFieldClassName("dependent_mailingZipcode")}
            />
            {this.errorMessage(dependent_mailingZipcodeError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Country
            </div>
            <Select
              fullWidth
              placeholder="Enter Country Name"
              className={this.handleFormFieldClassName("dependent_mailingCountry")}
              name="dependent_mailingCountry"
              data-testId="dependent-mailing-country"
              value={this.handleMailingAddressValue(this.state.dependent_sameAsPhysicalAddress, this.state.dependent_physicalCountry, this.state.dependent_mailingCountry)}
              onChange={this.handleFormInputsOnChange}
            >
              <MenuItem className="member_form_list" value="">Select Country</MenuItem>
              {configJSON.countriesList.map((country: string, indx: number) => {
                return (
                  <MenuItem className="member_form_list" key={indx} value={country}>{country}</MenuItem>
                )
              })}
            </Select>
            {this.errorMessage(dependent_mailingCountryError)}
          </Grid>

        </Grid>
      </>
    );

  }
  showAddBeneficiaryInfoForm = () => {
    const {
      beneficiary_firstNameError,
      beneficiary_lastNameError,
      beneficiary_dobError,
      beneficiary_genderError,
      beneficiary_contactNumberError,
      beneficiary_percentageError,
      beneficiary_relationshipError,
      beneficiary_emailError,
      beneficiary_beneficiaryError,
      beneficiary_physicalAddressLine1Error,
      beneficiary_physicalCityError,
      beneficiary_physicalStateError,
      beneficiary_physicalZipcodeError,
      beneficiary_physicalCountryError,
      beneficiary_mailingAddressLine1Error,
      beneficiary_mailingCityError,
      beneficiary_mailingStateError,
      beneficiary_mailingZipcodeError,
      beneficiary_mailingCountryError,
    } = this.state.beneficiaryFormErrors;
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <div className="addDependentFormTableLabel" style={{ marginBottom: "10px" }}>
              Beneficiary Info:
            </div>
            {this.showTableInfo(configJSON.beneficiaryInfoTableColumn, this.state.beneficiaryInfoList)}
          </Grid>
        </Grid>
        <Divider style={{ margin: "20px 0" }} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              First Name<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              className={this.handleFormFieldClassName("beneficiary_firstName")}
              fullWidth
              variant="outlined"
              placeholder="Enter First Name"
              type="text"
              onChange={this.handleFormInputsOnChange}
              name="beneficiary_firstName"
              value={this.state.beneficiary_firstName}
            />
            {this.errorMessage(beneficiary_firstNameError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Last Name<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Last Name"
              data-testId="member-last-name-input"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("beneficiary_lastName")}
              name="beneficiary_lastName"
              value={this.state.beneficiary_lastName}
            />
            {this.errorMessage(beneficiary_lastNameError)}
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Relationship<span style={{ color: "red" }}>*</span>
            </div>
            <Select
              fullWidth
              label="Select Relationship"
              className={this.handleFormFieldClassName("beneficiary_relationship")}
              name="beneficiary_relationship"
              onChange={this.handleFormInputsOnChange}
              value={this.state.beneficiary_relationship}
            >
              <MenuItem className="member_form_list" value="child">Child</MenuItem>
              <MenuItem className="member_form_list" value="spouse">Spouse</MenuItem>
              <MenuItem className="member_form_list" value="family">Family</MenuItem>
              <MenuItem className="member_form_list" value="parent">Parent</MenuItem>
              <MenuItem className="member_form_list" value="sibling">Sibling</MenuItem>
              <MenuItem className="member_form_list" value="other">other</MenuItem>
            </Select>
            {this.errorMessage(beneficiary_relationshipError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label gender_label">
              Gender<span style={{ color: "red" }}>*</span>
            </div>
            <Select
              fullWidth
              name="beneficiary_gender"
              label="Select Gender"
              className={this.handleFormFieldClassName("beneficiary_gender")}
              value={this.state.beneficiary_gender}
              onChange={this.handleFormInputsOnChange}
            >
              <MenuItem className="member_form_list" value="">Select Gender</MenuItem>
              <MenuItem className="member_form_list" value="male">Male</MenuItem>
              <MenuItem className="member_form_list" value="female">Female</MenuItem>
              <MenuItem className="member_form_list" value="others">Others</MenuItem>
            </Select>
            {this.errorMessage(beneficiary_genderError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Percentage<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              data-testId="member_percentage"
              fullWidth
              variant="outlined"
              placeholder="Enter Percentage"
              type="number"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("beneficiary_percentage")}
              name="beneficiary_percentage"
              value={this.state.beneficiary_percentage}
            />
            {this.errorMessage(beneficiary_percentageError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Date of Birth<span style={{ color: "red" }}>*</span>
            </div>
            <Input
              fullWidth
              placeholder="Enter Date of Birth"
              type="date"
              onChange={this.handleFormInputsOnChange}
              className={this.handleDobFieldClassName("beneficiary_dob")}
              name="beneficiary_dob"
              value={this.state.beneficiary_dob}
            />
            {this.errorMessage(beneficiary_dobError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Email Address<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              data-testId="beneficiary-email-input"
              fullWidth
              variant="outlined"
              placeholder="Enter Email"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("beneficiary_email")}
              name="beneficiary_email"
              value={this.state.beneficiary_email}
            />
            {this.errorMessage(beneficiary_emailError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Contact Number<span style={{ color: "red" }}>*</span>
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Contact Number"
              type="number"
              data-testId = "beneficiary-contactNumber-input"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("beneficiary_contactNumber")}
              name="beneficiary_contactNumber"
              value={this.state.beneficiary_contactNumber}
            />
            {this.errorMessage(beneficiary_contactNumberError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Beneficiary<span style={{ color: "red" }}>*</span>
            </div>
            <RadioGroup className="beneficiary_radio_group text-input" aria-label="beneficiary" name="beneficiary_beneficiary" value={this.state.beneficiary_beneficiary} onChange={this.handleFormInputsOnChange}>
              <FormControlLabel value="primary" control={<Radio color="primary" />} label="Primary" />
              <FormControlLabel value="contingent" control={<Radio color="primary" />} label="Contingent" />
            </RadioGroup>
            {this.errorMessage(beneficiary_beneficiaryError)}
          </Grid>

          <Divider style={{ margin: "20px 0" }} />
          <Grid item xs={12} sm={12} md={12}>
            <Typography className="form_address_label">Physical Address
              <span className="required_field_star_icon" style={{ color: "red" }}>*</span>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className="text-input-label">
              Address Line 1
            </div>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Enter Address Line 1"
              type="text"
              onChange={this.handleFormInputsOnChange}
              name="beneficiary_physicalAddressLine1"
              value={this.state.beneficiary_physicalAddressLine1}
              className={this.handleFormFieldClassName("beneficiary_physicalAddressLine1")}
            />
            {this.errorMessage(beneficiary_physicalAddressLine1Error)}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className="text-input-label">
              Address Line 2
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Address Line 2"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className="text-input"
              name="beneficiary_physicalAddressLine2"
              value={this.state.beneficiary_physicalAddressLine2}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              City/Town
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter City/Town Name"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("beneficiary_physicalCity")}
              name="beneficiary_physicalCity"
              value={this.state.beneficiary_physicalCity}
            />
            {this.errorMessage(beneficiary_physicalCityError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              State/County
            </div>
            <Select
              fullWidth
              label="Select State"
              className={this.handleFormFieldClassName("beneficiary_physicalState")}
              onChange={this.handleFormInputsOnChange}
              name="beneficiary_physicalState"
              value={this.state.beneficiary_physicalState}
            >
              <MenuItem className="member_form_list" value="">Select State</MenuItem>
              {configJSON.statesList?.map((state: string, indx: number) => {
                return (
                  <MenuItem className="member_form_list" key={indx} value={state}>{state}</MenuItem>
                )
              })}
            </Select>
            {this.errorMessage(beneficiary_physicalStateError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Zipcode/Postal Code
            </div>
            <TextField
              fullWidth
              className={this.handleFormFieldClassName("beneficiary_physicalZipcode")}
              name="beneficiary_physicalZipcode"
              variant="outlined"
              placeholder="Enter Zipcode/Postal Code"
              type="number"
              onChange={this.handleFormInputsOnChange}
              data-testId = "beneficiary-physicalZipcode-input"
              value={this.state.beneficiary_physicalZipcode}
            />
            {this.errorMessage(beneficiary_physicalZipcodeError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Country
            </div>
            <Select
              fullWidth
              label="Enter Country"
              className={this.handleFormFieldClassName("beneficiary_physicalCountry")}
              name="beneficiary_physicalCountry"
              data-testId="beneficiary-physical-country"
              value={this.state.beneficiary_physicalCountry}
              onChange={this.handleFormInputsOnChange}
            >
              <MenuItem className="member_form_list" value="">Select Country</MenuItem>
              {configJSON.countriesList.map((country: string, indx: number) => {
                return (
                  <MenuItem className="member_form_list" key={indx} value={country}>{country}</MenuItem>
                )
              })}
            </Select>
            {this.errorMessage(beneficiary_physicalCountryError)}
          </Grid>

          <Grid item xs={12} sm={12} md={12} className="address_heading_box">
            <Typography className="form_address_label">Mailing Address
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <span>
              <Checkbox
                checked={this.state.beneficiary_sameAsPhysicalAddress}
                onChange={this.handleSameAsPhysicalAddress}
                name="beneficiary_sameAsPhysicalAddress"
                color="primary"
                id="same-as-physical-address"

              />
              <label
                htmlFor="same-as-physical-address"
                className="form_checkbox_label"
              > Mailing address same as physical address</label>
            </span>

          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className="text-input-label">
              Address Line 1
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Address Line 1"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("beneficiary_mailingAddressLine1")}
              name="beneficiary_mailingAddressLine1"
              value={this.handleMailingAddressValue(this.state.beneficiary_sameAsPhysicalAddress, this.state.beneficiary_physicalAddressLine1, this.state.beneficiary_mailingAddressLine1)}
            />
            {this.errorMessage(beneficiary_mailingAddressLine1Error)}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className="text-input-label">
              Address Line 2
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Address Line 2"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className="text-input"
              name="beneficiary_mailingAddressLine2"
              value={this.handleMailingAddressValue(this.state.beneficiary_sameAsPhysicalAddress, this.state.beneficiary_physicalAddressLine2, this.state.beneficiary_mailingAddressLine2)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              City/Town
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter City/Town Name"
              type="text"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("beneficiary_mailingCity")}
              name="beneficiary_mailingCity"
              value={this.handleMailingAddressValue(this.state.beneficiary_sameAsPhysicalAddress, this.state.beneficiary_physicalCity, this.state.beneficiary_mailingCity)}
            />
            {this.errorMessage(beneficiary_mailingCityError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              State/County
            </div>
            <Select
              fullWidth
              placeholder="Enter State Name"
              className={this.handleFormFieldClassName("beneficiary_mailingState")}
              name="beneficiary_mailingState"
              value={this.handleMailingAddressValue(this.state.beneficiary_sameAsPhysicalAddress, this.state.beneficiary_physicalState, this.state.beneficiary_mailingState)}
              onChange={this.handleFormInputsOnChange}
            >
              <MenuItem className="member_form_list" value="">Select State</MenuItem>
              {configJSON.statesList?.map((state: string, indx: number) => {
                return (
                  <MenuItem className="member_form_list" key={indx} value={state}>{state}</MenuItem>
                )
              })}
            </Select>
            {this.errorMessage(beneficiary_mailingStateError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Zipcode/Postal Code
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Enter Zipcode/Postal Code"
              type="text"
              data-testId = "beneficiary-mailingZipcode-input"
              onChange={this.handleFormInputsOnChange}
              className={this.handleFormFieldClassName("beneficiary_mailingZipcode")}
              name="beneficiary_mailingZipcode"
              value={this.handleMailingAddressValue(this.state.beneficiary_sameAsPhysicalAddress, this.state.beneficiary_physicalZipcode, this.state.beneficiary_mailingZipcode)}
            />
            {this.errorMessage(beneficiary_mailingZipcodeError)}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="text-input-label">
              Country
            </div>
            <Select
              fullWidth
              placeholder="Enter Country Name"
              className={this.handleFormFieldClassName("beneficiary_mailingCountry")}
              name="beneficiary_mailingCountry"
              data-testId="beneficiary-mailing-country"
              value={this.handleMailingAddressValue(this.state.beneficiary_sameAsPhysicalAddress, this.state.beneficiary_physicalCountry, this.state.beneficiary_mailingCountry)}
              onChange={this.handleFormInputsOnChange}
            >
              <MenuItem className="member_form_list" value="">Select Country</MenuItem>
              {configJSON.countriesList.map((country: string, indx: number) => {
                return (
                  <MenuItem className="member_form_list" key={indx} value={country}>{country}</MenuItem>
                )
              })}
            </Select>
            {this.errorMessage(beneficiary_mailingCountryError)}
          </Grid>
        </Grid>
      </>
    );
  }
  showEditProfilePictureForm = () => {
    const { editProfilePicFormError } = this.state;

    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <div className="file_input_heading">
              Upload Profile Picture (format:jpg,png)
            </div>
            <Box className="file-input-label">
              <label htmlFor="edit-form-profile-pic">
                Choose a File
              </label>
              <input
                placeholder="Choose a File"
                type="file"
                id="edit-form-profile-pic"
                onChange={this.handleFileChange}
                accept=".jpg, .jpeg, .png"
                className="file-input profile_pic_input"
                name="member_profile_pic"
              />
            </Box>
            <Typography>
              {this.state.member_profile_pic}
            </Typography>
            {editProfilePicFormError && <div className="validation_errors">{editProfilePicFormError}</div>}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className="file_input_heading">
              Preview
            </div>
            <Box className="profile_pic_preview_container">
              {this.previewProfilePic(this.state.memberFullInfo.memberInfo_profile_pic)}
            </Box>
          </Grid>
          <Divider style={{ margin: "30px 0" }} />
          <Grid item xs={12} sm={12} md={12}>
            <div className="file_input_heading">
              Upload Document (format: doc, pdf)
            </div>
            <Box className="file-input-label">
              <label htmlFor="edit-profile-documents">
                Choose a File
              </label>
              <input
                placeholder="Choose a File"
                type="file"
                id="edit-profile-documents"
                onChange={this.handleFileChange}
                className="file-input member_documents_input"
                name="member_documents"
                accept=".doc, .pdf"
              />
            </Box>
            <Typography>{this.state.member_documents}</Typography>
          </Grid>
        </Grid>
      </>
    );
  }
  showWellnessForm = () => {
    return (
      <>
        <Grid container spacing={3}>
          Wellness Form
        </Grid>
      </>
    );
  }
}
