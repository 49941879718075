import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { FilterChild } from "../../../components/src/CustomFilterPopup.web";

export const configJSON = require("./config");
export const images = require("./assets");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  searchValue: string;
  healthcareDrList: HealthcareDr[];
  openContactUsDialog: boolean;
  selectedContactDetails: HealthcareDr;
  openFilter: boolean;
  filterChildList: FilterChild[];
}

interface SS {
  id: any;
}

interface HealthcareDr {
  key?: string;
  img?: string;
  firstName?: string;
  lastName?: string;
  specialist?: string;
  address?: string;
  area?: string;
  phone?: string;
  email?: string;
}

const HealthcareDrList = [
  {
    key: "1",
    img: images.healthcareDr1,
    firstName: configJSON.healthcareDrFirstName1,
    lastName: configJSON.healthcareDrLastName1,
    specialist: configJSON.healthcareDrSpecialist1,
    address: configJSON.address1,
    area: configJSON.addressArea1,
    phone: "+1-265-265-8461",
    email: "dr1@dummy.com",
  },
  {
    key: "2",
    img: images.healthcareDr2,
    firstName: configJSON.healthcareDrFirstName2,
    lastName: configJSON.healthcareDrLastName2,
    specialist: configJSON.healthcareDrSpecialist2,
    address: configJSON.address2,
    area: configJSON.addressArea2,
    phone: "+1-265-265-8462",
    email: "dr2@dummy.com",
  },
  {
    key: "3",
    img: images.healthcareDr3,
    firstName: configJSON.healthcareDrFirstName3,
    lastName: configJSON.healthcareDrLastName3,
    specialist: configJSON.healthcareDrSpecialist3,
    address: configJSON.address3,
    area: configJSON.addressArea3,
    phone: "+1-265-265-8463",
    email: "dr3@dummy.com",
  },
];

export default class FindHealthcareController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    const specialismList = [
      ...new Set(HealthcareDrList.map((list) => list.specialist)),
    ].map((list) => ({
      key: list,
      isChecked: false,
    }));

    const areaList = [
      ...new Set(HealthcareDrList.map((list) => list.area)),
    ].map((list) => ({
      key: list,
      isChecked: false,
    }));

    const lastNameList = [
      ...new Set(HealthcareDrList.map((list) => list.lastName)),
    ].map((list) => ({
      key: list,
      isChecked: false,
    }));

    this.state = {
      searchValue: "",
      healthcareDrList: HealthcareDrList,
      openContactUsDialog: false,
      selectedContactDetails: {},
      openFilter: false,
      filterChildList: [
        {
          key: "Specialism",
          isOpen: true,
          searchString: "",
          viewAll: false,
          list: specialismList,
          mainList: specialismList,
        },
        {
          key: "Area",
          isOpen: false,
          searchString: "",
          viewAll: false,
          list: areaList,
          mainList: areaList,
        },
        {
          key: "Last Name",
          isOpen: false,
          searchString: "",
          viewAll: false,
          list: lastNameList,
          mainList: lastNameList,
        },
      ],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  onSearchInputChange = (e: React.ChangeEvent<any>) => {
    const searchKey = e.target.value;
    this.setState({ searchValue: searchKey });
  };

  handleContactUsClick = (key: string) => {
    this.setState({
      openContactUsDialog: true,
      selectedContactDetails: HealthcareDrList.filter(
        (list) => list.key === key
      )[0],
    });
  };

  handleClose = () => {
    this.setState({ openContactUsDialog: false });
  };

  handleFilterClick = () => {
    this.setState({ openFilter: !this.state.openFilter });
  };

  handleFilterOrSearchOnChange = (newState: FilterChild[]) => {
    this.setState({ filterChildList: newState });
    const filterString = ([] as string[]).concat(
      ...newState.map((n) =>
        n.mainList.filter((l) => l.isChecked).map((l) => l.key)
      )
    );

    const newHealthcareDrListAfterFilter = HealthcareDrList.filter((record) => {
      return filterString.length
        ? filterString
            .map(
              (filter) =>
                record.lastName.toLowerCase().includes(filter.toLowerCase()) ||
                record.specialist
                  .toLowerCase()
                  .includes(filter.toLowerCase()) ||
                record.address.toLowerCase().includes(filter.toLowerCase())
            )
            .some((filter) => filter)
        : true;
    });

    const newHealthcareDrList = newHealthcareDrListAfterFilter.filter(
      (record) => {
        return (
          record.lastName
            ?.toLowerCase()
            .includes(this.state.searchValue.toLowerCase()) ||
          record.specialist
            ?.toLowerCase()
            .includes(this.state.searchValue.toLowerCase()) ||
          record.address
            ?.toLowerCase()
            .includes(this.state.searchValue.toLowerCase())
        );
      }
    );
    this.setState({ healthcareDrList: newHealthcareDrList });
  };
}
