import { IBlock } from "../../../../framework/src/IBlock";
import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
export const configJSON = require("../config");
import { StepList } from "../../../../components/src/CustomVerticalFormStepper.web";

import {
  BasicDetailsJSONData,
  EmployerData,
  IEmployerAddressDetailModel,
  IEmployerDetailModel,
  basicDetailsAPIData,
  initialEMPAgentData,
  initialEmployerDetailModelData
} from "./ClientEmployerModel";
import { Message } from "../../../../framework/src/Message";
import { IAgentModel } from "../../../../components/src/GenericAddAgentComponent.web";
import { Countries, States } from "../ClientMember/ClientMemberModel";

export interface Props {
  isOpen: boolean;
  isEdit: boolean;
  handleClose: any;
  saveEmployerData: any;
  employerDetails: IEmployerDetailModel;
  token: string;
  basicDataForAPI: EmployerData;
  clientName: string;
  clientId: string;
}
interface SelectionList {
  id: number | string;
  name: string;
}

interface S {
  employerActiveStep: number;
  isOpenWarningDialog: boolean;
  editEmployerFormStepList: StepList[];
  isOpenGroupSearch: boolean;
  groupSearchInput: string;
  countryList: SelectionList[];
  stateList: SelectionList[];
  mailingStateList: SelectionList[];
  employerData: IEmployerDetailModel;
  isSecondStepDirty: boolean;
  isMailingAddressSameAsPhysicalAddress: boolean;
  payrollDayList: SelectionList[];
  payrollMonthList: SelectionList[];
  payrollCycleList: SelectionList[];
  createApiErrors: { [key: string]: string[] };
  isEmployerCreated: boolean;
  ApiEmployerId: string;
  isAddressSaved: boolean;
  isEmployerAdded: boolean;
  saveAndNextSteps: {
    basicDetails: boolean;
    addressContact: boolean;
    upload: boolean;
    otherDetails: boolean;
  };
  basicDataForEditAPI: EmployerData;
  EditDataAPI: BasicDetailsJSONData;
  isMailing: boolean;
  basicDetailsCompleated: boolean;
}

interface SS {
  id: any;
}

export default class EditClientEmpFormDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  empCountryCallId: string = "";
  empStateCallId: string = "";
  empPostApiId: string = "";
  empAddressPostApiId: string = "";
  empOtherDetailsPostApiId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      employerActiveStep: 0,
      isOpenWarningDialog: false,
      isOpenGroupSearch: false,
      groupSearchInput: "",
      employerData: initialEmployerDetailModelData,
      editEmployerFormStepList: [
        {
          key: 1,
          title: "Employer Basic Details",
          description: "Type and Select details",
          isCompleted: false
        },
        {
          key: 2,
          title: "Address & Contact",
          description: "Type and Select details",
          isCompleted: false
        },
        {
          key: 3,
          title: "Upload Logo / Supporting Document",
          description: "Type and Select details",
          isCompleted: false
        },
        {
          key: 4,
          title: "Other Details",
          description: "Type and Select details",
          isCompleted: false
        }
      ],
      isSecondStepDirty: false,
      countryList: [],
      stateList: [],
      mailingStateList: [],
      isMailingAddressSameAsPhysicalAddress: false,
      payrollDayList: configJSON.payrollDayList,
      payrollMonthList: configJSON.payrollMonthList,
      payrollCycleList: configJSON.payrollCycleList,
      createApiErrors: {},
      isEmployerCreated: false,
      ApiEmployerId: "",
      isAddressSaved: false,
      isEmployerAdded: false,
      saveAndNextSteps: {
        basicDetails: false,
        addressContact: false,
        upload: true,
        otherDetails: false
      },
      basicDataForEditAPI: {},
      EditDataAPI: basicDetailsAPIData,
      isMailing: false,
      basicDetailsCompleated: false
    };
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.empCountryCallId) {
        this.receiveEmpCountryListFromAPI(responseJson);
      }
      if (apiRequestCallId === this.empStateCallId) {
        this.receiveEmpStateListFromAPI(responseJson);
      }
      if (apiRequestCallId === this.empPostApiId) {
        this.receiveEmpCreateAPIResponse(responseJson);
      }
      if (apiRequestCallId === this.empAddressPostApiId) {
        this.responseSaveAddressAPI(responseJson);
      }
      if (apiRequestCallId === this.empOtherDetailsPostApiId) {
        this.responseOtherDetailsAPI(responseJson);
      }
    }
  }

  async componentDidMount() {
    if (this.state.countryList.length === 0) {
      this.fetchCountryListAPI();
    }
    if (this.props.isEdit && !!this.props.basicDataForAPI.data) {
      const attr = this.props.basicDataForAPI.data.attributes;
      const addressStateId = this.props.employerDetails.employerAddressDetails;
      if (addressStateId.physical_country_id) {
        this.fetchStateListAPI(addressStateId.physical_country_id);
      }
      if (addressStateId.mailing_country_id) {
        this.fetchStateListAPI(addressStateId.mailing_country_id);
      }
      this.setState({
        isMailing: !!addressStateId.mailing_country_id,
        basicDataForEditAPI: this.props.basicDataForAPI,
        EditDataAPI: this.handleEditData(attr),
        ApiEmployerId: this.props.employerDetails.id
          ? this.props.employerDetails.id
          : "",
        employerData: { ...this.props.employerDetails },
        editEmployerFormStepList: this.state.editEmployerFormStepList.map(
          list => ({ ...list, isCompleted: true })
        )
      });
      return;
    }
    this.setState({
      employerData: {
        ...this.state.employerData,
        employerBasicDetails: {
          ...this.state.employerData.employerBasicDetails,
          client_name: this.props.clientName
        }
      }
    });
  }

  setActiveStep = (step: number) => {
    this.setState({ employerActiveStep: step });
  };

  handleCloseWarningDialog = (isAgreeToLooseData: boolean) => {
    if (isAgreeToLooseData) {
      this.props.handleClose();
    }
    this.setState({ ...this.state, isOpenWarningDialog: false });
  };
  setInitialState = () => {
    this.setState({
      ...this.state,
      employerActiveStep: 0,
      employerData: initialEmployerDetailModelData,
      isOpenWarningDialog: false
    });
  };
  handleNext = (isFinish?: boolean) => {
    if (isFinish) {
      this.props.saveEmployerData();
    } else {
      this.handleSteps(true);
    }
  };

  handleSteps = (next: boolean) => {
    const activeStep = next
      ? this.state.employerActiveStep + 1
      : this.state.employerActiveStep - 1;
    const length = this.state.editEmployerFormStepList.length;
    if (length > activeStep) {
      this.setState({
        employerActiveStep: activeStep,
        editEmployerFormStepList: this.state.editEmployerFormStepList.map(
          (list, index) => {
            if (index === this.state.employerActiveStep) {
              return {
                ...list,
                isCompleted: true
              };
            }
            return list;
          }
        )
      });
    }
  };

  handleMemberDialogClose = () => {
    if (
      JSON.stringify(this.props.employerDetails) !==
      JSON.stringify(this.state.employerData)
    ) {
      this.setState({ ...this.state, isOpenWarningDialog: true });
    } else {
      this.setInitialState();
      this.props.handleClose();
    }
  };

  handleEmployerInputItemChange = (
    e: { target: { value: string } },
    param: string
  ) => {
    let inputValue = e.target.value;
    if (param === "tax_id") {
      let formattedTaxId = inputValue.replace(/\D/g, "");
      if (formattedTaxId.length > 2) {
        formattedTaxId =
          formattedTaxId.slice(0, 2) + "-" + formattedTaxId.slice(2);
      }
      if (formattedTaxId.length > 10) {
        formattedTaxId = formattedTaxId.slice(0, 10);
      }
      inputValue = formattedTaxId;
    } else if (param === "emp_id") {
      let formattedEmpId = inputValue.replace(/[^a-zA-Z0-9]/, "");
      inputValue = formattedEmpId;
    } else if (param === "employer_name") {
      const values = inputValue.replace(/[1-9]/g, "");
      inputValue = values;
    }
    this.setState({
      ...this.state,
      employerData: {
        ...this.state.employerData,
        employerBasicDetails: {
          ...this.state.employerData.employerBasicDetails,
          [param]: inputValue
        }
      }
    });
  };

  addEMPAgent = () => {
    const newAgentList = [
      ...this.state.employerData.employerOtherDetails.agentsDetails
    ];
    newAgentList.push(initialEMPAgentData);
    this.setAgentList(newAgentList);
  };

  removeAgent = (index: number) => {
    const newAgentList = this.state.employerData.employerOtherDetails.agentsDetails.filter(
      (_, i) => i !== index
    );
    this.setAgentList(newAgentList);
  };

  setAgentList = (newAgentList: IAgentModel[]) => {
    this.setState({
      ...this.state,
      employerData: {
        ...this.state.employerData,
        employerOtherDetails: {
          ...this.state.employerData.employerOtherDetails,
          agentsDetails: newAgentList
        }
      }
    });
  };

  handleEmpMailingAddressCheckbox = (event: {
    target: { checked: boolean };
  }) => {
    const isChecked = event.target.checked;
    const {
      physical_address_line1,
      physical_address_line2,
      physical_city,
      physical_country_id,
      physical_state_id,
      physical_zip_code
    } = this.state.employerData.employerAddressDetails;
    let dataOfState = {
      isMailingAddressSameAsPhysicalAddress: isChecked,
      employerData: {
        ...this.state.employerData,
        employerAddressDetails: {
          ...this.state.employerData.employerAddressDetails,
          mailing_address_line1: "",
          mailing_address_line2: "",
          mailing_city: "",
          mailing_country_id: "",
          mailing_state_id: "",
          mailing_zip_code: ""
        }
      }
    };
    if (isChecked) {
      dataOfState = {
        isMailingAddressSameAsPhysicalAddress: isChecked,
        employerData: {
          ...this.state.employerData,
          employerAddressDetails: {
            ...this.state.employerData.employerAddressDetails,
            mailing_address_line1: physical_address_line1,
            mailing_address_line2: physical_address_line2,
            mailing_city: physical_city,
            mailing_country_id: physical_country_id,
            mailing_state_id: physical_state_id,
            mailing_zip_code: physical_zip_code
          }
        }
      };
    }
    this.setState({
      ...dataOfState,
      mailingStateList: isChecked ? this.state.stateList : []
    });
  };
  handleEmpIsActiveCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    this.setState({
      employerData: {
        ...this.state.employerData,
        employerBasicDetails: {
          ...this.state.employerData.employerBasicDetails,
          is_active: isChecked
        }
      }
    });
  };
  saveAndNext = () => {
    this.handleNext();
  };

  handleRequireErrors = () => {
    const {
      employer_name,
      emp_id,
      tax_id
    } = this.state.employerData.employerBasicDetails;
    const errors = {
      employer_name: this.fieldErrorHandling(employer_name),
      emp_id: this.fieldErrorHandling(emp_id),
      tax_id: this.fieldErrorHandling(tax_id)
    };
    this.setState({
      createApiErrors: errors
    });
    this.handleCreateEmployer(errors);
  };

  handleCreateEmployer = (errors: {
    employer_name: string[];
    emp_id: string[];
    tax_id: string[];
  }) => {
    const isAPIcall = Object.values(errors)
      .map(i => (i.length ? true : false))
      .includes(true);
    if (!isAPIcall) {
      this.callEmployerCreateAPI(this.state.employerData);
    }
  };

  fieldErrorHandling = (value: string | number) => {
    if (!value) {
      return ["Require field"];
    } else {
      return [];
    }
  };

  saveAndFinish = () => {
    const { employerActiveStep } = this.state;
    const editedEmployerData = { ...this.state.employerData };
    if (employerActiveStep === 0) {
      this.handleRequireErrors();
    } else {
      if (!this.state.ApiEmployerId || !this.state.basicDataForEditAPI.data) {
        this.handleRequireErrors();
        this.setState({ employerActiveStep: 0 });
      } else {
        if (employerActiveStep === 1) {
          this.employerAddressAPI(editedEmployerData);
        } else if (employerActiveStep === 3) {
          this.handleAddEmployerLastStep();
        }
      }
    }
    this.props.saveEmployerData(editedEmployerData);
  };
  fetchCountryListAPI = () => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.empCountryCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCountryAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEmpAddressItemChange = (
    e: { target: { value: string } },
    param: string
  ) => {
    const {
      mailing_phone_number1,
      mailing_phone_number2
    } = this.state.employerData.employerAddressDetails;
    let eventValue = e.target.value;
    if (
      param === "mailing_contact1" ||
      param === "mailing_contact2" ||
      param === "physical_city" ||
      param === "mailing_city"
    ) {
      const values = eventValue.replace(/[1-9]/g, "");
      eventValue = values;
    } else if (
      param === "mailing_phone_number1" ||
      param === "mailing_phone_number2"
    ) {
      if (eventValue.length > 10) {
        eventValue =
          param === "mailing_phone_number1"
            ? mailing_phone_number1
            : mailing_phone_number2;
      }
    } else if (
      param === "mailing_country_id" ||
      param === "physical_country_id"
    ) {
      this.fetchStateListAPI(eventValue);
    }
    this.setState({
      ...this.state,
      isMailing: param === "mailing_country_id",
      employerData: {
        ...this.state.employerData,
        employerAddressDetails: {
          ...this.state.employerData.employerAddressDetails,
          [param]: eventValue
        }
      }
    });
  };

  fetchStateListAPI = (countryCode: string) => {
    const header = {
      "Content-Type": configJSON.applicationJsonContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.empStateCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStateFromCountryAPiEndPoint}${countryCode}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEmpCountryData = (data: Countries[]) =>
    data.map(({ CountryName, countryCode }: Countries) => ({
      id: countryCode,
      name: CountryName
    }));

  handleEmpStateData = (data: States[]) =>
    data.map(({ StateName }: States) => ({
      id: StateName,
      name: StateName
    }));

  receiveEmpCountryListFromAPI(responseJson: any) {
    if (responseJson && responseJson.countries) {
      this.setState({
        countryList: this.handleEmpCountryData(responseJson.countries)
      });
    }
  }

  receiveEmpStateListFromAPI(responseJson: any) {
    if (responseJson && responseJson.states) {
      if (this.state.isMailing) {
        this.setState({
          mailingStateList: this.handleEmpStateData(responseJson.states)
        });
        const country = this.props.employerDetails.employerAddressDetails
          .physical_country_id;
        if (this.props.isEdit && !!country) {
          this.setState({ isMailing: false });
          this.fetchStateListAPI(country);
        }
      } else {
        this.setState({
          stateList: this.handleEmpStateData(responseJson.states)
        });
      }
    }
  }

  handleEmployerOtherDetailsInputItemChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { value: any }
    >,
    param: string,
    agentId?: number
  ) => {
    let inputValue = e.target.value;
    if (param === "agent_name") {
      const values = inputValue.replace(/[1-9]/g, "");
      inputValue = values;
    }
    if (agentId !== undefined) {
      this.setState({
        ...this.state,
        employerData: {
          ...this.state.employerData,
          employerOtherDetails: {
            ...this.state.employerData.employerOtherDetails,
            agentsDetails: this.state.employerData.employerOtherDetails.agentsDetails.map(
              (list, index) => {
                if (index === agentId) {
                  return {
                    ...list,
                    [param]: inputValue
                  };
                }
                return list;
              }
            )
          }
        }
      });
    } else {
      this.setState({
        ...this.state,
        employerData: {
          ...this.state.employerData,
          employerOtherDetails: {
            ...this.state.employerData.employerOtherDetails,
            [param]: inputValue
          }
        }
      });
    }
  };

  callEmployerCreateAPI = (fieldData: any) => {
    let HttpsReqType = configJSON.httpPostMethod;
    let endPoint = configJSON.createEmployerAPiEndPoint;
    const employer = { ...fieldData.employerBasicDetails };
    let values = {
      employer: {
        ...basicDetailsAPIData.employer,
        ...employer,
        account_id: this.props.clientId
      }
    };
    if (this.props.isEdit) {
      HttpsReqType = configJSON.httpPutMethod;
      endPoint = `${endPoint}/${this.state.ApiEmployerId}`;
      values = {
        employer: {
          ...this.state.EditDataAPI.employer,
          ...fieldData.employerBasicDetails,
          account_id: this.props.clientId
        }
      };
    }
    this.setState({ EditDataAPI: values });
    const physicalAdd = values.employer.physical_address_attributes;
    const mailingAdd = values.employer.mailing_address_attributes;
    physicalAdd.country = physicalAdd.country_id;
    physicalAdd.state = physicalAdd.state_id;
    mailingAdd.country = mailingAdd.country_id;
    mailingAdd.state = mailingAdd.state_id;

    delete physicalAdd.country_id;
    delete physicalAdd.state_id;
    delete mailingAdd.country_id;
    delete mailingAdd.state_id;
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.props.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.empPostApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      HttpsReqType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(values)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleContactData = (employerAddressDetails: IEmployerAddressDetailModel) => {
    const data = this.state.EditDataAPI.employer.contacts_attributes;
    const contact1Id = data[0] ? data[0].id : "";
    const contact2Id = data[1] ? data[1].id : "";
    const {
      mailing_contact1,
      mailing_email1,
      mailing_phone_number1,
      mailing_fax1,
      mailing_contact2,
      mailing_email2,
      mailing_phone_number2,
      mailing_fax2
    } = employerAddressDetails;
    const contact = [
      {
        id: contact1Id,
        contact: mailing_contact1,
        email: mailing_email1,
        phone_number: `${mailing_phone_number1}`,
        fax: mailing_fax1
      },
      {
        id: contact2Id,
        contact: mailing_contact2,
        email: mailing_email2,
        phone_number: `${mailing_phone_number2}`,
        fax: mailing_fax2
      }
    ];
    return contact;
  };

  handleCountryIdToName = (code: string) => {
    const countryName = this.state.countryList.find(
      (item: SelectionList) => item.id === code
    );
    return countryName ? countryName.name : code;
  };

  handlePhysicalAddress = (addressEditData: IEmployerAddressDetailModel) => {
    const data = this.state.EditDataAPI.employer.physical_address_attributes;
    const {
      physical_address_line1,
      physical_address_line2,
      physical_city,
      physical_zip_code,
      physical_state_id,
      physical_country_id
    } = addressEditData;

    const physical_address = {
      id: data.id,
      address_line_1: physical_address_line1,
      address_line_2: physical_address_line2,
      city: physical_city,
      state: physical_state_id,
      zip_code: physical_zip_code,
      country: this.handleCountryIdToName(physical_country_id)
    };
    return physical_address;
  };

  handleMailingAddress = (addressEditData: IEmployerAddressDetailModel) => {
    const data = this.state.EditDataAPI.employer.mailing_address_attributes;
    const {
      mailing_address_line1,
      mailing_address_line2,
      mailing_city,
      mailing_zip_code,
      mailing_state_id,
      mailing_country_id
    } = addressEditData;
    const physical_address = {
      id: data.id,
      address_line_1: mailing_address_line1,
      address_line_2: mailing_address_line2,
      city: mailing_city,
      state: mailing_state_id,
      zip_code: mailing_zip_code,
      country: this.handleCountryIdToName(mailing_country_id)
    };
    return physical_address;
  };

  handleContactErrors = () => {
    const {
      mailing_email1,
      mailing_email2
    } = this.state.employerData.employerAddressDetails;
    const testRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const errors = {
      "contacts.email1":
        mailing_email1 && !testRegex.test(mailing_email1)
          ? ["Invalid Email"]
          : [""],
      "contacts.email2":
        mailing_email2 && !testRegex.test(mailing_email2)
          ? ["Invalid Email"]
          : [""]
    };

    this.setState({ createApiErrors: errors });

    return Object.values(errors).some(errorArray => errorArray[0] !== "");
  };

  employerAddressAPI = (addressData: any) => {
    if (this.handleContactErrors()) {
      return;
    }
    const addressEditData = addressData.employerAddressDetails;
    const dataAPI = this.state.EditDataAPI.employer;
    let payloadBody = {
      employer: {
        ...dataAPI,
        contacts_attributes: this.handleContactData(addressEditData),
        physical_address_attributes: this.handlePhysicalAddress(
          addressEditData
        ),
        mailing_address_attributes: this.handleMailingAddress(addressEditData)
      }
    };

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.props.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.empAddressPostApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createEMPAddressEndpoint}${this.state.ApiEmployerId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payloadBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAddEmployerLastStep = () => {
    const editedEmployerData = { ...this.state.employerData };
    this.employerOtherDetailsAPI(editedEmployerData);
  };

  handleSecoundDialogContant = (isEmployerAdded:boolean) => {
    if(isEmployerAdded){
      if(this.props.isEdit){
        return configJSON.employerEditedSuccessfull;
      }else{
        return configJSON.employerAddedSuccessfull;
      }
    }else{
      return configJSON.employerBasicDetailSaved;
    }
  }

  handleAgentsData = (agents: IAgentModel[]) => {
    const IdOfAgents = this.state.EditDataAPI.employer.agents_attributes;
    const data = agents.map((item, index) => {
      const alreadyData = IdOfAgents[index];
      let agent: { agent_name: string; agent_rate: string; id?: string } = {
        agent_name: item.agent_name,
        agent_rate: item.agent_rate
      };
      if (!!alreadyData && alreadyData.id) {
        agent.id = alreadyData.id;
      }
      return agent;
    });
    return data;
  };

  employerOtherDetailsAPI = (otherData: any) => {
    const oldData = this.state.EditDataAPI.employer;
    let payloadBody = {
      employer: {
        ...oldData,
        agents_attributes: this.handleAgentsData(
          otherData.employerOtherDetails.agentsDetails
        ),
        employer_other_detail_attributes: {
          ...oldData.employer_other_detail_attributes,
          ...otherData.employerOtherDetails
        }
      }
    };
    const physicalAdd = payloadBody.employer.physical_address_attributes;
    const mailingAdd = payloadBody.employer.mailing_address_attributes;
    physicalAdd.country = physicalAdd.country_id;
    physicalAdd.state = physicalAdd.state_id;
    mailingAdd.country = mailingAdd.country_id;
    mailingAdd.state = mailingAdd.state_id;

    delete physicalAdd.country_id;
    delete physicalAdd.state_id;
    delete mailingAdd.country_id;
    delete mailingAdd.state_id;
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.props.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.empOtherDetailsPostApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createEMPAddressEndpoint}${this.state.ApiEmployerId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payloadBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEditData = (data: any) => {
    const ContactData = data.contact.data.length
      ? data.contact.data
      : [{ id: "", attributes: {} }];
    const agentsData = data.agents.data.length
      ? data.agents.data
      : [{ id: "", attributes: {} }];
    const contactsId = ContactData.map((i: { id: string; attributes: {} }) => ({
      ...i.attributes,
      id: i.id
    }));
    const agentsId = agentsData.map((i: { id: string; attributes: {} }) => ({
      ...i.attributes,
      id: i.id
    }));
    const physicalID = this.handleIds(data.physical_address.data);
    const mailingID = this.handleIds(data.mailing_address.data);
    const otherDetailsID = this.handleIds(data.other_details.data);
    const employerData = this.props.isEdit
      ? data
      : this.state.EditDataAPI.employer;
    const editableData = {
      employer: {
        ...employerData,
        employer_other_detail_attributes: {
          ...employerData.employer_other_detail_attributes,
          id: otherDetailsID
        },
        mailing_address_attributes: {
          ...employerData.mailing_address_attributes,
          id: mailingID
        },
        agents_attributes: agentsId,
        physical_address_attributes: {
          ...employerData.physical_address_attributes,
          id: physicalID
        },
        contacts_attributes: contactsId
      }
    };
    return editableData;
  };

  handleIds = (data: { id: string } | null) => {
    return data ? data.id : "";
  };

  receiveEmpCreateAPIResponse(responseJson: any) {
    if (responseJson && responseJson.errors) {
      const errors = responseJson.errors;
      this.setState({ createApiErrors: errors });
    } else if (responseJson && responseJson.data && responseJson.data.id) {
      this.setState({
        createApiErrors: {},
        ApiEmployerId: responseJson.data.id,
        saveAndNextSteps: {
          ...this.state.saveAndNextSteps,
          basicDetails: true
        },
        basicDetailsCompleated: !this.props.isEdit ? true : false,
        basicDataForEditAPI: responseJson,
        EditDataAPI: this.handleEditData(responseJson.data.attributes)
      });
      this.handleSteps(true);
    }
  }
  responseSaveAddressAPI(responseJson: any) {
    if (responseJson && responseJson.data && responseJson.data.id) {
      this.setState({
        createApiErrors: {},
        isAddressSaved: true,
        EditDataAPI: this.handleEditData(responseJson.data.attributes),
        saveAndNextSteps: {
          ...this.state.saveAndNextSteps,
          addressContact: true
        }
      });
      this.handleSteps(true);
    } else {
      if (responseJson.tax_id) {
        this.setState({ employerActiveStep: 0 });
      }
      this.setState({ createApiErrors: responseJson });
    }
  }
  responseOtherDetailsAPI(responseJson: any) {
    if (responseJson && responseJson.errors) {
      const errors = responseJson.errors;
      this.setState({ createApiErrors: errors });
    } else if (responseJson && responseJson.data && responseJson.data.id) {
      this.setState({
        createApiErrors: {},
        isEmployerCreated: true,
        isEmployerAdded: true,
        saveAndNextSteps: { ...this.state.saveAndNextSteps, otherDetails: true }
      });
    }
  }
  getEmpErrorMessage = (fieldName: string) => {
    const { createApiErrors } = this.state;
    if (createApiErrors[fieldName]) {
      return createApiErrors[fieldName][0];
    }
  };
  handleSuccessDialogClose = () => {
    this.setState({ isEmployerCreated: false, isEmployerAdded: false });
    if (this.state.isEmployerAdded) {
      this.props.handleClose();
    }
  };
}
