Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MemberScreen";
exports.labelBodyText = "MemberScreen Body";

exports.btnExampleTitle = "CLICK ME";

// Validation constant
exports.maxLength = 30;
exports.descriptionMaxLength = 200;

// My Documents
exports.myDocuments = "My Documents";
exports.search = "Search";
exports.addDocument = "Add Document";
exports.description = "Description";
exports.descriptionRequired = "Description is required.";

// Find Healthcare
exports.findHealthcare = "Find a Healthcare Provider";
exports.estimatedTreatmentCost = "Estimated Treatment Cost";
exports.compareHospitalQuality = "Compare Hospital Quality";
exports.compareHospitalPrice = "Compare Hospital Price";
exports.flexibleSpendingAccount = "Flexible Spending Account";
exports.findHealthcareSearch = "Search by area, specialism, last name";
exports.healthcareDrFirstName1 = "Vivek";
exports.healthcareDrLastName1 = "Oberoi";
exports.healthcareDrSpecialist1 = "Cardilogist";
exports.address1 =
  "Vivek Clinic, Main Chhatarpur Rd, Block A1, Chhatarpur, New Delhi, Delhi 110074";
exports.addressArea1 = "New Delhi";
exports.address2 =
  "Vivek Clinic, Main Chhatarpur Rd, Block A1, Chhatarpur, Mumbai, Mumbai 110074";
exports.addressArea2 = "Mumbai";
exports.address3 =
  "Vivek Clinic, Main Chhatarpur Rd, Block A1, Chhatarpur, Kolkata, Kolkata 110074";
exports.addressArea3 = "Kolkata";
exports.address4 =
  "Vivek Clinic, Main Chhatarpur Rd, Block A1, Chhatarpur, Gujarat, Gujarat 110074";
exports.addressArea4 = "Gujarat";
exports.healthcareDrFirstName2 = "Mira";
exports.healthcareDrLastName2 = "Mathew";
exports.healthcareDrSpecialist2 = "Dentist";
exports.healthcareDrFirstName3 = "John";
exports.healthcareDrLastName3 = "Abraham";
exports.healthcareDrSpecialist3 = "Neurologists";
exports.contactUs = "Contact Us";
exports.contactDetails = "Contact Details";
exports.address = "Address:";
exports.phoneNumber = "Phone Number";
exports.phoneNumberValue = "+1-202-555-0162";
exports.emailAddress = "E-mail Address";
exports.emailAddressRequired = "E-mail Address is Required.";
exports.emailAddressValue = "info@dummy.com";

// Find Pharmacy
exports.findPharmacy = "Find a Pharmacy";
exports.drugCovered = "Drug Covered";
exports.findDrug = "Find Drug";
exports.researchDrug = "Research Drug";
exports.findPharmacySearch = "Search by area, drug";
exports.pharmacyName1 = "Doon Medical Store";
exports.pharmacyName2 = "Medilife chemist 24/7";
exports.pharmacyTiming1 = "9am-11pm";
exports.pharmacyTiming2 = "24/7 Mon-Sat";
exports.pharmacyDrug1 = "Paracetamol";
exports.pharmacyDrug2 = "Ibuprofen";
exports.pharmacyDrug3 = "Dihydrocodeine";
exports.pharmacyDrug4 = "Amoxicillin";

// Help
exports.getHelp = "Get Help";
exports.myIdCard = "My ID Card";
exports.benefitStatement = "Benefit Statement";
exports.helpHeading1 = "We are here to help so please";
exports.helpHeading2 = "Get in Touch";
exports.helpHeading3 = "with us.";
exports.firstName = "First name";
exports.firstNameRequired = "First name is required.";
exports.lastName = "Last name";
exports.message = "Your message here";
exports.messageRequired = "Message is required.";
exports.sendMessage = "Send Message";
exports.maxLengthExist = "Maximum length exceeded.";
exports.helpSuccessMessage = "Thank you for reaching out to us! Your message has been successfully submitted. We will get back to you shortly.";

// My Notifications
exports.myNotifications = "My Notifications";
exports.notification = "Notification";
exports.read = "Read";
exports.unread = "Unread";

// My Claims
exports.welcome = "Welcome";
exports.submitNewClaim = "Submit New Claim";
exports.allClaims = "All Claims";
exports.medical = "Medical";
exports.dental = "Dental";
exports.vision = "Vision";
exports.rx = "RX";
exports.lifeInsurance = "Life Insurance";
exports.voluntarySupplements = "Voluntary Supplements";
exports.retirement = "Retirement";
exports.healthSavingsAccount = "Health Savings Account";
exports.healthFSA = "Health FSA";
exports.HRA = "HRA";
exports.others = "Others";
exports.uploadClaimFile = "Upload Claim File";
exports.claimType = "Claim Type";
exports.claimTypeRequired = "Claim Type is Required.";
exports.chooseAFile = "Choose a File";
exports.fileRequired = "File is Required.";
exports.cancel = "Cancel";
exports.submitClaim = "Submit Claim";
// Customizable Area End
