import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Column } from "../../../../components/src/CustomTable.web";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
export const configJSON = require("../config");
export const images = require("../assets");
import { FilterChild } from "../../../../components/src/CustomFilterPopup.web";
import { SelectedEmployerInterface } from "../AllRoleLandingPageController.web";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";

export interface Props {
    navigation: any;
    id: string;
    selectedEmployer: SelectedEmployerInterface[]
}
interface S {
    token: string;
    rowData: RowData[];
    filterChildList: FilterChild[];
    locationTableMenuAnchorEl: HTMLElement | null;
    isEditLocation: boolean;
    isOpenEditLocationDialog: boolean;
    searchValue: string;
    openFilter: boolean;
    isAddNewLocation: boolean;
}

interface RowData {
    id: string;
    location_id: string;
    location_name: string;
    group_name: string;
    division_name: string;
    employer_name: string;
    address_line_1: string;
    effective_date: string;
    termination_date: string;
}
interface SS { }


export default class ClientEmployerLocationController extends BlockComponent<
    Props,
    S,
    SS
> {
    tabMenuList = [
        "Groups",
        "Enrollment",
        "Enroll Config",
        "Medical Review",
        "Pay Claims",
        "Recon",
        "Invoice",
        "Payroll Invoice",
        "Case Billing",
        "Documents",
    ];

    tableColumns: Column[] = [
        {
            id: "location_id",
            label: "Location Number",
            headerAlign: "left",
            align: "left",
        },
        {
            id: "location_name",
            label: "Location Name",
            headerAlign: "left",
            align: "left",
        },
        {
            headerAlign: "left",
            align: "left",
            id: "group_name",
            label: "Group Name",
        },
        {
            label: "Division Name",
            headerAlign: "left",
            id: "division_name",
            align: "left",
        },
        {
            id: "employer_name",
            headerAlign: "left",
            align: "left",
            label: "Employer Name",
        },
        {
            id: "address_line_1",
            headerAlign: "left",
            align: "left",
            label: "Address line 1",
        },
        {
            id: "effective_date",
            headerAlign: "left",
            align: "left",
            label: "Effective date",
        },
        {
            id: "termination_date",
            headerAlign: "left",
            align: "left",
            label: "Termination date",
        },
        {
            id: "action",
            label: "",
            headerAlign: "left",
            align: "right",
            format: (row: RowData) => {
                return (
                    <div className="division-table-action-container">
                        <IconButton className="menu-btn" onClick={this.handleLocationOpenTableMenu}>
                            <MoreVertIcon fontSize="large" />
                        </IconButton>
                    </div>
                );
            },
        },
    ];

    requestLocationsListApiId: string = ""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            token: "",
            isEditLocation: false,
            rowData: [],
            locationTableMenuAnchorEl: null,
            isOpenEditLocationDialog: false,
            searchValue: "",
            openFilter: false,
            filterChildList: [],
            isAddNewLocation: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        const loggedInUser = localStorage.getItem("user");
        if (loggedInUser) {
            const { token } = JSON.parse(loggedInUser);
            this.setState({ token })
            this.fetchAllLocationsList(token);
        }
    }

    fetchAllLocationsList = (token: string) => {
        const header = {
            "Content-Type": configJSON.ContentType,
            "token": token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.requestLocationsListApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getListLocationEndPoint}?employer_id=${this.props.selectedEmployer[0].id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.requestLocationsListApiId) {
                this.getLocationListData(responseJson)
            }
        }
    }

    getLocationListData = (response: any) => {
        if (response && response.data && response.data.length > 0) {
            this.extractTableData(response.data);
        }
    }

    extractTableData = (apiData: any) => {
        const extractedData: RowData[] = [];

        apiData.forEach((item: any) => {
            const id = item.id;
            const location_id = item.attributes.location_id || "-";
            const location_name = item.attributes.location_name || "-";
            const division_name = item.attributes.division_name || "-";
            const group_name = item.attributes.group_id || "-";
            const employer_name = item.attributes.employer_name || "-";

            const effective_date = item.attributes.effective_date || "-";
            const termination_date = item.attributes.termination_date || "-";

            const address = item.attributes.physical_address.data;
            let address_line_1 = "-"
            if (address) {
                address_line_1 = address?.attributes.address_line_1 || "-";
            }

            const extractedItem = {
                id,
                location_id,
                location_name,
                group_name,
                division_name,
                employer_name,
                address_line_1,
                effective_date,
                termination_date,
            }
            extractedData.push(extractedItem);
        });

        this.setState({
            rowData: extractedData
        })
    }

    handleLocationOpenTableMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ ...this.state, locationTableMenuAnchorEl: event.currentTarget });
    };

    toggleEditLocationDialog = (open: boolean, isEdit: boolean) => {
        this.setState({
            ...this.state,
            isEditLocation: isEdit,
            locationTableMenuAnchorEl: null,
            isOpenEditLocationDialog: open,
        });
    };

    handleLocationCloseTableMenu = () => {
        this.setState({ ...this.state, locationTableMenuAnchorEl: null });
    };

    handlAddLocationButton = () => {
        this.setState({ isAddNewLocation: true })
    }

    onSearchInputChange = (e: any) => {
        const searchKey = e.target.value;
        this.setState({ searchValue: searchKey });
    };

    handleLocationFilterClick = () => {
        this.setState({ openFilter: !this.state.openFilter });
    };

    handleLocationFilterOrSearchOnChange = (newState: FilterChild[]) => {
    };

    handleLocationDialogClose = () => {
        this.toggleEditLocationDialog(false, false);
        this.fetchAllLocationsList(this.state.token)
    }

    handleLocationTableMenuClick = (id: string) => {
        if (id === "1") {
            this.toggleEditLocationDialog(true, true);
        }
    };
}
