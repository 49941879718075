import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { IEmployerDetailModel, initialEmployerDetailModelData } from "./EmployerDetailModel";

export interface IMetricsCard {
  title: string;
  count?: number;
  isIncreased?: boolean;
  iconType: string;
  description?: string;
  claims?: number;
  premiums?: number;
}

export interface ITimeFilterItem {
  name: string;
  value: number;
}

export interface IOtherFilterItem {
  name: string;
  value: string;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  metricsDataList: IMetricsCard[];
  isOpenEmployerFullDetails: boolean;
  selectedMetricsFilter: number;
  selectedPremiumTimeFilter: number;
  selectedPremiumOtherFilter: string;
  selectedEligibilityFilter: number;
  isOpenEditEmployerDialog: boolean;
  employerDetail: IEmployerDetailModel;
  isEmployerDetailsSaved: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  timeFilterList: ITimeFilterItem[] = [
    { name: "1 Month", value: 1 },
    { name: "3 Months", value: 3 },
    { name: "6 Months", value: 6 }
  ];
  otherFilterList: IOtherFilterItem[] = [
    { name: "Groups", value: "groups" },
    { name: "Divisions", value: "division" },
    { name: "Locations", value: "locations" }
  ];
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      metricsDataList: [
        {
          title: "Total Groups",
          count: 1089,
          isIncreased: true,
          iconType: "person",
          description: "10% increased than last month"
        },
        {
          title: "Total Divisions",
          count: 13670,
          isIncreased: false,
          iconType: "group",
          description: "5% decrease than last month"
        },
        {
          title: "Total Locations",
          count: 1290,
          isIncreased: false,
          iconType: "group",
          description: "5% decrease than last month"
        },
        {
          title: "Total Employees",
          count: 1344,
          isIncreased: true,
          iconType: "person",
          description: "10% increased than last month"
        },
        {
          title: "Total Claims",
          count: 1150,
          isIncreased: true,
          iconType: "solidarity",
          description: "12% increased than last month"
        },
        {
          title: "Total Premium",
          count: 1020,
          isIncreased: true,
          iconType: "solidarity",
          description: "12% increased than last month"
        },
        {
          title: "Total Enrollments",
          count: 1020,
          isIncreased: true,
          iconType: "solidarity",
          description: "12% increased than last month"
        },
        {
          title: "Total Savings",
          iconType: "chart",
          premiums: 1020,
          claims: 1150
        }
      ],
      isOpenEmployerFullDetails: false,
      selectedMetricsFilter: 1,
      selectedEligibilityFilter: 6,
      selectedPremiumTimeFilter: 6,
      selectedPremiumOtherFilter: "groups",
      isOpenEditEmployerDialog: false,
      employerDetail: initialEmployerDetailModelData,
      isEmployerDetailsSaved: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
        this.getDashboardData();
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        if (responseJson.data.length === 0) {
          this.setState({
            errorMsg: "Data Not Found",
            loading: false
          });
        } else {
          this.setState({
            dashboardData: responseJson.data,
            errorMsg: "",
            loading: false
          });
        }
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if (errorReponse === undefined) {
          this.setState({
            errorMsg: "Something went wrong",
            loading: false
          });
        } else {
          this.setState({
            errorMsg: errorReponse,
            loading: false
          });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  toggleEmployerFullDetails = () => {
    this.setState({
      ...this.state,
      isOpenEmployerFullDetails: !this.state.isOpenEmployerFullDetails
    });
  };

  handleChangeFilter = (
    e: React.ChangeEvent<{ value: unknown }>,
    type: string
  ) => {
    const selectedValue = e.target.value;
    if (type === "metrics") {
      this.setState({
        ...this.state,
        selectedMetricsFilter: selectedValue as number
      });
    } else if (type === "premium-time") {
      this.setState({
        ...this.state,
        selectedPremiumTimeFilter: selectedValue as number
      });
    } else if (type === "eligibility") {
      this.setState({
        ...this.state,
        selectedEligibilityFilter: selectedValue as number
      });
    } else if (type === "premium-other") {
      this.setState({
        ...this.state,
        selectedPremiumOtherFilter: selectedValue as string
      });
    }
  };

  toggleEditEmployerDialog = () => {
    this.setState({
      ...this.state,
      isOpenEditEmployerDialog: !this.state.isOpenEditEmployerDialog
    });
  };

  saveEmployerDetails = (editedEmpOb: IEmployerDetailModel) => {
    this.setState({...this.state, isEmployerDetailsSaved: true});
  }

  handleCloseConfirmationDialog = () => {
    this.setState({ ...this.state, employerDetail: initialEmployerDetailModelData, isEmployerDetailsSaved: false, isOpenEditEmployerDialog: false, });
  };
  // Customizable Area End

}
