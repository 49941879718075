import React from "react";

// Customizable Area Start
import {
  Button,
  Paper,
  Typography,
  OutlinedInput,
  Grid,
  InputAdornment,
  IconButton,
  Select,
  MenuItem
} from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ReactCodeInput from "react-code-input";
import { imgCompanyLogo, backButton } from "./assets";
const landingImage = require("./assets");

import ForgotPasswordBlockController, {
  Props,
  forgotPasswordStep,
  configJSON
} from "./ForgotPasswordBlockController.web";
import "./forgotPasswordBlock.web.css";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

// Customizable Area End

export default class ForgotPasswordBlock extends ForgotPasswordBlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Required for all blocks
      // Customizable Area Start
      <div className="forgot-password-main-body">
        <ThemeProvider theme={theme}>
          <Grid
            container
            className="main-container"
            style={{ minHeight: "100vh" }}
          >
            <Grid item xs={12} md={6}>
              <Paper className="slider-image p-relative h-100p">
                <img
                  src={landingImage.landingImage1}
                  className={`w-100p h-100p `}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container className="p-30">
                <Grid item xs={6}>
                  <Button
                    onClick={this.handleBackClick}
                    data-testid="backButton"
                  >
                    <img className="w-75p" src={`${backButton}`} />
                  </Button>
                </Grid>
                <Grid item xs={6} justifyContent="flex-end" className="d-flex">
                  <img src={imgCompanyLogo} className="mr-40 w-75p" />
                </Grid>
              </Grid>
              <Grid
                container
                className={`forget-password-container ${this.getIfCondition(
                  this.state.forgotPasswordStep === forgotPasswordStep.fourth,
                  "p-120-fourth",
                  "p-120"
                )}`}
              >
                {this.state.forgotPasswordStep ===
                  forgotPasswordStep.fourth && (
                  <>
                    <Grid
                      item
                      xs={12}
                      className="mb-40 text-align-center d-flex"
                    >
                      <img src={landingImage.confirmImage} />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    component="h2"
                    className="heading-label"
                  >
                    {this.state.headerTitleLabel}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    className="sub-heading-label"
                  >
                    {this.state.subHeaderTitleLabel}
                  </Typography>
                </Grid>
                {this.state.forgotPasswordStep === forgotPasswordStep.first && (
                  <>
                    <Grid item xs={12}>
                      <OutlinedInput
                        placeholder={configJSON.emailOrMobilePlaceholder}
                        data-test-id="txtInputEmail"
                        className={`input-boxes ${this.handleErrorClass(this.state.emailOrMobileError,this.state.emailOrMobileValue)}`}
                        fullWidth={true}
                        value={this.state.emailOrMobileValue}
                        onChange={e =>
                          this.setEmailOrMobileValue(e.target.value.replace(/ /g, ""))
                        }
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "15px" }}>
                      <Typography
                        style={{
                          fontSize: "14px",
                          color: "black",
                          textAlign: "center",
                          fontWeight: 700
                        }}
                      >
                        OR
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <div style={{ display: "flex" }}>
                        <Select
                          style={{
                            borderRadius: "10px",
                            backgroundColor: "#eaeef8",
                            height: "57px",
                            marginRight: "10px",
                            width: "95px",
                            textAlign: "center",
                            marginTop: "15px",
                            paddingLeft: "10px"
                          }}
                          data-test-id="country-select-dropdown"
                          className="country-select"
                          value={this.state.selectedCountryCode}
                          onChange={(e: any) => {
                            this.handleSelectChange(e.target.value,this.state.phone,this.state.mobileRegex.test(this.state.phone))
                          }}
                        >
                          {this.phoneNumberArray.map(item => {
                            return (
                              <MenuItem
                                value={item.countryCode}
                                key={
                                  item.countryName + "phone-country-dropdown"
                                }
                                style={{ display: "flex" }}
                              >
                                <img src={item.flag} /> {item.countryCode}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <OutlinedInput
                          placeholder={"Mobile number"}
                          data-test-id="txtInputMobile"
                          className={`input-boxes ${this.handleErrorClass(this.state.emailOrMobileError,this.state.phone)}`}
                          fullWidth={true}
                          name="phone"
                          type="number"
                          value={this.state.phone}
                          onChange={(e:{target:{name:string,value:string}})=>{this.handleInput(e,this.state.mobileRegex.test(e.target.value))}}
                        />
                      </div>
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          className={this.getIfCondition(
                            !!this.state.emailOrMobileError,
                            "text-info-red",
                            "text-info"
                          )}
                        >
                          {this.getIfCondition(
                            !!this.state.emailOrMobileError,
                            this.state.emailOrMobileError,
                            configJSON.emailOrMobileInfo
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                {this.state.forgotPasswordStep ===
                  forgotPasswordStep.second && (
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <ReactCodeInput
                          data-test-id="txtInputOTP"
                          type="text"
                          fields={4}
                          disabled={this.state.attemptOver}
                          className={`w-100p otp-field ${this.state.otpError &&
                            "otp-field-red-border"}`}
                          inputMode="numeric"
                          name="OTP input"
                          value={this.state.otpValue}
                          onChange={this.setOtpValue}
                        />
                      </Grid>
                        <Grid item xs={12} className="error-box">
                          <Typography
                            variant="subtitle1"
                            component="div"
                            className="text-info-red"
                          >
                            {this.state.otpError}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            component="div"
                            className="info-text"
                          >
                            {this.state.attemptMessageShow&&this.state.attempts}
                          </Typography>
                        </Grid>
                      <Grid item xs={12} justifyContent="flex-end">
                        <Typography
                          variant="subtitle1"
                          component="div"
                          align="right"
                          className="text-info"
                        >
                          {this.getIfCondition(
                            this.state.otpTimerValue > 0,
                            `00:${this.getStringTime(
                              this.state.otpTimerValue
                            )}`,
                            <Button
                              data-test-id="resendOTPBtn"
                              variant="text"
                              onClick={this.handleResendOTP}
                              className="resend-code"
                            >
                              {configJSON.resendCode}
                            </Button>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {this.state.forgotPasswordStep ===
                  forgotPasswordStep.thired && (
                  <>
                    <Grid item xs={12}>
                      <OutlinedInput
                        type={this.getIfCondition(
                          this.state.enablePasswordField,
                          "text",
                          "password"
                        )}
                        data-test-id="txtInputPassword"
                        placeholder={"Password"}
                        className="input-boxes"
                        fullWidth={true}
                        value={this.state.passwordValue}
                        onChange={this.setPasswordValue}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              data-test-id="enablePassword"
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              edge="end"
                            >
                              {this.getIfCondition(
                                this.state.enablePasswordField,
                                <Visibility />,
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        className="text-info"
                      >
                        {
                          "*At least 8 characters with at least 1 uppercase, 1 lowercase, 1 number and 1 special character."
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <OutlinedInput
                        type={this.getIfCondition(
                          this.state.enableConfirmPasswordField,
                          "text",
                          "password"
                        )}
                        data-test-id="txtInputConfirmPassword"
                        placeholder={configJSON.confirmPasswordLabel}
                        className={`input-boxes ${this.state
                          .confirmPasswordError && "input-box-red-border"}`}
                        fullWidth={true}
                        value={this.state.confirmPasswordValue}
                        onChange={this.setConfirmPasswordValue}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              data-test-id="enableConfirmPassword"
                              onClick={this.handleClickShowConfirmPassword}
                              edge="end"
                            >
                              {this.getIfCondition(
                                this.state.enableConfirmPasswordField,
                                <Visibility />,
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </Grid>
                    {this.state.confirmPasswordError && (
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          className="text-info-red"
                        >
                          {this.state.confirmPasswordError}
                        </Typography>
                      </Grid>
                    )}
                  </>
                )}
                <Grid item xs={12}>
                  <Button
                    data-test-id={"forgotPasswordBtn"}
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="send-otp-btn"
                    onClick={this.doOnClickBtn}
                    disabled={this.state.isBTNDisabled}
                  >
                    {this.state.btnLabel}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
