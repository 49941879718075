import React, { Component } from "react";
import { Stepper, Step, StepLabel } from "@material-ui/core";

import "./CustomVerticalFormStepper.web.css";

export interface StepList {
  key: number;
  title: string;
  description: string;
  isCompleted: boolean;
}

interface Props {
  activeStep: number;
  stepList: StepList[];
  onStepClick: (step: number) => void;
  disableOnClick?: boolean;
}

interface S { }

export default class CustomVerticalFormStepper extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Stepper
        activeStep={this.props.activeStep}
        orientation="vertical"
        className="custom-vertical-form-stepper"
      >
        {this.props.stepList.map((step, index) => {
          const alternateLabel = (
            <div className="stepper-description">{step.description}</div>
          );
          return (
            <Step
              data-test-id={`step-${index + 1}`}
              key={step.title}
              onClick={() =>
                this.props.disableOnClick ? () => { } : this.props.onStepClick(index)
              }
            >
              <StepLabel
                className={`step-label-default ${index ===
                  this.props.activeStep &&
                  "step-label-active"} ${step.isCompleted &&
                  "step-label-completed"}`}
                optional={alternateLabel}
                StepIconProps={{ icon: index + 1, completed: false }}
              >
                {step.title}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    );
  }
}
