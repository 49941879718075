import React, { Component } from "react";
import { TextField } from "@material-ui/core";

import "./GenericTextField.web.css";

interface Props {
  value: string | number;
  onChange?: (e: any) => void;
  error?: string;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  type?: string;
  variant?: "outlined" | "standard" | "filled";
  disabled?: boolean;
  isTaxId?: boolean;
  name?: string
  inputProps?: object;
  onlyNumber?: boolean;
  nameField?: boolean;
  onlyLetter?: boolean;
  onlyLetterAndNumber?: boolean;
  onBlur?: (e: any) => void;
  onFocus?: () => void;
}

interface S { }

export default class GenericTextField extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  getTaxIdValue = (e: any) => {
    let newValue: string = e.target.value;
    newValue = newValue.replace(/[^0-9]/g, "");
    if (newValue.length > 2) {
      newValue = newValue.slice(0, 2) + "-" + newValue.slice(2);
    }
    e.target.value = newValue;
    return e;
  };

  convertNumber = (e: any) => {
    let newValue: string = e.target.value;
    newValue = newValue.replace(/[^\d.-]+/gi, '');
    e.target.value = newValue;
    return e
  }

  nameField = (e:{target:{value:string}}) => {
    let newValue: string = e.target.value.replace(/[1-9]/g, "");
    e.target.value = newValue;
    return e
  }

  convertLetter = (e: any) => {
    let newValue: string = e.target.value;
    newValue = newValue.replace(/[^a-zA-Z ]/g, "");
    e.target.value = newValue;
    return e
  }

  convertLetterAndNumber = (e: any) => {
    let newValue: string = e.target.value;
    newValue = newValue.replace(/[^a-zA-Z0-9 ]/g, "");
    e.target.value = newValue;
    return e;
  }


  onChange = (e: any) => {
    if (e.target.value !== " " && this.props.onChange) {
      if (this.props.isTaxId) {
        return this.props.onChange(this.getTaxIdValue(e))
      }
      if (this.props.onlyNumber) {
        return this.props.onChange(this.convertNumber(e))
      }
      if(this.props.nameField){
        return this.props.onChange(this.nameField(e))
      }
      if (this.props.onlyLetter) {
        return this.props.onChange(this.convertLetter(e))
      }
      if (this.props.onlyLetterAndNumber) {
        return this.props.onChange(this.convertLetterAndNumber(e))
      }
      this.props.onChange(e);
    }
  };

  render() {
    return (
      <div className="generic-text-field">
        {this.props.label && (
          <div className="text-input-label">
            {this.props.label}
            {this.props.isRequired && <span className="error-asterisk">*</span>}
          </div>
        )}
        <TextField
          fullWidth
          variant={this.props.variant || "outlined"}
          placeholder={this.props.placeholder}
          type={this.props.type || "text"}
          className="text-input"
          value={this.props.value}
          onChange={this.onChange}
          error={!!this.props.error}
          helperText={this.props.error}
          disabled={this.props.disabled}
          name={this.props.name}
          inputProps={this.props.inputProps}
          onBlur={this.props.onBlur}
          onFocus={this.props.onFocus}
        />
      </div>
    );
  }
}
