import React from "react";

// Customizable Area Start
import { Paper, Grid, Button, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const landingImage = require("./assets");
import "./RegistrationWaiting.css";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

export default class RegistrationWaitingScreen extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      //Required for all blocks
      <div className="registration-waiting-main-body">
        <ThemeProvider theme={theme}>
          <Grid container className="main-container">
            <Grid item xs={12} md={6}>
              <Paper className="paper-images-container p-relative h-100p">
                <img
                  src={landingImage.landingImage1}
                  className="w-100p h-100p"
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container className="p-30">
                <Grid item xs={6}>
                  <Button
                    onClick={this.handleBackClick}
                    data-testid="backButton"
                  >
                    <img className="w-75p" src={landingImage.backButton} />
                  </Button>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  justifyContent="flex-end"
                  className="d-flex"
                >
                  <img
                    src={landingImage.imgCompanyLogo}
                    className="mr-40 w-75p wi-ht"
                  />
                </Grid>
              </Grid>

              <Grid container className="waiting-box">
                <Grid
                  item
                  xs={12}
                  className="mb-40 mt-40 text-align-center d-flex"
                >
                  <img src={landingImage.waiting} />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    component="h2"
                    className="heading-label"
                  >
                    {this.state.waitingLabel}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    className="sub-heading-label"
                  >
                    {this.state.waitingSublabel}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      </div>
    );
  }
}
