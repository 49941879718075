import React from "react";

import { Grid, Button } from "@material-ui/core";
const images = require("../assets");

import ClientMemberLandingPageController, {
    Props,
    configJSON,
} from "./ClientMemberLandingPageController";
import "./ClientMember.web.css";
import CustomClientTabPanel from "../../../../components/src/CustomClientTabPanel.web";
import CustomerTable from "../../../../components/src/CustomTable.web";
import EditClientMemberDialog from "./EditClientMemberDialog.web";
import CustomTableMenu from "../../../../components/src/CustomTableMenu.web";
import GenericSuccessDialog from "../../../../components/src/GenericSuccessDialog.web";


export default class ClientMemberLandingPage extends ClientMemberLandingPageController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
                <div
                    className="client-employer-division client-member-wrapper"
                    data-test-id="client-member-wrapper"
                >
                    <div
                        data-test-id="client-member-heading"
                        className="client-member-heading mb-20"
                    >
                        {configJSON.memberHeading}
                    </div>
                    <div>
                        <CustomClientTabPanel
                            tabList={this.tabMenuList}
                            isDisplayAllTab
                            allTabLabel={configJSON.member}
                        >
                            <Grid container className="all-location-tab-container all-member-tab-container">
                                <Grid item xs={12} className="search-bar-and-add-btn">
                                    <div className="add-member-btn-div">
                                        <Button
                                            className="add-member-btn"
                                            data-test-id="add-member-btn"
                                            onClick={() => this.toggleEditMemberDialog(true, false)}
                                        >
                                            + Add Member
                                        </Button>
                                    </div>
                                </Grid>
                                <Grid item xs={12} className="hr mt-30" />
                                <Grid item xs={12}>
                                    <CustomerTable
                                        columns={this.tableColumns}
                                        rowData={this.state.rowData}
                                        isColumnSelectable
                                        rowCount={this.state.totalMembers}
                                        handlePageChange={this.handleMembersPaginationChange}
                                        pageNo={this.state.page}
                                        onActionClick={this.HandleActionClick}
                                    />
                                    <CustomTableMenu
                                        selectedMenuAnchorEl={this.state.memberTableMenuAnchorEl}
                                        menuItemList={[
                                            { id: "1", label: "Edit", icon: images.blackEditIcon },
                                            { id: "2", label: "Delete", icon: images.deleteIcon },
                                        ]}
                                        closeTableMenu={this.handleMemberCloseTableMenu}
                                        menuItemOnClick={this.handleMemberTableMenuClick}
                                    />
                                </Grid>
                            </Grid>
                            {this.tabMenuList.map((tab) => (
                                <>{tab}</>
                            ))}
                        </CustomClientTabPanel>
                    </div>
                </div>
                <GenericSuccessDialog
                    open={this.state.deletedMemberSuccessfull}
                    handleDone={this.handleDialogClose}
                    contains={"Deleted Member Successfully"}
                    />
                {this.state.isEditMemberDialogOpen && (
                    <EditClientMemberDialog
                        isOpen={this.state.isEditMemberDialogOpen}
                        isEdit={this.state.isEdit}
                        handleClose={() => this.toggleEditMemberDialog(false, false)}
                        saveMemberData={this.saveMemberDetails}
                        memberDetails={this.state.memberDetails}
                        data-test-id="edit-member-dialog"
                        token={this.state.token}
                        employersList={this.state.employerList}
                        locationList={this.state.locationList}
                        editMemberDetails={this.state.EditMemberData}
                        editMemberIDs={this.state.EditMembersId}
                        clientDetails={{client_name:this.state.client_name,client_id:this.state.client_id,accountId:this.state.accountId}}
                    />
                )}
            </>
        )
    }
}