import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutline from "@material-ui/icons/DeleteOutline";

import "./GenericAddAgentComponent.web.css";
import GenericTextField from "./GenericTextField.web";
import GenericButton from "./GenericButton.web";

export interface IAgentModel {
  id: number;
  agent_name: string;
  agent_rate: string;
}

interface Props {
  agentList: IAgentModel[];
  handleInputChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { value: unknown }
    >,
    param: string,
    agentId?: number
  ) => void;
  agentListError: IAgentModel[];
  addAgent: () => void;
  removeAgent: (index: number) => void;
  isValidationDivision?: boolean;
}

interface S { }

export default class GenericAddAgentComponent extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        {this.props.agentList.map((agent, index) => (
          <React.Fragment key={`${index} agent`}>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={"Agent [NUMBER] Name".replace(
                  "[NUMBER]",
                  (index + 1).toString()
                )}
                value={agent.agent_name}
                onChange={(e) =>
                  this.props.handleInputChange(e, "agent_name", index)
                }
                error={this.props.agentListError[index]?.agent_name}
                data-test-id="agent_name"
                onlyLetter={this.props.isValidationDivision}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              className="generic-add-agent-component text-field-and-add-btn-grid"
            >
              <GenericTextField
                label={"Agent [NUMBER] Rate".replace(
                  "[NUMBER]",
                  (index + 1).toString()
                )}
                type={this.props.isValidationDivision ? 'text' : 'number'}
                value={agent.agent_rate}
                onChange={(e) => { this.props.handleInputChange(e, "agent_rate", index) }}
                error={this.props.agentListError[index]?.agent_rate}
                data-test-id="agent_rate"
                onlyNumber={this.props.isValidationDivision}
              />
              {index === 0 ? (
                <GenericButton
                  variant="outlined"
                  color="primary"
                  className="ml-24"
                  onClick={this.props.addAgent}
                  isIconButton
                  data-test-id="add-agent"
                >
                  <AddIcon fontSize="large" />
                </GenericButton>
              ) : (
                <GenericButton
                  variant="outlined"
                  color="primary"
                  className="ml-24 remove-agent"
                  onClick={() => this.props.removeAgent(index)}
                  isIconButton
                  data-test-id="remove-agent"
                >
                  <DeleteOutline fontSize="large" color="error" />
                </GenericButton>
              )}
            </Grid>
          </React.Fragment>
        ))}
        <Grid item xs={12}>
          <div className="hr" />
        </Grid>
      </>
    );
  }
}
