import React, { Component } from "react";

import "./GenericDialogFormWrapper.web.css";
import GenericDialog from "./GenericDialog.web";
import CustomVerticalFormStepper, {
  StepList,
} from "./CustomVerticalFormStepper.web";
import GenericButton from "./GenericButton.web";
import { leftArrow, rightArrow } from "./assets";

interface Props {
  open: boolean;
  handleClose: () => void;
  title: string;
  stepList: StepList[];
  activeStep: number;
  setActiveStep?: any;
  disableOnClick?: boolean;
  saveAndNext: any;
  saveAndFinish: any;
  formContent: any;
  handlePrevious: () => void;
  buttonTitle: string;
  disableSaveAndNext?: boolean;
}

interface S { }

export default class GenericDialogFormWrapper extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const genericFormWrapperDialogContent = (
      <div className="generic-dialog-content-wrapper">
        <div className="generic-dialog-content-stepper">
          <CustomVerticalFormStepper
            stepList={this.props.stepList}
            activeStep={this.props.activeStep}
            onStepClick={this.props.setActiveStep}
            disableOnClick={this.props.disableOnClick}
          />
        </div>
        <div className="generic-dialog-form-wrapper">
          {this.props.formContent}
        </div>
      </div>
    );

    const genericFormWrapperDialogActions = (
      <div className="generic-dialog-action-flex-wrapper">
        <div>
          {this.props.activeStep !== this.props.stepList.length - 1&&<GenericButton
            color="primary"
            variant="contained"
            onClick={this.props.saveAndFinish}
            data-test-id="save-and-finish-btn"
            disabled={this.props.disableSaveAndNext}
          >
            Save And Next
          </GenericButton>}
        </div>
        <div>
          <GenericButton
              color="primary"
              variant="contained"
              className="generic-dialog-previus-btn"
              disabled={this.props.activeStep===0}
              onClick={this.props.handlePrevious}
              data-test-id="previous-btn"
            >
              <img src={leftArrow} alt="leftArrow" className="arrow-png"/>
          </GenericButton>
          {this.props.activeStep < this.props.stepList.length - 1 ? (
            <GenericButton
              color="primary"
              variant="contained"
              className="generic-dialog-save-changes-btn"
              onClick={this.props.saveAndNext}
              data-test-id="save-and-next-btn"
            >
              <img src={rightArrow} alt="rightArrow" className="arrow-png"/>
            </GenericButton>
          ):
          (
            <GenericButton
              color="primary"
              variant="contained"
              className="generic-dialog-save-changes-btn"
              onClick={this.props.saveAndFinish}
              data-test-id="save-and-next-btn"
            >
              {this.props.buttonTitle}
            </GenericButton>
          )}
        </div>
      </div>
    );
    return (
      <>
        <GenericDialog
          title={this.props.title}
          open={this.props.open}
          handleClose={this.props.handleClose}
          contains={genericFormWrapperDialogContent}
          actions={genericFormWrapperDialogActions}
          hideCloseDialog={true}
          maxWidth="lg"
          className="generic-form-wrapper-dialog"
          data-test-id="generic-form-wrapper-dialog"
        />
      </>
    );
  }
}
