import React, { Component } from "react";
import Chart from "react-apexcharts";

type PieChartProps = { colors: string[]; labels: string[]; series: number[] };

export default class PieChart extends Component<PieChartProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const { colors, labels, series } = this.props;
    return (
      <div>
        <Chart
          options={{
            chart: {
              width: 150,
              type: "pie",
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: true,
              formatter: function (val, { seriesIndex, dataPointIndex, w }) {
                return `$${w.config.series[seriesIndex]}`;
              },
            },
            legend: {
              show: false,
            },
            colors: colors,
            labels: labels,
          }}
          series={series}
          type="pie"
        />
      </div>
    );
  }
}
