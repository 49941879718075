import React, { Component } from "react";
import { Dialog, DialogTitle, Typography, DialogContent, Grid, Box, Stepper, Step, StepLabel, IconButton, DialogActions, Button, InputLabel, TextField, FormControlLabel, Checkbox, Divider, Card, createTheme, ThemeProvider, Input, Select, MenuItem, FormHelperText } from '@material-ui/core'
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ColorlibStepIcon from "../ColorlibStepIcon.web";
import { Form, Formik } from 'formik';
import StepOne from "./StepOne.web";
import StepTwo from "./StepTwo.web";
import StepThree from "./StepThree.web";

const images = require("../../../../blocks/landingpage/src/assets");


interface Props {
    open: boolean;
    activeStep: number;
    handlePrev: () => void;
    handleClose: () => void;
    handleChangeValue: (event: any) => void;
    fromState: any;
    handleNext:() => void;
    successModalOn:() => void;
}

const icons: any = {
    1: {
        name: "1",
        label: "Client Basic Details",
        des: "Type and Select details"
    },
    2: {
        name: "2",
        label: "Address & Contact ",
        des: "Type and Select details"
    },
    3: {
        name: "3",
        label: "Upload Logo / Supporting Documents",
        des: "Type and Select details"
    }
};

export const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            main: "#4166f8",
        },

    },
    typography: {
        fontFamily: 'Poppins',
    },
});

export const EventIcon = ({ ...other }) => {
    return <img src={images.calendarIcon} alt="images" width="18px" />;
}

export class ClientEdits extends Component<Props> {

    render() {
        const steps: any[] = [StepOne, StepTwo, StepThree];
        const initialValues = steps.reduce(
            (values, { initialValues }: any) => ({
                ...values,
                ...initialValues
            }),
            {}
        );


        const ActiveStep = steps[this.props.activeStep]
        const validationSchema = ActiveStep.validationSchema
        const isLastStep = this.props.activeStep === steps.length - 1;

        function _handleSubmit(values:any, actions:any) {
            if (isLastStep) {
                _submitForm(values, actions);
            } else {
                _Next()
                actions.setTouched({});
                actions.setSubmitting(false);
            }
        }

        const _Next = () => {
            this.props.handleNext()
        }

        const _SuccModalOn = () => {
            this.props.successModalOn()
        }


        async function _submitForm(values:any, actions:any) {
            _SuccModalOn()
            actions.setSubmitting(false);
        }


        return (
            <ThemeProvider theme={defaultMaterialTheme}>
                <Dialog fullWidth maxWidth={"lg"} open={this.props.open} className="client-edit-dialog"
                >
                    <DialogTitle className="main-title">
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography className='header'>Edit Info</Typography>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={this.props.handleClose}>
                                    <img src={images.CloseIcon} width={"24px"} height={"24px"} alt="closeButton" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <Divider />
                    <Formik
                        initialValues={initialValues}
                        onSubmit={_handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ isSubmitting, touched, values, setValues }) => (
                            <>
                                <Form >
                                    <DialogContent className='scroll-view' style={{ overflowX: "hidden", overflowY: this.props.activeStep === 1 ? "scroll" : "hidden" }}>
                                        <Grid container>
                                            <Grid item sm={12} md={5} xl={4}>
                                                <div className='root'>
                                                    <Stepper activeStep={this.props.activeStep} orientation={"vertical"}>
                                                        {steps.map((step, index) => (
                                                            <Step key={index}>
                                                                <StepLabel StepIconComponent={ColorlibStepIcon} StepIconProps={{ itemProp: icons }}></StepLabel>
                                                            </Step>
                                                        ))}
                                                    </Stepper>
                                                </div>
                                            </Grid>
                                            <Grid
                                                container
                                                spacing={3}
                                                item
                                                sm={12}
                                                md={7}
                                                xl={8}
                                                className='text-root'
                                            >
                                                {steps.map((step, index) => {
                                                    if (index === this.props.activeStep) {
                                                        const Component = steps[index];
                                                        return <Component key={index} setValues={setValues} values={values} handleChangeValue={this.props.handleChangeValue} />;
                                                    } else {
                                                        return null
                                                    }
                                                })}
                                            </Grid>
                                        </Grid>



                                    </DialogContent>
                                    <DialogActions>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            className='grid-contanier'
                                        >
                                            <Grid item>
                                                {!isLastStep && <Button disabled={isSubmitting} type="submit" className='button'>Save & Next</Button>}
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className='button-prev'
                                                    disabled={this.props.activeStep === 0 || isSubmitting}
                                                    onClick={this.props.handlePrev}
                                                >
                                                    <ArrowBackIosIcon style={{ color: "#B1B6CD" }} />
                                                </Button>
                                                <Button disabled={isSubmitting} type="submit" className='button-next'>
                                                    {isLastStep ? 'Save Changes' : <ArrowForwardIosIcon />}
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </DialogActions>
                                </Form>
                            </>
                        )}
                    </Formik>
                </Dialog>
            </ThemeProvider>
        )
    }
}

export default ClientEdits