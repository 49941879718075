import React from "react";
import { IBlock } from "../../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { Column } from "../../../../../components/src/CustomTable.web";

import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { FilterChild } from "../../../../../components/src/CustomFilterPopup.web";
import {
  IDivisionDetailModel,
  initialAgentModelData,
  initialDivisionAddressDetailModelData,
  initialDivisionDetailModelData,
  initialDivisionOtherDetailModelData,
} from "../ClientEmployerDivisionModel";
import { Message } from "../../../../../framework/src/Message";
import { SelectedEmployerInterface } from "../../AllRoleLandingPageController.web";
import { IAgentModel } from "../../../../../components/src/GenericAddAgentComponent.web";

export const configJSON = require("./../../config");
export const images = require("./../../assets");

export interface Props {
  selectedEmployer: SelectedEmployerInterface[]
}

interface S {
  token: string;
  searchValue: string;
  openFilter: boolean;
  filterChildList: FilterChild[];
  rowData: RowData[];
  divisionTableMenuAnchorEl: HTMLElement | null;
  isOpenAddEditDivisionDialog: boolean;
  isEditDivision: boolean;
  divisionDetails: IDivisionDetailModel;
  actionId: string;
  convertedData: IDivisionDetailModel[];
  showDialogSuccess: boolean;
}

interface SS {
  id: any;
}

interface RowData {
  id: string;
  division_id: string;
  division_name: string;
  group_name: string;
  employer_name: string;
  address_line1: string;
  state: string;
  zip: string;
  effective_date: string;
  termination_date: string;
  contact_name: string;
  contact_number: string;
}

export default class ClientEmployerDivisionTabController extends BlockComponent<
  Props,
  S,
  SS
> {
  divisionsListApiId: string = ""
  clientEmployerDivisionList: RowData[] = [];
  divisionNameList: { key: any, isChecked: boolean }[] = [];
  employerNameList: { key: any, isChecked: boolean }[] = [];
  groupNameList: { key: any, isChecked: boolean }[] = [];
  stateList: { key: any, isChecked: boolean }[] = [];
  zipList: { key: any, isChecked: boolean }[] = [];
  rawDataList: any[] = [];
  requestDeleteDivision: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      token: "",
      searchValue: "",
      openFilter: false,
      filterChildList: [],
      rowData: [],
      convertedData: [],
      divisionTableMenuAnchorEl: null,
      isOpenAddEditDivisionDialog: false,
      isEditDivision: false,
      divisionDetails: {
        ...initialDivisionDetailModelData,
        divisionBasicDetails: {
          ...initialDivisionDetailModelData.divisionBasicDetails,
          employer_id: props.selectedEmployer[0].emp_id,
          employer_name: props.selectedEmployer[0].employer_name
        },
      },
      actionId: '',
      showDialogSuccess: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  tableColumns: Column[] = [
    {
      id: "division_id",
      label: "Division Number",
    },
    {
      id: "division_name",
      label: "Division Name",
    },
    { id: "group_name", label: "Group Name" },
    { id: "employer_name", label: "Employer Name" },
    { id: "address_line1", label: "Address Line 1" },
    { id: "state", label: "State" },
    { id: "zip", label: "Zip" },
    { id: "effective_date", label: "Effective Date" },
    { id: "termination_date", label: "Termination Date" },
    { id: "contact_name", label: "Contact Name" },
    { id: "contact_number", label: "Contact Number" },
    {
      id: "action",
      label: "",
      headerAlign: "left",
      align: "right",
      format: () => {
        return (
          <div className="division-table-action-container">
            <IconButton
              className="menu-btn"
              data-test-id="division-table-menu"
              onClick={this.handleDivisionOpenTableMenu}
            >
              <MoreVertIcon fontSize="large" />
            </IconButton>
          </div>
        );
      },
    },
  ];

  async componentDidMount() {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const { token } = JSON.parse(loggedInUser);
      this.setState({ token: token })
      this.fetchAllDivisionsList(token);
    }
  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.divisionsListApiId) {
        this.getDivisionListData(responseJson)
      }
      if (apiRequestCallId === this.requestDeleteDivision) {
        this.handleDeleteDivision(responseJson)
      }
    }
  }

  fetchAllDivisionsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.ContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.divisionsListApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.divisionsListGetApiEndPoint}?employer_id=${this.props.selectedEmployer[0].id}&page=1&per_page=300`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDivisionListData = (response: any) => {
    if (response && response.data && response.data.length > 0) {
      this.extractTableData(response.data);
    }
  }

  extractTableData = (apiData: any) => {
    const extractedData: RowData[] = [];
    this.rawDataList = apiData

    apiData.forEach((item: any) => {
      const id = item.id;
      const division_id = item.attributes.division_id || "-";
      const division_name = item.attributes.division_name || "-";
      const group_name = item.attributes.group_name || "-";
      const employer_name = item.attributes.employer_name || "-";

      const effective_date = item.attributes.effective_date || "-";
      const termination_date = item.attributes.termination_date || "-";

      const address = item.attributes.division_address.data;
      let address_line1 = "-"
      let state = "-"
      let zip = "-"
      let contact_name = "-"
      let contact_number = "-"
      if (address) {
        address_line1 = address?.attributes.physical_address_line1 || "-";
        state = address?.attributes.physical_state || "-";
        zip = address?.attributes.physical_zip_code || "-";
        contact_name = address?.attributes.division_check_contact1 || ""
        contact_number = address?.attributes.division_check_phone_number1 || ""
      }

      const extractedItem = {
        id,
        division_id,
        division_name,
        group_name,
        employer_name,
        address_line1,
        state,
        zip,
        effective_date,
        termination_date,
        contact_name,
        contact_number
      }
      extractedData.push(extractedItem);
    });

    this.clientEmployerDivisionList = extractedData

    this.divisionNameList = [
      ...new Set(this.clientEmployerDivisionList.filter(item => item.division_name !== "-").map((list) => list.division_name)),
    ].map((list) => ({
      key: list,
      isChecked: false,
    }));

    this.employerNameList = [
      ...new Set(this.clientEmployerDivisionList.filter(item => item.employer_name !== "-").map((list) => list.employer_name)),
    ].map((list) => ({
      key: list,
      isChecked: false,
    }));

    this.groupNameList = [
      ...new Set(this.clientEmployerDivisionList.filter(item => item.group_name !== "-").map((list) => list.group_name)),
    ].map((list) => ({
      key: list,
      isChecked: false,
    }));

    this.stateList = [
      ...new Set(this.clientEmployerDivisionList.filter(item => item.state !== "-").map((list) => list.state)),
    ].map((list) => ({
      key: list,
      isChecked: false,
    }));

    this.zipList = [
      ...new Set(this.clientEmployerDivisionList.filter(item => item.zip !== "-").map((list) => list.zip)),
    ].map((list) => ({
      key: list,
      isChecked: false,
    }));

    this.setState({
      ...this.state,
      filterChildList: [{
        key: "Division Name",
        isOpen: true,
        searchString: "",
        viewAll: false,
        list: this.divisionNameList,
        mainList: this.divisionNameList,
      },
      {
        key: "Employer Name",
        isOpen: false,
        searchString: "",
        viewAll: false,
        list: this.employerNameList,
        mainList: this.employerNameList,
      },
      {
        key: "Group Name",
        isOpen: false,
        searchString: "",
        viewAll: false,
        list: this.groupNameList,
        mainList: this.groupNameList,
      },
      {
        key: "State",
        isOpen: false,
        searchString: "",
        viewAll: false,
        list: this.stateList,
        mainList: this.stateList,
      },
      {
        key: "Zip",
        isOpen: false,
        searchString: "",
        viewAll: false,
        list: this.zipList,
        mainList: this.zipList,
      }],
      rowData: extractedData,
    });
  }

  onSearchInputChange = (e: React.ChangeEvent<any>) => {
    const searchKey = e.target.value;
    this.setState({ searchValue: searchKey }, () =>
      this.handleFilterOrSearchOnChange(this.state.filterChildList)
    );
  };

  handleFilterClick = () => {
    this.setState({ openFilter: !this.state.openFilter });
  };

  handleFilterOrSearchOnChange = (newState: FilterChild[]) => {
    this.setState({ filterChildList: newState });
    const filterString = ([] as string[]).concat(
      ...newState.map((n) =>
        n.mainList.filter((l) => l.isChecked).map((l) => l.key)
      )
    );

    const newClientEmployerDivisionListAfterFilter = this.clientEmployerDivisionList.filter(
      (record) => {
        return filterString.length
          ? filterString
            .map(
              (filter) => {
                filter = filter.toString().toLowerCase()
                return record.division_name
                  .toLowerCase()
                  .includes(filter) ||
                  record.address_line1
                    .toLowerCase()
                    .includes(filter) ||
                  record.employer_name
                    .toLowerCase()
                    .includes(filter) ||
                  record.group_name
                    .toLowerCase()
                    .includes(filter) ||
                  record.effective_date
                    .toLowerCase()
                    .includes(filter) ||
                  record.state.toString().toLowerCase().includes(filter) ||
                  record.zip.toLowerCase().includes(filter)
              }
            )
            .some((filter) => filter)
          : true;
      }
    );

    const newClientEmployerDivisionList = newClientEmployerDivisionListAfterFilter.filter(
      (record) => {
        return (
          record.division_id
            .toLowerCase()
            .includes(this.state.searchValue.toLowerCase()) ||
          record.division_name
            .toLowerCase()
            .includes(this.state.searchValue.toLowerCase()) ||
          record.address_line1
            .toLowerCase()
            .includes(this.state.searchValue.toLowerCase()) ||
          record.employer_name
            .toLowerCase()
            .includes(this.state.searchValue.toLowerCase()) ||
          record.group_name
            .toLowerCase()
            .includes(this.state.searchValue.toLowerCase()) ||
          record.effective_date
            .toLowerCase()
            .includes(this.state.searchValue.toLowerCase()) ||
          record.state.toString()
            .toLowerCase()
            .includes(this.state.searchValue.toLowerCase()) ||
          record.zip
            .toLowerCase()
            .includes(this.state.searchValue.toLowerCase())
        );
      }
    );
    this.setState({ rowData: newClientEmployerDivisionList });
  };

  handleDivisionOpenTableMenu = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    this.setState({
      divisionTableMenuAnchorEl: event ? event.currentTarget : null,
    });
  };

  handleDivisionCloseTableMenu = () => {
    this.setState({ divisionTableMenuAnchorEl: null });
  };

  handleDivisionTableMenuClick = (id: string) => {
    if (id === "1") {
      this.toggleAddEditDivisionDialog(true, true);
    }
    if (id === "2") {
      this.callAPIDeleteDivision()
    }
  };

  callAPIDeleteDivision = () => {
    this.setState({
      divisionTableMenuAnchorEl: null
    })
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.state.token,
    }

    const requestMessageAddress = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.requestDeleteDivision = requestMessageAddress.messageId

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createDivisionAPiEndPoint}/${this.state.actionId}`
    )

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(requestMessageAddress.id, requestMessageAddress);
  }

  handleDeleteDivision(response: any) {
    if (response) {
      this.setState({ showDialogSuccess: true });
    }
  }

  handleSuccessDialogClose = () => {
    this.rawDataList = this.rawDataList.filter(item => item.id !== this.state.actionId)
    this.clientEmployerDivisionList = this.clientEmployerDivisionList.filter(item => item.id !== this.state.actionId)

    this.setState({
      rowData: this.clientEmployerDivisionList,
      actionId: '',
      showDialogSuccess: false
    })
  }

  toggleAddEditDivisionDialog = (open: boolean, isEdit: boolean) => {

    this.setState({
      ...this.state,
      isOpenAddEditDivisionDialog: open,
      divisionTableMenuAnchorEl: null,
      isEditDivision: isEdit,
    });
  };

  handleDivsionDialogClose = () => {
    this.toggleAddEditDivisionDialog(false, false);
    this.fetchAllDivisionsList(this.state.token)
  }

  convertResponseToModal = (data: any) => {
    const attributes = data.attributes

    const basicDetails = {
      division_name: attributes.division_name,
      division_id: attributes.division_id,
      client_name: attributes.client_name,
      employer_name: attributes.employer_name,
      employer_id: attributes.employer_id,
      group_id: attributes.group_id,
      effective_date: attributes.effective_date,
      termination_date: attributes.termination_date,
      deductible_date: attributes.deductible_date,
      payroll_day: attributes.payroll_day,
      payroll_month: attributes.payroll_month,
      first_payroll_cycle: attributes.first_payroll_cycle,
      second_payroll_cycle: attributes.second_payroll_cycle,
      third_payroll_cycle: attributes.third_payroll_cycle,
      fourth_payroll_cycle: attributes.fourth_payroll_cycle,
      tax_id: attributes.tax_id,
      apiDivisionId: data.id
    }

    const division_address = attributes.division_address.data?.attributes
    let addressDetails
    if (division_address) {
      addressDetails = {
        physical_address_line1: division_address.physical_address_line1,
        physical_address_line2: division_address.physical_address_line2,
        physical_city: division_address.physical_city,
        physical_zip_code: division_address.physical_zip_code,
        physical_state_id: division_address.physical_state,
        physical_country_id: division_address.physical_country,
        division_check_contact1: division_address.division_check_contact1,
        division_check_contact2: division_address.division_check_contact2,
        division_check_email1: division_address.division_check_email1,
        division_check_email2: division_address.division_check_email2,
        division_check_phone_number1: division_address.division_check_phone_number1,
        division_check_phone_number2: division_address.division_check_phone_number2,
        division_check_fax1: division_address.division_check_fax1,
        division_check_fax2: division_address.division_check_fax2,
        mailing_address_line1: division_address.mailing_address_line1,
        mailing_address_line2: division_address.mailing_address_line2,
        mailing_city: division_address.mailing_city,
        mailing_zip_code: division_address.mailing_zip_code,
        mailing_state_id: division_address.mailing_state,
        mailing_country_id: division_address.mailing_country,
        division_check_address_line1: division_address.division_check_address_line1,
        division_check_address_line2: division_address.division_check_address_line2,
        division_check_city: division_address.division_check_city,
        division_check_zip_code: division_address.division_check_zip_code,
        division_check_state_id: division_address.division_check_state,
        division_check_country_id: division_address.division_check_country,
      }
    } else {
      addressDetails = initialDivisionAddressDetailModelData
    }

    const divOtherDetails = attributes.division_other_details.data?.attributes
    let otherDetail

    if (divOtherDetails) {
      const agents: Array<IAgentModel> = [initialAgentModelData]
      try {
        for (const key in divOtherDetails) {
          if (key.includes('agent')) {
            const num = Number(key.slice(5, 6))
            agents[num - 1] = {
              id: num - 1,
              agent_name: divOtherDetails[`agent${num}_name`],
              agent_rate: divOtherDetails[`agent${num}_rate`]
            }
          }
        }
      } catch (error) {

      }

      otherDetail = {
        ...divOtherDetails,
        agents_attributes: agents
      }
    } else {
      otherDetail = initialDivisionOtherDetailModelData
    }

    const dataDetails: IDivisionDetailModel = {
      apiDivisionId: data.id,
      apiDivisionAddressId: attributes.division_address.data?.id || '',
      divisionBasicDetails: basicDetails,
      divisionAddressDetails: addressDetails,
      divisionOtherDetails: otherDetail,
    }


    return dataDetails
  }

  handleActionClick = (id: string) => {
    this.setState({
      actionId: id,
      divisionDetails: this.convertResponseToModal(this.rawDataList.find(item => item.id === id))
    })
  }
}
