import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { Grid, Divider } from "@material-ui/core";
import AddEditClientEmployerGroupDialogController, {
  Props,
  configJSON
} from "./AddEditClientEmployerGroupDialogController";
import "./ClientEmpGroups.css";
import GenericCloseDialog from "../../../../components/src/GenericCloseDialog.web";
import { defaultMaterialTheme } from "../../../dashboard/src/EditEmployerDetailsDialog.web";
import GenericDialogFormWrapper from "../../../../components/src/GenericDialogFormWrapper.web";
import GenericTextField from "../../../../components/src/GenericTextField.web";
import GenericDateField from "../../../../components/src/GenericDateField.web";
import GenericSelectField from "../../../../components/src/GenericSelectField.web";
import GenericAddressComponent from "../../../../components/src/GenericeAddressFields.web";
import GenericCheckBoxField from "../../../../components/src/GenericCheckBoxField.web";
import GenericAddAgentComponent from "../../../../components/src/GenericAddAgentComponent.web";
import GenericErrorText from "../../../../components/src/GenericErrorText.web";
import GenericSuccessDialog from "../../../../components/src/GenericSuccessDialog.web";

export default class AddEditClientEmployerGroupDialog extends AddEditClientEmployerGroupDialogController {
  constructor(props: Props) {
    super(props);
  }

  render() {

    const {
      groupActiveStep,
      addEditGroupStepList,
      isOpenWarningDialog,
      isGroupCreated
    } = this.state;
    const { groupBasicDetails, groupAddressDetails, groupOtherDetails } = this.state.groupData;

    const groupFormContent = (
      <>
        {groupActiveStep === 0 && (
          <Grid container spacing={3} data-test-id="step-1-wrapper">
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="group_name"
                label={configJSON.groupNameLabel}
                value={groupBasicDetails.group_name}
                onChange={(e) => this.handleGroupInputItemChange(e, "group_name")}
                error={this.getGroupErrorMessage("group_name")}
                data-test-id="group_name"
                isRequired={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="group_id"
                label={configJSON.groupIDLabel}
                value={groupBasicDetails.group_id}
                onChange={(e) => this.handleGroupInputItemChange(e, "group_id")}
                error={this.getGroupErrorMessage("group_id")}
                data-test-id="group_id"
                isRequired={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.clientNameLabel}
                value={groupBasicDetails.client_name}
                error={this.getGroupErrorMessage("client_name")}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.employerNameLabel}
                value={groupBasicDetails.employer_name}
                error={this.getGroupErrorMessage("employer_name")}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.employerNumberLabel}
                value={groupBasicDetails.employer_number}
                error={this.getGroupErrorMessage("employer_number")}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label={configJSON.groupBeginDateLabel}
                value={groupBasicDetails.group_being_date}
                onChange={date => this.handleGroupInputItemChange(date, "group_being_date")}
                data-test-id="group_being_date"
                error={this.getGroupErrorMessage("group_being_date")}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label={configJSON.groupTerminationDateLabel}
                value={groupBasicDetails.group_termination_date}
                onChange={(e) => this.handleGroupInputItemChange(e, "group_termination_date")}
                data-test-id="group_termination_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericDateField
                label={configJSON.groupDeductibleDateLabel}
                value={groupBasicDetails.group_deductible_date}
                onChange={(e) => this.handleGroupInputItemChange(e, "group_deductible_date")}
                data-test-id="group_deductible_date"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label={configJSON.firstPayrollDayLabel}
                selectionList={configJSON.payrollDayList}
                value={groupBasicDetails.payroll_day}
                onChange={(e) => this.handleGroupInputItemChange(e, "payroll_day")}
                data-test-id="payroll_day"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label={configJSON.firstPayrollMonthLabel}
                selectionList={configJSON.payrollMonthList}
                value={groupBasicDetails.payroll_month}
                onChange={(e) => this.handleGroupInputItemChange(e, "payroll_month")}
                data-test-id="payroll_month"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label={configJSON.payrollCycle1Label}
                selectionList={configJSON.payrollCycleList}
                value={groupBasicDetails.payroll_cycle1}
                onChange={(e) => this.handleGroupInputItemChange(e, "payroll_cycle1")}
                data-test-id="payroll_cycle1"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label={configJSON.payrollCycle2Label}
                selectionList={configJSON.payrollCycleList}
                value={groupBasicDetails.payroll_cycle2}
                onChange={(e) => this.handleGroupInputItemChange(e, "payroll_cycle2")}
                data-test-id="payroll_cycle2"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label={configJSON.payrollCycle3Label}
                selectionList={configJSON.payrollCycleList}
                value={groupBasicDetails.payroll_cycle3}
                onChange={(e) => this.handleGroupInputItemChange(e, "payroll_cycle3")}
                data-test-id="payroll_cycle3"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericSelectField
                label={configJSON.payrollCycle4Label}
                selectionList={configJSON.payrollCycleList}
                value={groupBasicDetails.payroll_cycle4}
                onChange={e => this.handleGroupInputItemChange(e, "payroll_cycle4")}
                data-test-id="payroll_cycle4"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="tax_id"
                label={configJSON.taxIDLabel}
                value={groupBasicDetails.tax_id}
                data-test-id="tax_id"
                isTaxId={true}
                isRequired={true}
                onChange={(e) => this.handleGroupInputItemChange(e, "tax_id")}
                inputProps={{
                  maxLength: 10
                }}
                error={this.getGroupErrorMessage("tax_id")}
              />
            </Grid>
          </Grid>
        )}
        {groupActiveStep === 1 && (
          <Grid container spacing={3} data-test-id="step-2-wrapper">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="employer-division-from-header"
            >
              {configJSON.physicalAddress}
            </Grid>
            <GenericAddressComponent
              data-test-id="physical-address"
              countryError={this.getGroupErrorMessage('physical_country')}
              stateError={this.getGroupErrorMessage('physical_state')}
              type="physical"
              addressValues={groupAddressDetails}
              handleAddressChange={this.handleAddressItemChange}
              countryList={this.state.countryList}
              stateList={this.state.statePhysicalList}
              isValidationDivision={true}
              disabledState={groupAddressDetails.physical_country_id == ""}
            />
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="emp-edit-address-header"
            >
              <div className="group-mailing-address-flex-wrapper">
                <div className="employer-division-from-header">
                  {configJSON.mailingAddress}
                </div>
                <div>
                  <GenericCheckBoxField
                    checked={this.state.isMailingSameAsPhysical}
                    label="Mailing address same as physical address"
                    onChange={(event: React.ChangeEvent<any>) =>
                      this.handleMailingGroupAddressCheckbox(event)
                    }
                    data-test-id="is_mailing_address_same_as_physical_address"
                  />
                </div>
              </div>
            </Grid>
            <GenericAddressComponent
              countryError={this.getGroupErrorMessage('mailing_country')}
              stateError={this.getGroupErrorMessage('mailing_state')}
              type="mailing"
              addressValues={groupAddressDetails}
              handleAddressChange={this.handleAddressItemChange}
              countryList={this.state.countryList}
              stateList={this.state.stateMailingList}
              disabled={!!this.state.isMailingSameAsPhysical}
              isValidationDivision={true}
              disabledState={groupAddressDetails.mailing_country_id == ""}
              data-test-id="mailing-address"
            />

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="emp-edit-address-header"
            >
              <div className="group-check-address-flex-wrapper">
                <div className="employer-division-from-header">
                  Group Check Address
                </div>
                <div>
                  <GenericCheckBoxField
                    checked={
                      this.state.isGroupCheckAddressSameAsPhysicalAddress
                    }
                    label="Group Check Address is same as Physical Address"
                    onChange={(event: React.ChangeEvent<any>) =>
                      this.handleGroupCheckSameAsPhysical(event, "isGroupCheckAddressSameAsPhysicalAddress")
                    }
                    data-test-id="is_group_address_same_as_physical_address"
                  />
                  <GenericCheckBoxField
                    checked={
                      this.state.isGroupCheckAddressSameAsMailingAddress
                    }
                    label="Group Check Address is same as Mailing Address"
                    onChange={(event: React.ChangeEvent<any>) =>
                      this.handleDivCheckSameAsMailing(event, "isGroupCheckAddressSameAsMailingAddress")
                    }
                    data-test-id="is_group_address_same_as_mailing_address"
                  />
                </div>
              </div>
            </Grid>
            <GenericAddressComponent
              countryError={this.getGroupErrorMessage('group_check_country')}
              stateError={this.getGroupErrorMessage('group_check_state')}
              type="group_check"
              addressValues={this.state.groupData.groupAddressDetails}
              handleAddressChange={this.handleAddressItemChange}
              countryList={this.state.countryList}
              stateList={this.state.stateGrpCheckList}
              disabled={
                !!this.state.isGroupCheckAddressSameAsMailingAddress ||
                !!this.state.isGroupCheckAddressSameAsPhysicalAddress
              }
              isValidationDivision={true}
              disabledState={groupAddressDetails.group_check_country_id == ""}
            />
            <div className="dividerWrapper">
              <Divider />
            </div>

            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="group_check_group_contact1"
                label={"Contact Contact 1"}
                value={groupAddressDetails.group_check_group_contact1}
                onChange={(e) => this.handleContactItemChange(e, "group_check_group_contact1")}
                data-test-id="group_check_group_contact1"
                onlyLetter={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="group_check_group_contact2"
                label={"Contact Contact 2"}
                value={groupAddressDetails.group_check_group_contact2}
                onChange={(e) => this.handleContactItemChange(e, "group_check_group_contact2")}
                data-test-id="group_check_group_contact2"
                onlyLetter={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="group_check_group_email1"
                label={"Group Email 1"}
                value={groupAddressDetails.group_check_group_email1}
                onChange={(e) => this.handleContactItemChange(e, "group_check_group_email1")}
                data-test-id="group_check_group_email1"
                error={this.getGroupErrorMessage('group_check_group_email1')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="group_check_group_email2"
                label={"Group Email 2"}
                value={groupAddressDetails.group_check_group_email2}
                onChange={(e) => this.handleContactItemChange(e, "group_check_group_email2")}
                data-test-id="group_check_group_email2"
                error={this.getGroupErrorMessage('group_check_group_email2')}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="group_check_group_phone_number1"
                label={"Group Phone Number 1"}
                value={groupAddressDetails.group_check_group_phone_number1}
                onChange={(e) => this.handleContactItemChange(e, "group_check_group_phone_number1")}
                data-test-id="group_check_group_phone_number1"
                onlyNumber={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="group_check_group_phone_number2"
                label={"Group Phone Number 2"}
                value={groupAddressDetails.group_check_group_phone_number2}
                onChange={(e) => this.handleContactItemChange(e, "group_check_group_phone_number2")}
                data-test-id="group_check_group_phone_number2"
                onlyNumber={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="group_check_group_billing_contact"
                label={"Group Billing Contact"}
                value={groupAddressDetails.group_check_group_billing_contact}
                onChange={(e) => this.handleContactItemChange(e, "group_check_group_billing_contact")}
                data-test-id="group_check_group_billing_contact"
                onlyLetter={true}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                name="group_check_group_enroll_status"
                label={"Enroll Status"}
                value={groupAddressDetails.group_check_group_enroll_status}
                onChange={(e) => this.handleContactItemChange(e, "group_check_group_enroll_status")}
                data-test-id="group_check_group_enroll_status"
                onlyLetter={true}
              />
            </Grid>
          </Grid>
        )}
        {groupActiveStep === 2 && (
          <Grid container spacing={3} data-test-id="step-3-wrapper" />
        )}
        {groupActiveStep === 3 && (
          <Grid container spacing={3} data-test-id="step-4-wrapper">
            <GenericAddAgentComponent
              addAgent={this.addGroupAgent}
              agentList={groupOtherDetails.agents}
              agentListError={[]}
              handleInputChange={this.handleOtherDetailsGroupInputChange}
              removeAgent={this.removeGroupAgent}
              isValidationDivision={true}
            />
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.cost_containment_fee}
                value={groupOtherDetails.cost_containment_fee}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(e, "cost_containment_fee")
                }
                data-test-id="cost_containment_fee"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.group_admin_fee}
                value={groupOtherDetails.employer_admin_fee}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(e, "employer_admin_fee")
                }
                data-test-id="admin_fee"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.group_ppo_fee}
                value={groupOtherDetails.employer_ppo_fee}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(e, "employer_ppo_fee")
                }
                data-test-id="ppo_fee"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.group_cobra_fee}
                value={groupOtherDetails.employer_cobra_fee}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(e, "employer_cobra_fee")
                }
                data-test-id="cobra_fee"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.group_hipaa_fee}
                value={groupOtherDetails.employer_hipaa_fee}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(e, "employer_hipaa_fee")
                }
                data-test-id="hipaa_fee"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.group_agg_premium_fee}
                value={groupOtherDetails.employer_agg_premium_fee}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(e, "employer_agg_premium_fee")
                }
                data-test-id="agg_premium_fee"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.group_fsa_fee}
                value={groupOtherDetails.employer_fsa_fee}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(e, "employer_fsa_fee")
                }
                data-test-id="fsa_fee"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.group_hra_fee}
                value={groupOtherDetails.employer_hra_fee}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(e, "employer_hra_fee")
                }
                data-test-id="hra_fee"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.group_hsa_fee}
                value={groupOtherDetails.employer_hsa_fee}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(e, "employer_hsa_fee")
                }
                data-test-id="hsa_fee"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.group_fee1}
                value={groupOtherDetails.employer_fee1}
                onChange={(e) => this.handleOtherDetailsGroupInputChange(e, "employer_fee1")}
                data-test-id="fee1"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.group_fee2}
                value={groupOtherDetails.employer_fee2}
                onChange={(e) => this.handleOtherDetailsGroupInputChange(e, "employer_fee2")}
                data-test-id="fee2"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.group_fee3}
                value={groupOtherDetails.employer_fee3}
                onChange={(e) => this.handleOtherDetailsGroupInputChange(e, "employer_fee3")}
                data-test-id="fee3"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.group_fee4}
                value={groupOtherDetails.employer_fee4}
                onChange={(e) => this.handleOtherDetailsGroupInputChange(e, "employer_fee4")}
                data-test-id="fee4"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.med_ee_spec_premium}
                value={groupOtherDetails.med_ee_spec_premium}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(e, "med_ee_spec_premium")
                }
                data-test-id="med_ee_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.den_ee_spec_premium}
                value={groupOtherDetails.den_ee_spec_premium}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(e, "den_ee_spec_premium")
                }
                data-test-id="den_ee_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.vis_ee_spec_premium}
                value={groupOtherDetails.vis_ee_spec_premium}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(e, "vis_ee_spec_premium")
                }
                data-test-id="vis_ee_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.med_ee_plus_sp_spec_premium}
                value={groupOtherDetails.med_ee_plus_sp_spec_premium}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(
                    e,
                    "med_ee_plus_sp_spec_premium"
                  )
                }
                data-test-id="med_ee_plus_sp_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.den_ee_plus_sp_spec_premium}
                value={groupOtherDetails.den_ee_plus_sp_spec_premium}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(
                    e,
                    "den_ee_plus_sp_spec_premium"
                  )
                }
                data-test-id="den_ee_plus_sp_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.vis_ee_plus_sp_spec_premium}
                value={groupOtherDetails.vis_ee_plus_sp_spec_premium}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(
                    e,
                    "vis_ee_plus_sp_spec_premium"
                  )
                }
                data-test-id="vis_ee_plus_sp_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.med_ee_plus_ch_spec_premium}
                value={groupOtherDetails.med_ee_plus_ch_spec_premium}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(
                    e,
                    "med_ee_plus_ch_spec_premium"
                  )
                }
                data-test-id="med_ee_plus_ch_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.den_ee_plus_ch_spec_premium}
                value={groupOtherDetails.den_ee_plus_ch_spec_premium}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(
                    e,
                    "den_ee_plus_ch_spec_premium"
                  )
                }
                data-test-id="den_ee_plus_ch_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.vis_ee_plus_ch_spec_premium}
                value={groupOtherDetails.vis_ee_plus_ch_spec_premium}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(
                    e,
                    "vis_ee_plus_ch_spec_premium"
                  )
                }
                data-test-id="vis_ee_plus_ch_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.med_ee_plus_chs_spec_premium}
                value={groupOtherDetails.med_ee_plus_chs_spec_premium}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(
                    e,
                    "med_ee_plus_chs_spec_premium"
                  )
                }
                data-test-id="med_ee_plus_chs_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.den_ee_plus_chs_spec_premium}
                value={groupOtherDetails.den_ee_plus_chs_spec_premium}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(
                    e,
                    "den_ee_plus_chs_spec_premium"
                  )
                }
                data-test-id="den_ee_plus_chs_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.vis_ee_plus_chs_spec_premium}
                value={groupOtherDetails.vis_ee_plus_chs_spec_premium}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(
                    e,
                    "vis_ee_plus_chs_spec_premium"
                  )
                }
                data-test-id="vis_ee_plus_chs_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.med_ee_fam_spec_premium}
                value={groupOtherDetails.med_ee_fam_spec_premium}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(
                    e,
                    "med_ee_fam_spec_premium"
                  )
                }
                data-test-id="med_ee_fam_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.den_ee_fam_spec_premium}
                value={groupOtherDetails.den_ee_fam_spec_premium}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(
                    e,
                    "den_ee_fam_spec_premium"
                  )
                }
                data-test-id="den_ee_fam_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.vis_ee_fam_spec_premium}
                value={groupOtherDetails.vis_ee_fam_spec_premium}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(
                    e,
                    "vis_ee_fam_spec_premium"
                  )
                }
                data-test-id="vis_ee_fam_spec_premium"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.specific_deductible}
                value={groupOtherDetails.specific_deductible}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(e, "specific_deductible")
                }
                data-test-id="specific_deductible"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.aggregate_deductible}
                value={groupOtherDetails.aggregate_deductible}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(e, "aggregate_deductible")
                }
                data-test-id="aggregate_deductible"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.surcharge_percentage}
                value={groupOtherDetails.surcharge_percentage}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(e, "surcharge_percentage")
                }
                data-test-id="surcharge_percentage"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericTextField
                label={configJSON.employer_waiting_period}
                value={groupOtherDetails.employer_waiting_period}
                onChange={(e) =>
                  this.handleOtherDetailsGroupInputChange(e, "employer_waiting_period")
                }
                data-test-id="employer_waiting_period"
                onlyNumber
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={groupOtherDetails.group_disability_flag}
                onChange={(e, checked) =>
                  this.toggleGroupOtherDetailsField(e, checked, "group_disability_flag")
                }
                label={configJSON.group_disability_flag}
                data-test-id="group_disability_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={groupOtherDetails.group_den_flag}
                onChange={(e, checked) =>
                  this.toggleGroupOtherDetailsField(e, checked, "group_den_flag")
                }
                label={configJSON.group_den_flag}
                data-test-id="group_den_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={groupOtherDetails.group_med_flag}
                onChange={(e, checked) =>
                  this.toggleGroupOtherDetailsField(e, checked, "group_med_flag")
                }
                label={configJSON.group_med_flag}
                data-test-id="med_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={groupOtherDetails.group_std_flag}
                onChange={(e, checked) =>
                  this.toggleGroupOtherDetailsField(e, checked, "group_std_flag")
                }
                label={configJSON.group_std_flag}
                data-test-id="std_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={groupOtherDetails.group_vis_flag}
                onChange={(e, checked) =>
                  this.toggleGroupOtherDetailsField(e, checked, "group_vis_flag")
                }
                label={configJSON.group_vis_flag}
                data-test-id="vis_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={groupOtherDetails.group_life_cover_flag}
                onChange={(e, checked) =>
                  this.toggleGroupOtherDetailsField(
                    e,
                    checked,
                    "group_life_cover_flag"
                  )
                }
                label={configJSON.group_life_cover_flag}
                data-test-id="life_cover_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={groupOtherDetails.group_ltd_flag}
                onChange={(e, checked) =>
                  this.toggleGroupOtherDetailsField(e, checked, "group_ltd_flag")
                }
                label={configJSON.group_ltd_flag}
                data-test-id="ltd_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={groupOtherDetails.group_vol_life_flag}
                onChange={(e, checked) =>
                  this.toggleGroupOtherDetailsField(e, checked, "group_vol_life_flag")
                }
                label={configJSON.group_vol_life_flag}
                data-test-id="vol_life_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={groupOtherDetails.group_add_flag}
                onChange={(e, checked) =>
                  this.toggleGroupOtherDetailsField(e, checked, "group_add_flag")
                }
                label={configJSON.group_add_flag}
                data-test-id="add_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={groupOtherDetails.group_fsa_flag}
                onChange={(e, checked) =>
                  this.toggleGroupOtherDetailsField(e, checked, "group_fsa_flag")
                }
                label={configJSON.group_fsa_flag}
                data-test-id="fsa_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={groupOtherDetails.group_rx_flag}
                onChange={(e, checked) =>
                  this.toggleGroupOtherDetailsField(e, checked, "group_rx_flag")
                }
                label={configJSON.group_rx_flag}
                data-test-id="rx_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={groupOtherDetails.group_hra_flag}
                onChange={(e, checked) =>
                  this.toggleGroupOtherDetailsField(e, checked, "group_hra_flag")
                }
                label={configJSON.group_hra_flag}
                data-test-id="hra_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={groupOtherDetails.group_cancer_flag}
                onChange={(e, checked) =>
                  this.toggleGroupOtherDetailsField(e, checked, "group_cancer_flag")
                }
                label={configJSON.group_cancer_flag}
                data-test-id="cancer_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={groupOtherDetails.group_hsa_flag}
                onChange={(e, checked) =>
                  this.toggleGroupOtherDetailsField(e, checked, "group_hsa_flag")
                }
                label={configJSON.group_hsa_flag}
                data-test-id="hsa_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={groupOtherDetails.group_medical_question_flag}
                onChange={(e, checked) =>
                  this.toggleGroupOtherDetailsField(
                    e,
                    checked,
                    "group_medical_question_flag"
                  )
                }
                label={configJSON.group_medical_question_flag}
                data-test-id="medical_question_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={groupOtherDetails.group_401k_flag}
                onChange={(e, checked) =>
                  this.toggleGroupOtherDetailsField(
                    e,
                    checked,
                    "group_401k_flag"
                  )
                }
                label={configJSON.group_401k_flag}
                data-test-id="group_401k_flag"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={groupOtherDetails.group_other_flag2}
                onChange={(e, checked) =>
                  this.toggleGroupOtherDetailsField(
                    e,
                    checked,
                    "group_other_flag2"
                  )
                }
                label={configJSON.group_other_flag2}
                data-test-id="group_other_flag2"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GenericCheckBoxField
                checked={groupOtherDetails.is_first_of_the_month}
                onChange={(e, checked) =>
                  this.toggleGroupOtherDetailsField(
                    e,
                    checked,
                    "is_first_of_the_month"
                  )
                }
                label={configJSON.firstOfTheMonth}
                data-test-id="is_first_of_the_month"
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <GenericErrorText error={this.state.generalError} />
        </Grid>
        {isGroupCreated && (
          <GenericSuccessDialog
            data-test-id="success-dialog"
            open={isGroupCreated}
            handleDone={this.handleSuccessDialogClose}
            contains={configJSON.groupAddSuccess}
          />
        )}
      </>
    )

    return (
      <ThemeProvider theme={defaultMaterialTheme}>
        <GenericDialogFormWrapper
          open={this.props.isOpenEditGroupDialog}
          title={
            this.props.isEdit ? configJSON.editGroup : configJSON.addNewGroup
          }
          handleClose={this.handleGroupDialogClose}
          stepList={addEditGroupStepList}
          activeStep={groupActiveStep}
          saveAndNext={this.saveAndNext}
          saveAndFinish={this.saveAndFinish}
          data-testid="submit-form"
          formContent={groupFormContent}
          disableOnClick={!addEditGroupStepList[0].isCompleted}
          setActiveStep={this.setActiveStep}
          handlePrevious={() => {
            this.handleSteps(false);
          }}
          buttonTitle={this.props.isEdit ? "Save Changes" : "+Add Group"}
        />
        {isOpenWarningDialog && (
          <GenericCloseDialog
            open={isOpenWarningDialog}
            handleClose={() => this.handleCloseWarningDialog(false)}
            handleOk={() => this.handleCloseWarningDialog(true)}
          />
        )}
      </ThemeProvider >
    );
  }
}
