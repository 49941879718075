import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";

import "./CustomTable.web.css";
import { Typography } from "@material-ui/core";
import { SelectedEmployerInterface } from "../../blocks/landingpage/src/AllRoleLandingPageController.web";

interface Props {
  columns: Column[];
  innerColumns?: Column[];
  rowData: any[];
  isRowExpandable?: boolean;
  isExpandedTitle?: string;
  isColumnSelectable?: boolean;
  getSelectedColumns?: (selectedColumn: {}[]) => void;
  onActionClick: (id: string) => void;
  rowCount?: number;
  handlePageChange?:(page: number) => void;
  pageNo?:number;
  selectedEmployer?: SelectedEmployerInterface[];
}

interface S {
  page: number;
  rowsPerPage: number;
  selectedColumn: string[];
}

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  headerAlign?: "right" | "center" | "left";
  align?: "right" | "center" | "left";
  format?: (row: any) => any;
  style?: React.CSSProperties;
}

export default class CustomerTable extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 7,
      selectedColumn: [],
    };
  }

  componentDidMount(): void {
    const {pageNo, selectedEmployer} = this.props;
    const {page,selectedColumn} = this.state;
    const Selectedcolums = selectedEmployer&&selectedEmployer.length ? selectedEmployer.map((i:{id:string})=> i.id): selectedColumn;
    this.setState({page:pageNo?pageNo-1:page,selectedColumn:Selectedcolums})
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.rowData !== this.props.rowData) {
      if(!this.props.pageNo){
        this.setState({ page: 0 });
      }
    }
  }

  handleChangePage = (event: any, newPage: number) => {
    this.setState({ page: newPage });
    if(!!this.props.handlePageChange){
      this.props.handlePageChange(newPage);
    }
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  onRowClick = (id: string) => {
    let newList = this.state.selectedColumn;
    if (newList.includes(id)) {
      newList = newList.filter((list) => list !== id);
    } else {
      newList.push(id);
    }
    const selectedAllData = this.props.rowData.filter((i) => newList.includes(i.id));
    this.setState({
      selectedColumn: newList,
    });
    if (this.props.getSelectedColumns) {
      this.props.getSelectedColumns(selectedAllData)
    };
  };

  handleRowData = () =>{
    const {pageNo,rowData} = this.props;
    const { page, rowsPerPage } = this.state;
    if(pageNo){
      return rowData
    }else{
      return rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }
  }

  render() {
    const { page, rowsPerPage } = this.state;

    return (
      <Paper className="w-100p custom-table-web">
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {this.props.isRowExpandable && <TableCell key="expandable" />}
                {this.props.columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.headerAlign}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.handleRowData().map((row, index) => (
                  <Row
                    key={`${index} ${row.id || 0}`}
                    columns={this.props.columns}
                    row={row}
                    isExpandedTitle={this.props.isExpandedTitle}
                    isRowExpandable={this.props.isRowExpandable}
                    data-test-id="custom-table-row"
                    innerColumns={this.props.innerColumns || []}
                    selectedColumn={
                      this.props.isColumnSelectable
                        ? this.state.selectedColumn
                        : []
                    }
                    onRowClick={this.onRowClick}
                    onActionClick={this.props.onActionClick}
                  />
                ))}
              {this.props.rowData.length === 0 && (
                <TableRow>
                  <TableCell
                    colSpan={
                      this.props.isRowExpandable
                        ? this.props.columns.length + 1
                        : this.props.columns.length
                    }
                    align="center"
                  >
                    No Record Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {this.props.rowData.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            data-test-id="table-pagination"
            count={this.props.rowCount?this.props.rowCount:this.props.rowData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            labelDisplayedRows={({ count }) => (
              <>
                Page <span className="page-number">{page + 1}</span> of{" "}
                {Math.ceil(count / rowsPerPage)}
              </>
            )}
          />
        )}
      </Paper>
    );
  }
}

interface RowProps {
  columns: Column[];
  innerColumns?: Column[];
  row: any;
  isRowExpandable?: boolean;
  isExpandedTitle?: string;
  selectedColumn: string[];
  onRowClick: (id: string) => void;
  onActionClick: (id: string) => void;
}

interface RowS {
  isExpanded: boolean;
}

class Row extends Component<RowProps, RowS> {
  constructor(props: RowProps) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  render() {
    return (
      <React.Fragment>
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          className={`${this.state.isExpanded ? "expanded-main-row" : ""} ${
            this.props.selectedColumn.includes(this.props.row.id || 0)
              ? "main-row-active"
              : ""
          }`}
        >
          {this.props.isRowExpandable && (
            <TableCell key="expandable">
              <IconButton
                className="custom-table-expandable-icon-btn"
                onClick={() =>
                  this.setState({ isExpanded: !this.state.isExpanded })
                }
              >
                {this.state.isExpanded ? <Remove /> : <Add />}
              </IconButton>
            </TableCell>
          )}
          {this.props.columns.map((column) => {
            const row = this.props.row;
            const value = row[column.id as keyof typeof row];
            return (
              <>
                <TableCell
                  data-test-id="custom-table-cell"
                  key={column.id}
                  align={column.align}
                  style={column.style}
                  onClick={() => {
                      column.label
                      ? this.props.onRowClick(this.props.row.id || 0)
                      : this.props.onActionClick(this.props.row.id)
                    }
                  }
                >
                  {column.format ? column.format(row) : value}
                </TableCell>
              </>
            );
          })}
        </TableRow>
        {this.state.isExpanded && (
          <TableRow className="custom-inner-table-row">
            <TableCell colSpan={this.props.columns.length + 1}>
              {this.props.isExpandedTitle && (
                <Typography
                  style={{ marginBottom: 12, color: "#3C4557", fontSize: 16 }}
                >
                  {this.props.isExpandedTitle}
                </Typography>
              )}
              <CustomInnerTable
                columns={this.props.innerColumns || []}
                rowData={this.props.row.innerRowData}
              />
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  }
}

interface CustomInnerTableProps {
  columns: Column[];
  rowData: any[];
}

interface CustomInnerTableS {}

class CustomInnerTable extends Component<
  CustomInnerTableProps,
  CustomInnerTableS
> {
  constructor(props: CustomInnerTableProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Paper className="w-100p custom-inner-table">
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {this.props.columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.headerAlign}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.rowData.map((row, index) => (
                <Row
                  key={`${index}`}
                  columns={this.props.columns}
                  row={row}
                  selectedColumn={[]}
                  onRowClick={() => {}}
                  onActionClick={() => {}}
                />
              ))}
              {this.props.rowData.length === 0 && (
                <TableRow>
                  <TableCell colSpan={this.props.columns.length} align="center">
                    No Record Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
}
