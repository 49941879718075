import React from "react";
import ClientDetailsController, { Props } from "./ClientDetailsController.web"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, Grid, Typography, Button, Select, MenuItem } from "@material-ui/core";
import { editIcon, userClient } from "./assets";
import Chart from "react-apexcharts";
import '../assets/Styles/ClientDetails.css'
import ClientEdits from "../../../components/src/ClientStepper/ClientEditsSteper/ClientEdits.web";
import CloseDialogMessage from "../../../components/src/ClientStepper/CloseDialogMessage.web";
import GenericSuccessDialog from "../../../components/src/GenericSuccessDialog.web";



export class ClientDetails extends ClientDetailsController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <>
                {/* client dasboard deatils */}
                <div style={{ display: 'flex', width: '100%' }}>
                    <Box component={"div"} className='client-details-mainheader-postion'>
                        {/* header */}
                        <Grid container alignItems="center">
                            <Grid container item xs={12} className='client-details-main' alignItems="center">
                                <Grid item >
                                    <Box component={"div"} className='name-tag'>
                                        <img src={userClient} alt="user_client" width={"120px"} height={"120px"} />
                                        <Box component={"div"} style={{ marginLeft: 20 }}>
                                            <Box component={"div"} className='name-tag'>
                                                <Typography className='name'>Matt Henry</Typography>
                                                <Button className='edit-btn' onClick={this.handleEditClick}><img src={editIcon} width={"15px"} height={"15px"} alt="edit-icon" style={{ marginRight: 3 }} />Edit</Button>
                                            </Box>
                                            <Typography className='address'>8 Moretown Mountain Road, Moretown,vt,<br />
                                                5660  United States</Typography>
                                        </Box>

                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box component={"div"} className='main-border'>
                                        <Typography className='main-title'>Contact Name</Typography>
                                        <Typography className='main-title-details'>Matt</Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box component={"div"} className='main-border'>
                                        <Typography className='main-title'>Contact Number</Typography>
                                        <Typography className='main-title-details'>9282191019</Typography>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box component={"div"} className='main-border'>
                                        <Typography className='main-title'>Email</Typography>
                                        <Typography className='main-title-details'>Matt.henry87@gmail.com</Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                        </Grid>
                        {/* Emloyer data */}
                        <Grid container justifyContent="space-between" alignItems="center" className='emloyer-data-grid'>
                            <Grid item>
                                <Typography className='sec-title'>Metrics</Typography>
                            </Grid>
                            <Grid item xs={6} md={5} lg={2}>
                                <Select
                                    IconComponent={ExpandMoreIcon}
                                    fullWidth
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    data-test-id="MetricsMonths"
                                    value={this.state.selecteMetricsMonths}
                                    style={{ boxShadow: "1px 1px 2px 2px rgba(90, 95, 111, 0.10)", padding: '10px', borderRadius: 8, background: "#FFF", fontFamily: 'Poppins' }}
                                    onChange={(e)=>this.handleMetricsMonths(e)} 
                                >
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((obj, ind) => {
                                        return (
                                            <MenuItem value={obj} key={ind}>{obj} Months</MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                        </Grid>
                        {/* Emloyer Data boxes */}
                        <Grid container spacing={2} style={{ marginTop: 20 }}>
                            {this.state.metricsData.map((cardObj: any, cardInd: number) => {
                                return (
                                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={`cardObj_${cardInd}`}>
                                        <Box component={"div"} className='metrics-card'>
                                            <Box component={"div"} className='metrics-card-contant' >
                                                <Box component={"div"}>
                                                    <Typography className='card-title' >
                                                        {cardObj.title}
                                                    </Typography>
                                                    <Typography className='card-count'>
                                                        {cardObj.count}
                                                    </Typography>
                                                </Box>
                                                {cardObj.images && <img src={cardObj.images}
                                                    width={"54px"}
                                                    height={"54px"} style={{ objectFit: 'contain' }} />}
                                            </Box>
                                            <Box component={"div"} className='card-contant-bottom'>
                                                <img src={cardObj.flag} width={"26.167px"} height={"16px"} style={{ marginRight: 10 }} />
                                                <Typography className='card-contant-bottom-title'>
                                                    {cardObj.des}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                            })}
                        </Grid>
                        {/* Data Chart*/}
                        <Grid container spacing={2} className='chart-root'>

                            {/* ==========================================  chart 1=================================== */}
                            <Grid item xs={12} sm={12} lg={6} style={{ display: "flex", justifyContent: "center" }}>
                                <Box className='chart-card'>
                                    <Grid container spacing={2} justifyContent="space-between">
                                        <Grid item xs={12} md={6}>
                                            <Typography className='chart-title'>
                                                Premium VS Claims Report
                                            </Typography>
                                            <Typography className='chart-title-des'>
                                                All Groups, Divisions, Locations
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Select
                                                variant="outlined"
                                                IconComponent={ExpandMoreIcon}
                                                value={this.state.selectedMonthschart1}
                                                onChange={(e) => this.handleMonthsChange(e)}
                                                data-test-id="selectMonth"
                                                className='select-field'
                                                fullWidth
                                            >
                                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((obj, index) => {
                                                    return (
                                                        <MenuItem style={{ fontFamily: 'Montserrat' }} value={obj} key={obj}>{obj} Months</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Select
                                                IconComponent={ExpandMoreIcon}
                                                variant="outlined"
                                                fullWidth
                                                data-test-id="selectGroup"
                                                value={this.state.selectedGroups}
                                                onChange={(e) => this.handleSelectedGroups(e)}
                                                className='select-field-group'
                                            >
                                                {['Groups', 'Divisions', 'Locations', 'Class'].map((obj, index) => {
                                                    return (
                                                        <MenuItem style={{ fontFamily: 'Poppins' }} value={obj} key={obj}>{obj}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <div className="chart-overflow-size">
                                        <Chart
                                            series={this.state.chartFour.series}
                                            width="600"
                                            type="line"
                                            options={this.state.chartFour.options}
                                            height="380"
                                        />
                                    </div>
                                </Box>
                            </Grid>

                            {/* ======================== chart 2 ============================= */}
                            <Grid item xs={12} sm={12} lg={6} style={{ display: "flex", justifyContent: "center" }}>
                                <Box className='chart-card'>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography className='chart-title'>
                                                Claim Efficiency
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Select
                                                variant="outlined"
                                                className='select-field'
                                                data-test-id="selectMonth4"
                                                onChange={(e) => this.handleMonthsChangeChart4(e)}
                                                IconComponent={ExpandMoreIcon}
                                                fullWidth
                                                value={this.state.selectedMonthschart4}
                                            >
                                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((obj, index) => {
                                                    return (
                                                        <MenuItem style={{ fontFamily: 'Montserrat' }} value={obj} key={obj}>{obj} Months</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <div className="chart-overflow-size">
                                        <Chart
                                            height="380"
                                            series={this.state.chartThree.series}
                                            type="bar"
                                            width="650"
                                            options={this.state.chartThree.options}
                                        />
                                    </div>
                                    <div className="totle-counter">
                                        <Typography className='chart-title'>
                                            Total Claims: <Box component={"span"}> 21340</Box>
                                        </Typography>
                                    </div>
                                </Box>
                            </Grid>


                            {/* // ========== chart 3 =========================// */}
                            <Grid item xs={12} sm={12} lg={6} style={{ display: "flex", justifyContent: "center" }}>
                                <Box className='chart-card'>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item xs={12} md={6}>
                                            <Typography className='chart-title'>
                                                Eligibility Report
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Select
                                                variant="outlined"
                                                fullWidth
                                                data-test-id="selectMonth3"
                                                value={this.state.selectedMonthschart3}
                                                onChange={(e) => this.handleMonthsChangechart3(e)}
                                                IconComponent={ExpandMoreIcon}
                                                className='select-field'
                                            >
                                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((obj, index) => {
                                                    return (
                                                        <MenuItem style={{ fontFamily: 'Montserrat' }} value={obj} key={obj}>{obj} Months</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <div className="chart-overflow-size">
                                        <Chart
                                            type="bar"
                                            options={this.state.chartOne.options}
                                            series={this.state.chartOne.series}
                                            height="390"
                                            width="650"
                                        />
                                    </div>
                                </Box>
                            </Grid>

                            {/* ===================================  chart 4  ======================================== */}
                            <Grid item xs={12} sm={12} lg={6} style={{ display: "flex", justifyContent: "center" }}>
                                <Box className='chart-card'>
                                    <Grid container justifyContent="space-between">
                                        <Grid item xs={12} md={6}>
                                            <Typography className='chart-title'>
                                                User Role Reports
                                            </Typography>
                                            <Typography className='chart-title-des'>
                                                All denail management reports
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Select
                                                variant="outlined"
                                                fullWidth
                                                value={this.state.selectedMonthschart2}
                                                onChange={(e) => this.handleMonthsChangechart2(e)}
                                                className='select-field'
                                                data-test-id="selectMonth2"
                                                IconComponent={ExpandMoreIcon}
                                            >
                                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((obj, index) => {
                                                    return (
                                                        <MenuItem style={{ fontFamily: 'Montserrat' }} value={obj} key={obj}>{obj} Months</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <div className="chart-overflow-size">
                                        <Chart
                                            height="380"
                                            type="bar"
                                            width="650"
                                            data-test-id="chartId"
                                            options={this.state.chartTwo.options}
                                            series={this.state.chartTwo.series}
                                        />
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>



                    </Box>
                    {this.state.isClientEditsOpen && (
                                <ClientEdits
                                    open={true}
                                    activeStep={this.state.activeStep}
                                    handlePrev={this.handlePrev}
                                    handleClose={this.handleCloseClientEdits}
                                    handleChangeValue={this.handleChangeValue}
                                    fromState={this.state.fromState}
                                    handleNext={this.handleNext}
                                    successModalOn={this.successModalOn}
                                />
                    )}
                    <CloseDialogMessage open={this.state.isEditModalClose} handleClose={this.handleCloseClientEditsClose} handleOk={this.handleCloseClientEditsAll} />
                    <GenericSuccessDialog open={this.state.isEditSuccessModal} handleDone={this.handleSuccessDialogClose} />
                </div>

            </>
        )
    }
}



export default ClientDetails