export const empTopSectionBG = require("../assets/empTopSectionBG.png");
export const companyIcon = require("../assets/companyIcon.png");
export const dropdownIcon = require("../assets/dropdownIcon.png");
export const editIcon = require("../assets/editIcon.png");
export const increaseIcon = require("../assets/increaseIcon.png");
export const decreaseIcon = require("../assets/decreaseIcon.png");
export const customerIcon = require("../assets/customerIcon.png");
export const groupIcon = require("../assets/groupIcon.png");
export const solidarityIcon = require("../assets/solidarityIcon.png");
export const calendarIcon = require("../assets/calendarIcon.png");
export const defaultProfileIcon = require("../assets/defaultProfile.png");
export const searchIcon = require("../assets/searchIcon.png");
export const folderImage = require("../assets/folderImage.png");
export const filterIcon = require("../assets/filterIcon.png");
export const blackEditIcon = require("../assets/blackEditIcon.png");
export const deleteIcon = require("../assets/deleteIcon.png");
export const claimIcon = require("../assets/claimIcon.png");
