import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { MainMenuList } from "../../../components/src/CustomLeftSideBarContainer.web";

export const configJSON = require("./config");
export const images = require("./assets");

export interface Props {
  navigation: any;
  id: string;
}

export interface SelectedEmployerInterface {
  id: string;
  emp_id: string;
  employer_name: string;
}

interface S {
  mainMenuList: MainMenuList[];
  selectedEmployer: SelectedEmployerInterface[];
}

interface SS {
  id: any;
}

export default class AllRoleLandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      mainMenuList: [
        {
          key: "MyDetails",
          icon: images.myDetails,
          selectedIcon: images.myDetailsSelected,
          label: configJSON.myDetails,
          navigation: "MyDetails",
          role: 3,
          showTopBorder: true
        },
        {
          key: "MyBenefits",
          icon: images.myBenefits,
          selectedIcon: images.myBenefitsSelected,
          label: configJSON.myBenefits,
          navigation: "MyBenefits",
          children: [
            {
              key: "MyCoverageHistory",
              label: "My Coverage History",
              navigation: "MyCoverageHistory"
            }
          ],
          role: 3
        },
        {
          key: "MyClaims",
          icon: images.myClaims,
          selectedIcon: images.myClaimsSelected,
          label: configJSON.myClaims,
          navigation: "MyClaims",
          role: 3
        },
        {
          key: "MyDocuments",
          icon: images.myDocuments,
          selectedIcon: images.myDocumentsSelected,
          label: configJSON.myDocuments,
          navigation: "MyDocuments",
          role: 3
        },
        {
          key: "MyEnrollments",
          icon: images.myEnrollments,
          selectedIcon: images.myDetailsSelected,
          label: configJSON.myEnrollments,
          navigation: "MyEnrollments",
          role: 3
        },
        {
          key: "MyNotifications",
          icon: images.myNotifications,
          selectedIcon: images.myNotificationsSelected,
          label: configJSON.myNotifications,
          navigation: "MyNotifications",
          role: 3,
          showCount: 4
        },
        {
          key: "FindHealthcare",
          icon: images.findHealthcare,
          selectedIcon: images.findHealthcareSelected,
          label: configJSON.findHealthcare,
          navigation: "FindHealthcare",
          role: 3,
          showTopBorder: true
        },
        {
          key: "FindPharmacy",
          icon: images.findPharmacy,
          selectedIcon: images.findPharmacySelected,
          label: configJSON.findPharmacy,
          navigation: "FindPharmacy",
          role: 3
        },
        {
          key: "Help",
          icon: images.help,
          selectedIcon: images.helpSelected,
          label: configJSON.help,
          navigation: "Help",
          role: 3,
          showTopBorder: true
        },
        {
          key: "ClientLandingPage",
          icon: images.myDetails,
          selectedIcon: images.myDetailsSelected,
          label: "Client Details",
          navigation: "ClientLandingPage",
          role: 1,
          showTopBorder: true
        },
        {
          key: "Employers",
          icon: images.Employer,
          selectedIcon: images.EmployersSelected,
          label: "Employers",
          navigation: "Employers",
          role: 1,
          children: [
            {
              key: "Groups",
              label: "Groups",
              icon: images.Groups,
              selectedIcon: images.GroupSelected,
              navigation: "Groups"
            },
            {
              key: "Divisions",
              label: "Divisions",
              icon: images.Divisions,
              selectedIcon: images.DivisionsSelected,
              navigation: "Divisions"
            },
            {
              key: "Locations",
              label: "Locations",
              icon: images.Location,
              selectedIcon: images.Location,
              navigation: "Locations"
            },
            {
              key: "Class",
              label: "Class",
              icon: images.Presentation,
              selectedIcon: images.Presentation,
              navigation: "Class"
            }
          ]
        },
        {
          key: "Members",
          icon: images.Members,
          selectedIcon: images.MembersSelected,
          label: "Members",
          navigation: "Members",
          role: 1
        },
        {
          key: "Union",
          icon: images.Union,
          selectedIcon: images.UnionSelected,
          label: "Union",
          navigation: "Union",
          role: 1
        },
        {
          key: "Notifications",
          icon: images.notifications,
          selectedIcon: images.NotificationsSelected,
          label: "Notifications",
          navigation: "Notifications",
          role: 1
        },
        {
          key: "Reports",
          icon: images.report,
          selectedIcon: images.ReportSelected,
          label: "Reports",
          navigation: "Reports",
          role: 1
        },
        {
          key: "More",
          icon: images.more,
          selectedIcon: images.MoreSelected,
          label: "More",
          navigation: "More",
          role: 1,
          showTopBorder: true
        },
        {
          key: "Release",
          icon: images.release,
          selectedIcon: images.ReleaseSelected,
          label: "Release",
          navigation: "Release",
          role: 1
        },
        {
          key: "ExportImport",
          icon: images.exportimg,
          selectedIcon: images.ExportSelected,
          label: "Export/Import",
          navigation: "ExportImport",
          role: 1
        },
        {
          key: "BroadcastMessage",
          icon: images.Broadcast,
          selectedIcon: images.BroadcastSelected,
          label: "Broadcast Message",
          navigation: "BroadcastMessage",
          role: 1
        },
        {
          key: "SupportHelp",
          icon: images.helpimg,
          selectedIcon: images.SupportSelected,
          label: "Support/Help",
          navigation: "SupportHelp",
          role: 1
        }
      ],
      selectedEmployer: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const storageEmp = localStorage.getItem("selectedEmployer");
    if (storageEmp) {
      const response = JSON.parse(storageEmp);
      this.setState({ selectedEmployer: response })
    }
  }

  handleSelectEmployer = (selectedRows: any[]) => {
    if (selectedRows.length === 0) {
      this.setState({ selectedEmployer: [] })
      return localStorage.setItem("selectedEmployer", '')
    }

    this.setState({
      selectedEmployer: selectedRows.map(item => ({ id: item.id, emp_id: item.emp_id, employer_name: item.employer_name }))
    },
      () => {
        localStorage.setItem("selectedEmployer", JSON.stringify(this.state.selectedEmployer))
      })
  }
}
