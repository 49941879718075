import React from 'react';
import { Field } from 'formik';
import { TextField, Grid, makeStyles, InputLabel, Input, FormHelperText } from '@material-ui/core';
import * as yup from 'yup';
import "../../../../blocks/landingpage/assets/Styles/ClientDetails.css"

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        height: 50,
        background: "#F4F6FB",
        borderRadius: 8
    },
    inputlabel: {
        fontFamily: "Montserrat",
        letterSpacing: 0,
        fontSize: 16,
        fontWeight: 400,
        marginBottom: theme.spacing(1),
        color: "#757A8C"
    },
    dateGrid: {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: 'column',
        justifyContent: 'flex-end'
    },
}));

const StepOne = (props: any) => {

    const { handleChangeValue, setValues, values } = props;

    const classes = useStyles()
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <InputLabel className={classes.inputlabel}>Client Name</InputLabel>
                    <Field name="client_name">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <TextField
                                {...field}
                                fullWidth
                                variant='outlined'
                                error={meta.touched && meta.error}
                                helperText={meta.touched && meta.error}
                                InputProps={{
                                    className: classes.inputStyle
                                }}
                                onChange={(event) => {
                                    handleChangeValue(event)
                                    setValues({
                                        ...values,
                                        client_name: event.target.value
                                    })
                                }}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item xs={6}>
                    <InputLabel className={classes.inputlabel}>Tax ID</InputLabel>
                    <Field name="tax_id">
                        {({
                            field, 
                            meta,
                        }: any) => (
                            <TextField
                                {...field}
                                fullWidth
                                variant='outlined'
                                error={meta.touched && meta.error}
                                helperText={meta.touched && meta.error}
                                InputProps={{
                                    className: classes.inputStyle
                                }}
                                onChange={(event) => {
                                    const inputValue = event.target.value;
                                    const sanitizedInput = inputValue.replace(/[^0-9]/g, '');
                                    let formattedInput = sanitizedInput;
                                    if (sanitizedInput.length > 2) {
                                        formattedInput = sanitizedInput.slice(0, 2) + '-' + sanitizedInput.slice(2);
                                    }
                                    handleChangeValue(event)
                                    setValues({
                                        ...values,
                                        tax_id: formattedInput
                                    })
                                }}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item xs={6}>
                    <InputLabel className={classes.inputlabel}>Effective Date</InputLabel>
                    <Field name="effective_date">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <>
                                <Input
                                    format="DD-MM-YYYY"
                                    type='date'
                                    {...field}
                                    className="effective-date-box"
                                    fullWidth
                                    variant='outlined'
                                    error={meta.touched && meta.error}
                                    style={{ border: meta.touched && meta.error ? "1px solid red" : "1px solid transparent" }}
                                    InputProps={{
                                        className: classes.inputStyle
                                    }}
                                    onChange={(event) => {
                                        handleChangeValue(event)
                                        setValues({
                                            ...values,
                                            effective_date: event.target.value
                                        })
                                    }}

                                />
                                {meta.touched && meta.error && (<FormHelperText style={{ color: "#FF0000" }}>{meta.touched && meta.error}</FormHelperText>)}
                            </>
                        )}
                    </Field>
                </Grid>
                <Grid item xs={6} className={classes.dateGrid}>
                <InputLabel className={classes.inputlabel}>Termination Date</InputLabel>
                    <Field name="effective_date_1">
                        {({
                            field,
                            meta,
                        }: any) => (
                            <>
                                <Input
                                    format="DD-MM-YYYY"
                                    type='date'
                                    {...field}
                                    style={{ border: meta.touched && meta.error ? "1px solid red" : "1px solid transparent" }}
                                    className="effective-date-box"
                                    fullWidth
                                    variant='outlined'
                                    error={meta.touched && meta.error}
                                    InputProps={{
                                        className: classes.inputStyle
                                    }}
                                    onChange={(event) => {
                                        handleChangeValue(event)
                                        setValues({
                                            ...values,
                                            effective_date_1: event.target.value
                                        })
                                    }}
                                />
                                {meta.touched && meta.error && (<FormHelperText style={{ color: "#FF0000" }}>{meta.touched && meta.error}</FormHelperText>)}
                            </>
                        )}
                    </Field>
                </Grid>
            </Grid>
        </>
    );
};

StepOne.label = 'step 1';
StepOne.initialValues = {
    client_name: '',
    tax_id: '',
    effective_date: null,
    effective_date_1: null
};
StepOne.validationSchema = yup.object().shape({
    client_name: yup.string()
        .max(30, 'Must be 30 characters or less')
        .required('Please enter your client name'),
    tax_id: yup.string()
        .matches(/^\d{2}-\d{7}$/, 'Tax ID format should be Xx-xxxxxxx')
        .required('Tax ID is required'),
    effective_date: yup.date().required('Date is required').nullable(),
    effective_date_1: yup.date().required('Date is required').nullable()
});

export default StepOne;
