import React, { Component } from "react";
import "./languageSelector.css"
import {
    Box,
    Input,
    List,
    ListItem,
} from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';


interface Props {
    navigation: any;
    id: string;
    selectedLanguage: string;
    openLanguageSelectionBox: any;
    languageInputValue: string;
    languageList: string[];
    languageSelectionClickHandler: any;
    searchInputOnChangeHandler: any;
    languageListClickHandler: any;
}

interface S {
    languageList: string[]
    selectedLanguage: string
    openLanguageSelectionBox: boolean
    languageInputValue: string
}


export default class LanguageSelector extends Component<Props, S> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const handleSelectedLanguageLabel = (selectedLanguage: string) => {
            if (selectedLanguage) {
                return selectedLanguage.length > 25 ? selectedLanguage.substring(0, 25) + "..." : selectedLanguage
            } else {
                return "Select Language"
            }
        }
        const filteredLanguageList = this.props.languageList?.filter(language =>
            language?.toLowerCase().startsWith(this.props.languageInputValue)
        );
        
        return (
            <Box className="member_details_language_selection_box">
                <Box
                    onClick={this.props.languageSelectionClickHandler}
                    className={`language_input_box ${!this.props.selectedLanguage && "text-gray-700"
                        }`}
                >
                    {handleSelectedLanguageLabel(this.props.selectedLanguage)}
                    <KeyboardArrowDownIcon className={`${this.props.openLanguageSelectionBox && "rotate-180"}`} />
                </Box>
                <List
                    className={`language_container ${this.props.openLanguageSelectionBox ? "max-h-160" : "hidden"
                        } `}
                >
                    <Box style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        borderRadius: "6px",
                        border: "1px solid #B1B6CD",
                        marginBottom: "10px",
                        paddingLeft: "5px",
                        height: "35px",
                    }}>

                        <SearchOutlinedIcon className="search_icon" style={{ marginRight: "5px" }} />
                        <Input
                            type="text"
                            value={this.props.languageInputValue}
                            onChange={(e) => this.props.searchInputOnChangeHandler(e.target.value.toLowerCase())}
                            placeholder="Enter Language"
                            className="language_input"
                        />
                    </Box>
                    { 
                    filteredLanguageList.length === 0 ? (
                        <ListItem className="language_list_item" onClick={() => this.props.languageListClickHandler("")}>
                            No record found
                        </ListItem>
                    ) : (
                        <>
                            {filteredLanguageList.map((language) => (
                                <ListItem
                                    key={language}
                                    className={`language_list_item
              ${language?.toLowerCase() === this.props.selectedLanguage?.toLowerCase() &&
                                        "selected_language_list_item"
                                        }
              ${language?.toLowerCase().startsWith(this.props.languageInputValue)
                                            ? "block"
                                            : "hidden"
                                        }`}
                                    onClick={() => this.props.languageListClickHandler(language)}
                                >
                                    {language}
                                </ListItem>
                            ))}
                        </>
                    )}
                </List>
            </Box>
        );
    }
}


