import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import HelpController, {
  Props,
  configJSON,
  images,
} from "./HelpController.web";
import "./Help.web.css";
import GenericButton from "../../../components/src/GenericButton.web";
import GenericSuccessDialog from "../../../components/src/GenericSuccessDialog.web";

export default class Help extends HelpController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <Grid container className="help-right-side-container">
          <Grid item xs={12} sm={12} md={12} lg={4} className="d-flex">
            <Typography variant="body1" className="greeting-name">
              {configJSON.getHelp}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} className="white-box-grid">
            <Button className="white-box">{configJSON.myIdCard}</Button>
            <Button
              data-test-id="help-find-healthcare"
              className="white-box"
              onClick={() =>
                this.props.navigation.navigate("FindHealthcare")
              }
            >
              {configJSON.findHealthcare}
            </Button>
            <Button className="white-box">{configJSON.benefitStatement}</Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5} className="help-img-grid">
            <img
              src={images.helpLeftImg}
              alt="Help Left Img"
              className="w-100p"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7}>
            <Grid container className="help-right-container">
              <img
                src={images.helpRightImg}
                alt="Help Right Img"
                className="help-right-img"
              />
              <Grid item xs={12} className="help-header">
                {configJSON.helpHeading1} <span>{configJSON.helpHeading2}</span>{" "}
                {configJSON.helpHeading3}
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="p-10 p-relative">
                <img
                  src={images.bigOval}
                  alt="Left Big Oval"
                  className="left-big-oval-img"
                />
                <img
                  src={images.oval}
                  alt="Left Oval"
                  className="left-oval-img"
                />
                <div className="contact-details-info-box">
                  <div className="contact-details-info-image">
                    <img
                      src={images.phoneIcon}
                      alt="phone icon"
                      className="contact-details-info-icon"
                    />
                  </div>
                  <div className="contact-details-info">
                    <Typography
                      variant="body1"
                      className="contact-details-info-label"
                    >
                      {configJSON.phoneNumber}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="contact-details-info-value"
                    >
                      {configJSON.phoneNumberValue}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="p-10 p-relative">
                <img
                  src={images.bigOval}
                  alt="Right Big Oval"
                  className="right-big-oval-img"
                />
                <img
                  src={images.oval}
                  alt="Right Oval"
                  className="right-oval-img"
                />
                <div className="contact-details-info-box">
                  <div className="contact-details-info-image">
                    <img
                      src={images.emailIcon}
                      alt="email icon"
                      className="contact-details-info-icon"
                    />
                  </div>
                  <div className="contact-details-info">
                    <Typography
                      variant="body1"
                      className="contact-details-info-label"
                    >
                      {configJSON.emailAddress}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="contact-details-info-value"
                    >
                      {configJSON.emailAddressValue}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="help-form-control p-10 mt-30"
              >
                <FormControl variant="outlined">
                  <OutlinedInput
                    data-test-id="help-input-first-name"
                    type="text"
                    placeholder={configJSON.firstName}
                    autoComplete="off"
                    value={this.state.firstName}
                    onChange={(e) => this.handleInputChange(e, "firstName")}
                    error={!!this.state.firstNameError}
                  />
                  {this.state.firstNameError && (
                    <Typography
                      variant="subtitle1"
                      component="div"
                      className="text-info-red"
                      data-test-id="firstNameError"
                    >
                      {this.state.firstNameError}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="help-form-control p-10 last-name"
              >
                <FormControl variant="outlined">
                  <OutlinedInput
                    data-test-id="help-input-last-name"
                    type="text"
                    placeholder={configJSON.lastName}
                    autoComplete="off"
                    value={this.state.lastName}
                    onChange={(e) => this.handleInputChange(e, "lastName")}
                    error={!!this.state.lastNameError}
                  />
                  {this.state.lastNameError && (
                    <Typography
                      variant="subtitle1"
                      component="div"
                      className="text-info-red"
                    >
                      {this.state.lastNameError}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="help-form-control p-10"
              >
                <FormControl variant="outlined">
                  <OutlinedInput
                    data-test-id="help-input-email-address"
                    type="text"
                    placeholder={configJSON.emailAddress}
                    autoComplete="off"
                    value={this.state.email}
                    onChange={(e) => this.handleInputChange(e, "email")}
                    error={!!this.state.emailError}
                  />
                  {this.state.emailError && (
                    <Typography
                      variant="subtitle1"
                      component="div"
                      className="text-info-red"
                      data-test-id="emailError"
                    >
                      {this.state.emailError}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="help-form-control p-10"
              >
                <FormControl variant="outlined">
                  <OutlinedInput
                    data-test-id="help-input-phone-number"
                    type="number"
                    placeholder={configJSON.phoneNumber}
                    autoComplete="off"
                    value={this.state.phone}
                    onChange={(e) => this.handleInputChange(e, "phone")}
                    error={!!this.state.phoneError}
                  />
                  {this.state.phoneError && (
                    <Typography
                      variant="subtitle1"
                      component="div"
                      className="text-info-red"
                    >
                      {this.state.phoneError}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} className="help-form-control p-10">
                <textarea
                  data-test-id="help-input-message"
                  rows={4}
                  className={`input-box ${
                    !!this.state.messageError && "input-box-error"
                  }`}
                  placeholder={configJSON.message}
                  value={this.state.message}
                  onChange={(e) => this.handleInputChange(e, "message")}
                />
                {this.state.messageError && (
                  <Typography
                    variant="subtitle1"
                    component="div"
                    className="text-info-red"
                  >
                    {this.state.messageError}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                className="help-form-control p-10 d-flex mt-10 mb-40"
                justifyContent="center"
              >
                <GenericButton
                  data-test-id="help-send-message"
                  variant="contained"
                  color="primary"
                  onClick={this.handleSendMessageClick}
                >
                  {configJSON.sendMessage}
                </GenericButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {this.state.isDataSubmitted && (
          <GenericSuccessDialog
            open={this.state.isDataSubmitted}
            handleDone={this.handleSuccessClose}
            contains={configJSON.helpSuccessMessage}
          />
        )}
      </>
    );
  }
}
