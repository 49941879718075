import React, { Component } from "react";

import "./GenericErrorText.web.css";

interface Props {
  error: string;
}

interface S {}

export default class GenericErrorText extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="generic-error-text">
        {this.props.error && (
          <div className="validation-msg" data-test-id="error-message">
            {this.props.error}
          </div>
        )}
      </div>
    );
  }
}
