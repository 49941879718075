import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { CompanyVision, DivisionsVision, Solidarity, moveDown, moveUp } from "./assets";

// Customizable Area Start
import StepOne from "../../../components/src/ClientStepper/ClientEditsSteper/StepOne.web";
import StepTwo from "../../../components/src/ClientStepper/ClientEditsSteper/StepTwo.web";
import StepThree from "../../../components/src/ClientStepper/ClientEditsSteper/StepThree.web";
// Customizable Area End

export const configJSON = require("./config");

const steps: any[] = [StepOne,StepTwo,StepThree];


export interface Props {
  id: string;
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  metricsData: any;
  chartOne: any;
  chartTwo: any;
  chartThree: any;
  chartFour: any;
  activeStep: number;
  fromState: any;
  isClientEditsOpen: boolean;
  isEditModalClose: boolean;
  isEditSuccessModal: boolean;
  physicalCountry_statesList:any;
  selectedMonthschart1:any;
  selectedGroups:any
  selectedMonthschart2:any;
  selectedMonthschart3:any;
  selectedMonthschart4:any;
  selecteMetricsMonths:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ClientDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedGroups:'Groups',
      selecteMetricsMonths:'6',
      selectedMonthschart1:'6',
      selectedMonthschart2:'6',
      selectedMonthschart3:'6',
      selectedMonthschart4:'6',
      isClientEditsOpen: false,
      physicalCountry_statesList:[],
      chartFour: configJSON.chartData1,
      chartThree: configJSON.chartData3,
      chartTwo:configJSON.chartData2,
      chartOne:configJSON.chartData4,
      metricsData: [
        {
          title: "Total Employers",
          count: "7460",
          images: CompanyVision,
          flag: moveUp,
          des: "5% increase than last month"
        },
        {
          title: "Total Groups",
          count: "1150",
          images: Solidarity,
          flag: moveUp,
          des: "12% increase than last month"
        },
        {
          title: "Total Divisions",
          count: "1089",
          images: DivisionsVision,
          flag: moveUp,
          des: "10% increase than last month"
        },
        {
          title: "Total Locations",
          count: "1089",
          images: null,
          flag: moveDown,
          des: "5% increase than last month"
        },
        {
          title: "Total Members",
          count: "24000",
          images: CompanyVision,
          flag: moveUp,
          des: "5% increase than last month"
        },
        {
          title: "Total Classes",
          count: "1020",
          images: Solidarity,
          flag: moveUp,
          des: "12% increase than last month"
        },
        {
          title: "Total Claims",
          count: "$3245",
          images: Solidarity,
          flag: moveUp,
          des: "12% increase than last month"
        },
        {
          title: "Total Premiums",
          count: "$1020",
          images: null,
          flag: moveUp,
          des: "12% increase than last month"
        },
      ],
      activeStep: 0,
      fromState: {
        values: { 
          client_name: '',
          tax_id: '',
          effective_date: null,
          effective_date_1: null
         },
      },
      
      isEditModalClose: false,
      isEditSuccessModal: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start

  handleMonthsChange=(event:any)=>{
    this.setState({selectedMonthschart1:event.target.value});
  }

  handleMonthsChangechart2 =(event:any)=>{
    this.setState({selectedMonthschart2:event.target.value});
  }

  handleMonthsChangechart3=(event:any)=>{
    this.setState({selectedMonthschart3:event.target.value});
  }

  handleMonthsChangeChart4 =(event:any)=>{
    this.setState({selectedMonthschart4:event.target.value});
  }

  handleSelectedGroups =(event:any)=>{
    this.setState({selectedGroups:event.target.value})
  }

  handleMetricsMonths=(event:any)=>{
    this.setState({selecteMetricsMonths:event.target.value});
  }


  handleChangeValue = (event: any) => {
      event.persist();
      this.setState((prevState) => ({
        fromState: {
          values: {
            ...prevState.fromState.values,
            [event.target.name]: event.target.value
          }
        }
      }))
  }

  handleEditClick = () => {
    this.setState({ isClientEditsOpen: true })
  }

  handleCloseClientEdits = () => {
    const { values } = this.state.fromState
    if (values.client_name === "" && values.tax_id === "") {
      this.setState({ isClientEditsOpen: false })
    } else {
      this.setState({ isEditModalClose: true })
    }
  }

  handleCloseClientEditsClose = () => {
    this.setState({ isEditModalClose: false })
  }

  handleCloseClientEditsAll = () => {
    this.setState({ isEditModalClose: false, isClientEditsOpen: false,activeStep: 0,
      fromState:{values: { 
        client_name: '',
        tax_id: '',
        effective_date: null,
        effective_date_1: null
       }} })
  }

  handleSuccessDialogClose = () => {
    this.setState({ isEditSuccessModal: false, isClientEditsOpen:false,activeStep: 0,fromState:{values: { 
      client_name: '',
      tax_id: '',
      effective_date: null,
      effective_date_1: null
     }} })
  }

isLastStep = () => {
  return this.state.activeStep === steps.length - 1;
};

handlePrev = () => {
  this.setState({activeStep:Math.max(this.state.activeStep - 1, 0)})
};

handleNext = () => [
  this.setState({activeStep:Math.min(this.state.activeStep + 1, steps.length - 1)})
];

successModalOn = () => {
  this.setState({ isEditSuccessModal: true})
}


  // Customizable Area End
}
