import moment from "moment";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { configJSON } from "./DashboardController";
import {
  IDivisionAddressValidationModel,
  IDivisionBasicDetailValidationModel,
  IEmployerDivisionModel,
  initialAddressModelData,
  initialDivisionAddressValidation,
  initialDivisionBasicDetailValidation,
  initialEmployerDivisionModelData,
} from "./EmployerDetailModel";
import { scrollToErrorField } from "./EditEmployerDetailsDialogController";

export const getStepLabelClassName = (
  index: number,
  divisionActiveStep: number
) => {
  let labelClassName = "";
  if (index < divisionActiveStep) {
    labelClassName = "step-label-completed";
  } else if (index === divisionActiveStep) {
    labelClassName = "step-label-active";
  } else {
    labelClassName = "step-label-default";
  }
  return labelClassName;
};

export interface Props {
  isOpen: boolean;
  isEdit: boolean;
  handleClose: any;
  divisionDetail: IEmployerDivisionModel;
  saveDivisionDetail: (divisionOb: IEmployerDivisionModel) => void;
}

interface S {
  divisionActiveStep: number;
  divisionData: IEmployerDivisionModel;
  supportingDocName: string;
  logoUrl: string;
  divisionBasicDetailsValidation: IDivisionBasicDetailValidationModel;
  divisionAddressDetailsValidation: IDivisionAddressValidationModel;
  logoErrorMessage: string;
  supportingDocErrorMessage: string;
  isOpenWarningDialog: boolean;
  isOpenGroupSearch: boolean;
  groupSearchInput: string;
}

interface SS {}

export default class EditEmployerDivisionDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  editDivisionStepList = [
    {
      key: 1,
      title: "Division Basic Details",
      description: "Type and Select details",
    },
    {
      key: 2,
      title: "Address & Contact",
      description: "Type and Select details",
    },
    {
      key: 3,
      title: "Upload Logo / Supporting Documents",
      description: "Type and Select details",
    },
  ];
  stateList = [
    { name: "Florida", value: "1" },
    { name: "Texas", value: "2" },
    { name: "New York", value: "3" },
  ];
  countryList = [
    { name: "India", value: "1" },
    { name: "USA", value: "2" },
    { name: "Canada", value: "3" },
  ];
  groupList = ["Premium", "Premium Plus", "Project ai"];

  constructor(props: Props) {
    super(props);

    this.state = {
      divisionActiveStep: 0,
      divisionData: initialEmployerDivisionModelData,
      supportingDocName: "",
      logoUrl: "",
      divisionBasicDetailsValidation: initialDivisionBasicDetailValidation,
      divisionAddressDetailsValidation: initialDivisionAddressValidation,
      logoErrorMessage: "",
      supportingDocErrorMessage: "",
      isOpenWarningDialog: false,
      isOpenGroupSearch: false,
      groupSearchInput: "",
    };
  }

  async componentDidMount() {
    this.setState({ ...this.state, divisionData: this.props.divisionDetail });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (((JSON.stringify(prevState.divisionData.physicalAddress) !==
        JSON.stringify(this.state.divisionData.physicalAddress)) ||
      (JSON.stringify(prevState.divisionData.mailingAddress) !==
        JSON.stringify(this.state.divisionData.mailingAddress)))) {

      let mailingAddress = this.state.divisionData.mailingAddress;
      let divisionCheckAddress = this.state.divisionData.divisionCheckAddress;

      if ((JSON.stringify(prevState.divisionData.physicalAddress) !==
        JSON.stringify(this.state.divisionData.physicalAddress)) && this.state.divisionData.isMailingAddressSameAsPhysicalAddress) {
        mailingAddress = this.state.divisionData.physicalAddress;
      } 
      if ((JSON.stringify(prevState.divisionData.physicalAddress) !==
        JSON.stringify(this.state.divisionData.physicalAddress)) && this.state.divisionData.isDivisionAddressSameAsPhysicalAddress) {
        divisionCheckAddress = this.state.divisionData.physicalAddress;
      }

      if ((JSON.stringify(prevState.divisionData.mailingAddress) !==
        JSON.stringify(this.state.divisionData.mailingAddress)) &&
        this.state.divisionData.isDivisionAddressSameAsMailingAddress) {
        divisionCheckAddress = this.state.divisionData.mailingAddress;
      }

      this.setState({
        ...this.state,
        divisionData: {
          ...this.state.divisionData,
          mailingAddress: mailingAddress,
          divisionCheckAddress: divisionCheckAddress
        }
      })
    }
  }

  handleDivisionInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    param: string
  ) => {
    this.setState({
      ...this.state,
      divisionData: { ...this.state.divisionData, [param]: e.target.value },
    });
  };

  handleAddressItemChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { value: unknown }
    >,
    type: string,
    param: string
  ) => {
    if (type === "physical") {
      this.setState({
        ...this.state,
        divisionData: {
          ...this.state.divisionData,
          physicalAddress: {
            ...this.state.divisionData.physicalAddress,
            [param]: e.target.value,
          },
        },
      });
    } else if (type === "mailing") {
      this.setState({
        ...this.state,
        divisionData: {
          ...this.state.divisionData,
          mailingAddress: {
            ...this.state.divisionData.mailingAddress,
            [param]: e.target.value,
          },
        },
      });
    } else if ("divisionCheckAddress") {
      this.setState({
        ...this.state,
        divisionData: {
          ...this.state.divisionData,
          divisionCheckAddress: {
            ...this.state.divisionData.divisionCheckAddress,
            [param]: e.target.value,
          },
        },
      });
    }
  };

  handleDateChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, param: string) => {
    this.setState({
      ...this.state,
      divisionData: { ...this.state.divisionData, [param]: event.target.value },
    });
  };

  toggleMailingAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      divisionData: {
        ...this.state.divisionData,
        isMailingAddressSameAsPhysicalAddress: event.target.checked,
        mailingAddress: event.target.checked ? this.state.divisionData.physicalAddress : initialAddressModelData,
      },
    });
  };

  toggleDivisionCheckAddressAsPrimary = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      ...this.state,
      divisionData: {
        ...this.state.divisionData,
        isDivisionAddressSameAsPhysicalAddress: event.target.checked,
        isDivisionAddressSameAsMailingAddress: event.target.checked ? false : this.state.divisionData.isDivisionAddressSameAsMailingAddress,
        divisionCheckAddress: event.target.checked ? this.state.divisionData.physicalAddress : initialAddressModelData,
      },
    });
  };

  toggleDivisionCheckAddressAsMailing = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      ...this.state,
      divisionData: {
        ...this.state.divisionData,
        isDivisionAddressSameAsMailingAddress: event.target.checked,
        isDivisionAddressSameAsPhysicalAddress: event.target.checked ? false : this.state.divisionData.isDivisionAddressSameAsPhysicalAddress,
        divisionCheckAddress: event.target.checked ? this.state.divisionData.mailingAddress : initialAddressModelData,
      },
    });
  };

  handleDivisionFileUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    param: string
  ) => {
    if (event.target.files && event.target.files[0]) {
      const fileObj = event.target.files[0];
      if (param === "logo") {
        this.setState({
          ...this.state,
          divisionData: { ...this.state.divisionData, logo: fileObj },
          logoUrl: URL.createObjectURL(fileObj),
        });
      } else if (param === "supportingDocument") {
        this.setState({
          ...this.state,
          divisionData: {
            ...this.state.divisionData,
            supportingDocument: fileObj,
          },
          supportingDocName: fileObj.name,
        });
      }
    }
  };

  itemListClickHandler = (groupName: string) => {
    this.setState({
      ...this.state,
      divisionData: { ...this.state.divisionData, groupName: groupName },
      isOpenGroupSearch: false,
      groupSearchInput: ""
    });
  };

  searchInputOnChangeHandler = (targetValue: string) => {
    this.setState({ ...this.state, groupSearchInput: targetValue });
  };

  groupItemSelectionClickHandler = () => {
    this.setState({
      ...this.state,
      isOpenGroupSearch: !this.state.isOpenGroupSearch
    });
  };

  handleCloseWarningDialog = (isAgreeToLooseData: boolean) => {
    if (isAgreeToLooseData) {
      this.setToInitialState();
      this.props.handleClose();
    }
    this.setState({ ...this.state, isOpenWarningDialog: false });
  };

  handleNext = () => {
    if (this.state.divisionActiveStep === 0) {
      this.validateDivisionBasicDetails();
    } else if (this.state.divisionActiveStep === 1) {
      this.validateDivisionAddressDetails();
    }
  };

  handlePrevious = () => {
    this.setState({
      ...this.state,
      divisionActiveStep: this.state.divisionActiveStep - 1
    });
  };

  handleDivisionDialogClose = () => {
    if (
      JSON.stringify(this.props.divisionDetail) !==
      JSON.stringify(this.state.divisionData)
    ) {
      this.setState({ ...this.state, isOpenWarningDialog: true });
    } else {
      this.setToInitialState();
      this.props.handleClose();
    }
  };

  setToInitialState = () => {
    this.setState({
      ...this.state,
      divisionActiveStep: 0,
      divisionData: initialEmployerDivisionModelData,
      divisionBasicDetailsValidation: initialDivisionBasicDetailValidation,
      divisionAddressDetailsValidation: initialDivisionAddressValidation,
      logoErrorMessage: "",
      supportingDocErrorMessage: "",
      logoUrl: "",
      supportingDocName: "",
      isOpenWarningDialog: false,
    });
  };

  saveAndNext = () => {
    this.handleNext();
  };

  submitDivisionData = () => {
    const employerEditedData = { ...this.state.divisionData };
    this.setToInitialState();
    this.props.saveDivisionDetail(employerEditedData);
  };

  validateRequiredField = (label: string, value: any) => {
    let errorMessage = "";
    if (!value || value.trim() === "") {
      errorMessage = `${label} is required`;
    } else if (value && value.trim().length > 30) {
      errorMessage = "Maximum length exceeded.";
    }
    return errorMessage;
  };

  validateUserID = (label: string, value: any) => {
    let userIDError = "";
    if (!value || value.trim() === "") {
      userIDError = `${label} is required`;
    } else if (value && value.trim().length > 30) {
      userIDError = "Maximum length exceeded.";
    } else if (value && !Number(value)) {
      userIDError = `${label} must be a number`;
    }
    return userIDError;
  };

  validatePhoneNumber = (label: string, phoneNumber: string) => {
    let phoneNumberError = "";
    if (!phoneNumber || phoneNumber.trim() === "") {
      phoneNumberError = `${label} is required`;
    } else if (phoneNumber && !configJSON.mobileNumberRegex.test(phoneNumber)) {
      phoneNumberError = `${label} must be 10 digit number`;
    }
    return phoneNumberError;
  };

  validateEmail = (label: string, email: string) => {
    let emailError = "";
    if (!email || email.trim() === "") {
      emailError = `${label} is required`;
    } else if (email && !configJSON.emailRegex.test(email)) {
      emailError = `Invalid ${label}`;
    }
    return emailError;
  };

  validateZipCode = (label: string, value: string) => {
    let zipCodeError = "";
    if (!value || value.trim() === "") {
      zipCodeError = `${label} is required`;
    } else if (value && !configJSON.zipCodeRegex.test(value)) {
      zipCodeError = `Invalid ${label}`;
    }
    return zipCodeError;
  };

  validateEffectiveDate = () => {
    const { effectiveDate } = this.state.divisionData;
    let effectiveDateError = "";
    if (!effectiveDate) {
      effectiveDateError = "Effective Date is required";
    }
    return effectiveDateError;
  };

  validateTerminationDate = () => {
    const { terminationDate, effectiveDate } = this.state.divisionData;
    let effectiveDateError = "";
    if (!terminationDate) {
      effectiveDateError = "Termination Date is required";
    } else if (terminationDate && effectiveDate) {
      const dateDiff = moment(effectiveDate).isAfter(terminationDate, "day");
      if (dateDiff) {
        effectiveDateError =
          "Termination Date should be greater than Effective Date";
      }
    }
    return effectiveDateError;
  };

  validateDivisionBasicDetails = () => {
    let divisionFirstStepValidation = {
      ...initialDivisionBasicDetailValidation,
    };
    const {
      employerName,
      employerID,
      clientName,
      divisionID,
      divisionName,
      groupName,
    } = this.state.divisionData;
    let errorField = "";
    const empIDError = this.validateUserID("Employer ID", employerID);
    const empNameError = this.validateRequiredField(
      "Employer Name",
      employerName
    );
    const clientNameError = this.validateRequiredField(
      "Client Name",
      clientName
    );
    const divisionIDError = this.validateUserID("Division ID", divisionID);
    const divisionName1Error = this.validateRequiredField(
      "Division Name",
      divisionName
    );
    const groupNameError = this.validateRequiredField("Group Name", groupName);
    const effectiveDateError = this.validateEffectiveDate();
    const terminateDateError = this.validateTerminationDate();
    let nextStep = 0;

    if (empIDError) {
      divisionFirstStepValidation.employerID = empIDError;
      errorField = "employerID";
    } else if (empNameError) {
      divisionFirstStepValidation.employerName = empNameError;
      errorField = "employerName";
    } else if (clientNameError) {
      divisionFirstStepValidation.clientName = clientNameError;
      errorField = "clientName";
    } else if (divisionIDError) {
      divisionFirstStepValidation.divisionID = divisionIDError;
      errorField = "divisionID";
    } else if (divisionName1Error) {
      divisionFirstStepValidation.divisionName = divisionName1Error;
      errorField = "divisionName";
    } else if (groupNameError) {
      divisionFirstStepValidation.groupName = groupNameError;
      errorField = "groupName";
    } else if (effectiveDateError) {
      divisionFirstStepValidation.effectiveDate = effectiveDateError;
      errorField = "effectiveDate";
    } else if (terminateDateError) {
      divisionFirstStepValidation.terminationDate = terminateDateError;
      errorField = "terminationDate";
    } else {
      nextStep = 1;
      divisionFirstStepValidation = initialDivisionBasicDetailValidation;
    }
    if (divisionFirstStepValidation !== initialDivisionBasicDetailValidation) {
      scrollToErrorField(errorField);
    }
    this.setState({
      ...this.state,
      divisionBasicDetailsValidation: divisionFirstStepValidation,
      divisionActiveStep: nextStep,
    });
  };

  validatePhysicalAddressDetails = () => {
    let physicalAddressValidation = { ...initialDivisionAddressValidation };
    const physicalAddressOb = this.state.divisionData.physicalAddress;
    let physicalAddressErrorFiled = "";

    const physicalAddressLine1Error = this.validateRequiredField(
      "Address Line 1",
      physicalAddressOb.addressLine1
    );
    const physicalAddressLine2Error = this.validateRequiredField(
      "Address Line 2",
      physicalAddressOb.addressLine2
    );
    const physicalCityError = this.validateRequiredField(
      "City / Town",
      physicalAddressOb.city
    );
    const physicalStateError = this.validateRequiredField(
      "State / County",
      physicalAddressOb.state
    );
    const physicalZipCodeError = this.validateZipCode(
      "Zipcode / Postal Code",
      physicalAddressOb.zipCode
    );
    const physicalCountryError = this.validateRequiredField(
      "Country",
      physicalAddressOb.country
    );

    if (physicalAddressLine1Error) {
      physicalAddressValidation.physicalAddressLine1 = physicalAddressLine1Error;
      physicalAddressErrorFiled = "physical-addressLine1";
    } else if (physicalAddressLine2Error) {
      physicalAddressValidation.physicalAddressLine2 = physicalAddressLine2Error;
      physicalAddressErrorFiled = "physical-addressLine2";
    } else if (physicalCityError) {
      physicalAddressValidation.physicalCity = physicalCityError;
      physicalAddressErrorFiled = "physical-city";
    } else if (physicalStateError) {
      physicalAddressValidation.physicalState = physicalStateError;
      physicalAddressErrorFiled = "physical-state";
    } else if (physicalZipCodeError) {
      physicalAddressValidation.physicalZipCode = physicalZipCodeError;
      physicalAddressErrorFiled = "physical-zipCode";
    } else if (physicalCountryError) {
      physicalAddressValidation.physicalCountry = physicalCountryError;
      physicalAddressErrorFiled = "physical-country";
    }
    return { physicalAddressValidation, physicalAddressErrorFiled };
  };

  validateMailingAddressDetails = () => {
    let mailingAddressValidation = { ...initialDivisionAddressValidation };
    const mailingAddressOb = this.state.divisionData.mailingAddress;
    let mailingAddressErrorFiled = "";

    const mailingAddressLine1Error = this.validateRequiredField(
      "Address Line 1",
      mailingAddressOb.addressLine1
    );
    const mailingAddressLine2Error = this.validateRequiredField(
      "Address Line 2",
      mailingAddressOb.addressLine2
    );
    const mailingCityError = this.validateRequiredField(
      "City / Town",
      mailingAddressOb.city
    );
    const mailingStateError = this.validateRequiredField(
      "State / County",
      mailingAddressOb.state
    );
    const mailingZipCodeError = this.validateZipCode(
      "Zipcode / Postal Code",
      mailingAddressOb.zipCode
    );
    const mailingCountryError = this.validateRequiredField(
      "Country",
      mailingAddressOb.country
    );

    if (mailingAddressLine1Error) {
      mailingAddressValidation.mailingAddressLine1 = mailingAddressLine1Error;
      mailingAddressErrorFiled = "mailing-addressLine1";
    } else if (mailingAddressLine2Error) {
      mailingAddressValidation.mailingAddressLine2 = mailingAddressLine2Error;
      mailingAddressErrorFiled = "mailing-addressLine2";
    } else if (mailingCityError) {
      mailingAddressValidation.mailingCity = mailingCityError;
      mailingAddressErrorFiled = "mailing-city";
    } else if (mailingStateError) {
      mailingAddressValidation.mailingState = mailingStateError;
      mailingAddressErrorFiled = "mailing-state";
    } else if (mailingZipCodeError) {
      mailingAddressValidation.mailingZipCode = mailingZipCodeError;
      mailingAddressErrorFiled = "mailing-zipCode";
    } else if (mailingCountryError) {
      mailingAddressValidation.mailingCountry = mailingCountryError;
      mailingAddressErrorFiled = "mailing-country";
    }
    return { mailingAddressValidation, mailingAddressErrorFiled };
  };

  validateDivisionCheckAddressDetails = () => {
    let divisionCheckAddressValidation = {
      ...initialDivisionAddressValidation,
    };
    const divisionCheckAddressOb = this.state.divisionData.divisionCheckAddress;
    let divisionCheckAddressErrorFiled = "";

    const divisionCheckAddressLine1Error = this.validateRequiredField(
      "Address Line 1",
      divisionCheckAddressOb.addressLine1
    );
    const divisionCheckAddressLine2Error = this.validateRequiredField(
      "Address Line 2",
      divisionCheckAddressOb.addressLine2
    );
    const divisionCheckCityError = this.validateRequiredField(
      "City / Town",
      divisionCheckAddressOb.city
    );
    const divisionCheckStateError = this.validateRequiredField(
      "State / County",
      divisionCheckAddressOb.state
    );
    const divisionCheckZipCodeError = this.validateZipCode(
      "Zipcode / Postal Code",
      divisionCheckAddressOb.zipCode
    );
    const divisionCheckCountryError = this.validateRequiredField(
      "Country",
      divisionCheckAddressOb.country
    );

    if (divisionCheckAddressLine1Error) {
      divisionCheckAddressValidation.divisionCheckAddressLine1 = divisionCheckAddressLine1Error;
      divisionCheckAddressErrorFiled = "divisionCheckAddress-addressLine1";
    } else if (divisionCheckAddressLine2Error) {
      divisionCheckAddressValidation.divisionCheckAddressLine2 = divisionCheckAddressLine2Error;
      divisionCheckAddressErrorFiled = "divisionCheckAddress-addressLine2";
    } else if (divisionCheckCityError) {
      divisionCheckAddressValidation.divisionCheckCity = divisionCheckCityError;
      divisionCheckAddressErrorFiled = "divisionCheckAddress-city";
    } else if (divisionCheckStateError) {
      divisionCheckAddressValidation.divisionCheckState = divisionCheckStateError;
      divisionCheckAddressErrorFiled = "divisionCheckAddress-state";
    } else if (divisionCheckZipCodeError) {
      divisionCheckAddressValidation.divisionCheckZipCode = divisionCheckZipCodeError;
      divisionCheckAddressErrorFiled = "divisionCheckAddress-zipCode";
    } else if (divisionCheckCountryError) {
      divisionCheckAddressValidation.divisionCheckCountry = divisionCheckCountryError;
      divisionCheckAddressErrorFiled = "divisionCheckAddress-country";
    }
    return { divisionCheckAddressValidation, divisionCheckAddressErrorFiled };
  };

  validateDivisionAddressDetails = () => {
    let divisionSecondStepValidation = { ...initialDivisionAddressValidation };
    const divisionSecondStepData = this.state.divisionData;
    let addressErrorField = "";

    const {
      physicalAddressValidation,
      physicalAddressErrorFiled,
    } = this.validatePhysicalAddressDetails();
    const {
      mailingAddressValidation,
      mailingAddressErrorFiled,
    } = this.validateMailingAddressDetails();
    const {
      divisionCheckAddressValidation,
      divisionCheckAddressErrorFiled,
    } = this.validateDivisionCheckAddressDetails();

    const groupContact1Error = this.validateRequiredField(
      "Group Contact 1",
      divisionSecondStepData.groupContact1
    );

    const groupContact2Error = this.validateRequiredField(
      "Group Contact 2",
      divisionSecondStepData.groupContact2
    );

    const groupEmail1Error = this.validateEmail(
      "Group Email 1",
      divisionSecondStepData.groupEmail1
    );

    const groupEmail2Error = this.validateEmail(
      "Group Email 2",
      divisionSecondStepData.groupEmail2
    );

    const groupPhoneNumber1Error = this.validatePhoneNumber(
      "Group Phone Number 1",
      divisionSecondStepData.groupPhoneNumber1
    );

    const groupPhoneNumber2Error = this.validatePhoneNumber(
      "Group Phone Number 2",
      divisionSecondStepData.groupPhoneNumber2
    );

    const groupBillingContactError = this.validateRequiredField(
      "Group Billing Contact",
      divisionSecondStepData.groupBillingContact
    );

    const enrollStatusError = this.validateRequiredField(
      "Enroll Status",
      divisionSecondStepData.enrollStatus
    );

    const groupTaxIDError = this.validateRequiredField(
      "Group Tax ID",
      divisionSecondStepData.groupTaxID
    );

    let nextStep = 1;

    if (physicalAddressErrorFiled) {
      addressErrorField = physicalAddressErrorFiled;
      divisionSecondStepValidation = physicalAddressValidation;
    } else if (mailingAddressErrorFiled) {
      addressErrorField = mailingAddressErrorFiled;
      divisionSecondStepValidation = mailingAddressValidation;
    } else if (divisionCheckAddressErrorFiled) {
      addressErrorField = divisionCheckAddressErrorFiled;
      divisionSecondStepValidation = divisionCheckAddressValidation;
    } else if (groupContact1Error) {
      addressErrorField = "groupContact1";
      divisionSecondStepValidation.groupContact1 = groupContact1Error;
    } else if (groupContact2Error) {
      addressErrorField = "groupContact2";
      divisionSecondStepValidation.groupContact2 = groupContact2Error;
    } else if (groupEmail1Error) {
      addressErrorField = "groupEmail1";
      divisionSecondStepValidation.groupEmail1 = groupEmail1Error;
    } else if (groupEmail2Error) {
      addressErrorField = "groupEmail2";
      divisionSecondStepValidation.groupEmail2 = groupEmail2Error;
    } else if (groupPhoneNumber1Error) {
      addressErrorField = "groupPhoneNumber1";
      divisionSecondStepValidation.groupPhoneNumber1 = groupPhoneNumber1Error;
    } else if (groupPhoneNumber2Error) {
      addressErrorField = "groupPhoneNumber2";
      divisionSecondStepValidation.groupPhoneNumber2 = groupPhoneNumber2Error;
    } else if (groupBillingContactError) {
      addressErrorField = "groupBillingContact";
      divisionSecondStepValidation.groupBillingContact = groupBillingContactError;
    } else if (enrollStatusError) {
      addressErrorField = "enrollStatus";
      divisionSecondStepValidation.enrollStatus = enrollStatusError;
    } else if (groupTaxIDError) {
      addressErrorField = "groupTaxID";
      divisionSecondStepValidation.groupTaxID = groupTaxIDError;
    } else {
      nextStep = 2;
      divisionSecondStepValidation = initialDivisionAddressValidation;
    }
    if (divisionSecondStepValidation !== initialDivisionAddressValidation) {
      scrollToErrorField(addressErrorField);
    }
    this.setState({
      ...this.state,
      divisionAddressDetailsValidation: divisionSecondStepValidation,
      divisionActiveStep: nextStep,
    });
  };

  validateDivisionFiles = () => {
    const { logo, supportingDocument } = this.state.divisionData;
    let logoError = "";
    let documentError = "";
    let divisionFileErrorField = "";

    if (!logo) {
      divisionFileErrorField = "logo";
      logoError = "Logo is required";
    } else if (!supportingDocument) {
      divisionFileErrorField = "supporting-doc";
      documentError = "Supporting Document is required";
    } else {
      this.submitDivisionData();
    }
    if (divisionFileErrorField) {
      scrollToErrorField(divisionFileErrorField);
    }
    this.setState({
      ...this.state,
      logoErrorMessage: logoError,
      supportingDocErrorMessage: documentError,
    });
  };
}
