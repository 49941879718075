import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";

import FindPharmacyController, {
  Props,
  configJSON,
  images,
} from "./FindPharmacyController.web";
import "./FindPharmacy.web.css";
import GenericSearch from "../../../components/src/GenericSearch.web";
import GenericButton from "../../../components/src/GenericButton.web";
import GenericDialog from "../../../components/src/GenericDialog.web";
import CustomFilterPopup from "../../../components/src/CustomFilterPopup.web";

export default class FindPharmacy extends FindPharmacyController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const dialogContainPharmacy = (
      <>
        <Grid container className="contact-details-contain-pharmacy">
          <Grid item xs={12}>
            <div className="contact-details-address">
              <Typography variant="body1" className="contact-details-label">
                {configJSON.address}
              </Typography>
              <Typography variant="body2" className="contact-details-value">
                {this.state.selectedContactDetails.address}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="contact-details-info-pharmacy-box">
              <div className="contact-details-info-pharmacy-image">
                <img
                  src={images.phoneIcon}
                  alt="phone icon"
                  className="contact-details-info-pharmacy-icon"
                />
              </div>
              <div className="contact-details-info-pharmacy">
                <Typography
                  variant="body1"
                  className="contact-details-info-pharmacy-label"
                >
                  {configJSON.phoneNumber}
                </Typography>
                <Typography
                  variant="body2"
                  className="contact-details-info-pharmacy-value"
                >
                  {this.state.selectedContactDetails.phone}
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="contact-details-info-pharmacy-box">
              <div className="contact-details-info-pharmacy-image">
                <img
                  src={images.emailIcon}
                  alt="email icon"
                  className="contact-details-info-pharmacy-icon"
                />
              </div>
              <div className="contact-details-info-pharmacy">
                <Typography
                  variant="body1"
                  className="contact-details-info-pharmacy-label"
                >
                  {configJSON.emailAddress}
                </Typography>
                <Typography
                  variant="body2"
                  className="contact-details-info-pharmacy-value"
                >
                  {this.state.selectedContactDetails.email}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    );

    return (
      <>
        <Grid container className="find-pharmacy-right-side-container">
          <Grid item xs={12} sm={12} md={12} lg={4} className="d-flex">
            <Typography variant="body1" className="greeting-name">
              {configJSON.findPharmacy}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} className="white-box-grid">
            <Button className="white-box">{configJSON.drugCovered}</Button>
            <Button className="white-box">{configJSON.findDrug}</Button>
            <Button className="white-box">{configJSON.researchDrug}</Button>
          </Grid>
          <Grid item xs={12} className="search-bar-and-add-btn p-relative">
            <GenericSearch
              data-test-id="find-pharmacy-search"
              searchIcon={images.searchIcon}
              placeholder={configJSON.findPharmacySearch}
              value={this.state.searchValue}
              onChange={this.onSearchInputChange}
            />
            <div className="search-filter-btn">
              <Button
                data-test-id="search-btn"
                className="search-btn"
                onClick={() =>
                  this.handlePharmacyFilterOrSearchOnChange(
                    this.state.filterChildList
                  )
                }
              >
                {configJSON.search}
              </Button>
              <Button
                data-test-id="filter-btn"
                onClick={this.handlePharmacyFilterClick}
                className="filter-icon"
              >
                <img
                  src={
                    this.state.openFilter
                      ? images.filterIconSelected
                      : images.filterIcon
                  }
                  alt="Filter Icon"
                />
              </Button>
              {this.state.openFilter && (
                <CustomFilterPopup
                  searchIcon={images.searchIcon}
                  filterChildList={this.state.filterChildList}
                  onChange={(state) =>
                    this.handlePharmacyFilterOrSearchOnChange(state)
                  }
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12} className="hr mt-30" />
          {this.state.pharmacyList.length ? (
            <Grid item xs={12} className="mt-30">
              {this.state.pharmacyList.map((list) => (
                <Grid
                  container
                  key={list.key}
                  className="find-pharmacy-list"
                  data-test-id="find-pharmacy-list"
                >
                  <Grid item xs={12} sm={9} className="pharmacy-info">
                    <img
                      src={list.img}
                      alt="pharmacy 1"
                      className="pharmacy-img"
                    />
                    <div className="pharmacy-details">
                      <Typography variant="body1" className="pharmacy-name">
                        {list.pharmacyName}
                      </Typography>
                      <Typography variant="body2" className="pharmacy-address">
                        {list.pharmacyTiming} | {list.address}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3} className="contact-us-btn">
                    <GenericButton
                      data-test-id={`contact-us-btn-${list.key}`}
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        this.handleContactUsClick(list.key as string)
                      }
                    >
                      {configJSON.contactUs}
                    </GenericButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid item xs={12} className="find-pharmacy-image-grid">
              <img
                src={images.findHealthcareImage}
                alt="Find Pharmacy Image"
                className="find-pharmacy-image"
              />
            </Grid>
          )}
        </Grid>
        {this.state.openContactUsDialog && (
          <GenericDialog
            title={configJSON.contactDetails}
            open={this.state.openContactUsDialog}
            handleClose={this.handleClose}
            contains={dialogContainPharmacy}
            className="contact-us-dialog"
            hideActions
            hideCloseDialog
            maxWidth="md"
          />
        )}
      </>
    );
  }
}
