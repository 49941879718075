import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

export const configJSON = require("../config");

export interface Props {}

interface S {
  isAddPlanEmployerDialogOpen: boolean;
}

interface SS {
  id: any;
}

export default class ClientEmployerPlanPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      isAddPlanEmployerDialogOpen: false
    };
  }

  toggleAddPlanEmployerDialog = (open: boolean) => {
    this.setState({ isAddPlanEmployerDialogOpen: open });
  };
}
