import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Column } from "../../../components/src/CustomTable.web";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

interface IMemberRowData {
  firstName: string;
  lastName: string;
  locationName: string;
  groupName: string;
  divisionName: string;
  memberNo: string;
  dob: string;
}

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  memberSearchString: string;
  memberRowItems: IMemberRowData[];
  membersTableMenuAnchorEl: HTMLElement | null;
  isOpenClaimSDialog: boolean;
  isClaimsDataAdded: boolean;
  selectedClaimType: string;
  selectedClaimFile: File | string;
  selectedClaimFileName: string;
  isMembersDataSaved: boolean;
  isOpenCloseWarningDialog: boolean;
  claimTypeValidationMessage: string;
  claimFileValidationMessage: string;
}

interface SS {}

const memberListData = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((memberItem) => ({
  firstName: "Adish",
  lastName: "Adish",
  locationName: "Builder ai location",
  groupName: "Builder ai grp",
  divisionName: "Builder ai member",
  memberNo: `8987899${memberItem}`,
  dob: `0${memberItem}/11/1998`,
}));

export default class EmployerMembersController extends BlockComponent<
  Props,
  S,
  SS
> {
  memberListColumns: Column[] = [
    { id: "firstName", label: "First Name", headerAlign: "left", align: "left" },
    { id: "lastName", label: "Last Name", headerAlign: "left", align: "left" },
    { id: "locationName", label: "Location Name", headerAlign: "left", align: "left" },
    { id: "groupName", headerAlign: "left", align: "left", label: "Group Name" },
    { id: "divisionName", label: "Division Name", headerAlign: "left", align: "left" },
    { id: "memberNo", label: "Member No.", headerAlign: "left", align: "left" },
    { id: "dob", label: "DOB", headerAlign: "left", align: "left" },
    {
      id: "action",
      label: "",
      headerAlign: "left",
      align: "right",
      format: (memberRow: IMemberRowData) => {
        return (
          <div className="member-table-action-container">
            <IconButton
              className="menu-btn"
              data-test-id="member-table-menu"
              onClick={this.handleMembersOpenTableMenu}
            >
              <MoreVertIcon fontSize="large" />
            </IconButton>
          </div>
        );
      },
    },
  ];
  claimList = [
    { name: "Medical", value: "medical" },
    { name: "Death benefit", value: "death" },
  ];

  constructor(props: Props) {
    super(props);

    this.state = {
      memberSearchString: "",
      memberRowItems: memberListData,
      membersTableMenuAnchorEl: null,
      isOpenClaimSDialog: false,
      isClaimsDataAdded: false,
      selectedClaimType: "",
      selectedClaimFile: "",
      selectedClaimFileName: "",
      isMembersDataSaved: false,
      isOpenCloseWarningDialog: false,
      claimFileValidationMessage: "",
      claimTypeValidationMessage: ""
    };
  }

  handleMembersOpenTableMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      ...this.state,
      membersTableMenuAnchorEl: event?.currentTarget,
    });
  };

  handleMembersCloseTableMenu = () => {
    this.setState({ ...this.state, membersTableMenuAnchorEl: null });
  };

  onCloseClaimsDialog = () => {
    if (!this.state.isClaimsDataAdded) {
      this.setState({
        ...this.state,
        isOpenClaimSDialog: false,
        isClaimsDataAdded: false,
        selectedClaimType: "",
        selectedClaimFile: "",
        selectedClaimFileName: "",
        claimFileValidationMessage: "",
        claimTypeValidationMessage: ""
      });
    } else {
      this.setState({ ...this.state, isOpenCloseWarningDialog: true });
    }
  };

  onOpenClaimsDialog = () => {
    this.setState({
      ...this.state,
      isOpenClaimSDialog: true,
      membersTableMenuAnchorEl: null,
      isClaimsDataAdded: false,
    });
  };

  handleChangeClaimType = (e: React.ChangeEvent<any>) => {
    this.setState({
      ...this.state,
      selectedClaimType: e.target.value,
      isClaimsDataAdded: true,
    });
  };

  handleChangeClaimFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      this.setState({
        ...this.state,
        selectedClaimFile: e.target.files[0],
        selectedClaimFileName: e.target.files[0].name,
        isClaimsDataAdded: true,
      });
    }
  };

  onSaveClaimsData = () => {
    if (this.state.selectedClaimFile && this.state.selectedClaimType) {
      this.setState({
        ...this.state,
        isMembersDataSaved: true,
        claimFileValidationMessage: "",
        claimTypeValidationMessage: ""
      });
    } else {
      if (!this.state.selectedClaimType) {
        this.setState({...this.state, claimFileValidationMessage: "", claimTypeValidationMessage: "Claim Type is required"});
      } else if (!this.state.selectedClaimFile) {
        this.setState({...this.state, claimFileValidationMessage: "Document file is required", claimTypeValidationMessage: ""});
      }
    }
  };

  handleCloseMembersConfirmationDialog = () => {
    this.setToInitialState();
  };

  handleCloseDialogClose = () => {
    this.setState({ ...this.state, isOpenCloseWarningDialog: false });
  };

  handleCloseDialogOk = () => {
    this.setToInitialState();
  };

  setToInitialState = () => {
    this.setState({
      ...this.state,
      isOpenCloseWarningDialog: false,
      isOpenClaimSDialog: false,
      isMembersDataSaved: false,
      isClaimsDataAdded: false,
      selectedClaimType: "",
      selectedClaimFile: "",
      selectedClaimFileName: "",
      claimFileValidationMessage: "",
      claimTypeValidationMessage: "",
    });
  }

  onSearchMemberChange = (e: React.ChangeEvent<any>) => {
    const searchParam = e.target.value;
    const updatedMemberList = memberListData.filter((member) => {
      return (
        member.firstName.toLowerCase().includes(searchParam.toLowerCase()) ||
        member.lastName.toLowerCase().includes(searchParam.toLowerCase()) ||
        member.locationName.toLowerCase().includes(searchParam.toLowerCase()) ||
        member.groupName.toLowerCase().includes(searchParam.toLowerCase()) ||
        member.divisionName.toLowerCase().includes(searchParam.toLowerCase()) ||
        member.memberNo.toLowerCase().includes(searchParam.toLowerCase()) ||
        member.dob.toLowerCase().includes(searchParam.toLowerCase())
      );
    });
    this.setState({
      ...this.state,
      memberSearchString: searchParam,
      memberRowItems: updatedMemberList,
    });
  };
}
