import React, { Component } from "react";
import { Button } from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import "./CustomFilterPopup.web.css";

export interface FilterChild {
  key: string;
  list: { key: string; isChecked: boolean }[];
  mainList: { key: string; isChecked: boolean }[];
  searchString: string;
  isOpen: boolean;
  viewAll: boolean;
}

interface Props {
  filterChildList: FilterChild[];
  searchIcon: string;
  onChange: (state: FilterChild[]) => void;
}

interface S {
  filterChildList: FilterChild[];
}

export default class CustomFilterPopup extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {
      filterChildList: this.props.filterChildList,
    };
  }

  handleFilterChildrenClick = (key: string) => {
    let newList = this.state.filterChildList.map((list) => {
      if (list.key === key) {
        return { ...list, isOpen: !list.isOpen };
      }
      return list;
    });
    this.setState({
      filterChildList: newList,
    });
  };

  handleCheckBoxChange = (key: string, childKey: string) => {
    let newList = this.state.filterChildList.map((list) => {
      if (list.key === key) {
        return {
          ...list,
          list: list.list.map((c) => {
            if (c.key === childKey) {
              return { ...c, isChecked: !c.isChecked };
            }
            return c;
          }),
          mainList: list.mainList.map((c) => {
            if (c.key === childKey) {
              return { ...c, isChecked: !c.isChecked };
            }
            return c;
          }),
        };
      }
      return list;
    });
    this.setState({
      filterChildList: newList,
    });
    this.props.onChange(newList);
  };

  handleViewAllClick = (key: string) => {
    let newList = this.state.filterChildList.map((list) => {
      if (list.key === key) {
        return { ...list, viewAll: !list.viewAll };
      }
      return list;
    });
    this.setState({
      filterChildList: newList,
    });
  };

  handleFilterSearchChange = (e: any, key: string) => {
    let newList = this.state.filterChildList.map((list) => {
      if (list.key === key) {
        const newChildList = list.mainList.filter((record) => {
          return record.key
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        });
        return { ...list, searchString: e.target.value, list: newChildList };
      }
      return list;
    });
    this.setState({
      filterChildList: newList,
    });
  };

  render() {
    return (
      <div className="custom-filter-popup">
        <div className="filter-popup-heading">Filters</div>
        <div className="hr" />
        <div className="filter-popup">
          {this.state.filterChildList.map((child) => (
            <div key={child.key}>
              <Button
                className="filter-popup-item"
                onClick={() => this.handleFilterChildrenClick(child.key)}
              >
                {child.isOpen ? (
                  <KeyboardArrowDown className="arrow-icon" />
                ) : (
                  <KeyboardArrowRight className="arrow-icon" />
                )}
                {child.key}
              </Button>
              {child.isOpen && (
                <div className="filter-popup-item-child">
                  <FormControl variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-search"
                      type="text"
                      placeholder="Search"
                      value={child.searchString}
                      onChange={(e) =>
                        this.handleFilterSearchChange(e, child.key)
                      }
                      startAdornment={
                        <img src={this.props.searchIcon} alt="Search Icon" />
                      }
                      autoComplete="off"
                    />
                  </FormControl>
                  {child.list.map((l, index) =>
                    child.viewAll || index < 2 ? (
                      <div
                        key={l.key}
                        className="filter-popup-item-child-label"
                      >
                        <Checkbox
                          className="filter-popup-check-box"
                          checked={l.isChecked}
                          onChange={() =>
                            this.handleCheckBoxChange(child.key, l.key)
                          }
                        />{" "}
                        {l.key}
                      </div>
                    ) : null
                  )}
                  {child.list.length ? (
                    <Button
                      className="view-all-btn"
                      color="primary"
                      onClick={() => this.handleViewAllClick(child.key)}
                    >
                      {child.viewAll ? "View Less" : "View All"}
                    </Button>
                  ) : (
                    <div className="filter-popup-item-child-label mb-10 ml-10">
                      No result found
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
