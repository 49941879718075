import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Column } from "../../../../components/src/CustomTable.web";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { IGroupDetailModel, initialGroupDetailModelData } from "./ClientEmpGroupModel";
import { FilterChild } from "../../../../components/src/CustomFilterPopup.web";
import { SelectedEmployerInterface } from "../AllRoleLandingPageController.web";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";

export const configJSON = require("../config");
interface IGroupRowData {
  id: string;
  groupNo: string;
  groupName: string;
  clientName: string;
  employerName: string;
  city: string;
  state: string;
  zip: string;
  effective_date: string;
  termination_date: string;
  contact_name: string;
  contact_number: string;
}
export interface Props {
  id: string;
  navigation: any;
  selectedEmployer: SelectedEmployerInterface[]
}

interface S {
  groupSearchText: string;
  groupRowItems: IGroupRowData[];
  groupTableMenuAnchorEl: HTMLElement | null;
  isOpenEditGroupsDialog: boolean;
  isGroupDetailSaved: boolean;
  isEditGroup: boolean;
  groupDetails: IGroupDetailModel;
  openGroupFilter: boolean;
  groupFilterChildList: FilterChild[];
  token: string;
}

interface SS { }

export default class ClientEmpGroupsLandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  groupListColumns: Column[] = [
    { id: "groupNo", label: "Group No", },
    { id: "groupName", label: "Group Name", },
    { id: "clientName", label: "Client Name", },
    { id: "employerName", label: "Employer Name" },
    { id: "city", label: "City", },
    { id: "state", label: "State" },
    { id: "zip", label: "Zip" },
    { id: "effective_date", label: "Effective Date", },
    { id: "termination_date", label: "Termination Date", },
    { id: "contact_name", label: "Contact Name", },
    { id: "contact_number", label: "Contact Number", },
    {
      id: "action",
      label: "",
      headerAlign: "left",
      align: "right",
      format: (groupRow: IGroupRowData) => {
        return (
          <div className="grp-table-action-container">
            <IconButton
              className="menu-btn"
              data-test-id="group-table-menu"
              onClick={this.handleGroupsOpenTableMenu}
            >
              <MoreVertIcon fontSize="large" />
            </IconButton>
          </div>
        );
      },
    },
  ];
  groupTabMenuList = [
    "Plan",
    "Enrollment",
    "Enroll Config",
    "Medical Review",
    "Pay Claims",
    "Recon",
    "Invoice",
    "Payroll Invoice",
    "Case Billing",
    "Documents",
  ];
  requestGroupList: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    const groupNameList: never[] = []

    const clientNameList: never[] = []

    const employerNameList: never[] = []

    const cityList: never[] = []

    const stateList: never[] = []

    const zipList: never[] = []

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      groupSearchText: "",
      groupRowItems: [],
      groupTableMenuAnchorEl: null,
      isEditGroup: false,
      isGroupDetailSaved: false,
      isOpenEditGroupsDialog: false,
      groupDetails: initialGroupDetailModelData,
      openGroupFilter: false,
      groupFilterChildList: [
        {
          key: "Group Name",
          isOpen: true,
          searchString: "",
          viewAll: false,
          list: groupNameList,
          mainList: groupNameList,
        },
        {
          key: "Client Name",
          isOpen: false,
          searchString: "",
          viewAll: false,
          list: clientNameList,
          mainList: clientNameList,
        },
        {
          key: "Employer Name",
          isOpen: false,
          searchString: "",
          viewAll: false,
          list: employerNameList,
          mainList: employerNameList,
        },
        {
          key: "City",
          isOpen: false,
          searchString: "",
          viewAll: false,
          list: cityList,
          mainList: cityList,
        },
        {
          key: "State",
          isOpen: false,
          searchString: "",
          viewAll: false,
          list: stateList,
          mainList: stateList,
        },
        {
          key: "Zip",
          isOpen: false,
          searchString: "",
          viewAll: false,
          list: zipList,
          mainList: zipList,
        },
      ],
      token: ''
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const { token } = JSON.parse(loggedInUser);
      this.setState({ token: token })
      this.fetchGroupsList(token);
    }
  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.requestGroupList) {
        this.receiveGroupList(responseJson)
      }
    }
  }

  fetchGroupsList = (token: string) => {
    if (this.props.selectedEmployer.length === 0) {
      return;
    }
    const header = {
      "Content-Type": configJSON.ContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.requestGroupList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGroupNewAPiEndPoint.replace("{id}", this.props.selectedEmployer[0].id)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  receiveGroupList = (response: any) => {
    if (response && response.data && response.data.length > 0) {
      this.extractTableData(response.data);
    }
  }

  extractTableData = (apiData: any) => {
    const extractedData: IGroupRowData[] = [];
    apiData.forEach((item: any) => {
      const id = item.id;
      const groupNo = item.attributes.group_id || "-";
      const groupName = item.attributes.group_name || "-";
      const clientName = item.attributes.client_name || "-";
      const employerName = item.attributes.employer_name || "-";

      const effective_date = item.attributes.group_being_date || "-";
      const termination_date = item.attributes.group_termination_date || "-";

      const physical_address = item.attributes.physical_address.data
      const contact = item.attributes.contact.data
      let city = "-"
      let state = "-"
      let zip = "-"
      let contact_name = "-"
      let contact_number = "-"
      if (physical_address) {
        city = physical_address?.attributes.city || "-";
        state = physical_address?.attributes.state || "-";
        zip = physical_address?.attributes.zip_code || "-";
      }

      if (contact && contact.length !== 0) {
        contact_name = contact[0].attributes.contact || "-"
        contact_number = contact[0].attributes.phone_number || "-"
      }

      const extractedItem = {
        id,
        groupNo,
        groupName,
        clientName,
        employerName,
        city,
        state,
        zip,
        effective_date,
        termination_date,
        contact_name,
        contact_number
      }
      extractedData.push(extractedItem);
    });

    this.setState({ groupRowItems: extractedData })
  }

  handleGroupsOpenTableMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      ...this.state,
      groupTableMenuAnchorEl: event?.currentTarget,
    });
  };

  handleGroupsCloseTableMenu = () => {
    this.setState({ ...this.state, groupTableMenuAnchorEl: null });
  };

  onSearchGroupChange = (e: React.ChangeEvent<any>) => {
    const searchGrpParam = e.target.value;
    this.setState({ ...this.state, groupSearchText: searchGrpParam }, () =>
      this.handleGroupFilterOrSearchOnChange(this.state.groupFilterChildList)
    )
  };

  toggleEditGroupDialog = (open: boolean, isEdit: boolean) => {
    this.setState({
      ...this.state,
      isOpenEditGroupsDialog: open,
      groupTableMenuAnchorEl: null,
      isEditGroup: isEdit
    });
  };

  toggleGroupFilter = () => {
    this.setState({ ...this.state, openGroupFilter: !this.state.openGroupFilter });
  }

  handleGroupFilterOrSearchOnChange = (newState: FilterChild[]) => {
    this.setState({ groupFilterChildList: newState });

  };

  handleGroupDialogClose = () => {
    this.toggleEditGroupDialog(false, false);
    this.fetchGroupsList(this.state.token)
  }
}
