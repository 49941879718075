import React from "react";
import { Switch, Route } from "react-router-dom";

import AllRoleLandingPageController, {
  Props,
  configJSON,
  images,
} from "./AllRoleLandingPageController.web";
import "./AllRoleLandingPage.web.css";
import CustomLeftSideBarContainer from "../../../components/src/CustomLeftSideBarContainer.web";
import ClientDetails from "./ClientDetails.web";
import ClientEmployerLandingPage from "./clientEmployer/ClientEmployerLandingPage.web";
import ClientEmpGroupsLandingPage from "./ClientEmployerGroups/ClientEmpGroupsLandingPage.web";
import ClientEmployerDivision from "./ClientEmployerDivision/ClientEmployerDivision.web";
import ClientEmployerLocation from "./ClientEmployerLocation/ClientEmployerLocation.web";
import ClientEmployerClass from "./ClientEmployerClass/ClientEmployerClass.web";
import MemberScreenMyDetails from "./MemberScreenMyDetails.web";
import MyClaims from "../../MemberDirectory2/src/MyClaims.web";
import MyDocuments from "../../MemberDirectory2/src/MyDocuments.web";
import MyNotifications from "../../MemberDirectory2/src/MyNotifications.web";
import FindHealthcare from "../../MemberDirectory2/src/FindHealthcare.web";
import FindPharmacy from "../../MemberDirectory2/src/FindPharmacy.web";
import Help from "../../MemberDirectory2/src/Help.web";
import EmployerDetails from "../../dashboard/src/EmployerDetails.web";
import EmployerDocuments from "../../dashboard/src/EmployerDocuments.web";
import EmployerDivisions from "../../dashboard/src/EmployerDivisions.web";
import EmployerMembers from "../../dashboard/src/EmployerMembers.web";
import ClientMemberLandingPage from "./ClientMember/ClientMemberLandingPage.web";

export default class AllRoleLandingPage extends AllRoleLandingPageController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className="all-role-landing-page-main-body h-100p">
        <div className="main-container">
          <CustomLeftSideBarContainer
            mainMenuList={this.state.mainMenuList.filter(
              (list) => list.role === 1
            )}
            navigation={this.props.navigation}
            userEmail={configJSON.userEmail}
            userName={configJSON.userName}
            userImage={images.userImage}
            selectedEmployer={this.state.selectedEmployer}
          />
          <div className="pl-30 right-container">
            <Switch>
              <Route
                path="/Home/ClientLandingPage"
                render={() => (
                  <ClientDetails id={""} navigation={this.props.navigation} />
                )}
                exact
              />
              <Route
                path="/Home/Employers"
                render={() => (
                  <ClientEmployerLandingPage
                    id={""}
                    navigation={this.props.navigation}
                    handleSelectedRow={this.handleSelectEmployer}
                    selectedEmployer={this.state.selectedEmployer}
                  />
                )}
                exact
              />
              <Route
                path="/Home/Employers/Groups"
                render={() => (
                  <ClientEmpGroupsLandingPage
                    id={""}
                    navigation={this.props.navigation}
                    selectedEmployer={this.state.selectedEmployer}
                  />
                )}
              />
              <Route
                path="/Home/Employers/Divisions"
                render={() => (
                  <ClientEmployerDivision
                    id=""
                    navigation={this.props.navigation}
                    selectedEmployer={this.state.selectedEmployer}
                  />
                )}
              />
              <Route
                path="/Home/Employers/Locations"
                render={() => (
                  <ClientEmployerLocation
                    id={""}
                    navigation={this.props.navigation}
                    selectedEmployer={this.state.selectedEmployer}
                  />
                )}
              />
              <Route
                path="/Home/Employers/Class"
                render={() => (
                  <ClientEmployerClass
                    id={""}
                    navigation={this.props.navigation}
                    selectedEmployer={this.state.selectedEmployer}
                  />
                )}
              />
              <Route
                path="/Home/Members"
                render={() => (
                  <ClientMemberLandingPage
                    id={""}
                    navigation={this.props.navigation}
                  />
                )}
              />
              <Route
                path="/Home/MyDetails"
                render={() => (
                  <MemberScreenMyDetails
                    navigation={this.props.navigation}
                    id="MemberScreenMyDetails"
                  />
                )}
              />
              <Route
                path="/Home/MyClaims"
                render={() => (
                  <MyClaims
                    navigation={this.props.navigation}
                    id={this.props.id}
                  />
                )}
              />
              <Route
                path="/Home/MyDocuments"
                render={() => (
                  <MyDocuments
                    navigation={this.props.navigation}
                    id={this.props.id}
                  />
                )}
              />
              <Route
                path="/Home/MyNotifications"
                render={() => (
                  <MyNotifications
                    navigation={this.props.navigation}
                    id={this.props.id}
                  />
                )}
              />
              <Route
                path="/Home/FindHealthcare"
                render={() => (
                  <FindHealthcare
                    navigation={this.props.navigation}
                    id={this.props.id}
                  />
                )}
              />
              <Route
                path="/Home/FindPharmacy"
                render={() => (
                  <FindPharmacy
                    navigation={this.props.navigation}
                    id={this.props.id}
                  />
                )}
              />
              <Route
                path="/Home/Help"
                render={() => (
                  <Help navigation={this.props.navigation} id={this.props.id} />
                )}
              />
              <Route
                path="/Home/EmployerDetails"
                render={() => (
                  <EmployerDetails navigation={this.props.navigation} id="" />
                )}
              />
              <Route
                path="/Home/EmployerDocuments"
                render={() => (
                  <EmployerDocuments navigation={this.props.navigation} id="" />
                )}
              />
              <Route
                path="/Home/EmployerDivisions"
                render={() => (
                  <EmployerDivisions navigation={this.props.navigation} id="" />
                )}
              />
              <Route
                path="/Home/EmployerMembers"
                render={() => (
                  <EmployerMembers navigation={this.props.navigation} id="" />
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}
