export interface IMemberAddressDetailModel {
  physical_address_line1: string;
  physical_address_line2: string;
  physical_city: string;
  physical_zip_code: string;
  physical_state_id: number;
  physical_country_id: number;
  mailing_address_line1: string;
  mailing_address_line2: string;
  mailing_city: string;
  mailing_zip_code: string;
  mailing_state_id: number;
  mailing_country_id: number;
  member_age: number | string;
  member_gender: string;
  member_dob: Date | string;
  member_fax1: string;
  member_fax2: string;
  member_email_address: string;
  member_mobile: number | string;
  member_phone_number: string;
  member_phone_numberExt: string;
  member_primary_language_spoken: string;
  member_salary: number | string;
}
export interface States {
  StateName: string;
  StateCode: string;
}

export interface Countries {
  CountryName: string;
  countryCode: string;
}
export interface SelectionList {
  id: number | string;
  name: string;
  empId?: string;
}
export interface IMemberOtherDetailModel {
  member_paid_through_date: string;
  member_termination_date: string;
  member_other_id: string;
  member_other_id_desc: string;
  member_hire_date: string;
  member_client_emp_id: string;
  member_alt_emp_id: string;
  member_cvg_effective_date: string;
  member_medical_plan: string;
  member_medical_effective_date: string;
  member_medical_term_date: string;
  member_medical_cvg_type: string;
  member_dental_plan: string;
  member_dental_effective_date: string;
  member_dental_term_date: string;
  member_dental_cvg_type: string;
  member_vision_plan: string;
  member_vision_effective_date: string;
  member_vision_term_date: string;
  member_vision_cvg_type: string;
  member_rx_plan: string;
  member_rx_effective_date: string;
  member_rx_term_date: string;
  member_rx_cvg_type: string;
  member_401k_plan: string;
  member_401k_effective_date: string;
  member_401k_term_date: string;
  member_401k_cvg_type: string;
  member_hsa_plan: string;
  member_hsa_effective_date: string;
  member_hsa_term_date: string;
  member_hsa_cvg_type: string;
  member_hra_plan: string;
  member_hra_effective_date: string;
  member_hra_term_date: string;
  member_hra_cvg_type: string;
  member_fsa_plan: string;
  member_fsa_effective_date: string;
  member_fsa_term_date: string;
  member_fsa_cvg_type: string;
  member_group_life_plan: string;
  member_group_life_effective_date: string;
  member_group_life_term_date: string;
  member_group_life_cvg_type: string;
  member_voluntary_life_plan: string;
  member_voluntary_life_effective_date: string;
  member_voluntary_life_term_date: string;
  member_voluntary_life_cvg_type: string;
  member_std_plan: string;
  member_std_effective_date: string;
  member_std_term_date: string;
  member_std_cvg_type: string;
  member_ltd_plan: string;
  member_ltd_effective_date: string;
  member_ltd_term_date: string;
  member_ltd_cvg_type: string;
  member_critical_illness_plan: string;
  member_critical_illness_effective_date: string;
  member_critical_illness_term_date: string;
  member_critical_illness_cvg_type: string;
  member_accident_plan: string;
  member_accident_effective_date: string;
  member_accident_term_date: string;
  member_accident_cvg_type: string;
  member_cancer_plan: string;
  member_cancer_effective_date: string;
  member_cancer_term_date: string;
  member_cancer_cvg_type: string;
  member_additional_benefits_plan: string;
  member_additional_benefits_effective_date: string;
  member_additional_benefits_term_date: string;
  member_additional_benefits_cvg_type: string;
  member_heart_stroke_plan: string;
  member_heart_stroke_effective_date: string;
  member_heart_stroke_term_date: string;
  member_heart_stroke_cvg_type: string;
  member_date_of_death: string;
  member_group_life_benefit_amount: string;
  member_voluntary_life_benefit_amount: string;
  member_std_weekly_benefit_amount: string;
  member_ltd_monthly_payroll_amount: string;
  member_annual_salary: string;
  member_payroll_cycle: string;
  member_notes: string;
  member_male: boolean;
  member_cobra: boolean;
  member_vip: boolean;
  member_is_student: boolean;
  member_is_spouse: boolean;
  member_do_not_bill: boolean;
  member_is_married: boolean;
  member_is_employer_ft: boolean;
  member_retired: boolean;
  member_terminate_member: boolean;
  member_is_child: boolean;
  member_disable_dependent: boolean;
  [key:string]:string | boolean | number | Date;
}

export interface IDropdownModel {
  id: number;
  name: string;
}

export interface IMemberDetailModel {
  memberAddressDetails: IMemberAddressDetailModel;
  memberOtherDetails: IMemberOtherDetailModel;
  memberBasicDetails: IMemberBasicModel;
  id?: string;
}

export const initialMemberAddressDetailModelData: IMemberAddressDetailModel = {
  physical_address_line1: "",
  physical_address_line2: "",
  physical_city: "",
  physical_zip_code: "",
  physical_state_id: -1,
  physical_country_id: -1,
  mailing_address_line1: "",
  mailing_address_line2: "",
  mailing_city: "",
  mailing_zip_code: "",
  mailing_state_id: -1,
  mailing_country_id: -1,
  
  member_fax1: "",
  member_fax2: "",
  member_email_address: "",
  member_mobile: "",
  member_phone_number: "",
  member_phone_numberExt: "",

  member_age: "",
  member_gender: "",
  member_dob: "",
  member_primary_language_spoken: "",
  member_salary: ""
};

export const initialMemberOtherDetailModelData: IMemberOtherDetailModel = {
  member_paid_through_date: "",
  member_termination_date: "",
  member_other_id: "",
  member_other_id_desc: "",
  member_hire_date: "",
  member_client_emp_id: "",
  member_alt_emp_id: "",
  member_cvg_effective_date: "",
  member_medical_plan: "",
  member_medical_effective_date: "",
  member_medical_term_date: "",
  member_medical_cvg_type: "",
  member_dental_plan: "",
  member_dental_effective_date: "",
  member_dental_term_date: "",
  member_dental_cvg_type: "",
  member_vision_plan: "",
  member_vision_effective_date: "",
  member_vision_term_date: "",
  member_vision_cvg_type: "",
  member_rx_plan: "",
  member_rx_effective_date: "",
  member_rx_term_date: "",
  member_rx_cvg_type: "",
  member_401k_plan: "",
  member_401k_effective_date: "",
  member_401k_term_date: "",
  member_401k_cvg_type: "",
  member_hsa_plan: "",
  member_hsa_effective_date: "",
  member_hsa_term_date: "",
  member_hsa_cvg_type: "",
  member_hra_plan: "",
  member_hra_effective_date: "",
  member_hra_term_date: "",
  member_hra_cvg_type: "",
  member_fsa_plan: "",
  member_fsa_effective_date: "",
  member_fsa_term_date: "",
  member_fsa_cvg_type: "",
  member_group_life_plan: "",
  member_group_life_effective_date: "",
  member_group_life_term_date: "",
  member_group_life_cvg_type: "",
  member_voluntary_life_plan: "",
  member_voluntary_life_effective_date: "",
  member_voluntary_life_term_date: "",
  member_voluntary_life_cvg_type: "",
  member_std_plan: "",
  member_std_effective_date: "",
  member_std_term_date: "",
  member_std_cvg_type: "",
  member_ltd_plan: "",
  member_ltd_effective_date: "",
  member_ltd_term_date: "",
  member_ltd_cvg_type: "",
  member_critical_illness_plan: "",
  member_critical_illness_effective_date: "",
  member_critical_illness_term_date: "",
  member_critical_illness_cvg_type: "",
  member_accident_plan: "",
  member_accident_effective_date: "",
  member_accident_term_date: "",
  member_accident_cvg_type: "",
  member_cancer_plan: "",
  member_cancer_effective_date: "",
  member_cancer_term_date: "",
  member_cancer_cvg_type: "",
  member_additional_benefits_plan: "",
  member_additional_benefits_effective_date: "",
  member_additional_benefits_term_date: "",
  member_additional_benefits_cvg_type: "",
  member_heart_stroke_plan: "",
  member_heart_stroke_effective_date: "",
  member_heart_stroke_term_date: "",
  member_heart_stroke_cvg_type: "",
  member_date_of_death: "",
  member_group_life_benefit_amount: "",
  member_voluntary_life_benefit_amount: "",
  member_std_weekly_benefit_amount: "",
  member_ltd_monthly_payroll_amount: "",
  member_annual_salary: "",
  member_payroll_cycle: "",
  member_notes: "",
  member_male: false,
  member_cobra: false,
  member_vip: false,
  member_is_student: false,
  member_is_spouse: false,
  member_do_not_bill: false,
  member_is_married: false,
  member_is_employer_ft: false,
  member_retired: false,
  member_terminate_member: false,
  member_is_child: false,
  member_disable_dependent: false
};

export interface IMemberBasicModel {
  first_name: string;
  last_name: string;
  client_name: string;
  name_suffix: string;
  name_prefix: string;
  member_relation: string;
  middle_name: string;
  member_number: string;
  employer_id: string;
  class_basic_details_id: string;
  location_id: string;
  division_id: string;
  group_id: string;
  ssn: string;
  effective_date: string;
  termination_date: string;
  link_ssn: string;
  other_id: string;
  other_desc: string;
  [key: string]: string;
  account_id:string;
}

export const initialMemberBasicDetails: IMemberBasicModel = {
  first_name: "",
  last_name: "",
  client_name: "",
  name_suffix: "",
  name_prefix: "",
  member_relation: "",
  middle_name: "",
  member_number: "",
  employer_id: "",
  class_basic_details_id: "",
  location_id: "",
  division_id: "",
  group_id: "",
  ssn: "",
  effective_date: "",
  termination_date: "",
  link_ssn: "",
  other_id: "",
  other_desc: "",
  account_id: ""
};

export const initialMemberDetailModelData: IMemberDetailModel = {
  memberAddressDetails: initialMemberAddressDetailModelData,
  memberOtherDetails: initialMemberOtherDetailModelData,
  memberBasicDetails: initialMemberBasicDetails
};

export const APIPostData = {
  member_detail: {
    first_name: "",
    last_name: "",
    middle_name: "",
    effective_date: "",
    termination_date: "",
    member_relation: "",
    name_prefix: "",
    name_suffix: "",
    member_number: "",
    other_id: "",
    other_desc: "",
    email: "",
    date_of_birth: "",
    gender: "",
    age: "",
    primary_language_spoken: "",
    salary: "",
    salary_type: "",
    ssn: "",
    link_ssn: "",
    employer_id: "",
    group_id: "",
    division_id: "",
    location_id: "",
    class_basic_details_id: "",
    physical_address_attributes: {
      address_line_1: "",
      zip_code: "",
      city: "",
      country_id: "",
      state_id: ""
    },
    mailing_address_attributes: {
      address_line_1: "",
      zip_code: "",
      city: "",
      country_id: "",
      state_id: ""
    },
    member_contact_information_attributes: {
      phone_number: "",
      phone_number_etx: "",
      fax1: "",
      fax2: "",
      mobile: ""
    },
    member_other_detail_attributes: {
      paid_thru_date: "",
      terminate_date: "",
      other_id: "",
      otherid_description: "",
      hire_date: "",
      client_employee_id: "",
      alternate_employee_id: "",
      cvg_eff_date: "",
      medical_eff_date: "",
      medical_trm_date: "",
      dental_eff_date: "",
      dental_trm_date: "",
      vision_eff_date: "",
      vision_trm_date: "",
      rx_eff_date: "",
      rx_trm_date: "",
      k401_eff_date: "",
      k401_trm_date: "",
      hsa_eff_date: "",
      hsa_trm_date: "",
      hra_eff_date: "",
      hra_trm_date: "",
      fsa_eff_date: "",
      fsa_trm_date: "",
      group_life_eff_date: "",
      group_life_trm_date: "",
      vol_life_eff_date: "",
      vol_life_trm_date: "",
      std_eff_date: "",
      std_trm_date: "",
      ltd_eff_date: "",
      ltd_trm_date: "",
      cancer_eff_date: "",
      cancer_trm_date: "",
      accident_eff_date: "",
      accident_trm_date: "",
      heart_stroke_eff_date: "",
      heart_stroke_trm_date: "",
      date_of_death: "",
      group_life_benefit_amount: "",
      vol_life_benefit_amount: "",
      std_weekly_benefit_amount: "",
      ltd_mon_payroll_amount: "",
      annual_salary: "",
      notes: "",
      male: "",
      is_married: "",
      cobra: "",
      is_employer_ft: "",
      vip: "",
      retired: "",
      is_student: "",
      terminate_member: "",
      is_spouse: "",
      is_child: "",
      do_not_bill: "",
      disable_dependent: ""
    }
  }
};
export interface ClassData {
  data: ClassItem[];
}

export interface ClassItem {
  attributes: {
    class_id: string;
    class_name: string;
  };
  id: string;
}

export interface MemberData {
  id: string;
  type: string;
  attributes: {
    first_name: string;
    last_name: string;
    member_number: string;
    date_of_birth: string;
    effective_date: string;
    member_detail: {
      MI: string;
    };
    account: {
      client_name: string;
    };
    employer: {
      employer_name: string;
    };
    group: {
      group_name: string;
    };
    division: {
      division_name: string;
    };
  };
}

export interface Members {
  data: MemberData[];
}

export interface MemberDataResponse {
  count: number;
  members: Members;
}
export interface ErrorData {
  token: string;
}

export interface ErrorResponse {
  errors: ErrorData[];
}

export interface MembersEditData {
  account_id: string;
  age: string;
  class_basic_details: BasicDetails;
  date_of_birth: string;
  division: ObjectWithIdName;
  effective_date: string;
  email: string;
  employer: ObjectWithIdName;
  first_name: string;
  gender: string;
  group: ObjectWithIdName;
  id: number;
  last_name: string;
  link_ssn: string;
  location: ObjectWithIdName;
  mailing_address: { data: AddressData };
  member_contact_information: { data: {attributes:{fax1:string,fax2:string,mobile:string,phone_number:string,phone_number_ext:string},id:string} };
  member_number: string;
  member_other_detail: { data: {attributes:EmployeeOtherData,id:string} };
  member_relation: string;
  middle_name: string;
  name_prefix: string;
  name_suffix: string;
  other_desc: string;
  other_id: string;
  physical_address: { data: AddressData };
  primary_language_spoken: string;
  salary: string;
  salary_type: string;
  ssn: string;
  termination_date: string;
}

interface BasicDetails {
  id: string;
  name: string;
}

interface ObjectWithIdName {
  id: string;
  name: string;
}
export interface AddressAttributes {
  address_line_1: string;
  address_line_2: string ;
  city: string;
  country: number ;
  state: number ;
  zip_code: string;
}

export interface AddressData {
  attributes: AddressAttributes;
  id: string;
  type: string;
}

export interface EmployeeOtherData {
  accident_eff_date: string ;
  accident_trm_date: string ;
  alternate_employee_id:  string;
  annual_salary: string ;
  cancer_eff_date: string ;
  cancer_trm_date: string ;
  client_employee_id:  string;
  cobra:  boolean;
  cvg_eff_date: string ;
  date_of_death: string ;
  dental_eff_date: string ;
  dental_trm_date: string ;
  disable_dependent: boolean;
  do_not_bill:  boolean;
  fsa_eff_date: string ;
  fsa_trm_date: string ;
  group_life_benefit_amount: string ;
  group_life_eff_date: string ;
  group_life_trm_date: string ;
  heart_stroke_eff_date: string ;
  heart_stroke_trm_date: string ;
  hire_date: string ;
  hra_eff_date: string ;
  hra_trm_date: string ;
  hsa_eff_date: string ;
  hsa_trm_date: string ;
  is_child: boolean;
  is_employer_ft: boolean;
  is_married: boolean;
  is_spouse: boolean;
  is_student: boolean;
  ltd_eff_date: string ;
  ltd_mon_payroll_amount: string ;
  ltd_trm_date: string ;
  male: boolean;
  medical_eff_date: string ;
  medical_trm_date: string ;
  notes: string;
  other_id: string;
  otherid_description: string;
  paid_thru_date: string ;
  retired: boolean;
  rx_eff_date: string ;
  rx_trm_date: string ;
  std_eff_date: string ;
  std_trm_date: string ;
  std_weekly_benefit_amount: string ;
  terminate_date: string ;
  terminate_member: boolean;
  vip: boolean;
  vision_eff_date: string ;
  vision_trm_date: string ;
  vol_life_benefit_amount: string ;
  vol_life_eff_date: string ;
  vol_life_trm_date: string ;
  medical_plan_id: string;
  medical_cvg_type_id: string;
  dental_plan_id: string;
  dental_cvg_type_id: string;
  vision_plan_id: string;
  vision_cvg_type_id: string;
  rx_plan_id: string;
  rx_cvg_type_id: string;
  group_life_plan_id: string;
  group_life_cvg_type_id: string;
  fsa_plan_id: string;
  fsa_cvg_type_id: string;
  hra_plan_id: string;
  hra_cvg_type_id: string;
  hsa_plan_id: string;
  hsa_cvg_type_id: string;
  std_plan_id: string;
  std_cvg_type_id: string;
  ltd_plan_id: string;
  ltd_cvg_type_id: string;
  vol_life_plan_id: string;
  vol_life_cvg_type_id: string;
  accident_plan_id: string;
  accident_cvg_type_id: string;
  cancer_plan_id: string;
  cancer_cvg_type_id: string;
  heart_stroke_plan_id: string;
  heart_stroke_cvg_type_id: string;
  payroll_cycle_id: string;
  [key:string]: string | boolean | number | Date;
}

export interface EditIDs{
  id: string;
  physicalId: string;
  mailingId: string;
  contactId: string;
  otherId: string;
}

export const EditIdsData = {
  id: "",
  physicalId: "",
  mailingId: "",
  contactId: "",
  otherId: "",
}

export const GenderData = [
  { name: "Male", id: "Male" },
  { name: "Female", id: "Female" },
  { name: "Other", id: "other" },
]