export const logo = require("../assets/logo.png");
export const myDetails = require("../assets/my_details.png");
export const myDetailsSelected = require("../assets/my_details_selected.png");
export const myBenefits = require("../assets/my_benefits.png");
export const myBenefitsSelected = require("../assets/my_benefits_selected.png");
export const myClaims = require("../assets/my_claims.png");
export const myClaimsSelected = require("../assets/my_claims_selected.png");
export const myDocuments = require("../assets/my_documents.png");
export const myDocumentsSelected = require("../assets/my_documents_selected.png");
export const myEnrollments = require("../assets/my_enrollments.png");
export const myNotifications = require("../assets/my_notifications.png");
export const myNotificationsSelected = require("../assets/my_notifications_selected.png");
export const activeBackground = require("../assets/active_background.png");
export const findHealthcare = require("../assets/find_healthcare.png");
export const findHealthcareSelected = require("../assets/find_healthcare_selected.png");
export const findPharmacy = require("../assets/find_pharmacy.png");
export const findPharmacySelected = require("../assets/find_pharmacy_selected.png");
export const help = require("../assets/help.png");
export const helpSelected = require("../assets/help_selected.png");
export const userImage = require("../assets/user_image.png");
export const settingIcon = require("../assets/setting_icon.png");
export const arrowDown = require("../assets/arrow_down.png");
export const arrowDownSelected = require("../assets/arrow_down_selected.png");
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgCompanyLogo = require("../assets/company-logo.png");
export const HomeIcon = require("../assets/HomeIcon.png");
export const HealthcareProviderLogo = require("../assets/HealthcareProviderLogo.png");
export const MyBenefitStatementLogo = require("../assets/MyBenefitStatementLogo.png");
export const profile_img = require("../assets/profile-img.png");
export const editPencilIcon = require("../assets/editPencilIcon.png");
export const visibilityIcon = require("../assets/visibilityIcon.png");
export const arrowDownBlueIcon = require("../assets/arrowDownBlueIcon.png");
export const MedicalBenefitsLogo = require("../assets/MedicalBenefitsLogo.png");
export const MedicalBenefitsBackground = require("../assets/MedicalBenefitsBackground.png");
export const DentalBenefitsLogo = require("../assets/DentalBenefitsLogo.png");
export const DentalBenefitsBackground = require("../assets/DentalBenefitsBackground.png");
export const Health_FSA_Background = require("../assets/Health_FSA_Background.png");
export const Health_FSA_BenefitsLogo = require("../assets/Health_FSA_BenefitsLogo.png");
export const HealthSavingAccountBackground = require("../assets/HealthSavingAccountBackground.png");
export const HealthSavingAccountLogo = require("../assets/HealthSavingAccountLogo.png");
export const HRA_BenefitsBackground = require("../assets/HRA_BenefitsBackground.png");
export const HRA_BenefitsLogo = require("../assets/HRA_BenefitsLogo.png");
export const LifeBenefitsLogo = require("../assets/LifeBenefitsLogo.png");
export const LifeBenefitsBackground = require("../assets/LifeBenefitsBackground.png");
export const otherBenefitsBackground = require("../assets/otherBenefitsBackground.png");
export const otherBenefitsLogo = require("../assets/otherBenefitsLogo.png");
export const VoluntarySupplementsLogo = require("../assets/VoluntarySupplementsLogo.png");
export const VoluntarySupplementsBackground = require("../assets/VoluntarySupplementsBackground.png");
export const VisionBenefitsLogo = require("../assets/VisionBenefitsLogo.png");
export const VisionBenefitsBackground = require("../assets/VisionBenefitsBackground.png");
export const RxBenefitsLogo = require("../assets/RxBenefitsLogo.png");
export const RetirementBenefitsLogo = require("../assets/RetirementBenefitsLogo.png");
export const RxAndRetirementBenefitsBackground = require("../assets/RxAndRetirementBenefitsBackground.png");
export const preCertificationLogo = require("../assets/preCertificationLogo.png");
export const preCertificationBackground = require("../assets/preCertificationBackground.png");
export const memberDetailBlueBackground = require("../assets/memberDetailBlueBackground.png");
export const Employer = require("../assets/emlpoyers.png");
export const Groups = require("../assets/groups.png");
export const Divisions = require("../assets/Divisions.png");
export const DivisionsSelected = require("../assets/DivisionsSelected.png");
export const Location = require("../assets/locations.png");
export const Presentation = require("../assets/class.png");
export const Members = require("../assets/member.png");
export const Union = require("../assets/union.png");
export const notifications = require("../assets/notification.png");
export const report = require("../assets/report.png");
export const more = require("../assets/more.png");
export const release = require("../assets/release.png");
export const exportimg = require("../assets/export.png");
export const Broadcast = require("../assets/Broadcast.png");
export const helpimg = require("../assets/help.png");
export const logout = require("../assets/logout.png");
export const userClient = require("../assets/user_client.png");
export const editIcon = require("../assets/edit_icon.png");
export const CompanyVision = require("../assets/Company-vision.png");
export const Solidarity = require("../assets/Solidarity.png");
export const DivisionsVision = require("../assets/Divisions-vision.png");
export const moveUp = require("../assets/move-up.png");
export const moveDown = require("../assets/move-down.png");
export const ReportSelected = require("../assets/Report-Selected.png");
export const MoreSelected = require("../assets/More-Selected.png");
export const UnionSelected = require("../assets/Union-Selected.png");
export const ReleaseSelected = require("../assets/Release-Selected.png");
export const BroadcastSelected = require("../assets/Broadcast-Selected.png");
export const NotificationsSelected = require("../assets/Notifications-Selected.png");
export const ExportSelected = require("../assets/Export-Selected.png");
export const MembersSelected = require("../assets/Members-Selected.png");
export const SupportSelected = require("../assets/Support-Selected.png");
export const EmployersSelected = require("../assets/Employers-Selected.png");
export const CloseIcon = require("../assets/closeIcon.png");
export const calendarIcon = require("../assets/calendarIcon.png");
export const checkboxIcon = require("../assets/checkboxIcon.png");
export const searchIcon = require("../assets/searchIcon.png");
export const filterIcon = require("../assets/filterIcon.png");
export const blackEditIcon = require("../assets/blackEditIcon.png");
export const deleteIcon = require("../assets/deleteIcon.png");
export const filterIconSelected = require("../assets/filter_icon_selected.png");
export const GroupSelected = require("../assets/group_selected.png");
