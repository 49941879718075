import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import "./GenericSuccessDialog.web.css";
import GenericButton from "./GenericButton.web";
const successIcon = require("./success_icon.png");

interface Props {
  open: boolean;
  handleDone: () => void;
  contains?: any;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

interface S {}

export default class GenericSuccessDialog extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Dialog
        onClose={this.props.handleDone}
        aria-labelledby="customized-dialog-title"
        open={this.props.open}
        className="generic-success-dialog"
        data-test-id="generic-success-dialog"
        maxWidth={this.props.maxWidth || "sm"}
        fullWidth
      >
        <DialogContent dividers data-test-id="dialog-content">
          <img src={successIcon} alt="Success Icon" className="success-icon" />
          {this.props.contains || "Your changes have been saved successfully!"}
        </DialogContent>
        <DialogActions>
          <GenericButton
            variant="contained"
            color="primary"
            onClick={this.props.handleDone}
            data-test-id="success-done-btn"
          >
            Done
          </GenericButton>
        </DialogActions>
      </Dialog>
    );
  }
}
