export interface IEmployerDetailModel {
  employerID: string;
  employerName: string;
  clientName: string;
  phoneNumber: string;
  contactName1: string;
  email1: string;
  contactName2: string;
  email2: string;
  effectiveDate: Date | string;
  terminationDate: Date | string;
  physicalAddress: IAddressModel;
  mailingAddress: IAddressModel;
  isMailingAddressSameAsPhysicalAddress: boolean;
  logo: File | string;
  supportingDocument: File | string;
}

export interface IAddressModel {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
}

export interface IEmployerBasicDetailsValidationModel {
  employerID: string;
  employerName: string;
  clientName: string;
  phoneNumber: string;
  contactName1: string;
  email1: string;
  contactName2: string;
  email2: string;
  effectiveDate: string;
  terminationDate: string;
}

export interface IEmployerAddressDetailsValidationModel {
  physicalAddressLine1: string;
  physicalAddressLine2: string;
  physicalCity: string;
  physicalState: string;
  physicalCountry: string;
  physicalZipCode: string;
  mailingAddressLine1: string;
  mailingAddressLine2: string;
  mailingCity: string;
  mailingState: string;
  mailingCountry: string;
  mailingZipCode: string;
}

export interface IEmployerDivisionModel {
  employerID: string;
  employerName: string;
  clientName: string;
  divisionID: string;
  divisionName: string;
  groupName: string;
  effectiveDate: Date | string;
  terminationDate: Date | string;
  physicalAddress: IAddressModel;
  mailingAddress: IAddressModel;
  divisionCheckAddress: IAddressModel;
  isMailingAddressSameAsPhysicalAddress: boolean;
  isDivisionAddressSameAsPhysicalAddress: boolean;
  isDivisionAddressSameAsMailingAddress: boolean;
  groupContact1: string;
  groupContact2: string;
  groupEmail1: string;
  groupEmail2: string;
  groupPhoneNumber1: string;
  groupPhoneNumber2: string;
  groupBillingContact: string;
  enrollStatus: string;
  groupTaxID: string;
  logo: File | string;
  supportingDocument: File | string;
}

export interface IDivisionBasicDetailValidationModel {
  employerID: string;
  employerName: string;
  clientName: string;
  divisionID: string;
  divisionName: string;
  groupName: string;
  effectiveDate: string;
  terminationDate: string;
}

export interface IDivisionAddressValidationModel {
  physicalAddressLine1: string;
  physicalAddressLine2: string;
  physicalCity: string;
  physicalState: string;
  physicalCountry: string;
  physicalZipCode: string;
  mailingAddressLine1: string;
  mailingAddressLine2: string;
  mailingCity: string;
  mailingState: string;
  mailingCountry: string;
  mailingZipCode: string;
  divisionCheckAddressLine1: string;
  divisionCheckAddressLine2: string;
  divisionCheckCity: string;
  divisionCheckState: string;
  divisionCheckCountry: string;
  divisionCheckZipCode: string;
  groupContact1: string;
  groupContact2: string;
  groupEmail1: string;
  groupEmail2: string;
  groupPhoneNumber1: string;
  groupPhoneNumber2: string;
  groupBillingContact: string;
  enrollStatus: string;
  groupTaxID: string;
}

export const initialAddressModelData: IAddressModel = {
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
};

export const initialEmployerDetailModelData: IEmployerDetailModel = {
  employerID: "",
  employerName: "",
  clientName: "Builder AI",
  phoneNumber: "",
  contactName1: "",
  email1: "",
  contactName2: "",
  email2: "",
  effectiveDate: "",
  terminationDate: "",
  physicalAddress: initialAddressModelData,
  mailingAddress: initialAddressModelData,
  isMailingAddressSameAsPhysicalAddress: false,
  logo: "",
  supportingDocument: "",
};

export const initialBasicDetailValidation: IEmployerBasicDetailsValidationModel = {
  employerID: "",
  employerName: "",
  clientName: "",
  phoneNumber: "",
  contactName1: "",
  email1: "",
  contactName2: "",
  email2: "",
  effectiveDate: "",
  terminationDate: "",
};

export const initialAddressDetailValidation: IEmployerAddressDetailsValidationModel = {
  physicalAddressLine1: "",
  physicalAddressLine2: "",
  physicalCity: "",
  physicalState: "",
  physicalCountry: "",
  physicalZipCode: "",
  mailingAddressLine1: "",
  mailingAddressLine2: "",
  mailingCity: "",
  mailingState: "",
  mailingCountry: "",
  mailingZipCode: "",
};

export const initialEmployerDivisionModelData: IEmployerDivisionModel = {
  employerID: "",
  employerName: "",
  clientName: "Builder AI",
  divisionID: "",
  divisionName: "",
  groupName: "",
  effectiveDate: "",
  terminationDate: "",
  physicalAddress: initialAddressModelData,
  mailingAddress: initialAddressModelData,
  divisionCheckAddress: initialAddressModelData,
  isMailingAddressSameAsPhysicalAddress: false,
  isDivisionAddressSameAsMailingAddress: false,
  isDivisionAddressSameAsPhysicalAddress: false,
  groupContact1: "",
  groupContact2: "",
  groupEmail1: "",
  groupEmail2: "",
  groupPhoneNumber1: "",
  groupPhoneNumber2: "",
  groupBillingContact: "",
  groupTaxID: "",
  enrollStatus: "",
  logo: "",
  supportingDocument: "",
};

export const initialDivisionBasicDetailValidation: IDivisionBasicDetailValidationModel = {
  employerID: "",
  employerName: "",
  clientName: "",
  divisionID: "",
  divisionName: "",
  groupName: "",
  effectiveDate: "",
  terminationDate: "",
};

export const initialDivisionAddressValidation: IDivisionAddressValidationModel = {
  physicalAddressLine1: "",
  physicalAddressLine2: "",
  physicalCity: "",
  physicalState: "",
  physicalCountry: "",
  physicalZipCode: "",
  mailingAddressLine1: "",
  mailingAddressLine2: "",
  mailingCity: "",
  mailingState: "",
  mailingCountry: "",
  mailingZipCode: "",
  divisionCheckAddressLine1: "",
  divisionCheckAddressLine2: "",
  divisionCheckCity: "",
  divisionCheckState: "",
  divisionCheckCountry: "",
  divisionCheckZipCode: "",
  groupContact1: "",
  groupContact2: "",
  groupEmail1: "",
  groupEmail2: "",
  groupPhoneNumber1: "",
  groupPhoneNumber2: "",
  groupBillingContact: "",
  enrollStatus: "",
  groupTaxID: "",
};
