import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Divider,
  Dialog,
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  createTheme,
  Input,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowLeftRoundedIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import { ThemeProvider } from "@material-ui/styles";

import "./EmployerDetails.web.css";
import EditEmployerDetailsDialogController, {
  Props,
} from "./EditEmployerDetailsDialogController";
import GenericCloseDialog from "../../../components/src/GenericCloseDialog.web";
import { getStepLabelClassName } from "./EditEmployerDivisionDialogController";

export const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: "#4166f8",
    },
  },
});

export const formValidationMsg = (validationMessage: string) => {
  return (
    <>
      {validationMessage && (
        <div className="emp-input-validation-msg" data-test-id="validation-message">{validationMessage}</div>
      )}
    </>
  );
};

export default class EditEmployerDetailsDialog extends EditEmployerDetailsDialogController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      activeStep,
      employerData,
      supportingDocName,
      logoUrl,
      empBasicDetailsValidation,
      empAddressDetailsValidation,
      logoErrorMessage,
      supportingDocErrorMessage
    } = this.state;
    const { physicalAddress, mailingAddress } = employerData;

    return (
      <ThemeProvider theme={defaultMaterialTheme}>
        <Dialog
          className="edit-emp-details-dialog"
          maxWidth="lg"
          fullWidth
          open={this.props.isOpen}
          scroll="body"
        >
          <div className="edit-emp-dialog-wrapper">
            <div className="edit-emp-flex-wrapper">
              <div className="emp-dialog-title">Edit Info</div>
              <div>
                <IconButton
                  data-test-id="close-edit-employer-btn"
                  size="small"
                  onClick={this.handleDialogClose}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <Divider className="emp-dialog-flex-divider" />
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={4}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {this.editEmployerDetailsStepList.map(
                    (empDetailStep, index) => {
                      const supportText = (
                        <div className="emp-dialog-stepper-description">
                          {empDetailStep.description}
                        </div>
                      );
                      return (
                        <Step key={empDetailStep.title}>
                          <StepLabel className={getStepLabelClassName(index, activeStep)} optional={supportText} StepIconProps={{ icon: index + 1, completed: false}}>
                            {empDetailStep.title}
                          </StepLabel>
                        </Step>
                      );
                    }
                  )}
                </Stepper>
              </Grid>
              <Grid item xs={6} sm={6} md={8} className="emp-edit-form-wrapper">
                {activeStep === 0 && (
                  <Grid container spacing={3} data-test-id="step-1-wrapper">
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Employer ID<span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Employer ID"
                        type="text"
                        className="text-input"
                        data-test-id="employer-id"
                        value={employerData.employerID}
                        onChange={(e) =>
                          this.handleInputChange(e, "employerID")
                        }
                        name="employerID"
                      />
                      {formValidationMsg(empBasicDetailsValidation.employerID)}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Employer Name<span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Employer Name"
                        type="text"
                        className="text-input"
                        data-test-id="employer-name"
                        value={employerData.employerName}
                        onChange={(e) =>
                          this.handleInputChange(e, "employerName")
                        }
                        name="employerName"
                      />
                      {formValidationMsg(
                        empBasicDetailsValidation.employerName
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Client Name<span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Client Name"
                        type="text"
                        className="text-input"
                        data-test-id="client-name"
                        value={employerData.clientName}
                        onChange={(e) =>
                          this.handleInputChange(e, "clientName")
                        }
                        name="clientName"
                        disabled
                      />
                      {formValidationMsg(empBasicDetailsValidation.clientName)}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Phone Number<span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Phone Number"
                        type="text"
                        className="text-input"
                        data-test-id="phone-number"
                        value={employerData.phoneNumber}
                        onChange={(e) =>
                          this.handleInputChange(e, "phoneNumber")
                        }
                        name="phoneNumber"
                      />
                      {formValidationMsg(empBasicDetailsValidation.phoneNumber)}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Contact Name 1<span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Contact Name 1"
                        type="text"
                        className="text-input"
                        data-test-id="contact-name-1"
                        value={employerData.contactName1}
                        onChange={(e) =>
                          this.handleInputChange(e, "contactName1")
                        }
                        name="contactName1"
                      />
                      {formValidationMsg(
                        empBasicDetailsValidation.contactName1
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Email Address 1<span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Email Address 1"
                        type="text"
                        className="text-input"
                        data-test-id="email-address-1"
                        value={employerData.email1}
                        onChange={(e) => this.handleInputChange(e, "email1")}
                        name="email1"
                      />
                      {formValidationMsg(empBasicDetailsValidation.email1)}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Contact Name 2<span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Contact Name 2"
                        type="text"
                        className="text-input"
                        value={employerData.contactName2}
                        onChange={(e) =>
                          this.handleInputChange(e, "contactName2")
                        }
                        name="contactName2"
                      />
                      {formValidationMsg(
                        empBasicDetailsValidation.contactName2
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Email Address 2<span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Email Address 2"
                        type="text"
                        className="text-input"
                        value={employerData.email2}
                        onChange={(e) => this.handleInputChange(e, "email2")}
                        name="email2"
                      />
                      {formValidationMsg(empBasicDetailsValidation.email2)}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Effective Date<span className="error-asterisk">*</span>
                      </div>
                      <Input
                        fullWidth
                        placeholder="Select Effective Date"
                        type="date"
                        onChange={(date) =>
                          this.handleDateChange(date, "effectiveDate")
                        }
                        className="effective-date-input"
                        name="effectiveDate"
                        value={employerData.effectiveDate}
                        data-test-id="effective-date"
                      />
                      {formValidationMsg(
                        empBasicDetailsValidation.effectiveDate
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Termination Date
                        <span className="error-asterisk">*</span>
                      </div>
                      <Input
                        fullWidth
                        placeholder="Select Termination Date"
                        type="date"
                        onChange={(date) =>
                          this.handleDateChange(date, "terminationDate")
                        }
                        className="termination-date-input"
                        name="terminationDate"
                        value={employerData.terminationDate}
                        data-test-id="terminationDate"
                      />
                      {formValidationMsg(
                        empBasicDetailsValidation.terminationDate
                      )}
                    </Grid>
                  </Grid>
                )}
                {activeStep === 1 && (
                  <Grid container spacing={3} data-test-id="step-2-wrapper">
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className="emp-edit-address-header"
                    >
                      Physical Address <span className="error-asterisk">*</span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="text-input-label">
                        Address Line 1<span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Address Line 1"
                        type="text"
                        className="text-input"
                        data-test-id="address-line-1"
                        value={physicalAddress.addressLine1}
                        onChange={(e) =>
                          this.handleAddressItemChange(
                            e,
                            "physical",
                            "addressLine1"
                          )
                        }
                        name="physical-addressLine1"
                      />
                      {formValidationMsg(
                        empAddressDetailsValidation.physicalAddressLine1
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="text-input-label">
                        Address Line 2 <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Address Line 2"
                        type="text"
                        className="text-input"
                        data-test-id="address-line-2"
                        value={physicalAddress.addressLine2}
                        onChange={(e) =>
                          this.handleAddressItemChange(
                            e,
                            "physical",
                            "addressLine2"
                          )
                        }
                        name="physical-addressLine2"
                      />
                      {formValidationMsg(
                        empAddressDetailsValidation.physicalAddressLine2
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        City / Town <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter City / Town"
                        type="text"
                        className="text-input"
                        data-test-id="physical-city"
                        value={physicalAddress.city}
                        onChange={(e) =>
                          this.handleAddressItemChange(e, "physical", "city")
                        }
                        name="physical-city"
                      />
                      {formValidationMsg(
                        empAddressDetailsValidation.physicalCity
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        State / County
                        <span className="error-asterisk">*</span>
                      </div>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          fullWidth
                          IconComponent={(dropdownProps) => (
                            <KeyboardArrowDownRoundedIcon {...dropdownProps} />
                          )}
                          className="edit-emp-dropdown"
                          placeholder="Select State / County"
                          value={physicalAddress.state}
                          data-test-id="state"
                          onChange={(e) =>
                            this.handleAddressItemChange(e, "physical", "state")
                          }
                          name="physical-state"
                        >
                          {this.stateList.map((item) => (
                            <MenuItem
                              value={item.value}
                              key={item.name + " state-country-dropdown"}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formValidationMsg(
                        empAddressDetailsValidation.physicalState
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Zipcode / Postal Code
                        <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Zipcode / Postal Code"
                        type="text"
                        className="text-input"
                        data-test-id="physical-zipcode"
                        value={physicalAddress.zipCode}
                        onChange={(e) =>
                          this.handleAddressItemChange(e, "physical", "zipCode")
                        }
                        name="physical-zipCode"
                      />
                      {formValidationMsg(
                        empAddressDetailsValidation.physicalZipCode
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Country <span className="error-asterisk">*</span>
                      </div>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          fullWidth
                          IconComponent={(dropdownProps) => (
                            <KeyboardArrowDownRoundedIcon {...dropdownProps} />
                          )}
                          className="edit-emp-dropdown"
                          placeholder="Select Country"
                          value={physicalAddress.country}
                          data-test-id="physical-address-country"
                          onChange={(e) =>
                            this.handleAddressItemChange(
                              e,
                              "physical",
                              "country"
                            )
                          }
                          name="physical-country"
                        >
                          {this.countryList.map((item) => (
                            <MenuItem
                              value={item.value}
                              key={item.name + " country1-dropdown"}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formValidationMsg(
                        empAddressDetailsValidation.physicalCountry
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className="emp-edit-address-header"
                    >
                      <div className="emp-mailing-address-flex-wrapper">
                        <div>
                          Mailing Address
                          <span className="error-asterisk">*</span>
                        </div>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={
                                  employerData.isMailingAddressSameAsPhysicalAddress
                                }
                                onChange={this.toggleMailingAddress}
                              />
                            }
                            label="Mailing address same as physical address"
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="text-input-label">
                        Address Line 1 <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Address Line 1"
                        type="text"
                        className="text-input"
                        data-test-id="mailing-address-line-1"
                        value={mailingAddress.addressLine1}
                        onChange={(event) =>
                          this.handleAddressItemChange(
                            event,
                            "mailing",
                            "addressLine1"
                          )
                        }
                        name="mailing-addressLine1"
                        disabled={employerData.isMailingAddressSameAsPhysicalAddress}
                      />
                      {formValidationMsg(
                        empAddressDetailsValidation.mailingAddressLine1
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className="text-input-label">
                        Address Line 2 <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Address Line 2"
                        type="text"
                        className="text-input"
                        data-test-id="mailing-address-line-2"
                        value={mailingAddress.addressLine2}
                        onChange={(event) =>
                          this.handleAddressItemChange(
                            event,
                            "mailing",
                            "addressLine2"
                          )
                        }
                        name="mailing-addressLine2"
                        disabled={employerData.isMailingAddressSameAsPhysicalAddress}
                      />
                      {formValidationMsg(
                        empAddressDetailsValidation.mailingAddressLine2
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        City / Town <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter City / Town"
                        type="text"
                        className="text-input"
                        value={mailingAddress.city}
                        onChange={(event) =>
                          this.handleAddressItemChange(event, "mailing", "city")
                        }
                        name="mailing-city"
                        data-test-id="mailing-city"
                        disabled={employerData.isMailingAddressSameAsPhysicalAddress}
                      />
                      {formValidationMsg(
                        empAddressDetailsValidation.mailingCity
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        State / County
                        <span className="error-asterisk">*</span>
                      </div>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          fullWidth
                          IconComponent={(dropdownProps) => (
                            <KeyboardArrowDownRoundedIcon {...dropdownProps} />
                          )}
                          className="edit-emp-dropdown"
                          placeholder="Select State / County"
                          value={mailingAddress.state}
                          onChange={(e) =>
                            this.handleAddressItemChange(e, "mailing", "state")
                          }
                          name="mailing-state"
                          data-test-id="mailing-state"
                          disabled={employerData.isMailingAddressSameAsPhysicalAddress}
                        >
                          {this.stateList.map((stateObj) => (
                            <MenuItem
                              value={stateObj.value}
                              key={stateObj.name + " state-country-dropdown1"}
                            >
                              {stateObj.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formValidationMsg(
                        empAddressDetailsValidation.mailingState
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Zipcode / Postal Code
                        <span className="error-asterisk">*</span>
                      </div>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter Zipcode / Postal Code"
                        type="text"
                        className="text-input"
                        data-test-id="mailing-zipcode"
                        value={mailingAddress.zipCode}
                        onChange={(event) =>
                          this.handleAddressItemChange(
                            event,
                            "mailing",
                            "zipCode"
                          )
                        }
                        name="mailing-zipCode"
                        disabled={employerData.isMailingAddressSameAsPhysicalAddress}
                      />
                      {formValidationMsg(
                        empAddressDetailsValidation.mailingZipCode
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div className="text-input-label">
                        Country <span className="error-asterisk">*</span>
                      </div>
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          fullWidth
                          IconComponent={(dropdownProps) => (
                            <KeyboardArrowDownRoundedIcon {...dropdownProps} />
                          )}
                          className="edit-emp-dropdown"
                          placeholder="Select Country"
                          value={mailingAddress.country}
                          data-test-id="country"
                          onChange={(e) =>
                            this.handleAddressItemChange(
                              e,
                              "mailing",
                              "country"
                            )
                          }
                          name="mailing-country"
                          disabled={employerData.isMailingAddressSameAsPhysicalAddress}
                        >
                          {this.countryList.map((item) => (
                            <MenuItem
                              value={item.value}
                              key={item.name + " country-dropdown1"}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formValidationMsg(
                        empAddressDetailsValidation.mailingCountry
                      )}
                    </Grid>
                  </Grid>
                )}
                {activeStep === 2 && (
                  <Grid container spacing={3} data-test-id="step-3-wrapper">
                    <Grid container item xs={12} sm={12} md={12}>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className="text-input-label">
                          Upload a logo
                          <span className="error-asterisk">*</span>
                        </div>
                        <div>
                          <input
                            accept="image/*"
                            type="file"
                            id="logo-upload-input"
                            style={{ display: "none" }}
                            data-test-id="emp-logo-upload"
                            onChange={(e) => this.handleFileUpload(e, "logo")}
                            name="logo"
                          />
                          <label htmlFor="logo-upload-input">
                            <Button
                              variant="outlined"
                              color="primary"
                              component="span"
                              fullWidth
                              className="emp-img-upload-btn"
                            >
                              Choose a File
                            </Button>
                          </label>
                        </div>
                        {formValidationMsg(logoErrorMessage)}
                      </Grid>
                    </Grid>
                    {logoUrl && (
                      <Grid item xs={12} sm={12} md={12}>
                        <div className="text-input-label">Preview</div>
                        <div className="emp-img-preview">
                          <img src={logoUrl} height={96} width={96} />
                        </div>
                      </Grid>
                    )}
                    <div className="emp-img-upload-divider" />
                    <Grid container item xs={12} sm={12} md={12}>
                      <Grid item xs={12} sm={12} md={6}>
                        <div className="text-input-label">
                          Upload Documents (format: doc, pdf)
                          <span className="error-asterisk">*</span>
                        </div>
                        <div>
                          <input
                            accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            type="file"
                            id="file-upload-input"
                            style={{ display: "none" }}
                            data-test-id="supporting-doc"
                            onChange={(e) =>
                              this.handleFileUpload(e, "supportingDocument")
                            }
                            name="supporting-doc"
                          />
                          <label htmlFor="file-upload-input">
                            <Button
                              variant="outlined"
                              color="primary"
                              component="span"
                              fullWidth
                              className="emp-img-upload-btn"
                            >
                              Choose a File
                            </Button>
                          </label>
                        </div>
                        {formValidationMsg(supportingDocErrorMessage)}
                        <div className="support-doc-name">
                          {supportingDocName}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Divider className="emp-dialog-flex-divider" />
            <div className="edit-emp-flex-wrapper edit-emp-action-flex">
              <div>
                {activeStep <= 1 && (
                  <Button
                    variant="contained"
                    className="edit-emp-save-next-btn"
                    onClick={this.saveAndNext}
                    data-test-id="save-and-next-btn"
                  >
                    Save & Next
                  </Button>
                )}
              </div>
              <div>
                <IconButton
                  disabled={activeStep <= 0}
                  className="emp-dialog-previous-btn"
                  onClick={this.handlePrevious}
                  data-test-id="previous-step-btn"
                >
                  <KeyboardArrowLeftRoundedIcon fontSize="large" />
                </IconButton>
                {activeStep < 2 && (
                  <IconButton
                    disabled={activeStep >= 2}
                    className="emp-dialog-next-btn"
                    onClick={this.handleNext}
                    data-test-id="next-step-btn"
                  >
                    <KeyboardArrowRightRoundedIcon fontSize="large" />
                  </IconButton>
                )}
                {activeStep === 2 && (
                  <Button
                    variant="contained"
                    className="edit-emp-save-next-btn"
                    onClick={this.validateFiles}
                    data-test-id="save-changes-btn"
                  >
                    Save Changes
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Dialog>
        <GenericCloseDialog
          open={this.state.isOpenWarningDialog}
          handleClose={() => this.handleCloseWarningDialog(false)}
          handleOk={() => this.handleCloseWarningDialog(true)}
        />
      </ThemeProvider>
    );
  }
}
