import React, { Component } from "react";
import { Box, Tab } from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";

import "./CustomIconTabPanel.web.css";

export interface TabIconList {
  key: string;
  icon: string;
  selectedIcon: string;
}

interface Props {
  tabList: TabIconList[];
  children: any;
  isDisplayAllTab?: boolean;
  allTabLabel?: string;
  onChange?: (tabIndex: string) => void;
}

interface S {
  tabIndex: string;
  childrenList: React.ReactNode[];
}

export default class CustomIconTabPanel extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tabIndex: this.props.isDisplayAllTab ? "All" : this.props.tabList[0].key,
      childrenList: [],
    };
  }

  getUpdatedChildren = () => {
    const newChildrenList: React.ReactNode[] = [];
    React.Children.map(this.props.children, (child) => {
      newChildrenList.push(child);
    });
    this.setState({ childrenList: newChildrenList });
  };

  componentDidMount(): void {
    this.getUpdatedChildren();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (prevProps.children !== this.props.children) {
      this.getUpdatedChildren();
    }
    if (prevState.tabIndex !== this.state.tabIndex && this.props.onChange) {
      this.props.onChange(this.state.tabIndex);
    }
  }

  handleTabIndexChange = (event: React.ChangeEvent<{}>, value: string) => {
    this.setState({
      tabIndex: value,
    });
  };

  render() {
    return (
      <Box className="custom-icon-tab-panel-container">
        <TabContext value={this.state.tabIndex}>
          <Box sx={{ borderColor: "divider" }}>
            {this.props.isDisplayAllTab && (
              <>
                <TabList
                  onChange={this.handleTabIndexChange}
                  orientation="vertical"
                >
                  <Tab
                    label={this.props.allTabLabel}
                    value="All"
                    className="all-claims-tab"
                  />
                </TabList>
                <div className="hr" />
              </>
            )}
            <TabList
              onChange={this.handleTabIndexChange}
              variant="scrollable"
              orientation="vertical"
            >
              {this.props.tabList.map((tab) => (
                <Tab
                  icon={
                    <img
                      src={
                        tab.key === this.state.tabIndex
                          ? tab.selectedIcon
                          : tab.icon
                      }
                      alt={tab.key}
                      className="icon-image"
                    />
                  }
                  value={tab.key}
                  key={tab.key}
                />
              ))}
            </TabList>
          </Box>
          {this.props.isDisplayAllTab && (
            <TabPanel value="All">{this.state.childrenList[0]}</TabPanel>
          )}
          {this.props.tabList.map((tab, index) => (
            <TabPanel value={tab.key} key={`${tab.key}children`}>
              {
                this.state.childrenList[
                  this.props.isDisplayAllTab ? index + 1 : index
                ]
              }
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    );
  }
}
