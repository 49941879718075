export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgCompanyLogo = require("../assets/company-logo.png");
export const landingImage1 = require("../assets/landingPhoto.png");
export const landingImage2 = require("../assets/landingPhotoLong.png");
export const backButton = require("../assets/backButton.png");
export const arrownUp = require("../assets/arrowUp.png");
export const arrowDown = require("../assets/arrowDown.png");
export const crossModal = require("../assets/crossButton.png");
export const warning = require("../assets/image_Warning.png");
export const waiting = require("../assets/registrationWaiting.png");
export const india = require("../assets/india.png");
export const unitedKingdom = require("../assets/united-kingdom.png");
export const unitedStates = require("../assets/united-states.png");