import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
export const images = require("./assets");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  notificationList: NotificationList[];
}

interface SS {
  id: any;
}

export interface NotificationList {
  key: string;
  icon: string;
  headingLabel: string;
  description: string;
  longDescription: string;
  time: string;
  date: string;
  isRead: boolean;
  isOpen: boolean;
}

export default class MyNotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      notificationList: [1, 2, 3, 4, 5, 6, 7, 8].map((i) => ({
        key: `${i}`,
        icon: images.notificationIcon,
        headingLabel: "MBA TPA Health Insurance",
        description: "Your claim is ready to process.",
        longDescription:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
        time: "7:41 pm",
        date: "12/06/2023",
        isRead: [1, 4].includes(i),
        isOpen: false,
      })),
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleNotificationOnClick = (key: string) => {
    const newNotificationList = this.state.notificationList.map(
      (notification) => {
        if (notification.key === key) {
          return {
            ...notification,
            isOpen: !notification.isOpen,
          };
        }
        return notification;
      }
    );

    this.setState({ notificationList: newNotificationList });
  };
}
