import moment from "moment";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { configJSON } from "./DashboardController";
import {
  IEmployerAddressDetailsValidationModel,
  IEmployerBasicDetailsValidationModel,
  IEmployerDetailModel,
  initialAddressDetailValidation,
  initialAddressModelData,
  initialBasicDetailValidation,
  initialEmployerDetailModelData,
} from "./EmployerDetailModel";

export interface Props {
  isOpen: boolean;
  handleClose: any;
  employerDetail: IEmployerDetailModel;
  saveEmployerDetail: (employerOb: IEmployerDetailModel) => void;
}

export const scrollToErrorField = (field: string) => {
  const formField = document.querySelector(`input[name=${field}]`);
  formField?.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
};

interface S {
  activeStep: number;
  employerData: IEmployerDetailModel;
  supportingDocName: string;
  logoUrl: string;
  empBasicDetailsValidation: IEmployerBasicDetailsValidationModel;
  empAddressDetailsValidation: IEmployerAddressDetailsValidationModel;
  logoErrorMessage: string;
  supportingDocErrorMessage: string;
  isOpenWarningDialog: boolean;
}

interface SS {}

export default class EditEmployerDetailsDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  editEmployerDetailsStepList = [
    {
      key: 1,
      title: "Employer Basic Details",
      description: "Type and Select details",
    },
    { key: 2, title: "Address", description: "Type and Select details" },
    {
      key: 3,
      title: "Upload Logo / Supporting Documents",
      description: "Type and Select details",
    },
  ];
  stateList = [
    { name: "Florida", value: "1" },
    { name: "Texas", value: "2" },
    { name: "New York", value: "3" },
  ];
  countryList = [
    { name: "India", value: "1" },
    { name: "USA", value: "2" },
    { name: "Canada", value: "3" },
  ];

  constructor(props: Props) {
    super(props);

    this.state = {
      activeStep: 0,
      employerData: initialEmployerDetailModelData,
      supportingDocName: "",
      logoUrl: "",
      empBasicDetailsValidation: initialBasicDetailValidation,
      empAddressDetailsValidation: initialAddressDetailValidation,
      logoErrorMessage: "",
      supportingDocErrorMessage: "",
      isOpenWarningDialog: false,
    };
  }

  async componentDidMount() {
    this.setState({ ...this.state, employerData: this.props.employerDetail });
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (this.state.employerData.isMailingAddressSameAsPhysicalAddress) {
      if (
        JSON.stringify(prevState.employerData.physicalAddress) !==
        JSON.stringify(this.state.employerData.physicalAddress)
      ) {
        this.setState({
          ...this.state,
          employerData: {
            ...this.state.employerData,
            mailingAddress: this.state.employerData.physicalAddress,
          },
        });
      }
    }
  }

  handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    param: string
  ) => {
    this.setState({
      ...this.state,
      employerData: { ...this.state.employerData, [param]: e.target.value },
    });
  };

  handleAddressItemChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { value: unknown }
    >,
    type: string,
    param: string
  ) => {
    if (type === "physical") {
      this.setState({
        ...this.state,
        employerData: {
          ...this.state.employerData,
          physicalAddress: {
            ...this.state.employerData.physicalAddress,
            [param]: e.target.value,
          },
        },
      });
    } else if (type === "mailing") {
      this.setState({
        ...this.state,
        employerData: {
          ...this.state.employerData,
          mailingAddress: {
            ...this.state.employerData.mailingAddress,
            [param]: e.target.value,
          },
        },
      });
    }
  };

  handleDateChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, param: string) => {
    this.setState({
      ...this.state,
      employerData: { ...this.state.employerData, [param]: event.target.value },
    });
  };

  toggleMailingAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      employerData: {
        ...this.state.employerData,
        isMailingAddressSameAsPhysicalAddress: event.target.checked,
        mailingAddress: event.target.checked ? this.state.employerData.physicalAddress : initialAddressModelData,
      },
    });
  };

  handleFileUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    param: string
  ) => {
    if (event.target.files && event.target.files[0]) {
      const fileObj = event.target.files[0];
      if (param === "logo") {
        this.setState({
          ...this.state,
          employerData: { ...this.state.employerData, logo: fileObj },
          logoUrl: URL.createObjectURL(fileObj),
        });
      } else if (param === "supportingDocument") {
        this.setState({
          ...this.state,
          employerData: {
            ...this.state.employerData,
            supportingDocument: fileObj,
          },
          supportingDocName: fileObj.name,
        });
      }
    }
  };

  handleCloseWarningDialog = (isAgreeToLooseData: boolean) => {
    if (isAgreeToLooseData) {
      this.setToInitialState();
      this.props.handleClose();
    }
    this.setState({ ...this.state, isOpenWarningDialog: false });
  };

  handleNext = () => {
    if (this.state.activeStep === 0) {
      this.validateEmployerBasicDetails();
    } else if (this.state.activeStep === 1) {
      this.validateEmployerAddressDetails();
    }
  };

  handlePrevious = () => {
    this.setState({ ...this.state, activeStep: this.state.activeStep - 1 });
  };

  handleDialogClose = () => {
    if (
      JSON.stringify(this.props.employerDetail) !==
      JSON.stringify(this.state.employerData)
    ) {
      this.setState({ ...this.state, isOpenWarningDialog: true });
    } else {
      this.setToInitialState();
      this.props.handleClose();
    }
  };

  setToInitialState = () => {
    this.setState({
      ...this.state,
      activeStep: 0,
      employerData: initialEmployerDetailModelData,
      empBasicDetailsValidation: initialBasicDetailValidation,
      empAddressDetailsValidation: initialAddressDetailValidation,
      logoErrorMessage: "",
      supportingDocErrorMessage: "",
      logoUrl: "",
      supportingDocName: "",
      isOpenWarningDialog: false,
    });
  };

  saveAndNext = () => {
    this.handleNext();
  };

  submitEmployerData = () => {
    const employerEditedData = { ...this.state.employerData };
    this.setToInitialState();
    this.props.saveEmployerDetail(employerEditedData);
  };

  validateRequiredField = (label: string, value: any) => {
    let errorMessage = "";
    if (!value || value.trim() === "") {
      errorMessage = `${label} is required`;
    } else if (value && value.trim().length > 30) {
      errorMessage = "Maximum length exceeded.";
    }
    return errorMessage;
  };

  validateEmployerID = () => {
    const { employerID } = this.state.employerData;
    let empIDError = "";
    if (!employerID || employerID.trim() === "") {
      empIDError = "Employer ID is required";
    } else if (employerID && employerID.trim().length > 30) {
      empIDError = "Maximum length exceeded.";
    } else if (employerID && !Number(employerID)) {
      empIDError = "Employer ID must be a number";
    }
    return empIDError;
  };

  validatePhoneNumber = () => {
    const { phoneNumber } = this.state.employerData;
    let phoneNumberError = "";
    if (!phoneNumber || phoneNumber.trim() === "") {
      phoneNumberError = "Phone Number is required";
    } else if (phoneNumber && !configJSON.mobileNumberRegex.test(phoneNumber)) {
      phoneNumberError = "Phone Number must be 10 digit number";
    }
    return phoneNumberError;
  };

  validateEmail1 = () => {
    const { email1 } = this.state.employerData;
    let email1Error = "";
    if (!email1 || email1.trim() === "") {
      email1Error = "Email Address 1 is required";
    } else if (email1 && !configJSON.emailRegex.test(email1)) {
      email1Error = "Invalid Email Address 1";
    }
    return email1Error;
  };

  validateEmail2 = () => {
    const { email2 } = this.state.employerData;
    let email2Error = "";
    if (!email2 || email2.trim() === "") {
      email2Error = "Email Address 2 is required";
    } else if (email2 && !configJSON.emailRegex.test(email2)) {
      email2Error = "Invalid Email Address 2";
    }
    return email2Error;
  };

  validatePostCode = (label: string, value: any) => {
    let postCodeError = "";
    if (!value || value.trim() === "") {
      postCodeError = `${label} is required`;
    } else if (value && !configJSON.zipCodeRegex.test(value)) {
      postCodeError = `Invalid ${label}`;
    }
    return postCodeError;
  };

  validateEffectiveDate = () => {
    const { effectiveDate } = this.state.employerData;
    let effectiveDateError = "";
    if (!effectiveDate) {
      effectiveDateError = "Effective Date is required";
    }
    return effectiveDateError;
  };

  validateTerminationDate = () => {
    const { terminationDate, effectiveDate } = this.state.employerData;
    let effectiveDateError = "";
    if (!terminationDate) {
      effectiveDateError = "Termination Date is required";
    } else if (terminationDate && effectiveDate) {
      const dateDiff = moment(effectiveDate).isAfter(terminationDate, "day");
      if (dateDiff) {
        effectiveDateError =
          "Termination Date should be greater than Effective Date";
      }
    }
    return effectiveDateError;
  };

  validateEmployerBasicDetails = () => {
    let empFirstStepValidation = { ...initialBasicDetailValidation };
    const {
      employerName,
      clientName,
      contactName1,
      contactName2,
    } = this.state.employerData;
    let errorField = "";
    const empIDError = this.validateEmployerID();
    const empNameError = this.validateRequiredField(
      "Employer Name",
      employerName
    );
    const clientNameError = this.validateRequiredField(
      "Client Name",
      clientName
    );
    const phoneNumberError = this.validatePhoneNumber();
    const contactName1Error = this.validateRequiredField(
      "Contact Name 1",
      contactName1
    );
    const email1Error = this.validateEmail1();
    const contactName2Error = this.validateRequiredField(
      "Contact Name 2",
      contactName2
    );
    const email2Error = this.validateEmail2();
    const effectiveDateError = this.validateEffectiveDate();
    const terminateDateError = this.validateTerminationDate();
    let nextStep = 0;

    if (empIDError) {
      empFirstStepValidation.employerID = empIDError;
      errorField = "employerID";
    } else if (empNameError) {
      empFirstStepValidation.employerName = empNameError;
      errorField = "employerName";
    } else if (clientNameError) {
      empFirstStepValidation.clientName = clientNameError;
      errorField = "clientName";
    } else if (phoneNumberError) {
      empFirstStepValidation.phoneNumber = phoneNumberError;
      errorField = "phoneNumber";
    } else if (contactName1Error) {
      empFirstStepValidation.contactName1 = contactName1Error;
      errorField = "contactName1";
    } else if (email1Error) {
      empFirstStepValidation.email1 = email1Error;
      errorField = "email1";
    } else if (contactName2Error) {
      empFirstStepValidation.contactName2 = contactName2Error;
      errorField = "contactName2";
    } else if (email2Error) {
      empFirstStepValidation.email2 = email2Error;
      errorField = "email2";
    } else if (effectiveDateError) {
      empFirstStepValidation.effectiveDate = effectiveDateError;
      errorField = "effectiveDate";
    } else if (terminateDateError) {
      empFirstStepValidation.terminationDate = terminateDateError;
      errorField = "terminationDate";
    } else {
      nextStep = 1;
      empFirstStepValidation = initialBasicDetailValidation;
    }
    if (empFirstStepValidation !== initialBasicDetailValidation) {
      scrollToErrorField(errorField);
    }
    this.setState({
      ...this.state,
      empBasicDetailsValidation: empFirstStepValidation,
      activeStep: nextStep,
    });
  };

  validateEmployerAddressDetails = () => {
    let empSecondStepValidation = { ...initialAddressDetailValidation };
    const physicalAddressOb = this.state.employerData.physicalAddress;
    const mailingAddressOb = this.state.employerData.mailingAddress;
    let addressErrorField = "";
    const physicalAddressLine1Error = this.validateRequiredField(
      "Address Line 1",
      physicalAddressOb.addressLine1
    );
    const physicalAddressLine2Error = this.validateRequiredField(
      "Address Line 2",
      physicalAddressOb.addressLine2
    );
    const physicalCityError = this.validateRequiredField(
      "City / Town",
      physicalAddressOb.city
    );
    const physicalStateError = this.validateRequiredField(
      "State / County",
      physicalAddressOb.state
    );
    const physicalZipCodeError = this.validatePostCode(
      "Zipcode / Postal Code",
      physicalAddressOb.zipCode
    );
    const physicalCountryError = this.validateRequiredField(
      "Country",
      physicalAddressOb.country
    );

    const mailingAddressLine1Error = this.validateRequiredField(
      "Address Line 1",
      mailingAddressOb.addressLine1
    );
    const mailingAddressLine2Error = this.validateRequiredField(
      "Address Line 2",
      mailingAddressOb.addressLine2
    );
    const mailingCityError = this.validateRequiredField(
      "City / Town",
      mailingAddressOb.city
    );
    const mailingStateError = this.validateRequiredField(
      "State / County",
      mailingAddressOb.state
    );
    const mailingZipCodeError = this.validatePostCode(
      "Zipcode / Postal Code",
      mailingAddressOb.zipCode
    );
    const mailingCountryError = this.validateRequiredField(
      "Country",
      mailingAddressOb.country
    );

    let nextStep = 1;

    if (physicalAddressLine1Error) {
      empSecondStepValidation.physicalAddressLine1 = physicalAddressLine1Error;
      addressErrorField = "physical-addressLine1";
    } else if (physicalAddressLine2Error) {
      empSecondStepValidation.physicalAddressLine2 = physicalAddressLine2Error;
      addressErrorField = "physical-addressLine2";
    } else if (physicalCityError) {
      empSecondStepValidation.physicalCity = physicalCityError;
      addressErrorField = "physical-city";
    } else if (physicalStateError) {
      empSecondStepValidation.physicalState = physicalStateError;
      addressErrorField = "physical-state";
    } else if (physicalZipCodeError) {
      empSecondStepValidation.physicalZipCode = physicalZipCodeError;
      addressErrorField = "physical-zipCode";
    } else if (physicalCountryError) {
      empSecondStepValidation.physicalCountry = physicalCountryError;
      addressErrorField = "physical-country";
    } else if (mailingAddressLine1Error) {
      empSecondStepValidation.mailingAddressLine1 = mailingAddressLine1Error;
      addressErrorField = "mailing-addressLine1";
    } else if (mailingAddressLine2Error) {
      empSecondStepValidation.mailingAddressLine2 = mailingAddressLine2Error;
      addressErrorField = "mailing-addressLine2";
    } else if (mailingCityError) {
      empSecondStepValidation.mailingCity = mailingCityError;
      addressErrorField = "mailing-city";
    } else if (mailingStateError) {
      empSecondStepValidation.mailingState = mailingStateError;
      addressErrorField = "mailing-state";
    } else if (mailingZipCodeError) {
      empSecondStepValidation.mailingZipCode = mailingZipCodeError;
      addressErrorField = "mailing-zipCode";
    } else if (mailingCountryError) {
      empSecondStepValidation.mailingCountry = mailingCountryError;
      addressErrorField = "mailing-country";
    } else {
      nextStep = 2;
      empSecondStepValidation = initialAddressDetailValidation;
    }
    if (empSecondStepValidation !== initialAddressDetailValidation) {
      scrollToErrorField(addressErrorField);
    }
    this.setState({
      ...this.state,
      empAddressDetailsValidation: empSecondStepValidation,
      activeStep: nextStep,
    });
  };

  validateFiles = () => {
    const { logo, supportingDocument } = this.state.employerData;
    let logoErrorMsg = "";
    let docErrorMsg = "";
    let fileErrorField = "";

    if (!logo) {
      fileErrorField = "logo";
      logoErrorMsg = "Logo is required";
    } else if (!supportingDocument) {
      fileErrorField = "supporting-doc";
      docErrorMsg = "Supporting Document is required";
    } else {
      this.submitEmployerData();
    }
    if (fileErrorField) {
      scrollToErrorField(fileErrorField);
    }
    this.setState({
      ...this.state,
      logoErrorMessage: logoErrorMsg,
      supportingDocErrorMessage: docErrorMsg,
    });
  };
}
