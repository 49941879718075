import React, { Component } from "react";
import { Grid } from "@material-ui/core";

import GenericTextField from "./GenericTextField.web";
import GenericSelectField from "./GenericSelectField.web";

interface Props {
  addressValues: any;
  countryList: any[];
  stateList: any[];
  handleAddressChange: (e: any, param: string) => void;
  addressLine1Error?: string;
  addressLine2Error?: string;
  cityError?: string;
  stateError?: string;
  countryError?: string;
  zipCodeError?: string;
  disabled?: boolean;
  type?: string;
  isValidationDivision?: boolean;
  isValidationGroup?: boolean;
  disabledState?: boolean;
}

interface S { }

export default class GenericAddressComponent extends Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      addressLine1Error,
      addressLine2Error,
      cityError,
      stateList,
      stateError,
      countryList,
      countryError,
      zipCodeError,
      disabled,
      type,
      addressValues,
      handleAddressChange,
      isValidationDivision,
      disabledState
    } = this.props;

    const addressLine1 = `${type}_${"address_line1"}`;
    const addressLine2 = `${type}_${"address_line2"}`;
    const city = `${type}_${"city"}`;
    const stateId = `${type}_${"state_id"}`;
    const zipCode = `${type}_${"zip_code"}`;
    const countryId = `${type}_${"country_id"}`;
    const handleZipcode = (e: { target: { value: string, name: string } }) => {
      if (e.target.value.length <= 5) {
        handleAddressChange(e, zipCode)
      }
    }
    return (
      <>
        <Grid item xs={12} sm={12} md={12}>
          <GenericTextField
            label="Address Line 1"
            value={addressValues[addressLine1]}
            onChange={(e) => handleAddressChange(e, addressLine1)}
            error={addressLine1Error}
            disabled={disabled}
            data-test-id={"addressLine1-" + type}
            onlyLetterAndNumber={isValidationDivision}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <GenericTextField
            label="Address Line 2"
            value={addressValues[addressLine2]}
            onChange={(e) => handleAddressChange(e, addressLine2)}
            error={addressLine2Error}
            disabled={disabled}
            data-test-id={"addressLine2-" + type}
            onlyLetterAndNumber={isValidationDivision}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <GenericTextField
            label="City / Town"
            value={addressValues[city]}
            onChange={(e) => handleAddressChange(e, city)}
            error={cityError}
            disabled={disabled}
            data-test-id={"city-" + type}
            onlyLetter={isValidationDivision}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <GenericSelectField
            label="State / County"
            value={addressValues[stateId]}
            selectionList={stateList}
            onChange={(e) => handleAddressChange(e, stateId)}
            error={stateError}
            disabled={disabled || disabledState}
            data-test-id={"state-" + type}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <GenericTextField
            label="Zipcode / Postal Code"
            value={addressValues[zipCode]}
            onChange={handleZipcode}
            error={zipCodeError}
            disabled={disabled}
            type={isValidationDivision ? 'text' : "number"}
            data-test-id={"zipCode-" + type}
            onlyNumber={isValidationDivision}
            inputProps={isValidationDivision ? {
              maxLength: 5
            } : {}}

          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <GenericSelectField
            label="Country"
            value={addressValues[countryId]}
            selectionList={countryList}
            onChange={(e) => handleAddressChange(e, countryId)}
            disabled={disabled}
            error={countryError}
            data-test-id={"country-" + type}
          />
        </Grid>
      </>
    );
  }
}
