import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { StepList } from "../../../../components/src/CustomVerticalFormStepper.web";
import { runEngine } from "../../../../framework/src/RunEngine";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../../framework/src/Message";
import moment from 'moment';
import {
  IGroupAddressDetailModel,
  IGroupBasicDetailModel,
  IGroupDetailModel,
  IGroupOtherDetailModel,
  initialGroupAgentData,
  initialGroupDetailModelData,
} from "./ClientEmpGroupModel";
import { IDropdownModel } from "../ClientEmployerGroups/ClientEmpGroupModel";
import { SelectedEmployerInterface } from "../AllRoleLandingPageController.web";
import { IAgentModel } from "../../../../components/src/GenericAddAgentComponent.web";

export const configJSON = require("./../config");

export interface Props {
  isOpenEditGroupDialog: boolean;
  isEdit: boolean;
  handleClose: any;
  groupDetails: IGroupDetailModel;
  selectedEmployer: SelectedEmployerInterface[],
}

interface S {
  apiGroupId: string;
  isGroupCreated: boolean;
  groupActiveStep: number;

  isOpenWarningDialog: boolean;
  addEditGroupStepList: StepList[];
  groupData: IGroupDetailModel;
  fieldErrors: { [key: string]: string[] };
  generalError: string;

  countryList: IDropdownModel[];
  statePhysicalList: IDropdownModel[];
  stateMailingList: IDropdownModel[];
  stateGrpCheckList: IDropdownModel[];

  isMailingSameAsPhysical: boolean;
  isGroupCheckAddressSameAsPhysicalAddress: boolean;
  isGroupCheckAddressSameAsMailingAddress: boolean;
}

interface SS { }

type InputType = HTMLInputElement | HTMLTextAreaElement | { value: unknown };

export default class AddEditClientEmployerGroupDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  token: string = "";
  groupStateType: string = "";
  getCountryCallId: string = "";
  getStateCallId: string = "";
  getGroupCallId: string = "";
  requestCreateGroupCallId: string = "";
  requestCreateGroupAddressCallId: string = "";
  requestCreateOtherDetailCallId: string = "";
  requiredGrpFieldStep1: { name: keyof IGroupBasicDetailModel, message: string }[] = [
    {
      name: 'group_name',
      message: 'Group name is required'
    },
    {
      name: 'group_id',
      message: 'Group id is required'
    },
    {
      name: 'tax_id',
      message: 'Tax ID is required'
    }
  ]
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      groupActiveStep: 0,
      addEditGroupStepList: [
        {
          key: 1,
          title: "Group Basic Details",
          description: "Type and Select details",
          isCompleted: false
        },
        {
          key: 2,
          title: "Address And Contact",
          description: "Type and Select details",
          isCompleted: false
        },
        {
          key: 3,
          title: "Upload Logo / Supporting Document",
          description: "Type and Select details",
          isCompleted: false
        },
        {
          key: 4,
          title: "Other Details",
          description: "Type and Select details",
          isCompleted: false
        }
      ],

      apiGroupId: '',
      groupData: initialGroupDetailModelData,

      generalError: "",
      fieldErrors: {},

      countryList: [],
      statePhysicalList: [],
      stateMailingList: [],
      stateGrpCheckList: [],

      isGroupCreated: false,
      isOpenWarningDialog: false,
      isMailingSameAsPhysical: false,
      isGroupCheckAddressSameAsPhysicalAddress: false,
      isGroupCheckAddressSameAsMailingAddress: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const response = JSON.parse(loggedInUser);
      this.token = response.token;
      this.updateClientName(response.client_name)
    }
    this.getCountryListFromApi();
  }

  updateClientName(client_name: string) {
    this.setState({
      groupData: {
        ...this.state.groupData,
        groupBasicDetails: {
          ...this.state.groupData.groupBasicDetails,
          employer_name: this.props.selectedEmployer[0].employer_name,
          employer_number: this.props.selectedEmployer[0].emp_id,
          client_name,
        }
      }
    })
  }

  removeGroupProperty = (propKey: keyof IGroupBasicDetailModel | keyof IGroupAddressDetailModel | keyof IGroupOtherDetailModel, { [propKey]: propValue, ...rest }) => rest

  handleGroupInputItemChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { value: any }
    >,
    param: keyof IGroupBasicDetailModel
  ) => {
    const index = this.requiredGrpFieldStep1.findIndex(item => item.name === param)

    if (index !== -1) {
      if (e.target.value) {
        this.setState({
          fieldErrors: this.removeGroupProperty(param, this.state.fieldErrors)
        })

      } else {

        this.setState({
          fieldErrors: {
            ...this.state.fieldErrors,
            [param]: [this.requiredGrpFieldStep1[index].message]
          }
        })
      }
    }

    this.setState({
      groupData: {
        ...this.state.groupData,
        groupBasicDetails: {
          ...this.state.groupData.groupBasicDetails,
          [param]: e.target.value
        }
      }
    });
  };

  handleAddressItemChange = (
    e: React.ChangeEvent<InputType>,
    param: string
  ) => {
    const eventValue = e.target.value;

    let fieldName = ""
    if (param.includes("country")) {
      this.groupStateType = param
      this.getStateListFromAPI(eventValue + "")

      switch (param) {
        case 'physical_country_id':
          fieldName = 'physical_state_id'
          break;
        case 'mailing_country_id':
          fieldName = 'mailing_state_id';
          break;
        case 'group_check_country_id':
          fieldName = 'group_check_state_id';
          break;

      }
      this.setState({
        groupData: {
          ...this.state.groupData,
          groupAddressDetails: {
            ...this.state.groupData.groupAddressDetails,

          }
        }
      })
    }
    if (fieldName) {
      this.setState({
        groupData: {
          ...this.state.groupData,
          groupAddressDetails: {
            ...this.state.groupData.groupAddressDetails,
            [param]: eventValue,
            [fieldName]: -1
          },
        },
      });
    } else {
      this.setState({
        groupData: {
          ...this.state.groupData,
          groupAddressDetails: {
            ...this.state.groupData.groupAddressDetails,
            [param]: eventValue,
          },
        },
      });
    }
  };

  handleContactItemChange = (
    e: React.ChangeEvent<InputType>,
    param: string
  ) => {
    const eventValue = e.target.value;

    if (param === "group_check_group_email1" || param === "group_check_group_email2") {
      const regexEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
      const val: any = eventValue
      const isValid = regexEmail.test(val)

      if (isValid || !eventValue) {
        this.setState({
          fieldErrors: this.removeGroupProperty(param, this.state.fieldErrors)
        })
      } else {
        this.setState({
          fieldErrors: {
            ...this.state.fieldErrors,
            [param]: ["Email invalid"]
          }
        })
      }
    }

    this.setState({
      groupData: {
        ...this.state.groupData,
        groupAddressDetails: {
          ...this.state.groupData.groupAddressDetails,
          [param]: eventValue,
        },
      },
    });
  }

  handleMailingGroupAddressCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    const {
      physical_address_line1,
      physical_address_line2,
      physical_city,
      physical_country_id,
      physical_state_id,
      physical_zip_code,
    } = this.state.groupData.groupAddressDetails;

    const {
      statePhysicalList,
    } = this.state

    this.setState({
      isMailingSameAsPhysical: isChecked,
      stateMailingList: isChecked ? statePhysicalList : [],
      groupData: {
        ...this.state.groupData,
        groupAddressDetails: {
          ...this.state.groupData.groupAddressDetails,
          mailing_address_line1: isChecked ? physical_address_line1 : '',
          mailing_address_line2: isChecked ? physical_address_line2 : '',
          mailing_city: isChecked ? physical_city : '',
          mailing_country_id: isChecked ? physical_country_id : '',
          mailing_state_id: isChecked ? physical_state_id : '',
          mailing_zip_code: isChecked ? physical_zip_code : '',
        },
      },
    });
  };

  handleGroupCheckSameAsPhysical = (event: React.ChangeEvent<HTMLInputElement>, param: string) => {
    const {
      statePhysicalList,
    } = this.state
    const {
      physical_address_line1,
      physical_address_line2,
      physical_city,
      physical_country_id,
      physical_state_id,
      physical_zip_code,
    } = this.state.groupData.groupAddressDetails;
    const isChecked = event.target.checked;
    this.setState({
      ...this.state,
      [param]: isChecked,
      stateGrpCheckList: isChecked ? statePhysicalList : [],
      isGroupCheckAddressSameAsMailingAddress: false,
      groupData: {
        ...this.state.groupData,
        groupAddressDetails: {
          ...this.state.groupData.groupAddressDetails,
          group_check_address_line1: isChecked ? physical_address_line1 : '',
          group_check_address_line2: isChecked ? physical_address_line2 : '',
          group_check_city: isChecked ? physical_city : '',
          group_check_country_id: isChecked ? physical_country_id : '',
          group_check_state_id: isChecked ? physical_state_id : '',
          group_check_zip_code: isChecked ? physical_zip_code : '',
        },
      },
    });
  }

  handleDivCheckSameAsMailing = (event: React.ChangeEvent<HTMLInputElement>, param: string) => {
    const {
      stateMailingList
    } = this.state
    const {
      mailing_address_line1,
      mailing_address_line2,
      mailing_city,
      mailing_country_id,
      mailing_state_id,
      mailing_zip_code,
    } = this.state.groupData.groupAddressDetails;
    const isChecked = event.target.checked;

    this.setState({
      ...this.state,
      [param]: isChecked,
      stateGrpCheckList: isChecked ? stateMailingList : [],
      isGroupCheckAddressSameAsPhysicalAddress: false,
      groupData: {
        ...this.state.groupData,
        groupAddressDetails: {
          ...this.state.groupData.groupAddressDetails,
          group_check_address_line1: isChecked ? mailing_address_line1 : '',
          group_check_address_line2: isChecked ? mailing_address_line2 : '',
          group_check_city: isChecked ? mailing_city : '',
          group_check_country_id: isChecked ? mailing_country_id : '',
          group_check_state_id: isChecked ? mailing_state_id : '',
          group_check_zip_code: isChecked ? mailing_zip_code : '',
        },
      },
    });
  }

  getGroupErrorMessage = (fieldName: string) => {
    const { fieldErrors } = this.state;
    if (fieldErrors[fieldName]) {
      return fieldErrors[fieldName][0];
    }
    return "";
  };

  handleOtherDetailsGroupInputChange = (
    e: React.ChangeEvent<InputType>,
    param: string,
    agentId?: number
  ) => {
    if (agentId !== undefined) {
      this.setState({
        ...this.state,
        groupData: {
          ...this.state.groupData,
          groupOtherDetails: {
            ...this.state.groupData.groupOtherDetails,
            agents: this.state.groupData.groupOtherDetails.agents.map(
              (list, index) => {
                if (index === agentId) {
                  return {
                    ...list,
                    [param]: e.target.value,
                  };
                }
                return list;
              }
            ),
          },
        },
      });
    } else {
      this.setState({
        ...this.state,
        groupData: {
          ...this.state.groupData,
          groupOtherDetails: {
            ...this.state.groupData.groupOtherDetails,
            [param]: e.target.value,
          },
        },
      });
    }
  };

  toggleGroupOtherDetailsField = (
    event: React.ChangeEvent<{}>,
    checked: boolean,
    param: string
  ) => {
    this.setState({
      ...this.state,
      groupData: {
        ...this.state.groupData,
        groupOtherDetails: {
          ...this.state.groupData.groupOtherDetails,
          [param]: checked,
        },
      },
    });
  };

  setAgentList = (
    newAgentList: IAgentModel[],
  ) => {
    this.setState({
      ...this.state,
      groupData: {
        ...this.state.groupData,
        groupOtherDetails: {
          ...this.state.groupData.groupOtherDetails,
          agents: newAgentList,
        },
      },
    });
  };

  addGroupAgent = () => {
    const newAgentList = [
      ...this.state.groupData.groupOtherDetails.agents,
    ];
    newAgentList.push(initialGroupAgentData);

    this.setAgentList(newAgentList);
  };

  removeGroupAgent = (index: number) => {
    const newAgentList = this.state.groupData.groupOtherDetails.agents.filter(
      (_, i) => i !== index
    );
    this.setAgentList(newAgentList);
  };

  setInitialState = () => {
    this.setState({
      ...this.state,
      groupActiveStep: 0,
      groupData: initialGroupDetailModelData,
      isOpenWarningDialog: false,
      isGroupCreated: false,
      countryList: [],
      statePhysicalList: [],
      stateMailingList: [],
      stateGrpCheckList: [],
    });
  };

  setActiveStep = (step: number) => {
    this.setState({ groupActiveStep: step });
  };

  handleCloseWarningDialog = (isAgreeToLooseData: boolean) => {
    if (isAgreeToLooseData) {
      this.props.handleClose();
    }
    this.setState({ ...this.state, isOpenWarningDialog: false });
  };

  handleNext = (mustCheckStep: boolean) => {
    if (mustCheckStep && this.state.groupActiveStep === 3) {
      this.setState({
        isGroupCreated: true
      })
    }
    this.handleSteps(true);
  };

  handleSteps = (next: boolean) => {
    const activeStep = next ? this.state.groupActiveStep + 1 : this.state.groupActiveStep - 1;
    const length = this.state.addEditGroupStepList.length;
    if (length > activeStep) {
      this.setState({
        groupActiveStep: activeStep,
        addEditGroupStepList: this.state.addEditGroupStepList.map(
          (list, index) => {
            if (index === this.state.groupActiveStep) {
              return {
                ...list,
                isCompleted: true
              };
            }
            return list;
          }
        )
      });
    }
  }

  handleGroupDialogClose = () => {
    if (
      JSON.stringify(this.props.groupDetails) !==
      JSON.stringify(this.state.groupData)
    ) {
      this.setState({ ...this.state, isOpenWarningDialog: true });
    } else {
      this.setInitialState();
      this.props.handleClose();
    }
  };

  isEmptyData = (object: any) => {
    for (let key in object) {
      if (object.hasOwnProperty(key)) {
        return false
      }
    }
    return true
  }

  checkErrors = () => {
    return new Promise((resolve) => {

      if (!this.isEmptyData(this.state.fieldErrors) || this.state.generalError !== "") {
        return resolve(true)
      }

      const { groupBasicDetails } = this.state.groupData

      const { group_termination_date, group_being_date, group_name, group_id, tax_id } = groupBasicDetails

      const errors: { [key: string]: string[] } = {}


      if (!group_name) {
        errors.group_name = ["Group name is required"]
      }
      if (!group_id) {
        errors.group_id = ["Group id is required"]
      }
      if (!tax_id) {
        errors.tax_id = ["Tax ID is required"]
      }

      if (!this.isEmptyData(errors)) {
        this.setState({
          fieldErrors: errors
        })
        return resolve(true)
      }

      if (group_termination_date && group_being_date) {
        const termi_date = moment(group_termination_date, "YYYY-MM-DD")
        const beg_date = moment(group_being_date, "YYYY-MM-DD")

        if (termi_date.isBefore(beg_date)) {
          this.setState({
            generalError: `Termination date can't before the effective date`
          })
          return resolve(true)
        }
      }

      resolve(false)
    })
  }

  removeErrors = () => {
    return new Promise((resolve) => {
      this.setState({
        generalError: ''
      }, () => resolve(true))
    })
  }

  saveAndNext = () => {
    this.handleNext(false);
  };

  saveAndFinish = async () => {
    if (this.state.groupData.groupBasicDetails.group_id.trim() == "0") {
      return this.setState({
        generalError: "The group id invalid"
      })
    }
    await this.removeErrors()
    const stillError = await this.checkErrors()
    if (stillError) {
      return;
    }

    if (this.state.groupActiveStep === 0) {
      return this.createGroupAPI()
    }

    if (this.state.groupActiveStep === 1) {
      return this.createGroupAddressAPI()
    }
    if (this.state.groupActiveStep === 2) {
      return this.handleNext(false)
    }
    if (this.state.groupActiveStep === 3) {
      return this.createGroupOtherDetail()
    }
  };

  createGroupAPI = () => {
    this.setState({
      generalError: "",
      fieldErrors: {},
    });

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.requestCreateGroupCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createGroupAPIEndPointBasicDetails.replace("{id}", this.props.selectedEmployer[0].emp_id)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    let bodyData: any = this.state.groupData.groupBasicDetails
    bodyData = this.removeGroupProperty("employer_number", bodyData)

    const httpBody = {
      group: bodyData
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createBodyGroupAddress = () => {
    const { countryList, stateGrpCheckList, statePhysicalList, stateMailingList } = this.state
    const { groupAddressDetails } = this.state.groupData
    const {
      physical_state_id,
      physical_country_id,
      mailing_state_id,
      mailing_country_id,
      group_check_state_id,
      group_check_country_id,
    } = groupAddressDetails


    const mailingCountryName: string = countryList.find((item: IDropdownModel) => item.id === mailing_country_id)?.name || ""

    const physicalCountryName: string = countryList.find((item: IDropdownModel) => item.id === physical_country_id)?.name || ""

    const physicalStateName: string = statePhysicalList.find((item: IDropdownModel) => item.id === physical_state_id)?.name || ""

    const mailingStateName: string = stateMailingList.find((item: IDropdownModel) => item.id === mailing_state_id)?.name || ""

    const groupCheckCountryName: string = countryList.find((item: IDropdownModel) => item.id === group_check_country_id)?.name || ""

    const groupCheckStateName: string = stateGrpCheckList.find((item: IDropdownModel) => item.id === group_check_state_id)?.name || ""

    const addressDetails = groupAddressDetails

    let dataAddressDetais = this.removeGroupProperty("mailing_country_id", addressDetails)

    dataAddressDetais = this.removeGroupProperty("physical_country_id", dataAddressDetais)
    dataAddressDetais = this.removeGroupProperty("physical_state_id", dataAddressDetais)
    dataAddressDetais = this.removeGroupProperty("mailing_state_id", dataAddressDetais)
    dataAddressDetais = this.removeGroupProperty("group_check_state_id", dataAddressDetais)
    dataAddressDetais = this.removeGroupProperty("group_check_country_id", dataAddressDetais)

    const body: any = {
      group_address: {
        ...dataAddressDetais,
        physical_country: physicalCountryName,
        physical_state: physicalStateName,
        mailing_country: mailingCountryName,
        mailing_state: mailingStateName,
        group_check_country: groupCheckCountryName,
        group_check_state: groupCheckStateName,
        group_id: this.state.apiGroupId
      }
    }

    return body
  }

  createGroupAddressAPI = () => {
    this.setState({
      generalError: '',
      fieldErrors: {}
    })
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.token,
    }

    const requestMessageAddress = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.requestCreateGroupAddressCallId = requestMessageAddress.messageId

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createGroupAddressEndPoint
    )

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.createBodyGroupAddress())
    );

    runEngine.sendMessage(requestMessageAddress.id, requestMessageAddress);
  }

  createBodyOtherDetail = () => {
    const { groupOtherDetails } = this.state.groupData

    const otherDetails = groupOtherDetails

    const dataOthers = this.removeGroupProperty("agents", otherDetails)

    const agentField = {
      agent_name: groupOtherDetails.agents[0].agent_name,
      agent_rate: groupOtherDetails.agents[0].agent_rate
    }

    const params = {
      group_other_detail: {
        ...dataOthers,
        ...agentField,
        group_id: this.state.apiGroupId
      }
    }

    return params
  }

  createGroupOtherDetail = () => {
    this.setState({
      generalError: '',
      fieldErrors: {}
    })
    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: this.token,
    }

    const requestMessageAddress = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.requestCreateOtherDetailCallId = requestMessageAddress.messageId

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createGroupOtherDetailEndPoint
    )

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessageAddress.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.createBodyOtherDetail())
    );

    runEngine.sendMessage(requestMessageAddress.id, requestMessageAddress);
  }

  getCountryListFromApi = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllCountryAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStateListFromAPI = (idCountry: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStateCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStateFromCountryAPiEndPoint}${idCountry}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getCountryCallId) {
        this.receiveCountryList(responseJson);
      }
      if (apiRequestCallId === this.getStateCallId) {
        this.receiveStateList(responseJson);
      }

      if (this.requestCreateGroupCallId === apiRequestCallId) {
        this.receiveCreateGroup(message);
      }
      if (this.requestCreateGroupAddressCallId === apiRequestCallId) {
        this.receiveCreateGroupAddress(message);
      }
      if (this.requestCreateOtherDetailCallId === apiRequestCallId) {
        this.receiveCreateGroupOtherDetail(message);
      }
    }
  }

  receiveCreateGroup = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && responseJson.data) {
      this.setState({
        apiGroupId: responseJson.data.id
      })

      return this.handleNext(false);
    }
    return this.handleErrorApi(responseJson)
  };

  receiveCreateGroupAddress = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data) {

      return this.handleNext(false);

    }
    return this.handleErrorApi(responseJson)
  }

  receiveCreateGroupOtherDetail = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data) {

      return this.setState({
        isGroupCreated: true
      })

    }
    return this.handleErrorApi(responseJson)
  }

  receiveStateList = (message: Message) => {
    const responseJson = message as any;
    let type: string = ""
    switch (this.groupStateType) {
      case 'physical_country_id': type = 'statePhysicalList'
        break;
      case 'mailing_country_id': type = 'stateMailingList'
        break;
      case 'group_check_country_id': type = 'stateGrpCheckList'
        break;
    }

    if (responseJson && responseJson.states && responseJson.states.length > 0) {
      this.setState({
        ...this.state, [type]: responseJson.states.map((item: any) => ({
          id: item.StateCode,
          name: item.StateName
        }))
      });
    } else {
      this.setState({
        ...this.state,
        [type]: []
      });
    }
  };

  receiveCountryList = (message: Message) => {
    const responseJson = message as any;

    if (responseJson && responseJson.countries && responseJson.countries.length > 0) {
      this.setState({ ...this.state, countryList: responseJson.countries.map((item: any) => ({ id: item.countryCode, name: item.CountryName })) });
    } else {
      this.setState({ ...this.state, countryList: [] });
    }
  };


  handleErrorApi = (responseJson: any) => {
    if (responseJson) {

      if (responseJson.errors && Array.isArray(responseJson.errors) && responseJson.errors[0].token) {

        return this.setState({
          generalError: responseJson.errors[0].token,
        });
      }

      if (typeof responseJson === 'object') {

        if (responseJson.errors.group_id) {
          return this.setState({
            fieldErrors: {
              ...this.state.fieldErrors,
              group_id: [responseJson.errors.group_id]
            }
          })
        }
        if (responseJson.errors) {
          return this.setState({
            generalError: responseJson.errors
          });
        }

        return this.setState({
          generalError: `${Object.keys(responseJson)[0]} ${responseJson[Object.keys(responseJson)[0]][0]}`,
        });

      }
    }
  }

  handleSuccessDialogClose = () => {
    this.setInitialState()
    this.props.handleClose();
  }

}
