import React from "react";
import { Button } from "@material-ui/core";

import ClientEmployerPlanPageController, {
  Props
} from "./ClientEmployerPlanPageController.web";
import ClientEmployerAddPlanDialog from "./ClientEmployerAddPlanDialog.web";

export default class ClientEmployerPlanPage extends ClientEmployerPlanPageController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { isAddPlanEmployerDialogOpen } = this.state;

    return (
      <>
        <Button
          data-test-id="add-plan-btn"
          onClick={() => this.toggleAddPlanEmployerDialog(true)}
        >
          + Add Plan
        </Button>
        {isAddPlanEmployerDialogOpen && (
          <ClientEmployerAddPlanDialog
            isOpen={isAddPlanEmployerDialogOpen}
            handleClose={() => this.toggleAddPlanEmployerDialog(false)}
          />
        )}
      </>
    );
  }
}
